import { configureStore } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import userReducer from './auth'
import homeReducer from './home'
import coverReducer from './cover'
import moreReducer from './more'
import poolsReducer from './pools'

import application from './application/reducer'

import { updateVersion } from './global/actions'

const PERSISTED_KEYS = ['home']

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    user: userReducer,
    home: homeReducer,
    cover: coverReducer,
    more: moreReducer,
    pools: poolsReducer,

    application,
  },
  middleware: (getDefaultMiddleware) => {
    return [...getDefaultMiddleware({ thunk: true, serializableCheck: false }), save({ states: PERSISTED_KEYS })]
  },
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true }),
})

store.dispatch(updateVersion())

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
