import React, { useState, lazy, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'
import { BroadcastChannel } from 'broadcast-channel'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import useEagerConnect from '@/hooks/useEagerConnect'
import { LocalStorageForWalletConnection } from '@/config'
import { ToastContainer } from 'react-toastify'
import useAuth from './hooks/useAuth'
import { connectorLocalStorageKey } from '@/config'
import PageLoader from './components/Loader/PageLoader'
import PaymentDetailsModal from '@/components/PaymentDetailsModal'
import { useGetPaymentDialogOpen } from './state/application/hooks'
import 'react-toastify/dist/ReactToastify.css'
import BlockModal from '@/components/BlockModal'

import './views/Cover/style.css'
import './views/Cover/Main/css/Home.css'
import './views/Cover/Main/css/Assets.css'
import './views/Cover/Main/css/Loader.css'
import './views/Cover/Main/css/ConnectWalletModal.css'
import './views/Cover/Main/css/Profile.css'
import './views/Cover/Main/css/Home-Cart.css'
import './views/Cover/Main/css/Payment-Details.css'
import './views/Cover/Main/css/responsive.css'
import './views/Cover/Main/css/MobileMenu.css'
import './views/Cover/Main/css/Insurance.css'
//import { useCheckIsAllowed } from '@/hooks/queries/useCheckIsAllowed'

const CoverPortal = lazy(() => import('./views/Cover/Main'))
const CoverTerms = lazy(() => import('./views/Cover/Terms'))
const CoverCart = lazy(() => import('./views/Cover/Cart'))
const CoverProfile = lazy(() => import('./views/Cover/Profile'))
const Header = lazy(() => import('./components/common/Header'))
const Footer = lazy(() => import('./components/common/Footer'))
const Home = lazy(() => import('./views/Home'))
const MoreInfo = lazy(() => import('./views/MoreInfo'))
const ProtocolInfo = lazy(() => import('./views/ProtocolInfo'))
const Profile = lazy(() => import('./views/Profile'))
const Transactions = lazy(() => import('./views/Transactions'))
const ComingSoon = lazy(() => import('./views/ComingSoon'))
const ClaimForm = lazy(() => import('./views/Cover/Claim/ClaimForm'))
const ClaimsList = lazy(() => import('./views/Cover/Claim/ClaimsList'))
const ClaimDetails = lazy(() => import('./views/Cover/Claim/ClaimDetails'))
const MainRoutes = () => {
  const { account } = useActiveWeb3React()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isPaymentDialogOpen, setIsPaymentDlgOpen] = useGetPaymentDialogOpen()
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false)
  const [accountCnt, setAccountCnt] = useState(0)
  const { login, getSignature } = useAuth()
  // const { data: allowListData, isLoading, isSuccess } = useCheckIsAllowed(account)
  const channel = React.useMemo(() => {
    return new BroadcastChannel(LocalStorageForWalletConnection)
  }, [])

  // useEffect(() => {
  //   if (!isLoading && isSuccess && !allowListData?.isAllowed && process.env.REACT_APP_CHAIN_ID === '1') {
  //     setIsBlockModalOpen(!allowListData.success)

  //   }
  // }, [allowListData, isLoading, isSuccess])

  useEffect(() => {
    if (channel) {
      channel.postMessage({
        isOpnnedAlready: true,
      })
      channel.addEventListener('message', (e) => {
        if (e.data !== undefined) {
          if (e.data?.isOpnnedAlready && !account) {
            const connectorId = window.localStorage.getItem(connectorLocalStorageKey)
            if (connectorId && !account) {
              login(connectorId)
            }
          }
        }
      })
      return () => {
        // eslint-disable-next-line no-unused-expressions
        channel.close
      }
    }
  }, [channel, login, account])

  useEffect(() => {
    if (account && accountCnt === 0) {
      setAccountCnt((prev) => prev + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (accountCnt === 1 && !isBlockModalOpen) {
      getSignature(setAccountCnt)
    }
  }, [accountCnt, getSignature, isBlockModalOpen])

  useEagerConnect()

  useEffect(() => {
    if (document.body) {
      document.body.style.overflow = isPaymentDialogOpen ? 'hidden' : 'auto'
    }
  }, [isPaymentDialogOpen])

  useEffect(() => {
    const init = () => {
      // setIsBlockModalOpen(true)
    }
    if (!isBlockModalOpen) {
      setTimeout(() => {
        init()
      }, [60 * 1000])
    }
  }, [isBlockModalOpen])

  return (
    <React.Suspense fallback={<PageLoader />}>
      {isPaymentDialogOpen && (
        <PaymentDetailsModal isDialogOpen={isPaymentDialogOpen} setPaymentDialog={setIsPaymentDlgOpen} />
      )}

      {isBlockModalOpen && <BlockModal isDialogOpen={isBlockModalOpen} />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header setMenuOpen={setMenuOpen} showModalVisible={isMenuOpen} />
      <div className={`site-outer-wrapper ${isMenuOpen ? 'site-outer-wrapper-pulled' : ''}`}>
        <div className="site-wrapper">
          <Routes>
            {!isBlockModalOpen && (
              <>
                <Route path="/" element={<CoverPortal />} />
                <Route path="/buy-cover" element={<CoverPortal />} />
                <Route path="/terms" element={<CoverTerms />} />
                <Route path="/carts" element={<CoverCart />} />
                <Route path="/my-policies" element={<CoverProfile />} />
                <Route path="/(buy-cover|my-policies|terms|carts)" element={<CoverPortal />} />
                <Route path="/investment" element={<Home />} />
                <Route path="/more-info" element={<MoreInfo />} />
                <Route path="/protocol-info/:id" element={<ProtocolInfo />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/file-claim/:id" element={<ClaimForm />} />
                <Route path="/claims" element={<ClaimsList />} />
                <Route path="/claim/:id" element={<ClaimDetails />} />
              </>
            )}
          </Routes>
        </div>
      </div>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Allow"
        cookieName="UnoRe"
        style={{
          background: 'rgba(255, 42, 182, 0.7)',
          backdropFilter: 'blur(10px)',
          alignItems: 'center',
          padding: '10px 15px',
        }}
        contentStyle={{
          margin: '0',
          flex: '1 0',
        }}
        buttonStyle={{
          color: '#E7E9FF',
          background: '#221E41',
          borderRadius: '15px',
          height: '50px',
          width: '100px',
          margin: '0',
        }}
        buttonWrapperClasses="h-100"
        buttonClasses="site-button"
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </React.Suspense>
  )
}

export default MainRoutes
