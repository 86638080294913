import { useEffect } from 'react'
import { Connector } from '@web3-react/types'
import { connectorLocalStorageKey } from '@/config'
import { Connection, ConnectionType } from '@/connectors/types'
import { getConnection } from '@/connectors'

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly()
    } else {
      await connector.activate()
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`)
  }
}

const useEagerConnect = () => {
  const selectedWallet = window.localStorage.getItem(connectorLocalStorageKey) as ConnectionType
  let selectedConnection: Connection | undefined
  if (selectedWallet) {
    try {
      selectedConnection = getConnection(selectedWallet)
    } catch {
      console.debug('There is no selected wallet.')
    }
  }

  useEffect(() => {
    if (selectedConnection) {
      connect(selectedConnection.connector)
    } // The dependency list is empty so this is only run once on mount
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useEagerConnect
