import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ModalProvider } from './components/modals/Provider'
import { RefreshContextProvider } from '@/contexts/RefreshContext'
import store from './state'
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import useOrderedConnections from '@/hooks/useOrderedConnections'
import { Connector } from '@web3-react/types'

const Providers = ({ children }) => {
  const connections = useOrderedConnections()
  const connectors: [Connector, Web3ReactHooks][] = connections.map(({ hooks, connector }) => [connector, hooks])

  // Force a re-render when our connection state changes.
  const [index, setIndex] = useState(0)

  useEffect(() => setIndex((index) => index + 1), [connections])

  // const key = useMemo(
  //   () => connections.map((connection) => connection.getName()).join('-') + index,
  //   [connections, index]
  // )
  console.log('>>> connectors checking:', { connectors })
  return (
    //@ts-ignore
    <Web3ReactProvider connectors={connectors} key={connectors}>
      <Provider store={store}>
        <RefreshContextProvider>
          <ModalProvider>{children}</ModalProvider>
        </RefreshContextProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers
