import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchEntityData, RealDataEntity } from '@/state/helpers'

export interface EntityState {
  entityMeta: RealDataEntity
}

const initialState: EntityState = {
  entityMeta: null,
}

export const fetchEntityPublicData = createAsyncThunk('entityMeta/fetchEntityPublicData', async () => {
  const publicEntityData = await fetchEntityData()
  return publicEntityData.data
})

export const MoreSlice = createSlice({
  name: 'More',
  initialState,
  reducers: {
    setEntityData: (state, action) => {
      state.entityMeta = { ...state.entityMeta, ...action.payload }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntityPublicData.fulfilled, (state, action) => {
      if (action.payload) {
        state.entityMeta = { ...state.entityMeta, ...action.payload[0] }
      }
    })
  },
})

export const { setEntityData } = MoreSlice.actions

export default MoreSlice.reducer
