import { initializeConnector } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { Connection, ConnectionType } from './types'

export const [metaMask, hooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions, options: { mustBeMetaMask: true }, onError: (e) => console.error(e) }),
)

export const injectedConnection: Connection = {
  getName: () => 'Browser Wallet',
  //@ts-ignore
  connector: metaMask,
  hooks,
  type: ConnectionType.INJECTED,
  shouldDisplay: () => true,
}
