import { useCallback } from 'react'
import { ethers } from 'ethers'
import buySalesPolicy from '@/utils/calls/salesPolicy'
import { useInitBiconomy } from './useBiconomy'
import { callWithEstimateGas } from '@/utils/calls/estimateGas'
import { getSalesPolicyContract } from '@/utils/contractHelpers'
import useActiveWeb3React from './useActiveWeb3React'
import { ETH_DEFAULT_CHAIN_ID } from '@/config'
import { useWeb3React } from '@web3-react/core'

const useBuyPolicy = () => {
  const { account, chainId, library } = useActiveWeb3React()
  const { salesPolicyContract, biconomy } = useInitBiconomy()
  const { provider } = useWeb3React()

  const handleBuyPolicy = useCallback(
    async (param) => {
      try {
        const walletProvider = new ethers.providers.Web3Provider(provider.provider)

        if (param.signature && chainId === ETH_DEFAULT_CHAIN_ID) {
          const tx = await buySalesPolicy(salesPolicyContract, param, walletProvider, account, chainId, biconomy)
          if ((tx && tx?.transactionId) || tx?.result) return true
        }
      } catch (e) {
        console.log(e)
        return false
      }
    },
    [chainId, salesPolicyContract, account, biconomy, provider],
  )

  const handleTransferPolicy = useCallback(
    async (to, tokenId) => {
      try {
        const contract = getSalesPolicyContract(library?.getSigner())
        const tx = await callWithEstimateGas(contract, 'transferFrom', [account, to, tokenId])
        const receipt = await tx.wait()
        return receipt.status
      } catch (e) {
        return false
      }
    },
    [account, library],
  )

  return { onBuyPolicy: handleBuyPolicy, onTransferPolicy: handleTransferPolicy }
}

export default useBuyPolicy
