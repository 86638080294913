import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import stripHexPrefix from 'strip-hex-prefix'
import salesPolicyAbi from '@/config/abi/salesPolicy.json'
import { metaCall } from '../biconomy'

const usdcDecimals = 6
const secondsInOneDay = 24 * 60 * 60

function getHexStrFromStr(str) {
  const strWithoutHex = stripHexPrefix(str)
  const strBytes = ethers.utils.toUtf8Bytes(strWithoutHex)
  return ethers.utils.hexlify(strBytes)
}

export function getBytes32FromStr(str) {
  const strBytes = getHexStrFromStr(str)
  return ethers.utils.keccak256(strBytes)
}

const buySalesPolicy = async (contract, param, walletProvider, account, chainId, biconomy) => {
  try {
    const { protocols, coverPrice, coverAmount, coverDuration, signature, deadline, usdcAddress, assetAddresses } =
      param
    const nonce = await contract.getNonce(account)

    const bigCoverPrice = new BigNumber(coverPrice)
      .multipliedBy(10 ** usdcDecimals)
      .toString()
      .split('.')[0]
    const bigCoverAmount = []
    const coverDurationInSec = []

    coverAmount.forEach((element, index) => {
      bigCoverAmount.push(
        new BigNumber(element)
          .multipliedBy(10 ** usdcDecimals)
          .toString()
          .split('.')[0],
      )
      coverDurationInSec.push(new BigNumber(coverDuration[index] * secondsInOneDay).toString())
    })

    const funParam = [
      assetAddresses,
      protocols,
      bigCoverAmount,
      coverDurationInSec,
      bigCoverPrice,
      new BigNumber(deadline).toString(),
      usdcAddress,
      signature.r,
      signature.s,
      signature.v,
      nonce,
    ]

    const contractInterface = new ethers.utils.Interface(salesPolicyAbi)

    const tx = await metaCall(
      contract,
      contractInterface,
      account,
      walletProvider,
      chainId,
      {
        name: 'buyPolicy',
        params: funParam,
      },
      biconomy,
    )
    return tx
  } catch (error) {
    return false
  }
}

export default buySalesPolicy
