import { Contract, ethers } from 'ethers'
import { simpleRpcProvider, simpleRpcProviderOnBsc } from '@/utils/providers'
import { poolsConfig } from '@/config/constants'

// Addresses
import {
  getAddress,
  getAddressOnBsc,
  getMulticallAddress,
  getCohortAddress,
  getSSRPAddress,
  getMigrationAddress,
  getSalesPolicyAddress,
  getPayoutRequestETHAddress,
  getPayoutRequestUNOAddress,
  getPayoutRequestUSDCAddress,
  getPayoutRequestUSDTAddress,
  getLpUnoTokenAddress,
} from '@/utils/addressHelpers'

// ABI
import bep20Abi from '@/config/abi/erc20.json'
import CohortAbi from '@/config/abi/Cohort.json'
import poolAbi from '@/config/abi/RiskPool.json'
import poolAbiV1 from '@/config/abi/RiskPoolV1.json'
import ssipAbi from '@/config/abi/SSIPAbi.json'
import premiumAbi from '@/config/abi/PremiumPool.json'
import MultiCallAbi from '@/config/abi/Multicall.json'
import lpTokenAbi from '@/config/abi/lpTokenAbi.json'
import lpPoolAbi from '@/config/abi/lpPoolAbi.json'
import capitalAgentAbi from '@/config/abi/capitalAgent.json'
import { premiumPool } from '@/config/constants/pools'
import { Address, PoolConfig } from '@/config/constants/types'
import migrationAbi from '@/config/abi/Migration.json'
import salesPolicyAbi from '@/config/abi/salesPolicy.json'
import payoutRequestAbi from '@/config/abi/PayoutRequest.json'
import tokens, { lpTokens } from '@/config/constants/tokens'
import { getRpcUrl } from '@/utils/getRpcUrl'
import { JsonRpcProvider } from '@ethersproject/providers'
import { AppNetworkId } from '@/config/chains'
import addresses from '@/config/constants/contracts'
const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

const getContractOnBSC = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProviderOnBsc
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getBep20ContractOnBsc = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContractOnBSC(bep20Abi, address, signer)
}
export const getCohortContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContractOnBSC(CohortAbi, getCohortAddress(), signer)
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}

// export const getRiskPoolsContract = (addr: string, signer?: ethers.Signer | ethers.providers.Provider) => {
//   return getContract(poolAbi, addr, signer)
// }

export const getRiskPoolsContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.poolId === id)
  return config.ethPool
    ? getContract(poolAbi, getAddress(config.contractAddress), signer)
    : getContractOnBSC(poolAbiV1, getAddressOnBsc(config.contractAddress), signer)
}

export const getRiskPoolsContractV2 = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.poolId === id)
  return config.ethPool
    ? getContract(poolAbi, getAddress(config.riskPoolAddress), signer)
    : getContractOnBSC(poolAbiV1, getAddressOnBsc(config.riskPoolAddress), signer)
}

export const getPremiumContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(premiumAbi, getAddress(premiumPool.contractAddress), signer)
}

export const getSSRPContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ssipAbi, getSSRPAddress(), signer)
}

export const getCapitalAgentContract = (chainId: AppNetworkId, signer?: ethers.Signer | ethers.providers.Provider) => {
  return new Contract(
    addresses.capitalAgent[chainId],
    capitalAgentAbi,
    signer ?? new JsonRpcProvider(getRpcUrl(chainId)),
  )
}

export const getLpContract = (address: Address, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, getAddress(address), signer)
}

export const getLpPoolContract = (address: Address, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpPoolAbi, getAddress(address), signer)
}

export const getPoolContract = (pool: PoolConfig, signer?: ethers.Signer | ethers.providers.Provider) => {
  const provider = signer ?? new JsonRpcProvider(getRpcUrl(pool.chainId))

  return new Contract(pool.contractAddress[pool.chainId], ssipAbi, provider)
}

export const getMigrationContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContractOnBSC(migrationAbi, getMigrationAddress(), signer)
}

export const getSalesPolicyContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(salesPolicyAbi, getSalesPolicyAddress(), signer)
}

export const getPayoutRequestContract = (symbol: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  let address = '0x'
  if (symbol === 'ETH') {
    address = getPayoutRequestETHAddress()
  }
  if (symbol === 'UNO') {
    address = getPayoutRequestUNOAddress()
  }
  if (symbol === 'USDC') {
    address = getPayoutRequestUSDCAddress()
  }
  if (symbol === 'USDT') {
    address = getPayoutRequestUSDTAddress()
  }
  return getContract(payoutRequestAbi, address, signer)
}

export const getLPContractByToken = (symbol: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  let address = '0x'
  if (symbol === 'ETH') {
    address = getLpUnoTokenAddress(lpTokens.find((item) => item.originTokenSymbol === 'ETH').address)
  }
  if (symbol === 'UNO') {
    address = getLpUnoTokenAddress(lpTokens.find((item) => item.originTokenSymbol === 'UNO').address)
  }
  if (symbol === 'USDC') {
    address = getLpUnoTokenAddress(lpTokens.find((item) => item.originTokenSymbol === 'USDC').address)
  }
  if (symbol === 'USDT') {
    address = getLpUnoTokenAddress(lpTokens.find((item) => item.originTokenSymbol === 'USDT').address)
  }
  return getContract(lpTokenAbi, address, signer)
}

export const getErc20ContractBySymbol = (symbol: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  let address = '0x'

  if (symbol === 'UNO') {
    address = getAddress(tokens.uno.address)
  }
  if (symbol === 'USDC') {
    address = getAddress(tokens.usdc.address)
  }
  if (symbol === 'USDT') {
    address = getAddress(tokens.usdt.address)
  }
  return getContract(bep20Abi, address, signer)
}
