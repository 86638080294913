import { SiweMessage } from 'siwe'

const domain = window.location.host
const origin = window.location.origin

export function createSiweMessage(address, statement) {
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: '1',
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID ?? '1'),
  })
  return message.prepareMessage()
}
