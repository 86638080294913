import { useMemo } from 'react'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import {
  // getBep20Contract,
  getCohortContract,
  getLpPoolContract,
  getPoolContract,
  getSSRPContract,
} from '@/utils/contractHelpers'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../config/constants/multicall'
import { getContract } from '../utils'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  // const { library } = useActiveWeb3React()
  return null // useMemo(() => getBep20Contract(address, library.getSigner()), [address, library])
}

export const useCohort = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCohortContract(library.getSigner()), [library])
}

export const useSsip = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSSRPContract(library.getSigner()), [library])
}

export const usePoolContract = (address, isLpPool) => {
  const { library } = useActiveWeb3React()
  return useMemo(
    () => (isLpPool ? getLpPoolContract(address, library.getSigner()) : getPoolContract(address, library.getSigner())),
    [library, address, isLpPool],
  )
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}
