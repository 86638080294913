import { useMemo } from 'react'
import { ethers } from 'ethers'
import { Biconomy } from '@biconomy/mexa'
import { BICONOMY_API_KEY } from '@/config'
import salesPolicyAbi from '@/config/abi/salesPolicy.json'
import { getSalesPolicyAddress } from '@/utils/addressHelpers'
import { useWeb3React } from '@web3-react/core'
import { REACT_APP_CHAIN_ID } from '@/Cover-Portal/constants'
import { Web3Provider } from '@ethersproject/providers'

export const useInitBiconomy = () => {
  const { provider } = useWeb3React()

  return useMemo(() => {
    let biconomy: Biconomy

    if (provider?.provider) {
      const contractInterface = new ethers.utils.Interface(salesPolicyAbi)

      if (REACT_APP_CHAIN_ID !== '1') {
        return {
          salesPolicyContract: new ethers.Contract(
            getSalesPolicyAddress(),
            contractInterface,
            new Web3Provider(provider.provider),
          ),
          biconomy: {
            provider: new Web3Provider(provider.provider),
          },
        }
      }

      biconomy = new Biconomy(provider.provider, {
        contractAddresses: [getSalesPolicyAddress()],
        apiKey: BICONOMY_API_KEY,
        debug: true,
      })
      initBiconomy(biconomy)
      const salesPolicyContract = new ethers.Contract(
        getSalesPolicyAddress(),
        contractInterface,
        biconomy.ethersProvider,
      )

      return {
        salesPolicyContract,
        biconomy,
      }
    }
    return {
      salesPolicyContract: undefined,
      biconomy,
    }
  }, [provider])
}

export const initBiconomy = async (biconomy) => {
  await biconomy.init()
}
