import { createSlice } from '@reduxjs/toolkit'

export interface AuthState {
  userName: string
  userId: string
  whiteStatus: boolean
  emailId?: string
  healthCheck?: boolean
  showModal?: boolean
  step?: number
  error?: boolean
}

const initialState: AuthState = {
  userId: null,
  userName: '',
  whiteStatus: false,
  emailId: '',
  healthCheck: false,
  showModal: false,
  step: 0,
  error: false,
}

export const AuthSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    userLoginStatus: (state, action) => {
      state = { ...state, ...action.payload }
      return state
    },
    setHealthCheckStatus: (state, action) => {
      state = { ...state, ...action.payload }
      return state
    },
    setShowWalletModal: (state, action) => {
      state = { ...state, ...action.payload }
      return state
    },
  },
})

export const { userLoginStatus, setHealthCheckStatus, setShowWalletModal } = AuthSlice.actions

export default AuthSlice.reducer
