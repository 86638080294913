import { ethers } from 'ethers'
import abi from 'ethereumjs-abi'
import { toBuffer } from 'ethereumjs-util'

export const getSignatureParameters = (signature) => {
  if (!ethers.utils.isHexString(signature)) {
    throw new Error('Given value "'.concat(signature, '" is not a valid hex string.'))
  }
  const r = signature.slice(0, 66)
  const s = '0x'.concat(signature.slice(66, 130))
  let v = '0x'.concat(signature.slice(130, 132))
  let numV = ethers.BigNumber.from(v).toNumber()
  if (![27, 28].includes(numV)) numV += 27
  return { r, s, v }
}

export const constructMetaTransactionMessage = (
  nonce: any,
  salt: any,
  functionSignature: any,
  contractAddress: any,
) => {
  return abi.soliditySHA3(
    ['uint256', 'address', 'uint256', 'bytes'],
    [nonce, contractAddress, salt, toBuffer(functionSignature)],
  )
}

const getMetaTransactionEIP712SignConfig = (
  chainId: any,
  userAddress: any,
  domainName: string,
  contractAddress: any,
  functionSignature: any,
  nonce: { toNumber: () => any },
  isVenly = false,
) => {
  const domainType = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'verifyingContract', type: 'address' },
    { name: 'salt', type: 'bytes32' },
  ]

  const metaTransactionType = [
    { name: 'nonce', type: 'uint256' },
    { name: 'from', type: 'address' },
    { name: 'functionSignature', type: 'bytes' },
  ]

  const domainData = {
    name: domainName,
    version: '1',
    verifyingContract: contractAddress,
    salt: ethers.utils.hexZeroPad(ethers.BigNumber.from(chainId).toHexString(), 32),
  }

  const message = {
    nonce: nonce.toNumber(),
    from: userAddress,
    functionSignature,
  }

  const dataToSignObj = {
    types: {
      EIP712Domain: domainType,
      MetaTransaction: metaTransactionType,
    },
    domain: domainData,
    primaryType: 'MetaTransaction',
    message,
  }

  const dataToSign = isVenly ? dataToSignObj : JSON.stringify(dataToSignObj)

  return dataToSign
}

export const metaCall = async (contract, contractInterface, account, walletProvider, salt, call, biconomy) => {
  try {
    const nonce = await contract.getNonce(account)
    const functionSignature = contractInterface.encodeFunctionData(call.name, call.params)

    const dataToSign = getMetaTransactionEIP712SignConfig(
      salt,
      account,
      'BuyPolicyMetaTransaction',
      await contract.address,
      functionSignature,
      nonce,
    )
    const signature = await walletProvider.send('eth_signTypedData_v4', [account, dataToSign])
    const { r, s, v } = getSignatureParameters(signature)

    const executeMetaTransactionSignature = contractInterface.encodeFunctionData('executeMetaTransaction', [
      account,
      functionSignature,
      r,
      s,
      v,
    ])
    let txParams = {
      data: executeMetaTransactionSignature,
      to: await contract.address,
      from: account,
      signatureType: 'EIP712_SIGN',
    }
    let provider = biconomy.provider
    const tx = await provider.provider.send('eth_sendTransaction', [txParams])

    // TODO: transactionId is returned by biconomy but it always fail to fetch the transaction
    if (tx.result) {
      const transaction = await provider.getTransaction(tx.result || tx.transactionId)

      if (!transaction) throw new Error('Transaction failed')

      await transaction.wait()
    }

    return tx
  } catch (error) {
    return false
  }
}
