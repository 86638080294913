const cmcProtocols = [
  {
    id: '3',
    name: 'Curve',
    address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    symbol: 'CRV',
    url: 'https://curve.fi',
    description:
      'Curve is a decentralized exchange liquidity pool on Ethereum designed for extremely efficient stablecoin trading',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/curve.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'curve-dao-token',
    cmcId: '6538',
    category: 'Dexes',
    chains: ['Ethereum', 'Avalanche', 'Fantom', 'Polygon', 'Arbitrum', 'xDai', 'Harmony', 'Moonbeam', 'Optimism'],
    module: 'curve/index.js',
    twitter: 'CurveFinance',
    audit_links: ['https://curve.fi/audits'],
    oracles: [],
    language: 'Vyper',
    slug: 'curve',
    tvl: 20796976995.932858,
    chainTvls: {
      Optimism: 505439.1624512656,
      Avalanche: 998146139.4514115,
      Harmony: 68251028.75543213,
      Moonbeam: 657769.9552962538,
      Ethereum: 18402635146.221397,
      xDai: 85676403.73810571,
      'Ethereum-staking': 1249362055.7983425,
      Polygon: 413846232.77325886,
      Arbitrum: 215552954.3366069,
      Fantom: 612363651.4941957,
      staking: 1249362055.7983425,
    },
    change_1h: -0.012107228114146551,
    change_1d: 1.47889799898158,
    change_7d: 1.566713999108373,
    staking: 1249362055.7983425,
    fdv: 9238685156,
    mcap: 1096319787,
  },
  {
    id: '182',
    name: 'Lido',
    address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    symbol: 'LDO',
    url: 'https://lido.fi/',
    description:
      'Liquidity for staked assets. Daily rewards, no lock ups. Available for Ethereum, Solana, Terra & Kusama.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/lido.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'lido-dao',
    cmcId: '8000',
    category: 'Liquid Staking',
    chains: ['Ethereum', 'Terra', 'Solana', 'Moonriver'],
    module: 'lido/index.js',
    twitter: 'LidoFinance',
    audit_links: ['https://github.com/lidofinance/audits'],
    openSource: true,
    slug: 'lido',
    tvl: 18602267728.273186,
    chainTvls: {
      Ethereum: 9826502248.796364,
      Moonriver: 2902098.791086652,
      Solana: 366245547.40852475,
      Terra: 8406617833.277208,
    },
    change_1h: 0.17841698903463055,
    change_1d: 6.985991641367576,
    change_7d: 13.21638444960071,
    fdv: 3972257732,
    mcap: 658374376,
  },
  {
    id: '118',
    name: 'MakerDAO',
    address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    symbol: 'MKR',
    url: 'https://makerdao.com/',
    description: 'Builders of Dai, a digital currency that can be used by anyone, anywhere.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/makerdao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'maker',
    cmcId: '1518',
    category: 'CDP',
    chains: ['Ethereum'],
    module: 'maker/index.js',
    twitter: 'MakerDAO',
    audit_links: ['https://security.makerdao.com/'],
    oracles: ['Maker'],
    slug: 'makerdao',
    tvl: 17621292759.792496,
    chainTvls: { Ethereum: 17621292759.792496 },
    change_1h: 0.2911024261024835,
    change_1d: 1.3578451114954362,
    change_7d: 6.304667173309085,
    fdv: 2170923622,
    mcap: 1945825358,
  },
  {
    id: '294',
    name: 'Anchor',
    address: '-',
    symbol: 'ANC',
    url: 'https://anchorprotocol.com/',
    description: 'Money market on Terra',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/anchor.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'anchor-protocol',
    cmcId: '8857',
    category: 'Lending',
    chains: ['Terra', 'Avalanche'],
    module: 'anchor/index.js',
    twitter: 'anchor_protocol',
    audit_links: ['https://docs.anchorprotocol.com/security#audits'],
    oracles: ['Chainlink', 'Internal'],
    openSource: true,
    slug: 'anchor',
    tvl: 15076517183.401213,
    chainTvls: {
      Avalanche: 70130238.1959817,
      'Terra-borrowed': 2976430927.4933147,
      Terra: 15006386945.20523,
      borrowed: 2976430927.4933147,
    },
    change_1h: 0.03339506525732361,
    change_1d: 3.6651999671258153,
    change_7d: 5.765371075317432,
    fdv: 2815984328,
    mcap: 756313262,
  },
  {
    id: '111',
    name: 'AAVE',
    address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    symbol: 'AAVE',
    url: 'https://aave.com\r\n',
    description: 'Aave is an Open Source and Non-Custodial protocol to earn interest on deposits and borrow assets',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/aave.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'aave',
    cmcId: '7278',
    category: 'Lending',
    chains: ['Ethereum', 'Avalanche', 'Polygon', 'Arbitrum', 'Fantom', 'Optimism', 'Harmony'],
    module: 'aave/index.js',
    twitter: 'AaveAave',
    audit_links: ['https://aave.com/security'],
    oracles: ['Chainlink'],
    slug: 'aave',
    tvl: 14041007459.132307,
    chainTvls: {
      Optimism: 1639955.3516853964,
      Avalanche: 3052856287.930947,
      Harmony: 1406530.9797363756,
      'Harmony-borrowed': 1729219.5901421113,
      Ethereum: 9686557723.786728,
      'Ethereum-staking': 527861636.0415895,
      'Ethereum-borrowed': 4941077697.008688,
      Arbitrum: 7416730.609672641,
      borrowed: 7791756577.191046,
      Fantom: 3954798.6619167263,
      staking: 527861636.0415895,
      'Arbitrum-borrowed': 10143875.162985878,
      pool2: 252077753.40830275,
      'Polygon-borrowed': 447585712.41802543,
      'Ethereum-pool2': 252077753.40830275,
      'Fantom-borrowed': 5441219.859226495,
      'Optimism-borrowed': 2211821.140717246,
      Polygon: 1287175431.8116214,
      'Avalanche-borrowed': 2383567032.0112605,
    },
    change_1h: 0.567396444061103,
    change_1d: 2.4178458375294696,
    change_7d: 6.351106159722548,
    staking: 527861636.0415895,
    pool2: 252077753.40830275,
    fdv: 3026680031,
    mcap: 2583538603,
  },
  {
    id: '2',
    name: 'WBTC',
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    symbol: 'WBTC',
    url: 'https://wbtc.network/',
    description:
      'Wrapped Bitcoin (WBTC) is the first ERC20 token backed 1:1 with Bitcoin.\r\nCompletely transparent. 100% verifiable. Community led.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/wbtc.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'wrapped-bitcoin',
    cmcId: '3717',
    category: 'Bridge',
    chains: ['Ethereum'],
    module: 'wbtc.js',
    twitter: 'WrappedBTC',
    audit_links: ['https://wbtc.network/dashboard/audit'],
    oracles: [],
    slug: 'wbtc',
    tvl: 13120831067.619999,
    chainTvls: { Ethereum: 13120831067.619999 },
    change_1h: -0.08144512895478329,
    change_1d: 1.3300012707018425,
    change_7d: 11.699453373902884,
    fdv: 13148200360,
    mcap: 13148200360,
  },
  {
    id: '319',
    name: 'Convex Finance',
    address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
    symbol: 'CVX',
    url: 'https://www.convexfinance.com/',
    description: 'Convex simplifies your Curve-boosting experience to maximize your yields.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/convex-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'convex-finance',
    cmcId: '9903',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'convex/index.js',
    twitter: 'ConvexFinance',
    audit_links: [
      'https://github.com/convex-eth/platform/blob/main/audit/Convex%20Platform%20Security%20Audit%20Report.pdf',
    ],
    slug: 'convex-finance',
    tvl: 12982812486.631756,
    chainTvls: { Ethereum: 12982812486.631756, 'Ethereum-staking': 1301834218.1404536, staking: 1301834218.1404536 },
    change_1h: 0.15545736554570055,
    change_1d: 1.621686843346808,
    change_7d: 2.044827613605122,
    staking: 1301834218.1404536,
    fdv: 3133700865,
    mcap: 1735983647,
  },
  {
    id: '240',
    name: 'Polygon Bridge & Staking',
    address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    symbol: 'MATIC',
    url: 'https://polygon.technology/',
    description: 'Ethereum sidechain\r\n',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polygon.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'matic-network',
    cmcId: '3890',
    category: 'Chain',
    chains: ['Polygon'],
    module: 'polygon/index.js',
    twitter: '0xPolygon',
    slug: 'polygon-bridge-&-staking',
    tvl: 12486090486.585363,
    chainTvls: { Polygon: 12486090486.585363 },
    change_1h: 0.43310266028902333,
    change_1d: 1.0522649193287492,
    change_7d: null,
    fdv: 17271188879,
    mcap: 11870298417,
  },
  {
    id: '1',
    name: 'Uniswap',
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    symbol: 'UNI',
    url: 'https://info.uniswap.org/',
    description: 'A fully decentralized protocol for automated liquidity provision on Ethereum.\r\n',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/uniswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'uniswap',
    cmcId: '7083',
    category: 'Dexes',
    chains: ['Ethereum', 'Polygon', 'Arbitrum', 'Optimism'],
    module: 'uniswap/index.js',
    twitter: 'Uniswap',
    audit_links: [
      'https://github.com/Uniswap/uniswap-v3-core/tree/main/audits',
      'https://github.com/Uniswap/uniswap-v3-periphery/tree/main/audits',
      'https://github.com/ConsenSys/Uniswap-audit-report-2018-12',
    ],
    oracles: [],
    slug: 'uniswap',
    tvl: 7877727863.995713,
    chainTvls: {
      Optimism: 45123322.12594127,
      Ethereum: 7592854606.480607,
      Polygon: 150479908.17829254,
      Arbitrum: 89270027.21087272,
    },
    change_1h: 0.08430627047593475,
    change_1d: null,
    change_7d: 4.491266614437748,
    fdv: 11456218571,
    mcap: 5229644443,
  },
  {
    id: '114',
    name: 'Compound',
    address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    symbol: 'COMP',
    url: 'https://compound.finance',
    description:
      'Compound is an algorithmic, autonomous interest rate protocol built for developers, to unlock a universe of open financial applications.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/compound.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'compound-governance-token',
    cmcId: '5692',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'compound-onchain/index.js',
    twitter: 'compoundfinance',
    audit_links: ['https://compound.finance/docs/security'],
    oracles: ['Chainlink', 'TWAP'],
    slug: 'compound',
    tvl: 7322784364.785847,
    chainTvls: { Ethereum: 7322784364.785847, 'Ethereum-borrowed': 3796262370.996603, borrowed: 3796262370.996603 },
    change_1h: 0.6887479367074576,
    change_1d: 2.010292442211579,
    change_7d: 4.672427057090374,
    fdv: 1460648598,
    mcap: 972786994,
  },
  {
    id: '591',
    name: 'Multichain',
    address: '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
    symbol: 'MULTI',
    url: 'https://multichain.org/',
    description: 'Cross-Chain Router Protocol (CRP), envisioned to be the ultimate router for Web3.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/multichain.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'anyswap',
    cmcId: '5892',
    category: 'Bridge',
    chains: [
      'Fantom',
      'Avalanche',
      'Binance',
      'Ethereum',
      'Moonriver',
      'Arbitrum',
      'Harmony',
      'OKExChain',
      'Polygon',
      'xDai',
      'Telos',
      'Kucoin',
      'Heco',
      'Syscoin',
    ],
    module: 'anyswap/index.js',
    audit_links: [
      'https://github.com/anyswap/Anyswap-Audit/blob/master/TrailOfBits/Anyswap-CrossChain-Bridge-TrailofBits-Audit-Final%20Report.pdf',
      'https://github.com/anyswap/Anyswap-Audit/blob/master/SlowMist/AnySwap%20CrossChain-Bridge%20Security%20Audit%20Report.pdf',
      'https://github.com/anyswap/Anyswap-Audit/blob/master/SlowMist/Anyswap%20Smart%20Contract%20Security%20Audit.pdf',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Travala-AnyswapV5ERC20-v1.0.pdf',
    ],
    twitter: 'MultichainOrg',
    slug: 'multichain',
    tvl: 6476390345.249751,
    chainTvls: {
      OKExChain: 50914815.58908652,
      Moonriver: 268811255.026682,
      Avalanche: 564199958.1522269,
      Kucoin: 4970384.3781239055,
      Harmony: 55198962.26591929,
      Binance: 446964184.8543066,
      Ethereum: 414191617.2081494,
      xDai: 9260145.109747972,
      Heco: 1948514.8867698293,
      Syscoin: 1650908.9730113395,
      Polygon: 39896593.21766145,
      Telos: 7735162.609863937,
      Arbitrum: 267119266.1766599,
      Fantom: 4195632353.0756707,
    },
    change_1h: -0.1489532246866787,
    change_1d: 0.6892176669944803,
    change_7d: 2.71242813428681,
    fdv: 1307957105,
    mcap: 0,
  },
  {
    id: '120',
    name: 'Instadapp',
    address: '0x6f40d4a6237c257fff2db00fa0510deeecd303eb',
    symbol: 'INST',
    url: 'https://instadapp.io/',
    description:
      'Aims to drive interoperability between protocols for power users and ease of development allowing developers of all stripes to start leveraging the full potential of Decentralised Finance.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/instadapp.jpg',
    audits: null,
    audit_note: null,
    gecko_id: 'instadapp',
    cmcId: '10508',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'instadapp.js',
    twitter: 'Instadapp',
    audit_links: ['https://github.com/Instadapp/dsa-contracts/blob/master/audits/v1_PeckShield_Mar_2020.pdf'],
    slug: 'instadapp',
    tvl: 5373919315.544598,
    chainTvls: { Ethereum: 5373919315.544598 },
    change_1h: 0,
    change_1d: -0.027400000000014302,
    change_7d: null,
    mcap: 20846315,
  },
  {
    id: '194',
    name: 'PancakeSwap',
    address: 'bsc:0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    symbol: 'CAKE',
    url: 'https://pancakeswap.finance/',
    description: 'The #1 AMM and yield farm on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pancakeswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pancakeswap-token',
    cmcId: '1165',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'pancake-swap.js',
    twitter: 'PancakeSwap',
    audit_links: ['https://docs.pancakeswap.finance/#is-it-safe'],
    forkedFrom: ['Uniswap'],
    oracles: [],
    slug: 'pancakeswap',
    tvl: 5112011518.683993,
    chainTvls: { Binance: 5112011518.683993, 'Binance-staking': 1893581374.650366, staking: 1893581374.650366 },
    change_1h: -0.0048133055283159365,
    change_1d: 1.4854943756992753,
    change_7d: 10.028849477231773,
    staking: 1893581374.650366,
    mcap: 2430448967,
  },
  {
    id: '1541',
    name: 'Wormhole',
    address: null,
    symbol: '-',
    url: 'https://wormholenetwork.com',
    description: 'Wormhole is a generic cross-chain messaging protocol.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wormhole.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Ethereum', 'Terra', 'Fantom', 'Solana', 'Avalanche', 'Polygon', 'Binance', 'Oasis'],
    oracles: [],
    forkedFrom: [],
    module: 'wormhole.js',
    twitter: 'wormholecrypto',
    audit_links: ['https://github.com/certusone/wormhole/blob/dev.v2/audits/2021-01-10_neodyme.pdf'],
    listedAt: 1647148275,
    slug: 'wormhole',
    tvl: 4565178264.21,
    chainTvls: {
      Terra: 1281558552.49,
      Binance: 25102328.31,
      Ethereum: 2949600194.46,
      Oasis: 3020.1,
      Avalanche: 26961450.43,
      Polygon: 26135603.46,
      Solana: 34778102.16,
      Fantom: 221039013.26,
    },
    change_1h: 16.715915059086228,
    change_1d: 26.95565647508886,
    change_7d: 58.19739287050615,
  },
  {
    id: '119',
    name: 'SushiSwap',
    address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    symbol: 'SUSHI',
    url: 'https://sushi.com/',
    description: 'A fully decentralized protocol for automated liquidity provision on Ethereum.\r\n',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/sushiswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'sushi',
    cmcId: '6758',
    category: 'Dexes',
    chains: [
      'Ethereum',
      'Arbitrum',
      'Polygon',
      'Fantom',
      'Harmony',
      'Binance',
      'Avalanche',
      'Moonriver',
      'Celo',
      'xDai',
      'Telos',
      'Fuse',
      'OKExChain',
      'Palm',
      'Heco',
    ],
    module: 'sushiswap/index.js',
    twitter: 'SushiSwap',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-SushiSwap-v1.0.pdf',
    ],
    forkedFrom: ['Uniswap'],
    oracles: [],
    slug: 'sushiswap',
    tvl: 3781881069.2188597,
    chainTvls: {
      Moonriver: 23112070.436405294,
      Avalanche: 29265871.283490688,
      'xDai-borrowed': 0,
      Harmony: 48052334.91503972,
      Binance: 39703363.3757133,
      Ethereum: 2643148891.9311395,
      xDai: 16275350.697155712,
      Heco: 147.11431931156233,
      'Ethereum-staking': 257382294.6022621,
      'Ethereum-borrowed': 6643935280.998565,
      Telos: 3404709.6955945375,
      Celo: 18004035.340418383,
      Arbitrum: 640824407.6576673,
      borrowed: 6688352445.970786,
      Fuse: 1033863.5265594543,
      Fantom: 84669255.82865512,
      staking: 257382294.6022621,
      OKExChain: 50916.05409646434,
      'Arbitrum-borrowed': 51609.74896471678,
      'Polygon-borrowed': 44365408.248898625,
      'Binance-borrowed': 146.97435791989366,
      Palm: 595.7700786343277,
      'Fantom-borrowed': 0,
      Polygon: 234335255.59252596,
    },
    change_1h: 0.12808366328147258,
    change_1d: 2.3760659206869263,
    change_7d: 5.988136106265628,
    staking: 257382294.6022621,
    fdv: 1029290571,
    mcap: 793744653,
  },
  {
    id: '1571',
    name: 'Stargate',
    address: '0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6',
    symbol: 'STG',
    url: 'https://stargate.finance/',
    description:
      'Stargate is a fully composable liquidity transport protocol that lives at the heart of Omnichain DeFi. With Stargate, users & dApps can transfer native assets cross-chain while accessing the protocol’s unified liquidity pools with instant guaranteed finality.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/stargate.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'stargate-finance',
    cmcId: '18934',
    category: 'Bridge',
    chains: ['Ethereum', 'Avalanche', 'Binance', 'Polygon', 'Arbitrum', 'Fantom', 'Optimism'],
    oracles: [],
    forkedFrom: [],
    module: 'stargatefinance/index.js',
    twitter: 'StargateFinance',
    listedAt: 1647975344,
    slug: 'stargate',
    tvl: 3475301498.157185,
    chainTvls: {
      Optimism: 34626111.36826541,
      Avalanche: 769089168.3817369,
      Binance: 681402446.5084257,
      Ethereum: 1039226142.9933476,
      Polygon: 454870453.1645261,
      Arbitrum: 296065262.78128755,
      Fantom: 200021912.9595957,
    },
    change_1h: -0.5859600561951623,
    change_1d: -3.7760770117739355,
    change_7d: null,
    fdv: 2739353799,
    mcap: 0,
  },
  {
    id: '116',
    name: 'Balancer',
    address: '0xba100000625a3754423978a60c9317c58a424e3d',
    symbol: 'BAL',
    url: 'https://balancer.finance/',
    description: 'Balancer is a protocol for programmable liquidity.\r\n\r\n\r\n',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/balancer.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'balancer',
    cmcId: '5728',
    category: 'Dexes',
    chains: ['Ethereum', 'Polygon', 'Arbitrum'],
    module: 'balancer/index.js',
    twitter: 'BalancerLabs',
    audit_links: ['https://docs.balancer.fi/core-concepts/security/audits'],
    slug: 'balancer',
    tvl: 3105529131.4921603,
    chainTvls: { Ethereum: 2763414142.1883526, Polygon: 255897967.56385863, Arbitrum: 86217021.73994938 },
    change_1h: 0.3658976718683249,
    change_1d: -0.11798188581579439,
    change_7d: 3.164415322188674,
    fdv: 1501866456,
    mcap: 162199450,
  },
  {
    id: '113',
    name: 'Yearn Finance',
    address: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
    symbol: 'YFI',
    url: 'https://yearn.finance/',
    description:
      'Yearn.finance is an aggregator service for decentralized finance (DeFi) investors, using automation to allow them to maximize profits from yield farming.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/yearn-finance.png',
    audits: '1',
    audit_note: null,
    gecko_id: 'yearn-finance',
    cmcId: '5864',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Fantom', 'Arbitrum'],
    module: 'yearn/index.js',
    twitter: 'iearnfinance',
    audit_links: ['https://github.com/yearn/yearn-audits'],
    language: 'Vyper',
    slug: 'yearn-finance',
    tvl: 3051161135.812604,
    chainTvls: { Ethereum: 2816037291.2507854, Arbitrum: 1031636.5603131003, Fantom: 234092208.0015052 },
    change_1h: 0,
    change_1d: 0.679804348040534,
    change_7d: 0.44615879939775027,
    fdv: 846963697,
    mcap: 824479289,
  },
  {
    id: '611',
    name: 'Neutrino',
    address: 'waves:6nSpVyNH7yM69eg446wrQR94ipbbcmZMU1ENPwanC97g',
    symbol: 'NSBT',
    url: 'https://neutrino.at',
    description:
      'The ​Neutrino protocol is an algorithmic price-stable assetization protocol that enables the creation of stable coins tied to real-world assets or cryptocurrency.',
    chain: 'Waves',
    logo: 'https://icons.llama.fi/neutrino.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'neutrino-system-base-token',
    cmcId: '7320',
    category: 'Algo-Stables',
    chains: ['Waves'],
    module: 'neutrino/index.js',
    twitter: 'neutrino_proto',
    audit_links: ['https://audit.neutrino.at'],
    slug: 'neutrino',
    tvl: 2996408019.3811545,
    chainTvls: { Waves: 2996408019.3811545 },
    change_1h: -5.119057213117344,
    change_1d: 55.14497493562942,
    change_7d: 60.6448178836099,
    mcap: 107156780,
  },
  {
    id: '347',
    name: 'Abracadabra',
    address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    symbol: 'SPELL',
    url: 'https://abracadabra.money/',
    description:
      'Abracadabra.money is a spell book that allows users to produce magic internet money ($MIM) which is a stable coin that you can swap for any other traditional stable coin.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/abracadabra.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'spell-token',
    cmcId: '11289',
    category: 'CDP',
    chains: ['Ethereum', 'Arbitrum', 'Fantom', 'Avalanche'],
    module: 'abracadabra/api.js',
    twitter: 'MIM_Spell',
    oracles: ['Chainlink'],
    audit_links: ['https://www.certora.com/pubs/SushiBentoboxFeb2021.pdf'],
    slug: 'abracadabra',
    tvl: 2569000815.1207943,
    chainTvls: {
      Ethereum: 2437666081.6377664,
      Avalanche: 14660337.920090655,
      Arbitrum: 86425969.950169,
      Fantom: 30248425.61276814,
    },
    change_1h: -0.05521539443391532,
    change_1d: 1.2372987013117296,
    change_7d: -2.155416468169676,
    fdv: 932241159,
    mcap: 409592053,
  },
  {
    id: '359',
    name: 'Frax',
    address: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
    symbol: 'FXS',
    url: 'https://frax.finance/',
    description:
      'Inventors of the fractional stablecoin. $FRAX is the 1st stablecoin with parts backed & parts algorithmic',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/frax.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'frax-share',
    cmcId: '6953',
    category: 'Algo-Stables',
    chains: ['Ethereum', 'Fantom'],
    module: 'fraxfinance/index.js',
    twitter: 'fraxfinance',
    audit_links: ['https://www.certik.org/projects/fraxfinance'],
    oracles: ['Chainlink'],
    slug: 'frax',
    tvl: 2292602833.8228807,
    chainTvls: {
      pool2: 106322542.02577233,
      'Ethereum-pool2': 106322542.02577233,
      'Ethereum-Treasury': 716091827.2220296,
      Ethereum: 2186250401.7588387,
      'Ethereum-staking': 938408094.0591873,
      Treasury: 716091827.2220296,
      Fantom: 106352432.064042,
      staking: 938408094.0591873,
    },
    change_1h: -0.11535958225866239,
    change_1d: 0.027125772832945927,
    change_7d: -0.3034000200323419,
    staking: 938408094.0591873,
    pool2: 106322542.02577233,
    fdv: 2144202753,
    mcap: 1247532706,
  },
  {
    id: '494',
    name: 'JustLend',
    address: 'tron:TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9',
    symbol: 'JST',
    url: 'https://justlend.just.network',
    description:
      'JustLend is a TRON-powered coin market protocol aimed at establishing fund pools whose interest rates are determined by an algorithm based on the supply and demand of TRON assets.',
    chain: 'Tron',
    logo: 'https://icons.llama.fi/justlend.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'just',
    cmcId: '5488',
    category: 'Lending',
    chains: ['Tron'],
    module: 'justlend.js',
    twitter: 'DeFi_JUST',
    oracles: ['WINkLink'],
    slug: 'justlend',
    tvl: 2090907694.3741128,
    chainTvls: { pool2: 0, Tron: 2090907694.3741128, 'Tron-pool2': 0 },
    change_1h: -0.18960496396277904,
    change_1d: 0.6906048603414945,
    change_7d: 8.475094248357422,
    pool2: 0,
    fdv: 605417718,
    mcap: 446445116,
  },
  {
    id: '132',
    name: 'hBTC',
    address: '0x0316EB71485b0Ab14103307bf65a021042c6d380',
    symbol: 'hBTC',
    url: 'https://www.hbtc.finance/ ',
    description:
      'HBTC token aims to promote the growth of the decentralized marketplace by injecting Bitcoin (BTC), the largest and most liquid asset, into the decentralized finance (DeFi) eco-system.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/hbtc.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'huobi-btc',
    cmcId: '478',
    category: 'Bridge',
    chains: ['Ethereum'],
    module: 'hbtc.js',
    twitter: 'HBTC_official',
    audit_links: ['https://www.htokens.finance/static/pdf/audit-report-en.pdf'],
    slug: 'hbtc',
    tvl: 1907496010.0800002,
    chainTvls: { Ethereum: 1907496010.0800002 },
    change_1h: -0.12321186175211096,
    change_1d: 1.4014629492208286,
    change_7d: 11.347550754330427,
    fdv: 1899186121,
    mcap: 1899186121,
  },
  {
    id: '212',
    name: 'Venus',
    address: 'bsc:0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
    symbol: 'XVS',
    url: 'https://venus.io/',
    description: 'A Decentralized Marketplace for Lenders and Borrowers with Borderless Stablecoins.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/venus.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'venus',
    cmcId: '7288',
    category: 'Lending',
    chains: ['Binance'],
    module: 'venus.js',
    twitter: 'VenusProtocol',
    audit_links: ['https://www.certik.org/projects/swipe'],
    forkedFrom: ['Compound'],
    oracles: ['Chainlink'],
    slug: 'venus',
    tvl: 1796120993.4657347,
    chainTvls: { Binance: 1796120993.4657347, 'Binance-borrowed': 908790377.5199418, borrowed: 908790377.5199418 },
    change_1h: 0.13708950035831435,
    change_1d: 0.5740547184870195,
    change_7d: 8.61869991027963,
    fdv: 333341989,
    mcap: 143071158,
  },
  {
    id: '383',
    name: 'Osmosis',
    address: '-',
    symbol: 'OSMO',
    url: 'https://osmosis.zone/',
    description:
      'Osmosis is an advanced automated market maker (AMM) protocol that allows developers to build customized AMMs with sovereign liquidity pools.',
    chain: 'Osmosis',
    logo: 'https://icons.llama.fi/osmosis.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'osmosis',
    cmcId: null,
    category: 'Dexes',
    chains: ['Osmosis'],
    module: 'osmosis.js',
    twitter: 'osmosiszone',
    slug: 'osmosis',
    tvl: 1714627681.252668,
    chainTvls: { Osmosis: 1714627681.252668 },
    change_1h: -0.17644524833342246,
    change_1d: 2.3751448907784862,
    change_7d: 2.3366578585554834,
    fdv: 8537518132,
    mcap: 2832572968,
  },
  {
    id: '270',
    name: 'Liquity',
    address: '0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D',
    symbol: 'LQTY',
    url: 'https://www.liquity.org/',
    description:
      'Liquity is a decentralized borrowing protocol that allows you to draw 0% interest loans against Ether used as collateral.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/liquity.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'liquity',
    cmcId: '7429',
    category: 'CDP',
    chains: ['Ethereum'],
    module: 'liquity/index.js',
    twitter: 'LiquityProtocol',
    audit_links: [
      'https://github.com/trailofbits/publications/blob/master/reviews/Liquity.pdf',
      'https://www.coinspect.com/liquity-audit/',
    ],
    oracles: ['Chainlink'],
    slug: 'liquity',
    tvl: 1633840549.512805,
    chainTvls: { Ethereum: 1633840549.512805 },
    change_1h: 0.4738425532789847,
    change_1d: 3.153948670676982,
    change_7d: 13.810982783718089,
    fdv: 280260042,
    mcap: 49777316,
  },
  {
    id: '468',
    name: 'Trader Joe',
    address: 'avax:0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd',
    symbol: 'JOE',
    url: 'https://www.traderjoexyz.com',
    description: 'Trader Joe is your one-stop decentralized trading platform on the Avalanche network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/trader-joe.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'joe',
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'traderjoe/index.js',
    audit_links: ['https://docs.traderjoexyz.com/main/security-and-contracts/audits'],
    twitter: 'traderjoe_xyz',
    forkedFrom: ['Uniswap', 'Compound'],
    slug: 'trader-joe',
    tvl: 1494059783.5070653,
    chainTvls: {
      'Avalanche-staking': 14974024.645449731,
      Avalanche: 1494059783.5070653,
      borrowed: 236267257.32663637,
      staking: 14974024.645449731,
      'Avalanche-borrowed': 236267257.32663637,
    },
    change_1h: -0.006869933249760152,
    change_1d: 3.757351344680515,
    change_7d: -1.385359002209924,
    staking: 14974024.645449731,
    fdv: 583304927,
    mcap: 229065558,
  },
  {
    id: '1052',
    name: 'Astroport',
    address: 'terra:terra1xj49zyqrwpv5k928jwfpfy2ha668nwdgkwlrg3',
    symbol: 'ASTRO',
    url: 'https://astroport.fi',
    description: 'The meta AMM of Terra',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/astroport.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'astroport',
    cmcId: null,
    category: 'Dexes',
    chains: ['Terra'],
    module: 'astroport/index.js',
    twitter: 'astroport_fi',
    audit_links: ['https://github.com/astroport-fi/astro-audits'],
    openSource: true,
    listedAt: 1639915230,
    slug: 'astroport',
    tvl: 1445328079.8259203,
    chainTvls: { Terra: 1445328079.8259203 },
    change_1h: 1.4844432365092217,
    change_1d: 9.22469946903945,
    change_7d: 12.958472273753443,
    fdv: 2929500112,
    mcap: 374390226,
  },
  {
    id: '694',
    name: 'JustCryptos',
    address: 'tron:TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9',
    symbol: 'JST',
    url: 'https://just.network/token',
    description:
      'Just Cryptos builds a bridge that connects premium crypto currencies from the TRON network and other public chains, and introduces token value to TRON to power the JUST ecosystem. All assets are stored, and can be swapped on Poloniex.',
    chain: 'Tron',
    logo: 'https://icons.llama.fi/justcryptos.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Tron'],
    module: 'justCryptos/index.js',
    twitter: 'DeFi_JUST',
    listedAt: 1634850489,
    slug: 'justcryptos',
    tvl: 1444526740,
    chainTvls: { Tron: 1444526740 },
    change_1h: -0.05177713361804592,
    change_1d: 1.873896172337595,
    change_7d: 12.38295653306642,
  },
  {
    id: '117',
    name: 'RenVM',
    address: '0x408e41876cccdc0f92210600ef50372656052a38',
    symbol: 'REN',
    url: 'https://renproject.io/',
    description:
      'An open protocol providing access to inter-blockchain liquidity for all decentralized applications. Bring BTC, BCH and ZEC to your Ethereum app.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/renvm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'republic-protocol',
    cmcId: '2539',
    category: 'Bridge',
    chains: ['Ethereum', 'Fantom', 'Solana', 'Arbitrum', 'Polygon', 'Binance', 'Avalanche'],
    module: 'ren/index.js',
    twitter: 'renprotocol',
    audit_links: ['https://github.com/renproject/ren/wiki/Audits'],
    oracles: [],
    slug: 'renvm',
    tvl: 1435062973.68447,
    chainTvls: {
      Avalanche: 6925822.071708416,
      Binance: 13733647.59215122,
      Ethereum: 783074375.9972414,
      Polygon: 28704109.147786513,
      Solana: 71415331.97778592,
      Arbitrum: 30161807.06209184,
      Fantom: 501047879.8357046,
    },
    change_1h: 0.09013811028870578,
    change_1d: 0.7501451689751377,
    change_7d: 10.961920984225685,
    fdv: 450270491,
    mcap: 450270491,
  },
  {
    id: '831',
    name: 'VVS Finance',
    address: 'cronos:0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03',
    symbol: 'VVS',
    url: 'https://vvs.finance',
    description:
      'VVS is designed to be the simplest DeFi platform for users to swap tokens, earn high yields, and most importantly have fun!',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/vvs-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'vvs-finance',
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'vvs-finance/index.js',
    twitter: 'VVS_finance',
    audit_links: ['https://docs.vvs.finance/fundamentals/smart-contracts-and-security'],
    forkedFrom: ['Uniswap'],
    listedAt: 1636736668,
    slug: 'vvs-finance',
    tvl: 1358062987.2598748,
    chainTvls: { Cronos: 1358062987.2598748 },
    change_1h: 0.13033697896575802,
    change_1d: 0.4009736435769611,
    change_7d: 1.3039201155071254,
    fdv: 2812647512,
    mcap: 25652158,
  },
  {
    id: '467',
    name: 'Benqi',
    address: 'avax:0x8729438eb15e2c8b576fcc6aecda6a148776c0f5',
    symbol: 'QI',
    url: 'https://benqi.fi',
    description:
      'BENQI is a non-custodial liquidity market protocol, built on Avalanche. The protocol enables users to effortlessly lend, borrow, and earn interest with their digital assets.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/benqi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'benqi',
    cmcId: '9288',
    category: 'Lending',
    chains: ['Avalanche'],
    module: 'benqi/index.js',
    twitter: 'BenqiFinance',
    audit_links: ['https://docs.benqi.fi/risks#audits'],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'benqi',
    tvl: 1332370291.9033518,
    chainTvls: {
      pool2: 17155636.96075899,
      Avalanche: 1332370291.9033518,
      'Avalanche-pool2': 17155636.96075899,
      borrowed: 1173198364.0035336,
      'Avalanche-borrowed': 1173198364.0035336,
    },
    change_1h: 0.41637136974497935,
    change_1d: 1.301001437187253,
    change_7d: 5.6423992455123795,
    pool2: 17155636.96075899,
    fdv: 462768603,
    mcap: 108932306,
  },
  {
    id: '1556',
    name: 'Terra Bridge',
    address: null,
    symbol: '-',
    url: 'https://bridge.terra.money',
    description:
      'Terra Bridge enables cross-chain transfer of all tokens supported by Shuttle, including Terra native tokens, most mAssets and also other token types from Terra ecosystem.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/terra-bridge.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Ethereum', 'Binance', 'Harmony'],
    oracles: [],
    forkedFrom: [],
    module: 'terra-bridge/index.js',
    twitter: 'terra_money',
    listedAt: 1647460167,
    slug: 'terra-bridge',
    tvl: 1316815401.7665203,
    chainTvls: { Binance: 63886798.75944873, Ethereum: 1231623567.4924536, Harmony: 21305035.514617894 },
    change_1h: 0.7284730377159292,
    change_1d: 5.622802933538381,
    change_7d: 5.637391449183468,
  },
  {
    id: '691',
    name: 'JustStables',
    address: 'tron:TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT',
    symbol: 'USDJ',
    url: 'https://juststable.tronscan.org/#/login',
    description: 'JustStable is a decentralized finance system of USDJ stablecoin for everyone, anywhere, anytime.',
    chain: 'Tron',
    logo: 'https://icons.llama.fi/juststables.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'just-stablecoin',
    cmcId: '5446',
    category: 'Algo-Stables',
    chains: ['Tron'],
    module: 'juststable/index.js',
    twitter: 'DeFi_JUST',
    audit_links: ['https://just.tronscan.org/docs/audit-report-just_cn.pdf'],
    listedAt: 1634757569,
    oracles: ['WINkLink'],
    slug: 'juststables',
    tvl: 1253957016.1579611,
    chainTvls: { Tron: 1253957016.1579611 },
    change_1h: 0.5239510415639757,
    change_1d: 0.26694861779267853,
    change_7d: 13.099887629174972,
    mcap: 0,
  },
  {
    id: '162',
    name: 'Bancor',
    address: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
    symbol: 'BNT',
    url: 'https://app.bancor.network/',
    description:
      'Bancor is an on-chain liquidity protocol that enables automated, decentralized exchange on Ethereum and across blockchains.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bancor.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bancor',
    cmcId: '1727',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'bancor/index.js',
    twitter: 'Bancor',
    audit_links: ['https://docs.bancor.network/ethereum-contracts/security'],
    slug: 'bancor',
    tvl: 1238015109.7184446,
    chainTvls: { Ethereum: 1238015109.7184446 },
    change_1h: 0.7644340651696808,
    change_1d: 2.28881762930871,
    change_7d: 12.977810788368856,
    fdv: 727245079,
    mcap: 727245079,
  },
  {
    id: '612',
    name: 'Vires Finance',
    address: 'waves:DSbbhLsSTeDg5Lsiufk2Aneh3DjVqJuPr2M9uU1gwy5p',
    symbol: 'VIRES',
    url: 'https://vires.finance',
    description:
      'Vires.finance is is a decentralized non-custodial liquidity protocol based on Waves Blockchain, where users, wallets and dapps can participate as depositors or borrowers.',
    chain: 'Waves',
    logo: 'https://icons.llama.fi/vires-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Waves'],
    module: 'vires/index.js',
    twitter: 'viresfinance',
    audit_links: [
      'https://github.com/deemru/viresfinance-audit/releases/download/audit/viresfinance-security-audit.en.pdf',
    ],
    slug: 'vires-finance',
    tvl: 1187525277.6115077,
    chainTvls: { Waves: 1187525277.6115077, borrowed: 590419860.9708747, 'Waves-borrowed': 590419860.9708747 },
    change_1h: -1.0400496159212338,
    change_1d: 20.993117681218678,
    change_7d: 25.685349593986672,
  },
  {
    id: '115',
    name: 'Synthetix',
    address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    symbol: 'SNX',
    url: 'https://synthetix.io',
    description:
      'Synthetix is a derivatives liquidity protocol providing the backbone for derivatives trading in DeFi.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/synthetix.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'havven',
    cmcId: '2586',
    category: 'Derivatives',
    chains: ['Ethereum', 'Optimism'],
    module: 'synthetix/index.js',
    twitter: 'synthetix_io',
    audit_links: ['https://docs.synthetix.io/contracts/audits/'],
    oracles: ['Chainlink'],
    slug: 'synthetix',
    tvl: 1185929709.706149,
    chainTvls: { Optimism: 171507573.11054, Ethereum: 1014422136.5956092 },
    change_1h: 0.9931373072618044,
    change_1d: 2.232192173414944,
    change_7d: null,
    fdv: 1431990823,
    mcap: 1242548958,
  },
  {
    id: '1028',
    name: 'MM Finance',
    address: 'cronos:0x97749c9B61F878a880DfE312d2594AE07AEd7656',
    symbol: 'MMF',
    url: 'https://mm.finance',
    description: '1st AMM & DEX on Cronos Chain that offers fee rebate via trading mining',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/mm-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'mmfinance',
    cmcId: '1572',
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'mm-finance/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'MMFcrypto',
    audit_links: ['https://www.certik.com/projects/mmfinance'],
    listedAt: 1639599260,
    slug: 'mm-finance',
    tvl: 1077035504.722121,
    chainTvls: { Cronos: 1077035504.722121, 'Cronos-staking': 27637336.738538466, staking: 27637336.738538466 },
    change_1h: -1.4729396638428938,
    change_1d: 2.661086069937113,
    change_7d: 17.936321020300895,
    staking: 27637336.738538466,
    fdv: 639934380,
    mcap: 231697840,
  },
  {
    id: '690',
    name: 'SUNSwap',
    address: 'tron:TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S',
    symbol: 'SUN',
    url: 'https://sunswap.com/#/home',
    description:
      'SUNSwap is a TRON-based decentralized trading protocol for automated liquidity provision and an open financial market accessible to all.',
    chain: 'Tron',
    logo: 'https://icons.llama.fi/sunswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '1116',
    category: 'Dexes',
    chains: ['Tron'],
    module: 'justSwap/index.js',
    twitter: 'DeFi_JUST',
    audit_links: ['https://justswap.io/docs/audit-report_cn.pdf'],
    listedAt: 1634757407,
    slug: 'sunswap',
    tvl: 1048669196.676995,
    chainTvls: { Tron: 1048669196.676995 },
    change_1h: -0.16653005118755004,
    change_1d: 0.06980892238426861,
    change_7d: 10.099122333512184,
  },
  {
    id: '144',
    name: 'dYdX',
    address: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
    symbol: 'DYDX',
    url: 'https://dydx.exchange/',
    description:
      'The most powerful open trading platform for crypto assets. Margin trade, borrow, and lend cryptocurrency.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/dydx.jpg',
    audits: null,
    audit_note: null,
    gecko_id: 'dydx',
    cmcId: '516',
    category: 'Derivatives',
    chains: ['Ethereum'],
    module: 'dydx/index.js',
    twitter: 'dydxprotocol',
    audit_links: ['https://docs.dydx.exchange/#independent-audits'],
    oracles: ['Chainlink'],
    language: 'Cairo',
    slug: 'dydx',
    tvl: 1001171813.3435776,
    chainTvls: { Ethereum: 1001171813.3435776 },
    change_1h: -0.043382339982173335,
    change_1d: -1.0468932650666716,
    change_7d: 0.326389214204454,
    fdv: 5886516873,
    mcap: 528228924,
  },
  {
    id: '326',
    name: 'Beefy Finance',
    address: 'bsc:0xca3f508b8e4dd382ee878a314789373d80a5190a',
    symbol: 'BIFI',
    url: 'https://app.beefy.finance',
    description:
      'Beefy Finance is a Decentralized, Multi-Chain Yield Optimizer platform that allows its users to earn compound interest on their crypto holdings. Through a set of investment strategies secured and enforced by smart contracts, Beefy Finance automatically maximizes the user rewards from various liquidity pools (LPs),‌ ‌automated market making (AMM) projects,‌ ‌and‌ ‌other yield‌ farming ‌opportunities in the DeFi ecosystem.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/beefy-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'beefy-finance',
    cmcId: '7311',
    category: 'Yield Aggregator',
    chains: [
      'Fantom',
      'Avalanche',
      'Binance',
      'Polygon',
      'Cronos',
      'Arbitrum',
      'Metis',
      'Fuse',
      'Moonbeam',
      'Moonriver',
      'Aurora',
      'Celo',
      'Harmony',
      'Heco',
    ],
    module: 'beefy/index.js',
    twitter: 'beefyfinance',
    audit_links: ['https://github.com/beefyfinance/beefy-audits'],
    slug: 'beefy-finance',
    tvl: 965261991.3445114,
    chainTvls: {
      Metis: 18182158.95935688,
      'Celo-staking': 45545.66105016001,
      Moonriver: 2366690.86435284,
      Avalanche: 256137756.47577134,
      'Harmony-staking': 57910.51241172,
      'Fuse-staking': 228429.50721276,
      'Fantom-staking': 47502938.44462524,
      'Cronos-staking': 5916673.5747066,
      Harmony: 1562834.8877472,
      'Moonriver-staking': 63706.64297280001,
      Binance: 140001599.09360844,
      Heco: 503201.917854,
      'Binance-staking': 26517138.102295924,
      'Moonbeam-staking': 407071.4167626,
      Celo: 2162435.3624796,
      Arbitrum: 19647549.40799304,
      Fuse: 4682957.832057,
      Fantom: 344081793.1916364,
      staking: 96402267.69981876,
      'Aurora-staking': 181288.02237612,
      'Avalanche-staking': 8206486.888732441,
      Cronos: 39484789.63033308,
      'Arbitrum-staking': 1298405.20354668,
      'Heco-staking': 10548.36744876,
      Moonbeam: 2843721.2741071205,
      Aurora: 2211350.1724986,
      Polygon: 131393152.27471586,
      'Metis-staking': 942964.08936204,
      'Polygon-staking': 5023161.26631492,
    },
    change_1h: -0.10996246274935118,
    change_1d: -0.13238891926366136,
    change_7d: null,
    staking: 96402267.69981876,
    fdv: 119103489,
    mcap: 116125901,
  },
  {
    id: '302',
    name: 'SpookySwap',
    address: 'fantom:0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    symbol: 'BOO',
    url: 'https://spookyswap.finance/',
    description: 'Automated Market Maker.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/spookyswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'spookyswap',
    cmcId: '9608',
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'spookyswap/index.js',
    twitter: 'SpookySwap',
    audit_links: ['https://www.certik.org/projects/spookyswap'],
    forkedFrom: ['Uniswap'],
    slug: 'spookyswap',
    tvl: 946283120.5794107,
    chainTvls: { 'Fantom-staking': 71288180.81434183, staking: 71288180.81434183, Fantom: 946283120.5794107 },
    change_1h: 1.2993381462962361,
    change_1d: -0.0721161778334789,
    change_7d: 12.67365833850684,
    staking: 71288180.81434183,
    fdv: 197094958,
    mcap: 131614143,
  },
  {
    id: '136',
    name: 'Rari Capital',
    address: '0xD291E7a03283640FDc51b121aC401383A46cC623',
    symbol: 'RGT',
    url: 'https://rari.capital/',
    description:
      'Rari Capital aims to deliver the best yield to all users with various risk tolerances. Easy, sustainable and built for the future.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/rari-capital.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'rari-governance-token',
    cmcId: '7486',
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'rari/index.js',
    twitter: 'RariCapital',
    audit_links: ['https://www.notion.so/Rari-Capital-Audit-Quantstamp-December-2020-24a1d1df94894d6881ee190686f47bc7'],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'rari-capital',
    tvl: 899706621.2306299,
    chainTvls: {
      Ethereum: 899706621.2306299,
      pool2: 16022190.697033122,
      'Ethereum-borrowed': 164272543.19891483,
      borrowed: 164272543.19891483,
      'Ethereum-pool2': 16022190.697033122,
    },
    change_1h: 0.6203596707666179,
    change_1d: 2.2565447622404236,
    change_7d: 12.387901217574537,
    pool2: 16022190.697033122,
    fdv: 198425291,
    mcap: 178883983,
  },
  {
    id: '345',
    name: 'Alpaca Finance',
    address: 'bsc:0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    symbol: 'ALPACA',
    url: 'https://www.alpacafinance.org/',
    description: 'The 1st leveraged yield farming protocol on BSC',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/alpaca-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'alpaca-finance',
    cmcId: '8707',
    category: 'Yield',
    chains: ['Binance', 'Fantom'],
    module: 'alpaca-finance/index.js',
    twitter: 'AlpacaFinance',
    audit_links: ['https://github.com/alpaca-finance/bsc-alpaca-contract/tree/main/audits'],
    slug: 'alpaca-finance',
    tvl: 894496223.0733032,
    chainTvls: {
      Binance: 858412124.9541749,
      'Binance-staking': 26858468.611187857,
      staking: 26858468.611187857,
      Fantom: 36084098.119128376,
    },
    change_1h: -0.05964673686509059,
    change_1d: 5.139533851106549,
    change_7d: 10.521875639784199,
    staking: 26858468.611187857,
    fdv: 92422201,
    mcap: 75385851,
  },
  {
    id: '1166',
    name: 'DefiChain DEX',
    address: '-',
    symbol: 'DFI',
    url: 'https://defichain.com/dex',
    description: 'Swap between DFI and wrapped tokens in a completely decentralized, exchange.',
    chain: 'DefiChain',
    logo: 'https://icons.llama.fi/defichain.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['DefiChain'],
    module: 'defichain-dex.js',
    twitter: 'defichain',
    audit_links: ['https://defichain.com/security/'],
    slug: 'defichain-dex',
    tvl: 887431261.2091757,
    chainTvls: { DefiChain: 887431261.2091757 },
    change_1h: 0.3400565361394001,
    change_1d: 2.189788327500338,
    change_7d: 14.911111554421552,
  },
  {
    id: '306',
    name: 'Quickswap',
    address: 'polygon:0x831753dd7087cac61ab5644b308642cc1c33dc13',
    symbol: 'QUICK',
    url: 'https://quickswap.exchange/',
    description: 'AMM',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/quickswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'quick',
    cmcId: '8206',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'quickswap/index.js',
    twitter: 'QuickswapDEX',
    audit_links: ['https://contractsecurity.io/quick-audit-report/'],
    forkedFrom: ['Uniswap'],
    slug: 'quickswap',
    tvl: 885202292.7007916,
    chainTvls: { Polygon: 885202292.7007916 },
    change_1h: 0.18280703086186634,
    change_1d: 1.2282252988072457,
    change_7d: 10.075945163001563,
    fdv: 232177449,
    mcap: 83816059,
  },
  {
    id: '587',
    name: 'Maple',
    address: '0x33349b282065b0284d756f0577fb39c158f935e6',
    symbol: 'MPL',
    url: 'https://www.maple.finance',
    description: 'Maple Finance is an institutional capital marketplace powered by blockchain technology.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/maple.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'maple',
    cmcId: '9417',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'maple/index.js',
    twitter: 'maplefinance',
    audit_links: ['https://github.com/maple-labs/maple-core#audit-reports'],
    slug: 'maple',
    tvl: 868480227.8151618,
    chainTvls: { 'Ethereum-Treasury': 1685467.8590770017, Ethereum: 868480227.8151618, Treasury: 1685467.8590770017 },
    change_1h: 0.15381047089904598,
    change_1d: 0.3931753065586179,
    change_7d: 14.692182903171641,
    fdv: 443005414,
    mcap: 206084776,
  },
  {
    id: '1044',
    name: 'Stader',
    address: '0x30d20208d987713f46dfd34ef128bb16c404d10f',
    symbol: 'SD',
    url: 'https://staderlabs.com',
    description: 'Stader offers the most convenient & safest way to maximize your returns on staking.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/stader.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'stader',
    cmcId: '12623',
    category: 'Liquid Staking',
    chains: ['Terra'],
    module: 'stader/index.js',
    twitter: 'staderlabs',
    audit_links: [
      'https://staderlabs-docs.s3.amazonaws.com/audits/terra/Cryptonics_Audit_Report_Terra_V1.pdf',
      'https://kp5hfcaxs2prkdoscwibtrbjjddvoov3huhezsxknanxfaucm76a.arweave.net/U_pyiBeWnxUN0hWQGcQpSMdXOrs9DkzK6mgbcoKCZ_w',
      'https://github.com/HalbornSecurity/PublicReports/blob/master/CosmWasm%20Smart%20Contract%20Audits/Stader_Labs_LunaX_Contrracts_CosmWasm_Smart_Contract_Security_Audit_Report_Halborn_Final.pdf',
    ],
    listedAt: 1639811716,
    openSource: true,
    slug: 'stader',
    tvl: 860997711.811597,
    chainTvls: { Terra: 860997711.811597 },
    change_1h: -0.3458543184236049,
    change_1d: 10.016998199165755,
    change_7d: 9.297427801134958,
    mcap: 22690791,
  },
  {
    id: '173',
    name: 'Badger DAO',
    address: '0x3472A5A71965499acd81997a54BBA8D852C6E53d',
    symbol: 'BADGER',
    url: 'https://badger.finance/',
    description: 'DAO dedicated to building products and infrastructure to bring Bitcoin to DeFi.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/badger-dao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'badger-dao',
    cmcId: '7859',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Arbitrum', 'Binance'],
    module: 'badgerdao.js',
    twitter: 'Yield Aggregator',
    audit_links: [
      'https://badger.finance/wp-content/uploads/2021/01/HAECHI-AUDIT-BadgerDAO-Smart-Contract-Audit-Report-1.pdf',
    ],
    oracles: [],
    slug: 'badger-dao',
    tvl: 845852203.39,
    chainTvls: { Binance: 81068.73, Ethereum: 837357289.49, Arbitrum: 8413845.17 },
    change_1h: 0.473371625593046,
    change_1d: 0.24627297941231063,
    change_7d: 12.487050244172664,
    fdv: 247502925,
    mcap: 117409697,
  },
  {
    id: '508',
    name: 'KlaySwap',
    address: '-',
    symbol: 'KSP',
    url: 'https://klayswap.com/dashboard',
    description: 'KLAYswap is an AMM-based Instant Swap Protocol',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/klayswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'klayswap-protocol',
    cmcId: '8296',
    category: 'Dexes',
    chains: ['Klaytn'],
    module: 'klayswap/index.js',
    twitter: 'KLAYswap',
    audit_links: [
      'https://docs.klayswap.com/risk-and-security#greater-than-read-the-smart-contract-audit-reports-here',
    ],
    slug: 'klayswap',
    tvl: 845546836.4091512,
    chainTvls: { 'Klaytn-staking': 178140236.4974778, Klaytn: 845546836.4091512, staking: 178140236.4974778 },
    change_1h: -0.33552631793598664,
    change_1d: 0.4815580904589325,
    change_7d: 3.984307961267291,
    staking: 178140236.4974778,
    mcap: 0,
  },
  {
    id: '145',
    name: 'Serum',
    address: null,
    symbol: 'SRM',
    url: 'https://projectserum.com/',
    description:
      'Serum is a decentralized exchange (DEX) and ecosystem that brings unprecedented speed and low transaction costs to decentralized finance.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/serum.jpg',
    audits: null,
    audit_note: null,
    gecko_id: 'serum',
    cmcId: '6187',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'serum.js',
    twitter: 'ProjectSerum',
    slug: 'serum',
    tvl: 821966593.5830456,
    chainTvls: { Solana: 821966593.5830456 },
    change_1h: -0.1502057597152202,
    change_1d: 17.99110626979615,
    change_7d: -16.101770980747332,
    fdv: 34762085758,
    mcap: 455803032,
  },
  {
    id: '484',
    name: 'Marinade Finance',
    address: 'solana:MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey',
    symbol: 'MNDE',
    url: 'https://marinade.finance',
    description: 'The easiest way to stake Solana - liquid staking',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/marinade-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'marinade',
    cmcId: null,
    category: 'Liquid Staking',
    chains: ['Solana'],
    module: 'marinade.js',
    twitter: 'MarinadeFinance',
    audit_links: [
      'https://marinade.finance/Neodyme.pdf',
      'https://marinade.finance/AckeeBlockchain.pdf',
      'https://marinade.finance/KudelskiSecurity.pdf',
    ],
    slug: 'marinade-finance',
    tvl: 803287117.6266543,
    chainTvls: { Solana: 803287117.6266543 },
    change_1h: 1.6041051140143452,
    change_1d: 3.0199993580650357,
    change_7d: 23.460278730220878,
    fdv: 106065202,
    mcap: 7502646,
  },
  {
    id: '944',
    name: 'Platypus Finance',
    address: 'avax:0x22d4002028f537599be9f666d1c4fa138522f9c8',
    symbol: 'PTP',
    url: 'https://platypus.finance',
    description:
      'Platypus finance is a novel open-liquidity stableswap protocol that aims to remove blockchain divergence.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/platypus.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'platypus-finance',
    cmcId: '16231',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'platypus-finance/index.js',
    twitter: 'Platypusdefi',
    audit_links: [
      'https://omniscia.io/platypus-finance-core-implementation/',
      'https://hacken.io/audits/#platypus_finance',
      'https://omniscia.io/platypus-finance-governance-staking/',
    ],
    listedAt: 1638299790,
    slug: 'platypus-finance',
    tvl: 788834154.7868652,
    chainTvls: { 'Avalanche-staking': 48371981.713905945, Avalanche: 788834154.7868652, staking: 48371981.713905945 },
    change_1h: -0.3913779004584086,
    change_1d: 1.6166322270406965,
    change_7d: -23.6289967559603,
    staking: 48371981.713905945,
    fdv: 949967711,
    mcap: 62057308,
  },
  {
    id: '1303',
    name: 'Iron Bank',
    address: 'fantom:0x00a35FD824c717879BF370E70AC6868b95870Dfb',
    symbol: 'IB',
    url: 'https://ib.xyz',
    description:
      'Iron Bank is a decentralized protocol to protocol lending platform. It allows trusted protocols to borrow funds without posting collateral via whitelisting. It is helping build a better and safer DeFi lending ecosystem, by driving capital efficiency as the liquidity infrastructure and backbone for DeFi and CeFi.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/iron-bank.svg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum', 'Avalanche', 'Fantom'],
    module: 'ironbank/index.js',
    twitter: 'ibdotxyz',
    audit_links: ['https://github.com/trailofbits/publications/blob/master/reviews/CREAMSummary.pdf'],
    forkedFrom: ['Compound'],
    listedAt: 1642768849,
    oracles: ['Chainlink'],
    slug: 'iron-bank',
    tvl: 788540177.1994902,
    chainTvls: {
      Avalanche: 107354727.51980788,
      'Fantom-borrowed': 36665808.45969106,
      Ethereum: 647526010.0207666,
      'Ethereum-borrowed': 278749080.6423721,
      borrowed: 420265649.6693453,
      Fantom: 33659439.65891559,
      'Avalanche-borrowed': 104850760.56728217,
    },
    change_1h: 0.3625357238623792,
    change_1d: 1.9214810518317194,
    change_7d: -24.321320416999768,
  },
  {
    id: '129',
    name: 'xDAI Stake Bridge',
    address: '0x0Ae055097C6d159879521C384F1D2123D1f195e6',
    symbol: 'STAKE',
    url: 'https://xdaichain.com/ ',
    description: 'The first-ever USD stable blockchain and multi-chain staking token.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/xdai-stake.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'xdai-stake',
    cmcId: '5601',
    category: 'Chain',
    chains: ['Ethereum'],
    module: 'xdai/index.js',
    twitter: 'xdaichain',
    slug: 'xdai-stake-bridge',
    tvl: 786099522.5234156,
    chainTvls: { Ethereum: 786099522.5234156 },
    change_1h: 0.4548314583996387,
    change_1d: 9.476621466286545,
    change_7d: 14.84526964844946,
    fdv: 144408572,
    mcap: 32157411,
  },
  {
    id: '143',
    name: 'Flexa',
    address: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
    symbol: 'AMP',
    url: 'https://amptoken.org/',
    description: 'Collateral for fast and efficient value transfer',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/flexa.png_small',
    audits: '2',
    audit_note: null,
    gecko_id: 'amp-token',
    cmcId: '6945',
    category: 'Payments',
    chains: ['Ethereum'],
    module: 'flexa.js',
    twitter: 'amptoken',
    audit_links: [
      'https://consensys.net/diligence/audits/2020/06/amp',
      'https://github.com/trailofbits/publications/blob/master/reviews/amp.pdf',
    ],
    oracles: ['Chainlink'],
    slug: 'flexa',
    tvl: 754615366.8389205,
    chainTvls: { Ethereum: 754615366.8389205 },
    change_1h: -0.0559605368170395,
    change_1d: -1.1303838350988116,
    change_7d: 3.1339652788526564,
    fdv: 2759832060,
    mcap: 1335451358,
  },
  {
    id: '458',
    name: 'Solend',
    address: 'solana:SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp',
    symbol: 'SLND',
    url: 'https://solend.fi',
    description:
      'Solend is an algorithmic, decentralized protocol for lending and borrowing on Solana. Lending and borrowing has proven itself as being key in a DeFi ecosystem. However, current products are slow and expensive. On Solana, Solend can scale to being 100x faster and 100x cheaper. Solend aims to be the easiest to use and most secure solution on Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/solend.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'solend',
    cmcId: '13524',
    category: 'Lending',
    chains: ['Solana'],
    module: 'solend/index.js',
    twitter: 'solendprotocol',
    audit_links: ['https://github.com/solendprotocol/solana-program-library/tree/master/token-lending/audit'],
    oracles: ['Pyth'],
    slug: 'solend',
    tvl: 749399254.9408855,
    chainTvls: { 'Solana-borrowed': 481936511.83520913, Solana: 749399254.9408855, borrowed: 481936511.83520913 },
    change_1h: -0.7532455590384899,
    change_1d: 0.9469648401745872,
    change_7d: 10.866964409755496,
    fdv: 201838491,
    mcap: 20967639,
  },
  {
    id: '260',
    name: 'Mirror',
    address: '0x09a3ecafa817268f77be1283176b946c4ff2e608',
    symbol: 'MIR',
    url: 'https://mirror.finance/',
    description: 'Synthetics protocol for on-chain price exposure to real-world assets - accessible to all.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mirror.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mirror-protocol',
    cmcId: '7857',
    category: 'Derivatives',
    chains: ['Terra', 'Ethereum'],
    module: 'mirror/index.js',
    twitter: 'mirror_protocol',
    audit_links: ['https://docsend.com/view/p4es2dgvwadamgqg'],
    oracles: ['Band'],
    openSource: true,
    slug: 'mirror',
    tvl: 735091499.178146,
    chainTvls: {
      Ethereum: 68532470.5591886,
      pool2: 17561053.550669625,
      Terra: 666559028.6189574,
      'Terra-pool2': 9279356.456586216,
      'Ethereum-pool2': 8281697.094083412,
    },
    change_1h: -0.0002963624044127755,
    change_1d: 1.8760788709348901,
    change_7d: 3.6054089983859257,
    pool2: 17561053.550669625,
    fdv: 663023752,
    mcap: 263183287,
  },
  {
    id: '900',
    name: 'Rocket Pool',
    address: '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
    symbol: 'RPL',
    url: 'https://rocketpool.net',
    description:
      'Rocket Pool is the first truly decentralised Ethereum staking pool. Liquid Staking, Audited SC, and Minimised Penalty Risk. Unlike solo stakers, who are required to put 32 ETH up for deposit to create a new validator, Rocket Pool nodes only need to deposit 16 ETH per validator. This will be coupled with 16 ETH from the staking pool (which stakers deposited in exchange for rETH) to create a new ETH2 validator.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/rocket-pool.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'rocket-pool',
    cmcId: '2943',
    category: 'Liquid Staking',
    chains: ['Ethereum'],
    module: 'rocketpool/index.js',
    twitter: 'Rocket_Pool',
    audit_links: [
      'https://rocketpool.net/files/sigma-prime-audit.pdf',
      'https://consensys.net/diligence/audits/2021/04/rocketpool',
      'https://github.com/trailofbits/publications/blob/master/reviews/RocketPool.pdf',
      'https://immunefi.com/bounty/rocketpool',
    ],
    listedAt: 1637712907,
    slug: 'rocket-pool',
    tvl: 732560427.5172783,
    chainTvls: { Ethereum: 732560427.5172783 },
    change_1h: 0.006438327197798799,
    change_1d: 3.7056500183287824,
    change_7d: 17.27087948921688,
    fdv: 673734497,
    mcap: 606054161,
  },
  {
    id: '854',
    name: 'Maiar Exchange',
    address: 'elrond:MEX-455c57',
    symbol: 'MEX',
    url: 'https://maiar.exchange',
    description:
      'Maiar Exchange is a DEX AMM running on the Elrond Network. It is built by the same team that has built the Elrond blockchain.',
    chain: 'Elrond',
    logo: 'https://icons.llama.fi/maiar-exchange.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Elrond'],
    module: 'maiar/index.js',
    twitter: 'MaiarExchange',
    listedAt: 1637185638,
    slug: 'maiar-exchange',
    tvl: 723513767.4790858,
    chainTvls: { 'Elrond-staking': 759693180.8420379, Elrond: 723513767.4790858, staking: 759693180.8420379 },
    change_1h: -0.0251644761090688,
    change_1d: -2.144066861708353,
    change_7d: -0.6149174976202971,
    staking: 759693180.8420379,
  },
  {
    id: '204',
    name: 'Alchemix',
    address: '0xdBdb4d16EdA451D0503b854CF79D55697F90c8DF',
    symbol: 'ALCX',
    url: 'https://alchemix.fi/',
    description:
      'Alchemix is a DeFi protocol that allows for the creation of synthetic tokens that represent the future yield of a deposit. It enables users to retrieve near instant tokenized value against temporary* deposits of stablecoins.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/alchemix.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'alchemix',
    cmcId: '8613',
    category: 'Synthetics',
    chains: ['Ethereum'],
    module: 'alchemix.js',
    twitter: 'AlchemixFi',
    audit_links: ['https://alchemix.fi/a208baf6ca7e0d6b0116461f05e27cd9.pdf'],
    oracles: [],
    slug: 'alchemix',
    tvl: 719549307.6194274,
    chainTvls: { Ethereum: 719549307.6194274 },
    change_1h: 0.007751843818653015,
    change_1d: 1.0821379388356007,
    change_7d: -0.09841940569469898,
    fdv: 291588507,
    mcap: 148687935,
  },
  {
    id: '657',
    name: 'Synapse',
    address: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
    symbol: 'SYN',
    url: 'https://synapseprotocol.com',
    description: 'Synapse is a cross-chain layer ∞ protocol powering interoperability between chains.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/synapse.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'synapse-2',
    cmcId: '12147',
    category: 'Dexes',
    chains: [
      'Arbitrum',
      'Avalanche',
      'Fantom',
      'Binance',
      'Polygon',
      'Ethereum',
      'Optimism',
      'Aurora',
      'Harmony',
      'Metis',
      'Boba',
      'Moonriver',
      'Moonbeam',
      'Cronos',
    ],
    module: 'synapse/index.js',
    twitter: 'SynapseProtocol',
    forkedFrom: ['Saddle Finance'],
    slug: 'synapse',
    tvl: 712345512.9832869,
    chainTvls: {
      Optimism: 41239406.28101023,
      Metis: 16623379.925315691,
      Cronos: 135.8660514671599,
      Moonriver: 4514631.800170829,
      pool2: 50275319.15729575,
      Avalanche: 150584031.84359106,
      Harmony: 25386743.342605002,
      Moonbeam: 305843.0881714565,
      'Ethereum-pool2': 50275319.15729575,
      Binance: 85583685.25828497,
      Boba: 11634926.244206531,
      Ethereum: 42753753.86965681,
      Aurora: 28215775.023112968,
      Polygon: 43722130.89319547,
      Arbitrum: 156690542.99444908,
      Fantom: 105090526.55346511,
    },
    change_1h: -0.07281898377851803,
    change_1d: 0.5655576342662414,
    change_7d: -0.6182643101295753,
    pool2: 50275319.15729575,
    fdv: 562213897,
    mcap: 404579650,
  },
  {
    id: '295',
    name: 'Stacks Staking',
    address: '-',
    symbol: 'STX',
    url: 'https://stacks.co',
    description:
      'Stacks makes Bitcoin programmable, enabling decentralized apps and smart contracts that inherit all of Bitcoin’s powers. See detailed metrics for Stacking at https://stacking.club/',
    chain: 'Stacks',
    logo: 'https://icons.llama.fi/stacks.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'blockstack',
    cmcId: '4847',
    category: 'Chain',
    chains: ['Stacks'],
    module: 'stacks/index.js',
    twitter: 'Stacks',
    slug: 'stacks-staking',
    tvl: 712141396.7999126,
    chainTvls: { Stacks: 712141396.7999126 },
    change_1h: 1.3605442176870781,
    change_1d: 2.75862068965516,
    change_7d: 10.06904322004938,
    mcap: 1571807379,
  },
  {
    id: '1217',
    name: 'Parallel Liquid Crowdloan',
    address: null,
    symbol: '-',
    url: 'https://parallel.fi',
    description:
      'Parallel Finance is a Decentralized Money Market Protocol that offers lending, staking, and borrowing in the Polkadot ecosystem. Depositors can lend and stake simultaneously to earn double yield on their staked coins, and borrowers can collateralize to borrow.',
    chain: 'Parallel',
    logo: 'https://icons.llama.fi/parallel-liquid-crowdloan.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Parallel'],
    module: 'parallel.js',
    twitter: 'ParallelFi',
    listedAt: 1641749944,
    slug: 'parallel-liquid-crowdloan',
    tvl: 708471324.6342379,
    chainTvls: { Parallel: 708471324.6342379 },
    change_1h: 0.3214612879762484,
    change_1d: -0.7584036207174023,
    change_7d: 14.608321622602787,
  },
  {
    id: '214',
    name: 'Raydium',
    address: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    symbol: 'RAY',
    url: 'https://raydium.io',
    description: 'An on-chain order book AMM powering the evolution of DeFi.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/raydium.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'raydium',
    cmcId: '8526',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'raydium.js',
    twitter: 'RaydiumProtocol',
    openSource: false,
    slug: 'raydium',
    tvl: 707211665.1304327,
    chainTvls: { Solana: 707211665.1304327 },
    change_1h: 0.4059180149751853,
    change_1d: 1.245199533869524,
    change_7d: 12.986512043187702,
    fdv: 1883466824,
    mcap: 319110180,
  },
  {
    id: '425',
    name: 'Tranchess',
    address: 'bsc:0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6',
    symbol: 'CHESS',
    url: 'https://tranchess.com',
    description: 'Tranchess is a yield enhancing asset tracker with varied risk-return solutions',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/tranchess.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'tranchess',
    cmcId: '10974',
    category: 'Yield',
    chains: ['Binance'],
    module: 'tranchess/index.js',
    twitter: 'tranchess',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Tranchess-v1.0.pdf',
      'https://www.certik.org/projects/tranchess',
    ],
    slug: 'tranchess',
    tvl: 698698356.394934,
    chainTvls: { Binance: 698698356.394934, 'Binance-staking': 34323154.118154675, staking: 34323154.118154675 },
    change_1h: 0.21731945593019475,
    change_1d: 2.061854995147769,
    change_7d: 16.406995753528506,
    staking: 34323154.118154675,
    fdv: 281804309,
    mcap: 66878970,
  },
  {
    id: '491',
    name: 'Terraswap',
    address: '-',
    symbol: '-',
    url: 'https://terraswap.io',
    description:
      'Terraswap is a Uniswap-inspired automated market-maker (AMM) protocol implemented with smart contracts on the Terra blockchain. This enables a decentralized on-chain exchange for the various assets involved in Terra ecosystem.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/terraswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Terra'],
    module: 'terraswap/index.js',
    twitter: 'terraswap_io',
    openSource: true,
    slug: 'terraswap',
    tvl: 695372317.3542844,
    chainTvls: { Terra: 695372317.3542844 },
    change_1h: -0.2810121414212432,
    change_1d: -3.386063985947459,
    change_7d: -12.260754025510366,
  },
  {
    id: '661',
    name: 'MochiFi',
    address: null,
    symbol: 'MOCHI',
    url: 'https://mochi.fi',
    description:
      'Mochi puts your NFTs and long-tail assets to work as collateral while maintaining exposure to their long-term value. An autonomously governed protocol, Mochi bridges the gap between NFTs and DeFi to pioneer a new way to maximize the utility of emerging digital assets.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/mochifi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'usdm',
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'mochifi/index.js',
    twitter: 'MochiDeFi',
    audit_links: ['https://docs.mochi.fi/audits'],
    oracles: ['Chainlink', 'Internal'],
    slug: 'mochifi',
    tvl: 663840306.4577792,
    chainTvls: { Ethereum: 663840306.4577792 },
    change_1h: -0.000049199965289403735,
    change_1d: 3.0079399961761197,
    change_7d: 14.444573065380368,
    mcap: 0,
  },
  {
    id: '238',
    name: 'Ellipsis Finance',
    address: 'bsc:0xA7f552078dcC247C2684336020c03648500C6d9F',
    symbol: 'EPS',
    url: 'https://ellipsis.finance/',
    description: 'Secure low-slippage stable swapping on BSC.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/ellipsis-finance.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'ellipsis',
    cmcId: '8938',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'ellipsis/index.js',
    twitter: 'Ellipsisfi',
    audit_links: [
      'https://github.com/ellipsis-finance/ellipsis-audits/blob/master/010421_Hacken_Ellipsis_SC_Audit_Report.pdf',
    ],
    forkedFrom: ['Curve'],
    slug: 'ellipsis-finance',
    tvl: 663657908.7526258,
    chainTvls: { Binance: 663657908.7526258, 'Binance-staking': 31436712.70386558, staking: 31436712.70386558 },
    change_1h: -0.18464200496684668,
    change_1d: -0.6962129650771232,
    change_7d: 2.5593993321994617,
    staking: 31436712.70386558,
    fdv: 216648159,
    mcap: 151277803,
  },
  {
    id: '160',
    name: 'Homora',
    address: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
    symbol: 'ALPHA',
    url: 'https://alphafinance.io/',
    description:
      'Alpha Finance Lab is an ecosystem of cross-chain DeFi products that focus on capturing unaddressed demand in an innovative and user friendly way. Alpha Homora, the first product, is a leveraged yield farming and leveraged liquidity providing protocol where ETH lenders can earn high lending interest rate and leveraged yield farmers/liquidity providers can increase the APY.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/homora.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'alpha-finance',
    cmcId: '7232',
    category: 'Yield',
    chains: ['Avalanche', 'Ethereum', 'Fantom', 'Binance'],
    module: 'alpha-homora/index.js',
    twitter: 'AlphaFinanceLab',
    audit_links: ['https://blog.openzeppelin.com/alpha-homora-v2/'],
    oracles: ['Band', 'Chainlink'],
    slug: 'homora',
    tvl: 621977986.8805392,
    chainTvls: {
      Binance: 15482909.430445129,
      Ethereum: 212041314.88536173,
      Avalanche: 378600246.17499936,
      Fantom: 15853516.38973294,
    },
    change_1h: -0.03649302143809052,
    change_1d: null,
    change_7d: -18.227299375763522,
    fdv: 380007907,
    mcap: 169608977,
  },
  {
    id: '1327',
    name: 'Astar dApps Staking',
    address: 'astar:-',
    symbol: 'ASTR',
    url: 'https://astar.network',
    description:
      'Astar Network is a multi-chain decentralized application layer on Kusama Network. dApp staking is a mechanism that incentives developers who built in the Astar ecosystem. Users can stake on those dApps to earn staking rewards and at the same time support the project by giving them a daily income.',
    chain: 'Astar',
    logo: 'https://icons.llama.fi/astar-dapps-staking.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'astar',
    cmcId: '12885',
    category: 'Staking',
    chains: ['Astar'],
    module: 'astar-dapps-staking/index.js',
    twitter: 'AstarNetwork',
    audit_links: ['https://certificate.quantstamp.com/view/a-star-network-staking'],
    listedAt: 1643478301,
    slug: 'astar-dapps-staking',
    tvl: 580652099.4321494,
    chainTvls: { Astar: 580652099.4321494 },
    change_1h: 0.000003062079073856694,
    change_1d: 32.61202001382776,
    change_7d: 54.179582376021756,
    mcap: 333010437,
  },
  {
    id: '148',
    name: 'Tornado Cash',
    address: '0x77777feddddffc19ff86db637967013e6c6a116c',
    symbol: 'TORN',
    url: 'https://tornado.cash/',
    description: 'Non-custodial anonymous transactions on Ethereum.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/tornado-cash.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'tornado-cash',
    cmcId: '8049',
    category: 'Privacy',
    chains: ['Ethereum'],
    module: 'tornado/index.js',
    twitter: 'TornadoCash',
    audit_links: ['https://tornado.cash/#faq'],
    oracles: [],
    slug: 'tornado-cash',
    tvl: 578964813.926,
    chainTvls: { Ethereum: 578964813.926 },
    change_1h: 0.6246254786311738,
    change_1d: 3.8525372070785266,
    change_7d: 11.019425654627241,
    fdv: 486805287,
    mcap: 114479164,
  },
  {
    id: '353',
    name: 'Armor',
    address: '0x1337def16f9b486faed0293eb623dc8395dfe46a',
    symbol: 'ARMOR',
    url: 'https://armor.fi/',
    description: 'Insurance',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/armor.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'armor',
    cmcId: '8309',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'armorfinance/index.js',
    twitter: 'ArmorFi',
    slug: 'armor',
    tvl: 575463606.2339932,
    chainTvls: { Ethereum: 575463606.2339932 },
    change_1h: 0.6013473731396886,
    change_1d: 4.047095406226191,
    change_7d: 15.176303152504289,
    fdv: 36992940,
    mcap: 7752400,
  },
  {
    id: '643',
    name: 'Geist Finance',
    address: 'fantom:0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
    symbol: 'GEIST',
    url: 'https://geist.finance',
    description:
      'Geist is a decentralised non-custodial liquidity market protocol where users can participate as depositors or borrowers.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/geist-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'geist-finance',
    cmcId: null,
    category: 'Lending',
    chains: ['Fantom'],
    module: 'geist/index.js',
    twitter: 'GeistFinance',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Geist-v1.0.pdf',
      'https://solidity.finance/audits/GeistProtocol/',
    ],
    oracles: ['Chainlink'],
    forkedFrom: ['Aave'],
    slug: 'geist-finance',
    tvl: 570952476.4906873,
    chainTvls: {
      pool2: 7186710.228926173,
      'Fantom-staking': 31435745.23123802,
      'Fantom-borrowed': 345110064.20640016,
      borrowed: 345110064.20640016,
      'Fantom-pool2': 7186710.228926173,
      Fantom: 570952476.4906873,
      staking: 31435745.23123802,
    },
    change_1h: 0.27082721729311743,
    change_1d: 2.756450082635652,
    change_7d: 5.670712338468164,
    staking: 31435745.23123802,
    pool2: 7186710.228926173,
    fdv: 112084350,
    mcap: 34106729,
  },
  {
    id: '1115',
    name: 'Tectonic',
    address: 'cronos:0xeAdf7c01DA7E93FdB5f16B0aa9ee85f978e89E95',
    symbol: 'TONIC',
    url: 'https://tectonic.finance/',
    description: 'Tectonic is a cross-chain money market for earning passive yield and accessing instant backed loans',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/tectonic.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'tectonic',
    cmcId: '16430',
    category: 'Lending',
    chains: ['Cronos'],
    module: 'tectonic/index.js',
    twitter: 'TectonicFi',
    forkedFrom: ['Compound'],
    listedAt: 1640643322,
    slug: 'tectonic',
    tvl: 538490010.5622399,
    chainTvls: { Cronos: 538490010.5622399 },
    change_1h: -0.0582452042353907,
    change_1d: -2.0573321211978453,
    change_7d: 7.35192122408786,
    fdv: 545798247,
    mcap: 64616974,
  },
  {
    id: '122',
    name: 'Nexus Mutual',
    address: '0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b',
    symbol: 'NXM',
    url: 'https://nexusmutual.io/',
    description: 'Nexus Mutual offers decentralized cover against smart contract failure.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/nexus-mutual.png',
    audits: null,
    audit_note: null,
    gecko_id: 'nxm',
    cmcId: '1103',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'nexus/index.js',
    twitter: 'NexusMutual',
    audit_links: ['https://nexusmutual.gitbook.io/docs/helpful-resources/audits-and-security'],
    oracles: ['Chainlink'],
    slug: 'nexus-mutual',
    tvl: 538255089.11137,
    chainTvls: { Ethereum: 538255089.11137 },
    change_1h: 0.6367032946327527,
    change_1d: 4.028079212880442,
    change_7d: 15.325535832264634,
    fdv: 801895201,
    mcap: 801895201,
  },
  {
    id: '1142',
    name: 'MM Optimizer',
    address: 'cronos:0x50c0C5bda591bc7e89A342A3eD672FB59b3C46a7',
    symbol: 'MMO',
    url: 'https://vaults.mm.finance/vault',
    description:
      'MM Optimizer aims to provide users the easiest way to join the bandwagon of the DeFi world, built on top of MM Finance, giving you the tastiest MMFs topped with MMOs.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/mmo-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'mad-meerkat-optimizer',
    cmcId: null,
    category: 'Yield',
    chains: ['Cronos'],
    module: 'mmo-finance/index.js',
    twitter: 'MMFcrypto',
    forkedFrom: ['Bunny'],
    listedAt: 1640798436,
    slug: 'mm-optimizer',
    tvl: 536558932.3566167,
    chainTvls: { Cronos: 536558932.3566167, 'Cronos-staking': 52321255.63940754, staking: 52321255.63940754 },
    change_1h: -1.7627432700335675,
    change_1d: 4.53896957789091,
    change_7d: 24.83440750131693,
    staking: 52321255.63940754,
    fdv: 56767719,
    mcap: 37910526,
  },
  {
    id: '177',
    name: 'DefiSaver',
    address: '-',
    symbol: '-',
    url: 'https://defisaver.com/',
    description:
      'DeFi Saver is a one-stop management solution for decentralized finance with advanced leverage management tools and unique automated management features.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/defisaver.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Ethereum'],
    module: 'defisaver.js',
    twitter: 'DefiSaver',
    audit_links: ['https://consensys.net/diligence/audits/2021/03/defi-saver/'],
    slug: 'defisaver',
    tvl: 521933148,
    chainTvls: { Ethereum: 521933148 },
    change_1h: 0.16896100497089606,
    change_1d: 4.533613687003623,
    change_7d: 16.229418461315802,
  },
  {
    id: '412',
    name: 'Thorchain',
    address: '0x3155ba85d5f96b2d030a4966af206230e46849cb',
    symbol: 'RUNE',
    url: 'https://thorchain.org/',
    description: 'THORChain facilitates cross-chain liquidity pools with no pegged or wrapped tokens.',
    chain: 'Thorchain',
    logo: 'https://icons.llama.fi/thorchain.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'thorchain',
    cmcId: '4157',
    category: 'Dexes',
    chains: ['Thorchain'],
    module: 'thorchain.js',
    twitter: 'THORChain',
    audit_links: ['https://www.certik.org/projects/thorchain'],
    slug: 'thorchain',
    tvl: 520991705.46931386,
    chainTvls: { 'Thorchain-staking': 837793478.5297962, Thorchain: 520991705.46931386, staking: 837793478.5297962 },
    change_1h: -0.40592621735761725,
    change_1d: 12.678696292612685,
    change_7d: 61.674614759757986,
    staking: 837793478.5297962,
    fdv: 6198173031,
    mcap: 3728265228,
  },
  {
    id: '862',
    name: 'Quarry',
    address: null,
    symbol: '-',
    url: 'https://quarry.so',
    description: 'Quarry is an open protocol for launching liquidity mining programs.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/quarry.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Solana'],
    module: 'quarry/index.js',
    twitter: 'quarryprotocol',
    listedAt: 1637345891,
    slug: 'quarry',
    tvl: 508456016.7164614,
    chainTvls: { Solana: 508456016.7164614 },
    change_1h: 0.8455426548480602,
    change_1d: -3.377254621796382,
    change_7d: -9.760694073344368,
  },
  {
    id: '373',
    name: 'BiSwap',
    address: 'bsc:0x965f527d9159dce6288a2219db51fc6eef120dd1',
    symbol: 'BSW',
    url: 'https://biswap.org/',
    description:
      'Biswap is the first decentralized exchange platform on the market with a three-type referral system and the lowest platform transaction fees (0.1%).',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/biswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'biswap',
    cmcId: '10746',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'biswap/index.js',
    twitter: 'Biswap_Dex',
    audit_links: ['https://www.certik.org/projects/biswap'],
    forkedFrom: ['Uniswap'],
    slug: 'biswap',
    tvl: 499461094.81417054,
    chainTvls: { Binance: 499461094.81417054, 'Binance-staking': 79217773.86016478, staking: 79217773.86016478 },
    change_1h: 0.14505952702378977,
    change_1d: -5.215948757172626,
    change_7d: 2.4082530343615787,
    staking: 79217773.86016478,
    fdv: 470254043,
    mcap: 156815381,
  },
  {
    id: '166',
    name: 'TrueFi',
    address: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
    symbol: 'TRU',
    url: 'https://truefi.io/',
    description: 'Earn high yields on stablecoin loans and borrow capital without collateral',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/truefi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'truefi',
    cmcId: '7725',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'truefi/index.js',
    twitter: 'TrustToken',
    audit_links: ['https://github.com/trusttoken/audits/tree/master/TrueFi'],
    slug: 'truefi',
    tvl: 491154963.78444207,
    chainTvls: { Ethereum: 491154963.78444207 },
    change_1h: 0.3947242143292442,
    change_1d: 0.40662638130358175,
    change_7d: 0.7402322480166106,
    fdv: 332982693,
    mcap: 150966478,
  },
  {
    id: '801',
    name: 'cBridge',
    address: null,
    symbol: 'CELR',
    url: 'https://cbridge.celer.network/#/transfer',
    description:
      'Celer cBridge is a multi-chain network that enables instant, low-cost and ANY-to-ANY value transfers within and across different blockchains, such as Ethereum and Polkadot, and different layer-2 scaling solutions on top, such as Optimistic Rollup, ZK Rollup and sidechains',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cbridge.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Cross Chain',
    chains: [
      'Astar',
      'Ethereum',
      'Fantom',
      'Avalanche',
      'Binance',
      'Optimism',
      'Aurora',
      'Arbitrum',
      'Polygon',
      'Moonbeam',
      'Boba',
      'Harmony',
      'Metis',
      'Oasis',
      'Moonriver',
      'Shiden',
      'Heco',
      'OKExChain',
      'Celo',
      'xDai',
      'Syscoin',
    ],
    module: 'celerbridge/index.js',
    twitter: 'CelerNetwork',
    audit_links: [
      'https://github.com/celer-network/sgn-v2-contracts/blob/main/audit/REP-cBridge-v2-2021-11-17.pdf',
      'https://github.com/celer-network/sgn-v2-contracts/blob/main/audit/PeckShield-Audit-Report-SGNv2-v1.0.pdf',
      'https://github.com/celer-network/sgn-v2-contracts/blob/main/audit/SlowMist%20Audit%20Report%20-%20SGN.pdf',
    ],
    listedAt: 1636307154,
    slug: 'cbridge',
    tvl: 487629118.2612043,
    chainTvls: {
      Optimism: 30814374.936000668,
      Metis: 2951255.5195952333,
      Moonriver: 726542.188609783,
      Avalanche: 39146026.53822455,
      Harmony: 3766617.4958626507,
      Binance: 38421385.20916523,
      Ethereum: 39767625.725516655,
      xDai: 355.86619688910406,
      Astar: 192501114.8991563,
      Oasis: 1647559.0474205457,
      Heco: 3023.1476818953183,
      Shiden: 80768.66531534493,
      Celo: 391.0546079831845,
      Arbitrum: 24236792.403575048,
      Fantom: 39747136.00069304,
      OKExChain: 1171.751649536477,
      Moonbeam: 16357763.234903384,
      Boba: 5349623.797452568,
      Aurora: 28160453.75999644,
      Syscoin: 178.43402153482853,
      Polygon: 23948958.585559085,
    },
    change_1h: 1.702214389073859,
    change_1d: 3.028368597860293,
    change_7d: 6.897940880890843,
  },
  {
    id: '450',
    name: 'Scream',
    address: 'fantom:0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
    symbol: 'SCREAM',
    url: 'https://scream.sh/',
    description: 'Scream is a highly scalable decentralized lending protocol powered by Fantom.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/scream.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'scream',
    cmcId: null,
    category: 'Lending',
    chains: ['Fantom'],
    module: 'scream/index.js',
    twitter: 'Screamdotsh',
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'scream',
    tvl: 483767730.0234949,
    chainTvls: {
      'Fantom-borrowed': 323187497.1532785,
      'Fantom-staking': 10846912.082934247,
      borrowed: 323187497.1532785,
      staking: 10846912.082934247,
      Fantom: 483767730.0234949,
    },
    change_1h: 0.3619081545004974,
    change_1d: 1.8877946071678764,
    change_7d: 3.0048686553751764,
    staking: 10846912.082934247,
    fdv: 75242730,
    mcap: 16378138,
  },
  {
    id: '121',
    name: 'CREAM Finance',
    address: '0x2ba592F78dB6436527729929AAf6c908497cB200',
    symbol: 'CREAM',
    url: 'https://cream.finance/',
    description: 'Money markets, liquid staking and protocol-to-protocol lending',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cream-finance.png',
    audits: '3',
    audit_note: null,
    gecko_id: 'cream-2',
    cmcId: '6193',
    category: 'Lending',
    chains: ['Binance', 'Ethereum', 'Polygon'],
    module: 'cream/index.js',
    twitter: 'CreamdotFinance',
    audit_links: ['https://docs.cream.finance/audit-report'],
    forkedFrom: ['Compound'],
    oracles: ['Chainlink', 'Band'],
    slug: 'cream-finance',
    tvl: 476137084.4247479,
    chainTvls: {
      'Polygon-borrowed': 569079.1525839203,
      'Binance-borrowed': 29659122.08963261,
      Binance: 386993304.95342773,
      Ethereum: 87926486.57723652,
      Polygon: 1217292.8940836254,
      borrowed: 30228201.24221653,
    },
    change_1h: 0.0003036264796207888,
    change_1d: 1.8259558292356814,
    change_7d: 9.2562583501403,
    fdv: 152565375,
    mcap: 39987968,
  },
  {
    id: '255',
    name: 'Fei Protocol',
    address: '0x956f47f50a910163d8bf957cf5846d573e7f87ca',
    symbol: 'FEI',
    url: 'https://fei.money/',
    description:
      'FEI uses a new kind of stablecoin mechanism called direct incentives. It is more capital efficient, has a fair distribution, and is fully decentralized. The protocol uses the value it controls to maintain liquid secondary markets.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/fei-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'fei-protocol',
    cmcId: '8642',
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'fei/index.js',
    twitter: 'feiprotocol',
    audit_links: ['https://docs.fei.money/audit'],
    oracles: ['Chainlink'],
    slug: 'fei-protocol',
    tvl: 469464020.86894214,
    chainTvls: { Ethereum: 469464020.86894214 },
    change_1h: 0.000005569871120769676,
    change_1d: 3.224844095380888,
    change_7d: 11.721704619759635,
    fdv: 524970827,
    mcap: 521800599,
  },
  {
    id: '487',
    name: 'Sunny',
    address: 'solana:SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag',
    symbol: 'SUNNY',
    url: 'https://sunny.ag',
    description:
      'Sunny is a composable DeFi yield aggregator powered by Solana, one of the fastest growing blockchain ecosystems. The Sunny Protocol is designed with composability as a core feature, enabling other applications and protocols to easily build on top of it.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/sunny.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'sunny-aggregator',
    cmcId: '11612',
    category: 'Yield',
    chains: ['Solana'],
    module: 'sunny.js',
    twitter: 'SunnyAggregator',
    openSource: false,
    slug: 'sunny',
    tvl: 460697089.80721164,
    chainTvls: { Solana: 460697089.80721164 },
    change_1h: 0.19426357664022476,
    change_1d: -1.349231888602688,
    change_7d: 100.89597286387587,
    fdv: 78018121,
    mcap: 14573937,
  },
  {
    id: '315',
    name: 'Tulip Protocol',
    address: 'TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs',
    symbol: 'TULIP',
    url: 'https://tulip.garden/',
    description: 'The Efficient Solana Yield Aggregator',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/tulip-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'solfarm',
    cmcId: '10373',
    category: 'Yield',
    chains: ['Solana'],
    module: 'solfarm.js',
    twitter: 'TulipProtocol',
    oracles: ['Pyth'],
    openSource: false,
    slug: 'tulip-protocol',
    tvl: 448874782.39363056,
    chainTvls: { Solana: 448874782.39363056 },
    change_1h: 0.19910817276051773,
    change_1d: 1.6126821359464998,
    change_7d: 5.8738376679335005,
    fdv: 142891164,
    mcap: 20489215,
  },
  {
    id: '641',
    name: 'G-UNI',
    address: '0x15b7c0c907e4C6b9AdaAaabC300C08991D6CEA05',
    symbol: 'GEL',
    url: 'https://www.gelato.network',
    description: 'G-UNI, an automated liquidity provision ERC-20 for Uniswap v3, powered by Gelato Network',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/g-uni.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gelato',
    cmcId: '7229',
    category: 'Yield',
    chains: ['Ethereum', 'Optimism'],
    module: 'sorbetfinance/index.js',
    twitter: 'gelatonetwork',
    audit_links: [
      'https://www.certik.org/projects/gelato',
      'https://drive.google.com/file/d/1aTmVdENDTt7-Rbr9Nxx8DLqy8BE0ZLew/view?usp=sharing',
    ],
    slug: 'g-uni',
    tvl: 438874167.4431604,
    chainTvls: { Optimism: 5368398.188214172, Ethereum: 433505769.25494623 },
    change_1h: 0.2402231255584013,
    change_1d: -0.2525446991824225,
    change_7d: -16.71024621401665,
    fdv: 446319502,
    mcap: 18321815,
  },
  {
    id: '1465',
    name: 'PRISM Protocol',
    address: 'terra:terra1dh9478k2qvqhqeajhn75a2a7dsnf74y5ukregw',
    symbol: 'PRISM',
    url: 'https://home.prismprotocol.app',
    description:
      'PRISM enables users to split digital assets into yield and principal components, unleashing the next level of DeFi composability.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/prism.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'prism-protocol',
    cmcId: '18015',
    category: 'Staking',
    chains: ['Terra'],
    oracles: ['TWAP'],
    forkedFrom: [],
    module: 'prism/index.js',
    twitter: 'prism_protocol',
    audit_links: [
      'https://github.com/oak-security/audit-reports/blob/master/Prism/2022-01-20%20Audit%20Report%20-%20Prism%20v1.0.pdf',
    ],
    openSource: true,
    listedAt: 1645730868,
    slug: 'prism-protocol',
    tvl: 438290481.269985,
    chainTvls: { 'Terra-staking': 34488132.9122028, Terra: 438290481.269985, staking: 34488132.9122028 },
    change_1h: -0.3341348986046313,
    change_1d: 11.738999381360003,
    change_7d: 18.457354642804958,
    staking: 34488132.9122028,
    fdv: 760286085,
    mcap: 0,
  },
  {
    id: '126',
    name: 'Loopring',
    address: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
    symbol: 'LRC',
    url: 'https://loopring.org/#/',
    description: 'Loopring is a zkRollup Exchange and Payment Protocol.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/loopring.png',
    audits: null,
    audit_note: null,
    gecko_id: 'loopring',
    cmcId: '1934',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'loopring/index.js',
    twitter: 'loopringorg',
    audit_links: [
      'https://github.com/Loopring/protocols/blob/master/packages/loopring_v3/security_audit/LoopringV3_1_Report_EN.pdf',
    ],
    oracles: ['Chainlink', 'Band'],
    slug: 'loopring',
    tvl: 423516581.4336124,
    chainTvls: { Ethereum: 423516581.4336124 },
    change_1h: -0.2638527972128628,
    change_1d: 6.034285986931366,
    change_7d: 34.6574862110414,
    fdv: 1763309698,
    mcap: 1598433342,
  },
  {
    id: '636',
    name: 'Keep3r Network',
    address: '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44',
    symbol: 'KP3R',
    url: 'https://keep3r.network',
    description:
      'Keep3r Network is a decentralized keeper network for projects that need external devops and for external teams to find keeper jobs',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/keep3r-network.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'keep3rv1',
    cmcId: '7535',
    category: 'Derivatives',
    chains: ['Ethereum'],
    module: 'keep3r.js',
    twitter: 'thekeep3r',
    audit_links: ['https://github.com/keep3r-network/keep3r.network/tree/master/audits'],
    slug: 'keep3r-network',
    tvl: 421739769.52998954,
    chainTvls: {
      Ethereum: 421739769.52998954,
      'Ethereum-staking': 5865442.349066248,
      'Ethereum-borrowed': 77262416.82162835,
      borrowed: 77262416.82162835,
      staking: 5865442.349066248,
    },
    change_1h: 0.4495757228523587,
    change_1d: 0.8208507621378942,
    change_7d: -17.12424714120418,
    staking: 5865442.349066248,
    fdv: 205087779,
    mcap: 205087779,
  },
  {
    id: '234',
    name: 'Notional',
    address: '0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5',
    symbol: 'NOTE',
    url: 'https://notional.finance/',
    description: 'Fixed rate lending on Ethereum\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/notional.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'notional-finance',
    cmcId: '14631',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'notional/index.js',
    twitter: 'NotionalFinance',
    audit_links: ['https://blog.openzeppelin.com/notional-audit/'],
    slug: 'notional',
    tvl: 412837343.09915406,
    chainTvls: { Ethereum: 412837343.09915406 },
    change_1h: 0.01764672030904535,
    change_1d: -2.250404974679739,
    change_7d: -18.947408221669548,
    fdv: 95982170,
    mcap: 11244118,
  },
  {
    id: '135',
    name: 'Kava',
    address: null,
    symbol: 'KAVA',
    url: 'https://www.kava.io/',
    description:
      'Kava.io is the first cross-chain DeFi hub providing applications and services to the worlds largest cryptocurrencies.',
    chain: 'Kava',
    logo: 'https://icons.llama.fi/kava.png',
    audits: null,
    audit_note: null,
    gecko_id: 'kava',
    cmcId: '4846',
    category: 'CDP',
    chains: ['Kava'],
    module: 'kava.js',
    twitter: 'kava_labs',
    audit_links: ['https://www.certik.org/projects/kava'],
    oracles: ['Chainlink'],
    slug: 'kava',
    tvl: 404984613.86953646,
    chainTvls: { Kava: 404984613.86953646 },
    change_1h: 0,
    change_1d: 1.4609463669624603,
    change_7d: 6.89025418040039,
    mcap: 773109605,
  },
  {
    id: '334',
    name: 'MDEX',
    address: 'bsc:0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
    symbol: 'MDX',
    url: 'https://mdex.com/',
    description: 'AMM',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mdex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mdex',
    cmcId: '8335',
    category: 'Dexes',
    chains: ['Binance', 'Heco'],
    module: 'mdex/index.js',
    twitter: 'Mdextech',
    audit_links: [
      'https://mdex.com/slowmist_bsc.pdf',
      'https://mdex.com/fairyproof_bsc.pdf',
      'https://mdex.com/certik_bsc.pdf',
    ],
    forkedFrom: ['Uniswap'],
    slug: 'mdex',
    tvl: 403288586.52805287,
    chainTvls: { Binance: 210813245.2344237, Heco: 192475341.29362917 },
    change_1h: -17.084986720192504,
    change_1d: -21.78090455910437,
    change_7d: -36.09243888913545,
    mcap: 237591201,
  },
  {
    id: '419',
    name: 'Saber',
    address: 'solana:Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1',
    symbol: 'SBR',
    url: 'https://saber.so',
    description:
      'Saber is the first automated market maker optimized for trading pegged assets on Solana. Our protocol enables Solana users and applications to efficiently trade between stable pairs of assets, as well as earn yields by providing liquidity to the platform.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/saber.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'saber',
    cmcId: '11181',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'saber.js',
    twitter: 'Saber_HQ',
    slug: 'saber',
    tvl: 385361782.85359305,
    chainTvls: { Solana: 385361782.85359305 },
    change_1h: -0.028166527829725396,
    change_1d: -1.8681410776173948,
    change_7d: -15.573419703802173,
    fdv: 370120814,
    mcap: 32147596,
  },
  {
    id: '556',
    name: 'Defi Kingdoms',
    address: 'harmony:0x72cb10c6bfa5624dd07ef608027e366bd690048f',
    symbol: 'JEWEL',
    url: 'https://www.defikingdoms.com',
    description:
      'DeFi Kingdoms is a game, a DEX, a liquidity pool opportunity, a market of rare utility driven NFTs, and it all plays out seamlessly in the incredibly nostalgic form of fantasy pixel art.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/defi-kingdoms.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'defi-kingdoms',
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'defikingdoms/index.js',
    twitter: 'DefiKingdoms',
    audit_links: ['https://solidity.finance/audits/DefiKingdoms/'],
    forkedFrom: ['Uniswap'],
    slug: 'defi-kingdoms',
    tvl: 383899448.9297017,
    chainTvls: { 'Harmony-staking': 304947665.5642521, Harmony: 383899448.9297017, staking: 304947665.5642521 },
    change_1h: 1.2204711286326813,
    change_1d: 2.512105157760331,
    change_7d: null,
    staking: 304947665.5642521,
    fdv: 4751560436,
    mcap: 727041546,
  },
  {
    id: '249',
    name: 'StakeDAO',
    address: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f',
    symbol: 'SDT',
    url: 'https://stakedao.org/',
    description:
      "Stake Capital DAO is a revenue sharing DAO which tokenizes Stake Capital's DeFi services and uses staking derivatives to unlock additional decentralized products. The Stake Capital DAO distributes value generated by a basket of DeFi services to stakeholders.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/stakedao.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: 'stake-dao',
    cmcId: '8299',
    category: 'Yield',
    chains: ['Ethereum', 'Avalanche', 'Harmony', 'Polygon', 'Binance'],
    module: 'stakedao/index.js',
    twitter: 'StakeDAOHQ',
    slug: 'stakedao',
    tvl: 378177513.2575142,
    chainTvls: {
      Avalanche: 89043611.3070984,
      Harmony: 46589110.11809836,
      Binance: 1255.6846809483645,
      Ethereum: 227659383.8684194,
      'Ethereum-staking': 11057392.90911154,
      Polygon: 14884152.279217074,
      staking: 11057392.90911154,
    },
    change_1h: 0.06593773383274026,
    change_1d: null,
    change_7d: null,
    staking: 11057392.90911154,
    fdv: 110481178,
    mcap: 32142972,
  },
  {
    id: '1407',
    name: 'Solidly',
    address: 'fantom:0x888ef71766ca594ded1f0fa3ae64ed2941740a20',
    symbol: 'SOLID',
    url: 'https://solidly.exchange/',
    description:
      'Solidly allows low cost, near 0 slippage trades on uncorrelated or tightly correlated assets. The protocol incentivizes fees instead of liquidity. Liquidity providers (LPs) are given incentives in the form of token',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/solidly.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'solidly',
    cmcId: '18399',
    category: 'Dexes',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: '-',
    module: 'solidly/index.js',
    twitter: 'solidlyexchange',
    language: 'Solidity',
    audit_links: ['https://github.com/andrecronje/solidly/blob/master/audits/e456a816-3802-4384-894c-825a4177245a.pdf'],
    listedAt: 1644842175,
    slug: 'solidly',
    tvl: 371854590.36084306,
    chainTvls: { Fantom: 371854590.36084306 },
    change_1h: -0.000421795664138358,
    change_1d: -2.328470146826575,
    change_7d: -40.02339370523409,
    fdv: 121819925,
    mcap: 0,
  },
  {
    id: '654',
    name: 'Beethoven X',
    address: 'fantom:0xf24bcf4d1e507740041c9cfd2dddb29585adce1e',
    symbol: 'BEETS',
    url: 'https://www.beethovenx.io',
    description:
      'We leverage best in breed DeFi protocols to offer novel decentralized investment strategies. Built on Balancer V2, Beethoven X is the first next-generation AMM protocol on Fantom.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/beethoven-x.png',
    audits: '3',
    audit_note: null,
    gecko_id: 'beethoven-x',
    cmcId: '13244',
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'beethovenx/index.js',
    twitter: 'beethoven_x',
    forkedFrom: ['Balancer'],
    slug: 'beethoven-x',
    tvl: 368854488.40893465,
    chainTvls: { Fantom: 368854488.40893465 },
    change_1h: -0.01717506321845974,
    change_1d: 2.359412040514755,
    change_7d: 26.136392472097356,
    fdv: 192208258,
    mcap: 58030995,
  },
  {
    id: '283',
    name: 'Orca',
    address: 'solana:orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE',
    symbol: 'ORCA',
    url: 'https://www.orca.so',
    description: 'AMM',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/orca.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'orca',
    cmcId: '11165',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'orca/index.js',
    twitter: 'orca_so',
    openSource: false,
    slug: 'orca',
    tvl: 367734841.12645394,
    chainTvls: { Solana: 367734841.12645394 },
    change_1h: 0.5477903862726379,
    change_1d: 0.23226003949574192,
    change_7d: 6.122250043005934,
    fdv: 225826784,
    mcap: 38717782,
  },
  {
    id: '653',
    name: 'Atrix',
    address: null,
    symbol: '-',
    url: 'https://www.atrix.finance',
    description: 'A Serum-based AMM on Solana allowing for permissionless liquidity and farming.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/atrix.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Solana'],
    module: 'atrix.js',
    twitter: 'AtrixProtocol',
    openSource: false,
    slug: 'atrix',
    tvl: 365820692.092132,
    chainTvls: { Solana: 365820692.092132 },
    change_1h: 0.44420159446553953,
    change_1d: 3.7821600281870786,
    change_7d: 15.577672846921729,
  },
  {
    id: '449',
    name: 'QiDao',
    address: 'polygon:0x580a84c73811e1839f75d86d75d88cca0c241ff4',
    symbol: 'QI',
    url: 'https://app.mai.finance',
    description:
      "QiDao is a way for you to keep your crypto and still be able to spend its value. That means you're able to borrow stablecoins without having to sell your crypto assets, and do so at 0% interest!",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/qidao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'qi-dao',
    cmcId: '10237',
    category: 'CDP',
    chains: ['Fantom', 'Polygon', 'Avalanche', 'Harmony', 'Moonriver', 'xDai'],
    module: 'qidao/index.js',
    twitter: 'QiDaoProtocol',
    audit_links: ['https://docs.mai.finance/risks/security#has-the-project-been-audited'],
    slug: 'qidao',
    tvl: 356750174.3265011,
    chainTvls: {
      Moonriver: 17535.917728382097,
      Avalanche: 4821971.756223952,
      Harmony: 291582.4203531054,
      xDai: 7903.03281059048,
      Polygon: 149411671.8879671,
      Fantom: 202199509.31141794,
    },
    change_1h: 0.3316555063345561,
    change_1d: 1.1272635970516802,
    change_7d: 14.469086246313395,
    fdv: 256895898,
    mcap: 82172703,
  },
  {
    id: '951',
    name: 'Pando',
    address: null,
    symbol: '-',
    url: 'https://pando.im',
    description:
      'Pando is a set of open financial protocols which includes 3 major protocols: 1.Pando Lake/4swap: a decentralized protocol for automated liquidity provision built with the Mixin Trusted Group. 2.Pando Leaf: a decentralized financial network to minting stablecoins. 3.Pando Rings: a decentralized protocol where you can lend or borrow cryptocurrencies',
    chain: 'Mixin',
    logo: 'https://icons.llama.fi/pando.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Mixin'],
    module: 'pando/index.js',
    twitter: 'pando_im',
    audit_links: ['https://docs.pando.im/docs/security/audit-reports'],
    listedAt: 1638382732,
    slug: 'pando',
    tvl: 354329057.08,
    chainTvls: { Mixin: 354329057.08 },
    change_1h: -0.3319226041674881,
    change_1d: 1.28202532267629,
    change_7d: 8.088284829419834,
  },
  {
    id: '1518',
    name: 'Bastion',
    address: '-',
    symbol: 'BSTN',
    url: 'https://app.bastionprotocol.com/',
    description: 'Decentralized lending on Aurora',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/bastion.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Aurora'],
    oracles: ['Flux'],
    forkedFrom: ['Compound'],
    module: 'bastion/index.js',
    twitter: 'BastionProtocol',
    audit_links: [],
    listedAt: 1646686350,
    slug: 'bastion',
    tvl: 352788313.3275386,
    chainTvls: { Aurora: 352788313.3275386, borrowed: 58291258.24288598, 'Aurora-borrowed': 58291258.24288598 },
    change_1h: 0.3476466918244512,
    change_1d: 1.3430574046370651,
    change_7d: 243.73626026032514,
  },
  {
    id: '797',
    name: 'Katana DEX',
    address: null,
    symbol: '-',
    url: 'https://katana.roninchain.com/#/swap',
    description: 'Katana a DEX For Ronin',
    chain: 'Ronin',
    logo: 'https://icons.llama.fi/katana.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ronin'],
    module: 'katana-ronin.js',
    twitter: 'AxieInfinity',
    forkedFrom: ['Uniswap'],
    listedAt: 1636260169,
    slug: 'katana-dex',
    tvl: 347464788.52597934,
    chainTvls: { Ronin: 347464788.52597934 },
    change_1h: 0.7857175688307905,
    change_1d: 2.843428916567234,
    change_7d: null,
  },
  {
    id: '502',
    name: 'Coinwind',
    address: 'heco:0x80861a817106665bca173db6ac2ab628a738c737',
    symbol: 'COW',
    url: 'https://www.coinwind.pro',
    description: 'CoinWind is a DeFi financial platform for smart mining.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/coinwind.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'coinwind',
    cmcId: '10011',
    category: 'Yield',
    chains: ['Binance', 'Heco', 'Ethereum'],
    module: 'coinwind/index.js',
    twitter: 'coinwind_com',
    audit_links: [
      'https://www.fairyproof.com/doc/CoinwindV2-Audit-Report-071821.pdf',
      'https://www.coinwind.pro/BEOSIN_en.pdf',
      'https://www.coinwind.pro/flyhub_en.pdf',
    ],
    slug: 'coinwind',
    tvl: 345782904.3648924,
    chainTvls: {
      pool2: 2745994.822907932,
      'Heco-staking': 1514470.0177401598,
      'Binance-pool2': 1048175.8745635131,
      Binance: 265657279.78935802,
      Ethereum: 39304309.58800456,
      'Binance-staking': 3739203.5915130298,
      Heco: 40821314.98752983,
      'Heco-pool2': 1697818.9483444192,
      staking: 5253673.6092531895,
    },
    change_1h: -0.4339122211852242,
    change_1d: 1.6419171307666431,
    change_7d: 5.224975891767343,
    staking: 5253673.6092531895,
    pool2: 2745994.822907932,
    mcap: 0,
  },
  {
    id: '504',
    name: 'Mento',
    address: '-',
    symbol: '-',
    url: 'https://mento.finance',
    description:
      'Mento is an automated market maker (AMM) type of decentralized exchange that’s native to the Celo platform. It’s responsible for helping the Celo Dollar (cUSD) maintain its peg to the USD.',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/mento.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'celo',
    cmcId: '5567',
    category: 'Dexes',
    chains: ['Celo'],
    module: 'mento/index.js',
    twitter: 'CeloOrg',
    audit_links: ['https://celo.org/audits'],
    slug: 'mento',
    tvl: 343411088.74149,
    chainTvls: { Celo: 343411088.74149 },
    change_1h: -0.021915924798832975,
    change_1d: -1.0137183903900677,
    change_7d: 15.935240754248056,
    fdv: 3514291061,
    mcap: 1501967972,
  },
  {
    id: '475',
    name: 'Yield Yak',
    address: 'avax:0x59414b3089ce2af0010e7523dea7e2b35d776ec7',
    symbol: 'YAK',
    url: 'https://yieldyak.com',
    description: 'Yield Yak is an easy-to-use tool to earn more yield from defi farming on the Avalanche network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/yield-yak.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'yield-yak',
    cmcId: '11415',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'yieldyak/index.js',
    twitter: 'yieldyak_',
    slug: 'yield-yak',
    tvl: 343002367.12915283,
    chainTvls: {
      'Avalanche-staking': 17690987.766970247,
      pool2: 1226047.6194784003,
      Avalanche: 343002367.12915283,
      'Avalanche-pool2': 1226047.6194784003,
      staking: 17690987.766970247,
    },
    change_1h: -0.0009119002639579321,
    change_1d: 8.701292079140615,
    change_7d: 13.603438190285402,
    staking: 17690987.766970247,
    pool2: 1226047.6194784003,
    fdv: 19357300,
    mcap: 17905502,
  },
  {
    id: '438',
    name: 'Tokemak',
    address: '0x2e9d63788249371f1dfc918a52f8d799f4a38c94',
    symbol: 'TOKE',
    url: 'https://www.tokemak.xyz',
    description:
      'Tokemak creates sustainable DeFi liquidity and capital efficient markets through a convenient decentralized market making protocol.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/tokemak.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'tokemak',
    cmcId: '11202',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'tokemak/index.js',
    twitter: 'TokenReactor',
    audit_links: ['https://omniscia.io/tokemak-capital-management-protocol/'],
    slug: 'tokemak',
    tvl: 333443343.51968956,
    chainTvls: {
      Ethereum: 333443343.51968956,
      pool2: 279305206.96603346,
      'Ethereum-staking': 13553850.828137342,
      staking: 13553850.828137342,
      'Ethereum-pool2': 279305206.96603346,
    },
    change_1h: 0.5934372988990901,
    change_1d: 5.34434515262538,
    change_7d: 34.6024574613146,
    staking: 13553850.828137342,
    pool2: 279305206.96603346,
    fdv: 2769462626,
    mcap: 341199427,
  },
  {
    id: '337',
    name: 'GMX',
    address: 'arbitrum:0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    symbol: 'GMX',
    url: 'https://gmx.io/',
    description:
      'GMX is a decentralized spot and perpetual exchange that supports low swap fees and zero price impact trades. Trading is supported by a unique multi-asset pool that earns liquidity providers fees from market making, swap fees, leverage trading (spreads, funding fees & liquidations) and asset rebalancing.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/gmx.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gmx',
    cmcId: '11857',
    category: 'Derivatives',
    chains: ['Arbitrum', 'Avalanche'],
    module: 'gmx/index.js',
    twitter: 'GMX_IO',
    audit_links: ['https://github.com/xvi10/gambit-contracts/tree/master/audits'],
    slug: 'gmx',
    tvl: 326217645.74159676,
    chainTvls: {
      'Avalanche-staking': 10325243.678075287,
      Avalanche: 90542223.1429011,
      'Arbitrum-staking': 153069059.6674985,
      Arbitrum: 235675422.59869567,
      staking: 163394303.34557378,
    },
    change_1h: 0.0007424264970836703,
    change_1d: 2.050517050644501,
    change_7d: 7.762829323138362,
    staking: 163394303.34557378,
    fdv: 356608703,
    mcap: 202024216,
  },
  {
    id: '123',
    name: 'dForce',
    address: '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0',
    symbol: 'DF',
    url: 'https://dforce.network/',
    description: 'dForce network is an ecosystem of protocols aiming to be a full stack solution for DeFi.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/DF.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'dforce-token',
    cmcId: '4758',
    category: 'Lending',
    chains: ['Arbitrum', 'Binance', 'Ethereum', 'Polygon', 'Optimism'],
    module: 'dforce/index.js',
    twitter: 'dForcenet',
    audit_links: ['https://github.com/dforce-network/documents/tree/master/audit_report/Lending'],
    forkedFrom: [],
    oracles: ['Chainlink', 'Band'],
    slug: 'dforce',
    tvl: 322249339.83602875,
    chainTvls: {
      Binance: 106759809.05115381,
      Optimism: 699726.0814306547,
      Ethereum: 65779385.006724544,
      Polygon: 10566762.497769924,
      Arbitrum: 138443657.1989498,
    },
    change_1h: 0.15266810226228245,
    change_1d: 2.492994858428105,
    change_7d: -36.03123568573503,
    fdv: 85567785,
    mcap: 35473465,
  },
  {
    id: '1524',
    name: 'Mars Protocol',
    address: 'terra:terra12hgwnpupflfpuual532wgrxu2gjp0tcagzgx4n',
    symbol: 'MARS',
    url: 'https://marsprotocol.io',
    description:
      'Lend, borrow and earn with an autonomous credit protocol on the Terra blockchain. Open to all, closed to none',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/mars-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mars-protocol',
    cmcId: '18621',
    category: 'Lending',
    chains: ['Terra'],
    oracles: ['TWAP'],
    forkedFrom: [],
    module: 'mars/index.js',
    twitter: 'mars_protocol',
    audit_links: ['https://github.com/mars-protocol/mars-audits'],
    openSource: true,
    listedAt: 1646854377,
    slug: 'mars-protocol',
    tvl: 316281020.8145553,
    chainTvls: { 'Terra-borrowed': 64274285.21025164, Terra: 316281020.8145553, borrowed: 64274285.21025164 },
    change_1h: -0.2520075001378359,
    change_1d: 8.766330271399013,
    change_7d: 8.413572413621239,
    mcap: 0,
  },
  {
    id: '202',
    name: 'Saddle Finance',
    address: '-',
    symbol: 'SDL',
    url: 'https://saddle.finance/',
    description: 'Saddle is an automated market maker for pegged value crypto assets.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/saddle-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'saddle-finance',
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum', 'Fantom', 'Arbitrum', 'Optimism'],
    module: 'saddle/index.js',
    twitter: 'saddlefinance',
    audit_links: ['https://github.com/saddle-finance/saddle-audits'],
    forkedFrom: [],
    slug: 'saddle-finance',
    tvl: 310905986.81374663,
    chainTvls: {
      Optimism: 5376.814404527346,
      Ethereum: 215315378.39992213,
      Arbitrum: 27555003.56162616,
      Fantom: 68030228.03779382,
    },
    change_1h: -0.035185194034468736,
    change_1d: 3.2513796327747997,
    change_7d: 7.247154468470086,
  },
  {
    id: '285',
    name: 'Opyn',
    address: null,
    symbol: '-',
    url: 'https://www.opyn.co/',
    description: 'Options market',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/opyn.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Options',
    chains: ['Ethereum', 'Avalanche'],
    module: 'opyn/index.js',
    twitter: 'opyn_',
    audit_links: ['https://opyn.gitbook.io/opyn/get-started/security#audits'],
    oracles: ['Chainlink'],
    slug: 'opyn',
    tvl: 296931280.8861171,
    chainTvls: { Ethereum: 290716507.33748585, Avalanche: 6214773.548631275 },
    change_1h: 0.36331420830673267,
    change_1d: 2.349424679805793,
    change_7d: -2.6501041078998924,
  },
  {
    id: '281',
    name: 'Ribbon Finance',
    address: '0x6123b0049f904d730db3c36a31167d9d4121fa6b',
    symbol: 'RBN',
    url: 'https://www.ribbon.finance/',
    description: 'Structured products protocol',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/ribbon-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'ribbon-finance',
    cmcId: '12387',
    category: 'Options',
    chains: ['Ethereum', 'Avalanche', 'Solana'],
    module: 'ribbon/index.js',
    twitter: 'ribbonfinance',
    audit_links: ['https://docs.ribbon.finance/developers/security#audits'],
    slug: 'ribbon-finance',
    tvl: 295744682.0469553,
    chainTvls: {
      Ethereum: 282851729.1155019,
      Avalanche: 8799635.81005618,
      'Ethereum-staking': 12250762.082952324,
      Solana: 4093317.121397193,
      staking: 12250762.082952324,
    },
    change_1h: 0.35740395203760045,
    change_1d: 2.3459328674705944,
    change_7d: 9.802732154251714,
    staking: 12250762.082952324,
    fdv: 1481396129,
    mcap: 77160244,
  },
  {
    id: '199',
    name: 'Sablier Finance',
    address: '-',
    symbol: '-',
    url: 'https://sablier.finance/',
    description: 'The protocol for real-time finance.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/sablier-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Payments',
    chains: ['Ethereum'],
    module: 'sablier/index.js',
    twitter: 'SablierHQ',
    audit_links: ['https://certificate.quantstamp.com/full/sablier'],
    slug: 'sablier-finance',
    tvl: 292076413.27764845,
    chainTvls: { Ethereum: 292076413.27764845 },
    change_1h: 0.3556383627130657,
    change_1d: -1.4174587290005718,
    change_7d: 28.470352124999806,
  },
  {
    id: '266',
    name: 'Set Protocol',
    address: '-',
    symbol: '-',
    url: 'https://www.tokensets.com/',
    description:
      'A protocol for building portfolios as digital assets (ERC-20) that represent a fully collateralized digital asset (ERC-20) that represents a fully collateralized portfolio of other assets including Bitcoin (WBTC), Ethereum (WETH), and fiat-pegged coins (DAI).portfolio of other assets including Bitcoin (WBTC), Ethereum (WETH), and fiat-pegged coins (DAI).',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/set-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '1115',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'set-protocol/index.js',
    twitter: 'SetProtocol',
    audit_links: ['https://www.tokensets.com/security'],
    oracles: ['Chainlink'],
    slug: 'set-protocol',
    tvl: 288666000.1788023,
    chainTvls: { Ethereum: 288666000.1788023 },
    change_1h: 1.7004916111439456,
    change_1d: 5.635551629466633,
    change_7d: 20.526702684562977,
  },
  {
    id: '522',
    name: 'Wonderland',
    address: 'avax:0xb54f16fb19478766a268f172c9480f8da1a7c9c3',
    symbol: 'TIME',
    url: 'https://www.wonderland.money',
    description:
      'Wonderland is the first decentralized reserve currency protocol available on the Avalanche Network based on the TIME token.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wonderland.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'wonderland',
    cmcId: '11585',
    category: 'Reserve Currency',
    chains: ['Avalanche', 'Ethereum'],
    module: 'wonderland/index.js',
    twitter: 'Wonderland_fi',
    forkedFrom: ['Olympus DAO'],
    slug: 'wonderland',
    tvl: 283196317.8616717,
    chainTvls: {
      'Avalanche-staking': 541032893.0863304,
      Ethereum: 77681205.16678423,
      Avalanche: 205515112.6948875,
      staking: 541032893.0863304,
    },
    change_1h: 0.03958429828850285,
    change_1d: -2.9903014683758897,
    change_7d: 0.7890664387005302,
    staking: 541032893.0863304,
    fdv: 129677578,
    mcap: 122021631,
  },
  {
    id: '1167',
    name: 'DefiChain Loans',
    address: '-',
    symbol: 'DFI',
    url: 'https://defichain.com',
    description: 'Over-collateralized loans on DefiChain.',
    chain: 'DefiChain',
    logo: 'https://icons.llama.fi/defichain.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['DefiChain'],
    module: 'defichain-loans.js',
    twitter: 'defichain',
    audit_links: ['https://defichain.com/security/'],
    slug: 'defichain-loans',
    tvl: 281801076.35674864,
    chainTvls: { DefiChain: 281801076.35674864 },
    change_1h: -0.007018737128717589,
    change_1d: null,
    change_7d: 22.702510327741223,
  },
  {
    id: '706',
    name: 'YSL.IO',
    address: 'bsc:0x64d3638a7d8747eee7bd5d402cc5f5bd00dc27dc',
    symbol: 'SYSL',
    url: 'https://ysl.io',
    description:
      'A cutting-edge DeFi tool designed to OPTIMISE and AMPLIFY the returns from yield farming platforms, whilst maximising the benefits of LOCKED LIQUIDITY to create a truly unparalleled token economy.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/ysl.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ysl-io',
    cmcId: '11334',
    category: 'Yield',
    chains: ['Binance'],
    module: 'yslio/index.js',
    twitter: 'ysl_io',
    audit_links: ['https://docs.ysl.io/ysl-io/protocol/audits'],
    listedAt: 1635107834,
    slug: 'ysl.io',
    tvl: 280327943.01933265,
    chainTvls: { Binance: 280327943.01933265 },
    change_1h: -0.000013346605413744328,
    change_1d: -0.007815006738979946,
    change_7d: -0.05957524218381138,
    mcap: 0.00049806,
  },
  {
    id: '112',
    name: 'Harvest Finance',
    address: '0xa0246c9032bC3A600820415aE600c6388619A14D',
    symbol: 'FARM',
    url: 'https://harvest.finance/',
    description: 'Harvest automatically farms the highest yields in DeFi',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/harvest-finance.png',
    audits: '1',
    audit_note: null,
    gecko_id: 'harvest-finance',
    cmcId: '6859',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Polygon', 'Binance'],
    module: 'harvest.js',
    twitter: 'harvest_finance',
    audit_links: ['https://github.com/harvest-finance/harvest/tree/master/audits'],
    slug: 'harvest-finance',
    tvl: 258224310.44774678,
    chainTvls: { Binance: 803145.2675868133, Ethereum: 248824662.45819515, Polygon: 8596502.7219648 },
    change_1h: 0.21763290408442515,
    change_1d: 5.987710153362727,
    change_7d: 8.883568864669584,
    fdv: 77198512,
    mcap: 73574679,
  },
  {
    id: '431',
    name: 'SUN.io',
    address: 'tron:TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S',
    symbol: 'SUN',
    url: 'https://sun.io',
    description: 'First integrated platform for stablecoin swap, stake-mining, and self-governance on TRON',
    chain: 'Tron',
    logo: 'https://icons.llama.fi/sun.io.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'sun-token',
    cmcId: '10529',
    category: 'Dexes',
    chains: ['Tron'],
    module: 'sun/index.js',
    twitter: 'defi_sunio',
    audit_links: ['https://sun.io/docs/slowmist_audit_report_en_sun.pdf'],
    slug: 'sun.io',
    tvl: 247600457.07671085,
    chainTvls: {
      pool2: 23178152.63896354,
      Tron: 247600457.07671085,
      'Tron-pool2': 23178152.63896354,
      'Tron-staking': 14656168.547971569,
      staking: 14656168.547971569,
    },
    change_1h: -0.060371484026049416,
    change_1d: 0.1559783672415307,
    change_7d: -0.48740634251579706,
    staking: 14656168.547971569,
    pool2: 23178152.63896354,
    mcap: 158227127,
  },
  {
    id: '620',
    name: 'Francium',
    address: '-',
    symbol: '-',
    url: 'https://francium.io',
    description:
      'Francium is a DeFi yield strategy Platform on Solana, providing leveraged/hedged farming, DeFi strategies & on-chain trading strategies. Francium also help users to build their own yield strategy through strategy dev toolbox. Granted by Solana & Serum.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/francium.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Solana'],
    module: 'francium.js',
    twitter: 'Francium_Defi',
    audit_links: ['https://www.certik.org/projects/francium'],
    oracles: [],
    openSource: false,
    slug: 'francium',
    tvl: 241956687.6714117,
    chainTvls: { Solana: 241956687.6714117 },
    change_1h: 0.32316133666472524,
    change_1d: 0.7527910276171781,
    change_7d: -8.364965300831699,
  },
  {
    id: '172',
    name: 'Index Coop',
    address: '0x0954906da0Bf32d5479e25f46056d22f08464cab',
    symbol: 'INDEX',
    url: 'https://www.indexcoop.com/',
    description:
      "Index Coop creates and maintains the world's best crypto index products.\r\n\r\nAll index products are always fully collateralized.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/index-coop.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'index-cooperative',
    cmcId: '7336',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'indexcoop/index.js',
    twitter: 'indexcoop',
    slug: 'index-coop',
    tvl: 241621123.95828196,
    chainTvls: { Ethereum: 241621123.95828196 },
    change_1h: 0.28977482126421705,
    change_1d: 4.914793519075175,
    change_7d: 20.249843757807213,
    fdv: 102883626,
    mcap: 22442882,
  },
  {
    id: '744',
    name: 'Tranquil Finance',
    address: 'harmony:0xcf1709ad76a79d5a60210f23e81ce2460542a836',
    symbol: 'TRANQ',
    url: 'https://tranquil.finance/',
    description:
      'Tranquil Finance is the first algorithmic money market protocol on Harmony. Tranquil enables users to lend and borrow assets permissionlessly on a blockchain with very fast and cheap transactions.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/tranq.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'tranquil-finance',
    cmcId: '14653',
    category: 'Lending',
    chains: ['Harmony'],
    module: 'tranquil/index.js',
    twitter: 'tranquil_fi',
    oracles: ['TWAP'],
    forkedFrom: ['Compound'],
    audit_links: ['https://www.certik.com/projects/tranquil-finance'],
    listedAt: 1635819874,
    slug: 'tranquil-finance',
    tvl: 238534355.90088058,
    chainTvls: {
      'Harmony-pool2': 401466.69723411853,
      pool2: 401466.69723411853,
      'Harmony-staking': 7988496.002254928,
      Harmony: 238534355.90088058,
      'Harmony-borrowed': 162973165.97711426,
      borrowed: 162973165.97711426,
      staking: 7988496.002254928,
    },
    change_1h: 1.1579865764956025,
    change_1d: 1.1454896236669185,
    change_7d: 2.1818409684734092,
    staking: 7988496.002254928,
    pool2: 401466.69723411853,
    fdv: 462264697,
    mcap: 25283447,
  },
  {
    id: '311',
    name: 'SpiritSwap',
    address: 'fantom:0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    symbol: 'SPIRIT',
    url: 'https://app.spiritswap.finance/#/',
    description: 'AMM',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/spiritswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'spiritswap',
    cmcId: '1359',
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'spiritswap/index.js',
    twitter: 'Spirit_Swap',
    audit_links: [
      'https://github.com/Layer3Org/spiritswap-core/blob/main/SpiritSwap-Core%20Security%20Audit%20Report.pdf',
    ],
    forkedFrom: ['Uniswap'],
    slug: 'spiritswap',
    tvl: 234467368.159586,
    chainTvls: { 'Fantom-staking': 31887185.40091915, staking: 31887185.40091915, Fantom: 234467368.159586 },
    change_1h: 0.544975314216444,
    change_1d: 2.1177114876919774,
    change_7d: 18.505046593264836,
    staking: 31887185.40091915,
    fdv: 105953318,
    mcap: 28982332,
  },
  {
    id: '225',
    name: 'Enzyme Finance',
    address: '0xec67005c4e498ec7f55e092bd1d35cbc47c91892',
    symbol: 'MLN',
    url: 'https://enzyme.finance/',
    description: 'Fast & cost-effective way to build, scale and monetize investment strategies.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/enzyme-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'melon',
    cmcId: '1552',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'enzyme/index.js',
    twitter: 'enzymefinance',
    audit_links: ['https://audit.enzyme.finance/'],
    oracles: ['Chainlink'],
    slug: 'enzyme-finance',
    tvl: 233399381.39401355,
    chainTvls: { Ethereum: 233399381.39401355 },
    change_1h: -0.06822104791626771,
    change_1d: 2.5363221244327576,
    change_7d: 18.804970128249693,
    fdv: 133083586,
    mcap: 91056605,
  },
  {
    id: '246',
    name: 'Pangolin',
    address: 'avax:0x60781C2586D68229fde47564546784ab3fACA982',
    symbol: 'PNG',
    url: 'https://pangolin.exchange',
    description: 'Pangolin is a community-driven DEX that runs on Avalanche. ',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/pangolin.png',
    audits: '3',
    audit_note: null,
    gecko_id: 'pangolin',
    cmcId: '8422',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'pangolin/index.js',
    twitter: 'pangolindex',
    forkedFrom: ['Uniswap'],
    slug: 'pangolin',
    tvl: 232018355.97395957,
    chainTvls: { Avalanche: 232018355.97395957 },
    change_1h: 0.0982583513513049,
    change_1d: -0.35297715723649503,
    change_7d: 9.658251551897592,
    fdv: 113612839,
    mcap: 19447654,
  },
  {
    id: '1450',
    name: 'Solidex',
    address: 'fantom:0xd31fcd1f7ba190dbc75354046f6024a9b86014d7',
    symbol: 'SEX',
    url: 'https://solidexfinance.com',
    description: 'Solidex is a protocol to optimize yield, voting power, and liquidity provisioning on Solidly.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/solidex.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'solidex',
    cmcId: '18398',
    category: 'Yield Aggregator',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'solidex/index.js',
    twitter: 'SolidexFantom',
    listedAt: 1645578071,
    slug: 'solidex',
    tvl: 231383895.23255736,
    chainTvls: { Fantom: 231383895.23255736 },
    change_1h: 0.18579826832998947,
    change_1d: 1.2197014537943573,
    change_7d: -40.8683848907985,
    mcap: 3453098,
  },
  {
    id: '441',
    name: 'Pylon Protocol',
    address: 'terra:terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy',
    symbol: 'MINE',
    url: 'https://www.pylon.money',
    description:
      'Pylon consists of a suite of savings and payments products in Decentralized Finance (DeFi) that builds on stable yield-bearing protocols such as Terra’s Anchor Protocol',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/pylon-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pylon-protocol',
    cmcId: '10767',
    category: 'Yield',
    chains: ['Terra'],
    module: 'pylon/index.js',
    twitter: 'pylon_protocol',
    audit_links: ['https://docs.pylon.money/security#audits'],
    openSource: true,
    slug: 'pylon-protocol',
    tvl: 230818033.57662657,
    chainTvls: {
      'Terra-staking': 19173371.89985833,
      pool2: 45240902.38857356,
      Terra: 230818033.57662657,
      'Terra-pool2': 45240902.38857356,
      staking: 19173371.89985833,
    },
    change_1h: -0.19789039851642087,
    change_1d: 0.6214061978693195,
    change_7d: 2.556494470032149,
    staking: 19173371.89985833,
    pool2: 45240902.38857356,
    fdv: 419301804,
    mcap: 83985266,
  },
  {
    id: '577',
    name: 'Allbridge',
    address: 'celo:0x6e512BFC33be36F2666754E996ff103AD1680Cc9',
    symbol: 'ABR',
    url: 'https://allbridge.io',
    description:
      'Allbridge is a simple, modern, and reliable way to transfer assets between different networks. It is a bridge between both EVM and non-EVM compatible blockchains, that aims to cover L2 solutions and NFT transfers in the future.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/allbridge.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'allbridge',
    cmcId: '12212',
    category: 'Bridge',
    chains: [
      'Ethereum',
      'Solana',
      'Terra',
      'Celo',
      'Binance',
      'Avalanche',
      'Polygon',
      'Fantom',
      'Heco',
      'Near',
      'Harmony',
      'Fuse',
      'Aurora',
    ],
    module: 'allbridge/index.js',
    audit_links: ['https://hacken.io/audits/#allbridge'],
    twitter: 'Allbridge_io',
    slug: 'allbridge',
    tvl: 228055565.5304769,
    chainTvls: {
      'Celo-staking': 60325.43395135674,
      'Harmony-staking': 11034.513944264365,
      'Fuse-staking': 22414.999345226475,
      'Fantom-staking': 90911.82284890597,
      Harmony: 0,
      'Near-staking': 26937.50140926278,
      'Terra-staking': 175674.8959604,
      Binance: 4762619.72009548,
      Ethereum: 193845884.27182576,
      Heco: 443834.5840634863,
      'Binance-staking': 123963.17256225145,
      Avalanche: 2418766.815857393,
      Celo: 6522276.034652911,
      Fuse: 0,
      Fantom: 1013847.3344308479,
      staking: 1858421.1229497506,
      'Aurora-staking': 115996.54684986848,
      Terra: 7717247.175002496,
      Near: 152940.06913946566,
      'Heco-staking': 11388.47462126019,
      'Solana-staking': 1055221.5254496457,
      Aurora: 0,
      'Avalanche-staking': 89807.91327934439,
      Polygon: 1915852.3698166942,
      Solana: 9262297.15559236,
      'Polygon-staking': 74744.32272796442,
    },
    change_1h: 0.12017866889053153,
    change_1d: 0.7853055970369525,
    change_7d: 9.365310845490853,
    staking: 1858421.1229497506,
    fdv: 183291797,
    mcap: 5599232,
  },
  {
    id: '149',
    name: 'B.Protocol',
    address: '0xbbBBBBB5AA847A2003fbC6b5C16DF0Bd1E725f61',
    symbol: 'BPRO',
    url: 'https://www.bprotocol.org/',
    description:
      'B.Protocol makes lending platforms more secure by eliminating the gas wars and shifting the miner’s profit to the users.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/b.protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'b-protocol',
    cmcId: '10556',
    category: 'Lending',
    chains: ['Ethereum', 'Arbitrum', 'Fantom'],
    module: 'bprotocol/index.js',
    twitter: 'bprotocoleth',
    audit_links: ['https://app.bprotocol.org/risk'],
    oracles: ['Chainlink'],
    slug: 'b.protocol',
    tvl: 226401819.083085,
    chainTvls: { Ethereum: 224008977.65020007, Arbitrum: 1581378.4888541023, Fantom: 811462.9440308176 },
    change_1h: 0.059553613363164004,
    change_1d: 0.30786477196124906,
    change_7d: -0.27321523108005863,
    fdv: 40830096,
    mcap: 13694602,
  },
  {
    id: '279',
    name: 'StakeHound',
    address: '0x160b1e5aabfd70b2fc40af815014925d71ceed7e',
    symbol: '-',
    url: 'https://stakehound.com/',
    description: 'Liquid staking provider.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/stakehound.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'stakehound',
    cmcId: '7566',
    category: 'Liquid Staking',
    chains: ['Ethereum'],
    module: 'stakehound/index.js',
    twitter: 'stakedTokens',
    audit_links: ['https://stakehound.com/wp-content/uploads/2020/12/StakeHound-Quantstamp-audit-report.pdf'],
    slug: 'stakehound',
    tvl: 225769416.56167814,
    chainTvls: { Ethereum: 225769416.56167814 },
    change_1h: 0,
    change_1d: 4.025344316270221,
    change_7d: 15.389054261126773,
    mcap: 0,
  },
  {
    id: '195',
    name: 'DefiDollar DAO',
    address: '0x20c36f062a31865bed8a5b1e512d9a1a20aa333a',
    symbol: 'DFD',
    url: 'https://dusd.finance/',
    description: 'DefiDollar is a Multichain DeFi Protocol Lab',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/defidollar-dao.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'defidollar-dao',
    cmcId: '7593',
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'defidollar/index.js',
    twitter: 'defidollar',
    audit_links: ['https://docs.dusd.finance/audits'],
    oracles: ['Chainlink'],
    slug: 'defidollar-dao',
    tvl: 221754550.3460565,
    chainTvls: { Ethereum: 221754550.3460565 },
    change_1h: 0.03938248660000454,
    change_1d: 1.580987840377432,
    change_7d: 11.003287250140374,
    fdv: 4100241,
    mcap: 896632,
  },
  {
    id: '403',
    name: 'Liquid Driver',
    address: 'fantom:0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9',
    symbol: 'LQDR',
    url: 'https://www.liquiddriver.finance',
    description:
      'LiquidDriver is a High-Yield liquidity mining DAPP launched on fantom, incentivizing liquidity providers on SushiSwap.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/liquid-driver.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'liquiddriver',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'liquiddriver/index.js',
    twitter: 'LiquidDriver',
    slug: 'liquid-driver',
    tvl: 219773290.52097905,
    chainTvls: { 'Fantom-staking': 46048855.23565662, staking: 46048855.23565662, Fantom: 219773290.52097905 },
    change_1h: 0.67856288689498,
    change_1d: 3.088945940400606,
    change_7d: 77.77122294630433,
    staking: 46048855.23565662,
    mcap: 26740305,
  },
  {
    id: '434',
    name: 'Tarot',
    address: 'fantom:0xc5e2b037d30a390e62180970b3aa4e91868764cd',
    symbol: 'TAROT',
    url: 'https://www.tarot.to',
    description:
      'Protocol users can deposit LP tokens in Tarot Vaults and receive Vault Tokens. The deposited LP tokens are then farmed and earned rewards are automatically converted to additional LP tokens and reinvested.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/tarot.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'tarot',
    cmcId: '11409',
    category: 'Lending',
    chains: ['Fantom'],
    module: 'tarot/index.js',
    twitter: 'TarotFinance',
    forkedFrom: ['Impermax Finance'],
    slug: 'tarot',
    tvl: 218052605.4856602,
    chainTvls: { Fantom: 218052605.4856602 },
    change_1h: 0.534628320505945,
    change_1d: 0.178656805619255,
    change_7d: 9.810392712489573,
    fdv: 42359152,
    mcap: 12009168,
  },
  {
    id: '1054',
    name: 'ChainPort',
    address: null,
    symbol: '-',
    url: 'https://app.chainport.io',
    description:
      'ChainPort is a next-gen blockchain secure bridge that lets you hop across various blockchains at a click ',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/chainport.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Ethereum'],
    module: 'chainport/index.js',
    twitter: 'chain_port',
    audit_links: ['https://www.certik.com/projects/chainport'],
    listedAt: 1639946121,
    slug: 'chainport',
    tvl: 217674632,
    chainTvls: { Ethereum: 217674632 },
    change_1h: 0.40649118326885514,
    change_1d: -0.9791519172891014,
    change_7d: 25.084816038411134,
  },
  {
    id: '340',
    name: 'Olympus DAO',
    address: '0x383518188c0c6d7730d91b2c03a03c837814a899',
    symbol: 'OHM',
    url: 'https://olympusdao.finance/',
    description: '$OHM is a decentralized reserve currency that remains unbound by a peg.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/olympus-dao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'olympus',
    cmcId: '9067',
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    module: 'olympus/index.js',
    twitter: 'OlympusDAO',
    audit_links: ['https://docs.olympusdao.finance/references/audits'],
    slug: 'olympus-dao',
    tvl: 216845466.5631709,
    chainTvls: { Ethereum: 216845466.5631709, 'Ethereum-staking': 31171305.250009764, staking: 31171305.250009764 },
    change_1h: -0.0044670372954556115,
    change_1d: 0.8279417329758871,
    change_7d: -7.980260369051578,
    staking: 31171305.250009764,
    fdv: 604467078,
    mcap: 548842186,
  },
  {
    id: '1260',
    name: 'ThetaSwap',
    address: null,
    symbol: '-',
    url: 'https://swap.thetatoken.org/swap',
    description:
      'ThetaSwap is the first decentralized exchange built on Theta blockchain, using an open-source smart contract system built based on Automated Market Maker logic similar to that of Uniswap.',
    chain: 'Theta',
    logo: 'https://icons.llama.fi/thetaswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Theta'],
    module: 'thetaswap/index.js',
    twitter: 'Theta_Network',
    forkedFrom: ['Uniswap'],
    listedAt: 1642191461,
    slug: 'thetaswap',
    tvl: 212196279.08036852,
    chainTvls: { Theta: 212196279.08036852 },
    change_1h: 0.9802428071390921,
    change_1d: -2.5425709503661693,
    change_7d: 21.70207577154288,
  },
  {
    id: '1494',
    name: 'Minswap',
    address: 'cardano:asset1d9v7aptfvpx7we2la8f25kwprkj2ma5rp6uwzv',
    symbol: 'MIN',
    url: 'https://minswap.org',
    description:
      'Minswap aims to be the best liquidity provider on the market by integrating the best asset pool models from across the DEX ecosystem into one protocol.',
    chain: 'Cardano',
    logo: 'https://icons.llama.fi/minswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cardano'],
    oracles: [],
    forkedFrom: [],
    module: 'minswap/index.js',
    twitter: 'MinswapDEX',
    audit_links: ['https://github.com/minswap/contracts#audit-report'],
    listedAt: 1646175638,
    slug: 'minswap',
    tvl: 206033174.1346699,
    chainTvls: { Cardano: 206033174.1346699 },
    change_1h: 0.5596306580913648,
    change_1d: -6.395399766422088,
    change_7d: 9.471409675479109,
  },
  {
    id: '397',
    name: 'ShibaSwap',
    address: '0x9813037ee2218799597d83d4a5b6f3b6778218d9',
    symbol: 'BONE',
    url: 'https://shibaswap.com',
    description:
      'SHIB, LEASH, and BONE, come together to create ShibaSwap, the next evolution in DeFi platforms. ShibaSwap gives users the ability to DIG (provide liquidity), BURY (stake), and SWAP tokens to gain WOOF Returns through our sophisticated and innovative passive income reward system.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/shibaswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bone-shibaswap',
    cmcId: '11865',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'shibaswap/index.js',
    twitter: 'Shibtoken',
    audit_links: ['https://www.certik.org/projects/shib'],
    forkedFrom: ['Uniswap'],
    slug: 'shibaswap',
    tvl: 202939718.8317713,
    chainTvls: { Ethereum: 202939718.8317713 },
    change_1h: 0.9050716545224304,
    change_1d: 3.339605398631491,
    change_7d: 1.4479662836609322,
    mcap: 43347817,
  },
  {
    id: '398',
    name: 'ApeSwap',
    address: 'bsc:0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
    symbol: 'BANANA',
    url: 'https://apeswap.finance',
    description:
      'Built by DeFi apes, for DeFi apes. ApeSwap is a decentralized exchange (DEX) on Binance Smart Chain and Polygon. Users are incentivized to pool liquidity on ApeSwap through yield farming to earn the native currency, $BANANA. Additionally, apes can use their earned $BANANA to stake and earn other tokens and unlock exclusive features',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/apeswap.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'apeswap-finance',
    cmcId: '8497',
    category: 'Dexes',
    chains: ['Binance', 'Polygon'],
    module: 'apeswap/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'ape_swap',
    audit_links: 'https://apeswap.gitbook.io/apeswap-finance/security/audits',
    slug: 'apeswap',
    tvl: 201122039.5368486,
    chainTvls: {
      'Binance-borrowed': 40827142.31752899,
      Binance: 172831851.32981583,
      'Binance-staking': 21500298.213363744,
      Polygon: 28290188.207032785,
      borrowed: 40827142.31752899,
      staking: 21500298.213363744,
    },
    change_1h: 0.6342727380813216,
    change_1d: 3.0568218284251003,
    change_7d: 41.91019995850368,
    staking: 21500298.213363744,
    fdv: 58775285,
    mcap: 48095684,
  },
  {
    id: '140',
    name: 'Kava Lend',
    address: '-',
    symbol: 'HARD',
    url: 'https://kava.io/lend',
    description: 'Cross-chain money market. Lend, borrow, and earn with your crypto.\r\n',
    chain: 'Kava',
    logo: 'https://icons.llama.fi/kava-lend.png',
    audits: null,
    audit_note: null,
    gecko_id: 'kava-lend',
    cmcId: '7576',
    category: 'Lending',
    chains: ['Kava'],
    module: 'hard.js',
    twitter: 'hard_protocol',
    audit_links: ['https://www.certik.org/projects/hard'],
    oracles: ['Chainlink'],
    slug: 'kava-lend',
    tvl: 195830229.6046876,
    chainTvls: { Kava: 195830229.6046876, borrowed: 45085041.163609274, 'Kava-borrowed': 45085041.163609274 },
    change_1h: 0.040180325313386334,
    change_1d: 1.1995949656127465,
    change_7d: 6.998445623695233,
    fdv: 96846418,
    mcap: 53366412,
  },
  {
    id: '209',
    name: 'Autofarm',
    address: 'bsc:0xa184088a740c695e156f91f5cc086a06bb78b827',
    symbol: 'AUTO',
    url: 'https://autofarm.network/',
    description:
      'The Autofarm ecosystem is a one-stop DeFi suite for all users. Autofarm aggregate yield opportunities using vaults and best swap rates using a DEX aggregator (AutoSwap). In addition, other products such as AutoAnalytics, AutoTrade & AutoPortfolio are set to go live later in 2021. The platform is currently live on BSC, HECO, Polygon, Avalanche, Fantom & Moonriver, with more chains to be introduced soon.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/autofarm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'auto',
    cmcId: '8387',
    category: 'Yield Aggregator',
    chains: [
      'Binance',
      'Cronos',
      'Polygon',
      'Fantom',
      'Avalanche',
      'Moonriver',
      'Heco',
      'Boba',
      'Celo',
      'Velas',
      'xDai',
      'OKExChain',
      'Harmony',
      'Aurora',
    ],
    module: 'autofarm.js',
    twitter: 'autofarmnetwork',
    audit_links: ['https://www.certik.org/projects/autofarm'],
    forkedFrom: ['Yearn Finance'],
    slug: 'autofarm',
    tvl: 193016714.49652436,
    chainTvls: {
      Cronos: 24917578.707628977,
      OKExChain: 175617.3502070577,
      Moonriver: 3025890.0596241644,
      Avalanche: 3273531.1677367315,
      Harmony: 101755.55634858033,
      Velas: 347628.74789904244,
      Binance: 129883029.85785471,
      Boba: 665783.356434241,
      xDai: 273891.36822359124,
      Aurora: 121.54580433773977,
      Heco: 1729686.707535406,
      Polygon: 24459307.282664735,
      Celo: 369391.99853473983,
      Fantom: 3793500.790028016,
    },
    change_1h: 0.0005704970930082709,
    change_1d: 0.31392256528532414,
    change_7d: 5.124654063535658,
    fdv: 36755173,
    mcap: 16063404,
  },
  {
    id: '277',
    name: 'StakeWise',
    address: '0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2',
    symbol: 'SWISE',
    url: 'https://stakewise.io/',
    description: 'Stake your ETH and manage your capital flexibly with the principal and yield tokens from StakeWise.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/stakewise.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'stakewise',
    cmcId: '10439',
    category: 'Liquid Staking',
    chains: ['Ethereum'],
    module: 'stakewise/index.js',
    twitter: 'stakewise_io',
    audit_links: ['https://github.com/stakewise/contracts/tree/master/audits'],
    slug: 'stakewise',
    tvl: 192310913.29652002,
    chainTvls: { Ethereum: 192310913.29652002 },
    change_1h: 0.6233285570915683,
    change_1d: 4.068762810773194,
    change_7d: 17.220994029229672,
    fdv: 160101671,
    mcap: 13739390,
  },
  {
    id: '265',
    name: 'Reflexer',
    address: '0x6243d8cea23066d098a15582d81a598b4e8391f4',
    symbol: 'FLX',
    url: 'https://reflexer.finance/',
    description: 'A decentralized, stable and non pegged currency made for the digital economy',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/reflexer.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'reflexer-ungovernance-token',
    cmcId: '9493',
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'reflexer/index.js',
    twitter: 'reflexerfinance',
    audit_links: ['https://reflexer.finance/about/'],
    oracles: ['Chainlink'],
    slug: 'reflexer',
    tvl: 187841016.50909904,
    chainTvls: { Ethereum: 187841016.50909904 },
    change_1h: 0,
    change_1d: 3.989814728067387,
    change_7d: 5.119461080428195,
    fdv: 130391816,
    mcap: 25134538,
  },
  {
    id: '124',
    name: 'KEEP Network',
    address: '0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa',
    symbol: 'KEEP',
    url: 'https://tbtc.network/',
    description: 'Deposit and redeem BTC in DeFi without intermediaries.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/keep-network.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'keep-network',
    cmcId: '5566',
    category: 'Cross Chain',
    chains: ['Ethereum'],
    module: 'keep.js',
    twitter: null,
    audit_links: ['https://consensys.net/diligence/audits/2020/02/thesis-tbtc-and-keep/'],
    oracles: ['Maker'],
    slug: 'keep-network',
    tvl: 187629384.6832714,
    chainTvls: { Ethereum: 187629384.6832714 },
    change_1h: 0.08690216247877913,
    change_1d: 2.6184217115971364,
    change_7d: 10.114698236364731,
    fdv: 663038931,
    mcap: 364483308,
  },
  {
    id: '1307',
    name: '0xDAO',
    address: 'fantom:0xc165d941481e68696f43EE6E99BFB2B23E0E3114',
    symbol: 'OXD',
    url: 'https://www.oxdao.fi',
    description: 'Protocol on top of solidly',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/0xdao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: '0xDAO/index.js',
    twitter: '0xDAO_fi',
    forkedFrom: ['Uniswap'],
    listedAt: 1642798081,
    slug: '0xdao',
    tvl: 187362538.98367894,
    chainTvls: { Fantom: 187362538.98367894 },
    change_1h: 0.08212994155256581,
    change_1d: -5.14547541347558,
    change_7d: -37.098944696219604,
  },
  {
    id: '1230',
    name: 'Welnance',
    address: 'bsc:0x854b4c305554c5fa72353e31b8480c0e5128a152',
    symbol: 'WEL',
    url: 'https://welnance.com',
    description:
      'Welnance is a protocol on the Binance Smart Chain that establishes money markets, which are pools of assets with algorithmically derived interest rates, based on the supply and demand for the asset.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/welnance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'welnance-finance',
    cmcId: '12328',
    category: 'Chain',
    chains: ['Binance'],
    module: 'welnance/index.js',
    twitter: 'WelnanceWorld',
    audit_links: ['https://app.inspex.co/library/welnance#?scope=welnance-lending'],
    listedAt: 1641875259,
    forkedFrom: ['Compound'],
    slug: 'welnance',
    tvl: 187103814.51825848,
    chainTvls: { Binance: 187103814.51825848, 'Binance-borrowed': 218190.30170403278, borrowed: 218190.30170403278 },
    change_1h: 0,
    change_1d: -0.15787208157442478,
    change_7d: 1.973028445431794,
    mcap: 0,
  },
  {
    id: '704',
    name: 'Chainge Finance',
    address: 'fusion:0xed0294dbd2a0e52a09c3f38a09f6e03de2c44fcf',
    symbol: 'CHNG',
    url: 'https://www.chainge.finance',
    description:
      'Chainge is a DeFi app running on the Fusion blockchain that empowers people from all corners of the world to become their own digital bank',
    chain: 'Fusion',
    logo: 'https://icons.llama.fi/chainge-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'chainge-finance',
    cmcId: '9071',
    category: 'Dexes',
    chains: ['Fusion'],
    module: 'chainge.js',
    twitter: 'FinanceChainge',
    audit_links: ['https://www.certik.org/projects/chaingefinance'],
    forkedFrom: ['Uniswap'],
    listedAt: 1635106127,
    slug: 'chainge-finance',
    tvl: 185298810.1806625,
    chainTvls: { Fusion: 185298810.1806625 },
    change_1h: 0.2668107817391103,
    change_1d: 0.692939997949324,
    change_7d: 3.650028376793557,
    mcap: 14069127,
  },
  {
    id: '278',
    name: 'Ankr',
    address: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
    symbol: 'ANKR',
    url: 'https://www.ankr.com/',
    description: 'Liquid staking provider.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/ankr.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ankr',
    cmcId: '3783',
    category: 'Liquid Staking',
    chains: ['Ethereum', 'Binance', 'Polygon', 'Avalanche', 'Fantom', 'Polkadot', 'Kusama'],
    module: 'ankr/index.js',
    twitter: 'ankr',
    audit_links: ['https://assets.ankr.com/files/stkr_smart_contract_auditing_report.pdf'],
    slug: 'ankr',
    tvl: 184660392.85802388,
    chainTvls: {
      Avalanche: 933099.257612088,
      Kusama: 374.466497118,
      Binance: 9551853.505565412,
      Ethereum: 172998056.1730717,
      Polygon: 1085995.2729599797,
      Fantom: 79039.268,
      Polkadot: 11974.914317585999,
    },
    change_1h: 0.5705151980518508,
    change_1d: 2.9823536088348703,
    change_7d: 13.871970128162346,
    fdv: 892842653,
    mcap: 728818474,
  },
  {
    id: '406',
    name: 'Element Finance',
    address: '-',
    symbol: '-',
    url: 'https://www.element.fi',
    description: 'Element Finance is a protocol that enables users to seek high fixed yield income in the DeFi market.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/element-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'elementfi/index.js',
    twitter: 'element_fi',
    audit_links: ['https://docs.element.fi/developers/bug-bounty-programs#b93e'],
    slug: 'element-finance',
    tvl: 182123183.9178859,
    chainTvls: { Ethereum: 182123183.9178859 },
    change_1h: 0.33486745250257854,
    change_1d: 1.8725420796246652,
    change_7d: 7.506988560864642,
  },
  {
    id: '146',
    name: 'DODO',
    address: '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd',
    symbol: 'DODO',
    url: 'https://dodoex.io/',
    description: 'Trade crypto assets with market-leading liquidity',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dodo.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dodo',
    cmcId: '7224',
    category: 'Dexes',
    chains: ['Ethereum', 'Binance', 'Polygon', 'Arbitrum'],
    module: 'dodo/index.js',
    twitter: 'BreederDodo',
    audit_links: ['https://github.com/DODOEX/docs/blob/master/docs/audit.md'],
    oracles: ['Chainlink'],
    slug: 'dodo',
    tvl: 181832562.7938218,
    chainTvls: {
      Binance: 43279209.460307226,
      Ethereum: 89188787.50474744,
      Polygon: 31208942.159583565,
      Arbitrum: 18155623.669183586,
    },
    change_1h: -0.07226502927257172,
    change_1d: 2.1460076620689676,
    change_7d: 1.3842803456681736,
    fdv: 518070188,
    mcap: 150034787,
  },
  {
    id: '660',
    name: 'Dopex',
    address: '0xeec2be5c91ae7f8a338e1e5f3b5de49d07afdc81',
    symbol: 'DPX',
    url: 'https://www.dopex.io',
    description: 'Dopex is a maximum liquidity and minimal exposure options protocol',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dopex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dopex',
    cmcId: '11188',
    category: 'Options',
    chains: ['Arbitrum', 'Binance', 'Avalanche', 'Ethereum'],
    module: 'dopex/index.js',
    twitter: 'dopex_io',
    audit_links: [
      'https://github.com/solidified-platform/audits/blob/master/Audit%20Report%20-%20Dopex%20%5B21.06.2021%5D.pdf',
      'https://solidity.finance/audits/Dopex/',
    ],
    slug: 'dopex',
    tvl: 173660181.61680105,
    chainTvls: {
      pool2: 155460549.08669466,
      Avalanche: 186560.013235,
      'Arbitrum-staking': 261905744.20710236,
      'Ethereum-pool2': 109594.69888674779,
      Binance: 251942.9385329708,
      Ethereum: 0,
      Arbitrum: 173221678.66503307,
      'Arbitrum-pool2': 155350954.3878079,
      staking: 261905744.20710236,
    },
    change_1h: 0.12479680977158125,
    change_1d: 11.29801096220379,
    change_7d: 30.277470344511784,
    staking: 261905744.20710236,
    pool2: 155460549.08669466,
    mcap: 317790558,
  },
  {
    id: '286',
    name: 'Mango Markets',
    address: 'solana:MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac',
    symbol: 'MNGO',
    url: 'https://mango.markets',
    description: 'DEX for leverage trading',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/mango-markets.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'mango-markets',
    cmcId: '11171',
    category: 'Lending',
    chains: ['Solana'],
    module: 'mango-markets/index.js',
    twitter: 'mangomarkets',
    oracles: ['Pyth'],
    slug: 'mango-markets',
    tvl: 173155407.37579215,
    chainTvls: { Solana: 173155407.37579215 },
    change_1h: -0.0017942808419633138,
    change_1d: 3.1079820626666503,
    change_7d: 6.827003309429941,
    fdv: 845105241,
    mcap: 176002987,
  },
  {
    id: '231',
    name: 'Lightning Network',
    address: '-',
    symbol: '-',
    url: 'https://lightning.network/',
    description: ' Scalable, Instant Bitcoin/Blockchain Transactions\r\n',
    chain: 'Bitcoin',
    logo: 'https://icons.llama.fi/lightning-network.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Payments',
    chains: ['Bitcoin'],
    module: 'lightning-network/index.js',
    twitter: null,
    slug: 'lightning-network',
    tvl: 172519599.15,
    chainTvls: { Bitcoin: 172519599.15 },
    change_1h: 0.03629135702638564,
    change_1d: 2.2199607869762588,
    change_7d: 12.614944307202464,
  },
  {
    id: '1492',
    name: 'Aurigami',
    address: null,
    symbol: '-',
    url: 'https://www.aurigami.finance',
    description: 'MultiPLY with Aurigami, the noncustodial lending and borrowing haven on Aurora',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/aurigami.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Aurora'],
    oracles: ['Flux'],
    forkedFrom: ['Compound'],
    module: 'aurigami.js',
    twitter: 'aurigami_PLY',
    listedAt: 1646168827,
    slug: 'aurigami',
    tvl: 171553246.9260867,
    chainTvls: { Aurora: 171553246.9260867, borrowed: 215848053.0899381, 'Aurora-borrowed': 215848053.0899381 },
    change_1h: -0.0711522730865255,
    change_1d: -0.7036875092855723,
    change_7d: -3.6532975026232606,
  },
  {
    id: '413',
    name: 'Spectrum Protocol',
    address: 'terra:terra1s5eczhe0h0jutf46re52x5z4r03c8hupacxmdr',
    symbol: 'SPEC',
    url: 'https://spec.finance',
    description:
      'The First and Innovative yield optimizer on Terra Ecosystem, auto-compound and auto-stake your Mirror and Anchor farm rewards.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/spectrum-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'spectrum-token',
    cmcId: '11460',
    category: 'Yield',
    chains: ['Terra'],
    module: 'specfinance.js',
    twitter: 'SpecProtocol',
    audit_links: [
      'https://github.com/HalbornSecurity/PublicReports/blob/master/CosmWasm%20Smart%20Contract%20Audits/Spectrum_Protocol_CosmWasm_Smart_Contract_Security_Audit_Halborn_Report_v1_1.pdf',
    ],
    openSource: true,
    slug: 'spectrum-protocol',
    tvl: 169978567.09864438,
    chainTvls: { Terra: 170624118.59656432 },
    change_1h: 0.2365995074419942,
    change_1d: 6.30433463421258,
    change_7d: 11.189247371162267,
    mcap: 24571203,
  },
  {
    id: '1453',
    name: 'EOS REX',
    address: 'eos:eos',
    symbol: 'EOS',
    url: 'https://eosauthority.com/rex/statistics?network=eos',
    description:
      'REX (Resource Exchange) used for lending and borrowing CPU & NET resources and earning system protocol income (RAM fees, Name Bids, CPU/Net loans, PowerUp fees).',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/eos-rex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'eos',
    cmcId: '1765',
    category: 'Lending',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'rex/index.js',
    twitter: 'EosNFoundation',
    language: 'C++',
    listedAt: 1645579151,
    slug: 'eos-rex',
    tvl: 168964250.86367399,
    chainTvls: { EOS: 168964250.86367399 },
    change_1h: -0.2969287617673757,
    change_1d: -1.2851395806493286,
    change_7d: 20.949387604628768,
    mcap: 2919263259,
  },
  {
    id: '784',
    name: 'Trisolaris',
    address: 'aurora:0xFa94348467f64D5A457F75F8bc40495D33c65aBB',
    symbol: 'TRI',
    url: 'https://www.trisolaris.io/#/swap',
    description:
      'Trisolaris is #1 Dex on the Aurora engine, an EVM compatible blockchain running in the near ecosystem.',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/trisolaris.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Aurora'],
    module: 'trisolaris.js',
    twitter: 'trisolarislabs',
    forkedFrom: ['Uniswap'],
    listedAt: 1636045437,
    slug: 'trisolaris',
    tvl: 166952502.3585618,
    chainTvls: { Aurora: 166952502.3585618 },
    change_1h: 0.18708038985211317,
    change_1d: 2.21803159108876,
    change_7d: 14.421021650910433,
  },
  {
    id: '655',
    name: 'Almond',
    address: null,
    symbol: 'ALM',
    url: 'https://almond.so',
    description: 'Fair and Nutritious DeFi.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/almond.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Solana'],
    module: 'almond.js',
    twitter: 'almond_so',
    openSource: false,
    slug: 'almond',
    tvl: 162386697.72688282,
    chainTvls: { Solana: 162386697.72688282 },
    change_1h: 0.1578171726702493,
    change_1d: 2.314309037831805,
    change_7d: 12.281276179264395,
  },
  {
    id: '756',
    name: 'Angle',
    address: '0x31429d1856aD1377A8A0079410B297e1a9e214c2',
    symbol: 'ANGLE',
    url: 'https://app.angle.money',
    description:
      'Angle is the first over-collateralized, decentralized and capital-efficient stablecoin protocol. It offers full convertibility between stable assets and collateral at oracle value: you can swap 1 of collateral against 1 of stablecoin and conversely. This makes the protocol both capital efficient and highly liquid.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/angle.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'angle-protocol',
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'angle/index.js',
    twitter: 'AngleProtocol',
    audit_links: ['https://github.com/AngleProtocol/angle-core/tree/main/audits'],
    oracles: ['Chainlink', 'TWAP'],
    listedAt: 1635962344,
    slug: 'angle',
    tvl: 160839438.1298199,
    chainTvls: { Ethereum: 160839438.1298199, 'Ethereum-staking': 5609415.54624322, staking: 5609415.54624322 },
    change_1h: -0.09852408204245933,
    change_1d: -0.5602160922099984,
    change_7d: -9.275100877050917,
    staking: 5609415.54624322,
    fdv: 178270595,
    mcap: 16169656,
  },
  {
    id: '1525',
    name: 'Vector Finance',
    address: 'avax:0x5817d4f0b62a59b17f75207da1848c2ce75e7af4',
    symbol: 'VTX',
    url: 'https://vectorfinance.io/',
    description: "Vector allows users to boost their Platypus and stablecoin staking even if they don't own any PTP",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/vector-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'vector-finance',
    cmcId: '18418',
    category: 'Yield',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: [],
    module: 'vector/index.js',
    twitter: 'vector_fi',
    audit_links: ['https://omniscia.io/vector-finance-core-protocol/'],
    listedAt: 1646854535,
    slug: 'vector-finance',
    tvl: 159965708.97025666,
    chainTvls: {
      Avalanche: 159965708.97025666,
      'Avalanche-staking': 3948707.9833472297,
      pool2: 4336.503566833572,
      'Avalanche-pool2': 4336.503566833572,
      staking: 3948707.9833472297,
    },
    change_1h: -0.031178807305280998,
    change_1d: 9.820154803257026,
    change_7d: -19.675241872808442,
    staking: 3948707.9833472297,
    pool2: 4336.503566833572,
    fdv: 47863645,
    mcap: 2226429,
  },
  {
    id: '1401',
    name: 'Moonwell',
    address: 'moonriver:0xbb8d88bcd9749636bc4d2be22aac4bb3b01a58f1',
    symbol: 'MFAM',
    url: 'https://moonwell.fi',
    description: 'Moonwell is an open lending and borrowing DeFi protocol on Moonbeam & Moonriver.',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/moonwell-defi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'moonwell',
    cmcId: null,
    category: 'Lending',
    chains: ['Moonriver'],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    module: 'moonwell/index.js',
    twitter: 'MoonwellDeFi',
    language: 'Solidity',
    listedAt: 1644685001,
    slug: 'moonwell',
    tvl: 158886652.44520235,
    chainTvls: {
      Moonriver: 158886652.44520235,
      borrowed: 145018185.48872784,
      'Moonriver-borrowed': 145018185.48872784,
    },
    change_1h: -0.010824852848401179,
    change_1d: 3.8431199333616206,
    change_7d: 13.810404118349155,
    mcap: 0,
  },
  {
    id: '536',
    name: 'Larix',
    address: 'solana:Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC',
    symbol: 'LARIX',
    url: 'https://projectlarix.com',
    description: 'The first Metaverse based Finance Protocol on Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/larix.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'larix',
    cmcId: '11911',
    category: 'Lending',
    chains: ['Solana'],
    module: 'larix.js',
    twitter: 'ProjectLarix',
    audit_links: ['https://docs.projectlarix.com/how-to-prove/audit'],
    oracles: ['Pyth'],
    openSource: false,
    slug: 'larix',
    tvl: 157098003.3044,
    chainTvls: { Solana: 157098003.3044 },
    change_1h: 0.6202052843691632,
    change_1d: 3.496204806648848,
    change_7d: 15.12707931979125,
    fdv: 36809283,
    mcap: 0,
  },
  {
    id: '1427',
    name: 'Benqi Staked Avax',
    address: 'avax:0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
    symbol: 'SAVAX',
    url: 'https://staking.benqi.fi',
    description:
      "Stake AVAX on BENQI's Liquid Staking Protocol and freely utilize it within powerful Decentralized Financial applications.",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/benqi-staked-avax.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'benqi-liquid-staked-avax',
    cmcId: null,
    category: 'Liquid Staking',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: [],
    module: 'benqi-staked-avax/index.js',
    audit_links: ['https://docs.benqi.fi/resources/risks#audits'],
    twitter: 'BenqiFinance',
    listedAt: 1645133212,
    slug: 'benqi-staked-avax',
    tvl: 154633721.04572174,
    chainTvls: { Avalanche: 154633721.04572174 },
    change_1h: 1.150621684199038,
    change_1d: 5.310485931490419,
    change_7d: 17.73037024503337,
    mcap: 152137049,
  },
  {
    id: '310',
    name: 'Belt Finance',
    address: 'bsc:0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
    symbol: 'BELT',
    url: 'https://belt.fi/',
    description: 'Yield aggregator + AMM',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/belt-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'belt',
    cmcId: '1311',
    category: 'Yield',
    chains: ['Binance', 'Heco', 'Klaytn'],
    module: 'belt/index.js',
    twitter: 'BELT_Finance',
    audit_links: ['https://github.com/BeltFi/belt-contract/tree/main/audit'],
    slug: 'belt-finance',
    tvl: 153266678.50150448,
    chainTvls: { Binance: 136457292.0378718, Heco: 16778344.431480654, Klaytn: 31042.03215204 },
    change_1h: 0.357162052238877,
    change_1d: 0.46311652523995406,
    change_7d: -1.5095224921635264,
    mcap: 6821867,
  },
  {
    id: '435',
    name: 'Hop Protocol',
    address: '-',
    symbol: '-',
    url: 'https://hop.exchange',
    description:
      'A protocol for sending tokens across rollups and their shared layer-1 network in a quick and trustless manner',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/hop-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Cross Chain',
    chains: ['Ethereum', 'Arbitrum', 'Optimism', 'Polygon', 'xDai'],
    module: 'hop',
    twitter: 'HopProtocol',
    audit_links: ['https://docs.hop.exchange/faq#are-hop-contracts-audited'],
    forkedFrom: ['Saddle Finance'],
    slug: 'hop-protocol',
    tvl: 150034373.76120153,
    chainTvls: {
      Optimism: 13596786.902091172,
      Ethereum: 93199525.11964294,
      xDai: 5186131.36563452,
      Polygon: 11866727.533673769,
      Arbitrum: 26185202.840159133,
    },
    change_1h: 0.2582666049802498,
    change_1d: 2.6889778714939183,
    change_7d: 8.520910938765397,
  },
  {
    id: '155',
    name: 'KeeperDAO',
    address: '0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a',
    symbol: 'ROOK',
    url: 'https://keeperdao.com/',
    description: 'KeeperDAO is an on-chain liquidity underwriter for DeFi.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/keeperdao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'rook',
    cmcId: '7678',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'keeper-dao/index.js',
    twitter: 'Keeper_DAO',
    slug: 'keeperdao',
    tvl: 149450463.9338889,
    chainTvls: { Ethereum: 149450463.9338889 },
    change_1h: 0.2586890818073897,
    change_1d: 10.155801673770213,
    change_7d: 22.98198469676049,
    fdv: 180824659,
    mcap: 115581727,
  },
  {
    id: '614',
    name: 'Waves Exchange',
    address: 'waves:Atqv59EYzjFGuitKVnMRk6H8FukjoV3ktPorbEys25on',
    symbol: 'Waves.Exchange',
    url: 'https://waves.exchange',
    description:
      'The Waves Exchange is a fast and secure decentralized trading environment for Waves-based assets and other supported cryptocurrencies, like Bitcoin and Litecoin.',
    chain: 'Waves',
    logo: 'https://icons.llama.fi/waves-exchange.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'waves-exchange',
    cmcId: '246',
    category: 'Dexes',
    chains: ['Waves'],
    module: 'waves-exchange/index.js',
    twitter: 'Waves_Exchange',
    slug: 'waves-exchange',
    tvl: 149081511.42706487,
    chainTvls: { Waves: 149081511.42706487 },
    change_1h: -0.37967267951036376,
    change_1d: 2.749886845906275,
    change_7d: 3.364383357957095,
    fdv: 1603147701,
    mcap: 0,
  },
  {
    id: '752',
    name: 'Nexus Protocol',
    address: 'terra:terra12897djskt9rge8dtmm86w654g7kzckkd698608',
    symbol: 'PSI',
    url: 'https://nexusprotocol.app',
    description:
      'Nexus removes the complexity of advanced yield farming strategies to deliver optimized, sustainable yield for users',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/nexus-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'nexus-governance-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Terra'],
    module: 'nexus-protocol/index.js',
    twitter: 'NexusProtocol',
    audit_links: [
      'https://github.com/HalbornSecurity/PublicReports/blob/master/CosmWasm%20Smart%20Contract%20Audits/Nexus_Protocol_CosmWasm_Smart_Contract_Security_Audit_Report_Halborn%20v1.1.pdf',
    ],
    listedAt: 1635878160,
    openSource: true,
    slug: 'nexus-protocol',
    tvl: 148511031.264832,
    chainTvls: { Terra: 148511031.264832 },
    change_1h: -0.023007832660042027,
    change_1d: 6.88286617749057,
    change_7d: 12.108008011020672,
    fdv: 479553448,
    mcap: 0,
  },
  {
    id: '1460',
    name: 'ValleySwap',
    address: 'oasis:0xBC033203796CC2C8C543a5aAe93a9a643320433D',
    symbol: 'VS',
    url: 'https://valleyswap.com',
    description: 'ValleySwap – DeFi exchange on Oasis Emerald Paratime Mainnet',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/valleyswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Oasis'],
    oracles: [],
    forkedFrom: [],
    module: 'valleyswap/index.js',
    twitter: 'ValleySwap',
    audit_links: ['https://paladinsec.co/projects/valley-swap/'],
    listedAt: 1645647146,
    slug: 'valleyswap',
    tvl: 147268903.90269494,
    chainTvls: { Oasis: 147268903.90269494 },
    change_1h: -11.54446164193908,
    change_1d: -10.463745252030648,
    change_7d: -4.429409438405713,
  },
  {
    id: '547',
    name: 'pNetwork',
    address: '0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed',
    symbol: 'PNT',
    url: 'https://p.network',
    description:
      'The pNetwork is a decentralized network of validators contributing to the verification of crypto asset switches across blockchains',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/pnetwork.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pnetwork',
    cmcId: '5794',
    category: 'Cross Chain',
    chains: ['Binance', 'Ethereum', 'Ultra', 'Polygon', 'EOS', 'Telos', 'xDai'],
    module: 'pnetwork.js',
    twitter: 'pNetworkDeFi',
    audit_links: ['https://cryptonics.consulting/wp-content/uploads/2020/03/20200302_Audit_Report_pToken-2.pdf'],
    slug: 'pnetwork',
    tvl: 147076272.5385669,
    chainTvls: {
      EOS: 3704055.098265218,
      Binance: 56865517.089633785,
      Ethereum: 55340913.853388004,
      xDai: 1095.455698873536,
      Ultra: 20013667.44643477,
      Polygon: 10287563.441859137,
      Telos: 863460.1532870979,
    },
    change_1h: 1.2402887845429262,
    change_1d: 1.6169787881355546,
    change_7d: 8.97346848755096,
    fdv: 57385306,
    mcap: 21966413,
  },
  {
    id: '427',
    name: 'Origin Dollar',
    address: '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86',
    symbol: 'OUSD',
    url: 'https://www.ousd.com',
    description:
      'Origin Dollar (OUSD) is a new stablecoin that was initially launched on the Ethereum network. Its design is superior to existing stablecoins because OUSD captures competitive yields while being passively held in wallets.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/origin-dollar.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'origin-dollar',
    cmcId: '7189',
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'origindollar/index.js',
    twitter: 'OriginProtocol',
    audit_links: ['https://docs.ousd.com/v/en/security-and-risks/audits'],
    slug: 'origin-dollar',
    tvl: 142720930.88195813,
    chainTvls: { Ethereum: 142720930.88195813 },
    change_1h: 0.000004110915497790302,
    change_1d: 0.09305857449864163,
    change_7d: -4.6329615923074385,
    fdv: 141656822,
    mcap: 141656822,
  },
  {
    id: '1523',
    name: 'KLAYstation',
    address: null,
    symbol: '-',
    url: 'https://klaystation.io',
    description:
      'KLAYstation is a staking tool based on the Klaytn Network, that has now introduced the sKLAY token, a liquid staking solution',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/klaystation.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Liquid Staking',
    chains: ['Klaytn'],
    oracles: [],
    forkedFrom: [],
    module: 'klaystation/index.js',
    twitter: 'Orbit_Chain',
    listedAt: 1646799845,
    slug: 'klaystation',
    tvl: 138435075.47,
    chainTvls: { Klaytn: 138435075.47 },
    change_1h: -0.1847254505941578,
    change_1d: 2.747200954721876,
    change_7d: 11.450580452400615,
  },
  {
    id: '349',
    name: 'Injective',
    address: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
    symbol: 'INJ',
    url: 'https://injective.com',
    description: 'Access limitless DeFi markets with zero barriers.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/injective.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'injective-protocol',
    cmcId: '7226',
    category: 'Synthetics',
    chains: ['Ethereum'],
    module: 'injective/index.js',
    twitter: 'InjectiveLabs',
    audit_links: ['https://www.certik.org/projects/injectiveprotocol'],
    oracles: ['Chainlink', 'Band', 'DIA'],
    slug: 'injective',
    tvl: 137613575.06131,
    chainTvls: { Ethereum: 137613575.06131 },
    change_1h: 0.1623376623376629,
    change_1d: 0.6070097250265576,
    change_7d: 8.344671482104872,
    fdv: 617428904,
    mcap: 300413456,
  },
  {
    id: '127',
    name: 'KyberSwap',
    address: '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202',
    symbol: 'KNC',
    url: 'https://kyber.network/',
    description:
      'Kyber Network is connecting the fragmented tokenized world by enabling instant and seamless transactions between platforms, ecosystems.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kyberswap.png',
    audits: null,
    audit_note: null,
    gecko_id: 'kyber-network',
    cmcId: '404',
    category: 'Dexes',
    chains: ['Avalanche', 'Ethereum', 'Polygon', 'Binance', 'Fantom', 'Cronos', 'Arbitrum', 'Aurora'],
    module: 'kyber/index.js',
    twitter: 'KyberNetwork',
    audit_links: ['https://chainsecurity.com/security-audit/kyber-network-dynamic-market-maker-dmm/'],
    oracles: ['Chainlink', 'Band'],
    slug: 'kyberswap',
    tvl: 134654091.55601147,
    chainTvls: {
      Cronos: 92584.62985157565,
      Avalanche: 57535447.7621925,
      Binance: 5240615.536267477,
      Ethereum: 50491685.92411176,
      Aurora: 0.00000106434041853356,
      Polygon: 21103513.653609056,
      Arbitrum: 43761.56229739399,
      Fantom: 146482.48768065748,
    },
    change_1h: 0.17330324638231787,
    change_1d: 2.2536267562936843,
    change_7d: 15.597068580351731,
    fdv: 790440153,
    mcap: 187501971,
  },
  {
    id: '1272',
    name: 'ioTube',
    address: null,
    symbol: '-',
    url: 'https://iotube.org',
    description:
      'ioTube is the decentralized bridge for connecting Ethereum, Binance Smart Chain, Polygon (Matic), and IoTeX. ioTube enables bi-directional exchange of tokens between IoTeX, Ethereum, Binance Smart Chain, and now Polygon!',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/iotube.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'crosschain-iotx',
    cmcId: '11851',
    category: 'Bridge',
    chains: ['IoTeX', 'Ethereum', 'Binance', 'Polygon'],
    module: 'iotube/index.js',
    twitter: 'iotube_org',
    listedAt: 1642303801,
    slug: 'iotube',
    tvl: 134318559.63064602,
    chainTvls: {
      Binance: 8617974.134730907,
      IoTeX: 110149093.38164483,
      Ethereum: 13571499.864505906,
      Polygon: 1979992.2497643777,
    },
    change_1h: 0.5141728319359942,
    change_1d: -0.6634197780168023,
    change_7d: -1.076610563210366,
    mcap: 0,
  },
  {
    id: '864',
    name: 'Wrapped BNB',
    address: 'kava:-',
    symbol: 'BNB',
    url: 'https://www.kava.io',
    description:
      'Wrapped BNB (BNB) is a Kava chain token that is backed 1:1 with BNB using the open BEP3 protocol. Completely transparent. 100% verifiable. Secured by Binance.com.',
    chain: 'Kava',
    logo: 'https://icons.llama.fi/wrapped-bnb.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Kava'],
    module: 'wrappedBNB.js',
    twitter: null,
    forkedFrom: ['BinanceChain BEPs'],
    listedAt: 1637348348,
    slug: 'wrapped-bnb',
    tvl: 133117022.16044624,
    chainTvls: { Kava: 133117022.16044624 },
    change_1h: 0,
    change_1d: 1.1198196779370448,
    change_7d: 8.595356008992326,
  },
  {
    id: '460',
    name: 'Ondo Finance',
    address: '-',
    symbol: '-',
    url: 'https://ondo.finance',
    description:
      'Ondo is a DeFi protocol that allows investors to better balance risk with rewards while gaining exposure to yield-generating crypto-assets on Ethereum.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ondo-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'ondofinance/index.js',
    twitter: 'OndoFinance',
    audit_links: ['https://www.certik.org/projects/ondofinance'],
    slug: 'ondo-finance',
    tvl: 130254859.46657516,
    chainTvls: { Ethereum: 130254859.46657516 },
    change_1h: 0.3509073460938481,
    change_1d: 1.0660385541161048,
    change_7d: 0.1429697579639111,
  },
  {
    id: '954',
    name: 'REX Staking',
    address: '',
    symbol: '-',
    url: 'https://eosauthority.com/rex',
    description:
      'REX is short for “Resource Exchange” and is a marketplace that allows for the risk-free leasing of network resources (CPU & Network)',
    chain: 'Telos',
    logo: 'https://icons.llama.fi/rex-staking.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Telos'],
    module: 'telosRex.js',
    twitter: 'EOSauthority',
    listedAt: 1638411637,
    slug: 'rex-staking',
    tvl: 129510778.88894285,
    chainTvls: { Telos: 129510778.88894285 },
    change_1h: 3.075075641053161,
    change_1d: -2.7600254019511254,
    change_7d: 5.053019548880158,
  },
  {
    id: '1497',
    name: 'Starlay Finance',
    address: null,
    symbol: '-',
    url: 'https://starlay.finance',
    description: 'Starlay Finance is the first lending protocol backed by Astar Network.',
    chain: 'Astar',
    logo: 'https://icons.llama.fi/starlay-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Astar'],
    oracles: ['DIA'],
    forkedFrom: ['Aave'],
    module: 'starlay/index.js',
    twitter: 'starlay_fi',
    listedAt: 1646202072,
    slug: 'starlay-finance',
    tvl: 127528671.60474746,
    chainTvls: { 'Astar-borrowed': 194964366.88088304, Astar: 127528671.60474746, borrowed: 194964366.88088304 },
    change_1h: 0.925440052962287,
    change_1d: 8.914135963159424,
    change_7d: 28.236250441264815,
  },
  {
    id: '1162',
    name: 'Friktion',
    address: null,
    symbol: '-',
    url: 'https://friktion.fi',
    description:
      'Friktion is a crypto-asset portfolio management platform, built to perform across market cycles. Volts, Friktion’s native structured products, offer sustainable income generation and volatility yield strategies. Designed for individuals and DAOs.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/friktion.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Options',
    chains: ['Solana'],
    module: 'friktion/index.js',
    twitter: 'friktion_labs',
    oracles: ['Pyth'],
    listedAt: 1640906003,
    openSource: false,
    slug: 'friktion',
    tvl: 125819716.65917182,
    chainTvls: { Solana: 125819716.65917182 },
    change_1h: 0.11424361683201312,
    change_1d: 5.292536360435562,
    change_7d: 15.668907508571436,
  },
  {
    id: '254',
    name: 'Bunny',
    address: 'bsc:0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    symbol: 'BUNNY',
    url: 'https://pancakebunny.finance/',
    description: 'Defi yield farming aggregator and optimizer for Binance Smart Chan (BSC) and Ethereum (ETH)',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bunny.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pancake-bunny',
    cmcId: '7791',
    category: 'Yield',
    chains: ['Binance', 'Polygon'],
    module: 'bunny/index.js',
    twitter: 'PancakeBunnyFin',
    audit_links: [
      'https://github.com/PancakeBunny-finance/Bunny/blob/main/audits/%5BHAECHI%20AUDIT%5D%20PancakeBunny%20Smart%20Contract%20Audit%20Report%20ver%202.0.pdf',
    ],
    oracles: ['Chainlink'],
    slug: 'bunny',
    tvl: 124043502.08675055,
    chainTvls: { Binance: 123220203.82111102, Polygon: 823298.2656395355 },
    change_1h: -0.0012646552354453888,
    change_1d: 1.711749440659787,
    change_7d: 9.960581045365657,
    mcap: 154442,
  },
  {
    id: '1110',
    name: 'Market',
    address: null,
    symbol: '-',
    url: 'https://www.market.xyz',
    description:
      'Join the world of permissionless DeFi by Leveraging your assets in isolated markets, where the rules are set by you and the community.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/market.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Fantom', 'Polygon'],
    module: 'market.xyz/index.js',
    twitter: 'market_xyz',
    listedAt: 1640630956,
    forkedFrom: ['Compound'],
    slug: 'market',
    tvl: 123376773.42508516,
    chainTvls: {
      'Fantom-borrowed': 27249605.323383592,
      Polygon: 21708881.19677899,
      'Polygon-borrowed': 2286441.3611924686,
      borrowed: 29536046.68457606,
      Fantom: 101667892.22830617,
    },
    change_1h: 1.0008336570524108,
    change_1d: 1.3717839710860886,
    change_7d: 15.502192875439562,
  },
  {
    id: '541',
    name: 'Ref Finance',
    address: '-',
    symbol: 'REF',
    url: 'https://app.ref.finance',
    description:
      'Ref Finance is a core projects in the DeFi ecosystem on Near Protocol. Its main objective is to bring together the core components of DeFi, namely, decentralized exchange (DEX), lending protocol, synthetic asset issuer, and more',
    chain: 'Near',
    logo: 'https://icons.llama.fi/ref-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'ref-finance',
    cmcId: null,
    category: 'Dexes',
    chains: ['Near'],
    module: 'reffinance.js',
    twitter: 'finance_ref',
    slug: 'ref-finance',
    tvl: 121362263.8312447,
    chainTvls: { Near: 121362263.8312447 },
    change_1h: -0.9237313059984729,
    change_1d: 1.938841924151376,
    change_7d: 4.770901392171382,
    mcap: 11625089,
  },
  {
    id: '1395',
    name: 'ArthSwap',
    address: null,
    symbol: '-',
    url: 'https://app.arthswap.org/#/swap',
    description:
      'ArthSwap is the No.1 decentralized exchange with the largest community on Astar Network. It has very high APY and simple UI, quite easy to use.',
    chain: 'Astar',
    logo: 'https://icons.llama.fi/arthswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Astar'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'arthswap/index.js',
    twitter: 'arthswap',
    language: 'Solidity',
    listedAt: 1644594055,
    slug: 'arthswap',
    tvl: 119051099.65534487,
    chainTvls: { Astar: 119051099.65534487 },
    change_1h: -1.6116595969855467,
    change_1d: 5.258722622215146,
    change_7d: -0.818362139888734,
  },
  {
    id: '1173',
    name: 'JPool',
    address: 'solana:7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    symbol: 'JSOL',
    url: 'https://jpool.one',
    description:
      'JPool is a stake pool on the Solana blockchain network enabling safe, secure, high-yield rewards on your staked SOL.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/jpool.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'jpool',
    cmcId: '14926',
    category: 'Liquid Staking',
    chains: ['Solana'],
    module: 'jpool.js',
    twitter: 'JPoolSolana',
    listedAt: 1641070522,
    openSource: false,
    slug: 'jpool',
    tvl: 116286285.47442695,
    chainTvls: { Solana: 116286285.47442695 },
    change_1h: 0.5257261053175739,
    change_1d: 3.827513299014413,
    change_7d: 24.659750642302754,
    fdv: 57750178073,
    mcap: 0,
  },
  {
    id: '394',
    name: 'Reaper Farm',
    address: '-',
    symbol: '-',
    url: 'https://www.reaper.farm',
    description:
      'Rapid auto-compounder on the Fantom network. Reap your favorites while you sleep; backed by industry leading analytics, fresh UI, and a dedicated team.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/reaper-farm.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'reaper.farm/index.js',
    twitter: 'Reaper_Farm',
    audit_links: ['https://solidity.finance/audits/ReaperFarm'],
    slug: 'reaper-farm',
    tvl: 115891891.95089054,
    chainTvls: { Fantom: 115891891.95089054 },
    change_1h: 0.12192973943633945,
    change_1d: -0.11769883746852372,
    change_7d: 6.808943333512715,
  },
  {
    id: '942',
    name: 'KnightSwap Finance',
    address: 'bsc:0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    symbol: 'KNIGHT',
    url: 'https://knightswap.financial',
    description: 'Trade, Earn, & Raid To Stack Your Riches While Securely Storing Them Within Our Castle Vaults',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/knightswap-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance', 'Fantom'],
    module: 'knightswap/index.js',
    twitter: 'KnightEcosystem',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/November/KnightSwap.pdf'],
    forkedFrom: ['Uniswap'],
    listedAt: 1638214443,
    slug: 'knightswap-finance',
    tvl: 113864837.06927234,
    chainTvls: {
      Binance: 93434352.84874406,
      'Binance-staking': 9359513.531648278,
      'Fantom-staking': 4257010.059816971,
      staking: 13616523.59146525,
      Fantom: 20430484.220528282,
    },
    change_1h: 0.012320090867106614,
    change_1d: 0.907429655429695,
    change_7d: 6.296558389334621,
    staking: 13616523.59146525,
  },
  {
    id: '832',
    name: 'Hector Finance',
    address: 'fantom:0x5C4FDfc5233f935f20D2aDbA572F770c2E377Ab0',
    symbol: 'HEC',
    url: 'https://hectordao.com',
    description:
      'Hector is a decentralized storage protocol based on the HEC token – collateralized and backed by the Hector DAO. HEC will be the reserve currency on/of Fantom.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/hector-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'hector-dao',
    cmcId: '13881',
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'hector/index.js',
    twitter: 'HectorDAO_HEC',
    forkedFrom: ['Olympus DAO'],
    audit_links: ['https://www.certik.com/projects/hector-finance'],
    listedAt: 1636755480,
    slug: 'hector-finance',
    tvl: 113441733.78914714,
    chainTvls: {
      borrowed: 1247554.4298610801,
      staking: 71384010.7313322,
      Fantom: 113441733.78914714,
      'Fantom-staking': 71384010.7313322,
      'Fantom-borrowed': 1247554.4298610801,
    },
    change_1h: 0.0033268216010071683,
    change_1d: 0.8526279271086565,
    change_7d: 2.572554871752601,
    staking: 71384010.7313322,
    mcap: 66451341,
  },
  {
    id: '1243',
    name: 'StackSwap',
    address: 'stacks:-',
    symbol: 'STSW',
    url: 'https://app.stackswap.org/swap',
    description:
      'Stackswap is a combination of DEX and token launchpad on Stacks blockchain. You can buy/sell tokens or list your own token without having any coding skills.',
    chain: 'Stacks',
    logo: 'https://icons.llama.fi/stackswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Stacks'],
    module: 'stackswap.js',
    twitter: 'Stackswap_BTC',
    language: 'Clarity',
    audit_links: [
      'https://contact-stackswap.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FR2Qy2jO41SJwWbgnrvP4%2Fuploads%2F9tk4xdtFNupBe79yjKqY%2FTintash%20-%20Stackswap%20Contract%20audit.pdf?alt=media&token=b58b7f73-60a6-45e3-a8a5-b5e6f1bde584',
    ],
    listedAt: 1641952294,
    slug: 'stackswap',
    tvl: 112845940.09081493,
    chainTvls: { Stacks: 112845940.09081493 },
    change_1h: 0.3284318162523334,
    change_1d: 1.0285102049511892,
    change_7d: 11.419695213222653,
  },
  {
    id: '703',
    name: 'Goldfinch',
    address: '0xdab396ccf3d84cf2d07c4454e10c8a6f5b008d2b',
    symbol: 'GFI',
    url: 'https://goldfinch.finance',
    description: 'Goldfinch is a decentralized credit platform for crypto loans without collateral.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/goldfinch.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'goldfinch',
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'goldfinch/index.js',
    twitter: 'goldfinch_fi',
    listedAt: 1635105023,
    slug: 'goldfinch',
    tvl: 112023185.00707535,
    chainTvls: { Ethereum: 112023185.00707535 },
    change_1h: -0.11158841158840005,
    change_1d: -0.021533847889813273,
    change_7d: 2.463822623217496,
    fdv: 350924165,
    mcap: 20959316,
  },
  {
    id: '1346',
    name: 'ThetaCash',
    address: 'theta:0x4dc08b15ea0e10b96c41aec22fab934ba15c983e',
    symbol: 'TBILL',
    url: 'https://tbill.io',
    description: 'ThetaCash (TBILL) Rebasing Metaverse Liquidity Token.',
    chain: 'Theta',
    logo: 'https://icons.llama.fi/tbill.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Theta'],
    module: 'tbill.js',
    twitter: 'OfficialTBILL',
    listedAt: 1643833339,
    slug: 'thetacash',
    tvl: 108936200.85345,
    chainTvls: { Theta: 108936200.85345 },
    change_1h: 1.6461129279630171,
    change_1d: 0.3999424190421337,
    change_7d: 23.09239698011065,
  },
  {
    id: '1302',
    name: 'SundaeSwap',
    address: null,
    symbol: 'SUNDAE',
    url: 'https://www.sundaeswap.finance/',
    description: 'The first native AMM-based decentralized exchange and liquidity provision protocol on Cardano.',
    chain: 'Cardano',
    logo: 'https://icons.llama.fi/sundaeswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'sundaeswap',
    cmcId: '11986',
    category: 'Dexes',
    chains: ['Cardano'],
    module: 'sundaeswap/index.js',
    twitter: 'SundaeSwap',
    forkedFrom: [],
    listedAt: 1642765130,
    slug: 'sundaeswap',
    tvl: 107562810.21065369,
    chainTvls: { Cardano: 107562810.21065369 },
    change_1h: 0.7156061336006871,
    change_1d: 0.28902108849892727,
    change_7d: -6.672568240874611,
  },
  {
    id: '1140',
    name: 'NetSwap',
    address: 'metis:0x90fe084f877c65e1b577c7b2ea64b8d8dd1ab278',
    symbol: 'NETT',
    url: 'https://netswap.io/#/home',
    description:
      'Netswap is a decentralized exchange (DEX) which runs on Metis Andromeda (Layer2), uses the same AMM model as Uniswap and has a governance token NETT',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/netswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Metis'],
    module: 'netswap/index.js',
    twitter: 'netswapofficial',
    audit_links: ['https://docs.netswap.io/security/security-audits'],
    forkedFrom: ['Uniswap'],
    listedAt: 1640747909,
    slug: 'netswap',
    tvl: 101763568.12922113,
    chainTvls: { Metis: 101763568.12922113 },
    change_1h: 0.14400669501901575,
    change_1d: -5.651861368036194,
    change_7d: -7.721074045162737,
  },
  {
    id: '304',
    name: 'Flamingo Finance',
    address: null,
    symbol: 'FLM',
    url: 'https://flamingo.finance/',
    description: 'An Interoperable Full-Stack DeFi Protocol on Neo',
    chain: 'NEO',
    logo: 'https://icons.llama.fi/flamingo-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'flamingo-finance',
    cmcId: '7150',
    category: 'Dexes',
    chains: ['NEO'],
    module: 'flamingo.js',
    twitter: 'FlamingoFinance',
    audit_links: ['https://github.com/flamingo-finance/flamingo-audit'],
    slug: 'flamingo-finance',
    tvl: 98466485.56294073,
    chainTvls: { NEO: 98466485.56294073 },
    change_1h: 0.05346780752061875,
    change_1d: 2.4274288069909886,
    change_7d: 14.515837370384219,
    mcap: 81429358,
  },
  {
    id: '1300',
    name: 'Starstream',
    address: 'metis:0xb26f58f0b301a077cfa779c0b0f8281c7f936ac0',
    symbol: 'STARS',
    url: 'https://starstream.finance',
    description:
      'Starstream is a suite of products providing yield aggregation, yield generation and one-click smart contracts on the Metis L2 rollup. The protocol is maintained by various developers and is governed by STARS holders.',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/starstream.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'starstream',
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Metis'],
    module: 'starstream/index.js',
    twitter: 'starstreamfin',
    audit_links: ['https://docs.starstream.finance/contracts#audits'],
    forkedFrom: ['Yearn Finance'],
    listedAt: 1642726193,
    slug: 'starstream',
    tvl: 96713901.96140751,
    chainTvls: { Metis: 96713901.96140751 },
    change_1h: 0.16681870939896726,
    change_1d: 1.2466167012636475,
    change_7d: -48.34884646892155,
    mcap: 4018346,
  },
  {
    id: '125',
    name: 'mStable',
    address: '0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2',
    symbol: 'MTA',
    url: 'https://mstable.org/',
    description: 'mStable unites stablecoins, lending and swapping into one standard.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mstable.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'meta',
    cmcId: '5748',
    category: 'CDP',
    chains: ['Ethereum', 'Polygon'],
    module: 'mstable/index.js',
    twitter: 'mstable_',
    audit_links: ['https://developers.mstable.org/security/security-audits'],
    slug: 'mstable',
    tvl: 95516678.6359329,
    chainTvls: { Ethereum: 79426164.83528098, Polygon: 16090513.800651902 },
    change_1h: -0.20648435776941199,
    change_1d: -0.26877702264567915,
    change_7d: -3.1677550440582394,
    fdv: 44866649,
    mcap: 12789486,
  },
  {
    id: '243',
    name: 'Flamincome',
    address: '0x57dD84042ec9507963016596A34EdaD42F7e4CCE ',
    symbol: 'FLAG',
    url: 'https://flamincome.finance',
    description: 'Yield aggregator for flamingo finance',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/flamincome.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'flamincome.js',
    twitter: 'flamincome',
    audit_links: ['https://github.com/flamincome/audits'],
    slug: 'flamincome',
    tvl: 91806746.88,
    chainTvls: { Ethereum: 91806746.88 },
    change_1h: -0.08066138183710336,
    change_1d: 1.665755463724068,
    change_7d: 9.164785797549797,
  },
  {
    id: '780',
    name: 'Apricot Finance',
    address: 'solana:APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt',
    symbol: 'APT',
    url: 'https://apricot.one/#/',
    description:
      'Apricot is a next-gen lending protocol that supports leveraged yield farming on Solana. Our mission is to help users maximize yield while protecting their downsides.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/apricot-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'apricot',
    cmcId: '13473',
    category: 'Lending',
    chains: ['Solana'],
    module: 'apricot.js',
    twitter: 'ApricotFinance',
    oracles: ['Pyth'],
    listedAt: 1636003606,
    openSource: false,
    slug: 'apricot-finance',
    tvl: 91179512.47177467,
    chainTvls: { Solana: 91179512.47177467 },
    change_1h: -0.02585591658557007,
    change_1d: 14.553328085152572,
    change_7d: 25.29766180106303,
    fdv: 20751931,
    mcap: 3413693,
  },
  {
    id: '524',
    name: 'Ramp',
    address: '0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f',
    symbol: 'RAMP',
    url: 'https://www.rampdefi.com',
    description:
      'RAMP is an optimized lending platform that gives users the highest deposit yields and lowest borrowing fees on collateral assets across multiple blockchains.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/ramp.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'ramp',
    cmcId: '7463',
    category: 'Lending',
    chains: ['Binance', 'Polygon', 'Ethereum'],
    module: 'ramp/index.js',
    twitter: 'RampDefi',
    audit_links: ['https://github.com/RAMP-DEFI/Security-Audits'],
    oracles: ['Chainlink'],
    slug: 'ramp',
    tvl: 89044065.00059856,
    chainTvls: { Binance: 59412675.69773404, Ethereum: 6875255.681747826, Polygon: 23294533.89241514 },
    change_1h: -0.6902653189147543,
    change_1d: -8.02650655176565,
    change_7d: -9.234164033956503,
    fdv: 102358096,
    mcap: 42065265,
  },
  {
    id: '1183',
    name: 'Euler',
    address: null,
    symbol: '-',
    url: 'https://www.euler.finance',
    description:
      'Permissionless crypto lending markets are almost here. Euler is a non-custodial protocol on Ethereum that allows users to lend and borrow almost any crypto asset',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/euler.svg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'euler/index.js',
    twitter: 'eulerfinance',
    audit_links: ['https://docs.euler.finance/security/smart-contract-audits'],
    listedAt: 1641231917,
    slug: 'euler',
    tvl: 88053469.80710134,
    chainTvls: { Ethereum: 88053469.80710134 },
    change_1h: 0.2585190348952011,
    change_1d: 0.12622327487088114,
    change_7d: 22.654004865024532,
  },
  {
    id: '336',
    name: 'Sifchain',
    address: '0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe',
    symbol: 'EROWAN',
    url: 'https://sifchain.finance/',
    description: 'The world’s first omni-chain decentralized exchange.',
    chain: 'Sifchain',
    logo: 'https://icons.llama.fi/sifchain.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'sifchain',
    cmcId: '8541',
    category: 'Dexes',
    chains: ['Sifchain'],
    module: 'sifchain.js',
    twitter: 'sifchain',
    audit_links: ['https://drive.google.com/drive/u/1/folders/1kkjdpNuRmTjaiIKA6CQISavCvj4Awpbc'],
    slug: 'sifchain',
    tvl: 86208698.84,
    chainTvls: { Sifchain: 86208698.84 },
    change_1h: 0.43032766251238286,
    change_1d: 10.423892342976941,
    change_7d: 21.129892291263275,
    fdv: 133995496,
    mcap: 89712355,
  },
  {
    id: '208',
    name: 'ACryptoS',
    address: 'bsc:0x4197c6ef3879a08cd51e5560da5064b773aa1d29',
    symbol: 'ACS',
    url: 'https://app.acryptos.com/',
    description: 'Advanced Crypto Strategies, Yield Optimizer and StableSwap on BSC.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/acryptos.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'acryptos',
    cmcId: '7844',
    category: 'Yield Aggregator',
    chains: ['Binance'],
    module: 'acryptos.js',
    twitter: 'acryptosdao',
    audit_links: ['https://docs.acryptos.com/security-and-risks#audits'],
    slug: 'acryptos',
    tvl: 84490820.73,
    chainTvls: { Binance: 84490820.73 },
    change_1h: 0.7888270251060732,
    change_1d: 1.8865858710054084,
    change_7d: 3.5265676242463257,
    fdv: 7446583,
    mcap: 5066292,
  },
  {
    id: '874',
    name: 'ICHI Farm',
    address: '0x903bef1736cddf2a537176cf3c64579c3867a881',
    symbol: 'ICHI',
    url: 'https://www.ichi.org',
    description:
      'ICHI deploys a branded dollar for any cryptocurrency community. This enables retail on-boarding, protocol growth, and ecosystem acceleration.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ichi-farm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'ichi-farm',
    cmcId: '7726',
    category: 'CDP',
    chains: ['Ethereum'],
    module: 'ichifarm/index.js',
    twitter: 'ichifarm',
    audit_links: ['https://docs.ichi.farm/audits-and-risks/audits'],
    listedAt: 1637376327,
    slug: 'ichi-farm',
    tvl: 83848899.51046395,
    chainTvls: {
      Ethereum: 83848899.51046395,
      pool2: 21794958.95692574,
      'Ethereum-staking': 193131386.40332136,
      staking: 193131386.40332136,
      'Ethereum-pool2': 21794958.95692574,
    },
    change_1h: -0.4448235494004962,
    change_1d: 65.62612953679934,
    change_7d: 61.744223478612014,
    staking: 193131386.40332136,
    pool2: 21794958.95692574,
    fdv: 366142898,
    mcap: 331686842,
  },
  {
    id: '597',
    name: 'BabySwap',
    address: 'bsc:0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657',
    symbol: 'BABY',
    url: 'https://home.babyswap.finance',
    description: 'BabySwap is an AMM+NFT decentralized exchange for new projects on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/babyswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'babyswap',
    cmcId: '10334',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'babyswap/index.js',
    twitter: 'babyswap_bsc',
    audit_links: ['https://www.certik.org/projects/babyswap'],
    forkedFrom: ['Uniswap'],
    slug: 'babyswap',
    tvl: 83272629.96564573,
    chainTvls: { Binance: 83272629.96564573, 'Binance-staking': 17183177.58506755, staking: 17183177.58506755 },
    change_1h: -0.23335301781941098,
    change_1d: 1.6021253124876722,
    change_7d: 4.160970927078168,
    staking: 17183177.58506755,
    mcap: 181961,
  },
  {
    id: '1455',
    name: 'ClaimSwap',
    address: 'klaytn:0xcf87f94fd8f6b6f0b479771f10df672f99eada63',
    symbol: 'CLA',
    url: 'https://claimswap.org/',
    description: 'Swap, earn, and claim on the decentralized, community driven platform',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/claimswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Klaytn'],
    oracles: [],
    forkedFrom: ['SushiSwap'],
    module: 'claimswap/index.js',
    twitter: 'claimswap',
    language: 'Solidity',
    audit_links: ['https://github.com/claimswap/claimswap-audit'],
    listedAt: 1645643108,
    slug: 'claimswap',
    tvl: 83262053.3946747,
    chainTvls: { 'Klaytn-staking': 7717665.62004984, Klaytn: 83262053.3946747, staking: 7717665.62004984 },
    change_1h: 0.4839646832251958,
    change_1d: 12.648294117553306,
    change_7d: 24.00353511084694,
    staking: 7717665.62004984,
  },
  {
    id: '1087',
    name: 'Algofi',
    address: null,
    symbol: '-',
    url: 'https://www.algofi.org/',
    description: 'Decentralized lending protocol and stablecoin built on Algorand',
    chain: 'Algorand',
    logo: 'https://icons.llama.fi/algofi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Algorand'],
    module: 'algofi/index.js',
    twitter: 'algofiorg',
    oracles: ['TWAP'],
    audit_links: ['https://github.com/runtimeverification/publications/blob/main/reports/smart-contracts/Algofi.pdf'],
    listedAt: 1640301190,
    slug: 'algofi',
    tvl: 83163058.15944235,
    chainTvls: {
      'Algorand-borrowed': 51221055.30525646,
      Algorand: 83163058.15944235,
      'Algorand-staking': 12090867.814222123,
      borrowed: 51221055.30525646,
      staking: 12090867.814222123,
    },
    change_1h: -0.042198497952739444,
    change_1d: -0.16851858507186535,
    change_7d: 13.492781228501173,
    staking: 12090867.814222123,
  },
  {
    id: '557',
    name: 'Meta Pool',
    address: 'near:-',
    symbol: 'META',
    url: 'https://metapool.app',
    description: 'Meta Pool is a liquid staking protocol built on the NEAR blockchain.',
    chain: 'Near',
    logo: 'https://icons.llama.fi/meta-pool.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Liquid Staking',
    chains: ['Near'],
    module: 'metapool.js',
    twitter: 'meta_pool',
    slug: 'meta-pool',
    tvl: 82680226.87645501,
    chainTvls: { Near: 82680226.87645501 },
    change_1h: 1.8521493368295125,
    change_1d: 3.265680880404105,
    change_7d: 24.41545635300848,
  },
  {
    id: '1515',
    name: 'Echidna Finance',
    address: 'avax:0xeb8343D5284CaEc921F035207ca94DB6BAaaCBcd',
    symbol: 'ECD',
    url: 'https://www.echidna.finance',
    description: 'The Ultimate Yield Boosting Protocol For Platypus Finance',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/echidna.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'echidna',
    cmcId: '18475',
    category: 'Yield',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: [],
    module: 'echidna/index.js',
    twitter: 'echidna_finance',
    audit_links: ['https://docs.echidna.finance/docs/security/audits'],
    listedAt: 1646627764,
    slug: 'echidna-finance',
    tvl: 81971687.48262945,
    chainTvls: {
      Avalanche: 81971687.48262945,
      'Avalanche-staking': 1037646.0955276384,
      pool2: 706459.3744215516,
      'Avalanche-pool2': 706459.3744215516,
      staking: 1037646.0955276384,
    },
    change_1h: 0.3553143897148061,
    change_1d: 6.143129246360317,
    change_7d: -26.524569214186926,
    staking: 1037646.0955276384,
    pool2: 706459.3744215516,
    fdv: 21227819,
    mcap: 0,
  },
  {
    id: '329',
    name: 'Sovryn',
    address: 'rsk:0xEfC78FC7D48B64958315949279bA181C2114abbD',
    symbol: 'SOV',
    url: 'https://www.sovryn.app/',
    description:
      'Sovryn is a non-custodial and permission-less smart contract based system for bitcoin lending, borrowing and margin trading.',
    chain: 'RSK',
    logo: 'https://icons.llama.fi/sovryn.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'sovryn',
    cmcId: '8669',
    category: 'Dexes',
    chains: ['RSK'],
    module: 'sovryn.js',
    twitter: 'SovrynBTC',
    audit_links: ['https://www.sovryn.app/library'],
    slug: 'sovryn',
    tvl: 80181509.58514899,
    chainTvls: { RSK: 80181509.58514899 },
    change_1h: -0.09202088690565802,
    change_1d: 5.7992386915924214,
    change_7d: 3.4058686585138673,
    fdv: 305365121,
    mcap: 65395165,
  },
  {
    id: '509',
    name: 'Centrifuge',
    address: '0xc221b7e65ffc80de234bbb6667abdd46593d34f0',
    symbol: 'CFG',
    url: 'https://tinlake.centrifuge.io',
    description: 'Centrifuge is an open market place of real-world asset pools. Investments earn rewards in CFG token.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/centrifuge.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wrapped-centrifuge',
    cmcId: '6748',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'tinlake/index.js',
    twitter: 'centrifuge',
    audit_links: ['https://github.com/centrifuge/security/blob/master/audits/tinlake/LA-Tinlake-Audit-v0.3.0.pdf'],
    slug: 'centrifuge',
    tvl: 79081851.21405044,
    chainTvls: { Ethereum: 79081851.21405044 },
    change_1h: -0.2275449101796312,
    change_1d: 0.23212709364213424,
    change_7d: 0.2217117774545585,
    fdv: 241830919,
    mcap: 112438501,
  },
  {
    id: '150',
    name: 'Idle Finance',
    address: '0x875773784Af8135eA0ef43b5a374AaD105c5D39e',
    symbol: 'IDLE',
    url: 'https://idle.finance',
    description:
      'Choose your strategy and earn the yield you deserve without worry about finding the best option, either if you want to optimize returns or risks.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/idle-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'idle',
    cmcId: '7841',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Polygon'],
    module: 'idle/index.js',
    twitter: 'idlefinance',
    audit_links: ['https://certificate.quantstamp.com/full/idle-finance'],
    oracles: ['Chainlink'],
    slug: 'idle-finance',
    tvl: 78551338.3825667,
    chainTvls: { Ethereum: 77682292.51895186, Polygon: 869045.8636148481 },
    change_1h: 0.12632833811703392,
    change_1d: 1.0064937450052582,
    change_7d: 12.119518822514848,
    fdv: 24182206,
    mcap: 4948023,
  },
  {
    id: '781',
    name: 'Klima DAO',
    address: 'polygon:0x4e78011ce80ee02d2c3e649fb657e45898257815',
    symbol: 'KLIMA',
    url: 'https://www.klimadao.finance',
    description:
      'Klima DAO is a Decentralised Autonomous Organisation for change. Klima DAO develops infrastructure incentives that fulfill our manifesto, through primitives such as the KLIMA token. We will also solve the critical problems of the carbon markets: illiquidity, opacity and inefficiency.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/klima-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'klima-dao',
    cmcId: '12873',
    category: 'Reserve Currency',
    chains: ['Polygon'],
    module: 'klima-dao/index.js',
    twitter: 'KlimaDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1636042787,
    slug: 'klima-dao',
    tvl: 77773674.51037002,
    chainTvls: { Polygon: 77773674.51037002, 'Polygon-staking': 84005145.14095397, staking: 84005145.14095397 },
    change_1h: -0.050466615711172835,
    change_1d: 1.262783444907484,
    change_7d: 14.022994538890131,
    staking: 84005145.14095397,
    mcap: 97136053,
  },
  {
    id: '1487',
    name: 'KlayBank',
    address: 'klaytn:0x946bc715501413b9454bb6a31412a21998763f2d',
    symbol: 'KBT',
    url: 'https://klaybank.org',
    description: 'Klaybank is the first Klaytn based lending protocol that supports Flashloan.',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/klaybank.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Klaytn'],
    oracles: ['TWAP'],
    forkedFrom: ['Aave'],
    module: 'klaybank/index.js',
    twitter: 'klaybank',
    audit_links: [
      'https://github.com/klaybank/klaybank-bondsale/blob/main/audits/%20Hexlant-Bondsale-2022-01-13.pdf.pdf',
      'https://github.com/klaybank/klaybank-stake/blob/main/audits/Smart_Contract_Audit_Expert-Stake-2022-01-20.pdf',
      'https://github.com/klaybank/klaybank-stake/blob/main/audits/Smart_Contract_Audit_Hexlant-Stake-2022-02-23.pdf',
    ],
    listedAt: 1646157808,
    slug: 'klaybank',
    tvl: 76057839.07260677,
    chainTvls: {
      Bonding: 0,
      'Klaytn-staking': 1918381.4869077054,
      Klaytn: 76057839.07260677,
      'Klaytn-Bonding': 0,
      staking: 1918381.4869077054,
    },
    change_1h: -2.7576937004992743,
    change_1d: -0.5186158946649471,
    change_7d: 2.8995704084082092,
    staking: 1918381.4869077054,
  },
  {
    id: '1384',
    name: 'Hermes Protocol',
    address: '-',
    symbol: '-',
    url: 'https://hermes.maiadao.xyz/',
    description:
      'Hermes is the first DEX that allows low cost, near 0 slippage trades on uncorrelated or tightly correlated assets. The protocol incentivizes fees instead of liquidity.',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/hermes-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Metis'],
    module: 'hermes-protocol/index.js',
    twitter: 'MaiaDAOMetis',
    language: 'Solidity',
    forkedFrom: ['Solidly'],
    listedAt: 1644507498,
    slug: 'hermes-protocol',
    tvl: 75393319.72968735,
    chainTvls: { Metis: 75393319.72968735 },
    change_1h: -0.010034836361725752,
    change_1d: 1.7909877439352186,
    change_7d: -44.052742991236705,
  },
  {
    id: '603',
    name: 'Socean',
    address: 'solana:5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm',
    symbol: 'SOCN',
    url: 'https://www.socean.fi',
    description:
      'Socean is a stake pool for the Solana blockchain. Users deposit their SOL and receive a SOCN token. The SOCN token can also be used in a variety of DeFi applications to earn extra returns.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/socean.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Solana'],
    module: 'socean/index.js',
    twitter: 'SoceanFinance',
    audit_links: [
      'http://solana.com/SolanaNeodymeStakePoolAudit.pdf',
      'http://solana.com/SolanaQuantstampStakePoolAudit.pdf',
      'http://solana.com/SolanaKudelskiStakePoolAudit.pdf',
    ],
    openSource: false,
    slug: 'socean',
    tvl: 75325694.40621716,
    chainTvls: { Solana: 75325694.40621716 },
    change_1h: 0,
    change_1d: 2.8943057225453828,
    change_7d: 22.190389950414783,
  },
  {
    id: '726',
    name: 'Solo Top',
    address: null,
    symbol: '-',
    url: 'https://solo.top',
    description:
      'Solo.top is a single-asset LP mining aggregator aims to earn liquidity incentive with zero impermanent loss, and higher yield and safer to all users.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/solo-top.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance', 'Heco', 'Polygon', 'OKExChain'],
    module: 'solo/index.js',
    twitter: 'solo_top',
    audit_links: ['https://www.certik.com/projects/solotop'],
    oracles: ['Chainlink'],
    listedAt: 1635500629,
    slug: 'solo-top',
    tvl: 74750185.23865032,
    chainTvls: {
      Binance: 58948653.84163013,
      OKExChain: 215325.56953217313,
      Heco: 9593534.790218316,
      Polygon: 5992671.03726971,
    },
    change_1h: -0.6537049218987647,
    change_1d: 0.8241318412714378,
    change_7d: 42.298773894205254,
  },
  {
    id: '1002',
    name: 'YokaiSwap',
    address: 'godwoken:0xb02c930c2825a960a50ba4ab005e8264498b64a0',
    symbol: 'YOK',
    url: 'https://www.yokaiswap.com',
    description:
      'YokaiSwap is a next generation interoperable, decentralized trading platform and the first to come from the Nervos network.',
    chain: 'Godwoken',
    logo: 'https://icons.llama.fi/yokaiswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Godwoken'],
    module: 'yokaiswap/index.js',
    twitter: 'yokaiswap',
    forkedFrom: ['Uniswap'],
    listedAt: 1639116787,
    slug: 'yokaiswap',
    tvl: 74393446.24281634,
    chainTvls: { Godwoken: 74393446.24281634, 'Godwoken-staking': 7994915.514396271, staking: 7994915.514396271 },
    change_1h: -0.1615709851419922,
    change_1d: 1.329414988140016,
    change_7d: 7.267739464762627,
    staking: 7994915.514396271,
  },
  {
    id: '739',
    name: 'Aldrin',
    address: 'solana:E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp',
    symbol: 'RIN',
    url: 'https://aldrin.com',
    description: 'Aldrin’s mission is to simplify DeFi and create powerful tools for all traders to succeed.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/aldrin.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'aldrin',
    cmcId: '10935',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'aldrin/index.js',
    twitter: 'Aldrin_Exchange',
    audit_links: ['https://dex.aldrin.com/877f900320eec44c13409814fe473fb7.pdf'],
    listedAt: 1635802554,
    openSource: false,
    slug: 'aldrin',
    tvl: 73960399.65709315,
    chainTvls: { Solana: 73960399.65709315 },
    change_1h: -0.22694610778442836,
    change_1d: 0.13642318833613842,
    change_7d: -0.22694610778442836,
    fdv: 54193438,
    mcap: 9784512,
  },
  {
    id: '207',
    name: 'Unslashed',
    address: '0xe0e05c43c097b0982db6c9d626c4eb9e95c3b9ce',
    symbol: 'USF',
    url: 'https://unslashed.finance/',
    description: 'Decentralized insurance for major DeFi protocols',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/unslashed.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'unslashed-finance',
    cmcId: '8896',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'unslashed.js',
    twitter: 'UnslashedF',
    audit_links: ['https://documentation.unslashed.finance/risks-and-security/audits'],
    slug: 'unslashed',
    tvl: 73347969.96026644,
    chainTvls: { Ethereum: 73347969.96026644 },
    change_1h: 0.04667767615993057,
    change_1d: -2.216548235866256,
    change_7d: 12.136930018141001,
    fdv: 21956447,
    mcap: 5386439,
  },
  {
    id: '220',
    name: 'Vesper',
    address: '0x1b40183efb4dd766f11bda7a7c3ad8982e998421',
    symbol: 'VSP',
    url: 'https://vesper.finance/',
    description: 'Vesper is a platform for easy-to-use DeFi products.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/vesper.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'vesper-finance',
    cmcId: '8492',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'vesper/index.js',
    twitter: 'VesperFi',
    audit_links: ['https://github.com/vesperfi/doc/tree/main/audit'],
    oracles: ['Chainlink'],
    slug: 'vesper',
    tvl: 70854038.60290776,
    chainTvls: { Ethereum: 70854038.60290776 },
    change_1h: 0.7644425691521093,
    change_1d: 1.1465795303661253,
    change_7d: 12.222475842132823,
    fdv: 24212848,
    mcap: 20616219,
  },
  {
    id: '461',
    name: 'Planet Finance',
    address: 'bsc:0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
    symbol: 'AQUA',
    url: 'https://planetfinance.io',
    description:
      'The best DeFi apps all in one place at the apex of high yields and low risk — starting with the most optimized stable coin aggregator in crypto.‌',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/planet-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'planet-finance',
    cmcId: '10023',
    category: 'Yield',
    chains: ['Binance'],
    module: 'planet-finance/index.js',
    twitter: 'planet_finance',
    audit_links:
      'https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/Planet_Finance_Smart_Contract_Security_Audit_Halborn_v1_1.pdf',
    slug: 'planet-finance',
    tvl: 69795079.93162507,
    chainTvls: { Binance: 69795079.93162507, 'Binance-staking': 30105892.237408184, staking: 30105892.237408184 },
    change_1h: 0.669399166934042,
    change_1d: 3.0513988823680336,
    change_7d: 14.574957108926114,
    staking: 30105892.237408184,
    fdv: 30144229,
    mcap: 30154320,
  },
  {
    id: '448',
    name: 'Balanced',
    address: 'icon:cxf61cd5a45dc9f91c15aa65831a30a90d59a09619',
    symbol: 'BALN',
    url: 'https://balanced.network',
    description:
      'Balanced is a decentralized finance platform designed for ease of use. Swap assets on the ICON blockchain, and get paid Balance Tokens (BALN) to borrow assets or supply liquidity. BALN holders have the power to vote on platform changes and earn network fees.',
    chain: 'Icon',
    logo: 'https://icons.llama.fi/balanced.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '11262',
    category: 'Dexes',
    chains: ['Icon'],
    module: 'balanced/index.js',
    twitter: 'BalancedDAO',
    audit_links: ['https://docs.balanced.network/security#smart-contract-audits'],
    oracles: ['Band'],
    slug: 'balanced',
    tvl: 69701135.31,
    chainTvls: { Icon: 69701135.31 },
    change_1h: 0.16024125948426615,
    change_1d: 0.3262523911663777,
    change_7d: 25.62784237533316,
  },
  {
    id: '233',
    name: 'Metronome',
    address: '0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e',
    symbol: 'MET',
    url: 'https://metronome.io/',
    description: 'Metronome is the world’s first autonomous cryptocurrency.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/metronome.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'metronome',
    cmcId: '2873',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'metronome/index.js',
    twitter: 'MET_Token',
    audit_links: ['https://github.com/autonomoussoftware/metronome-audits'],
    slug: 'metronome',
    tvl: 69297490.92029594,
    chainTvls: { Ethereum: 69297490.92029594 },
    change_1h: 0.9714459487429821,
    change_1d: 1.5883564286773577,
    change_7d: 15.933453276348715,
    mcap: 34047614,
  },
  {
    id: '183',
    name: 'Wing Finance',
    address: '-',
    symbol: 'WING',
    url: 'https://wing.finance',
    description: 'A Credit-based, cross-chain DeFi Platform.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wing-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wing-finance',
    cmcId: '7048',
    category: 'Lending',
    chains: ['Ontology', 'Ethereum', 'Binance', 'OKExChain'],
    module: 'wing.js',
    twitter: 'Wing_Finance',
    audit_links: ['https://docs.wing.finance/#audits'],
    oracles: ['Chainlink'],
    slug: 'wing-finance',
    tvl: 67870217.43721887,
    chainTvls: {
      'OKExChain-staking': 17146.546880507773,
      OKExChain: 472597.7861187579,
      Ontology: 56797302.083344996,
      'Ontology-staking': 1332478.6961476672,
      Binance: 2393100.039413329,
      Ethereum: 6210777.300674101,
      'Binance-staking': 126766.06255816175,
      'Ethereum-staking': 77154.28987055244,
      staking: 1553545.5954568894,
    },
    change_1h: 0.6420241772677571,
    change_1d: 0.9335409963989463,
    change_7d: 16.430903249618908,
    staking: 1553545.5954568894,
    fdv: 50182822,
    mcap: 24330906,
  },
  {
    id: '507',
    name: 'DefiBox',
    address: 'bsc:0x708c0e1d2417603d9acb0ae04fefbb93a7a322ff',
    symbol: 'BOX',
    url: 'https://defibox.io',
    description:
      'Defibox is a one-stop DeFi application platform. The project was launched on EOS chain (https://defibox.io) on July 21, 2020 and Swap protocol was launched on BSC chain(https://bscdapp.defibox.io/) on July 21, 2021. Currently, Defibox has launched three protocols, namely Swap protocol, USN stable coin protocol and decentralized lending protocol.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/defibox.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'defibox',
    cmcId: '6960',
    category: 'Dexes',
    chains: ['EOS', 'Wax', 'Binance'],
    module: 'defibox/index.js',
    twitter: 'DefiboxOfficial',
    audit_links: [
      'https://support.defibox.cc/hc/en-us/articles/900006609803-PeckShield-Smart-Contract-Security-Audit-Report',
      'https://support.defibox.cc/hc/en-us/articles/900005670046',
    ],
    slug: 'defibox',
    tvl: 66830938.26955993,
    chainTvls: { Binance: 80673.72443389261, EOS: 66257161.423889264, Wax: 496958.186547253 },
    change_1h: -0.394119312906696,
    change_1d: -0.6688864370053125,
    change_7d: 12.949755011767962,
    fdv: 12737101,
    mcap: 6634929,
  },
  {
    id: '338',
    name: 'Inverse Finance',
    address: '0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68',
    symbol: 'INV',
    url: 'https://inverse.finance/',
    description:
      'DeFi Summer was just the beginning. Meet Inverse, part of the new wave of decentralized banking & finance. From a capital-efficient money market, to tokenized synthetic assets, legacy finance is about to be invaded.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/inverse-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'inverse-finance',
    cmcId: '8720',
    category: 'CDP',
    chains: ['Ethereum'],
    module: 'inverse/index.js',
    twitter: 'InverseFinance',
    forkedFrom: ['Compound'],
    slug: 'inverse-finance',
    tvl: 66696461.87171575,
    chainTvls: { Ethereum: 66696461.87171575 },
    change_1h: -0.19955579219276842,
    change_1d: 3.0424783133784246,
    change_7d: 15.145215707531307,
    mcap: 35922238,
  },
  {
    id: '197',
    name: 'Ooki',
    address: '0x0De05F6447ab4D22c8827449EE4bA2D5C288379B',
    symbol: 'OOKI',
    url: 'https://hello.ooki.com',
    description:
      'Ooki is a flexible decentralized finance protocol for margin trading, borrowing, lending and staking on multiple blockchains.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/ooki.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ooki',
    cmcId: '5810',
    category: 'Lending',
    chains: ['Ethereum', 'Polygon', 'Binance'],
    module: 'ooki/index.js',
    twitter: 'OokiTrade',
    audit_links: ['https://docs.ooki.com/developers/audits'],
    oracles: ['Chainlink'],
    slug: 'ooki',
    tvl: 66289199.149157986,
    chainTvls: { Binance: 20936.48403352458, Ethereum: 65845348.90003943, Polygon: 422913.76508503145 },
    change_1h: -4.0042087336455126,
    change_1d: 6.563402578638076,
    change_7d: 0.15577551593501937,
    fdv: 152066826,
    mcap: 46235966,
  },
  {
    id: '607',
    name: 'Hundred Finance',
    address: 'hundredfinance.jpg',
    symbol: 'HND',
    url: 'https://hundred.finance',
    description:
      'Hundred Finance is the successor to Percent Finance, a community owned fork of Compound Finance using Chainlink oracles.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/hundred-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'hundred-finance',
    cmcId: null,
    category: 'Lending',
    chains: ['Arbitrum', 'Fantom', 'Harmony', 'Moonriver', 'Polygon', 'Ethereum', 'xDai'],
    module: 'hundredfinance/index.js',
    twitter: 'HundredFinance',
    audit_links: [
      'https://github.com/chainsulting/Smart-Contract-Security-Audits/blob/master/Percent%20Finance/02_Smart%20Contract%20Audit%20Percent%20Finance.pdf',
    ],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'hundred-finance',
    tvl: 65833528.63023475,
    chainTvls: {
      'Arbitrum-borrowed': 4480502.04848948,
      Moonriver: 2334925.36365131,
      'Polygon-borrowed': 18.660564426826106,
      'xDai-borrowed': 0,
      Harmony: 6553285.0947852945,
      'Harmony-borrowed': 508258.2014779201,
      'Moonriver-borrowed': 2308.3250702792157,
      'Fantom-borrowed': 9187643.34856274,
      Ethereum: 215.60059828147803,
      xDai: 0,
      Polygon: 17323.250876036058,
      'Ethereum-borrowed': 1.014581762743184,
      Arbitrum: 29699591.796556164,
      borrowed: 14178731.598746609,
      Fantom: 27228187.523767658,
    },
    change_1h: 0.41170755321948604,
    change_1d: 0.6560803142886158,
    change_7d: -6.284726545628189,
    fdv: 56104893,
    mcap: 6719267,
  },
  {
    id: '348',
    name: 'FilDA',
    address: 'heco:0xe36ffd17b2661eb57144ceaef942d95295e637f0',
    symbol: 'FILDA',
    url: 'https://filda.io/',
    description: 'Lending market',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/filda.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'filda',
    cmcId: '8426',
    category: 'Lending',
    chains: ['Heco', 'IoTeX', 'Elastos', 'Arbitrum', 'Polygon', 'Binance'],
    module: 'filda/index.js',
    twitter: 'FilDAFinance',
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=c6eb91a0f506aac62c334eb909a37f95e236987d3b3089c9ff902e6c39f16389',
      'https://docs.filda.io/zhong-wen/dong-tai-geng-xin/2021112-geng-xin-update#filda-passed-the-security-audit-of-knownsec',
    ],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'filda',
    tvl: 64892184.59170593,
    chainTvls: {
      'Arbitrum-borrowed': 27137.316983293887,
      'Elastos-borrowed': 2523025.41117536,
      'Polygon-borrowed': 5715.645507357839,
      'Binance-borrowed': 2.8095568518688396,
      Elastos: 5433540.72263548,
      Binance: 7.997242587879315,
      IoTeX: 22202830.321170643,
      Heco: 37189560.58054227,
      Polygon: 25113.938754489376,
      Arbitrum: 41131.03136046995,
      borrowed: 15290762.897147879,
      'IoTeX-borrowed': 5862198.692112751,
      'Heco-borrowed': 6872683.021812264,
    },
    change_1h: -3.4246026140207135,
    change_1d: -2.2145903009512438,
    change_7d: 0.3232676419903555,
    fdv: 7117563,
    mcap: 2277009,
  },
  {
    id: '181',
    name: 'MANTRA DAO',
    address: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d',
    symbol: 'OM',
    url: 'https://mantradao.com',
    description:
      'MANTRA DAO leverages the wisdom of the crowd to give financial control back to the people to grow wealth together.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mantra-dao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'mantra-dao',
    cmcId: '6536',
    category: 'Lending',
    chains: ['Ethereum', 'Binance', 'Polygon'],
    module: 'mantradao.js',
    twitter: 'MANTRADAO',
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=1193264f67af7c2bb840306b82eff6218471cf4fbed79a7d48d6a01a93030e35',
    ],
    oracles: ['Band'],
    slug: 'mantra-dao',
    tvl: 63258354.36492089,
    chainTvls: {
      Binance: 12210504.116822965,
      Ethereum: 49414110.13593011,
      Polygon: 1633740.1121678108,
      'Ethereum-borrowed': 18391024.349999998,
      borrowed: 18391024.349999998,
    },
    change_1h: 0.39039700280640943,
    change_1d: 2.9062449233584857,
    change_7d: 17.41838728179519,
    fdv: 78539126,
    mcap: 39522622,
  },
  {
    id: '130',
    name: 'The Tokenized Bitcoin',
    address: '0x3212b29E33587A00FB1C83346f5dBFA69A458923',
    symbol: 'imBTC',
    url: 'https://tokenlon.im/imbtc',
    description: 'imToken is an easy and secure digital wallet trusted by millions',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/the-tokenized-bitcoin.png',
    audits: null,
    audit_note: null,
    gecko_id: 'imbtc',
    cmcId: '5292',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'imbtc.js',
    twitter: 'tokenlon',
    audit_links: [
      'https://cure53.de/pentest-report_imtoken.pdf',
      'https://drive.google.com/file/d/1o7byxnZbhl8PkWpYHnWtxDgUp_cpPO73/view',
    ],
    slug: 'the-tokenized-bitcoin',
    tvl: 62260256.059999995,
    chainTvls: { Ethereum: 62260256.059999995 },
    change_1h: 0.016727303140555705,
    change_1d: 2.0004778314457496,
    change_7d: 19.374687076056233,
  },
  {
    id: '908',
    name: 'Knit Finance',
    address: '0xEF53462838000184F35f7D991452e5f25110b207',
    symbol: 'KFT',
    url: 'https://knit.finance',
    description:
      'Developed Single infrastructure which will allow various assets , liquidity to move from one chain to another seamlessely as well enable liquidity staking for DPoS assets',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/knit-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'knit-finance',
    cmcId: '9856',
    category: 'Cross Chain',
    chains: ['Fantom', 'Binance', 'Ethereum', 'Polygon', 'Heco'],
    module: 'knitfinance/index.js',
    twitter: 'KnitFinance',
    audit_links: ['https://www.certik.com/projects/knitfinance'],
    oracles: ['Internal', 'DIA'],
    listedAt: 1637799515,
    slug: 'knit-finance',
    tvl: 62022117.67795475,
    chainTvls: {
      Binance: 4500065.239072184,
      Ethereum: 4489137,
      Heco: 0,
      Polygon: 4.4982292660350005,
      Fantom: 53032910.940653294,
    },
    change_1h: -6.99615725352487e-8,
    change_1d: null,
    change_7d: null,
    fdv: 4453846,
    mcap: 489772,
  },
  {
    id: '514',
    name: 'Orion Money',
    address: '0x727f064a78dc734d33eec18d5370aef32ffd46e4',
    symbol: 'ORION',
    url: 'https://orion.money',
    description:
      "Orion Money's vision is to become a cross-chain stablecoin bank providing seamless and frictionless stablecoin saving, lending, and spending.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/orion-money.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'orion-money',
    cmcId: '12115',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'orion-money/index.js',
    twitter: 'orion_money',
    audit_links: ['https://orion.money/security-and-audits'],
    slug: 'orion-money',
    tvl: 61784991.89788828,
    chainTvls: { Ethereum: 61784991.89788828 },
    change_1h: -0.09453635756922552,
    change_1d: -0.6901630270870953,
    change_7d: -5.694033621874809,
    fdv: 374500122,
    mcap: 23276267,
  },
  {
    id: '1084',
    name: 'Pala',
    address: 'klaytn:0x7A1CdCA99FE5995ab8E317eDE8495c07Cbf488aD',
    symbol: 'PALA',
    url: 'https://pala.world',
    description: 'A truly decentralized social platform for crypto investors',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/pala.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Klaytn'],
    module: 'pala/index.js',
    twitter: 'attention_pala',
    listedAt: 1640256393,
    slug: 'pala',
    tvl: 60983874.4503,
    chainTvls: { 'Klaytn-staking': 73514481.91284001, Klaytn: 60983874.4503, staking: 73514481.91284001 },
    change_1h: -0.7386984937694336,
    change_1d: -9.740998992555305,
    change_7d: -19.37970555046506,
    staking: 73514481.91284001,
  },
  {
    id: '276',
    name: 'Stafi',
    address: '0xef3A930e1FfFFAcd2fc13434aC81bD278B0ecC8d',
    symbol: 'FIS',
    url: 'https://www.stafi.io/',
    description: 'Liquid staking provider.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/stafi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'stafi',
    cmcId: '5882',
    category: 'Staking',
    chains: ['Ethereum', 'Polkadot', 'Cosmos', 'Polygon', 'Solana', 'Binance', 'Kusama'],
    module: 'stafi/index.js',
    twitter: 'StaFi_Protocol',
    audit_links: ['https://github.com/stafiprotocol/stafi-bootstrap/tree/master/audits'],
    oracles: ['Chainlink'],
    slug: 'stafi',
    tvl: 59586760.476019226,
    chainTvls: {
      Cosmos: 385201.1079,
      Kusama: 40768.6424,
      Binance: 125633.93350000001,
      Ethereum: 58313198.298119225,
      'Ethereum-staking': 7744206.659383259,
      Polygon: 183630.97110000002,
      Solana: 128312.5946,
      staking: 7744206.659383259,
      Polkadot: 410014.9284,
    },
    change_1h: 0.003641012825326584,
    change_1d: 3.9640068998386084,
    change_7d: 15.571567469286009,
    staking: 7744206.659383259,
    fdv: 88805081,
    mcap: 43856644,
  },
  {
    id: '758',
    name: 'Spartacus',
    address: 'fantom:0x5602df4a94eb6c680190accfa2a475621e0ddbdc',
    symbol: 'SPA',
    url: 'https://spartacus.finance/#/',
    description: 'Decentralized multi-chain reserve currency.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/spartacus.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'spartacus',
    cmcId: '13748',
    category: 'Reserve Currency',
    chains: ['Fantom'],
    module: 'spartacus/index.js',
    twitter: 'Spartacus_Fi',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1635966336,
    slug: 'spartacus',
    tvl: 58932037.62117484,
    chainTvls: { 'Fantom-staking': 9628185.480998097, staking: 9628185.480998097, Fantom: 58932037.62117484 },
    change_1h: -0.14394612248359806,
    change_1d: 0.040254236428921786,
    change_7d: -3.9654763741552586,
    staking: 9628185.480998097,
    fdv: 15207807,
    mcap: 15207807,
  },
  {
    id: '1291',
    name: 'Agora',
    address: 'metis:0x0Ed0Ca6872073E02cd3aE005BaF04bA43BE947fA',
    symbol: 'AGORA',
    url: 'https://agoradefi.io',
    description: 'Agora is an open source and non-custodial money market for lending and borrowing on Metis.',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/agora.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Metis'],
    module: 'agora/index.js',
    twitter: 'AgoraDefi',
    audit_links: [
      'https://agora-defi.notion.site/Contracts-Audits-a0a0058d0fd0450bab43ffe9d1db9f5e#b233bb933bc84084aa9ab56244764a98',
    ],
    forkedFrom: ['Compound'],
    listedAt: 1642626305,
    slug: 'agora',
    tvl: 58635640.50535255,
    chainTvls: { Metis: 58635640.50535255, 'Metis-borrowed': 54912957.700720415, borrowed: 54912957.700720415 },
    change_1h: 0.9455206606029378,
    change_1d: -11.866622860120998,
    change_7d: -25.351924861250325,
  },
  {
    id: '863',
    name: 'Yoshi Exchange',
    address: 'fantom:0x3dc57B391262e3aAe37a08D91241f9bA9d58b570',
    symbol: 'YOSHI',
    url: 'https://yoshi.exchange',
    description:
      'Yoshi.exchange is a DEX aggregator launching at Fantom Opera blockchain. We create the first closed-cycle united Fantom payment system. The main goal is to facilitate entry threshold to Fantom ecosystem for newcomers and to simplify the user experience for the current consumers.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/yoshi-exchange.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'yoshi-exchange',
    cmcId: '13118',
    category: 'Dexes',
    chains: ['Fantom', 'Binance'],
    module: 'yoshi-exchange/index.js',
    twitter: 'YoshiExchange',
    audit_links: ['https://hacken.io/wp-content/uploads/2021/10/Yoshi_22102021SCAudit_Report_2.pdf'],
    listedAt: 1637346402,
    forkedFrom: ['Uniswap'],
    slug: 'yoshi-exchange',
    tvl: 56897653.408903286,
    chainTvls: { Binance: 9597781.744416196, Fantom: 47299871.66448709 },
    change_1h: 1.2075592954873713,
    change_1d: 0.4518024926666584,
    change_7d: 5.154665621713789,
    fdv: 1453087935,
    mcap: 195192108,
  },
  {
    id: '469',
    name: 'Port Finance',
    address: 'solana:PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y',
    symbol: 'PORT',
    url: 'https://port.finance',
    description:
      'Port Finance is a decentralized Money Market protocol on Solana, aiming to provide a full suite of lending products including but not limited to: variable rate lending, fixed rate lending and interest rate swaps.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/port-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'port-finance',
    cmcId: '11220',
    category: 'Lending',
    chains: ['Solana'],
    module: 'portfinance/index.js',
    twitter: 'port_finance',
    oracles: ['Pyth'],
    slug: 'port-finance',
    tvl: 55829957.998619325,
    chainTvls: { Solana: 55829957.998619325 },
    change_1h: 1.3400190528922025,
    change_1d: 2.3459926922779744,
    change_7d: 9.713343199594362,
    fdv: 66356842,
    mcap: 7267461,
  },
  {
    id: '221',
    name: 'Defi Swap',
    address: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
    symbol: 'CRO',
    url: 'https://crypto.com/defi/swap',
    description:
      'Crypto.com DeFi Swap is a fork of Uniswap V2 designed to be the best place to swap and farm DeFi coins.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/defi-swap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'crypto-com-chain',
    cmcId: '1776',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'defi-swap/index.js',
    twitter: 'cryptocom',
    audit_links: ['https://crypto.com/defi/swap-protocol-audit.pdf'],
    forkedFrom: ['Uniswap'],
    slug: 'defi-swap',
    tvl: 55527453.72214969,
    chainTvls: { Ethereum: 55527453.72214969 },
    change_1h: 0.1885060465745596,
    change_1d: 1.3460588336268842,
    change_7d: 9.154457823213576,
    mcap: 12464166552,
  },
  {
    id: '151',
    name: 'DeversiFi',
    address: '0xdddddd4301a082e62e84e43f474f044423921918',
    symbol: 'DVF',
    url: 'https://www.deversifi.com/',
    description:
      'DeversiFi gives traders ​the edge​ in fast moving decentralised finance (DeFi) markets by allowing them to ​trade at lightning speed​ and ​with deep aggregated liquidity​, directly from their privately owned cryptocurrency wallet.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/deversifi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'dvf',
    cmcId: '1057',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'deversifi/index.js',
    twitter: 'deversifi',
    audit_links: ['https://www.deversifi.com/audit'],
    language: 'Cairo',
    slug: 'deversifi',
    tvl: 55521857.56044492,
    chainTvls: { Ethereum: 55521857.56044492 },
    change_1h: -1.4210854715202004e-14,
    change_1d: 1.9493931630020285,
    change_7d: -0.6577232519098715,
    fdv: 494441452,
    mcap: 59756814,
  },
  {
    id: '275',
    name: 'SharedStake',
    address: '0x84810bcf08744d5862b8181f12d17bfd57d3b078',
    symbol: 'SGT',
    url: 'https://www.sharedstake.org/',
    description: 'Liquid staking provider.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/sharedstake.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'sharedstake-governance-token',
    cmcId: '8445',
    category: 'Liquid Staking',
    chains: ['Ethereum'],
    module: 'sharedstake/index.js',
    twitter: 'SharedStakeDAO',
    audit_links: ['https://www.certik.org/projects/sharedstake'],
    slug: 'sharedstake',
    tvl: 55505585.79449175,
    chainTvls: { Ethereum: 55505585.79449175, pool2: 550.0184082379824, 'Ethereum-pool2': 550.0184082379824 },
    change_1h: 1.1871392278053179,
    change_1d: 4.025344316270193,
    change_7d: 15.352847485693587,
    pool2: 550.0184082379824,
    fdv: 437798,
    mcap: 113176,
  },
  {
    id: '1283',
    name: 'Bent Finance',
    address: '0x01597e397605bf280674bf292623460b4204c375',
    symbol: 'BENT',
    url: 'https://app.bentfinance.com',
    description:
      'BENT is a staking and farming platform that enhances your curve to elevate your yields. By the people for the people.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bent-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bent-finance',
    cmcId: '15733',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'bent/index.js',
    twitter: 'BENT_Finance',
    audit_links: ['https://hacken.io/audits/#bent_finance'],
    listedAt: 1642558178,
    slug: 'bent-finance',
    tvl: 55012347.73842674,
    chainTvls: {
      Ethereum: 55012347.73842674,
      pool2: 7456968.150656017,
      'Ethereum-staking': 6569016.927594567,
      staking: 6569016.927594567,
      'Ethereum-pool2': 7456968.150656017,
    },
    change_1h: 0.5839117568233263,
    change_1d: 2.8289797819190454,
    change_7d: 7.423550070226597,
    staking: 6569016.927594567,
    pool2: 7456968.150656017,
    fdv: 335533870,
    mcap: 11053348,
  },
  {
    id: '1491',
    name: 'RelayChain',
    address: '0x5d843fa9495d23de997c394296ac7b4d721e841c',
    symbol: 'RELAY',
    url: 'https://www.relaychain.com',
    description:
      "Access cross-chain opportunities on the World's Leading Blockchains using Relay's simple, fast, and secure bridge",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/relaychain.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'relay-token',
    cmcId: '11317',
    category: 'Bridge',
    chains: [
      'Metis',
      'Fantom',
      'Ethereum',
      'Avalanche',
      'Polygon',
      'Binance',
      'Cronos',
      'Harmony',
      'Heco',
      'Moonriver',
      'IoTeX',
    ],
    oracles: [],
    forkedFrom: [],
    module: 'relayChain/index.js',
    twitter: 'relay_chain',
    audit_links: ['https://consensys.net/diligence/audits/private/adash47d-chainbridge/'],
    listedAt: 1646164456,
    slug: 'relaychain',
    tvl: 54177737.32294002,
    chainTvls: {
      Metis: 19175651.283303175,
      Cronos: 569034.3489336341,
      Moonriver: 11546.053885387857,
      Harmony: 202563.75863947917,
      Binance: 1104502.742651801,
      IoTeX: 0,
      Ethereum: 10062410.484586176,
      Heco: 12177.887227101943,
      Avalanche: 6556231.537815829,
      Polygon: 5066043.401545891,
      Fantom: 11417575.824351553,
    },
    change_1h: 0.1966271585058763,
    change_1d: -0.47450410974710167,
    change_7d: -16.7929162254828,
    fdv: 10083610,
    mcap: 4296752,
  },
  {
    id: '913',
    name: 'Defrost',
    address: 'avax:0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D',
    symbol: 'MELT',
    url: 'https://www.defrost.finance',
    description:
      'Defrost Finance is a decentralized protocol that allows you to leverage yield-bearing Tokens or other pool tokens from Avalanche and cross-chain protocols as collaterals for generating H2O, a USD-pegged stablecoin.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/defrost.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'defrost-finance',
    cmcId: null,
    category: 'CDP',
    chains: ['Avalanche'],
    module: 'defrost/index.js',
    twitter: 'Defrost_Finance',
    audit_links: ['https://github.com/DefrostFinance/Audit-Reports/blob/main/REP-Defrost-Finance-2021-11-10.pdf'],
    oracles: ['Chainlink'],
    listedAt: 1637875319,
    slug: 'defrost',
    tvl: 53652703.829616316,
    chainTvls: { Avalanche: 53652703.829616316, 'Avalanche-staking': 1798704.658645256, staking: 1798704.658645256 },
    change_1h: -0.22694610778442836,
    change_1d: -1.4091153952369382,
    change_7d: -2.0369897577213436,
    staking: 1798704.658645256,
    fdv: 37395638,
    mcap: 1396762,
  },
  {
    id: '709',
    name: 'pStake',
    address: '0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006',
    symbol: 'PSTAKE',
    url: 'https://pstake.finance',
    description:
      'pSTAKE is a liquid staking protocol unlocking the liquidity of staked assets. Stakers of PoS tokens can now stake their assets while maintaining the liquidity of these assets. On staking with pSTAKE, users earn staking rewards and also receive 1:1 pegged staked representative tokens (stkTOKENs) which can be used in DeFi to generate additional yield (yield on top of staking rewards).',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/pstake.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'pstake/index.js',
    twitter: 'pStakeFinance',
    audit_links: [
      'https://consensys.net/diligence/audits/private/61fqr5mouh4oq8/',
      'https://github.com/persistenceOne/pStake-auditReports',
    ],
    listedAt: 1635248310,
    slug: 'pstake',
    tvl: 53588694.38289279,
    chainTvls: { Ethereum: 53588694.38289279, pool2: 11264635.396695001, 'Ethereum-pool2': 11264635.396695001 },
    change_1h: 0.5739408342896155,
    change_1d: 1.764463201523796,
    change_7d: 2.458442966925233,
    pool2: 11264635.396695001,
  },
  {
    id: '886',
    name: 'Position Exchange',
    address: 'bsc:0x5CA42204cDaa70d5c773946e69dE942b85CA6706',
    symbol: 'POSI',
    url: 'https://app.position.exchange/',
    description:
      'Position Exchange is a Decentralized Trading Protocol, powered by a vAMM and operating on Binance Smart Chain initially, aiming to bridge the gap between people and the cryptocurrency markets and enhance trading experiences.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/positionexchange.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'position-token',
    cmcId: '11234',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'positionexchange/index.js',
    twitter: 'PositionEx',
    audit_links: ['https://www.certik.org/projects/positionexchange'],
    listedAt: 1637451430,
    slug: 'position-exchange',
    tvl: 53076589.181319304,
    chainTvls: {
      Binance: 53076589.181319304,
      'Binance-staking': 1596298.0434652457,
      'Binance-Treasury': 50369.928344741136,
      Treasury: 50369.928344741136,
      staking: 1596298.0434652457,
    },
    change_1h: 0.16032345100289547,
    change_1d: 12.777149389206684,
    change_7d: -13.881924865274726,
    staking: 1596298.0434652457,
    fdv: 120903504,
    mcap: 53615647,
  },
  {
    id: '489',
    name: 'Moola Market',
    address: 'celo:0x17700282592D6917F6A73D0bF8AcCf4D578c131e',
    symbol: 'MOO',
    url: 'https://moola.market',
    description:
      'Moola Market is a DeFi lending platform that allows loans to anyone! It supports CELO, cUSD (Celo Dollar) and cEUR (Celo Euro!)',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/moola-market.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'moola-market',
    cmcId: null,
    category: 'Lending',
    chains: ['Celo'],
    module: 'moola/index.js',
    twitter: 'Moola_Market',
    forkedFrom: ['Aave'],
    audit_links: ['https://drive.google.com/file/d/1qd1h0dujnp4Xxrl68ZTIMzbt4aXzMWY7/view'],
    slug: 'moola-market',
    tvl: 53032727.5944562,
    chainTvls: { Celo: 53032727.5944562, borrowed: 13597655.525152668, 'Celo-borrowed': 13597655.525152668 },
    change_1h: 0.23426572857489703,
    change_1d: 0.05803804382446742,
    change_7d: 14.919453969770387,
    mcap: 2036229,
  },
  {
    id: '239',
    name: 'PoolTogether',
    address: '0x0cec1a9154ff802e7934fc916ed7ca50bde6844e',
    symbol: 'POOL',
    url: 'https://pooltogether.com/',
    description: 'PoolTogetheris an open source and decentralized protocol for no-loss prize games',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/pooltogether.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pooltogether',
    cmcId: '8508',
    category: 'Services',
    chains: ['Polygon', 'Ethereum', 'Celo', 'Avalanche', 'Binance'],
    module: 'pooltogether/index.js',
    twitter: 'PoolTogether_',
    audit_links: ['https://docs.pooltogether.com/security/audits-and-testing'],
    oracles: ['Chainlink'],
    slug: 'pooltogether',
    tvl: 52946051.41556533,
    chainTvls: {
      Binance: 19285.441793031005,
      Ethereum: 21733501.478042275,
      Polygon: 25440249.97156062,
      Avalanche: 2738563.446969574,
      Celo: 3014451.0771998283,
    },
    change_1h: 0.09292836202511978,
    change_1d: 0.1355753549094203,
    change_7d: -1.1892602268269599,
    fdv: 31959089,
    mcap: 9797583,
  },
  {
    id: '358',
    name: 'Parrot Protocol',
    address: 'solana:PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44',
    symbol: 'PRT',
    url: 'https://parrot.fi',
    description: 'Stablecoin',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/parrot-protocol.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: 'parrot-protocol',
    cmcId: '11935',
    category: 'CDP',
    chains: ['Solana'],
    module: 'parrot.js',
    twitter: 'gopartyparrot',
    audit_links: ['https://doc.parrot.fi/security/'],
    openSource: false,
    slug: 'parrot-protocol',
    tvl: 52916875.53193042,
    chainTvls: { Solana: 52916875.53193042 },
    change_1h: 1.4511919955888999,
    change_1d: 2.503246851274696,
    change_7d: 19.60684519174258,
    fdv: 69091310,
    mcap: 0,
  },
  {
    id: '503',
    name: 'Lyra',
    address: 'optimism:0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
    symbol: 'LYRA',
    url: 'https://www.lyra.finance',
    description: 'The first complete decentralized options protocol built on Ethereum',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/lyra.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'lyra-finance',
    cmcId: null,
    category: 'Options',
    chains: ['Optimism', 'Ethereum'],
    module: 'lyra/index.js',
    twitter: 'lyrafinance',
    audit_links: ['https://github.com/lyra-finance/lyra-protocol/tree/master/audits/v1'],
    oracles: ['Chainlink'],
    slug: 'lyra',
    tvl: 52209909.102634236,
    chainTvls: {
      Optimism: 39950019.285975054,
      Ethereum: 12259889.81665918,
      'Ethereum-staking': 11357681.289411759,
      staking: 11357681.289411759,
    },
    change_1h: 0.01581869827920457,
    change_1d: -17.630694101406974,
    change_7d: -20.36283512718215,
    staking: 11357681.289411759,
    fdv: 281933165,
    mcap: 25935040,
  },
  {
    id: '1452',
    name: 'PayCash',
    address: 'eos:MLNK-eos-swap.pcash',
    symbol: 'MLNK',
    url: 'https://paycashswap.com',
    description:
      'PayCash – among other things – is a universal EOS blockchain-based decentralized p2p crypto-to-fiat gateway (exchange) facilitating easy entry and exit to and from the cryptocurrency universe.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/paycash.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'malinka',
    cmcId: null,
    category: 'Dexes',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'paycashswap/index.js',
    twitter: 'PayCashTweet',
    language: 'C++',
    listedAt: 1645578954,
    slug: 'paycash',
    tvl: 51620447.06727,
    chainTvls: { EOS: 51620447.06727 },
    change_1h: -0.001963900465199231,
    change_1d: 0.7478766697072388,
    change_7d: 4.138040141154903,
    mcap: 0,
  },
  {
    id: '141',
    name: 'UMA Protocol',
    address: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
    symbol: 'UMA',
    url: 'https://umaproject.org/',
    description: 'A Protocol for Building Synthetic Assets',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/uma-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'uma',
    cmcId: '5617',
    category: 'Synthetics',
    oracles: ['UMA'],
    chains: ['Ethereum', 'Boba', 'Polygon'],
    module: 'uma/index.js',
    twitter: 'UMAprotocol',
    audit_links: ['https://blog.openzeppelin.com/uma-audit-phase-2/'],
    slug: 'uma-protocol',
    tvl: 51504331.4270474,
    chainTvls: { Boba: 3340754.105011754, Ethereum: 45435969.01742585, Polygon: 2727608.3046097956 },
    change_1h: 0.1661281473324152,
    change_1d: 0.6363539397744802,
    change_7d: 8.689438398250246,
    fdv: 859373777,
    mcap: 529215778,
  },
  {
    id: '1139',
    name: 'Tethys Finance',
    address: 'metis:0x69fdb77064ec5c84FA2F21072973eB28441F43F3',
    symbol: 'TETHYS',
    url: 'https://tethys.finance',
    description:
      'We believe that in the future, L2 solutions will help Ethereum with scaling. Our mission is to empower the Metis Andromeda network with a fast, secure, reliable, and advanced native decentralized exchange app to handle all kinds of trading needs.',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/tethys-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Metis'],
    module: 'tethys/index.js',
    twitter: 'tethysfinance',
    audit_links: ['https://defisecurity.io/dist/files/tethys_finance_security_audit_report_1.0.pdf'],
    forkedFrom: ['Uniswap', 'Impermax Finance'],
    listedAt: 1640746550,
    slug: 'tethys-finance',
    tvl: 51288166.83569594,
    chainTvls: { Metis: 51288166.83569594, 'Metis-staking': 247035.66259340016, staking: 247035.66259340016 },
    change_1h: -1.3210581054127033,
    change_1d: null,
    change_7d: null,
    staking: 247035.66259340016,
  },
  {
    id: '693',
    name: 'Superfluid',
    address: null,
    symbol: '-',
    url: 'https://www.superfluid.finance/home',
    description:
      'Programmable Cashflows. Handle subscriptions, salaries, rewards and any composable stream of value, with continuous settlement and per-second netting for extreme capital efficiency.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/superfluid.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Payments',
    chains: ['xDai', 'Polygon'],
    module: 'superfluid.js',
    twitter: 'Superfluid_HQ',
    audit_links: [
      'https://github.com/superfluid-finance/protocol-monorepo/blob/dev/packages/ethereum-contracts/audits/PeckShield-Audit-Report-Superfluid-v1.0.pdf',
    ],
    listedAt: 1634846846,
    slug: 'superfluid',
    tvl: 51083617.501575775,
    chainTvls: { xDai: 38805419.72809085, Polygon: 12278197.773484925 },
    change_1h: -0.014932900011444872,
    change_1d: 1.6367162713067671,
    change_7d: -15.651064105668084,
  },
  {
    id: '1544',
    name: 'Concentrator',
    address: null,
    symbol: '-',
    url: 'https://concentrator.aladdin.club/',
    description:
      'Aladdin concentrator is a platform that boosts rewards for Curve & Convex stakers and liquidity providers alike, all in a simple and easy to use interface.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/concentrator.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'concentrator/index.js',
    twitter: '0xconcentrator',
    audit_links: ['https://aladdin.club/audits/AladdinDao_V3_Report_Secbit.pdf'],
    listedAt: 1647257070,
    slug: 'concentrator',
    tvl: 50412213.59230128,
    chainTvls: { Ethereum: 50412213.59230128 },
    change_1h: 0.553686060950227,
    change_1d: 2.2372412338117442,
    change_7d: 27.248757526278467,
  },
  {
    id: '251',
    name: 'Mimo Defi',
    address: '0x90b831fa3bebf58e9744a14d638e25b4ee06f9bc',
    symbol: 'MIMO',
    url: 'https://mimo.capital',
    description: 'The Mimo protocol is a decentralized price-stable token issuance protocol on the Ethereum blockchain',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mimo-defi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'mimo-parallel-governance-token',
    cmcId: '10831',
    category: 'Lending',
    chains: ['Ethereum', 'Polygon', 'Fantom'],
    module: 'mimo/index.js',
    twitter: 'mimodefi',
    audit_links: ['https://certificate.quantstamp.com/full/ten-x-titan'],
    slug: 'mimo-defi',
    tvl: 50403895.4209204,
    chainTvls: { Ethereum: 29822659.662881594, Polygon: 14865340.35423844, Fantom: 5715895.403800368 },
    change_1h: 0.3792029648288775,
    change_1d: 3.0033120988846207,
    change_7d: 13.848118937229032,
    mcap: 40263283,
  },
  {
    id: '245',
    name: 'Secret Network',
    address: '-',
    symbol: 'SCRT',
    url: 'https://scrt.network/',
    description:
      'Secret is the native coin of the Secret Network, a decentralized network for private / secure computation.',
    chain: 'Secret',
    logo: 'https://icons.llama.fi/secret-network.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'secret',
    cmcId: '5604',
    category: 'Chain',
    chains: ['Secret'],
    module: 'scrt.js',
    twitter: 'SecretNetwork',
    slug: 'secret-network',
    tvl: 49905497.885652356,
    chainTvls: { Secret: 49905497.885652356 },
    change_1h: 0.1465927433807792,
    change_1d: 2.21443045318064,
    change_7d: 9.272286389113972,
    mcap: 915409543,
  },
  {
    id: '385',
    name: 'Mercurial Finance',
    address: 'solana:MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K',
    symbol: 'MER',
    url: 'https://mercurial.finance/',
    description: 'Mercurial is building the infrastructure for stable and pegged assets for Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/mercurial-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'mercurial',
    cmcId: '9549',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'mercurial.js',
    twitter: 'MercurialFi',
    openSource: false,
    slug: 'mercurial-finance',
    tvl: 49724037.81386934,
    chainTvls: { Solana: 49724037.81386934 },
    change_1h: 1.3935670066796035,
    change_1d: 0.5741485446848742,
    change_7d: 30.808837742763615,
    fdv: 151687912,
    mcap: 8342835,
  },
  {
    id: '934',
    name: 'InvictusDAO',
    address: 'solana:inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY',
    symbol: 'IN',
    url: 'https://app.invictusdao.fi/#/dashboard',
    description: '$IN is the decentralized reserve currency of Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/invictusdao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'invictus',
    cmcId: '14278',
    category: 'Reserve Currency',
    chains: ['Solana'],
    module: 'invictus/index.js',
    twitter: 'InvictusDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638188717,
    openSource: false,
    slug: 'invictusdao',
    tvl: 49529804.790509626,
    chainTvls: { 'Solana-staking': 2959848.2859726846, Solana: 49529804.790509626, staking: 2959848.2859726846 },
    change_1h: -0.18455655307653274,
    change_1d: 0.28916090238617187,
    change_7d: 0.7796489231940882,
    staking: 2959848.2859726846,
    mcap: 12419969,
  },
  {
    id: '134',
    name: 'Pickle',
    address: '0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5',
    symbol: 'PICKLE',
    url: 'https://pickle.finance/',
    description:
      'Pickle Finance is a yield aggregator, auto-compounding users LP positions saving them time and money. The governance token PICKLE can be staked as DILL, to earn a share of the protocols revenues and boost users farm APY',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/pickle.svg',
    audits: '3',
    audit_note: null,
    gecko_id: 'pickle-finance',
    cmcId: '7022',
    category: 'Yield Aggregator',
    chains: [
      'Ethereum',
      'Aurora',
      'Arbitrum',
      'Polygon',
      'Moonriver',
      'Moonbeam',
      'Metis',
      'Optimism',
      'OKExChain',
      'Cronos',
      'Harmony',
    ],
    module: 'pickle/index.js',
    twitter: 'picklefinance',
    audit_links: ['https://docs.pickle.finance/faqs/security'],
    slug: 'pickle',
    tvl: 48737297.86806592,
    chainTvls: {
      Optimism: 40379.213063279996,
      Metis: 247782.31303862398,
      Cronos: 158.142657036,
      OKExChain: 14930.82783192,
      Moonriver: 1624744.0451189221,
      pool2: 806924.8499398228,
      'Polygon-pool2': 68782.80234680399,
      Harmony: 0,
      Moonbeam: 512643.790103718,
      'Ethereum-pool2': 691007.1400973428,
      Ethereum: 39178852.79182332,
      Aurora: 2717052.2116526878,
      'Ethereum-staking': 4596897.767354141,
      Polygon: 1831611.4119253862,
      Arbitrum: 2569143.1208510343,
      'Arbitrum-pool2': 47134.907495676,
      staking: 4596897.767354141,
    },
    change_1h: 0.07814373142223019,
    change_1d: 4.7256763420118375,
    change_7d: -9.635058053720854,
    staking: 4596897.767354141,
    pool2: 806924.8499398228,
    fdv: 9065105,
    mcap: 3856407,
  },
  {
    id: '332',
    name: 'Bao Finance',
    address: '0x374cb8c27130e2c9e04f44303f3c8351b9de61c1',
    symbol: 'BAO',
    url: 'https://www.bao.finance/',
    description:
      'Bao Finance is like a combination of SNX and Aave, but use LP tokens for collateral. Rather than re-invent the wheel, Bao will create new features for existing DeFi protocols. The BAO token acts as a governance token for the fully community run project. It is also backed by the insurance fund where all Bao fees go.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bao-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'bao-finance',
    cmcId: '8168',
    category: 'Yield',
    chains: ['Ethereum', 'xDai'],
    module: 'bao/index.js',
    twitter: 'BaoFinance',
    slug: 'bao-finance',
    tvl: 48410320.96135386,
    chainTvls: { Ethereum: 46474430.12944324, xDai: 1935890.8319106647 },
    change_1h: 0.7155966996083691,
    change_1d: null,
    change_7d: -6.403363302225145,
    fdv: 141274866,
    mcap: 10784525,
  },
  {
    id: '573',
    name: 'Liqee',
    address: '-',
    symbol: 'LQE',
    url: 'https://liqee.io',
    description:
      'Liqee is the world’s first lending protocol for liquid staking assets (PoS staking tokens, DeFi staking tokens). The protocol also provides tokenization for PoS and mining facilities and PoS validation services across a number of networks',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/liqee.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Binance', 'Ethereum'],
    module: 'liqee/index.js',
    twitter: 'liqeeio',
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'liqee',
    tvl: 47481173.53123987,
    chainTvls: { Binance: 40875824.279288016, Ethereum: 6605349.25195185 },
    change_1h: 0.21390843229001177,
    change_1d: 1.5932783315450365,
    change_7d: 6.441835868957341,
  },
  {
    id: '362',
    name: 'Perpetual Protocol',
    address: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
    symbol: 'PERP',
    url: 'https://perp.exchange/',
    description: 'Decentralized Perpetual Contracts for Every Asset.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/perpetual-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'perpetual-protocol',
    cmcId: '1301',
    category: 'Derivatives',
    chains: ['Optimism', 'xDai', 'Ethereum'],
    module: 'perp/index.js',
    twitter: 'perpprotocol',
    audit_links: ['https://docs.perp.fi/library/audit-report'],
    oracles: ['Chainlink'],
    slug: 'perpetual-protocol',
    tvl: 47412869.34619578,
    chainTvls: {
      Optimism: 30871831.96316035,
      xDai: 16541037.383035427,
      'Ethereum-staking': 153724343.9234582,
      staking: 153724343.9234582,
    },
    change_1h: 0.6857234180648817,
    change_1d: 1.8114828736494673,
    change_7d: 32.29594191738528,
    staking: 153724343.9234582,
    fdv: 751513912,
    mcap: 329545560,
  },
  {
    id: '1181',
    name: 'MojitoSwap',
    address: 'kcc:0x2ca48b4eea5a731c2b54e7c3944dbdb87c0cfb6f',
    symbol: 'MJT',
    url: 'https://www.mojitoswap.finance',
    description:
      'Mojito Finance is a decentralized exchange running on KCC, with lots of features that let you earn and win tokens. It is fast, cheap and easily accessible as it is not difficult to use. It has currently opened Trade ,Bar (Farm) and Wine Pools. Other functions are on the way. And it will continue to launch interesting functions such as Cocktail Tickets and NFT in the future.',
    chain: 'Kucoin',
    logo: 'https://icons.llama.fi/mojitoswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '1521',
    category: 'Dexes',
    chains: ['Kucoin'],
    module: 'mojitoswap/index.js',
    twitter: 'MojitoSwap',
    forkedFrom: ['Uniswap'],
    audit_links: [
      'https://github.com/MojitoFinance/mojito-swap-farm/blob/main/doc/PeckShield-Audit-Report-Mojito-v1.0.pdf',
    ],
    listedAt: 1641194662,
    slug: 'mojitoswap',
    tvl: 46912087.65790656,
    chainTvls: { 'Kucoin-staking': 8390535.094403673, Kucoin: 46912087.65790656, staking: 8390535.094403673 },
    change_1h: 1.5273681792193514,
    change_1d: 3.72161552915189,
    change_7d: 4.842690099196673,
    staking: 8390535.094403673,
  },
  {
    id: '331',
    name: 'MoneyOnChain',
    address: 'rsk:0x9AC7fE28967B30E3A4e6e03286d715b42B453D10',
    symbol: 'MOC',
    url: 'https://moneyonchain.com/',
    description:
      'Money On Chain is a self-contained DeFi protocol (a stablecoin, HODL token, leveraging mechanism, and more) which empowers Bitcoiners to improve performance of their assets while also retaining full control of their private keys.',
    chain: 'RSK',
    logo: 'https://icons.llama.fi/moneyonchain.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['RSK'],
    module: 'moneyonchain.js',
    twitter: 'moneyonchainok',
    audit_links: ['https://github.com/money-on-chain/Audits'],
    slug: 'moneyonchain',
    tvl: 46828514.27,
    chainTvls: { RSK: 46828514.27 },
    change_1h: 0.11324355740822512,
    change_1d: 1.1019784123341765,
    change_7d: 12.059364858869586,
  },
  {
    id: '1545',
    name: 'Steroids',
    address: null,
    symbol: '-',
    url: 'https://steroids.finance/',
    description:
      'Steroids is a platform to host liquidity mining programmes. Automatically mine liquidity for DeFi liquidity pools and farm tokens when contributing.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/steroids.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'steroids/index.js',
    twitter: 'SteroidsFinance',
    listedAt: 1647260100,
    slug: 'steroids',
    tvl: 46249042.83833735,
    chainTvls: { Ethereum: 46249042.83833735 },
    change_1h: 0,
    change_1d: 2.1269784455830916,
    change_7d: 12.730366126838305,
  },
  {
    id: '464',
    name: 'Beta Finance',
    address: '0xbe1a001fe942f96eea22ba08783140b9dcc09d28',
    symbol: 'BETA',
    url: 'https://betafinance.org',
    description: 'Beta Finance is the permissionless money market for borrowing, lending, and shorting crypto assets.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/beta-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'beta-finance',
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum', 'Avalanche'],
    module: 'betafinance/index.js',
    twitter: 'beta_finance',
    audit_links: ['https://betafinance.gitbook.io/betafinance/security-and-audits'],
    slug: 'beta-finance',
    tvl: 46194520.95626578,
    chainTvls: { Ethereum: 40663370.7827811, Avalanche: 5531150.173484675 },
    change_1h: 0.17372429673545753,
    change_1d: 6.088650944465826,
    change_7d: 8.047695966638074,
    fdv: 497530364,
    mcap: 179110931,
  },
  {
    id: '1315',
    name: 'NeoBurger',
    address: null,
    symbol: '-',
    url: 'https://neoburger.io/home',
    description: 'Delegate your NEO Governance to NeoBurger to mint bNEO and receive GAS',
    chain: 'NEO',
    logo: 'https://icons.llama.fi/neoburger.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Liquid Staking',
    chains: ['NEO'],
    module: 'neoburger/index.js',
    twitter: 'NeoBurger_io',
    listedAt: 1643142923,
    slug: 'neoburger',
    tvl: 46149543.026321955,
    chainTvls: { NEO: 46149543.026321955 },
    change_1h: 1.2181078391227942,
    change_1d: 3.8102504937397725,
    change_7d: 14.947155568835228,
  },
  {
    id: '217',
    name: 'Connext',
    address: '-',
    symbol: '-',
    url: 'https://connext.network/',
    description: 'Fast, non-custodial L1-L2 and cross-L2 communication.\r\n',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/connext.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Cross Chain',
    chains: [
      'Optimism',
      'Fantom',
      'Ethereum',
      'Polygon',
      'Moonbeam',
      'xDai',
      'Binance',
      'Arbitrum',
      'Avalanche',
      'Moonriver',
      'Fuse',
    ],
    module: 'connext/index.js',
    twitter: 'ConnextNetwork',
    audit_links: ['https://drive.google.com/file/d/1l42vxzHwLXrKU10v3FutG2DWthU43vB8/view'],
    slug: 'connext',
    tvl: 45552931.516306534,
    chainTvls: {
      Optimism: 12508947.031310078,
      Moonriver: 1526323.032859638,
      Avalanche: 2524716.701137294,
      Moonbeam: 3406059.818154755,
      Binance: 2720250.4899590765,
      Ethereum: 4200843.866294358,
      xDai: 3297756.1621015673,
      Polygon: 3734186.2161489744,
      Arbitrum: 2699555.449259094,
      Fuse: 0,
      Fantom: 8934292.749081705,
    },
    change_1h: -0.033806963012025903,
    change_1d: -2.304543652129695,
    change_7d: null,
  },
  {
    id: '354',
    name: 'InsurAce',
    address: '0x544c42fbb96b39b21df61cf322b5edc285ee7429',
    symbol: 'INSUR',
    url: 'https://www.insurace.io',
    description:
      'InsurAce is a leading decentralised insurance protocol, providing reliable, robust and secure insurance services to DeFi users, allowing them to secure their investment funds against various risks. Being the 1st in the industry to offer cross-chain portfolio-based covers, InsurAce enables users to get unbeatable low premium. Users can also get sustainable investment returns through InsurAce’s investment portal and gain rewards though the mining program. InsurAce has a live product launched on Ethereum in April 2021 and have built a full-spectrum cross-chain insurance product line, covering protocols, CEX and IDO platform running on Ethereum, Solana, BSC, Heco, Polygon, Fantom and more in the future.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/insurace.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'insurace',
    cmcId: '8799',
    category: 'Insurance',
    chains: ['Polygon', 'Avalanche', 'Binance', 'Ethereum'],
    module: 'insurace/index.js',
    twitter: 'InsurAce_io',
    audit_links: [
      'https://files.insurace.io/public/security/InsurAce-DeFi%20Smart%20Contract%20Security%20Audit%20Report.pdf',
      'https://files.insurace.io/public/security/InsurAcePeckShieldAuditReport.pdf',
    ],
    slug: 'insurace',
    tvl: 44989166.47372739,
    chainTvls: {
      Binance: 10943281.862781303,
      Ethereum: 8366817.361142274,
      Polygon: 14024708.904634386,
      Avalanche: 11654358.345169432,
    },
    change_1h: 0.1739264027540628,
    change_1d: 1.3656562803338659,
    change_7d: 1.6965901531071523,
    fdv: 55647388,
    mcap: 27650869,
  },
  {
    id: '1085',
    name: 'ThetaNuts',
    address: null,
    symbol: '-',
    url: 'https://thetanuts.finance',
    description:
      'Thetanuts is a decentralized structured products protocol that helps users gain sustainable yield through various options strategies',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/thetanuts.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Options',
    chains: ['Ethereum', 'Avalanche', 'Fantom', 'Polygon', 'Binance', 'Boba', 'Aurora'],
    module: 'thetanuts/index.js',
    twitter: 'ThetaNuts',
    audit_links: ['https://drive.google.com/file/d/1NJAZ3L8VGezmEw2xpyCl073oplm9O0in/view?usp=sharing'],
    oracles: ['Chainlink'],
    listedAt: 1640274979,
    slug: 'thetanuts',
    tvl: 44962593.594518684,
    chainTvls: {
      Avalanche: 5304557.861507658,
      Binance: 1233222.1351210163,
      Boba: 724300.483891363,
      Ethereum: 33492978.95814178,
      Aurora: 329354.8575456578,
      Polygon: 1591564.5137346154,
      Fantom: 2286614.784576592,
    },
    change_1h: 0.00001443719305882496,
    change_1d: 6.751066962611759,
    change_7d: 22.08269557841207,
  },
  {
    id: '463',
    name: 'Gro',
    address: '0x3ec8798b81485a254928b70cda1cf0a2bb0b74d7',
    symbol: 'GRO',
    url: 'https://www.gro.xyz',
    description:
      'Gro protocol is a stablecoin yield aggregator that tranches risk and yield. The first two products built on it are the PWRD stablecoin with deposit protection and yield, and Vault with leveraged stablecoin yields.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/gro.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gro-dao-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum', 'Avalanche'],
    module: 'groprotocol/index.js',
    twitter: 'groprotocol',
    audit_links: ['https://docs.gro.xyz/gro-docs/how-it-works/security-and-audits/audits'],
    slug: 'gro',
    tvl: 44876490.97960008,
    chainTvls: {
      pool2: 2362289.255523156,
      Avalanche: 2883199.363663766,
      'Ethereum-pool2': 2362289.255523156,
      Ethereum: 41993291.61593632,
      'Ethereum-staking': 1470648.9395589163,
      staking: 1470648.9395589163,
    },
    change_1h: 0.021729525528371596,
    change_1d: 0.7553528680171837,
    change_7d: 0.546812660493103,
    staking: 1470648.9395589163,
    pool2: 2362289.255523156,
    fdv: 186763972,
    mcap: 7205320,
  },
  {
    id: '1530',
    name: 'Excalibur',
    address: 'fantom:0x6e99e0676A90b2a5a722C44109db22220382cc9F',
    symbol: 'EXC',
    url: 'https://excalibur.exchange/',
    description:
      'Excalibur is a highly flexible and ecosystem-oriented Fantom AMM providing fee mining, swap referrals and customizable fees.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/excalibur.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'excalibur',
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    oracles: ['Chainlink'],
    forkedFrom: ['Uniswap'],
    module: 'excalibur/index.js',
    twitter: 'ExcaliburDeFi',
    audit_links: ['https://paladinsec.co/projects/excalibur/'],
    listedAt: 1646949250,
    slug: 'excalibur',
    tvl: 44046601.02687789,
    chainTvls: { Fantom: 44046601.02687789 },
    change_1h: -0.019625433097388623,
    change_1d: -0.08675243788923126,
    change_7d: 5.156006157094396,
    mcap: 0,
  },
  {
    id: '1207',
    name: 'Across',
    address: null,
    symbol: '-',
    url: 'https://across.to',
    description:
      'Across protocol is a novel bridging method that combines an optimistic oracle, bonded relayers and single-sided liquidity pools to provide decentralized instant transactions from rollup chains to Ethereum mainnet.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/across.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Cross Chain',
    chains: ['Ethereum'],
    module: 'across/index.js',
    twitter: 'AcrossProtocol',
    audit_links: ['https://blog.openzeppelin.com/uma-audit-l2-bridges/'],
    oracles: ['UMA'],
    listedAt: 1641519276,
    slug: 'across',
    tvl: 42654615.54616172,
    chainTvls: { Ethereum: 42654615.54616172 },
    change_1h: 0,
    change_1d: 2.3527117399107453,
    change_7d: 9.53195502869302,
  },
  {
    id: '1240',
    name: 'YuzuSwap',
    address: 'emerald:0xf02b3e437304892105992512539F769423a515Cb',
    symbol: 'YUZU',
    url: 'https://yuzu-swap.com',
    description:
      'YuzuSwap is a decentralized exchange on the Oasis Emerald paratime that includes incentives like liquidity & and trade mining',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/yuzuswap.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Oasis'],
    module: 'yuzu.js',
    twitter: 'Yuzu_Swap',
    forkedFrom: ['Uniswap'],
    listedAt: 1641941821,
    audit_links: ['https://app.yuzu-swap.com/PeckShield-Audit-Report-YuzuSwap-v1.0_final.pdf'],
    slug: 'yuzuswap',
    tvl: 42549521.07498286,
    chainTvls: { Oasis: 42549521.07498286 },
    change_1h: -0.05489304263672068,
    change_1d: 0.26172517707728105,
    change_7d: 3.3825638354419993,
  },
  {
    id: '316',
    name: 'SecretSwap',
    address: '0x773258b03c730f84af10dfcb1bfaa7487558b8ac',
    symbol: 'SEFI',
    url: 'https://www.secretswap.io/',
    description:
      'SecretSwap is the first front-running resistant, cross-chain and privacy-first AMM - secured by Secret Network.',
    chain: 'Secret',
    logo: 'https://icons.llama.fi/secretswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'sefi',
    cmcId: '9123',
    category: 'Dexes',
    chains: ['Secret'],
    module: 'secretswap.js',
    twitter: 'SecretNetwork',
    slug: 'secretswap',
    tvl: 41761968.913236655,
    chainTvls: { Secret: 41761968.913236655 },
    change_1h: -0.043695601903365855,
    change_1d: 5.251898519102511,
    change_7d: 8.778558673816946,
    mcap: 14613869,
  },
  {
    id: '1387',
    name: 'DEUS Finance',
    address: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    symbol: 'DEUS',
    url: 'https://deus.finance',
    description:
      'World-first decentralized bilateral OTC derivatives platform. Enabling next-gen DeFi giving users & developers unlimited access to global markets',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/deus-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'deus-finance-2',
    cmcId: '12972',
    category: 'CDP',
    chains: ['Fantom', 'Ethereum', 'Polygon'],
    oracles: [],
    module: 'deusfi/index.js',
    twitter: 'DeusDao',
    language: 'Solidity',
    listedAt: 1644525911,
    slug: 'deus-finance',
    tvl: 41398378.130662,
    chainTvls: {
      pool2: 840030.0022158669,
      'Polygon-pool2': 667088.8536965447,
      'Ethereum-pool2': 169142.7298911753,
      Ethereum: 376954.7188051628,
      Polygon: 79730.0298059606,
      'Fantom-pool2': 3798.418628147004,
      Fantom: 40941693.38205088,
    },
    change_1h: -0.11158841158840005,
    change_1d: 2.3820110892233117,
    change_7d: 31.28714973251263,
    pool2: 840030.0022158669,
    fdv: 229122142,
    mcap: 137822372,
  },
  {
    id: '333',
    name: 'O3 Swap',
    address: '0xee9801669c6138e84bd50deb500827b776777d28',
    symbol: 'O3',
    url: 'https://o3swap.com/',
    description: 'Multi-chain bridge',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/o3-swap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'o3-swap',
    cmcId: '9588',
    category: 'Dexes',
    chains: ['Heco', 'Polygon', 'Binance', 'Arbitrum', 'OKExChain', 'Avalanche', 'Ethereum'],
    module: 'o3swap.js',
    twitter: 'O3_Labs',
    audit_links: ['https://certik-public-assets.s3.amazonaws.com/CertiK+Security+Assessment+for+O3+Swap.pdf'],
    slug: 'o3-swap',
    tvl: 40986841.06,
    chainTvls: {
      OKExChain: 3476229.68,
      Avalanche: 3392084.75,
      Binance: 5534903.5,
      Ethereum: 265295.4,
      Heco: 16167525.47,
      Polygon: 8188981.59,
      Arbitrum: 3959426.06,
    },
    change_1h: -0.09794843432716505,
    change_1d: 0.7884145097040829,
    change_7d: 2.0140103051255807,
    fdv: 37869501,
    mcap: 13529141,
  },
  {
    id: '1025',
    name: 'Wrapped',
    address: null,
    symbol: '-',
    url: 'https://wrapped.com',
    description:
      'Your favorite assets on every blockchain. wrapped.com is a cross-chain institutional bridge. Designed for high-performance and speed.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wrapped.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Cross Chain',
    chains: ['Ethereum', 'Celo'],
    module: 'wrappedfi.js',
    twitter: 'WrappedFi',
    listedAt: 1639522646,
    slug: 'wrapped',
    tvl: 40552663.459343225,
    chainTvls: { Ethereum: 31440692.23985962, Celo: 9111971.219483607 },
    change_1h: 0.2773112889986322,
    change_1d: 5.236750237928618,
    change_7d: 14.444008911922637,
  },
  {
    id: '897',
    name: 'Donkey',
    address: '0x4576E6825B462b6916D2a41E187626E9090A92c6',
    symbol: 'DON',
    url: 'https://www.donkey.fund',
    description:
      'Money Market Protocol and the Oracle for Korea’s Digital Asset Market(detailed in https://docs.donkey.fund/about/whitepaper/english-whitepaper)',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/donkey.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Klaytn'],
    module: 'donkey/index.js',
    twitter: null,
    oracles: ['Internal'],
    forkedFrom: ['Compound'],
    audit_links: ['https://docs.donkey.fund/about/audit'],
    listedAt: 1637687404,
    slug: 'donkey',
    tvl: 40086400.289193876,
    chainTvls: { Klaytn: 40086400.289193876 },
    change_1h: 0.15401548747300353,
    change_1d: 1.9294605021804188,
    change_7d: null,
  },
  {
    id: '602',
    name: 'BakerySwap',
    address: 'bsc:0xe02df9e3e622debdd69fb838bb799e3f168902c5',
    symbol: 'BAKE',
    url: 'https://www.bakeryswap.org',
    description: 'BakerySwap is an automated market maker and the first curated NFT marketplace on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bakeryswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bakerytoken',
    cmcId: '7064',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'bakeryswap/index.js',
    twitter: 'bakery_swap',
    audit_links: ['https://www.certik.org/projects/bakeryswap'],
    forkedFrom: ['Uniswap'],
    oracles: [],
    slug: 'bakeryswap',
    tvl: 39988600.33779826,
    chainTvls: { Binance: 39988600.33779826, 'Binance-staking': 25750328.241275962, staking: 25750328.241275962 },
    change_1h: 0.6098625548786885,
    change_1d: 3.0820141267916057,
    change_7d: 12.293758253727788,
    staking: 25750328.241275962,
    fdv: 204493634,
    mcap: 141963931,
  },
  {
    id: '163',
    name: 'ForTube',
    address: '0x1fcdce58959f536621d76f5b7ffb955baa5a672f',
    symbol: 'FOR',
    url: 'https://for.tube/',
    description:
      'Based on The Force Protocol, ForTube is committed to providing decentralized lending services for cryptoasset enthusiasts around the world.\r\n',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/fortube.jpg',
    audits: '4',
    audit_note: null,
    gecko_id: 'force-protocol',
    cmcId: '4118',
    category: 'Lending',
    chains: ['Binance', 'IoTeX', 'Ethereum', 'Polygon', 'OKExChain'],
    module: 'fortube/index.js',
    twitter: 'ForTubeFi',
    audit_links: ['https://for.tube/security'],
    slug: 'fortube',
    tvl: 39632750.82146014,
    chainTvls: {
      Binance: 37121500.487453334,
      IoTeX: 1656305.4796497049,
      Ethereum: 430417.0187056561,
      OKExChain: 2703.423615911382,
      Polygon: 421824.41203553305,
    },
    change_1h: 0.7219778808051274,
    change_1d: 0.7081025378864183,
    change_7d: 5.241016727605995,
    fdv: 48037154,
    mcap: 27079410,
  },
  {
    id: '753',
    name: 'RealT',
    address: null,
    symbol: '-',
    url: 'https://realt.co',
    description:
      'RealToken provides investors with a simple, intelligent, and user-friendly method to buy into fractional, tokenized properties, leveraging the U.S. legal system and the permissionless, unrestricted token issuance of Ethereum.',
    chain: 'xDai',
    logo: 'https://icons.llama.fi/realt.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['xDai'],
    module: 'realt.js',
    twitter: 'RealTPlatform',
    listedAt: 1635878880,
    slug: 'realt',
    tvl: 39252268.066,
    chainTvls: { xDai: 39252268.066 },
    change_1h: 0,
    change_1d: -0.19842242663723653,
    change_7d: 1.9581166906988017,
  },
  {
    id: '1551',
    name: 'Kujira',
    address: 'terra:terra1xfsdgcemqwxp4hhnyk4rle6wr22sseq7j07dnn',
    symbol: 'KUJI',
    url: 'https://kujira.app',
    description:
      'An economical, easy to use platform to bid on discounted, at-risk collateral on Terra with the click of a button.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/kujira.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Terra'],
    oracles: [],
    forkedFrom: [],
    module: 'kijura/index.js',
    twitter: 'TeamKujira',
    audit_links: [
      'https://github.com/SCV-Security/PublicReports/blob/main/CW/Kujira/OrcaQueue/Kujira%20-%20Orca%20Queue%20-%20Audit%20Report%201.0.pdf',
    ],
    openSource: false,
    listedAt: 1647396317,
    slug: 'kujira',
    tvl: 39145852.95659592,
    chainTvls: {
      'Terra-staking': 15291224.222402882,
      pool2: 5722631.95862091,
      Terra: 39145852.95659592,
      'Terra-pool2': 5722631.95862091,
      staking: 15291224.222402882,
    },
    change_1h: 0.03236669689525229,
    change_1d: -0.25477530706771745,
    change_7d: 7.349562944103781,
    staking: 15291224.222402882,
    pool2: 5722631.95862091,
  },
  {
    id: '1274',
    name: 'StellaSwap',
    address: 'moonbeam:0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2',
    symbol: 'STELLA',
    url: 'https://stellaswap.com',
    description: 'Stellaswap is leading DEX on Moonbeam.',
    chain: 'Moonbeam',
    logo: 'https://icons.llama.fi/stellaswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonbeam'],
    module: 'stellaswap.js',
    twitter: 'StellaSwap',
    audit_links: ['https://www.certik.com/projects/stellaswap'],
    forkedFrom: ['Uniswap'],
    listedAt: 1642405794,
    slug: 'stellaswap',
    tvl: 39083041.47915964,
    chainTvls: { Moonbeam: 39083041.47915964 },
    change_1h: -1.5891708129811377,
    change_1d: -1.903028530954046,
    change_7d: 11.952937831274,
  },
  {
    id: '807',
    name: 'Blizz Finance',
    address: 'avax:0x0f34919404a290e71fc6a510cb4a6acb8d764b24',
    symbol: 'BLZZ',
    url: 'https://blizz.finance',
    description: 'Blizz Finance is a decentralized, non-custodial liquidity market protocol operating on Avalanche.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/blizz-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'blizz-finance',
    cmcId: null,
    category: 'Lending',
    chains: ['Avalanche'],
    module: 'blizzfinance/index.js',
    twitter: 'BlizzFinance',
    oracles: ['Chainlink'],
    forkedFrom: ['Aave'],
    listedAt: 1636390733,
    slug: 'blizz-finance',
    tvl: 39060607.8364463,
    chainTvls: { Avalanche: 39060607.8364463, borrowed: 28493625.043883637, 'Avalanche-borrowed': 28493625.043883637 },
    change_1h: -2.842170943040401e-14,
    change_1d: 1.669338027800535,
    change_7d: -40.359354681699045,
    fdv: 21393705,
    mcap: 6082757,
  },
  {
    id: '546',
    name: 'Omm',
    address: 'icon:cx1a29259a59f463a67bb2ef84398b30ca56b5830a',
    symbol: 'OMM',
    url: 'https://omm.finance',
    description:
      'Omm is the money market made for everyone. Supply assets to earn interest, borrow assets for short-term loans, and earn OMM for doing so.',
    chain: 'Icon',
    logo: 'https://icons.llama.fi/omm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'omm-tokens',
    cmcId: '11548',
    category: 'Lending',
    chains: ['Icon'],
    module: 'omm/index.js',
    twitter: 'ommfinance',
    audit_links: ['https://docs.omm.finance/security'],
    oracles: ['Band'],
    slug: 'omm',
    tvl: 38687337.25,
    chainTvls: { Icon: 38687337.25 },
    change_1h: 0.05356089106986417,
    change_1d: 1.0450261738107542,
    change_7d: 23.033118464306582,
    mcap: 0,
  },
  {
    id: '157',
    name: '88mph',
    address: '0x8888801af4d980682e47f1a9036e589479e835c5',
    symbol: 'MPH',
    url: 'https://88mph.app/',
    description: 'Earn fixed-rate interest on your crypto.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/88mph.png',
    audits: '2',
    audit_note: null,
    gecko_id: '88mph',
    cmcId: '7742',
    category: 'Lending',
    chains: ['Ethereum', 'Fantom', 'Polygon', 'Avalanche'],
    module: '88mph/index.js',
    twitter: '88mphapp',
    audit_links: ['https://docs.88mph.app/main-products/security-and-audits#is-88mph-audited'],
    oracles: ['Chainlink'],
    slug: '88mph',
    tvl: 38370041.44931326,
    chainTvls: {
      Avalanche: 392.06985746912204,
      Ethereum: 27465632.324356463,
      Polygon: 4692.740195819868,
      'Ethereum-staking': 3248231.1994483946,
      staking: 3248231.1994483946,
      Fantom: 10899324.31490351,
    },
    change_1h: 0.43896056958242013,
    change_1d: 1.8698977484444868,
    change_7d: 1.116215239310975,
    staking: 3248231.1994483946,
    fdv: 45049252,
    mcap: 27690603,
  },
  {
    id: '1241',
    name: 'Mimo',
    address: null,
    symbol: '-',
    url: 'https://mimo.exchange',
    description:
      "mimo is a decentralized liquidity protocol that will fuel the next wave of decentralized finance (DeFi) on IoTeX. mimo’s vision is to empower next-gen DeFi products that utilize our state-of-the-art automated liquidity protocol and the IoTeX's lightning-fast speed, low gas fees, and cross-chain capabilities.",
    chain: 'IoTeX',
    logo: 'https://icons.llama.fi/mimo.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['IoTeX'],
    module: 'mimoswap/index.js',
    twitter: 'mimoprotocol',
    forkedFrom: ['Uniswap'],
    listedAt: 1641944808,
    slug: 'mimo',
    tvl: 38302794.8119512,
    chainTvls: { IoTeX: 38302794.8119512 },
    change_1h: -0.0077927508211388385,
    change_1d: 0.705951082049495,
    change_7d: 0.44184102832657857,
  },
  {
    id: '618',
    name: 'Kava Swap',
    address: '-',
    symbol: 'SWP',
    url: 'https://www.kava.io/swap',
    description:
      "Kava Swap is a cross-chain Autonomous Market Making (AMM) application designed to provide an efficient and safe way to swap natively between the world's largest cryptocurrency assets",
    chain: 'Kava',
    logo: 'https://icons.llama.fi/kava-swap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'kava-swap',
    cmcId: '11562',
    category: 'Dexes',
    chains: ['Kava'],
    module: 'kavaswap.js',
    twitter: 'Kava_Swap',
    slug: 'kava-swap',
    tvl: 37291424.06200453,
    chainTvls: { Kava: 37291424.06200453 },
    change_1h: -0.4557721877982317,
    change_1d: 2.2912043819578685,
    change_7d: 2.7927128980176548,
    mcap: 113642795,
  },
  {
    id: '1258',
    name: 'FLRFarm',
    address: 'songbird:0x0D94e59332732D18CF3a3D457A8886A2AE29eA1B',
    symbol: 'SFIN',
    url: 'https://xff.flr.finance',
    description:
      "FlareFarm is the ecosystem's yield farming and fair launch distribution platform (launchpad) for new projects entering the Flare Network and/or Flare Finance Ecosystem.",
    chain: 'Songbird',
    logo: 'https://icons.llama.fi/flarefarm.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Launchpad',
    chains: ['Songbird'],
    module: 'flarefarm/index.js',
    twitter: 'flrfinance',
    listedAt: 1642143222,
    slug: 'flrfarm',
    tvl: 37186456.45312682,
    chainTvls: { Songbird: 37186456.45312682, 'Songbird-staking': 2597064.2907739016, staking: 2597064.2907739016 },
    change_1h: -0.0749246286937364,
    change_1d: -0.8085591920641662,
    change_7d: 13.246109287383632,
    staking: 2597064.2907739016,
  },
  {
    id: '520',
    name: 'Youves',
    address: 'tezos:KT1Xobej4mc6XgEjDoJoHtTKgbD1ELMvcQuL',
    symbol: 'YOU',
    url: 'https://app.youves.com',
    description:
      'Youves is a decentralised, non-custodial and self-governed platform for the creation and management of synthetic assets. Minters of synthetic assets earn a passive income in YOU, the governance token on Youves.',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/youves.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Synthetics',
    chains: ['Tezos'],
    module: 'youves/index.js',
    twitter: 'youves_com',
    audit_links: [
      'https://github.com/youves-com/youves-smart-contract/blob/main/audits/09212021-LeastAuthority-TezosFoundationSyntheticAssetPlatformSmartContractsFinalAuditReport.pdf',
    ],
    oracles: ['Ubinetic'],
    slug: 'youves',
    tvl: 36541530.96469062,
    chainTvls: { Tezos: 36541530.96469062 },
    change_1h: 0.15832675848398026,
    change_1d: 3.6703900142433667,
    change_7d: 4.031051525299418,
  },
  {
    id: '161',
    name: 'BoringDAO',
    address: '0xbc19712feb3a26080ebf6f2f7849b417fdd792ca',
    symbol: 'BORING',
    url: 'https://www.boringdao.com/',
    description:
      'BoringDAO is a decentralized bridge that connects all blockchain assets, we offer users a safe way to maximize their utilization rate of crypto assets.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/boringdao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'boringdao',
    cmcId: '7509',
    category: 'Cross Chain',
    chains: ['Ethereum'],
    module: 'boringdao.js',
    twitter: 'TheBoringDAO',
    audit_links: [
      'https://github.com/BoringDAO/boringDAO-contract/blob/master/peckshield-audit-report-boringdao-v1.0rc.pdf',
    ],
    oracles: ['Chainlink'],
    slug: 'boringdao',
    tvl: 36176929.18,
    chainTvls: { Ethereum: 36176929.18 },
    change_1h: -0.06657713737438087,
    change_1d: 1.225993109584067,
    change_7d: 9.12282430057465,
    fdv: 80200186,
    mcap: 39274404,
  },
  {
    id: '589',
    name: 'Strike',
    address: '0x74232704659ef37c08995e386a2e26cc27a8d7b1',
    symbol: 'STRK',
    url: 'https://strike.org',
    description:
      'The Strike App enables users access to a fully decentralized money market powered on Ethereum 24/7/365 with a user-interface, api, or smart contracts.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/strike.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'strike',
    cmcId: '8911',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'strike/index.js',
    twitter: 'StrikeFinance',
    audit_links: ['https://www.certik.org/projects/strikefinance'],
    forkedFrom: ['Compound'],
    slug: 'strike',
    tvl: 36170003.43192954,
    chainTvls: { Ethereum: 36170003.43192954, 'Ethereum-borrowed': 33589544.76376962, borrowed: 33589544.76376962 },
    change_1h: 0.4483142216656262,
    change_1d: 2.2773259779526,
    change_7d: 10.928348101439525,
    fdv: 286509667,
    mcap: 137582652,
  },
  {
    id: '506',
    name: 'Tetu',
    address: 'polygon:0x255707b70bf90aa112006e1b07b9aea6de021424',
    symbol: 'TETU',
    url: 'http://tetu.io',
    description:
      'Tetu is a decentralized organization committed to providing a next generation yield aggregator to DeFi investors. The Tetu core team has deep industry knowledge building back-end international banking systems and development with leading global payment processing infrastructure.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/tetu.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'tetu',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom', 'Polygon'],
    module: 'tetu/index.js',
    twitter: 'tetu_io',
    audit_links: ['https://docs.tetu.io/tetu-io/security/audits'],
    oracles: ['Internal'],
    forkedFrom: ['Harvest Finance'],
    slug: 'tetu',
    tvl: 36011789.44855145,
    chainTvls: { Polygon: 12006741.384215532, Fantom: 24005048.06433592 },
    change_1h: -0.07951530362636561,
    change_1d: 0.19039963163784535,
    change_7d: 6.305374629305831,
    fdv: 19664505,
    mcap: 4141446,
  },
  {
    id: '244',
    name: 'WePiggy',
    address: '0x6f620ec89b8479e97a6985792d0c64f237566746',
    symbol: 'WPC',
    url: 'https://www.wepiggy.com/',
    description: 'WePiggy is an open source, non-custodial crypto asset lending market protocol.\r\n',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wepiggy.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'wepiggy-coin',
    cmcId: '13479',
    category: 'Lending',
    chains: [
      'Ethereum',
      'OKExChain',
      'Binance',
      'Arbitrum',
      'Optimism',
      'Polygon',
      'Oasis',
      'Harmony',
      'Heco',
      'Aurora',
      'Moonriver',
    ],
    module: 'wepiggy.js',
    twitter: 'wepiggydotcom',
    audit_links: ['https://github.com/WePiggy/wepiggy-contracts/tree/master/docs/audits'],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'wepiggy',
    tvl: 35971584.99163941,
    chainTvls: {
      Optimism: 1615266.284175168,
      'Oasis-borrowed': 50842.32783208925,
      Moonriver: 85915.95273395699,
      Harmony: 241429.51464745658,
      'Harmony-borrowed': 76748.26844991252,
      Binance: 4747074.859782349,
      Ethereum: 18058482.022808574,
      Oasis: 406119.4847859857,
      Heco: 177309.46693172675,
      'Ethereum-borrowed': 15509543.648447696,
      Arbitrum: 4555928.916831124,
      borrowed: 25658848.88175969,
      'Heco-borrowed': 36612.24375775621,
      OKExChain: 5365890.442246823,
      'Arbitrum-borrowed': 3598197.3254565247,
      'Polygon-borrowed': 153161.6224025482,
      'Binance-borrowed': 1590508.2447562127,
      'Moonriver-borrowed': 46615.4593674719,
      'Aurora-borrowed': 153356.14663430062,
      Aurora: 125159.01650146494,
      'Optimism-borrowed': 399494.7999681169,
      Polygon: 593009.0301947787,
      'OKExChain-borrowed': 4043768.7946870634,
    },
    change_1h: 0.04915535901517387,
    change_1d: 1.4842764588959767,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '1448',
    name: 'White Whale',
    address: 'terra:terra1php5m8a6qd68z02t3zpw4jv2pj4vgw4wz0t8mz',
    symbol: 'WHALE',
    url: 'https://whitewhale.money',
    description:
      'Empowering retail investors to keep the UST peg via L1 seigniorage arbitrage as well as participate in other complex automated trading strategies through a simple user-friendly experience.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/white-whale.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'white-whale',
    cmcId: '16121',
    category: 'Yield',
    chains: ['Terra'],
    oracles: [],
    forkedFrom: [],
    module: 'whitewhale/index.js',
    twitter: 'WhiteWhaleTerra',
    audit_links: [
      'https://github.com/oak-security/audit-reports/blob/master/WhiteWhale/Audit%20Report%20-%20White%20Whale.pdf',
      'https://www.certik.com/projects/white-whale',
    ],
    openSource: true,
    listedAt: 1645555485,
    slug: 'white-whale',
    tvl: 35418266.48383087,
    chainTvls: { 'Terra-staking': 22107525.874892037, Terra: 35418266.48383087, staking: 22107525.874892037 },
    change_1h: -0.009620389768855375,
    change_1d: -0.6967213515285664,
    change_7d: -11.339961783836799,
    staking: 22107525.874892037,
    fdv: 115571557,
    mcap: 0,
  },
  {
    id: '318',
    name: 'Dfyn Network',
    address: 'polygon:0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    symbol: 'DFYN',
    url: 'https://www.dfyn.network/',
    description: 'AMM',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dfyn-network.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'dfyn-network',
    cmcId: '9511',
    category: 'Dexes',
    chains: ['Polygon', 'Fantom', 'OKExChain'],
    module: 'dfyn/index.js',
    twitter: '_Dfyn',
    oracles: ['Chainlink', 'DIA'],
    forkedFrom: ['Uniswap'],
    slug: 'dfyn-network',
    tvl: 35255471.28614324,
    chainTvls: { OKExChain: 75.46968524682049, Polygon: 35198231.83549774, Fantom: 57163.980960256704 },
    change_1h: 0.822562752514159,
    change_1d: 4.293501617603312,
    change_7d: -3.5569151921195186,
    mcap: 10475573,
  },
  {
    id: '961',
    name: 'Fantohm',
    address: '0xfa1fbb8ef55a4855e5688c0ee13ac3f202486286',
    symbol: 'FHM',
    url: 'https://www.fantohm.com',
    description: 'Community-owned DeFi infrastructure for more stability in the world.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/fantohm.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'fantohm',
    cmcId: '14063',
    category: 'Reserve Currency',
    chains: ['Fantom', 'Ethereum', 'Moonriver', 'Binance', 'Boba'],
    module: 'fantOHM/index.js',
    twitter: 'FantohmDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638463469,
    slug: 'fantohm',
    tvl: 34241511.142223775,
    chainTvls: {
      Moonriver: 1967288.1952973395,
      'Fantom-staking': 16711393.577222547,
      'Moonriver-staking': 1586506.6303158181,
      Binance: 155553.41187317093,
      Boba: 79025.3636793042,
      Ethereum: 2719394.5274137906,
      Fantom: 29320249.643960174,
      staking: 18297900.207538366,
    },
    change_1h: -0.013492462306487596,
    change_1d: 5.528309082953697,
    change_7d: 6.371960091709724,
    staking: 18297900.207538366,
    fdv: 26218580,
    mcap: 19229615,
  },
  {
    id: '343',
    name: 'Impermax Finance',
    address: '0x7b35ce522cb72e4077baeb96cb923a5529764a00',
    symbol: 'IMX',
    url: 'https://impermax.finance/',
    description: 'Impermax is a DeFi ecosystem that enables liquidity providers to leverage their LP tokens',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/impermax-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'impermax',
    cmcId: '9532',
    category: 'Yield',
    chains: ['Polygon', 'Avalanche', 'Arbitrum', 'Fantom', 'Ethereum', 'Moonriver'],
    module: 'impermax/index.js',
    twitter: 'ImpermaxFinance',
    audit_links: ['https://github.com/Impermax-Finance/impermax-x-uniswapv2-core/tree/main/audit'],
    oracles: ['Chainlink'],
    slug: 'impermax-finance',
    tvl: 33832974.881611854,
    chainTvls: {
      Moonriver: 619832.2269465384,
      Ethereum: 1940081.443469015,
      Avalanche: 7806923.157387674,
      'Ethereum-staking': 1585183.0344738287,
      Polygon: 13691866.193639666,
      Arbitrum: 6322321.809756403,
      Fantom: 3451950.050412556,
      staking: 1585183.0344738287,
    },
    change_1h: 0.3432522905680031,
    change_1d: -0.6718904828973677,
    change_7d: -12.078260296730676,
    staking: 1585183.0344738287,
    mcap: 5245915,
  },
  {
    id: '952',
    name: 'FlatQube',
    address: null,
    symbol: '-',
    url: 'https://tonswap.io/swap',
    description: 'Allows peer-to-peer crypto transactions online securely and without an intermediary.',
    chain: 'Everscale',
    logo: 'https://icons.llama.fi/flatcube.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Everscale'],
    module: 'tonswap/index.js',
    twitter: 'FlatQube',
    audit_links: ['https://github.com/broxus/ton-dex/tree/master/audits'],
    listedAt: 1638389104,
    slug: 'flatqube',
    tvl: 33675743.41814289,
    chainTvls: { Everscale: 33675743.41814289 },
    change_1h: 0.07771909163190571,
    change_1d: 0.11192203772208131,
    change_7d: 3.9483294066356507,
  },
  {
    id: '631',
    name: 'Beanstalk',
    address: '0xdc59ac4fefa32293a95889dc396682858d52e5db',
    symbol: 'BEAN',
    url: 'https://bean.money',
    description:
      'Beanstalk is a decentralized credit based algorithmic stablecoin protocol that is built on Ethereum. Beanstalk uses credit instead of collateral to create price stability relative to a non-blockchain-native asset.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/beanstalk.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'bean',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'bean/index.js',
    twitter: 'BeanstalkFarms',
    oracles: ['TWAP'],
    slug: 'beanstalk',
    tvl: 33664886.530744925,
    chainTvls: { Ethereum: 33664886.530744925 },
    change_1h: 0.70782521829247,
    change_1d: 0.4742788002821925,
    change_7d: -1.2946549515246062,
    mcap: 46559592,
  },
  {
    id: '972',
    name: 'StellarX',
    address: null,
    symbol: '-',
    url: 'https://www.stellarx.com',
    description:
      'We’re fast. We’re free. And we’re the first decentralized crypto platform with global fiat gateways. You can trade bitcoin for Euros for Chinese Yuan on StellarX. That’s not possible anywhere else.',
    chain: 'Stellar',
    logo: 'https://icons.llama.fi/stellarx.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Stellar'],
    module: 'stellarx/index.js',
    twitter: 'stellarxhq',
    listedAt: 1638583658,
    slug: 'stellarx',
    tvl: 33146290.909575,
    chainTvls: { Stellar: 33146290.909575 },
    change_1h: 0,
    change_1d: -0.04479073104135978,
    change_7d: 6.188197712155713,
  },
  {
    id: '1501',
    name: 'Octus Bridge',
    address: null,
    symbol: '-',
    url: 'https://octusbridge.io/bridge',
    description: 'Cross-chain transfers',
    chain: 'Everscale',
    logo: 'https://icons.llama.fi/octus-bridge.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Everscale'],
    oracles: [],
    forkedFrom: [],
    module: 'tonbridge.js',
    twitter: 'OctusBridge',
    audit_links: ['https://everscale.nil.foundation/report.pdf'],
    listedAt: 1646333180,
    slug: 'octus-bridge',
    tvl: 32945597.191257607,
    chainTvls: { Everscale: 32945597.191257607 },
    change_1h: -0.04688995859224576,
    change_1d: 0.268613573697678,
    change_7d: 0.5094315625028543,
  },
  {
    id: '213',
    name: 'OnX Finance',
    address: '0xE0aD1806Fd3E7edF6FF52Fdb822432e847411033',
    symbol: 'ONX',
    url: 'https://onx.finance',
    description:
      'OnX Finance is a yield aggregating platform specifically designed for yield generating tokens such as liquid staking, LP tokens, and vault tokens to unlock new yield opportunities combining vaults and lending.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/onx-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'onx-finance',
    cmcId: '8071',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Fantom', 'Polygon', 'Avalanche'],
    module: 'onx.js',
    twitter: 'OnXFinance',
    audit_links: ['https://onx-finance.gitbook.io/docs/audits-and-contracts'],
    slug: 'onx-finance',
    tvl: 32565094.908324774,
    chainTvls: {
      pool2: 565941.280368108,
      Avalanche: 852.094346118,
      'Ethereum-pool2': 565941.280368108,
      Ethereum: 32526048.446223672,
      'Ethereum-staking': 534735.988952298,
      Polygon: 2679.789488214,
      'Ethereum-borrowed': 2922618.480507372,
      borrowed: 2922618.480507372,
      Fantom: 35514.578266770004,
      staking: 534735.988952298,
    },
    change_1h: 1.6083286044547833,
    change_1d: 22.486348873206524,
    change_7d: 37.02107398059792,
    staking: 534735.988952298,
    pool2: 565941.280368108,
    fdv: 2866874,
    mcap: 2859640,
  },
  {
    id: '1466',
    name: 'ALEX',
    address: 'stacks:-',
    symbol: 'ALEX',
    url: 'https://www.alexgo.io',
    description: 'Next Gen DeFi on Bitcoin via Stacks. Building Financial Infrastructure on Bitcoin through Stacks',
    chain: 'Stacks',
    logo: 'https://icons.llama.fi/alex.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Stacks'],
    oracles: [],
    forkedFrom: [],
    module: 'alexlab/index.js',
    twitter: 'alexgoBtc',
    audit_links: ['https://www.alexgo.io/#Security'],
    language: 'Clarity',
    listedAt: 1645731214,
    slug: 'alex',
    tvl: 31936405.40062065,
    chainTvls: { Stacks: 31936405.40062065 },
    change_1h: 1.5125516122788127,
    change_1d: 2.3819778091738613,
    change_7d: 16.722606164801277,
  },
  {
    id: '186',
    name: 'WanSwap Dex',
    address: 'wan:0x8b9f9f4aa70b1b0d586be8adfb19c1ac38e05e9a',
    symbol: 'WASP',
    url: 'https://wanswap.finance/',
    description:
      'WanSwap is an innovative crosschain automatic market making (AMM) decentralized exchange (DEX) based on Wanchain.',
    chain: 'Wanchain',
    logo: 'https://icons.llama.fi/wanswap-dex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wanswap',
    cmcId: '1267',
    category: 'Dexes',
    chains: ['Wanchain'],
    module: 'wanswap.js',
    twitter: 'wanswap',
    audit_links: ['https://docs.wanswap.finance/audit'],
    slug: 'wanswap-dex',
    tvl: 31325998.1,
    chainTvls: { Wanchain: 31325998.1 },
    change_1h: 0.02594687779759397,
    change_1d: 1.401244939601412,
    change_7d: 5.271966463754339,
    mcap: 3389242,
  },
  {
    id: '368',
    name: 'Channels Finance',
    address: 'bsc:0xdE9a73272BC2F28189CE3c243e36FaFDA2485212',
    symbol: 'CAN',
    url: 'https://channels.finance',
    description:
      "Channels.finance is the first fully decentralized lending platform on Heco, focusing on user asset security. The first release supports LP mortgage lending and LP vault pool. It currently supports 15 mainstream currencies and 6 (Mdex) LP asset mortgage lending . The platform's token CAN has also been listed on Huobi Exchange on April 21. As a leading lending product on Heco, Channels has always performed well in asset security, user experience and mining revenue, and valued product experience and user interests.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/channels-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'channels',
    cmcId: '8537',
    category: 'Lending',
    chains: ['Heco', 'Binance', 'Arbitrum'],
    module: 'channels/index.js',
    twitter: 'ChannelsFinance',
    forkedFrom: ['Compound'],
    audit_links: [
      'https://attest.im/attestation/searchResult?qurey=4c533d00766c4b2a9761fad5da78b56f',
      'https://firebasestorage.googleapis.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-MSgvIS2RfKE66pfkI81%2F-MUv-RfymyB6PvCRlnwz%2F-MUv1a71AjJ6p3Bgz5ui%2FChannels-Audit-Report-030321(1).pdf?alt=media&token=1af91ea6-d3d0-4ba3-8784-5dce6a907930',
    ],
    slug: 'channels-finance',
    tvl: 31314324.47767592,
    chainTvls: {
      'Arbitrum-borrowed': 13270.766924528683,
      'Binance-borrowed': 305721.28126299847,
      Binance: 1501038.5182072343,
      Heco: 29734247.3984209,
      Arbitrum: 79038.56104778498,
      borrowed: 6487645.488918574,
      'Heco-borrowed': 6168653.440731047,
    },
    change_1h: -0.5631685458427569,
    change_1d: -1.9060723511622797,
    change_7d: -2.25477095483096,
    fdv: 1734164,
    mcap: 785997,
  },
  {
    id: '1191',
    name: 'Katana',
    address: null,
    symbol: '-',
    url: 'https://app.katana.so',
    description:
      'Katana is a yield generation protocol on Solana designed to generate sustainable yield from automated option strategies.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/katana.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Options',
    chains: ['Solana'],
    module: 'katana/index.js',
    twitter: 'Katana_HQ',
    oracles: ['Pyth'],
    listedAt: 1641359167,
    openSource: false,
    slug: 'katana',
    tvl: 31224984.319051802,
    chainTvls: { Solana: 31224984.319051802 },
    change_1h: 0.23867637200636693,
    change_1d: 3.3141532439313153,
    change_7d: 21.824528073206892,
  },
  {
    id: '1563',
    name: 'iZUMi Finance',
    address: '0x9ad37205d608B8b219e6a2573f922094CEc5c200',
    symbol: 'IZI',
    url: 'https://izumi.finance',
    description:
      'iZUMi Finance LiquidBox is a platform providing programmable Liquidity As A Service (LaaS) on the top of UniswapV3. It allows liquidity providers earn additional liquidity mining rewards as well as trading fees on UniswapV3. On the other side, it will help protocols attract liquidity in an efficient and lasting way.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/izumi-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'izumi-finance',
    cmcId: '16305',
    category: 'Yield',
    chains: ['Arbitrum', 'Ethereum', 'Polygon'],
    oracles: ['TWAP'],
    forkedFrom: [],
    module: 'izumi/index.js',
    twitter: 'izumi_Finance',
    audit_links: ['https://docsend.com/view/ura947u6ck3urpqb', 'https://docsend.com/view/2bif3vfinpv657mh'],
    listedAt: 1647667685,
    slug: 'izumi-finance',
    tvl: 30999808.95,
    chainTvls: { Ethereum: 12618426.67, Polygon: 2731526.57, pool2: 1413224.65, Arbitrum: 15649855.71 },
    change_1h: -0.00013622646042676934,
    change_1d: 74.0145479977659,
    change_7d: 88.69847339121105,
    pool2: 1413224.65,
    fdv: 144469485,
    mcap: 0,
  },
  {
    id: '1477',
    name: 'Edge Protocol',
    address: null,
    symbol: '-',
    url: 'https://www.edgeprotocol.io',
    description: 'Edge Protocol is a money-market-as-a-service (MMaaS) provider on Terra ecosystem.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/edge-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Terra'],
    oracles: [],
    forkedFrom: [],
    module: 'edgeprotocol.js',
    twitter: 'EdgeProtocol',
    audit_links: ['https://docs.edgeprotocol.io/overview/extras/security'],
    openSource: false,
    listedAt: 1645900975,
    slug: 'edge-protocol',
    tvl: 30448704.2628319,
    chainTvls: { 'Terra-borrowed': 20311924.783629954, Terra: 30448704.2628319, borrowed: 20311924.783629954 },
    change_1h: -0.28626470354743105,
    change_1d: 6.1802589816450535,
    change_7d: 14.58269864354935,
  },
  {
    id: '622',
    name: 'Clipper',
    address: '-',
    symbol: '-',
    url: 'https://clipper.exchange',
    description:
      'Clipper is the decentralized exchange (DEX) built to give the self-made crypto trader the best possible prices on small trades (< $10k)',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/clipper.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum', 'Optimism', 'Polygon', 'Moonbeam'],
    module: 'clipper/index.js',
    twitter: 'Clipper_DEX',
    audit_links: ['https://docs.clipper.exchange/audits'],
    slug: 'clipper',
    tvl: 30316912.00837242,
    chainTvls: {
      Optimism: 2543408.5965162693,
      Ethereum: 23826508.146653082,
      Polygon: 2364544.2673782096,
      Moonbeam: 1582450.9978248554,
    },
    change_1h: 0.09213225242716305,
    change_1d: 1.533983971287725,
    change_7d: 25.026338342578242,
  },
  {
    id: '1306',
    name: 'ProtoFi',
    address: 'fantom:0xa23c4e69e5Eaf4500F2f9301717f12B578b948FB',
    symbol: 'PROTO',
    url: 'https://fantombank.protofi.app/',
    description: 'The First AMM on Fantom in which you are the owner of the Protocol',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/protofi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'protofi/index.js',
    twitter: 'ProtoFiProtocol',
    audit_links: ['https://www.certik.com/projects/protofi'],
    listedAt: 1642781169,
    forkedFrom: ['Uniswap'],
    slug: 'protofi',
    tvl: 30251245.791424435,
    chainTvls: { Fantom: 30251245.791424435 },
    change_1h: 0.00017035118740693633,
    change_1d: 0.8728860941757688,
    change_7d: -6.773630265054138,
  },
  {
    id: '380',
    name: 'LendHub',
    address: 'heco:0x8f67854497218043e1f72908ffe38d0ed7f24721',
    symbol: 'LHB',
    url: 'https://www.lendhub.org',
    description:
      'LendHub is a decentralized lending platform based on the Huobi Ecological Chain. It supports pledged lending of multiple currencies on the Heco chain. Users can obtain incentive token LHB rewards by borrowing on LendHub. LHB will serve as the hub of the LendHub platform, and realize the mutual accommodation and value transfer between Huobi ecological assets through pledged lending that supports the mutual accommodation of assets on the Huobi ecological chain.',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/lendhub.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'lendhub',
    cmcId: '8427',
    category: 'Lending',
    chains: ['Heco'],
    module: 'lendhub/index.js',
    twitter: 'LendHubDefi',
    audit_links: [
      'https://www.lendhub.org/report/LendHubAuditReport.pdf',
      'https://www.lendhub.org/report/LendHubreport-020821.pdf',
      'https://www.lendhub.org/report/LendHubAuditReport2.pdf',
    ],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'lendhub',
    tvl: 29896752.445053138,
    chainTvls: { Heco: 29896752.445053138, borrowed: 4774844.344792889, 'Heco-borrowed': 4774844.344792889 },
    change_1h: -0.20114018077089213,
    change_1d: 3.7394321746713644,
    change_7d: -0.9274986338873532,
    mcap: 0,
  },
  {
    id: '1257',
    name: 'CrossChain Bridge',
    address: '0x92868a5255c628da08f550a858a802f5351c5223',
    symbol: 'BRIDGE',
    url: 'https://crosschainbridge.org',
    description:
      'The Cross-Chain Bridge v2.0 has an ambitious goal: It should be possible to move any token to almost any network. The Cross-Chain Bridge will attract and incentivize all players to participate in the ecosystem. Similar to projects launching tokens on a DEX (e.g. Uniswap), communities and/or creators will be able to add their own token via the Cross-Chain Bridge intuitively and permissionless, benefiting from greater accessibility and security. Meanwhile, yield farmers AND projects will be incentivized to provide liquidity with fee-participation and yield farming.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/crosschain-bridge.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cross-chain-bridge-token',
    cmcId: '14399',
    category: 'Cross Chain',
    chains: ['Ethereum', 'Binance', 'Polygon', 'Avalanche', 'Fantom'],
    module: 'crosschainbridge/index.js',
    twitter: 'CCB_Bridge',
    audit_links: ['https://drive.google.com/file/d/1cei3INZN9lAjclgOYdnSj1_rULqMLXlC/view'],
    listedAt: 1642142431,
    slug: 'crosschain-bridge',
    tvl: 29889339.551813886,
    chainTvls: {
      'Fantom-staking': 704.2013251364068,
      Binance: 2442799.50372392,
      Ethereum: 26373971.26183738,
      'Binance-staking': 36958.69248845004,
      Avalanche: 289114.9943458763,
      'Avalanche-staking': 1451.290945424191,
      'Ethereum-staking': 10760.88108105658,
      Polygon: 749407.9660311207,
      'Polygon-staking': 11344.170431269167,
      Fantom: 34045.82587558935,
      staking: 61219.23627133638,
    },
    change_1h: 1.0566919312469736,
    change_1d: 12.947878018307833,
    change_7d: 43.150010012876436,
    staking: 61219.23627133638,
    mcap: 0,
  },
  {
    id: '1261',
    name: 'DarkCrypto',
    address: 'cronos:0x83b2AC8642aE46FC2823Bc959fFEB3c1742c48B5',
    symbol: 'DARK',
    url: 'https://www.darkcrypto.finance',
    description:
      'DarkCrypto Finance is the first ecosystem running around an algorithmic token pegged to CRO on Cronos chain. DarkVerse will be our ecosystem using DARK as the main token. It contains: DeFi App (Vaults, Stablecoin swap, lending), GameFi, NFT Apps, Betting DApps, MetaVerse applications',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/darkcrypto.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'darkcrypto',
    cmcId: '17118',
    category: 'Algo-Stables',
    chains: ['Cronos'],
    module: 'darkcrypto/index.js',
    twitter: 'DarkCryptoFi',
    forkedFrom: ['Tomb Finance'],
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-DarkCrypto-v1.0.pdf',
    ],
    listedAt: 1642192171,
    slug: 'darkcrypto',
    tvl: 29272463.074921843,
    chainTvls: {
      Cronos: 29272463.074921843,
      pool2: 40251442.75584986,
      'Cronos-pool2': 40251442.75584986,
      'Cronos-staking': 27967188.858079806,
      staking: 27967188.858079806,
    },
    change_1h: 0.8819993971235505,
    change_1d: 3.8881076021993124,
    change_7d: 32.738009287627506,
    staking: 27967188.858079806,
    pool2: 40251442.75584986,
    mcap: 147065,
  },
  {
    id: '253',
    name: 'BiFi',
    address: '0x2791BfD60D232150Bff86b39B7146c0eaAA2BA81',
    symbol: 'BiFi',
    url: 'https://bifi.finance/',
    description:
      'BiFi (abbreviation of Bifrost Finance) is the decentralized finance platform built on Bifrost, the universal multichain middleware.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bifi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bifi',
    cmcId: '8132',
    category: 'Lending',
    chains: ['Ethereum', 'Binance', 'Bitcoin', 'Klaytn', 'Avalanche'],
    module: 'bifi/index.js',
    twitter: 'BIFROSTio',
    audit_links: [
      'https://github.com/bifrost-platform/BiFi-X/blob/main/docs/bifrost_bifix_audit.pdf',
      'https://github.com/bifrost-platform/BIFI/blob/master/docs/ENG/(ENG)_BiFi_BIFROST_Extension_Theori.pdf',
      'https://github.com/bifrost-platform/BIFI/blob/master/docs/ENG/(ENG)_BiFi_Lending_Sooho_EN_Public.pdf',
      'https://github.com/bifrost-platform/BIFI/blob/master/docs/ENG/(ENG)_BiFi_Token_Hexlant_EN.pdf',
      'https://github.com/bifrost-platform/BIFI/blob/master/docs/ENG/(ENG)_BiFrost_BTC_Extension_CertiK.pdf',
    ],
    oracles: ['Chainlink', 'TWAP'],
    slug: 'bifi',
    tvl: 28777104.595319312,
    chainTvls: {
      Klaytn: 2845635.79264431,
      Binance: 9149909.25056218,
      Ethereum: 12653369.858330626,
      Avalanche: 819633.7497356476,
      'Ethereum-staking': 102315066.58637744,
      staking: 102315066.58637744,
      Bitcoin: 3308555.94404655,
    },
    change_1h: -0.10103682976415485,
    change_1d: 1.4141230821010424,
    change_7d: 12.918812366287312,
    staking: 102315066.58637744,
    fdv: 28190639,
    mcap: 8404623,
  },
  {
    id: '626',
    name: 'Reserve',
    address: '0x8762db106b2c2a0bccb3a80d1ed41273552616e8',
    symbol: 'RSR',
    url: 'https://reserve.org',
    description:
      'Reserve Protocol aims to build a censorship-resistant stablecoin and decentralized fiat gateway network that serves as a borderless, inflation-resistant store of wealth, that can be easily transacted with low friction.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/reserve.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'reserve-rights-token',
    cmcId: '3964',
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'reserve/index.js',
    twitter: 'reserveprotocol',
    audit_links: ['https://certificate.certik.io/reports/reserve.org.pdf'],
    slug: 'reserve',
    tvl: 28348096.599892512,
    chainTvls: { Ethereum: 28348096.599892512 },
    change_1h: 0,
    change_1d: 0.0750200948762938,
    change_7d: -0.040833304199992426,
    fdv: 1693526516,
    mcap: 222830877,
  },
  {
    id: '1154',
    name: 'SocialSwap',
    address: 'tron:TBLQs7LqUYAgzYirNtaiX3ixnCKnhrVVCe',
    symbol: 'SST',
    url: 'https://www.socialswap.io',
    description: 'Socialswap is a decentralized exchange on tron blockchain',
    chain: 'Tron',
    logo: 'https://icons.llama.fi/socialswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Tron'],
    module: 'socialswap/index.js',
    twitter: 'Socialswapdefi',
    audit_links: [
      'https://turingpoint.de/en/security-assessments/certificate/smart_contract_audit_socialswapio-d27a325bc6/',
      'https://www.certik.com/projects/socialswap',
    ],
    listedAt: 1640829477,
    slug: 'socialswap',
    tvl: 28191413.322479833,
    chainTvls: { Tron: 28191413.322479833 },
    change_1h: 0.2238102260658792,
    change_1d: 7.506232608434033,
    change_7d: 4.271121157045513,
  },
  {
    id: '1356',
    name: 'Hubble',
    address: 'solana:HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6',
    symbol: 'HBB',
    url: 'https://hubbleprotocol.io',
    description:
      "Hubble's Phase 1 launches a Solana native crypto-backed stablecoin, USDH, that earns users a yield on their multi-asset collateral. Phases 2 and 3 will launch DeFi structured products and undercollateralized loans.",
    chain: 'Solana',
    logo: 'https://icons.llama.fi/hubble.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'hubble',
    cmcId: '16643',
    category: 'Lending',
    chains: ['Solana'],
    module: 'hubble/index.js',
    twitter: 'HubbleProtocol',
    audit_links: ['https://hubble-markets.gitbook.io/hubble-protocol-official-docs/documentation/security-audits'],
    oracles: ['Pyth'],
    listedAt: 1643884771,
    slug: 'hubble',
    tvl: 28097950.3314813,
    chainTvls: { 'Solana-staking': 1058445.5210096084, Solana: 28097950.3314813, staking: 1058445.5210096084 },
    change_1h: 1.2700307870463519,
    change_1d: 2.415585481919848,
    change_7d: null,
    staking: 1058445.5210096084,
    fdv: 59740018,
    mcap: 13002217,
  },
  {
    id: '588',
    name: 'Mobius Money',
    address: 'celo:0x73a210637f6f6b7005512677ba6b3c96bb4aa44b',
    symbol: 'MOBI',
    url: 'https://www.mobius.money',
    description: 'A cross-chain stableswap protocol built on Celo.',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/mobius-money.png',
    audits: '3',
    audit_note: null,
    gecko_id: 'mobius-money',
    cmcId: null,
    category: 'Dexes',
    chains: ['Celo'],
    module: 'mobius/index.js',
    twitter: 'MobiusMoney',
    forkedFrom: ['Saddle Finance'],
    slug: 'mobius-money',
    tvl: 27961493.63568102,
    chainTvls: { Celo: 27961493.63568102 },
    change_1h: 0.06814840109139197,
    change_1d: 0.15463834598934056,
    change_7d: -0.9728697722366348,
    fdv: 17842130,
    mcap: 0,
  },
  {
    id: '917',
    name: 'Pacoca',
    address: 'bsc:0x55671114d774ee99d653d6c12460c780a67f1d18',
    symbol: 'PACOCA',
    url: 'https://pacoca.io',
    description:
      'Paçoca is a portfolio manager where users can track their DeFi assets, invest in smart vaults with compounding yields, and trade tokens at the best rates using the decentralized exchange aggregator.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pacoca.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pacoca',
    cmcId: '10522',
    category: 'Yield',
    chains: ['Binance'],
    module: 'pacoca/index.js',
    twitter: 'pacoca_io',
    audit_links: [
      'https://www.certik.com/projects/pacoca',
      'https://app.inspex.co/library/pacoca#?scope=pacoca-sweetvaults',
    ],
    listedAt: 1637892027,
    slug: 'pacoca',
    tvl: 27950042.32944924,
    chainTvls: { Binance: 27950042.32944924 },
    change_1h: -0.06379234952636637,
    change_1d: 3.5210879289559784,
    change_7d: 25.415525448961844,
    fdv: 8279780,
    mcap: 10381323,
  },
  {
    id: '810',
    name: 'Zenlink',
    address: 'moonriver:0x0f47ba9d9Bde3442b42175e51d6A367928A1173B',
    symbol: 'ZLK',
    url: 'https://zenlink.pro',
    description: 'Zenlink is a cross-chain DEX protocol based on Polkadot.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/zenlink.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonbeam', 'Moonriver'],
    module: 'zenlink/index.js',
    twitter: 'ZenlinkPro',
    forkedFrom: ['Uniswap'],
    listedAt: 1636427656,
    slug: 'zenlink',
    tvl: 27940470.016759116,
    chainTvls: { Moonriver: 13048826.240656776, Moonbeam: 14891643.77610234 },
    change_1h: -0.20064338869347864,
    change_1d: -1.1010534732632635,
    change_7d: -20.396380914215655,
  },
  {
    id: '519',
    name: 'Tezos Liquidity Baking',
    address: 'tezos:KT1TxqZ8QtKvLu3V3JH7Gx58n7Co8pgtpQU5',
    symbol: '-',
    url: 'https://liquidity-baking.com',
    description: 'A new Decentralized Exchange contract added to the Tezos protocol between XTZ and tzBTC.',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/tezos-liquidity-baking.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Tezos'],
    module: 'tezos-baking/index.js',
    twitter: 'tezos',
    slug: 'tezos-liquidity-baking',
    tvl: 27735857.23310958,
    chainTvls: { Tezos: 27735857.23310958 },
    change_1h: 0.5415093663164896,
    change_1d: 1.02051739320801,
    change_7d: 15.675841661867665,
  },
  {
    id: '296',
    name: 'Zookeeper',
    address: 'wan:0x6e11655d6ab3781c6613db8cb1bc3dee9a7e111f',
    symbol: 'ZOO',
    url: 'https://www.zookeeper.finance/',
    description:
      'ZooKeeper brings you a brand new and exciting farming experience, enabling you to enjoy higher rewards and more interesting NFT gameplay and use cases on Wanchain',
    chain: 'Wanchain',
    logo: 'https://icons.llama.fi/zookeeper.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'zookeeper',
    cmcId: '9488',
    category: 'Yield',
    chains: ['Wanchain'],
    module: 'zookeeper.js',
    twitter: 'ZooFarming',
    audit_links: ['https://github.com/ZooFarming/Audit'],
    slug: 'zookeeper',
    tvl: 27389699.303104464,
    chainTvls: { Wanchain: 27389699.303104464 },
    change_1h: 3.017986478563401,
    change_1d: 4.238831064703746,
    change_7d: 7.318815568200932,
    fdv: 21115767,
    mcap: 11378449,
  },
  {
    id: '731',
    name: 'Synthetify',
    address: 'solana:4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y',
    symbol: 'SNY',
    url: 'https://www.synthetify.io',
    description:
      'Synthetify is a decentralized synthetic assets protocol build on the Solana blockchain. The protocol allows for the creation and exchange of synthetic assets that closely track the price of specific assets. Synthetic tokens are based on the SPL-Token standard that gives them the ability to be easily integrated with other DeFi applications like AMM.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/synthetify.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'synthetify-token',
    cmcId: '9447',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'synthetify/index.js',
    twitter: 'synthetify',
    audit_links: ['https://github.com/Synthetify/synthetify-landing/blob/master/public/blog/audit/audit.pdf'],
    oracles: ['Pyth'],
    listedAt: 1635546647,
    slug: 'synthetify',
    tvl: 27048643.8893685,
    chainTvls: { Solana: 27048643.8893685 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 36.6743827826177,
    fdv: 91265880,
    mcap: 9409512,
  },
  {
    id: '1386',
    name: 'Soluna',
    address: null,
    symbol: '-',
    url: 'https://soluna.money',
    description:
      "Soluna is a protocol for composable stable assets designed to bridge Anchor Protocol's high-yield savings to Solana.",
    chain: 'Terra',
    logo: 'https://icons.llama.fi/soluna.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Terra'],
    oracles: [],
    module: 'soluna/index.js',
    twitter: 'solunaDAO',
    listedAt: 1644516018,
    slug: 'soluna',
    tvl: 26666502.294312,
    chainTvls: { Terra: 26666502.294312 },
    change_1h: 0,
    change_1d: -33.22138272263206,
    change_7d: 6.188311244452024,
  },
  {
    id: '488',
    name: 'Ubeswap',
    address: 'celo:0x00Be915B9dCf56a3CBE739D9B9c202ca692409EC',
    symbol: 'UBE',
    url: 'https://ubeswap.org',
    description: 'Ubeswap is the leading DEX on Celo network!',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/ubeswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'ubeswap',
    cmcId: '1339',
    category: 'Dexes',
    chains: ['Celo'],
    module: 'ubeswap/index.js',
    twitter: 'ubeswap',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://docs.ubeswap.org/code-and-contracts/security#audits'],
    slug: 'ubeswap',
    tvl: 26025163.361539397,
    chainTvls: { Celo: 26025163.361539397 },
    change_1h: 3.3174283428226943,
    change_1d: 2.635029908114234,
    change_7d: 12.217751479943857,
    mcap: 13657172,
  },
  {
    id: '915',
    name: 'Definix',
    address: 'bsc:0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d',
    symbol: 'FINIX',
    url: 'https://definix.com/en',
    description:
      'Definix is the transparent crypto investment platform on a multi-chain, with useful features that allows professional traders to earn more from their investing skills by creating and managing their own funds.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/definix.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Klaytn', 'Binance'],
    module: 'definix.js',
    twitter: 'DefinixOfficial',
    audit_links: ['https://www.certik.com/projects/sixnetwork'],
    listedAt: 1637885232,
    slug: 'definix',
    tvl: 25841277.925141282,
    chainTvls: { Binance: 11369894.522937382, Klaytn: 14471383.402203903 },
    change_1h: 0.5908718936877619,
    change_1d: 9.692104152871053,
    change_7d: 9.446663678709584,
  },
  {
    id: '613',
    name: 'Swop',
    address: 'waves:Ehie5xYpeN8op1Cctc6aGUrqx8jq3jtf1DSjXDbfm7aT',
    symbol: 'SWOP',
    url: 'https://swop.fi',
    description:
      'Swop Fi is a service featuring functionality for instant exchange of cryptocurrencies and investing assets in order to receive passive income.',
    chain: 'Waves',
    logo: 'https://icons.llama.fi/swop.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'swop',
    cmcId: '8732',
    category: 'Dexes',
    chains: ['Waves'],
    module: 'swop/index.js',
    twitter: 'Swopfi',
    audit_links: ['https://backend.swop.fi/files/en/deemru.pdf', 'https://backend.swop.fi/files/en/fairyproof.pdf'],
    slug: 'swop',
    tvl: 25822459.244680673,
    chainTvls: { Waves: 25822459.244680673 },
    change_1h: -0.0536129805468164,
    change_1d: 13.97553022092626,
    change_7d: 21.017198044468728,
    mcap: 11658530,
  },
  {
    id: '292',
    name: 'Swapr',
    address: 'arbitrum:0xdE903E2712288A1dA82942DDdF2c20529565aC30',
    symbol: 'SWPR',
    url: 'https://swapr.eth.link/#/swap',
    description: 'A governance-enabled automated-market maker with adjustable fees, made by DXdao.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/swapr.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'swapr',
    cmcId: null,
    category: 'Dexes',
    chains: ['xDai', 'Arbitrum', 'Ethereum'],
    module: 'swapr/index.js',
    twitter: 'SwaprEth',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://gateway.ipfs.io/ipfs/QmNspbn2dQgQMQ9uXkMc7Fjf12RUVVJTzB27ywGeLUXXdn'],
    slug: 'swapr',
    tvl: 25769769.197597414,
    chainTvls: { Ethereum: 1357665.9204667297, xDai: 18645708.256997727, Arbitrum: 5766395.020132959 },
    change_1h: 0.0735205266774841,
    change_1d: 16.481056456883408,
    change_7d: null,
    fdv: 8166309,
    mcap: 1247174,
  },
  {
    id: '303',
    name: 'ZilSwap',
    address: null,
    symbol: 'ZWAP',
    url: 'https://zilswap.io/swap',
    description: 'Decentralised exchange for ZRC2 tokens on Zilliqa.',
    chain: 'Zilliqa',
    logo: 'https://icons.llama.fi/zilswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'zilswap',
    cmcId: '9107',
    category: 'Dexes',
    chains: ['Zilliqa'],
    module: 'zilswap.js',
    twitter: 'ZilSwap',
    slug: 'zilswap',
    tvl: 25374680.972835,
    chainTvls: { Zilliqa: 25374680.972835 },
    change_1h: -1.9195631065861534,
    change_1d: -8.744114348434834,
    change_7d: 68.48303727831052,
    mcap: 6706180,
  },
  {
    id: '200',
    name: 'Bella Protocol',
    address: '0xa91ac63d040deb1b7a5e4d4134ad23eb0ba07e14',
    symbol: 'BEL',
    url: 'https://fs.bella.fi/',
    description:
      'Bella Flex Savings v2 is a smart-mining DApp where users can deposit stablecoins and supported tokens to earn attractive yields instantly. ',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bella-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bella-protocol',
    cmcId: '6928',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'bella.js',
    twitter: 'BellaProtocool',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/bella_audit_report_2020_48_en_1_0.pdf',
    ],
    slug: 'bella-protocol',
    tvl: 25235477.06234957,
    chainTvls: { Ethereum: 25235477.06234957 },
    change_1h: 0.8888972768339016,
    change_1d: 0.791380644767159,
    change_7d: 4.836565688984479,
    fdv: 108199430,
    mcap: 60591789,
  },
  {
    id: '1402',
    name: 'UXD',
    address: 'solana:UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M',
    symbol: 'UXP',
    url: 'https://uxd.fi/',
    description:
      'UXD Protocol is an algorithmic stablecoin that is fully backed by a delta-neutral position using derivatives.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/uxd.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'uxd-protocol-token',
    cmcId: '15028',
    category: 'Algo-Stables',
    chains: ['Solana'],
    module: 'uxd/index.js',
    twitter: 'UXDProtocol',
    language: 'Rust',
    audit_links: ['https://bramah.systems/audits/UXD_Audit_Bramah.pdf'],
    listedAt: 1644696944,
    openSource: false,
    slug: 'uxd',
    tvl: 24984278.292344134,
    chainTvls: { Solana: 24984278.292344134 },
    change_1h: -0.22694610778442836,
    change_1d: 3.5701110517172907,
    change_7d: 15.469478840907769,
    mcap: 0,
  },
  {
    id: '713',
    name: 'Polkaswap',
    address: null,
    symbol: 'PSWAP',
    url: 'https://polkaswap.io',
    description: 'Polkaswap is a DEX built on SORA network',
    chain: 'Sora',
    logo: 'https://icons.llama.fi/polkaswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'polkaswap',
    cmcId: '1101',
    category: 'Dexes',
    chains: ['Sora'],
    module: 'sora.js',
    twitter: 'polkaswap',
    listedAt: 1635262454,
    slug: 'polkaswap',
    tvl: 24819605.274287462,
    chainTvls: { Sora: 24819605.274287462 },
    change_1h: 0,
    change_1d: 0.13642318833611,
    change_7d: 4.4528530273998115,
    fdv: 115549900,
    mcap: 42097186,
  },
  {
    id: '1555',
    name: 'Darkness',
    address: 'cronos:0xE727240728C1a5f95437b8b50AFDd0EA4AE5F0c8',
    symbol: 'NESS',
    url: 'https://www.darkness.finance',
    description:
      'DarkNess Finance is the first multi-fractional-algorithmic stablecoin with stablecoins DEX on Cronos Chain',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/darkness.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'darkness-share',
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    oracles: [],
    forkedFrom: ['Balancer'],
    module: 'darkness/index.js',
    twitter: 'DarkCryptoFi',
    listedAt: 1647396455,
    slug: 'darkness',
    tvl: 24597038.95855331,
    chainTvls: { Cronos: 24597038.95855331, pool2: 6237984.99131205, 'Cronos-pool2': 6237984.99131205 },
    change_1h: -0.07957364393810451,
    change_1d: 6.8442966297248375,
    change_7d: 20.974522371861056,
    pool2: 6237984.99131205,
    fdv: 529133166,
    mcap: 5640596,
  },
  {
    id: '745',
    name: 'Atlantis Loans',
    address: 'bsc:0x1fd991fb6c3102873ba68a4e6e6a87b3a5c10271',
    symbol: 'ATL',
    url: 'https://atlantis.loans',
    description:
      'Atlantis is a decentralised money market that enables users to borrow and supply digital assets to the protocol within a non-custodial environment directly within the Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/atlantis-loans.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'atlantis-loans',
    cmcId: '13121',
    category: 'Lending',
    chains: ['Binance'],
    forkedFrom: ['Compound'],
    module: 'atlantisloans/index.js',
    twitter: 'atlantis_loans',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Atlantis-v1.0.pdf',
    ],
    listedAt: 1635820333,
    slug: 'atlantis-loans',
    tvl: 24264031.286222734,
    chainTvls: {
      pool2: 93267.76451194118,
      'Binance-borrowed': 6742400.05036253,
      'Binance-pool2': 93267.76451194118,
      Binance: 24264031.286222734,
      'Binance-staking': 177391.4128339072,
      borrowed: 6742400.05036253,
      staking: 177391.4128339072,
    },
    change_1h: -0.07517624745332796,
    change_1d: -0.5055126546625388,
    change_7d: -1.441113367435193,
    staking: 177391.4128339072,
    pool2: 93267.76451194118,
    mcap: 581669,
  },
  {
    id: '794',
    name: 'OolongSwap',
    address: 'boba:0x5008F837883EA9a07271a1b5eB0658404F5a9610',
    symbol: 'OLO',
    url: 'https://oolongswap.com/#/',
    description:
      "As the liquidity hub of Boba Network, OolongSwap is a DEX that is...well, not satisfied with being just an ordinary, everyday DEX. We don't want to be just another AMM, we want the best of both worlds: safe, smooth while also being fun, revolutionary, and most importantly, irreplaceable!",
    chain: 'Boba',
    logo: 'https://icons.llama.fi/oolongswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Boba'],
    module: 'oolongswap/index.js',
    twitter: 'oolongswap',
    audit_links: ['https://github.com/OolongSwap/oolongswap-audits'],
    forkedFrom: ['Uniswap'],
    listedAt: 1636194293,
    slug: 'oolongswap',
    tvl: 24244519.243276946,
    chainTvls: { Boba: 24244519.243276946 },
    change_1h: 0,
    change_1d: 0.8097717278756846,
    change_7d: 3.7400586156631306,
  },
  {
    id: '407',
    name: 'Mars Poolin',
    address: '0x66C0DDEd8433c9EA86C8cf91237B14e10b4d70B7',
    symbol: 'MARS',
    url: 'https://mars.poolin.fi',
    description:
      'Mars is the first Ethereum-based, decentralized standardized hashrate protocol. Mars Project combines standardized hashrate product and liquidity mining together, packing PoW mining into an on-chain protocol.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/mars-poolin.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'mars',
    cmcId: '8253',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'marspoolin/index.js',
    twitter: 'MarsProject2020',
    slug: 'mars-poolin',
    tvl: 24126723.562058594,
    chainTvls: { Ethereum: 24126723.562058594 },
    change_1h: -0.13164766256024052,
    change_1d: -0.9656886234653967,
    change_7d: -1.6127822625228845,
    mcap: 394065,
  },
  {
    id: '996',
    name: 'OCP Finance',
    address: 'bsc:0x3c70260eee0a2bfc4b375feb810325801f289fbd',
    symbol: 'OCP',
    url: 'https://ocp.finance',
    description: 'The highest APY yield optimization strategies for BTC, BUSD, BNB and more.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/ocp-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'omni-consumer-protocol',
    cmcId: '13471',
    category: 'Yield',
    chains: ['Binance'],
    module: 'OCP/index.js',
    twitter: 'omnic_pro',
    audit_links: [
      'https://github.com/Quillhash/Audit_Reports/blob/master/OmniOracle%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf',
    ],
    listedAt: 1638998697,
    slug: 'ocp-finance',
    tvl: 24106097.859275557,
    chainTvls: { Binance: 24106097.859275557 },
    change_1h: 0.2885380602162684,
    change_1d: 0.7032689476144327,
    change_7d: 5.314571385104031,
    mcap: 87932,
  },
  {
    id: '1003',
    name: 'WagyuSwap',
    address: 'velas:0xabf26902fd7b624e0db40d31171ea9dddf078351',
    symbol: 'WAG',
    url: 'https://www.wagyuswap.app',
    description:
      'WagyuSwap is the leading decentralized exchange on the Velas Chain, which has been clocked as the fastest blockchain in the world.',
    chain: 'Velas',
    logo: 'https://icons.llama.fi/wagyuswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wagyuswap',
    cmcId: '11354',
    category: 'Dexes',
    chains: ['Velas'],
    module: 'wagyuswap/index.js',
    twitter: 'WagyuSwap_app',
    audit_links: ['https://solidity.finance/audits/Wagyu'],
    forkedFrom: ['Uniswap'],
    listedAt: 1639119653,
    slug: 'wagyuswap',
    tvl: 23876477.810736015,
    chainTvls: { Velas: 23876477.810736015 },
    change_1h: -0.08468133045606407,
    change_1d: 4.3694205104535655,
    change_7d: 14.249171945129916,
    fdv: 66462050,
    mcap: 6217994,
  },
  {
    id: '259',
    name: 'Snowball',
    address: 'avax:0xc38f41a296a4493ff429f1238e030924a1542e50',
    symbol: 'SNOB',
    url: 'https://snowball.network',
    description:
      'Snowball Network is a multi protocol; first as an optimized yield farm of Pangolin Dex, additionally as Stable Coin Vault and Dex for near zero fee/zero slippage stable coin swaps.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/snowball.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'snowball-token',
    cmcId: '9780',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'snowball/index.js',
    twitter: 'throwsnowballs',
    forkedFrom: ['Pickle', 'Saddle Finance'],
    slug: 'snowball',
    tvl: 23600265.635927938,
    chainTvls: { Avalanche: 23600265.635927938 },
    change_1h: 0.3735515853787348,
    change_1d: 6.981283038914697,
    change_7d: 18.405179303053743,
    fdv: 3452433,
    mcap: 1072491,
  },
  {
    id: '1444',
    name: 'Vesta Finance',
    address: 'arbitrum:0xa684cd057951541187f288294a1e1C2646aA2d24',
    symbol: 'VSTA',
    url: 'https://vestafinance.xyz',
    description:
      'Vesta Finance allows you to borrow collateralized stablecoin against supported crypto assets with no interest rate.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/vesta-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'vesta-finance',
    cmcId: null,
    category: 'CDP',
    chains: ['Arbitrum'],
    oracles: ['Chainlink'],
    forkedFrom: ['Liquity'],
    module: 'vesta/index.js',
    twitter: 'vestafinance',
    audit_links: ['https://docs.vestafinance.xyz/audit/audits'],
    listedAt: 1645453933,
    slug: 'vesta-finance',
    tvl: 22822163.764691856,
    chainTvls: { pool2: 2198847.8728362424, Arbitrum: 22822163.764691856, 'Arbitrum-pool2': 2198847.8728362424 },
    change_1h: 0.4831010597200418,
    change_1d: 2.106007509067936,
    change_7d: 7.680932096129766,
    pool2: 2198847.8728362424,
    fdv: 165561603,
    mcap: 8278080,
  },
  {
    id: '1194',
    name: 'Sperax USD',
    address: 'arbitrum:0xD74f5255D557944cf7Dd0E45FF521520002D5748',
    symbol: 'USDs',
    url: 'http://sperax.io',
    description:
      "Sperax's USDs strikes a dynamic balance between collateralization and algorithmic-stability, unleashing the stablecoin's capital efficiency",
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/sperax-usd.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'sperax',
    cmcId: '6715',
    category: 'Algo-Stables',
    chains: ['Arbitrum'],
    module: 'sperax/index.js',
    twitter: 'SperaxUSD',
    oracles: ['DIA'],
    audit_links: ['https://github.com/Sperax/Audit_Reports'],
    listedAt: 1641400651,
    slug: 'sperax-usd',
    tvl: 22773685.66424252,
    chainTvls: { Arbitrum: 22773685.66424252 },
    change_1h: -0.1301458818806509,
    change_1d: 9.95385264810615,
    change_7d: 16.622286467433796,
    mcap: 162470563,
  },
  {
    id: '623',
    name: 'Composable Finance',
    address: '-',
    symbol: 'LAYR',
    url: 'https://www.composable.finance',
    description:
      'Composable is a hyper liquidity infrastructure layer for DeFi assets powered by Layer 2 Ethereum and Polkadot.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/composable-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Cross Chain',
    chains: ['Ethereum', 'Polygon', 'Arbitrum'],
    module: 'composable/index.js',
    twitter: 'ComposableFin',
    slug: 'composable-finance',
    tvl: 22734597.38903544,
    chainTvls: { Ethereum: 22390822.228751324, Polygon: 268300.5271114701, Arbitrum: 75474.63317264638 },
    change_1h: 0.04878556166556791,
    change_1d: -1.7560049747553137,
    change_7d: -2.9799298319360332,
  },
  {
    id: '366',
    name: 'DFX Finance',
    address: '0x888888435fde8e7d4c54cab67f206e4199454c60',
    symbol: 'DFX',
    url: 'https://app.dfx.finance',
    description:
      'DFX is a decentralized foreign exchange protocol optimized for trading fiat-backed foreign stablecoins',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dfx-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'dfx-finance',
    cmcId: '8666',
    category: 'Dexes',
    chains: ['Ethereum', 'Polygon'],
    module: 'dfx/index.js',
    twitter: 'DFXFinance',
    audit_links: ['https://github.com/dfx-finance/protocol/blob/main/audits/2021-05-03-Trail_of_Bits.pdf'],
    slug: 'dfx-finance',
    tvl: 22693742.277210716,
    chainTvls: { Ethereum: 17332600.39510878, Polygon: 5361141.8821019335 },
    change_1h: -0.19821756654727096,
    change_1d: -5.390713757962004,
    change_7d: -12.797359419581,
    fdv: 43129534,
    mcap: 5694264,
  },
  {
    id: '1486',
    name: 'Fountain Protocol',
    address: 'oasis:0xd1dF9CE4b6159441D18BD6887dbd7320a8D52a05',
    symbol: 'FTP',
    url: 'https://ftp.cash/',
    description:
      'Fountain Protocol is a lending platform powered by Oasis. The protocol enables users to experience high capital efficiency one-stop management of DeFi assets. Taking advantage of the extremely efficient and low-cost Oasis Network.',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/fountain-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Oasis'],
    oracles: ['Band'],
    forkedFrom: ['Compound'],
    module: 'Fountain-Protocol/index.js',
    twitter: 'fountainprot',
    audit_links: ['https://github.com/dev-fountain/fountain-protocol-audit'],
    listedAt: 1646137364,
    slug: 'fountain-protocol',
    tvl: 22211549.665506314,
    chainTvls: { 'Oasis-borrowed': 7198237.447598369, Oasis: 22211549.665506314, borrowed: 7198237.447598369 },
    change_1h: 0.7975995590268923,
    change_1d: -0.8868530267917407,
    change_7d: -11.729850759648485,
  },
  {
    id: '229',
    name: 'Gnosis',
    address: '0x6810e776880c02933d47db1b9fc05908e5386b96',
    symbol: 'GNO',
    url: 'https://gnosis.io/',
    description: 'Gnosis builds new market mechanisms for decentralized finance.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/gnosis.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'gnosis',
    cmcId: '1659',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'gnosis/index.js',
    twitter: 'gnosisPM',
    audit_links: ['https://docs.gnosis.io/safe/docs/intro_audits/'],
    slug: 'gnosis',
    tvl: 22109903.906953324,
    chainTvls: { Ethereum: 22109903.906953324 },
    change_1h: 0.794793275769095,
    change_1d: 3.2995274957229555,
    change_7d: 14.040426967756432,
    fdv: 5147308169,
    mcap: 959986872,
  },
  {
    id: '963',
    name: 'Vexchange',
    address: 'vechain:-',
    symbol: 'VEX',
    url: 'https://vexchange.io/swap',
    description: 'A protocol for trading and automated liquidity provision on VeChain',
    chain: 'VeChain',
    logo: 'https://icons.llama.fi/vexchange.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '1552',
    category: 'Dexes',
    chains: ['VeChain'],
    module: 'vexchange/index.js',
    twitter: 'VexchangeIO',
    forkedFrom: ['Uniswap'],
    listedAt: 1638464939,
    slug: 'vexchange',
    tvl: 21782175.192092456,
    chainTvls: { VeChain: 21782175.192092456 },
    change_1h: -0.1815612439446994,
    change_1d: -3.4169074941683846,
    change_7d: 32.2477208210899,
  },
  {
    id: '1532',
    name: 'Proton Loan',
    address: 'proton:LOAN-proton-loan.token',
    symbol: 'LOAN',
    url: 'https://protonloan.com',
    description: 'Proton Loan is a DeFi lending protocol powered by the Proton blockchain.',
    chain: 'Proton',
    logo: 'https://icons.llama.fi/proton-loan.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'proton-loan',
    cmcId: null,
    category: 'Lending',
    chains: ['Proton'],
    oracles: ['TWAP'],
    forkedFrom: [],
    module: 'proton-loan/index.js',
    twitter: 'ProtonLoan',
    audit_links: ['https://docs.protonloan.com/developers/audit'],
    language: 'C++',
    listedAt: 1646953530,
    slug: 'proton-loan',
    tvl: 21727522.763534218,
    chainTvls: {
      'Proton-staking': 15359461.212335866,
      'Proton-borrowed': 6358077.516378658,
      Proton: 21727522.763534218,
      borrowed: 6358077.516378658,
      staking: 15359461.212335866,
    },
    change_1h: -0.2539090817864462,
    change_1d: 15.63551035289035,
    change_7d: 37.58155392229065,
    staking: 15359461.212335866,
    mcap: 0,
  },
  {
    id: '418',
    name: 'MoonFarm',
    address: 'bsc:0xb46049c79d77ff1d555a67835fba6978536581af',
    symbol: 'MFO',
    url: 'https://moonfarm.finance',
    description:
      'MoonFarm is a DeFi and Centralized Finance (CeFi) hybrid yield farming aggregator. It provides the best of both worlds, offering all the cost-effectiveness, easy access, transparency and rapidity of DeFi yield farming, in addition to offering the security associated with a centralized financial ecosystem. Moon Farm optimises Yield Farming interest (APY/ APR) and provides users with the abilities to choose what farms they want to use or the system will automatically determine the best farming strategies using the yield optimization engine.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/moonfarm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'moonfarm-finance',
    cmcId: '10411',
    category: 'Yield',
    chains: ['Binance', 'Ethereum', 'Polygon'],
    module: 'moonfarm.js',
    twitter: 'MoonfarmFinance',
    audit_links: [
      'https://moonfarmfinance.medium.com/moonfarm-finances-security-auditted-and-certified-by-the-arcadia-group-810c4f24c051',
    ],
    oracles: ['Chainlink'],
    slug: 'moonfarm',
    tvl: 21461839,
    chainTvls: { Binance: 16196616, Ethereum: 2016617, Polygon: 1716449 },
    change_1h: 0.16607661954924424,
    change_1d: 1.0219380984395627,
    change_7d: 10.566472214800797,
    fdv: 5247383,
    mcap: 248112,
  },
  {
    id: '1289',
    name: 'BeamSwap',
    address: 'moonbeam:0xcd3B51D98478D53F4515A306bE565c6EebeF1D58',
    symbol: 'GLINT',
    url: 'https://beamswap.io',
    description: 'Defi Hub on Moonbeam',
    chain: 'Moonbeam',
    logo: 'https://icons.llama.fi/beamswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonbeam'],
    module: 'beamswap/index.js',
    twitter: 'Beamswapio',
    audit_links: ['https://docs.beamswap.io/contracts/audit'],
    forkedFrom: ['Uniswap'],
    listedAt: 1642599283,
    slug: 'beamswap',
    tvl: 21368126.28993646,
    chainTvls: { 'Moonbeam-staking': 816830.7151114225, staking: 816830.7151114225, Moonbeam: 21368126.28993646 },
    change_1h: -0.66130159492252,
    change_1d: -2.675437017282377,
    change_7d: -19.57479865665252,
    staking: 816830.7151114225,
  },
  {
    id: '501',
    name: 'Robo-Vault',
    address: '-',
    symbol: '-',
    url: 'https://www.robo-vault.com',
    description: 'Single asset automated yield generation',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/robo-vault.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Fantom'],
    module: 'robovault/index.js',
    twitter: 'robo_vault',
    oracles: ['Band'],
    audit_links: ['https://docs.robo-vault.com/robovault/security'],
    slug: 'robo-vault',
    tvl: 21205313.134498242,
    chainTvls: { Fantom: 21205313.134498242 },
    change_1h: -0.00814095339785581,
    change_1d: -0.5647779071410639,
    change_7d: -15.219190048113845,
  },
  {
    id: '492',
    name: 'Moonpot',
    address: 'bsc:0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
    symbol: 'POTS',
    url: 'https://play.moonpot.com',
    description:
      'Win-Win Prize Pots on Binance Smart Chain. Earn interest, win big crypto prizes, and always keep your deposit. Powered by Beefy Finance',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/moonpot.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'moonpot',
    cmcId: '11242',
    category: 'Yield',
    chains: ['Binance'],
    module: 'moonpot/index.js',
    twitter: 'moonpotdotcom',
    slug: 'moonpot',
    tvl: 20837309.164796524,
    chainTvls: { Binance: 20837309.164796524 },
    change_1h: 1.0215397776721034,
    change_1d: -4.34178008890396,
    change_7d: -16.625355113238598,
    fdv: 9698904,
    mcap: 0,
  },
  {
    id: '667',
    name: 'Mars Ecosystem',
    address: 'bsc:0x7859b01bbf675d67da8cd128a50d155cd881b576',
    symbol: 'XMS',
    url: 'https://marsecosystem.com/home',
    description:
      'Mars Ecosystem is a new decentralized stablecoin paradigm, it integrates the creation and use of stablecoin into the same system. Mars Ecosystem consists of three parts: Mars Treasury, Mars Stablecoin and Mars DeFi protocols, which together form a positive feedback loop. Mars stablecoin is price-stable, capital-efficient, scalable and decentralized. The goal of Mars Ecosystem is to build the central bank and reserve currency of the DeFi world.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/mars-ecosystem.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mars-ecosystem-token',
    cmcId: '10030',
    category: 'CDP',
    chains: ['Binance'],
    module: 'marsecosystem/index.js',
    twitter: 'MarsEcosystem',
    audit_links: ['https://www.certik.org/projects/marsecosystem'],
    listedAt: 1634207654,
    slug: 'mars-ecosystem',
    tvl: 20770948.045305207,
    chainTvls: {
      Binance: 20770948.045305207,
      'Binance-staking': 10023598.447808888,
      'Binance-masterchef': 20770948.045305207,
      masterchef: 20770948.045305207,
      staking: 10023598.447808888,
    },
    change_1h: 0.5137311510098357,
    change_1d: -1.9950765956834147,
    change_7d: -11.064246836334178,
    staking: 10023598.447808888,
    mcap: 9332874,
  },
  {
    id: '399',
    name: 'Geyser',
    address: '0xbEa98c05eEAe2f3bC8c3565Db7551Eb738c8CCAb',
    symbol: 'GYSR',
    url: 'https://www.gysr.io',
    description: 'GYSR is a platform for on-chain incentives',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/geyser.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'geyser',
    cmcId: '7661',
    category: 'Yield',
    chains: ['Ethereum', 'Polygon'],
    module: 'gysr/index.js',
    twitter: 'gysr_io',
    audit_links: [
      'https://resources.gysr.io/gysr_v1_audit_pessimistic.pdf',
      'https://resources.gysr.io/gysr_v2_audit_certik.pdf',
    ],
    slug: 'geyser',
    tvl: 20450616.9130775,
    chainTvls: { Ethereum: 19098865.116978563, Polygon: 1351751.7960989368 },
    change_1h: -1.320520471982988,
    change_1d: 0.9195547345351827,
    change_7d: 259.2624598285119,
    fdv: 1793846,
    mcap: 1406599,
  },
  {
    id: '680',
    name: 'Tinyman',
    address: null,
    symbol: '-',
    url: 'https://tinyman.org',
    description: 'Tinyman is a next-generation decentralized AMM on the Algorand blockchain.',
    chain: 'Algorand',
    logo: 'https://icons.llama.fi/tinyman.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Algorand'],
    module: 'tinyman.js',
    twitter: 'tinymanorg',
    audit_links: ['https://docs.tinyman.org/contracts'],
    listedAt: 1634497796,
    slug: 'tinyman',
    tvl: 20421833.287177548,
    chainTvls: { Algorand: 20421833.287177548 },
    change_1h: 1.1112132918716213,
    change_1d: -6.53402415068804,
    change_7d: 8.299552409713897,
  },
  {
    id: '580',
    name: 'SashimiSwap',
    address: '0xC28E27870558cF22ADD83540d2126da2e4b464c2',
    symbol: 'SASHIMI',
    url: 'https://sashimi.cool/home/swap',
    description:
      'SashimiSwap is a multi-chain deployed decentralized transaction protocol built upon AMM and exchange pools.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/sashimiswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'sashimi',
    cmcId: '1175',
    category: 'Dexes',
    chains: ['Ethereum', 'Heco', 'Binance'],
    module: 'sashimiswap/index.js',
    twitter: 'SASHIMISASHIMI5',
    audit_links: ['https://sashimi.cool/files/sashimi_audit_report.pdf'],
    forkedFrom: ['Uniswap'],
    slug: 'sashimiswap',
    tvl: 20354704.22954183,
    chainTvls: {
      'Binance-borrowed': 19.244796511968584,
      Binance: 493412.6777395167,
      Ethereum: 18496437.376684014,
      Heco: 1364854.1751182994,
      'Ethereum-staking': 99608.93310647912,
      'Ethereum-borrowed': 129176.89669891667,
      borrowed: 129199.61155365422,
      'Heco-borrowed': 3.470058225584161,
      staking: 99608.93310647912,
    },
    change_1h: 0.49839124286515357,
    change_1d: 3.8468440092804883,
    change_7d: 14.115433670567896,
    staking: 99608.93310647912,
    fdv: 751143,
    mcap: 1099849,
  },
  {
    id: '1046',
    name: 'Ouchi Finance',
    address: '0xDe52a16C6DB7914e6953E27A7df4Ce6A315f8b45',
    symbol: 'OUCHI',
    url: 'https://ouchi.finance',
    description:
      "Ouchi Finance aims to make people's concept of living, renting, and staying easier and more automatic. We provides user-friendly NFT platform and DEX functions (Swap, Liquidity, etc.) to realize the goal.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ouchi-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'nodoka/index.js',
    twitter: 'OuchiFinance',
    listedAt: 1639813156,
    slug: 'ouchi-finance',
    tvl: 20340147.374809317,
    chainTvls: { Ethereum: 20340147.374809317 },
    change_1h: 0.9490839897078303,
    change_1d: 3.726836395864467,
    change_7d: 13.810927083627675,
  },
  {
    id: '836',
    name: 'CronaSwap',
    address: 'cronos:0xadbd1231fb360047525BEdF962581F3eee7b49fe',
    symbol: 'CRONA',
    url: 'https://app.cronaswap.org',
    description:
      'CronaSwap is a decentralized exchange platform for swapping ERC-20 tokens on the Cronos Chain network.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/cronaswap.png',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'cronaswap/index.js',
    twitter: 'cronaswap',
    forkedFrom: ['Uniswap'],
    listedAt: 1636768562,
    slug: 'cronaswap',
    tvl: 20159251.092020262,
    chainTvls: { Cronos: 20159251.092020262, 'Cronos-staking': 1521456.0769029746, staking: 1521456.0769029746 },
    change_1h: -0.1662939642038168,
    change_1d: null,
    change_7d: null,
    staking: 1521456.0769029746,
  },
  {
    id: '230',
    name: 'IDEX',
    address: '0xb705268213d593b8fd88d3fdeff93aff5cbdcfae',
    symbol: 'IDEX',
    url: 'https://idex.io/',
    description: 'The fastest, most secure decentralized exchange.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/idex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'aurora-dao',
    cmcId: '310',
    category: 'Dexes',
    chains: ['Polygon', 'Ethereum'],
    module: 'idex/index.js',
    twitter: 'idexio',
    audit_links: [
      'https://github.com/idexio/idex-contracts/tree/master/audits',
      'https://callisto.network/idex-security-audit/',
    ],
    slug: 'idex',
    tvl: 20070778.480930127,
    chainTvls: { Ethereum: 1328790.1907630628, Polygon: 18741988.290167063 },
    change_1h: -0.0430740671122436,
    change_1d: 1.3182986976395767,
    change_7d: 10.455438798121449,
    fdv: 167671033,
    mcap: 108937581,
  },
  {
    id: '496',
    name: 'Orion Protocol',
    address: '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a',
    symbol: 'ORN',
    url: 'https://www.orionprotocol.io',
    description:
      'The Orion Protocol is a standard for connecting to centralised and decentralised exchanges’ orderbooks, allowing the creation of dApps to provide robust services for buying and selling any cryptocurrency at the best price the market has to offer at the time.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/orion-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'orion-protocol',
    cmcId: '5631',
    category: 'Dexes',
    chains: ['Ethereum', 'Binance'],
    module: 'orionprotocol/index.js',
    twitter: 'orion_protocol',
    audit_links: ['https://www.certik.org/projects/orionprotocol'],
    forkedFrom: ['Uniswap'],
    slug: 'orion-protocol',
    tvl: 19681589.10863089,
    chainTvls: { Binance: 7033748.372835057, Ethereum: 12647840.73579583 },
    change_1h: 0.6908138194208391,
    change_1d: 3.302526581167811,
    change_7d: 13.575198758177791,
    fdv: 384469273,
    mcap: 131680726,
  },
  {
    id: '189',
    name: '1inch Network',
    address: '0x111111111117dc0aa78b770fa6a738034120c302',
    symbol: '1INCH',
    url: 'https://1inch.io',
    description:
      'The 1inch Network unites decentralized protocols whose synergy enables the most lucrative, fastest and protected operations in the DeFi space.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/1inch-network.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: '1inch',
    cmcId: '8104',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: '1inch.js',
    twitter: '1inchNetwork',
    audit_links: ['https://help.1inch.io/en/articles/4585088-how-secure-is-1inch'],
    oracles: ['Chainlink'],
    slug: '1inch-network',
    tvl: 19667337.202142067,
    chainTvls: { Ethereum: 19667337.202142067 },
    change_1h: 1.693528641841553,
    change_1d: 2.3054955240178856,
    change_7d: -23.157760923514047,
    fdv: 2773179910,
    mcap: 764996669,
  },
  {
    id: '483',
    name: 'NFTX',
    address: '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776',
    symbol: 'NFTX',
    url: 'https://nftx.org',
    description: 'NFTX is a platform for making ERC20 tokens that are backed by NFT collectibles.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/nftx.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'nftx',
    cmcId: '8191',
    category: 'NFT Marketplace',
    chains: ['Ethereum'],
    module: 'nftx/index.js',
    twitter: 'NFTX_',
    audit_links: ['https://github.com/NFTX-project/audit-level-k/blob/master/NFTX-final.pdf'],
    slug: 'nftx',
    tvl: 19644602.15260719,
    chainTvls: { Ethereum: 19644602.15260719 },
    change_1h: 0.04445159309662472,
    change_1d: 2.7762025433860202,
    change_7d: 44.07645319623791,
    fdv: 111299288,
    mcap: 71916463,
  },
  {
    id: '393',
    name: 'APY Finance',
    address: '0x95a4492F028aa1fd432Ea71146b433E7B4446611',
    symbol: 'APY',
    url: 'https://apy.finance',
    description:
      'The APY.Finance platform is a yield farming robo-advisor that runs a portfolio of yield farming strategies from a single pool of liquidity.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/apy-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'apy-finance',
    cmcId: '7227',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'apyfinance/index.js',
    twitter: 'apyfinance',
    audit_links: ['https://github.com/apy-finance/apy-audits'],
    oracles: ['Chainlink'],
    slug: 'apy-finance',
    tvl: 19308224.652272467,
    chainTvls: { Ethereum: 19308224.652272467 },
    change_1h: 0,
    change_1d: -1.9047440721478353,
    change_7d: 12.403946182851271,
    fdv: 13882506,
    mcap: 8291444,
  },
  {
    id: '671',
    name: 'FODL Finance',
    address: '0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3',
    symbol: 'FODL',
    url: 'https://fodl.finance/',
    description:
      'The Fodl platform combines several money "legos" of the DeFi ecosystem - flash loans and collateral lending platforms, combined with proprietary position protection bots and cross-asset price indexing - to build a fully decentralized leverage trading platform.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/fodl-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'fodl-finance',
    cmcId: '12767',
    category: 'Options',
    chains: ['Ethereum'],
    module: 'fodl/index.js',
    twitter: 'FODLfi',
    audit_links: ['https://certificate.quantstamp.com/full/fodl'],
    listedAt: 1634287149,
    slug: 'fodl-finance',
    tvl: 19284321.785829637,
    chainTvls: { Ethereum: 19284321.785829637, pool2: 14327760.058178421, 'Ethereum-pool2': 14327760.058178421 },
    change_1h: 0.49105476354473865,
    change_1d: 2.6729564928088365,
    change_7d: 12.404484859649443,
    pool2: 14327760.058178421,
    fdv: 155273001,
    mcap: 40915517,
  },
  {
    id: '543',
    name: 'CherrySwap',
    address: 'okex:0x8179d97eb6488860d816e3ecafe694a4153f216c',
    symbol: 'CHE',
    url: 'https://www.cherryswap.net',
    description:
      'CherrySwap is the automatic market-making protocol based on OKExChain(OEC). It adopts the mechanism of Automatic Market Maker (AMM) and aims to achieve self-driven liquidity creation with diversified functions such as liquidity mining, IFO, NFT, lottery, and DAO, so as to provide participants with the maximum value bonus.',
    chain: 'OKExChain',
    logo: 'https://icons.llama.fi/cherryswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cherryswap',
    cmcId: '9437',
    category: 'Dexes',
    chains: ['OKExChain'],
    forkedFrom: ['Uniswap'],
    module: 'cherryswap/index.js',
    twitter: 'CherryswapNet',
    audit_links: [
      'https://docs.cherryswap.net/noneag',
      'https://docs.cherryswap.net/knownsec',
      'https://docs.cherryswap.net/certik',
    ],
    slug: 'cherryswap',
    tvl: 19101851.23490119,
    chainTvls: { 'OKExChain-staking': 99180.72407584303, OKExChain: 19101851.23490119, staking: 99180.72407584303 },
    change_1h: -0.4669281217334458,
    change_1d: 0.31742499517551437,
    change_7d: 4.706312900328442,
    staking: 99180.72407584303,
    fdv: 13235332,
    mcap: 5004674,
  },
  {
    id: '1569',
    name: 'Swim Protocol',
    address: null,
    symbol: '-',
    url: 'https://swim.io/',
    description: 'Swim is a multi-chain AMM for native assets, designed to make bridging as easy as possible.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/swim-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Cross Chain',
    chains: ['Solana', 'Ethereum', 'Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'swim/index.js',
    twitter: 'SwimProtocol',
    audit_links: ['https://swim.io/audits/kudelski.pdf'],
    listedAt: 1647877802,
    slug: 'swim-protocol',
    tvl: 18859956.60374278,
    chainTvls: { Binance: 6038525.6588731455, Ethereum: 6055346.601114928, Solana: 6766490.987334114 },
    change_1h: 0.03195424941442582,
    change_1d: 0.43547071744454513,
    change_7d: 5.879468969034178,
  },
  {
    id: '518',
    name: 'Nuls',
    address: '-',
    symbol: 'NULS',
    url: 'https://nuls.io',
    description:
      'NULS is a blockchain infrastructure for customizable services, driven by our global open-source community.',
    chain: 'Nuls',
    logo: 'https://icons.llama.fi/nuls.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'nuls',
    cmcId: '2092',
    category: 'Chain',
    chains: ['Nuls'],
    module: 'nuls.js',
    twitter: 'Nuls',
    slug: 'nuls',
    tvl: 18776065.13,
    chainTvls: { Nuls: 18776065.13 },
    change_1h: 0.22854768048500773,
    change_1d: 1.168417022294605,
    change_7d: 12.956959738889168,
    fdv: 100744624,
    mcap: 46147405,
  },
  {
    id: '1189',
    name: 'Mimas Finance',
    address: 'cronos:0x10C9284E6094b71D3CE4E38B8bFfc668199da677',
    symbol: 'MIMAS',
    url: 'https://mimas.finance/',
    description: 'Mimas Finance is an algorithmic money market and liquid staking protocol on the Cronos blockchain.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/mimas-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Cronos'],
    module: 'mimas-finance/index.js',
    twitter: 'mimas_fi',
    forkedFrom: ['Compound'],
    listedAt: 1641305977,
    slug: 'mimas-finance',
    tvl: 18755497.389621586,
    chainTvls: { Cronos: 18755497.389621586, 'Cronos-staking': 814653.7862709058, staking: 814653.7862709058 },
    change_1h: 0.42615850057354976,
    change_1d: 1.243600679719961,
    change_7d: 8.255364581181098,
    staking: 814653.7862709058,
  },
  {
    id: '290',
    name: 'Penguin Finance',
    address: 'avax:0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
    symbol: 'PEFI',
    url: 'https://penguinfinance.org/',
    description: 'A third-generation yield farming project building on Avalanche',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/penguin-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'penguin-finance',
    cmcId: '10818',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'penguin/index.js',
    twitter: 'penguin_defi',
    audit_links: ['https://www.certik.com/projects/penguinfinance'],
    slug: 'penguin-finance',
    tvl: 18649376.29403056,
    chainTvls: { 'Avalanche-staking': 1948648.8688804663, Avalanche: 18649376.29403056, staking: 1948648.8688804663 },
    change_1h: 0.7465342654999034,
    change_1d: 0.7128422838344761,
    change_7d: -11.949451120426048,
    staking: 1948648.8688804663,
    fdv: 3580603,
    mcap: 3264544,
  },
  {
    id: '876',
    name: 'Yield Protocol',
    address: null,
    symbol: '-',
    url: 'https://yield.is',
    description:
      'Yield Protocol brings collateralized fixed-rate,fixed-term borrowing and lending and interest rate markets to decentralized finance.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/yield-protocol.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'yieldis/index.js',
    twitter: 'yield',
    listedAt: 1637377782,
    slug: 'yield-protocol',
    tvl: 18526648.243352585,
    chainTvls: { Ethereum: 18526648.243352585 },
    change_1h: 0.4352092899747646,
    change_1d: 8.998359835033341,
    change_7d: -45.311545681820256,
  },
  {
    id: '493',
    name: 'Grim Finance',
    address: 'fantom:0x7ec94c4327dc757601b4273cd67014d7760be97e',
    symbol: 'GRIM',
    url: 'https://www.grim.finance',
    description:
      'Grim Finance is a Smart Yield Optimizer Platform that allows users to stake LP-Tokens issued from the AMM’s in Smart Grim Vaults, which automatically harvest and re-stake their rewards as LP-Tokens for a compounding effect. Helping users reap more rewards, hassle-free.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/grim-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'grimtoken',
    cmcId: '11363',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'grim/index.js',
    twitter: 'financegrim',
    audit_links: ['https://solidity.finance/audits/GrimVault/'],
    slug: 'grim-finance',
    tvl: 18162979.53000001,
    chainTvls: { Fantom: 18162979.53000001 },
    change_1h: 0.6833595130872681,
    change_1d: -1.9241462046279025,
    change_7d: 20.23391201303653,
    fdv: 622448,
    mcap: 540587,
  },
  {
    id: '551',
    name: 'Solarbeam',
    address: 'moonriver:0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
    symbol: 'SOLAR',
    url: 'https://solarbeam.io/exchange/swap',
    description:
      'Solarbeam is a decentralized exchange, providing liquidity and enabling peer-to-peer transactions on the Moonriver Network.',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/solarbeam.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonriver'],
    module: 'solarbeam/index.js',
    twitter: 'Solarbeamio',
    forkedFrom: ['Uniswap'],
    slug: 'solarbeam',
    tvl: 18067188.01587815,
    chainTvls: { Moonriver: 18067188.01587815 },
    change_1h: 2.3576280253381015,
    change_1d: -0.5633165815787606,
    change_7d: null,
  },
  {
    id: '712',
    name: 'Kaidex',
    address: null,
    symbol: '-',
    url: 'https://kaidex.io',
    description:
      'KAIDEX is the first of its kind in the decentralized exchange market, possessing the capabilities of a completely decentralized cross-chain token trading platform. KAIDEX is built upon the Dual Node technology, a novel patent-pending technology that would truly revolutionize multi-chain swapping.',
    chain: 'Kardia',
    logo: 'https://icons.llama.fi/kaidex.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Kardia'],
    module: 'kaidex/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'KardiaChain',
    listedAt: 1635254890,
    slug: 'kaidex',
    tvl: 17974385.287384458,
    chainTvls: { Kardia: 17974385.287384458 },
    change_1h: 0.14972641986025792,
    change_1d: 4.987458177278285,
    change_7d: 25.546133289346102,
  },
  {
    id: '967',
    name: 'Babylon Finance',
    address: '0xf4dc48d260c93ad6a96c5ce563e70ca578987c74',
    symbol: 'BABL',
    url: 'https://www.babylon.finance',
    description:
      "Babylon is a community-led asset management protocol that enables users to invest in DeFi together. It's built on the Ethereum network and it's non-custodial, transparent, permission-less, and governed by the community. Babylonians can create investment communities (called 'Gardens') and invite people to deposit capital. Members participate in the garden by joining the telegram chat, suggesting investment Strategies and voting on Strategies. Members receive rewards based on their contributions.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/babylon-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'babylon-finance',
    cmcId: '14228',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'babylon-finance/index.js',
    twitter: 'BabylonFinance',
    audit_links: ['https://github.com/babylon-finance/security/tree/main/audits'],
    listedAt: 1638501613,
    slug: 'babylon-finance',
    tvl: 17823686.719552204,
    chainTvls: { Ethereum: 17823686.719552204, 'Ethereum-staking': 38469.84086215979, staking: 38469.84086215979 },
    change_1h: 0.7871227527399327,
    change_1d: -1.414471751781079,
    change_7d: -7.11488628463394,
    staking: 38469.84086215979,
    fdv: 38226983,
    mcap: 1241536,
  },
  {
    id: '452',
    name: 'Karura Dollar (kUSD)',
    address: '-',
    symbol: '-',
    url: 'https://acala.network/karura',
    description:
      'The kUSD stablecoin is a decentralized, trustless, collateral-backed cryptocurrency whose value is strong-pegged to the US Dollar.',
    chain: 'Karura',
    logo: 'https://icons.llama.fi/karura-dollar.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Karura'],
    module: 'karura-lending/index.js',
    twitter: 'KaruraNetwork',
    slug: 'karura-dollar-(kusd)',
    tvl: 17779459.700037096,
    chainTvls: { Kusuma: 17779459.700037096, Karura: 17779459.700037096 },
    change_1h: -0.5899560422564178,
    change_1d: 0.45237282492212216,
    change_7d: 17.109887985835698,
  },
  {
    id: '1578',
    name: 'Hyphen',
    address: null,
    symbol: '-',
    url: 'https://www.biconomy.io',
    description:
      'Hyphen provides simple APIs that lets you easily offer instantaneous value transfers between EVM chains, various L2s & allows quick onboarding from L1 to L2s.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/biconomy.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Ethereum', 'Polygon', 'Avalanche'],
    oracles: [],
    forkedFrom: [],
    module: 'biconomy/index.js',
    twitter: 'biconomy',
    audit_links: [
      'https://biconomy-assets.ams3.digitaloceanspaces.com/Biconomy-Hyphen-Audit-report-by-Certik.pdf',
      'https://biconomy-assets.ams3.digitaloceanspaces.com/Hyphen_Smart_Contract_Security_Audit_Report_Halborn_v1_1.pdf',
    ],
    listedAt: 1648148576,
    slug: 'hyphen',
    tvl: 17664132.846560627,
    chainTvls: { Ethereum: 8799769.944840439, Polygon: 8046260.938203873, Avalanche: 818101.9635163153 },
    change_1h: 1.0459264357030662,
    change_1d: 2.9337003346676056,
    change_7d: null,
  },
  {
    id: '1470',
    name: 'Nereus Finance',
    address: 'avax:0xfcDe4A87b8b6FA58326BB462882f1778158B02F1',
    symbol: 'WXT',
    url: 'https://nereus.finance/',
    description:
      'Nereus is a decentralised, non-custodial liquidity market protocol in which users can participate as depositors or borrowers.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/nereus-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'wirex',
    cmcId: '4090',
    category: 'Lending',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: [],
    module: 'nereus/index.js',
    twitter: 'nereusfinance',
    audit_links: ['https://docs.nereus.finance/nereus-protocol/security-audit'],
    listedAt: 1645753082,
    slug: 'nereus-finance',
    tvl: 17625104.05183585,
    chainTvls: { Avalanche: 17625104.05183585 },
    change_1h: 0.5041518174944741,
    change_1d: 3.270199161345971,
    change_7d: 15.306264071795312,
    mcap: 12687802,
  },
  {
    id: '749',
    name: 'BenSwap',
    address: 'smartbch:0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B',
    symbol: 'EBEN',
    url: 'https://benswap.cash',
    description: 'BenSwap. The #1 AMM and yield farm for dog lovers on BSC and smartBCH',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/benswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['smartBCH', 'Binance'],
    module: 'benswap/index.js',
    twitter: 'BenTokenFinanc1',
    audit_links: ['https://www.certik.org/projects/bentoken-finance'],
    forkedFrom: ['Uniswap'],
    listedAt: 1635848244,
    slug: 'benswap',
    tvl: 17373847.942388352,
    chainTvls: {
      'smartBCH-masterchef': 6700569.85,
      masterchef: 6700569.85,
      smartBCH: 17218102.162546143,
      Binance: 155745.77984220991,
      'smartBCH-staking': 4277232.2224535225,
      'Binance-staking': 156829.276492108,
      staking: 4434061.49894563,
    },
    change_1h: 0.4721217983890682,
    change_1d: -1.0354467438381079,
    change_7d: -0.49292988583381714,
    staking: 4434061.49894563,
  },
  {
    id: '164',
    name: 'ValueDefi',
    address: '0x49e833337ece7afe375e44f4e3e8481029218e5c',
    symbol: 'VALUE',
    url: 'https://valuedefi.io/',
    description:
      'The Value DeFi protocol is a platform and suite of products that aim to bring fairness, true value, and innovation to Decentralized Finance.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/valuedefi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'value-liquidity',
    cmcId: '7404',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    module: 'valuedefi.js',
    twitter: 'value_defi',
    audit_links: ['https://valuedefi.io/audit'],
    oracles: ['Chainlink'],
    slug: 'valuedefi',
    tvl: 17355397.191962466,
    chainTvls: { Binance: 15898503.024102129, Ethereum: 1456894.167860338 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 0,
    fdv: 2141738,
    mcap: 2140014,
  },
  {
    id: '133',
    name: 'Shell Protocol',
    address: null,
    symbol: '-',
    url: 'https://www.shellprotocol.io/',
    description:
      'Deposit your stablecoins into our liquidity pool and receive shells, a natively liquid, diversified and yield bearing asset.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/shell-protocol.jpg',
    audits: null,
    audit_note: null,
    gecko_id: null,
    cmcId: '7499',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'shell/index.js',
    twitter: 'ShellProtocol',
    audit_links: ['https://consensys.net/diligence/audits/2020/06/shell-protocol/'],
    slug: 'shell-protocol',
    tvl: 17339652.53896142,
    chainTvls: { Ethereum: 17339652.53896142 },
    change_1h: -0.08083987416776495,
    change_1d: 0.933664185963238,
    change_7d: 7.030765241872473,
  },
  {
    id: '190',
    name: 'dHEDGE',
    address: '0xca1207647ff814039530d7d35df0e1dd2e91fa84',
    symbol: 'DHT',
    url: 'https://www.dhedge.org/',
    description: 'Decentralized Hedge Funds on Ethereum',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dhedge.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dhedge-dao',
    cmcId: '7094',
    category: 'Indexes',
    chains: ['Ethereum', 'Polygon', 'Optimism'],
    module: 'dhedge.js',
    twitter: 'dHedgeOrg',
    audit_links: ['https://iosiro.com/audits/dhedge-platform-smart-contract-audit'],
    slug: 'dhedge',
    tvl: 16840638.994473346,
    chainTvls: {
      Optimism: 409851.87534919207,
      Ethereum: 10400284.623856785,
      Polygon: 6030502.495267366,
      staking: 2910175.5042363284,
    },
    change_1h: 0.416625537698593,
    change_1d: 1.3094351147141907,
    change_7d: 5.5839741747083025,
    staking: 2910175.5042363284,
    fdv: 42150697,
    mcap: 10747321,
  },
  {
    id: '553',
    name: 'Annex',
    address: 'bsc:0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067',
    symbol: 'ANN',
    url: 'https://www.annex.finance',
    description: 'A Decentralized Marketplace for Lenders and Borrowers with Borderless Stablecoins.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/annex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'annex',
    cmcId: '11583',
    category: 'Lending',
    chains: ['Binance', 'Cronos'],
    module: 'annex.js',
    twitter: 'AnnexFinance',
    audit_links: ['https://www.certik.org/projects/annex'],
    slug: 'annex',
    tvl: 16723458.412470417,
    chainTvls: { Binance: 12435501.931344902, Cronos: 4287956.481125515 },
    change_1h: -0.03982416172013359,
    change_1d: 0.29448116462531004,
    change_7d: 7.925327222967908,
    fdv: 43792934,
    mcap: 5919898,
  },
  {
    id: '381',
    name: 'Premia',
    address: '0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70',
    symbol: 'PREMIA',
    url: 'https://premia.finance/',
    description:
      "Premia's automated options market enables best-in-class pricing based on realtime supply and demand, bringing fully-featured peer-to-pool trading and capital efficiency to DeFi options.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/premia.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'premia',
    cmcId: '8476',
    category: 'Options',
    chains: ['Arbitrum', 'Ethereum', 'Binance'],
    module: 'premia/index.js',
    twitter: 'PremiaFinance',
    audit_links: [
      'https://solidity.finance/audits/Premia',
      'https://hacken.io/audits/#solidstate',
      'https://hacken.io/audits/#premia_finance',
    ],
    slug: 'premia',
    tvl: 16548366.133814435,
    chainTvls: {
      Binance: 11155.9195,
      Ethereum: 6943095.792426516,
      'Ethereum-staking': 11178037.268091124,
      Arbitrum: 9594114.42188792,
      staking: 11178037.268091124,
    },
    change_1h: 0,
    change_1d: 0.36750068165261496,
    change_7d: 6.932780874400606,
    staking: 11178037.268091124,
    fdv: 211891571,
    mcap: 22456301,
  },
  {
    id: '673',
    name: 'Aavegotchi',
    address: '0x3F382DbD960E3a9bbCeaE22651E88158d2791550',
    symbol: 'GHST',
    url: 'https://aavegotchi.com',
    description: 'Provably-rare DeFi-staked Crypto Collectibles, powered by Aave',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/aavegotchi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'aavegotchi',
    cmcId: '7046',
    category: 'Gaming',
    chains: ['Polygon', 'Ethereum'],
    module: 'aavegotchi/index.js',
    twitter: 'aavegotchi',
    audit_links: ['https://www.certik.org/projects/aavegotchi'],
    listedAt: 1634320821,
    slug: 'aavegotchi',
    tvl: 16269127.164227583,
    chainTvls: {
      pool2: 11676315.71748532,
      'Polygon-pool2': 11676315.71748532,
      Ethereum: 6094570.772575323,
      Polygon: 10174556.39165226,
      'Polygon-staking': 35847505.067088775,
      staking: 35847505.067088775,
    },
    change_1h: 0.7516322568634166,
    change_1d: 0.08100029218421412,
    change_7d: -1.817627027483283,
    staking: 35847505.067088775,
    pool2: 11676315.71748532,
    fdv: 132313873,
    mcap: 128695419,
  },
  {
    id: '313',
    name: 'ViperSwap',
    address: 'harmony:0xEa589E93Ff18b1a1F1e9BaC7EF3E86Ab62addc79',
    symbol: 'VIPER',
    url: 'https://viper.exchange',
    description:
      "ViperSwap is Harmony's leading DEX. Building on top of Harmony's 2-second finality and insanely low fees it's one of the fastest and cheapest DEXes to use.",
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/viperswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'viper',
    cmcId: '8850',
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'viperswap/index.js',
    twitter: 'VenomDAO',
    audit_links: ['https://solidity.finance/audits/ViperSwap/'],
    forkedFrom: ['Uniswap'],
    slug: 'viperswap',
    tvl: 16217561.390747136,
    chainTvls: { 'Harmony-staking': 1250041.4650496286, Harmony: 16217561.390747136, staking: 1250041.4650496286 },
    change_1h: 1.1903131487555072,
    change_1d: -2.255633007388653,
    change_7d: null,
    staking: 1250041.4650496286,
    fdv: 20073463,
    mcap: 3528949,
  },
  {
    id: '909',
    name: 'Kronos DAO',
    address: 'klaytn:0xD676e57Ca65b827fEb112AD81Ff738E7B6c1048d',
    symbol: 'KRNO',
    url: 'https://kronosdao.finance',
    description: 'A decentralized reserve currency protocol empowering the system for growth and wealth creation.',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/kronos-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Klaytn'],
    module: 'kronos/index.js',
    twitter: 'KronosDAO_fi',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637808505,
    slug: 'kronos-dao',
    tvl: 15949391.8188969,
    chainTvls: { 'Klaytn-staking': 55159389.16436068, Klaytn: 15949391.8188969, staking: 55159389.16436068 },
    change_1h: -0.2566822991992126,
    change_1d: 0.5674298201495276,
    change_7d: -7.652572937014838,
    staking: 55159389.16436068,
  },
  {
    id: '1573',
    name: 'ViteX',
    address: 'vite:tti_564954455820434f494e69b5',
    symbol: 'VX',
    url: 'https://vitex.net',
    description: 'A DAG-based Cross-Chain DEX By the Community, For the Community',
    chain: 'Vite',
    logo: 'https://icons.llama.fi/vitex.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'vitex',
    cmcId: '5246',
    category: 'Dexes',
    chains: ['Vite'],
    oracles: [],
    forkedFrom: [],
    module: 'vitex/index.js',
    twitter: 'ViteXExchange',
    language: 'Solidity++',
    listedAt: 1648005741,
    slug: 'vitex',
    tvl: 15924916.55500473,
    chainTvls: { Vite: 15924916.55500473 },
    change_1h: 0,
    change_1d: 3.408736033974378,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '647',
    name: 'Jet',
    address: 'solana:JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz',
    symbol: 'JET',
    url: 'https://www.jetprotocol.io',
    description:
      'Jet is a decentralized borrowing and lending protocol built for speed, power, and scalability on Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/jet.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'jet',
    cmcId: '12236',
    category: 'Lending',
    chains: ['Solana'],
    module: 'jetprotocol.js',
    twitter: 'JetProtocol',
    oracles: ['Pyth'],
    slug: 'jet',
    tvl: 15810122.067715779,
    chainTvls: { 'Solana-borrowed': 4395665.515051233, Solana: 15810122.067715779, borrowed: 4395665.515051233 },
    change_1h: 0.9951109754379672,
    change_1d: 0.8860543404503858,
    change_7d: 8.043327864841118,
    fdv: 460621538,
    mcap: 42338489,
  },
  {
    id: '1314',
    name: 'Shiden dApp Staking',
    address: 'shiden:-',
    symbol: 'SDN',
    url: 'https://shiden.astar.network',
    description:
      'Shiden Network is a multi-chain decentralized application layer on Kusama Network. dApp staking is a mechanism that incentives developers who built in the Shiden ecosystem. Users can stake on those dApps to earn staking rewards and at the same time support the project by giving them a daily income.',
    chain: 'Shiden',
    logo: 'https://icons.llama.fi/shiden-dapps-staking.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'shiden',
    cmcId: '11451',
    category: 'Staking',
    chains: ['Shiden'],
    module: 'shiden-dapps-staking/index.js',
    twitter: 'ShidenNetwork',
    audit_links: ['https://certificate.quantstamp.com/view/a-star-network-staking'],
    listedAt: 1643128783,
    slug: 'shiden-dapp-staking',
    tvl: 15735735.488150062,
    chainTvls: { Shiden: 15735735.488150062 },
    change_1h: 0.0000559026606055113,
    change_1d: 22.98957547734055,
    change_7d: 49.2118919332479,
    fdv: 78627634,
    mcap: 55238671,
  },
  {
    id: '1057',
    name: 'Linear Finance',
    address: 'bsc:0x762539b45A1dCcE3D36d080F74d1AED37844b878',
    symbol: 'LINA',
    url: 'https://linear.finance',
    description: 'The first cross-chain compatible, delta-one asset protocol.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/linear-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'linear',
    cmcId: '7102',
    category: 'Derivatives',
    chains: ['Binance'],
    module: 'linear/index.js',
    twitter: 'LinearFinance',
    audit_links: ['https://www.certik.com/projects/linearfinance'],
    oracles: ['Chainlink'],
    listedAt: 1639989997,
    slug: 'linear-finance',
    tvl: 15717388.647768086,
    chainTvls: { Binance: 15717388.647768086, pool2: 422444.89764168905, 'Binance-pool2': 422444.89764168905 },
    change_1h: 1.7235995760245544,
    change_1d: -1.2217353803621904,
    change_7d: 24.31234284679111,
    pool2: 422444.89764168905,
    fdv: 266540942,
    mcap: 89339761,
  },
  {
    id: '1026',
    name: 'Starterra',
    address: 'terra:terra13xujxcrc9dqft4p9a8ls0w3j0xnzm6y2uvve8n',
    symbol: 'STT',
    url: 'https://app.starterra.io',
    description:
      'StarTerra is a Gamified LaunchPad with lottery based + guaranteed allocations, Play2Earn and NFT Integration. Through our gamification you, the investor, can strategize your way into larger allocations and be send out into Universe to conquer other planets, moons and galaxies!',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/starterra.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'starterra',
    cmcId: null,
    category: 'Launchpad',
    chains: ['Terra'],
    module: 'starterra.js',
    twitter: 'StarTerra_io',
    audit_links: ['https://github.com/auditmos/audits/blob/main/2021_08_21_STARTERRA.pdf'],
    openSource: true,
    listedAt: 1639533957,
    slug: 'starterra',
    tvl: 15596686.660434123,
    chainTvls: { pool2: 1036469.2208340779, Terra: 15596686.660434123, 'Terra-pool2': 1036469.2208340779 },
    change_1h: 0.24090711429217038,
    change_1d: 0.30329492131457414,
    change_7d: 30.855905963823517,
    pool2: 1036469.2208340779,
    fdv: 140887088,
    mcap: 37921888,
  },
  {
    id: '232',
    name: 'MCDEX',
    address: '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
    symbol: 'MCB',
    url: 'https://mcdex.io/',
    description:
      'MCDEX is a fully decentralized perpetual swap exchange based on Mai Protocol,\r\nallowing anyone to create any perpetual market.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mcdex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'mcdex',
    cmcId: '5956',
    category: 'Dexes',
    chains: ['Binance', 'Arbitrum', 'Ethereum'],
    module: 'mcdex/index.js',
    twitter: 'MonteCarloDEX',
    audit_links: ['https://blog.openzeppelin.com/?s=MCDEX'],
    oracles: ['Chainlink', 'Band'],
    slug: 'mcdex',
    tvl: 15575476.002878249,
    chainTvls: { Binance: 7725537.077859057, Ethereum: 2171424.7538003414, Arbitrum: 5678514.17121885 },
    change_1h: -0.004939381810430632,
    change_1d: 0.11353488258932032,
    change_7d: 1.5890971070605673,
    fdv: 54742939,
    mcap: 20151638,
  },
  {
    id: '382',
    name: 'Pendle',
    address: '0x808507121b80c02388fad14726482e061b8da827',
    symbol: 'PENDLE',
    url: 'https://pendle.finance/',
    description: 'Pendle Finance is a protocol that enables the trading of tokenized future yield on an AMM system.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/pendle.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pendle',
    cmcId: '9481',
    category: 'Yield',
    chains: ['Ethereum', 'Avalanche'],
    module: 'pendle/index.js',
    twitter: 'pendle_fi',
    audit_links: ['https://docs.pendle.finance/faq#has-pendle-been-audited'],
    slug: 'pendle',
    tvl: 15501366.72542615,
    chainTvls: {
      pool2: 3132743.681048126,
      Avalanche: 1976381.7809353042,
      'Ethereum-pool2': 1416241.826060339,
      Ethereum: 13524984.944490846,
      'Ethereum-staking': 3940202.4529746394,
      'Avalanche-pool2': 1716501.8549877866,
      staking: 3940202.4529746394,
    },
    change_1h: -2.030370177786935,
    change_1d: -3.276032561309435,
    change_7d: -4.4035634749658215,
    staking: 3940202.4529746394,
    pool2: 3132743.681048126,
    fdv: 37728293,
    mcap: 17114554,
  },
  {
    id: '437',
    name: 'Guard(Helmet)',
    address: 'polygon:0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    symbol: 'GUARD',
    url: 'https://www.guard.insure',
    description:
      'Guard.insure is a peer-to-peer (P2P) price-shield insurance protocol launched on Binance Smart Chain (BSC) fistly with the form of helmet.insure and now multi-chained to support Polygon net with the aim to redefine option trading with user-friendly insurance policy wrapping.\n\n    Guard allows users to create an insurance policy for any crypto asset in the market, protecting DeFi users against the risk of price fluctuations. Policy trading on Guard is market-oriented without complex mathematics. There are two kinds of policy which could be involved in mining: SHORT Tokens and LONG tokens.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/gurad.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'helmet-insure',
    cmcId: '8265',
    category: 'Insurance',
    chains: ['Binance', 'Polygon'],
    module: 'helmetinsure/index.js',
    twitter: 'Helmet_insure',
    audit_links: ['https://www.certik.org/projects/helmet'],
    slug: 'guard(helmet)',
    tvl: 15490077.695388585,
    chainTvls: { Binance: 10633276.377236953, Polygon: 4856801.318151633 },
    change_1h: -1.1050187949192036,
    change_1d: 1.595220284695472,
    change_7d: null,
    mcap: 1627969,
  },
  {
    id: '545',
    name: 'ApolloDAO',
    address: '-',
    symbol: 'APOLLO',
    url: 'https://apollo.farm',
    description: 'A protocol that facilitates cost-efficient and secure auto-compounding returns',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/apollodao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Terra'],
    module: 'apollodao/index.js',
    twitter: 'ApolloDAO',
    audit_links: [
      'https://github.com/oak-security/audit-reports/blob/master/Apollo/2021-08-18%20Audit%20Report%20-%20Apollo.pdf',
    ],
    openSource: false,
    slug: 'apollodao',
    tvl: 15316922.64654852,
    chainTvls: { Terra: 15316922.64654852 },
    change_1h: 0.0583965149739214,
    change_1d: 0.02437653055280009,
    change_7d: -1.3718792425147228,
  },
  {
    id: '624',
    name: 'Paladin Finance',
    address: '0xAB846Fb6C81370327e784Ae7CbB6d6a6af6Ff4BF',
    symbol: 'PAL',
    url: 'https://www.paladin.vote',
    description:
      'Paladin is a decentralized, non-custodial governance lending protocol where users can either loan the voting power in their governance token, or borrow some voting power.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/paladin-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'paladinfinance/index.js',
    twitter: 'Paladin_vote',
    audit_links: [
      'https://github.com/pessimistic-io/audits/blob/914e35fdfc879451a61d2a3969f3be839b8c808f/Paladin%20Security%20Analysis%20by%20Pessimistic.pdf',
    ],
    slug: 'paladin-finance',
    tvl: 15311800.117108263,
    chainTvls: { Ethereum: 15311800.117108263 },
    change_1h: null,
    change_1d: 20.476023684604343,
    change_7d: 30.167826195273022,
  },
  {
    id: '1045',
    name: 'Tempus Finance',
    address: '0xa36fdbbae3c9d55a1d67ee5821d53b50b63a1ab9 ',
    symbol: 'TEMP',
    url: 'http://tempus.finance',
    description:
      'Tempus is a multi-chain fixed income protocol. Tempus integrates with lending protocols, staking protocols, and yield aggregators, and lets users fix or speculate on the yield generated by them.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/tempus-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'tempus',
    cmcId: '14089',
    category: 'Yield',
    chains: ['Ethereum', 'Fantom'],
    module: 'tempus.js',
    twitter: 'TempusFinance',
    audit_links: ['https://www.coinspect.com/tempus-audit'],
    listedAt: 1639812368,
    slug: 'tempus-finance',
    tvl: 15290336.10427255,
    chainTvls: { Ethereum: 8283415.967228364, Fantom: 7006920.137044186 },
    change_1h: 0.25916170796323,
    change_1d: -0.38204106267245663,
    change_7d: 4.961790026570398,
    fdv: 120199155,
    mcap: 9122198,
  },
  {
    id: '970',
    name: 'Drift',
    address: null,
    symbol: '-',
    url: 'https://www.drift.trade',
    description:
      'Drift brings on-chain, cross-margined perpetual futures to Solana. Making futures DEXs the best way to trade.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/drift.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Derivatives',
    chains: ['Solana'],
    module: 'drift.js',
    twitter: 'DriftProtocol',
    oracles: ['Pyth'],
    listedAt: 1638568951,
    slug: 'drift',
    tvl: 15137253.373240223,
    chainTvls: { Solana: 15137253.373240223 },
    change_1h: 0.06628417594873781,
    change_1d: 1.3879863382459519,
    change_7d: -1.205240014596967,
  },
  {
    id: '1278',
    name: 'YieldWolf',
    address: null,
    symbol: '-',
    url: 'https://yieldwolf.finance',
    description: 'Next-generation yield farming automation',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/yieldwolf.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom', 'Binance', 'Avalanche', 'Cronos', 'Polygon', 'Harmony', 'Celo'],
    module: 'yieldwolf/index.js',
    twitter: 'YieldWolf',
    audit_links: ['https://paladinsec.co/projects/yieldwolf'],
    listedAt: 1642454522,
    slug: 'yieldwolf',
    tvl: 14916939.429320993,
    chainTvls: {
      Cronos: 1236370.8732254975,
      Harmony: 83482.82414602768,
      Binance: 2187663.5303976783,
      Avalanche: 2160696.5965564884,
      Polygon: 284807.7292705812,
      Celo: 5461.483461714947,
      Fantom: 8958456.392263006,
    },
    change_1h: 0.6113738387326038,
    change_1d: -1.1038549375389408,
    change_7d: null,
  },
  {
    id: '241',
    name: 'PowerPool',
    address: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1',
    symbol: 'CVP',
    url: 'https://powerpool.finance/',
    description: 'PowerPool is a decentralized protocol for automatically managed token portfolios, and smart indices',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/powerpool.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'concentrated-voting-power',
    cmcId: '7368',
    category: 'Indexes',
    chains: ['Ethereum', 'Binance'],
    module: 'powerindex/index.js',
    twitter: 'powerpoolcvp',
    audit_links: ['https://docs.powerpool.finance/security/security-audits'],
    slug: 'powerpool',
    tvl: 14748890.325359004,
    chainTvls: { Binance: 239748.85930951894, Ethereum: 14509141.466049485 },
    change_1h: 0.5294621148466803,
    change_1d: 1.0707275804413285,
    change_7d: 2.5568458166427916,
    fdv: 87134345,
    mcap: 24053129,
  },
  {
    id: '1351',
    name: 'WigoSwap',
    address: 'fantom:0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    symbol: 'WIGO',
    url: 'https://wigoswap.io/',
    description:
      'DeFi hub on Fantom network with LIFETIME farming earnings powered by Gamified Burning Mechanism (GBM)',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/wigoswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'wigoswap/index.js',
    twitter: 'WigoSwap',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://certik.com/projects/wigoswap'],
    listedAt: 1643852628,
    slug: 'wigoswap',
    tvl: 14706427.124215206,
    chainTvls: { 'Fantom-staking': 582159.3769239024, staking: 582159.3769239024, Fantom: 14706427.124215206 },
    change_1h: -0.0004358770101475784,
    change_1d: 1.704455039835807,
    change_7d: -24.26527771547775,
    staking: 582159.3769239024,
  },
  {
    id: '1016',
    name: 'Aztec',
    address: null,
    symbol: '-',
    url: 'https://aztec.network',
    description:
      'Aztec is an open source layer 2 network bringing scalability and privacy too Ethereum. Aztec uses zkSNARK proofs to provide privacy and scaling via our zkRollup service.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/aztec.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Privacy',
    chains: ['Ethereum'],
    module: 'aztec/index.js',
    twitter: 'aztecnetwork',
    listedAt: 1639389670,
    slug: 'aztec',
    tvl: 14436839.97306682,
    chainTvls: { Ethereum: 14436839.97306682 },
    change_1h: 1.43325492153528,
    change_1d: 5.77250090245731,
    change_7d: 14.698778641994664,
  },
  {
    id: '570',
    name: 'TEN Finance',
    address: 'bsc:0xd15c444f1199ae72795eba15e8c1db44e47abf62',
    symbol: 'TENFI',
    url: 'https://ten.finance',
    description:
      'TEN is a yield optimizer that is simplifying the staking and yield farming by auto-compounding your LP’s and tokens across multiple Binance Smart Chain farming ecosystems while providing a robust yield earning environment on the market while adhering to security, sustainability, longevity and simplicity. TEN is the Token Enrichment Network, decentralized finance, simplified!',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/ten-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ten',
    cmcId: '10031',
    category: 'Yield',
    chains: ['Binance'],
    module: 'tenfinance/index.js',
    twitter: 'TENfinance',
    audit_links: ['https://certik.org/projects/tenfinance'],
    slug: 'ten-finance',
    tvl: 14378549.551210545,
    chainTvls: {
      Binance: 14378549.551210545,
      'Binance-staking': 2415863.637831528,
      pool2: 1560884.3677879306,
      'Binance-pool2': 1560884.3677879306,
      staking: 2415863.637831528,
    },
    change_1h: 0.11924002839387526,
    change_1d: 2.033205571328338,
    change_7d: 4.8471850775882785,
    staking: 2415863.637831528,
    pool2: 1560884.3677879306,
    mcap: 767351,
  },
  {
    id: '505',
    name: 'Karura Liquid-Staking',
    address: '-',
    symbol: '-',
    url: 'https://acala.network/karura',
    description:
      'Stake your KSM for LKSM to earn rewards without sacrificing liquidity. LKSM is unlocked and ready to trade, take out collateralized loans, and provide liquidity for additional yield.',
    chain: 'Karura',
    logo: 'https://icons.llama.fi/karura-liquid-staking.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Liquid Staking',
    chains: ['Karura'],
    module: 'karura-staking/index.js',
    twitter: 'KaruraNetwork',
    slug: 'karura-liquid-staking',
    tvl: 14289766.527780812,
    chainTvls: { Kusuma: 14289766.527780812, Karura: 14289766.527780812 },
    change_1h: -0.5271538080889115,
    change_1d: 0.8021984379077622,
    change_7d: 13.75196564412478,
  },
  {
    id: '585',
    name: 'Popsicle Finance',
    address: '0xf16e81dce15b08f326220742020379b855b87df9',
    symbol: 'ICE',
    url: 'https://popsicle.finance',
    description:
      'Popsicle finance is a cross-chain yield enhancement platform focusing on Automated Market-Making (AMM) Liquidity Providers (LP).',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/popsicle-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ice-token',
    cmcId: '9073',
    category: 'Yield',
    chains: ['Fantom', 'Binance', 'Ethereum'],
    module: 'popsicle/index.js',
    twitter: 'PopsicleFinance',
    audit_links: ['https://docs.popsicle.finance/reports/our-audits'],
    slug: 'popsicle-finance',
    tvl: 14239796.39642037,
    chainTvls: {
      pool2: 5946934.649388821,
      'Fantom-staking': 1678900.777218924,
      'Binance-pool2': 155784.15403557266,
      'Ethereum-pool2': 2878556.63295517,
      Ethereum: 13151389.771578241,
      'Fantom-pool2': 2912593.8623980787,
      Fantom: 1088406.6248421294,
      staking: 1678900.777218924,
    },
    change_1h: 0.4387272674134266,
    change_1d: 0.3890645480280739,
    change_7d: 13.566688609943185,
    staking: 1678900.777218924,
    pool2: 5946934.649388821,
    mcap: 32351516,
  },
  {
    id: '420',
    name: 'Elk',
    address: 'polygon:0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
    symbol: 'ELK',
    url: 'https://elk.finance',
    description:
      'Elk Finance is a decentralized network for cross-chain liquidity. The Elk ecosystem will make it seamless for anyone to exchange cryptocurrencies. Our motto is Any chain, anytime, anywhere.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/elk.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'elk-finance',
    cmcId: '10095',
    category: 'Dexes',
    chains: [
      'Avalanche',
      'Polygon',
      'Binance',
      'Cronos',
      'Fantom',
      'xDai',
      'Fuse',
      'Elastos',
      'IoTeX',
      'Harmony',
      'Hoo',
      'Heco',
      'Kucoin',
      'Moonriver',
      'OKExChain',
    ],
    module: 'elkfinance/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'elk_finance',
    audit_links: ['https://blog.hashex.org/elk-finance-smart-contracts-audit-report-a18deaa5890b'],
    slug: 'elk',
    tvl: 14186641.200901898,
    chainTvls: {
      Cronos: 1497907.301057345,
      OKExChain: 125881.37160009565,
      Hoo: 313317.5145391555,
      Moonriver: 196384.93279870765,
      Avalanche: 3212129.804834593,
      Kucoin: 247824.97529454718,
      Harmony: 322818.04567497526,
      Elastos: 543584.6945516859,
      Binance: 1942834.8696143935,
      IoTeX: 427446.4548161222,
      xDai: 1222236.5713103085,
      Heco: 306672.1158201971,
      Polygon: 2030486.4388414572,
      Fuse: 544546.8213207572,
      Fantom: 1252569.2888275573,
    },
    change_1h: 0.46500653645568946,
    change_1d: 4.633040483921988,
    change_7d: null,
    mcap: 14555248,
  },
  {
    id: '355',
    name: 'Gamma',
    address: '0x6bea7cfef803d1e3d5f7c0103f7ded065644e197',
    symbol: 'GAMMA',
    url: 'https://www.gammastrategies.org',
    description:
      'Access active liquidity management on Uniswap v3. Gamma has developed a protocol, a management infrastructure, and a variety of strategies used by managers and market makers.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/gamma.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'gamma-strategies',
    cmcId: '9170',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'visor/index.js',
    twitter: 'GammaStrategies',
    audit_links: ['https://github.com/VisorFinance/hypervisor/blob/master/REP-Hypervisor-2021-07-07.pdf'],
    slug: 'gamma',
    tvl: 13941918.623742247,
    chainTvls: { Ethereum: 13941918.623742247, 'Ethereum-staking': 5446149.781789215, staking: 5446149.781789215 },
    change_1h: 0.4474708149202513,
    change_1d: 3.4736103746863165,
    change_7d: 12.02941731604237,
    staking: 5446149.781789215,
    fdv: 49412689,
    mcap: 16370664,
  },
  {
    id: '562',
    name: 'Crystl Finance',
    address: 'polygon:0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64',
    symbol: 'CRYSTL',
    url: 'https://www.crystl.finance',
    description:
      'Crystl Finance is a decentralized Vaulting Platform that runs on Polygon and ApeSwap Polygon Exchange, and pays out $CRYSTL, the native currency. With it, you can earn profits from your capital in a way that is fair, transparent, and secure.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/crystl-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'crystl-finance',
    cmcId: '12100',
    category: 'Yield',
    chains: ['Cronos', 'Polygon'],
    module: 'crystl/index.js',
    twitter: 'CrystlFinance',
    audit_links: ['https://polycrystal.gitbook.io/polycrystal-finance/security/audits'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    listedAt: 1634207334,
    slug: 'crystl-finance',
    tvl: 13839556.58702686,
    chainTvls: { Cronos: 12839442.31066634, Polygon: 1000114.2763605204 },
    change_1h: 0.4096090796632126,
    change_1d: 127.9931657389426,
    change_7d: 138.8279951467687,
    fdv: 1657314,
    mcap: 1631246,
  },
  {
    id: '187',
    name: 'Unagii',
    address: '-',
    symbol: '-',
    url: 'https://www.unagii.com/',
    description: 'The automated DeFi yield platform with staking services.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/unagii.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'unagii.js',
    twitter: 'unagiidotcom',
    audit_links: ['https://docs.unagii.com/unagii-vaults/vaults-audits'],
    slug: 'unagii',
    tvl: 13826827.014073854,
    chainTvls: { Ethereum: 13826827.014073854 },
    change_1h: 0.05158007283003485,
    change_1d: 1.3880956594255878,
    change_7d: 3.257996618522057,
  },
  {
    id: '1454',
    name: 'Bullish',
    address: null,
    symbol: '-',
    url: 'https://bullish.com',
    description:
      'Bullish is a powerful new exchange for digital assets that offers deep liquidity, automated market making, and industry-leading security.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/bullish.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'bullish/index.js',
    twitter: 'Bullish',
    language: 'C++',
    listedAt: 1645579286,
    slug: 'bullish',
    tvl: 13769239.622544,
    chainTvls: { EOS: 13769239.622544 },
    change_1h: 0,
    change_1d: -11.088238871660934,
    change_7d: 6.810706619822454,
  },
  {
    id: '517',
    name: 'Kolibri',
    address: 'tezos:KT1JkoE42rrMBP9b2oDhbx6EUr26GcySZMUH',
    symbol: 'kDAO',
    url: 'https://kolibri.finance',
    description:
      'Kolibri is an Tezos based stablecoin built on Collateralized Debt Positions (CDPs) known as Ovens. Kolibri uses CDPs (referred to as an Oven) to collateralize a soft pegged USD-stable value asset, kUSD.',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/kolibri.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'CDP',
    chains: ['Tezos'],
    module: 'kolibri/index.js',
    twitter: 'HoverEng',
    audit_links: ['https://kolibri.finance/docs/security-report.pdf'],
    oracles: ['Harbinger'],
    slug: 'kolibri',
    tvl: 13732683.444773274,
    chainTvls: { 'Tezos-pool2': 686684.001847876, pool2: 686684.001847876, Tezos: 13732683.444773274 },
    change_1h: 0,
    change_1d: -0.03299001608175445,
    change_7d: 16.975731536853374,
    pool2: 686684.001847876,
  },
  {
    id: '1461',
    name: 'WOOFi',
    address: 'bsc:0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
    symbol: 'WOO',
    url: 'https://fi.woo.org',
    description:
      'WOOFi is a multichain DeFi platform which offers the best trade execution and lowest swap fee, opportunities to earn sustainable yields on crypto, and a high-efficiency solution for on-chain liquidity provision.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/woofi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'woo-network',
    cmcId: '1289',
    category: 'Dexes',
    chains: ['Binance', 'Avalanche'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'woofi.js',
    twitter: 'WOOnetwork',
    audit_links: ['https://www.certik.com/projects/woofiswap'],
    listedAt: 1645647756,
    slug: 'woofi',
    tvl: 13679466.077381726,
    chainTvls: {
      Binance: 8259629.2294022655,
      'Binance-staking': 55234231.83331596,
      Avalanche: 5419836.847979462,
      'Avalanche-staking': 8002315.355918991,
      staking: 63236547.18923495,
    },
    change_1h: 1.800272472137891,
    change_1d: 3.6228123835810635,
    change_7d: -15.962420529354333,
    staking: 63236547.18923495,
    fdv: 1926546360,
    mcap: 645906834,
  },
  {
    id: '1419',
    name: 'BabelFish',
    address: null,
    symbol: '-',
    url: 'https://babelfish.money',
    description: 'BabelFish is a multi-chain aggregator and distributor of stablecoins',
    chain: 'RSK',
    logo: 'https://icons.llama.fi/babelfish.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['RSK'],
    oracles: [],
    forkedFrom: [],
    module: 'babelfish/index.js',
    twitter: 'babelfishmoney',
    language: 'Solidity',
    listedAt: 1644988202,
    slug: 'babelfish',
    tvl: 13653402.107053014,
    chainTvls: { RSK: 13653402.107053014 },
    change_1h: 0.0012220144558199308,
    change_1d: 3.1662726354009294,
    change_7d: -1.812059348558023,
  },
  {
    id: '882',
    name: 'LumenSwap',
    address: 'stellar:GAB7STHVD5BDH3EEYXPI3OM7PCS4V443PYB5FNT6CFGJVPDLMKDM24WK',
    symbol: 'LSP',
    url: 'https://lumenswap.io',
    description:
      'Lumenswap is a decentralized exchange built on the Stellar network that allows you to swap and trade assets using a friendly, minimal interface.',
    chain: 'Stellar',
    logo: 'https://icons.llama.fi/lumenswap.jpg',
    audit: '0',
    audit_note: null,
    gecko_id: 'lumenswap',
    cmcId: '11678',
    category: 'Dexes',
    chains: ['Stellar'],
    module: 'lumenswap/index.js',
    twitter: 'lumenswap',
    listedAt: 1637392233,
    slug: 'lumenswap',
    tvl: 13489050.987048,
    chainTvls: { Stellar: 13489050.987048 },
    change_1h: -0.01951037914379583,
    change_1d: 0.277615637931234,
    change_7d: 6.234907091739373,
    fdv: 28828653,
    mcap: 0,
  },
  {
    id: '721',
    name: 'Tracer',
    address: 'arbitrum:0xa72159fc390f0e3c6d415e658264c7c4051e9b87',
    symbol: 'TCR',
    url: 'https://tracer.finance',
    description: 'Tracer is an open-source, smart contract protocol for derivatives.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/tracer.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'tracer-dao',
    cmcId: '12341',
    category: 'Derivatives',
    chains: ['Arbitrum'],
    module: 'tracerdao/index.js',
    twitter: 'TracerDAO',
    audit_links: ['https://tracer.finance/radar/sigma-prime-audit-response'],
    listedAt: 1635464348,
    slug: 'tracer',
    tvl: 13418751.510274515,
    chainTvls: { Arbitrum: 13418751.510274515 },
    change_1h: -0.030332837728209938,
    change_1d: 5.804759165287933,
    change_7d: 6.123168512141078,
    fdv: 216285897,
    mcap: 48993578,
  },
  {
    id: '372',
    name: 'Rabbit Finance',
    address: 'bsc:0x95a1199eba84ac5f19546519e287d43d2f0e1b41',
    symbol: 'RABBIT',
    url: 'https://www.rabbitfinance.io/earn',
    description:
      'Rabbit Finance is a cross-chain excess lending protocol, which can help users deposit and obtain more interest and help liquidity farmers obtain higher income through high as 9x leverage.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/rabbit-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'rabbit-finance',
    cmcId: '10178',
    category: 'Lending',
    chains: ['Binance'],
    module: 'rabbitfinance/index.js',
    twitter: 'FinanceRabbit',
    audit_links: ['https://rabbitfinance.gitbook.io/homepage/resources/audit-report'],
    slug: 'rabbit-finance',
    tvl: 13365630.975969937,
    chainTvls: { Binance: 13365630.975969937, 'Binance-staking': 0, staking: 0 },
    change_1h: -1.6665617545167493,
    change_1d: -5.5119322185359465,
    change_7d: -20.921483539161983,
    staking: 0,
    fdv: 1851063,
    mcap: 970663,
  },
  {
    id: '374',
    name: 'Kine Finance',
    address: 'bsc:0xbfa9df9ed8805e657d0feab5d186c6a567752d7f',
    symbol: 'KINE',
    url: 'https://kine.finance/',
    description:
      'Kine establishes general purpose liquidity pools backed by a customizable portfolio of digital assets. The liquidity pool allows traders to open and close derivatives positions according to trusted price feeds, avoiding the need of counterparties.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kine-finance.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: 'kine-protocol',
    cmcId: '8790',
    category: 'Yield',
    chains: ['Binance', 'Ethereum', 'Avalanche', 'Polygon'],
    module: 'kinefinance/index.js',
    twitter: 'KineProtocol',
    audit_links: ['https://docs.kine.io/audit/peckshield'],
    slug: 'kine-finance',
    tvl: 13279589.508996302,
    chainTvls: {
      Avalanche: 665328.0195231767,
      'Polygon-borrowed': 270929.7644786351,
      'Binance-borrowed': 2688332.9376724777,
      Binance: 6260598.737823639,
      Ethereum: 5750861.062088421,
      Polygon: 602801.6895610653,
      'Ethereum-borrowed': 2818024.399381585,
      borrowed: 6443070.262231629,
      'Avalanche-borrowed': 665783.1606989324,
    },
    change_1h: -0.052682433044580534,
    change_1d: -0.28063905464694017,
    change_7d: 4.966711277072861,
    fdv: 33091011,
    mcap: 6688330,
  },
  {
    id: '1557',
    name: 'Single Finance',
    address: 'cronos:0x0804702a4e749d39a35fde73d1df0b1f1d6b8347',
    symbol: 'SINGLE',
    url: 'https://singlefinance.io',
    description:
      'Single Finance is a super intuitive platform for all your DeFi investments, minimizing correlations with the general market. Our signature strategy is a pseudo-market-neutral strategy. Everything here, including capital protection and return calculation, is worked out from your injected capital in USD. Everything is visualized. And everything is at your fingertips. We also launched LP Time Machine, a performance analytics tool for liquidity pools across all EVM compatible chains. The tool shows the full breakdown of backward-simulated P & L (based on capital marked to USD), including LP rewards, DEX reward tokens, and value change due to the price impact.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/single-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'single-finance',
    cmcId: '17469',
    category: 'Yield',
    chains: ['Cronos'],
    oracles: ['Internal'],
    forkedFrom: [],
    module: 'single/index.js',
    twitter: 'single_finance',
    audit_links: ['https://www.certik.com/projects/singlefinance'],
    listedAt: 1647474272,
    slug: 'single-finance',
    tvl: 13141040.643963818,
    chainTvls: {
      Cronos: 13141040.643963818,
      pool2: 3818639.721696729,
      'Cronos-pool2': 3818639.721696729,
      'Cronos-staking': 691246.4617151027,
      staking: 691246.4617151027,
    },
    change_1h: -0.08793264030938985,
    change_1d: -3.6439766694986275,
    change_7d: 11.567586698755576,
    staking: 691246.4617151027,
    pool2: 3818639.721696729,
    fdv: 60590347,
    mcap: 0,
  },
  {
    id: '648',
    name: 'Alkemi',
    address: '0x6C16119B20fa52600230F074b349dA3cb861a7e3',
    symbol: 'ALK',
    url: 'https://alkemi.network',
    description:
      'Alkemi Network is bridging CeFi to DeFi, building an institution-grade liquidity network for financial institutions and individuals to access professional DeFi and earn yields on their Ethereum-based digital assets.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/alkemi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'alkemi-network-dao-token',
    cmcId: '11974',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'alkemi/index.js',
    twitter: 'AlkemiNetwork',
    audit_links: ['https://github.com/AlkemiNetwork/alkemi-earn-security-audit'],
    oracles: ['Chainlink'],
    slug: 'alkemi',
    tvl: 13125340.956734868,
    chainTvls: { Ethereum: 13125340.956734868 },
    change_1h: 0.02430326134712857,
    change_1d: 1.0271349329889148,
    change_7d: 5.712509460863487,
    fdv: 17667058,
    mcap: 0,
  },
  {
    id: '1426',
    name: 'MiniPanther DAO',
    address: 'fantom:0x3264810174f577F82DDD4FD08818F368AC363505',
    symbol: 'MP',
    url: 'https://minipanther.money/#/',
    description:
      'MiniPanther is a DAO with only 14 days of supply expansion. On the 15th day, 80% of the treasury will be used for buybacks! After that, MP will become the meme token of the Fantom ecosystem. The DAO also operates PantherUSD, a backed stablecoin with arbitrage-enforced pricing.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/minipanther-dao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'minipanther',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Olympus DAO'],
    module: 'minipanther/index.js',
    twitter: 'PolyWantsAFarm',
    listedAt: 1645133019,
    slug: 'minipanther-dao',
    tvl: 13025076.320688188,
    chainTvls: { 'Fantom-staking': 538485.7885536985, staking: 538485.7885536985, Fantom: 13025076.320688188 },
    change_1h: -0.015923011297786616,
    change_1d: 8.359421698411353,
    change_7d: 43.89671988424834,
    staking: 538485.7885536985,
    mcap: 0,
  },
  {
    id: '423',
    name: 'Deri Protocol',
    address: 'bsc:0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
    symbol: 'DERI',
    url: 'https://deri.finance',
    description:
      'Deri Protocol is a decentralized protocol for users to exchange risk exposures precisely and capital efficiently. It is the DeFi way to trade derivatives: to hedge, to speculate, to arbitrage, all on chain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/deri-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'deri-protocol',
    cmcId: '8424',
    category: 'Derivatives',
    chains: ['Binance', 'Polygon'],
    module: 'deri/index.js',
    twitter: 'DeriProtocol',
    audit_links: ['https://docs.deri.finance/code-audits'],
    oracles: ['Chainlink'],
    slug: 'deri-protocol',
    tvl: 12937402.982674748,
    chainTvls: { Binance: 12687075.270049127, Polygon: 250327.71262562097 },
    change_1h: -0.2598083068345147,
    change_1d: -5.2307400935401915,
    change_7d: -20.968038165957054,
    fdv: 31134667,
    mcap: 8794862,
  },
  {
    id: '845',
    name: 'Axial',
    address: 'avax:0xcF8419A615c57511807236751c0AF38Db4ba3351',
    symbol: 'AXIAL',
    url: 'https://www.axial.exchange',
    description:
      'Axial is creating the new center for liquidity on Avalanche. It is an implementation of the Stableswap mechanism and decentralized governance. As the centerpiece for liquidity of value-pegged assets, bridged and native assets will have a home and route throughout the Avalanche ecosystem. Axial was launched in November of 2021, and is one of many projects originating from the Snowball DAO.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/axial.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'axial/index.js',
    twitter: 'AxialDeFi',
    forkedFrom: ['Saddle Finance'],
    listedAt: 1637031735,
    slug: 'axial',
    tvl: 12848977.654089538,
    chainTvls: { pool2: 348585.54185913503, Avalanche: 12848977.654089538, 'Avalanche-pool2': 348585.54185913503 },
    change_1h: -0.1610945127838761,
    change_1d: 0.12688718419131817,
    change_7d: 0.1395773048151483,
    pool2: 348585.54185913503,
  },
  {
    id: '271',
    name: 'Honeyswap',
    address: 'xdai:0x71850b7e9ee3f13ab46d67167341e4bdc905eef9',
    symbol: 'HNY',
    url: 'https://honeyswap.org/',
    description:
      'Honeyswap is a decentralized exchange built on the xDai Chain, this enables users to experience fast and secure transactions with incredibly low fees. Multiple tokens are available with which you can swap and add liquidity. It is a fork of the well known Uniswap-V2 protocol adapted to fit for the xDai Chain and the 1hive ecosystem.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/honeyswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'honey',
    cmcId: '7972',
    category: 'Dexes',
    chains: ['xDai', 'Polygon'],
    module: 'honeyswap/index.js',
    twitter: 'Honeyswap',
    forkedFrom: ['Uniswap'],
    slug: 'honeyswap',
    tvl: 12486808.765334709,
    chainTvls: { xDai: 12259031.324081918, Polygon: 227777.4412527907 },
    change_1h: -0.024588032478433774,
    change_1d: 1.0559570919447907,
    change_7d: 18.152199144969927,
    mcap: 0,
  },
  {
    id: '408',
    name: 'JetFuel Finance',
    address: 'bsc:0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a',
    symbol: 'FUEL',
    url: 'https://jetfuel.finance',
    description:
      'Jetfuel Finance is an all in one defi protocol with yield optimization at Jetfuel.Finance, credit/lending at Fortress, transactional tax/auto liquidity/passive yield token GFORCE, Automated Market Maker called Jetswap as well as a staking platform.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/jetfuel-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'fuel-token',
    cmcId: '8659',
    category: 'Yield',
    chains: ['Binance'],
    module: 'jetfuelfinance/index.js',
    twitter: 'Jetfuelfinance',
    audit_links: ['https://jetfuel.finance/audits'],
    forkedFrom: ['Uniswap', 'Compound'],
    slug: 'jetfuel-finance',
    tvl: 12433270.039091099,
    chainTvls: { Binance: 12433270.039091099, 'Binance-borrowed': 2380182.691477391, borrowed: 2380182.691477391 },
    change_1h: 0.17074762968307766,
    change_1d: 1.1659182659680312,
    change_7d: 5.365188312523486,
    mcap: 4271958,
  },
  {
    id: '451',
    name: 'Karura Swap',
    address: '-',
    symbol: '-',
    url: 'https://acala.network/karura',
    description:
      'Karura Swap is a trustless, automated market maker (AMM)-styled decentralized exchange on the Karura network.',
    chain: 'Karura',
    logo: 'https://icons.llama.fi/karura-swap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Karura'],
    module: 'karura-dex/index.js',
    twitter: 'KaruraNetwork',
    slug: 'karura-swap',
    tvl: 12258928.15686322,
    chainTvls: { Kusuma: 12258928.15686322, Karura: 12258928.15686322 },
    change_1h: -0.05315371280015313,
    change_1d: 0.2490821002325987,
    change_7d: 8.701486621275322,
  },
  {
    id: '833',
    name: 'ZoomSwap',
    address: 'iotex:0xf87aed04889a1dd0159d9C22B0D57b345Ab16dDD',
    symbol: 'ZM',
    url: 'https://zoomswap.io',
    description: 'Yield farming AMM in IoTeX!',
    chain: 'IoTeX',
    logo: 'https://icons.llama.fi/zoomswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['IoTeX'],
    module: 'zoomswap/index.js',
    twitter: 'zoomswap_io',
    audit_links: ['https://rugdoc.io/project/zoomswap'],
    forkedFrom: ['Goose Finance'],
    listedAt: 1636756297,
    slug: 'zoomswap',
    tvl: 12191824.19,
    chainTvls: { IoTeX: 12191824.19 },
    change_1h: 0.01898632536072853,
    change_1d: 1.0931352877429958,
    change_7d: 1.2133003951680905,
  },
  {
    id: '377',
    name: 'Ethernity Chain',
    address: '0xbbc2ae13b23d715c30720f079fcd9b4a74093505',
    symbol: 'ERN',
    url: 'https://ethernity.io/',
    description:
      'A community-oriented platform that produces limited edition authenticated NFTs and trading cards created by your favorite artists and endorsed by notable figures.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ethernity-chain.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'ethernity-chain',
    cmcId: '8615',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'ethernity/index.js',
    twitter: 'EthernityChain',
    oracles: ['Chainlink'],
    slug: 'ethernity-chain',
    tvl: 12142010.119258143,
    chainTvls: { Ethereum: 12142010.119258143 },
    change_1h: -1.6671372939164542,
    change_1d: 1.369063165049809,
    change_7d: 14.2045176415225,
    fdv: 189574223,
    mcap: 84840023,
  },
  {
    id: '596',
    name: 'Flux Protocol',
    address: 'okex:0xd0C6821aba4FCC65e8f1542589e64BAe9dE11228',
    symbol: 'FLUX',
    url: 'https://flux.01.finance',
    description:
      'Flux Protocol is a borderless lending protocol, where low transaction fees and cross-chain interoperability are reality!',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/flux-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'flux-protocol',
    cmcId: '9837',
    category: 'Lending',
    chains: ['OKExChain', 'Binance', 'Conflux', 'Heco', 'Polygon', 'Ethereum', 'Arbitrum'],
    module: 'flux/index.js',
    twitter: 'zero1_flux',
    audit_links: ['https://certik.org/projects/flux'],
    oracles: ['Chainlink', 'TWAP'],
    slug: 'flux-protocol',
    tvl: 11992650.96452776,
    chainTvls: {
      'OKExChain-staking': 61401.895314014466,
      OKExChain: 8657122.731012832,
      'Arbitrum-staking': 167.86618292671503,
      'Heco-staking': 10397.193713680905,
      Binance: 1316955.1571671942,
      Ethereum: 88519.73429878156,
      'Binance-staking': 17756.0540808579,
      Heco: 327640.20513375837,
      'Ethereum-staking': 0,
      Polygon: 317008.97085073934,
      Conflux: 1239026.6697092145,
      'Polygon-staking': 24.268930634260002,
      Arbitrum: 46377.49635523978,
      'Conflux-staking': 19167.793800613887,
      staking: 108915.07202272813,
    },
    change_1h: -0.00034813449805426444,
    change_1d: 3.3304341873737826,
    change_7d: -22.542197304334806,
    staking: 108915.07202272813,
    mcap: 0,
  },
  {
    id: '930',
    name: 'Maximizer',
    address: 'avax:0x7C08413cbf02202a1c13643dB173f2694e0F73f0',
    symbol: 'MAXI',
    url: 'https://maxi.xyz',
    description: 'Maximizer is a decentralized reserve currency for Avalanche that remains unbound by a peg.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/maximizer.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'maximizer',
    cmcId: '15243',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'maximizer/index.js',
    twitter: 'maximizer_xyz',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638133490,
    slug: 'maximizer',
    tvl: 11914039.094327189,
    chainTvls: { 'Avalanche-staking': 2827672.9474618337, Avalanche: 11914039.094327189, staking: 2827672.9474618337 },
    change_1h: 0.5747628197542411,
    change_1d: 1.2027102914626226,
    change_7d: 1.8811481977852225,
    staking: 2827672.9474618337,
    mcap: 4085262,
  },
  {
    id: '748',
    name: 'MistSwap',
    address: 'smartbch:0x5fA664f69c2A4A3ec94FaC3cBf7049BD9CA73129',
    symbol: 'MIST',
    url: 'https://mistswap.fi',
    description: 'Trade, launch, stake, farm, invest, automate, build on the premier DeFi platform of smartBCH',
    chain: 'smartBCH',
    logo: 'https://icons.llama.fi/mistswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['smartBCH'],
    module: 'mistswap/index.js',
    twitter: 'mistswapdex',
    audit_links: ['https://docs.mistswap.fi/MistSwap-0xguard-audit.pdf'],
    forkedFrom: ['Uniswap'],
    listedAt: 1635846048,
    slug: 'mistswap',
    tvl: 11550523.145018164,
    chainTvls: { 'smartBCH-staking': 1662559.053449107, smartBCH: 11550523.145018164, staking: 1662559.053449107 },
    change_1h: 0.6285165359649341,
    change_1d: 0.06833474388785987,
    change_7d: null,
    staking: 1662559.053449107,
  },
  {
    id: '955',
    name: 'Houses of Rome',
    address: 'moonriver:0x4a436073552044d5f2f49b176853ad3ad473d9d6',
    symbol: 'ROME',
    url: 'https://romedao.finance',
    description: 'Assembling the great houses to build the reserve currency of Kusama & Polkadot.',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/houses-of-rome.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'rome',
    cmcId: '15398',
    category: 'Reserve Currency',
    chains: ['Moonriver'],
    module: 'rome-dao/index.js',
    twitter: 'romedaofinance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638412597,
    slug: 'houses-of-rome',
    tvl: 11461950.057169419,
    chainTvls: { 'Moonriver-staking': 18197953.593874764, Moonriver: 11461950.057169419, staking: 18197953.593874764 },
    change_1h: 0.22721868952861257,
    change_1d: -0.6039527892796883,
    change_7d: 0.07995918239660682,
    staking: 18197953.593874764,
    mcap: 21181071,
  },
  {
    id: '210',
    name: 'yAxis',
    address: '0x0ada190c81b814548ddc2f6adc4a689ce7c1fe73',
    symbol: 'YAXIS',
    url: 'https://www.yaxis.io',
    description:
      'yAxis v3 is a meta yield aggregator allowing users to earn interest on digital assets like ETH, stablecoins, LINK and BTC through the Canonical Vaults. Governance allows YAXIS holders to boost their personal yield and vote to further increase vault yields',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/yaxis.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'yaxis',
    cmcId: '7222',
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'yaxis/index.js',
    twitter: 'yaxis_project',
    audit_links: ['https://github.com/yaxis-project/metavault/tree/main/audits'],
    slug: 'yaxis',
    tvl: 11445966.080034949,
    chainTvls: {
      Ethereum: 11445966.080034949,
      pool2: 676837.0965832097,
      'Ethereum-staking': 633931.498702676,
      staking: 633931.498702676,
      'Ethereum-pool2': 676837.0965832097,
    },
    change_1h: 0.3726676128945172,
    change_1d: 0.5873230860426162,
    change_7d: 1.7036171635209598,
    staking: 633931.498702676,
    pool2: 676837.0965832097,
    mcap: 214359,
  },
  {
    id: '980',
    name: 'WannaSwap',
    address: '0x7faA64Faf54750a2E3eE621166635fEAF406Ab22',
    symbol: 'WANNA',
    url: 'https://wannaswap.finance',
    description: "WannaSwap is the Liquidity Central built on NEAR's Aurora EVM",
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/wannaswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wannaswap',
    cmcId: null,
    category: 'Dexes',
    chains: ['Aurora'],
    module: 'wannaswap/index.js',
    twitter: 'wannaswapamm',
    oracles: ['Chainlink'],
    forkedFrom: ['Uniswap'],
    audit_links: ['https://www.certik.com/projects/wannaswap'],
    listedAt: 1638702808,
    slug: 'wannaswap',
    tvl: 11258670.969567621,
    chainTvls: { 'Aurora-staking': 453361.42997846176, Aurora: 11258670.969567621, staking: 453361.42997846176 },
    change_1h: 0.6139679075610474,
    change_1d: 3.4865432587129845,
    change_7d: 5.382958064035861,
    staking: 453361.42997846176,
    fdv: 5899757,
    mcap: 1247394,
  },
  {
    id: '1531',
    name: '01',
    address: null,
    symbol: '-',
    url: 'https://01.xyz/',
    description:
      'Fully decentralized orderbook based derivatives dex allowing users to trade, borrow & lend their crypto assets.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/01.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Solana'],
    oracles: ['Pyth'],
    forkedFrom: [],
    module: '01/index.js',
    twitter: '01_exchange',
    listedAt: 1646949250,
    slug: '01',
    tvl: 11083732.212243497,
    chainTvls: { Solana: 11083732.212243497 },
    change_1h: -0.07941357015607764,
    change_1d: 0.5451415767486054,
    change_7d: 1.1834415662683568,
  },
  {
    id: '1408',
    name: 'Drachma',
    address: 'metis:0xDb3434513433ee887a299ebB3a4a76d6AC35cFa7',
    symbol: 'DRACHMA',
    url: 'https://app.drachmadefi.io/#/',
    description:
      'The Stable Swap owned by the Metis Community. Drachma offers Curve style hyper efficient, low slippage swaps for both pegged and unpegged assets',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/drachma.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Metis'],
    oracles: [],
    forkedFrom: ['DFX Finance'],
    module: 'drachma/index.js',
    twitter: 'drachmadefi',
    audit_links: ['https://drachma-defi.notion.site/Contracts-Audits-1ef408de14fa4c619feddb99abbddffc'],
    listedAt: 1644868232,
    slug: 'drachma',
    tvl: 10705557.293833919,
    chainTvls: { Metis: 10705557.293833919, 'Metis-borrowed': 0, borrowed: 0 },
    change_1h: -0.19196608657769332,
    change_1d: -1.7581009933713716,
    change_7d: -11.778079635770439,
  },
  {
    id: '1550',
    name: 'METF Finance',
    address: 'cronos:0xb8df27c687c6af9afe845a2afad2d01e199f4878',
    symbol: 'METF',
    url: 'https://metf.finance/#/',
    description:
      'MM ETF is the first decentralized ETF protocol that runs on METF token. The DTF term is thus coined by our team as the DEX Traded Fund, being the first of its kind.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/metf-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'mad-meerkat-etf',
    cmcId: null,
    category: 'Yield',
    chains: ['Cronos'],
    oracles: [],
    forkedFrom: [],
    module: 'metf-finance/index.js',
    twitter: 'MMFcrypto',
    listedAt: 1647390824,
    slug: 'metf-finance',
    tvl: 10594576.436420364,
    chainTvls: {
      Cronos: 10594576.436420364,
      pool2: 6160651.981304696,
      'Cronos-pool2': 6160651.981304696,
      'Cronos-staking': 12071241.380360082,
      staking: 12071241.380360082,
    },
    change_1h: -4.1283206148515035,
    change_1d: 6.922286766638791,
    change_7d: 79.34906065456423,
    staking: 12071241.380360082,
    pool2: 6160651.981304696,
    mcap: 19607361,
  },
  {
    id: '1433',
    name: 'Jones DAO',
    address: 'arbitrum:0x10393c20975cf177a3513071bc110f7962cd67da',
    symbol: 'JONES',
    url: 'https://jonesdao.io',
    description:
      'Jones DAO is a yield, strategy, and liquidity protocol for options, with vaults that enable 1-click access to institutional-grade options strategies while unlocking liquidity and capital efficiency for DeFi options with yield-bearing options-backed asset tokens.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/jones-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'jones-dao',
    cmcId: '17743',
    category: 'Options',
    chains: ['Arbitrum'],
    oracles: [],
    forkedFrom: [],
    module: 'jones-dao/index.js',
    twitter: 'DAOJonesOptions',
    listedAt: 1645235945,
    slug: 'jones-dao',
    tvl: 10532377.910321914,
    chainTvls: {
      pool2: 19286261.472881246,
      'Arbitrum-staking': 16959998.901772104,
      Arbitrum: 10532377.910321914,
      'Arbitrum-pool2': 19286261.472881246,
      staking: 16959998.901772104,
    },
    change_1h: 0,
    change_1d: 6.397278393561564,
    change_7d: -5.839905912768387,
    staking: 16959998.901772104,
    pool2: 19286261.472881246,
    fdv: 100257174,
    mcap: 11607382,
  },
  {
    id: '272',
    name: 'Cryptex Finance',
    address: '0x321C2fE4446C7c963dc41Dd58879AF648838f98D',
    symbol: 'CTX',
    url: 'https://cryptex.finance/',
    description:
      'Cryptex is focused on building innovative, open-source financial solutions for the global Crypto community. Using Ethereum’s smart contract system, Cryptex is able to create decentralized financial solutions such as Total Crypto Market Cap Token, TCAP and many others.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cryptex-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cryptex-finance',
    cmcId: '10368 ',
    category: 'Indexes',
    chains: ['Ethereum', 'Optimism'],
    module: 'cryptex-finance/index.js',
    twitter: 'CryptexFinance',
    audit_links: [
      'https://cryptex.finance/Cryptex_-_Final_Report.pdf',
      'https://certificate.quantstamp.com/full/cryptex',
    ],
    oracles: ['Chainlink'],
    slug: 'cryptex-finance',
    tvl: 10229301.766811706,
    chainTvls: {
      Optimism: 1044.5840514274173,
      pool2: 8969249.547753185,
      'Ethereum-pool2': 8969249.547753185,
      'Ethereum-Treasury': 37156431.2697803,
      Ethereum: 10228257.18276028,
      'Ethereum-staking': 113677.50098825264,
      Treasury: 37156431.2697803,
      staking: 113677.50098825264,
    },
    change_1h: 0.6206052119021876,
    change_1d: 4.012575486973532,
    change_7d: 15.288135521067872,
    staking: 113677.50098825264,
    pool2: 8969249.547753185,
    fdv: 73103110,
    mcap: 21332728,
  },
  {
    id: '817',
    name: 'CashCow Finance',
    address: 'bsc:0xf823f18d13df1ffdced206708d389dd455bb802b',
    symbol: 'CCF',
    url: 'https://cashcow.finance',
    description:
      'CashCow Finance is an all-in-one DeFi platform designed to allow users to deposit, borrow, and earn on leverage without having to hop from platform to platform.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/cashcow-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cashcow-finance',
    cmcId: null,
    category: 'Lending',
    chains: ['Binance'],
    module: 'cashcow/index.js',
    twitter: 'FinanceCow',
    audit_links: ['https://docs.cashcow.finance/cash-cow-audit-report'],
    listedAt: 1636444434,
    forkedFrom: ['Compound'],
    slug: 'cashcow-finance',
    tvl: 10221111.728996051,
    chainTvls: {
      Binance: 10221111.728996051,
      'Binance-staking': 18130.96232023867,
      'Binance-borrowed': 91518.24328596283,
      borrowed: 91518.24328596283,
      staking: 18130.96232023867,
    },
    change_1h: 0.5425552554120259,
    change_1d: 1.3539225454677108,
    change_7d: 11.93259627835657,
    staking: 18130.96232023867,
    mcap: 0,
  },
  {
    id: '1056',
    name: 'Redacted Cartel',
    address: '0xc0d4ceb216b3ba9c3701b291766fdcba977cec3a',
    symbol: 'BTRFLY',
    url: 'https://www.redactedcartel.xyz',
    description:
      'REDACTED Cartel is an initiative using the POL mechanics of OlympusDAO to accumulate as much liquidity as possible from the Curve ecosystem in order to have a majority say over the Curve gauge.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/redacted-cartel.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'butterflydao',
    cmcId: null,
    category: 'Services',
    chains: ['Ethereum'],
    module: 'redacted/index.js',
    twitter: 'redactedcartel',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639972343,
    slug: 'redacted-cartel',
    tvl: 10220498.296790583,
    chainTvls: { Ethereum: 10220498.296790583, 'Ethereum-staking': 148027171.87816963, staking: 148027171.87816963 },
    change_1h: 0.3422840928352713,
    change_1d: -2.8296640199206706,
    change_7d: null,
    staking: 148027171.87816963,
    fdv: 168039548,
    mcap: 166196603,
  },
  {
    id: '1451',
    name: 'Chintai',
    address: 'eos:CHEX-eos-chexchexchex',
    symbol: 'CHEX',
    url: 'https://chintai.io',
    description:
      'A comprehensive blockchain solution that modernizes capital markets for asset managers, banks and enterprise.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/chintai.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'chex-token',
    cmcId: '8534',
    category: 'Lending',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'chintai/index.js',
    twitter: 'ChintaiNetwork',
    language: 'C++',
    listedAt: 1645578660,
    slug: 'chintai',
    tvl: 10212851.416226443,
    chainTvls: { EOS: 10212851.416226443 },
    change_1h: -0.6299674751667226,
    change_1d: 0.9991505271413246,
    change_7d: -10.694017025220589,
    mcap: 0,
  },
  {
    id: '227',
    name: 'CreamSwap',
    address: '0x2ba592f78db6436527729929aaf6c908497cb200',
    symbol: 'CREAM',
    url: 'https://app.cream.finance/swap',
    description:
      'C.R.E.A.M. Finance Swap is a blockchain agnostic, dimensional market-maker built on Ethereum. It allows user to provide liquidity to pools and earn trading fees/reserves.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/creamswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'creamswap/index.js',
    twitter: 'CreamdotFinance',
    audit_links: ['https://github.com/CreamFi/compound-protocol/blob/master/audits/trailofbits-CREAMSummary.pdf'],
    forkedFrom: ['Balancer'],
    slug: 'creamswap',
    tvl: 10058083.842844743,
    chainTvls: { Ethereum: 10058083.842844743 },
    change_1h: 0.6082862488313339,
    change_1d: 3.613153488307546,
    change_7d: 14.187998432072945,
  },
  {
    id: '1538',
    name: 'Risk Harbor',
    address: null,
    symbol: '-',
    url: 'https://www.riskharbor.com/',
    description:
      'Risk Harbor is a risk management marketplace for decentralized finance (DeFi) that utilizes a completely automated, transparent, and impartial invariant detection mechanism to secure liquidity providers and stakers against smart contract risks, hacks, and attacks.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/risk-harbor.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Insurance',
    chains: ['Terra'],
    oracles: [],
    forkedFrom: [],
    module: 'risk-harbor-ozone/index.js',
    twitter: 'riskharbor',
    audit_links: ['https://docs.riskharbor.com/contracts/security-audits'],
    listedAt: 1647046245,
    slug: 'risk-harbor',
    tvl: 10057434.033805158,
    chainTvls: { Terra: 10057434.033805158 },
    change_1h: 0.09999999999999432,
    change_1d: 0.24927116476432332,
    change_7d: -0.09980039920161232,
  },
  {
    id: '131',
    name: 'BarnBridge',
    address: '0x0391D2021f89DC339F60Fff84546EA23E337750f',
    symbol: 'BOND',
    url: 'https://barnbridge.com/',
    description: 'A fluctuations derivatives protocol for hedging yield sensitivity and market price.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/barnbridge.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'barnbridge',
    cmcId: '7440',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Polygon', 'Avalanche'],
    module: 'barnbridge/index.js',
    twitter: 'Barn_Bridge',
    audit_links: ['https://docs.barnbridge.com/beginners-guide-to-smart-yield#is-it-secure-to-invest-in-smart-yield'],
    oracles: ['Chainlink'],
    slug: 'barnbridge',
    tvl: 10034227.668719169,
    chainTvls: { Ethereum: 9956768.72777221, Polygon: 62588.95133375937, Avalanche: 14869.98961319892 },
    change_1h: 0.34940209333937844,
    change_1d: -5.478244435378102,
    change_7d: -2.4766613457963444,
    fdv: 93317024,
    mcap: 59585466,
  },
  {
    id: '1296',
    name: 'ZipSwap',
    address: 'optimism:0xFA436399d0458Dbe8aB890c3441256E3E09022a8',
    symbol: 'ZIP',
    url: 'https://zipswap.fi/#/',
    description:
      'Swap, earn, and build on the first optimistic rollup optimized decentralized crypto trading protocol.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/zipswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Optimism', 'Arbitrum'],
    forkedFrom: ['Uniswap'],
    module: 'zipswap/index.js',
    twitter: 'Zip_swap',
    listedAt: 1642724011,
    slug: 'zipswap',
    tvl: 10006871.810181689,
    chainTvls: { Optimism: 10005689.292590443, Arbitrum: 1182.5175912455431 },
    change_1h: 0,
    change_1d: -11.777289659104966,
    change_7d: -4.469031964255393,
  },
  {
    id: '604',
    name: 'Sherlock',
    address: '-',
    symbol: '-',
    url: 'https://sherlock.xyz',
    description:
      'Sherlock is a risk management protocol whose mission is to make DeFi safe for the masses. Sherlock combines protocol-to-protocol coverage with a decentralized smart contract security team to bring large amounts of affordable coverage to DeFi and beyond',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/sherlock.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Insurance',
    oracles: ['UMA'],
    chains: ['Ethereum'],
    module: 'sherlock/index.js',
    twitter: 'Sherlock_DeFi',
    audit_links: [
      'https://certificate.quantstamp.com/full/sherlock',
      'https://code423n4.com/reports/2021-07-sherlock/',
    ],
    slug: 'sherlock',
    tvl: 9484906.845935943,
    chainTvls: { Ethereum: 9484906.845935943 },
    change_1h: -0.11151103927630857,
    change_1d: -9.65065401919722,
    change_7d: -13.93435180875953,
  },
  {
    id: '480',
    name: 'KuSwap',
    address: 'kucoin:0x4a81704d8c16d9fb0d7f61b747d0b5a272badf14',
    symbol: 'KUS',
    url: 'https://kuswap.finance',
    description:
      'KuSwap is the first decentralized trading platform on the KCC network to offer the lowest platform transaction fees (0.1 percent), with fees refunded in KUS, our native currency.',
    chain: 'Kucoin',
    logo: 'https://icons.llama.fi/kuswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'kuswap',
    cmcId: '1420',
    category: 'Dexes',
    chains: ['Kucoin'],
    module: 'kuswap/index.js',
    twitter: 'kuswapfinance',
    forkedFrom: ['Uniswap'],
    slug: 'kuswap',
    tvl: 9418536.963420141,
    chainTvls: { Kucoin: 9418536.963420141 },
    change_1h: 3.220445763128126,
    change_1d: 5.503334051549814,
    change_7d: 18.78978737450103,
    mcap: 5396044,
  },
  {
    id: '1295',
    name: 'BurgerSwap',
    address: 'bsc:0xae9269f27437f0fcbc232d39ec814844a51d6b8f',
    symbol: 'BURGER',
    url: 'https://burgerswap.org/trade/swap',
    description:
      'BurgerSwap is the first cross-chain aggregator combining rates and prices from the leading DEXs and CEXs; By combining the switch protocol, BurgerSwap, has integrated CEXs, DEXs, and DeFi deals into a single platform',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/burgerswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'burger-swap',
    cmcId: '7158',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'burgerswap/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'burger_swap',
    listedAt: 1642723859,
    slug: 'burgerswap',
    tvl: 9392750.314426366,
    chainTvls: { Binance: 9392750.314426366, 'Binance-staking': 166230.2090914515, staking: 166230.2090914515 },
    change_1h: -0.04988153296292808,
    change_1d: 1.1451213951274752,
    change_7d: 5.898196025971728,
    staking: 166230.2090914515,
    fdv: 34943630,
    mcap: 0,
  },
  {
    id: '828',
    name: 'Crodex',
    address: 'cronos:0xe243CCab9E66E6cF1215376980811ddf1eb7F689',
    symbol: 'CRX',
    url: 'https://swap.crodex.app',
    description:
      'Crodex is a decentralized exchange (DEX), providing liquidity and enabling peer-to-peer transactions on Cronos.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/crodex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'crodex/index.js',
    twitter: 'crodexapp',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://hacken.io/audits/#crodex'],
    listedAt: 1636671573,
    slug: 'crodex',
    tvl: 9392188.7319998,
    chainTvls: { Cronos: 9392188.7319998 },
    change_1h: -0.25294455957839546,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '138',
    name: 'Hakka Finance',
    address: '0x0E29e5AbbB5FD88e28b2d355774e73BD47dE3bcd',
    symbol: 'HAKKA',
    url: 'https://hakka.finance/',
    description: 'Hakka Decentralized Finance Ecosystem Warped Spacetime with Crypto Native Primitives.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/hakka-finance.png',
    audits: null,
    audit_note: null,
    gecko_id: 'hakka-finance',
    cmcId: '6622',
    category: 'Derivatives',
    chains: ['Ethereum'],
    module: 'hakka.js',
    twitter: 'hakkafinance',
    audit_links: ['https://github.com/hakkafinance/audit-reports'],
    oracles: ['Chainlink'],
    slug: 'hakka-finance',
    tvl: 9368139,
    chainTvls: { Ethereum: 9368139 },
    change_1h: 1.0591964230235504,
    change_1d: 2.5512754242492406,
    change_7d: 11.465047575998398,
    fdv: 3974552,
    mcap: 1827889,
  },
  {
    id: '1108',
    name: 'Gearbox',
    address: '0xba3335588d9403515223f109edc4eb7269a9ab5d',
    symbol: 'GEAR',
    url: 'https://gearbox.fi',
    description:
      'Get up to 10x leverage and use it across DeFi protocols. Gearbox Protocol allows anyone to get leverage in a decentralized way and use it across various other protocols in a composable way: margin trading, leverage farming, and more!',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/gearbox.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'gearbox',
    cmcId: null,
    category: 'Indexes',
    chains: ['Ethereum'],
    oracles: ['Chainlink'],
    module: 'gearbox/index.js',
    twitter: 'GearboxProtocol',
    audit_links: ['https://github.com/Gearbox-protocol/gearbox-contracts/tree/master/audits'],
    listedAt: 1640629561,
    slug: 'gearbox',
    tvl: 9365602.958161846,
    chainTvls: { Ethereum: 9365602.958161846 },
    change_1h: -0.0010307419483126523,
    change_1d: 1.1790451740234715,
    change_7d: 5.092473521476165,
  },
  {
    id: '365',
    name: 'Charm Finance',
    address: '-',
    symbol: '-',
    url: 'https://charm.fi/',
    description: 'An ecosystem of innovative products within DeFi',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/charm-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'charmfinance/index.js',
    twitter: 'CharmFinance',
    audit_links: [
      'https://github.com/solidified-platform/audits/blob/master/Audit%20Report%20-%20Charm%20Finance%20%5B27.10.2020%5D.pdf',
    ],
    slug: 'charm-finance',
    tvl: 9346061.660272678,
    chainTvls: { Ethereum: 9346061.660272678 },
    change_1h: -0.001085238078275097,
    change_1d: 0.8209043860557159,
    change_7d: 3.8611343475832456,
  },
  {
    id: '632',
    name: 'FarmersOnly',
    address: 'harmony:0x0159ed2e06ddcd46a25e74eb8e159ce666b28687',
    symbol: 'FOX',
    url: 'https://app.farmersonly.fi',
    description: 'Harmony yield farm and yield aggregator with auto-compounding vaults.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/farmersonly.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'farmers-only',
    cmcId: null,
    category: 'Yield',
    chains: ['Harmony'],
    module: 'farmersonly/index.js',
    twitter: 'FarmersOnlyFi',
    audit_links: ['https://rugdoc.io/project/farmersonly/'],
    slug: 'farmersonly',
    tvl: 9328553.454064159,
    chainTvls: {
      'Harmony-pool2': 1338025.4008590379,
      pool2: 1338025.4008590379,
      'Harmony-staking': 204662.9282881638,
      Harmony: 9328553.454064159,
      staking: 204662.9282881638,
    },
    change_1h: 0,
    change_1d: 0.09992710728046461,
    change_7d: -0.22694610778442836,
    staking: 204662.9282881638,
    pool2: 1338025.4008590379,
    mcap: 0,
  },
  {
    id: '1505',
    name: 'Floor Dao',
    address: '0xf59257E961883636290411c11ec5Ae622d19455e',
    symbol: 'FLOOR',
    url: 'https://floor.xyz/',
    description: 'Deep liquidity and High Yield strategies for NFTs',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/floor-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'floordao',
    cmcId: '18440',
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    oracles: [],
    forkedFrom: ['Olympus DAO'],
    module: 'floor-dao/index.js',
    twitter: 'FloorDAO',
    listedAt: 1646390885,
    slug: 'floor-dao',
    tvl: 9303481.01255384,
    chainTvls: { Ethereum: 9303481.01255384, 'Ethereum-staking': 28161187.36366997, staking: 28161187.36366997 },
    change_1h: 0.5269236866349587,
    change_1d: 4.833763693385265,
    change_7d: 12.093245898828968,
    staking: 28161187.36366997,
    fdv: 40728622,
    mcap: 33488565,
  },
  {
    id: '971',
    name: 'NFTb',
    address: 'bsc:0xde3dbbe30cfa9f437b293294d1fd64b26045c71a',
    symbol: 'NFTB',
    url: 'https://nftb.io',
    description:
      'Complete Multi-Chain NFT & DeFi Platform for Communities: Premium NFT Marketplace, Farming/Staking, Launchpad',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/nftb.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'nftb',
    cmcId: '9545',
    category: 'Yield',
    chains: ['Binance'],
    module: 'nftb/index.js',
    twitter: 'nftbmarket',
    audit_links: [
      'https://nftb.io/[NFTB_07072021]_SC_Audit_Report.pdf',
      'https://blog.nftb.io/audit-results-hacken-cd636cb01feb',
    ],
    listedAt: 1638580947,
    slug: 'nftb',
    tvl: 9268871.406796938,
    chainTvls: { Binance: 9268871.406796938 },
    change_1h: -0.09079575379385574,
    change_1d: -4.142660112372553,
    change_7d: 28.92037279741058,
    fdv: 116132919,
    mcap: 13776933,
  },
  {
    id: '1172',
    name: 'PsyOptions',
    address: 'solana:PsyFiqqjiv41G7o5SMRzDJCu4psptThNR2GtfeGHfSq',
    symbol: 'PSY',
    url: 'https://www.psyoptions.io',
    description: 'Trade on-chain, asset settled, American style options for $BTC & $ETH',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/psyoptions.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'psyoptions',
    cmcId: '16896',
    category: 'Options',
    chains: ['Solana'],
    module: 'psyoptions/index.js',
    twitter: 'PsyOptions',
    listedAt: 1641069495,
    slug: 'psyoptions',
    tvl: 9193958.938041136,
    chainTvls: { Solana: 9193958.938041136 },
    change_1h: -0.15037781119447402,
    change_1d: 2.7059315323113964,
    change_7d: 110.32032177274814,
    mcap: 0,
  },
  {
    id: '1404',
    name: 'GotchiVault',
    address: 'polygon:0x51195e21BDaE8722B29919db56d95Ef51FaecA6C',
    symbol: 'VGHST',
    url: 'https://www.gotchivault.com',
    description:
      'Gotchi Vault allows users to deposit their GHST and Aavegotchis into the Vault smart contracts to be collectively managed.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/gotchivault.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Polygon'],
    oracles: [],
    forkedFrom: ['QiDao'],
    module: 'GotchiVault/index.js',
    twitter: 'GotchiVault',
    language: 'Solidity',
    listedAt: 1644782258,
    slug: 'gotchivault',
    tvl: 9111334.42450865,
    chainTvls: { Polygon: 9111334.42450865 },
    change_1h: 1.1937850449005083,
    change_1d: 2.998575755774908,
    change_7d: 25.182162834120717,
  },
  {
    id: '191',
    name: 'Opium',
    address: '0x888888888889c00c67689029d7856aac1065ec11',
    symbol: 'OPIUM',
    url: 'https://www.opium.network/',
    description:
      'Opium is a universal and robust protocol\r\nthat allows for creating, settling, and trading any decentralised derivative.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/opium.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'opium',
    cmcId: '7230',
    category: 'Options',
    chains: ['Polygon', 'Ethereum', 'Binance', 'Arbitrum'],
    module: 'opium.js',
    twitter: 'Opium_Network',
    audit_links: ['https://blog.smartdec.net/opium-smart-contracts-security-analysis-4c1857cfd93f'],
    slug: 'opium',
    tvl: 9102227.994040642,
    chainTvls: {
      Binance: 906.8623789769574,
      Ethereum: 4281899.774187035,
      Polygon: 4819419.957638429,
      Arbitrum: 1.3998362,
    },
    change_1h: 1.5458303375977493,
    change_1d: 3.5678696649527097,
    change_7d: 13.820313882781349,
    fdv: 56301092,
    mcap: 4640716,
  },
  {
    id: '176',
    name: 'Mushrooms Finance',
    address: '0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304',
    symbol: 'MM',
    url: 'https://www.mushrooms.finance/',
    description:
      'Mushrooms Finance is yet another crypto earning vault with focus on seeking sustainable profit in DeFi universe',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mushroom-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'mm-token',
    cmcId: '7875',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Fantom', 'Binance', 'Polygon'],
    module: 'mushrooms.js',
    twitter: 'MushroomsFinan1',
    audit_links: ['https://github.com/mushroomsforest/deployment/blob/main/security.md'],
    forkedFrom: ['Yearn Finance'],
    oracles: ['Chainlink'],
    slug: 'mushrooms-finance',
    tvl: 9042280.113213412,
    chainTvls: {
      Binance: 62597.45983811917,
      Ethereum: 8782596.219441183,
      Polygon: 24949.768169790485,
      Fantom: 172136.66576432026,
    },
    change_1h: -0.24489998542028957,
    change_1d: 3.3314432200094473,
    change_7d: 15.72103076108948,
    fdv: 1353464,
    mcap: 781661,
  },
  {
    id: '236',
    name: 'Perlin',
    address: '0xeca82185adce47f39c684352b0439f030f860318',
    symbol: 'PERL',
    url: 'https://perlinx.finance/',
    description:
      'Democratizing the trading of real-world assets through decentralized liquidity pools and synthetic asset generation\r\n\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/perlin.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'perlin',
    cmcId: '4293',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'perlinx/index.js',
    twitter: 'PerlinNetwork',
    audit_links: ['https://perlinx.finance/risks'],
    slug: 'perlin',
    tvl: 8977070.117060972,
    chainTvls: { Ethereum: 8977070.117060972 },
    change_1h: 1.027454013146965,
    change_1d: 1.215687721369548,
    change_7d: 14.729602232236942,
    mcap: 0,
  },
  {
    id: '1250',
    name: 'Llama Airforce',
    address: null,
    symbol: '-',
    url: 'https://llama.airforce',
    description: 'Airdropping knowledge bombs about the Curve ecosystem.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/llama-airforce.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'llama-airforce/index.js',
    twitter: '0xAlunara',
    listedAt: 1642057702,
    slug: 'llama-airforce',
    tvl: 8949919.004917141,
    chainTvls: { Ethereum: 8949919.004917141 },
    change_1h: 0.6787924605047095,
    change_1d: 3.372374499177127,
    change_7d: 71.2178967707712,
  },
  {
    id: '1447',
    name: 'Hashflow',
    address: null,
    symbol: '-',
    url: 'https://www.hashflow.com',
    description: 'The most powerful DeFi trading experience. Tight spreads. Zero slippage. MEV-resistant',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/hashflow.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum', 'Binance', 'Arbitrum', 'Avalanche', 'Polygon'],
    oracles: [],
    forkedFrom: [],
    module: 'hashflow/index.js',
    twitter: 'hashflownetwork',
    listedAt: 1645555257,
    slug: 'hashflow',
    tvl: 8887838.785448126,
    chainTvls: {
      Binance: 1617353.665886759,
      Ethereum: 3842629.746435415,
      Polygon: 965818.5334715495,
      Avalanche: 1191000.7894974756,
      Arbitrum: 1271036.0501569286,
    },
    change_1h: -0.196799653788986,
    change_1d: -3.4111882027717257,
    change_7d: -6.003223148768868,
  },
  {
    id: '247',
    name: 'Jarvis Network',
    address: '0x8a9c67fee641579deba04928c4bc45f66e26343a ',
    symbol: 'JRT',
    url: 'https://jarvis.network/',
    description:
      'Jarvis Network is a set of protocols to gain exposure to the price of any asset against liquidity pools.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/jarvis-network.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'jarvis-reward-token',
    cmcId: '5187',
    category: 'Derivatives',
    chains: ['Polygon', 'Binance', 'Ethereum', 'xDai'],
    module: 'jarvis/index.js',
    twitter: 'Jarvis_Network',
    audit_links: ['https://drive.google.com/file/d/1PdmrOKDV-udArVJu6E2rKUsCm9gPuN2p/view'],
    oracles: ['Chainlink', 'UMA'],
    slug: 'jarvis-network',
    tvl: 8694918.11029729,
    chainTvls: {
      Binance: 285230.61439695366,
      Ethereum: 100259.98287638395,
      xDai: 20648.148318,
      Polygon: 8288779.364705952,
    },
    change_1h: -0.7866890753058158,
    change_1d: -3.186629326968017,
    change_7d: -1.3206562004741187,
    fdv: 22928965,
    mcap: 8667421,
  },
  {
    id: '226',
    name: 'Erasure',
    address: '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671',
    symbol: 'NMR',
    url: 'https://erasure.world/',
    description: 'A new kind of hedge fund built by a network of data scientists',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/erasure.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'numeraire',
    cmcId: '1732',
    category: 'Derivatives',
    chains: ['Ethereum'],
    module: 'erasure/index.js',
    twitter: 'numerai',
    audit_links: ['https://github.com/erasureprotocol/NMR/tree/master/audits'],
    slug: 'erasure',
    tvl: 8661447.640130287,
    chainTvls: { Ethereum: 8661447.640130287 },
    change_1h: 1.2260971863198478,
    change_1d: -0.25424632766285526,
    change_7d: 5.29858076142375,
    fdv: 344382951,
    mcap: 186021019,
  },
  {
    id: '242',
    name: 'Energiswap',
    address: '0x1416946162b1c2c871a73b07e932d2fb6c932069',
    symbol: 'NRG',
    url: 'https://www.energiswap.exchange',
    description: 'A fully decentralized protocol for automated liquidity provision on Energi.',
    chain: 'Energi',
    logo: 'https://icons.llama.fi/energiswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'energi',
    cmcId: '3218',
    category: 'Dexes',
    chains: ['Energi'],
    module: 'energiswap.js',
    twitter: 'Energicrypto',
    forkedFrom: ['Uniswap'],
    slug: 'energiswap',
    tvl: 8501184.306466827,
    chainTvls: { Energi: 8501184.306466827 },
    change_1h: 3.9637225815919237,
    change_1d: 5.11663458724496,
    change_7d: 8.990999298911035,
    mcap: 38767853,
  },
  {
    id: '1233',
    name: 'Creditum',
    address: 'fantom:0x77128DFdD0ac859B33F44050c6fa272F34872B5E',
    symbol: 'CREDIT',
    url: 'https://revenant.finance/creditum',
    description:
      'Creditum is a lending & borrowing protocol that allows users to mint cUSD, a stablecoin pegged at $1, by supplying collateral.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/creditum.png',
    audits: '0',
    audit_note: '2',
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: 'creditum/index.js',
    twitter: 'RevenantFinance',
    listedAt: 1641878060,
    audit_links: ['https://github.com/revenant-finance/creditum-audit'],
    slug: 'creditum',
    tvl: 8476225.918291157,
    chainTvls: {
      pool2: 1683672.7859344757,
      'Fantom-staking': 407309.9763745921,
      'Fantom-pool2': 1683672.7859344757,
      staking: 407309.9763745921,
      Fantom: 8476225.918291157,
    },
    change_1h: -0.11151102417606751,
    change_1d: 0.15962753880656066,
    change_7d: -0.4696372540707614,
    staking: 407309.9763745921,
    pool2: 1683672.7859344757,
  },
  {
    id: '1495',
    name: 'Zappy',
    address: 'telos:0x9a271e3748f59222f5581bae2540daa5806b3f77',
    symbol: 'ZAP',
    url: 'https://www.zappy.finance/swap',
    description:
      'Zappy is an automated market-making (AMM) decentralized exchange (DEX) for the Telos network. Unlike other DEXs, we have invested in building a strong foundation with our ZAP token as a governance token, diverse farms, a built-in bridge, built-in limit orders, and user-centered service.',
    chain: 'Telos',
    logo: 'https://icons.llama.fi/zappy.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'zappy',
    cmcId: null,
    category: 'Dexes',
    chains: ['Telos'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'zappy/index.js',
    twitter: 'ZappyFi',
    listedAt: 1646186833,
    slug: 'zappy',
    tvl: 8347224.932193952,
    chainTvls: { Telos: 8347224.932193952 },
    change_1h: 2.876435696903229,
    change_1d: -2.978444827244374,
    change_7d: -4.84904848437894,
    mcap: 0,
  },
  {
    id: '167',
    name: 'PieDAO',
    address: '0xad32A8e6220741182940c5aBF610bDE99E737b2D',
    symbol: 'DOUGH',
    url: 'https://www.piedao.org/',
    description:
      "PieDAO is the decentralized community building ETF products known as pies. Their offerings provide diversified exposure to both DEFI and the wider cryptocurrency ecosystem, with baskets of tokens automatically rebalancing to maintain set allocations. All decisions are made as a community, with members using the DOUGH token to vote on proposals. DOUGH also provides holders with a share of the fees generated by the DAO's products. The platform aims to simplify decentralized finance with automation and minimize risk with diversified portfolios reducing exposure to any one single asset.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/piedao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'piedao-dough-v2',
    cmcId: '7284',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'piedao/index.js',
    twitter: 'PieDAO_Defi',
    audit_links: ['https://docs.piedao.org/technical/audits'],
    slug: 'piedao',
    tvl: 8292439.771513678,
    chainTvls: { Ethereum: 8292439.771513678, 'Ethereum-staking': 3642639.157335542, staking: 3642639.157335542 },
    change_1h: 0.659008037817685,
    change_1d: 3.9514713918165256,
    change_7d: 15.315740030982283,
    staking: 3642639.157335542,
    fdv: 25610575,
    mcap: 3984203,
  },
  {
    id: '342',
    name: 'CompliFi',
    address: '0x752efadc0a7e05ad1bcccda22c141d01a75ef1e4',
    symbol: 'COMFI',
    url: 'https://compli.fi/',
    description: 'Decentralised derivatives. No defaults, no margin calls, no liquidations.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/complifi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'complifi',
    cmcId: '9609',
    category: 'Dexes',
    chains: ['Polygon', 'Ethereum'],
    module: 'complifi/index.js',
    twitter: 'CompliFi_Pro',
    audit_links: [
      'https://github.com/CompliFi/complifi-protocol/tree/master/audits',
      'https://github.com/CompliFi/complifi-amm/tree/master/audits',
    ],
    slug: 'complifi',
    tvl: 8164956.366977334,
    chainTvls: { Ethereum: 913151.5303573542, Polygon: 7251804.83661998 },
    change_1h: -0.028514587698964533,
    change_1d: 0.3616601747074242,
    change_7d: 1.8029949433390868,
    mcap: 1189713,
  },
  {
    id: '651',
    name: 'CHFRY Finance',
    address: '0x332E824e46FcEeB9E59ba9491B80d3e6d42B0B59',
    symbol: 'CHEESE',
    url: 'https://chfry.finance',
    description:
      'CHFRY is a lending protocol that powers automatic repayment of debt by deploying capital to high yield strategies and with flash loans',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/chfry-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cheesefry',
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'chfry/index.js',
    twitter: 'ChfryFinance',
    audit_links: [
      'https://raw.githubusercontent.com/chfry-finance/chfry-protocol/master/docs/PeckShield-Audit-Report-Chfry-v1.0.pdf',
    ],
    slug: 'chfry-finance',
    tvl: 8099082.978092508,
    chainTvls: { Ethereum: 8099082.978092508 },
    change_1h: -0.18935118646051308,
    change_1d: 0.101738303036214,
    change_7d: -0.19115708207434068,
    fdv: 6860437,
    mcap: 90568,
  },
  {
    id: '1549',
    name: 'Babena',
    address: 'kadena:-',
    symbol: 'BABE',
    url: 'https://babena.finance',
    description: 'Certificate of Deposit on the Kadena blockchain',
    chain: 'Kadena',
    logo: 'https://icons.llama.fi/babena.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'CDP',
    chains: ['Kadena'],
    oracles: [],
    forkedFrom: [],
    module: 'babena/index.js',
    twitter: 'BabenaFinance',
    language: 'Pact',
    listedAt: 1647387814,
    slug: 'babena',
    tvl: 8034331.654763794,
    chainTvls: { 'Kadena-staking': 4592148.461151017, Kadena: 8034331.654763794, staking: 4592148.461151017 },
    change_1h: 0,
    change_1d: -1.2937162874699766,
    change_7d: 12.02203878078494,
    staking: 4592148.461151017,
  },
  {
    id: '642',
    name: 'Bumper Finance',
    address: '0x785c34312dfa6b74f6f1829f79ade39042222168',
    symbol: 'BUMP',
    url: 'https://www.bumper.fi',
    description:
      'Bumper Finance is the first product on the market to offer efficient, flexible, cost effective, easy-to-use, and guaranteed protection against volatility',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bumper-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bumper',
    cmcId: null,
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'bumper/index.js',
    twitter: 'bumperfinance',
    audit_links: ['https://drive.google.com/file/d/1MT75ifDmiV2hAg9nVJfVjzWr4UrNO6fU/view'],
    slug: 'bumper-finance',
    tvl: 7907574.181749286,
    chainTvls: { 'Ethereum-masterchef': 7907574.181749286, Ethereum: 7907574.181749286, masterchef: 7907574.181749286 },
    change_1h: -0.11158841158840005,
    change_1d: -0.011700000000004707,
    change_7d: -0.12696314107809314,
    fdv: 18575078,
    mcap: 0,
  },
  {
    id: '1582',
    name: 'Aperture Finance',
    address: null,
    symbol: '-',
    url: 'https://aperture.finance',
    description:
      'Cross-chain investment ecosystem with a community-driven marketplace for strategies. All your DeFi needs taken care of in one single place.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/aperture-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Synthetics',
    chains: ['Terra'],
    oracles: [],
    forkedFrom: [],
    module: 'aperture/index.js',
    twitter: 'ApertureFinance',
    listedAt: 1648463492,
    slug: 'aperture-finance',
    tvl: 7887988.395071827,
    chainTvls: { Terra: 7887988.395071827 },
    change_1h: -0.39068783724852096,
    change_1d: 0.01362214038245213,
    change_7d: null,
  },
  {
    id: '595',
    name: 'Convergence',
    address: '0xc834fa996fa3bec7aad3693af486ae53d8aa8b50',
    symbol: 'CONV',
    url: 'https://conv.finance',
    description:
      'Convergence Finance is a cross-chain AMM and launchpad that aims to make private markets public while enabling cross-chain compatibility to maximize liquidity across different chains so users can interact with private-sale token projects and transact beyond the ERC20 standard.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/convergence.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'convergence',
    cmcId: '8716',
    category: 'Dexes',
    chains: ['Moonbeam', 'Ethereum'],
    module: 'convergence/index.js',
    twitter: 'ConvergenceFin',
    audit_links: ['https://www.certik.org/projects/convergencefinance'],
    forkedFrom: ['Uniswap'],
    slug: 'convergence',
    tvl: 7777929.503873272,
    chainTvls: { Ethereum: 2241541.673533971, Moonbeam: 5536387.830339301 },
    change_1h: 0.9763349713023786,
    change_1d: 2.209651556548593,
    change_7d: -1.8254705459456346,
    mcap: 10888308,
  },
  {
    id: '1209',
    name: 'INK Protocol',
    address: null,
    symbol: '-',
    url: 'https://inkprotocol.finance',
    description:
      'The first decentralized passive income and prize savings protocol on the Terra blockchain that lets you win by saving.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/ink-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Terra'],
    module: 'ink-protocol/index.js',
    twitter: 'InkProtocol__',
    audit_links: [
      'https://github.com/oak-security/audit-reports/blob/master/Ink%20Protocol/2021-09-15%20Ink%20-%20Audit%20Report.pdf',
    ],
    listedAt: 1641534878,
    slug: 'ink-protocol',
    tvl: 7725214.551941887,
    chainTvls: { Terra: 7725214.551941887 },
    change_1h: -0.20467214874047102,
    change_1d: 2.486059236318198,
    change_7d: 0.6225902698863592,
  },
  {
    id: '581',
    name: 'Morpheus Swap',
    address: 'fantom:0xB66b5D38E183De42F21e92aBcAF3c712dd5d6286',
    symbol: 'PILLS',
    url: 'https://morpheusswap.finance',
    description:
      "Morpheus Swap is a decentralized exchange that's powered by Fantom Opera. By staking our core token, PILLS, users are entitled to a percentage of all protocol revenue, paid out in other tokens.",
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/morpheus-swap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'morpheus-token',
    cmcId: '11896',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'morpheusswap/index.js',
    twitter: 'MorpheusSwap',
    audit_links: ['https://morpheusswap.gitbook.io/morpheus-swap/security/audit'],
    forkedFrom: ['Uniswap'],
    slug: 'morpheus-swap',
    tvl: 7631682.320835858,
    chainTvls: { 'Fantom-staking': 1519373.7861012954, staking: 1519373.7861012954, Fantom: 7631682.320835858 },
    change_1h: 0.09334745819991497,
    change_1d: -1.015587859913495,
    change_7d: 3.7112554219149985,
    staking: 1519373.7861012954,
    fdv: 860153,
    mcap: 0,
  },
  {
    id: '538',
    name: 'Alchemist',
    address: '0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab',
    symbol: 'MIST',
    url: 'https://www.alchemist.wtf',
    description:
      "A combination of Defi platforms that include, Crucible which is more than just a smart wallet, it's a vault in the form of an NFT, MistX DEX, Copper their launchpad, and Sandwiched a tool for viewing if your transactions on other dexes have been affected by front-running.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/alchemist.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'alchemist',
    cmcId: '9131',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'alchemist/index.js',
    twitter: '_alchemistcoin',
    slug: 'alchemist',
    tvl: 7623880.286379659,
    chainTvls: { pool2: 14178628.644804528, Ethereum: 7623880.286379659, 'Ethereum-pool2': 14178628.644804528 },
    change_1h: -0.1828258040077344,
    change_1d: 3.1319484192001426,
    change_7d: 6.167929264027293,
    pool2: 14178628.644804528,
    mcap: 23989757,
  },
  {
    id: '586',
    name: 'Tidal Finance',
    address: 'polygon:0xB41EC2c036f8a42DA384DDE6ADA79884F8b84b26',
    symbol: 'TIDAL',
    url: 'https://tidal.finance',
    description:
      'TIDAL is a decentralized discretionary mutual cover protocol that offers the DeFi community the ability to hedge against the failure of any DeFi protocol or asset.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/tidal-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'tidal-finance',
    cmcId: '8912',
    category: 'Insurance',
    chains: ['Polygon'],
    module: 'tidalfinance/index.js',
    twitter: 'tidaldefi',
    audit_links: ['https://docs.tidal.finance/audit-report'],
    slug: 'tidal-finance',
    tvl: 7623318.1691194,
    chainTvls: { Polygon: 7623318.1691194, 'Polygon-staking': 890910.3033008868, staking: 890910.3033008868 },
    change_1h: -0.11158841158840005,
    change_1d: -0.011700000000004707,
    change_7d: 0.8313787885451944,
    staking: 890910.3033008868,
    fdv: 25709495,
    mcap: 5463927,
  },
  {
    id: '389',
    name: 'Adamant Finance',
    address: 'polygon:0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539',
    symbol: 'ADDY',
    url: 'https://adamant.finance',
    description:
      'Adamant is a yield optimizer vault that provides users with an easy and safe way to automatically compound their tokens on the Matic/Polygon network and maximize their yields.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/adamant-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'adamant',
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon', 'Arbitrum', 'Cronos'],
    module: 'adamantfinance/index.js',
    twitter: 'AdamantVault',
    audit_links: ['https://adamantfinance.gitbook.io/adamant-finance/audits'],
    slug: 'adamant-finance',
    tvl: 7546854.142400203,
    chainTvls: {
      Cronos: 317159.6317904637,
      pool2: 4589.134112080772,
      'Polygon-pool2': 1.2949786590631533,
      'Arbitrum-staking': 0,
      'Cronos-staking': 0,
      Polygon: 6588332.061286739,
      'Cronos-pool2': 4587.8391334217085,
      'Polygon-staking': 2800.359740705553,
      Arbitrum: 641362.4493230005,
      staking: 2800.359740705553,
    },
    change_1h: 0.000688614992895964,
    change_1d: -5.875891548853389,
    change_7d: 0.24185769922031852,
    staking: 2800.359740705553,
    pool2: 4589.134112080772,
    mcap: 0,
  },
  {
    id: '1079',
    name: 'IFPool',
    address: 'csc:0x1D7C98750A47762FA8B45c6E3744aC6704F44698',
    symbol: 'IFT',
    url: 'https://ifpool.io',
    description:
      'IFPool is a liquid staking pool for CSC. Users stake CET for iCET, which can be used in other DeFi protocols.',
    chain: 'CSC',
    logo: 'https://icons.llama.fi/ifpool.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'iftoken',
    cmcId: '8949',
    category: 'Liquid Staking',
    chains: ['CSC'],
    module: 'ifpool/index.js',
    twitter: 'if_networks',
    oracles: ['Chainlink'],
    audit_links: ['https://github.com/IFWallet/ifpool_staking/blob/main/audits/IFPool%20Security%20Assessment.pdf'],
    listedAt: 1640179280,
    slug: 'ifpool',
    tvl: 7500118.8448671475,
    chainTvls: { CSC: 7500118.8448671475 },
    change_1h: 0,
    change_1d: 2.5456590592901875,
    change_7d: 10.099807340424462,
    mcap: 0,
  },
  {
    id: '1378',
    name: 'Elephant Money',
    address: 'bsc:0xe283d0e3b8c102badf5e8166b73e02d96d92f688',
    symbol: 'ELEPHANT',
    url: 'https://elephant.money',
    description:
      'ELEPHANT.MONEY implements a voluntary, sustainable, and permissionless global economic engine on top of a collection of blue chip assets, a core rewards token (ELEPHANT), and a stable coin (TRUNK). The ELEPHANT.MONEY protocol is a 100% complete and finished product and provides yield and price appreciation in any market cycle.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/elephant-money.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'elephant-money',
    cmcId: '9936',
    category: 'Yield',
    chains: ['Binance'],
    oracles: ['TWAP'],
    module: 'elephantmoney/index.js',
    twitter: 'ElephantStatus',
    language: 'Solidity',
    audit_links: ['https://certik.org/projects/elephant', 'https://solidity.finance/audits/ElephantMoney'],
    listedAt: 1644442438,
    slug: 'elephant-money',
    tvl: 7461807.922048525,
    chainTvls: {
      Binance: 7461807.922048525,
      'Binance-staking': 62509017.171920955,
      pool2: 62768038.1777773,
      'Binance-pool2': 62768038.1777773,
      staking: 62509017.171920955,
    },
    change_1h: 0.07833350447921816,
    change_1d: 3.8775345996103283,
    change_7d: 16.54490023394122,
    staking: 62509017.171920955,
    pool2: 62768038.1777773,
    mcap: 0,
  },
  {
    id: '528',
    name: 'Symmetric',
    address: '0x57db3ffca78dbbe0efa0ec745d55f62aa0cbd345',
    symbol: 'SYMM',
    url: 'https://symmetric.finance',
    description:
      'Symmetric is an Automated Market Maker (AMM) and a Decentralized Exchange (DEX), running on the Celo and xDai networks.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/symmetric.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'symmetric',
    cmcId: '12050',
    category: 'Dexes',
    chains: ['xDai', 'Celo'],
    module: 'symmetric/index.js',
    twitter: '0xSymmetric',
    audit_links: ['https://docs.symmetric.exchange/audit'],
    forkedFrom: ['Balancer'],
    slug: 'symmetric',
    tvl: 7391886.066373477,
    chainTvls: { xDai: 6542885.904531827, Celo: 849000.1618416498 },
    change_1h: 2.483874222308714,
    change_1d: 4.024999474049665,
    change_7d: 28.821330183274853,
    fdv: 2574355,
    mcap: 0,
  },
  {
    id: '1018',
    name: 'Gains Network',
    address: 'polygon:0xe5417af564e4bfda1c483642db72007871397896',
    symbol: 'GNS',
    url: 'https://gainsnetwork.io',
    description:
      'Gains Network is building the decentralized finance ecosystem of the future. Our goal is to build the most advanced and optimised suite of DeFi products in the space.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/gains-network.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gains-network',
    cmcId: '13663',
    category: 'Derivatives',
    chains: ['Polygon'],
    module: 'gainsNetwork.js',
    twitter: 'GainsNetwork_io',
    audit_links: ['https://www.certik.com/projects/gainsnetwork'],
    listedAt: 1639418230,
    slug: 'gains-network',
    tvl: 7361665.274505347,
    chainTvls: { Polygon: 7361665.274505347, pool2: 16154710.337246802, 'Polygon-pool2': 16154710.337246802 },
    change_1h: -0.02267894636194967,
    change_1d: -0.6040557258388759,
    change_7d: null,
    pool2: 16154710.337246802,
    mcap: 72204539,
  },
  {
    id: '1036',
    name: 'BTCST',
    address: 'bsc:0x78650b139471520656b9e7aa7a5e9276814a38e9',
    symbol: 'BTCST',
    url: 'https://www.btcst.finance',
    description:
      'Bitcoin Standard Hashrate Token (or BTCST token) aims to widen up the participation of Bitcoin mining to the open market.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/btcst.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'btc-standard-hashrate-token',
    cmcId: '8891',
    category: 'Yield',
    chains: ['Binance'],
    module: 'btcst/index.js',
    twitter: 'BTCST2020',
    listedAt: 1639793638,
    slug: 'btcst',
    tvl: 7315009.213253378,
    chainTvls: {
      Binance: 7315009.213253378,
      'Binance-staking': 104404153.40016891,
      'Binance-Treasury': 9485667.731008498,
      Treasury: 9485667.731008498,
      staking: 104404153.40016891,
    },
    change_1h: 0.09138786258520781,
    change_1d: 0.409826267541618,
    change_7d: -11.808701024022042,
    staking: 104404153.40016891,
    fdv: 284798051,
    mcap: 138366280,
  },
  {
    id: '1416',
    name: 'GemKeeper',
    address: null,
    symbol: '-',
    url: 'https://app.gemkeeper.finance/#/swap',
    description: 'GemKeeper is a community focused AMM & DeFi Platform built on Oasis.',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/gemkeeper.png',
    audits: '1',
    audit_links: [
      'https://github.com/GemKeeperDEV/GemKeeperFinance/blob/main/PeckShield-Audit-Report-GemKeeper-v1.0.pdf',
    ],
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Oasis'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'gemkeeper.js',
    twitter: 'GemKeeperDeFi',
    listedAt: 1644969875,
    slug: 'gemkeeper',
    tvl: 7257559.221463319,
    chainTvls: { Oasis: 7257559.221463319 },
    change_1h: 4.617539415277406,
    change_1d: 4.2049556155586885,
    change_7d: 21.72901822197953,
  },
  {
    id: '500',
    name: 'StakeSteak',
    address: 'fantom:0x05848B832E872d9eDd84AC5718D58f21fD9c9649',
    symbol: 'STEAK',
    url: 'https://stakesteak.com',
    description: 'The first Fantom-native, interest-bearing Stable Coin: iFUSD',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/stakesteak.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'steak-token',
    cmcId: '11774',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'stakesteak/index.js',
    twitter: 'stake_steak',
    slug: 'stakesteak',
    tvl: 7245440.93851875,
    chainTvls: { 'Fantom-staking': 3513.8700984299708, staking: 3513.8700984299708, Fantom: 7245440.93851875 },
    change_1h: 0.28592668054498915,
    change_1d: 4.80454869606875,
    change_7d: 23.970904061662864,
    staking: 3513.8700984299708,
    fdv: 66491,
    mcap: 0,
  },
  {
    id: '515',
    name: 'Wrap Protocol',
    address: 'tezos:KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
    symbol: 'WRAP',
    url: 'https://app.tzwrap.com',
    description:
      'Through Wrap, users issue wTokens (wrapped tokens) which are representations of ERC20 and ERC721 tokens on the Tezos blockchain. wTokens can then be used on the Tezos blockchain, and their value is pegged to the original tokens.',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/wrap-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Synthetics',
    chains: ['Tezos'],
    module: 'tzwrap/index.js',
    twitter: 'Wrap_Protocol',
    audit_links: ['https://leastauthority.com/blog/audit-of-wrap-protocol-smart-contracts-for-tezos-foundation'],
    slug: 'wrap-protocol',
    tvl: 7240950,
    chainTvls: { Tezos: 7240950 },
    change_1h: 0.5128815899829107,
    change_1d: 0.8609104372331728,
    change_7d: 7.706264647766403,
  },
  {
    id: '885',
    name: 'FLRX',
    address: 'songbird:0x72D932019AE098A6368E6676E16169D112E7720A',
    symbol: 'EXFI',
    url: 'https://xfx.flr.finance',
    description:
      "Flare Networks has launched Songbird, a full-value experimental development network.Flare Finance has decided to follow in the footsteps of the main network, and deployed an experimental playground of our own. The Experimental Finance (or 'ExFi') platform on the Songbird Network",
    chain: 'Songbird',
    logo: 'https://icons.llama.fi/flarex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Songbird'],
    module: 'flarex/index.js',
    twitter: 'flrfinance',
    forkedFrom: ['Uniswap'],
    listedAt: 1637418466,
    slug: 'flrx',
    tvl: 7181238.119018578,
    chainTvls: { Songbird: 7181238.119018578 },
    change_1h: -0.7302693641837834,
    change_1d: -2.7541555655124057,
    change_7d: 5.140982139737233,
  },
  {
    id: '679',
    name: 'UniFarm',
    address: '0x40986a85b4cfcdb054a6cbfb1210194fee51af88',
    symbol: 'UFARM',
    url: 'https://unifarm.co',
    description: "Decentralized farming pool of DEFI's top projects. One Farm To Rule Them All",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/unifarm.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'unifarm',
    cmcId: '9262',
    category: 'Yield',
    chains: ['Ethereum', 'Binance', 'Polygon'],
    module: 'unifarm/index.js',
    twitter: 'unifarm_',
    audit_links: [
      'https://blog.oropocket.com/wp-content/uploads/2021/02/OpenDeFi-Unifarm-Audit-Report-QuillAudits.pdf',
    ],
    listedAt: 1634497185,
    slug: 'unifarm',
    tvl: 7153891.490992268,
    chainTvls: { Binance: 1306373.5391258541, Ethereum: 5174243.453815264, Polygon: 673274.49805115 },
    change_1h: 0.5839743593976578,
    change_1d: 0.8967876505642636,
    change_7d: 12.019755671103425,
    fdv: 14541762,
    mcap: 1237446,
  },
  {
    id: '852',
    name: 'Demeter',
    address: 'heco:0x9f5c80dc840f9cc60670a20c1e5d0fbd3e13b015',
    symbol: 'DUSD',
    url: 'https://demeter.xyz/#/',
    description:
      'Demeter is a multi-chain deployment decentralized currency market protocol (DUSD). Combining collateralized lending and collateralized stablecoin generation, Demeter provides a more secure lending service at a lower cost',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/demeter.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'demeter-usd',
    cmcId: '12366',
    category: 'Lending',
    chains: ['Heco'],
    module: 'demeter.js',
    twitter: 'Demetervip',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Demeter-v1.0.pdf',
    ],
    forkedFrom: ['Compound'],
    listedAt: 1637123250,
    slug: 'demeter',
    tvl: 7145219.784455405,
    chainTvls: { Heco: 7145219.784455405, 'Heco-staking': 178687.87805556116, staking: 178687.87805556116 },
    change_1h: -0.027505164452179542,
    change_1d: -0.11040748637618947,
    change_7d: 5.409721570031039,
    staking: 178687.87805556116,
    fdv: 7951325677281,
    mcap: 0,
  },
  {
    id: '454',
    name: 'Back Finance',
    address: 'heco:0x6474bc11f512dfe6a5162b2167e3f94b61471d05',
    symbol: 'BACK',
    url: 'https://back.finance',
    description: 'Back is a decentralized smart contract for leveraged borrowing and lending.',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/back-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'back-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Heco'],
    module: 'backfinance/index.js',
    twitter: 'Back_Finance',
    audit_links: ['https://back-finance.gitbook.io/back-finance/v/en/xie-yi/risk#contract-risk'],
    slug: 'back-finance',
    tvl: 7135234.605162556,
    chainTvls: { Heco: 7135234.605162556 },
    change_1h: 0.013700918889966829,
    change_1d: -0.18585023115664967,
    change_7d: -0.11266964599587936,
    mcap: 0,
  },
  {
    id: '421',
    name: 'Paint Swap',
    address: 'fantom:0x85dec8c4B2680793661bCA91a8F129607571863d',
    symbol: 'BRUSH',
    url: 'https://paintswap.finance',
    description:
      'PaintSwap Finance has the premier open NFT Marketplace on Fantom. It has a unique and sustainable yield farming AMM platform where 50% of farming rewards are locked for 3 months in the Art Gallery. The first project to create a video game built on the Fantom network, as well as many other exciting features in the works.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/paint-swap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'paint-swap',
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    forkedFrom: ['Uniswap'],
    module: 'paintswap/index.js',
    twitter: 'paint_swap',
    audit_links: ['https://solidity.finance/audits/PaintSwap/'],
    slug: 'paint-swap',
    tvl: 7092461.449576178,
    chainTvls: { 'Fantom-staking': 3150606.0947380615, staking: 3150606.0947380615, Fantom: 7092461.449576178 },
    change_1h: 1.0548761059304042,
    change_1d: -0.5321044304419189,
    change_7d: -14.182988662018275,
    staking: 3150606.0947380615,
    fdv: 21348996,
    mcap: 8910541,
  },
  {
    id: '324',
    name: 'Unicly',
    address: '0x94e0bab2f6ab1f19f4750e42d7349f2740513ad5',
    symbol: 'UNIC',
    url: 'https://app.unic.ly',
    description:
      'Unicly is a permissionless protocol to combine, fractionalize, and trade NFTs. Users can fractionalize NFT collections into "uTokens", and trade them on a Sushiswap-fork AMM with liquidity incentives via liquidity mining.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/unicly.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'unicly',
    cmcId: '1334',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'unicly/index.js',
    twitter: 'uniclyNFT',
    audit_links: ['https://github.com/uniclyNFT/Resources/blob/main/Unicly%20Security%20Audit.pdf'],
    forkedFrom: ['Uniswap'],
    slug: 'unicly',
    tvl: 7057727.14895404,
    chainTvls: { Ethereum: 7057727.14895404 },
    change_1h: -1.1291651372386582,
    change_1d: -3.111090403594801,
    change_7d: -11.993772658504824,
    fdv: 11443263,
    mcap: 7149987,
  },
  {
    id: '964',
    name: 'Nemesis DAO',
    address: 'bsc:0x8AC9DC3358A2dB19fDd57f433ff45d1fc357aFb3',
    symbol: 'NMS',
    url: 'https://nemesisdao.finance/',
    description: 'Nemesis DAO is a Decentralized Treasury Protocol with Reserve Currency',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/nemesis-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'nemesis-dao',
    cmcId: '14492',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'nemesis-dao/index.js',
    twitter: 'nemesis_dao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638483973,
    slug: 'nemesis-dao',
    tvl: 7037109.603836557,
    chainTvls: { Binance: 7037109.603836557, 'Binance-staking': 902513.5634264012, staking: 902513.5634264012 },
    change_1h: -0.17564674425480575,
    change_1d: 0.18657813531469003,
    change_7d: -0.02087685504385206,
    staking: 902513.5634264012,
    mcap: 324670,
  },
  {
    id: '128',
    name: 'Hegic',
    address: '0x584bC13c7D411c00c01A62e8019472dE68768430',
    symbol: 'HEGIC',
    url: 'https://www.hegic.co/ ',
    description: 'Trade non-custodial options for profits or to hedge your positions.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/hegic.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'hegic',
    cmcId: '6929',
    category: 'Options',
    chains: ['Ethereum'],
    module: 'hegic/index.js',
    twitter: 'HegicOptions',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Hegic-v1.0.pdf',
    ],
    slug: 'hegic',
    tvl: 6981805.315653795,
    chainTvls: { Ethereum: 6981805.315653795 },
    change_1h: 0.8222809504721909,
    change_1d: 2.327131406726963,
    change_7d: 11.83106269962775,
    fdv: 141406794,
    mcap: 33038347,
  },
  {
    id: '1514',
    name: 'The Granary',
    address: '-',
    symbol: '-',
    url: 'https://granary.finance/',
    description: 'The Granary is a decentralized, user-driven borrowing and lending liquidity market inspired by AAVE.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/the-granary.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Fantom'],
    oracles: ['Chainlink', 'Band'],
    forkedFrom: ['Aave'],
    module: 'the-granary/index.js',
    twitter: 'GranaryFinance',
    listedAt: 1646515940,
    slug: 'the-granary',
    tvl: 6959373.589673383,
    chainTvls: { 'Fantom-borrowed': 8023750.5155497175, borrowed: 8023750.5155497175, Fantom: 6959373.589673383 },
    change_1h: 0.11740142818852917,
    change_1d: -0.7573200205183213,
    change_7d: 56.529386573748724,
  },
  {
    id: '188',
    name: 'Indexed Finance',
    address: '0x86772b1409b61c639eaac9ba0acfbb6e238e5f83',
    symbol: 'NDX',
    url: 'https://indexed.finance/',
    description:
      'Indexed Finance is a project focused on the development of passive portfolio management strategies for the Ethereum network.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/indexed-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'indexed-finance',
    cmcId: '8260',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'indexed/index.js',
    twitter: 'ndxfi',
    audit_links: ['https://docs.indexed.finance/protocol/security'],
    slug: 'indexed-finance',
    tvl: 6849668.032675428,
    chainTvls: { Ethereum: 6849668.032675428 },
    change_1h: 0.7089522924560185,
    change_1d: 1.930203072843156,
    change_7d: 15.79771190530208,
    fdv: 13452097,
    mcap: 4635687,
  },
  {
    id: '824',
    name: 'Euphoria',
    address: 'harmony:0x0dc78c79b4eb080ead5c1d16559225a46b580694',
    symbol: 'WAGMI',
    url: 'https://euphoria.money',
    description:
      'Euphoria is an algorithmic reserve currency protocol on Harmony (ONE). The protocol intends to combine ViperSwap with Euphoria to bridge the gap between DeFi 1.0’s liquidity mining mechanisms and DeFi 2.0’s protocol-owned liquidity mechanisms',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/euphoria.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'euphoria-2',
    cmcId: '14153',
    category: 'Reserve Currency',
    chains: ['Harmony'],
    module: 'euphoria/index.js',
    twitter: 'EuphoriaMoney',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1636592925,
    slug: 'euphoria',
    tvl: 6810302.334963444,
    chainTvls: { 'Harmony-staking': 4505230.284355347, Harmony: 6810302.334963444, staking: 4505230.284355347 },
    change_1h: 0.14481916104351455,
    change_1d: 0.2443092193989287,
    change_7d: 3.5337486540434497,
    staking: 4505230.284355347,
    mcap: 8063361,
  },
  {
    id: '1311',
    name: 'Moremoney',
    address: 'avax:0xd9d90f882cddd6063959a9d837b05cb748718a05',
    symbol: 'MORE',
    url: 'https://moremoney.finance',
    description:
      'Moremoney is a protocol for borrowing stablecoin while receiving yield on LP tokens and other collateral assets.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/moremoney.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Avalanche'],
    module: 'moremoney/index.js',
    twitter: 'Moremoneyfi',
    audit_links: ['https://github.com/MoreMoney-Finance/audits/blob/main/PeckShield-Audit-Report-Moremoney-1.0.pdf'],
    oracles: ['Chainlink', 'TWAP'],
    listedAt: 1643047373,
    slug: 'moremoney',
    tvl: 6771275.66005607,
    chainTvls: { Avalanche: 6771275.66005607 },
    change_1h: -0.13612220477661197,
    change_1d: 21.095773106641587,
    change_7d: 4.71255347270818,
  },
  {
    id: '443',
    name: 'Kogefarm',
    address: 'polygon:0x13748d548D95D78a3c83fe3F32604B4796CFfa23',
    symbol: 'KOGECOIN',
    url: 'https://kogefarm.io',
    description:
      'KogeFarm is a yield optimizer on the Polygon chain. We have the lowest fees (by far) which helps investors get the highest yields. We are also audited and have new vaults voted in by DAO.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kogefarm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'kogecoin',
    cmcId: '10665',
    category: 'Yield',
    chains: ['Polygon', 'Fantom', 'Moonriver'],
    module: 'kogefarm/index.js',
    twitter: 'kogecoin',
    audit_links: ['https://github.com/Tibereum/obelisk-audits/blob/main/Kogefarm.pdf'],
    slug: 'kogefarm',
    tvl: 6762386.752581788,
    chainTvls: {
      Moonriver: 145327.76325104327,
      pool2: 190114.9151593615,
      'Polygon-pool2': 190114.9151593615,
      Polygon: 6315111.926562032,
      'Polygon-staking': 321150.6502109953,
      Fantom: 301947.0627687138,
      staking: 321150.6502109953,
    },
    change_1h: 0.048561280433204956,
    change_1d: -0.24395066400138887,
    change_7d: null,
    staking: 321150.6502109953,
    pool2: 190114.9151593615,
    mcap: 0,
  },
  {
    id: '1020',
    name: 'Arkadiko',
    address: 'stacks:SP2C2YFP12AJZB4MABJBAJ55XECVS7E4PMMZ89YZR.arkadiko-token',
    symbol: 'DIKO',
    url: 'https://arkadiko.finance',
    description:
      'Arkadiko is a decentralized, non-custodial liquidity protocol where users can collateralize their assets and mint a stablecoin called USDA.',
    chain: 'Stacks',
    logo: 'https://icons.llama.fi/arkadiko.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Stacks'],
    module: 'arkadiko.js',
    twitter: 'ArkadikoFinance',
    language: 'Clarity',
    listedAt: 1639420466,
    slug: 'arkadiko',
    tvl: 6686121.639770396,
    chainTvls: { Stacks: 6686121.639770396 },
    change_1h: 0.39123377451298325,
    change_1d: 0.6824937505706998,
    change_7d: 6.237054918979908,
  },
  {
    id: '291',
    name: 'Integral',
    address: '0xd502f487e1841fdc805130e13eae80c61186bc98',
    symbol: 'ITGR',
    url: 'https://integral.link/',
    description: 'The last exchange',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/integral.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'integral',
    cmcId: '10404',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'integral/index.js',
    twitter: 'ProfessorJEY',
    audit_links: ['https://docs.integral.link/library/audit-reports'],
    slug: 'integral',
    tvl: 6660983.898589506,
    chainTvls: { Ethereum: 6660983.898589506 },
    change_1h: 0.2233343073391154,
    change_1d: 1.9843564618402922,
    change_7d: 6.2946757071227495,
    fdv: 33144929,
    mcap: 3229032,
  },
  {
    id: '1008',
    name: 'Soy Finance',
    address: 'callisto:0x9fae2529863bd691b4a7171bdfcf33c7ebb10a65',
    symbol: 'SOY',
    url: 'https://soy.finance',
    description:
      'SOY Finance will deploy a set of services on the Callisto Network blockchain, including decentralized swap, yield farming, as well as a stable coin.',
    chain: 'Callisto',
    logo: 'https://icons.llama.fi/soy-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Callisto'],
    module: 'soyfinance/index.js',
    twitter: 'Soy_Finance',
    audit_links: ['https://soy-finance.gitbook.io/soy-finance/safety-on-yields/soy-finance-security-audit'],
    forkedFrom: ['Uniswap'],
    listedAt: 1639294731,
    slug: 'soy-finance',
    tvl: 6588399.11102714,
    chainTvls: { Callisto: 6588399.11102714 },
    change_1h: -0.018719557910301887,
    change_1d: 0.7555118867881276,
    change_7d: 4.838417253851517,
  },
  {
    id: '307',
    name: 'Wault',
    address: 'bsc:0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
    symbol: 'WEX',
    url: 'https://wault.finance/',
    description: 'Yield products',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wault.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wault',
    cmcId: '9478',
    category: 'Yield',
    chains: ['Binance', 'Polygon'],
    module: 'wault.js',
    twitter: 'Wault_Finance',
    audit_links: ['https://docs.wault.finance/resources/transparency'],
    slug: 'wault',
    tvl: 6548412.519050806,
    chainTvls: { Binance: 5737792.767263316, Polygon: 810619.7517874906 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 0,
    fdv: 630583,
    mcap: 482494,
  },
  {
    id: '1030',
    name: 'TopShelf',
    address: 'fantom:0x33333ee26a7d02e41c33828B42Fb1E0889143477',
    symbol: 'LIQR',
    url: 'https://topshelf.finance/',
    description: 'Liquity fork',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/topshelf.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'CDP',
    chains: ['Fantom', 'Avalanche', 'Binance'],
    module: 'topshelf/index.js',
    twitter: 'TopShelfFinance',
    audit_links: ['https://app.topshelf.finance/PeckShield-Audit-Report-Topshelf-v1.0.pdf'],
    listedAt: 1639732995,
    forkedFrom: ['Liquity'],
    slug: 'topshelf',
    tvl: 6507006.019440733,
    chainTvls: { Binance: 1104298.0676261222, Avalanche: 1159993.5937945542, Fantom: 4242714.358020056 },
    change_1h: 0,
    change_1d: -4.678314297939352,
    change_7d: 4.133497270910098,
  },
  {
    id: '211',
    name: 'Smoothy',
    address: '0xbf776e4fca664d791c4ee3a71e2722990e003283',
    symbol: 'SMTY',
    url: 'https://smoothy.finance/',
    description:
      'Smoothy is a novel single pool liquidity protocol specialized in same backed assets with low-cost zero-slippage swapping and maximized interest earning.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/smoothy.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'smoothy',
    cmcId: '7594',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'smoothy.js',
    twitter: 'smoothswap',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Smoothy-v1.0.pdf',
      'https://github.com/slowmist/Knowledge-Base/blob/master/open-report/Smart%20Contract%20Security%20Audit%20Report%20-%20SmoothyV1.pdf',
    ],
    slug: 'smoothy',
    tvl: 6485356.36,
    chainTvls: { Ethereum: 6485356.36 },
    change_1h: 0.000887545844435067,
    change_1d: -0.06587939353457273,
    change_7d: -0.06966187140550062,
    fdv: 4187436,
    mcap: 3454634,
  },
  {
    id: '659',
    name: 'JetSwap',
    address: 'bsc:0x0487b824c8261462F88940f97053E65bDb498446',
    symbol: 'WINGS',
    url: 'https://jetswap.finance',
    description:
      "Jetfuel ecosystem is an all-in-one Defi that's meant to be a one-stop-shop for users, it accounts with an AMM & Yield Farming on Jetswap, Lending/borrowing markets on Fortress, a Yield optimizer on Jetfuel.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/jetswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'jetswap-token',
    cmcId: '10810',
    category: 'Dexes',
    chains: ['Binance', 'Polygon', 'Fantom'],
    module: 'jetswap/index.js',
    twitter: 'Jetfuelfinance',
    audit_links: ['https://jetswap.finance/audit-by-etherauthority.pdf', 'https://jetswap.finance/audit-by-hash0x.pdf'],
    forkedFrom: ['Uniswap'],
    slug: 'jetswap',
    tvl: 6479927.83837964,
    chainTvls: {
      'Fantom-staking': 26862.331210842516,
      Binance: 4524919.370205541,
      'Binance-staking': 110417.245644226,
      Polygon: 1174192.5936878268,
      'Polygon-staking': 89067.20458763471,
      Fantom: 780815.8744862727,
      staking: 226346.7814427032,
    },
    change_1h: 0.1676852137422884,
    change_1d: 1.4210475810715337,
    change_7d: 4.291732616913421,
    staking: 226346.7814427032,
    mcap: 0,
  },
  {
    id: '548',
    name: 'DeFis Network',
    address: 'eos:DFS',
    symbol: 'DFS',
    url: 'https://defis.network',
    description:
      'DeFis Network is the most decentralized finance network built on EOSIO that aggregates all valuable DeFi-protocols.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/defis-network.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'defis-network',
    cmcId: '6610',
    category: 'Dexes',
    chains: ['EOS'],
    module: 'dfs/index.js',
    twitter: 'DFSnetworks',
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=ca2c97083e94d0958e9376b770b16a7f8ddd53fb1e5f1b9cbabc0ccf8d157d40',
      'https://github.com/peckshield/publications/blob/master/audit_reports/DeFis-Network_Swap_audit_report_2020_20_cn_1_0.pdf',
    ],
    slug: 'defis-network',
    tvl: 6430706.419283858,
    chainTvls: { EOS: 6430706.419283858 },
    change_1h: 0.5823950988864652,
    change_1d: -0.792649943794757,
    change_7d: 9.853547150647898,
    mcap: 0,
  },
  {
    id: '1269',
    name: 'Solarflare',
    address: 'moonbeam:0xE3e43888fa7803cDC7BEA478aB327cF1A0dc11a7',
    symbol: 'FLARE',
    url: 'https://solarflare.io',
    description:
      'Solarflare is a decentralized exchange, providing liquidity and enabling peer-to-peer transactions on the Moonbeam Network.',
    chain: 'Moonbeam',
    logo: 'https://icons.llama.fi/solarflare.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonbeam'],
    module: 'solarflare.js',
    twitter: 'Solarbeamio',
    audit_links: ['https://www.certik.com/projects/solarbeam'],
    forkedFrom: ['Uniswap'],
    listedAt: 1642210209,
    slug: 'solarflare',
    tvl: 6415870.436064262,
    chainTvls: { Moonbeam: 6415870.436064262 },
    change_1h: -1.4514645401410888,
    change_1d: -1.1076536246005588,
    change_7d: 1.8409395758300917,
  },
  {
    id: '280',
    name: 'Oxygen',
    address: 'z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M',
    symbol: 'OXY',
    url: 'https://www.oxygen.org/',
    description: 'Money market on Solana',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/oxygen.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'oxygen',
    cmcId: '8029',
    category: 'Lending',
    chains: ['Solana'],
    module: 'oxygen/index.js',
    twitter: 'Oxygen_protocol',
    openSource: false,
    slug: 'oxygen',
    tvl: 6364246.3685619,
    chainTvls: { Solana: 6364246.3685619 },
    change_1h: 0.015206477959608833,
    change_1d: 0.07350898258755478,
    change_7d: -0.2853891995592761,
    fdv: 4130719252,
    mcap: 83589842,
  },
  {
    id: '258',
    name: 'Wasabix',
    address: '0x896e145568624a498c5a909187363AE947631503',
    symbol: 'WASABI',
    url: 'https://wasabix.finance/#/app',
    description:
      'WasabiX is a DeFi protocol that enables for the creation of synth-tokens that represent the future yield of a deposit. It allows users to retrieve near instant tokenized value against temporary* deposits of stablecoins and ETH.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wasabix.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wasabix',
    cmcId: '9077',
    category: 'Yield',
    chains: ['Ethereum', 'Binance', 'Polygon'],
    module: 'wasabix/index.js',
    twitter: 'WasabixFinance',
    audit_links: ['https://wasabix-finance.gitbook.io/wasabix_finance/code/certik-audit-report'],
    forkedFrom: ['Alchemix'],
    slug: 'wasabix',
    tvl: 6283872.851130208,
    chainTvls: { Binance: 1465946.3767484378, Ethereum: 4485432.191436946, Polygon: 332494.28294482396 },
    change_1h: 0.4823280614469212,
    change_1d: 1.9112771175168177,
    change_7d: 12.842853083699453,
    fdv: 1991080,
    mcap: 1991080,
  },
  {
    id: '363',
    name: 'xDollar',
    address: 'iotex:0x375488f097176507e39b9653b88fdc52cde736bf',
    symbol: 'SPACE',
    url: 'https://www.xdollar.fi/',
    description:
      'xDollar is cross-chain collateral based stablecoin lending platform with zero interest fee, 110% low collateral ratio and fully redeemable XIM plus DAO governance and multi-collateral vault system.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/xdollar.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'xdollar',
    cmcId: null,
    category: 'CDP',
    chains: ['IoTeX', 'Ethereum'],
    module: 'xdollar-finance/index.js',
    twitter: 'xDollarFi',
    audit_links: [
      'https://github.com/xDollar-Finance/xDollar-contracts/blob/main/xDollar%20-%20Smart%20Contract%20Audit%20v210624.pdf',
    ],
    forkedFrom: ['Liquity'],
    slug: 'xdollar',
    tvl: 6280930.733682036,
    chainTvls: { IoTeX: 6222336.516440357, Ethereum: 58594.21724167944 },
    change_1h: 0.39189179973972443,
    change_1d: 0.4512303148912906,
    change_7d: -0.35106151544842135,
    mcap: 0,
  },
  {
    id: '1208',
    name: 'OpenLeverage',
    address: null,
    symbol: '-',
    url: 'https://openleverage.finance',
    description:
      'OpenLeverage is a permissionless margin trading protocol that enables traders or other applications to be long or short on any trading pair on DEXs efficiently and securely.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/openleverage.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '1441',
    category: 'Dexes',
    chains: ['Binance', 'Ethereum'],
    module: 'openleverage/index.js',
    twitter: 'OpenLeverage',
    audit_links: [
      'https://github.com/OpenLeverageDev/openleverage-contracts/blob/main/audits/REP-OpenLeverage-Protocol-2021-06-24.pdf',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-OpenLeverage-1.0.1.pdf',
    ],
    listedAt: 1641526578,
    slug: 'openleverage',
    tvl: 6256944.346182143,
    chainTvls: { Binance: 5548918.361180103, Ethereum: 708025.9850020399 },
    change_1h: -0.04771984541709173,
    change_1d: -3.378034365735786,
    change_7d: -5.043183756557596,
  },
  {
    id: '219',
    name: 'DDEX',
    address: '-',
    symbol: '-',
    url: 'https://ddex.io/',
    description:
      'A decentralized margin exchange built on Ethereum. Trade ETH and BTC with up to 5x leverage. Lend to earn interest.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ddex.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '367',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'ddex/index.js',
    twitter: 'ddexio2018',
    audit_links: [
      'https://github.com/HydroProtocol/audit-reports/blob/master/2.0/hydro_audit_report_2019_14_en_1_0.pdf',
    ],
    slug: 'ddex',
    tvl: 6229377.875926896,
    chainTvls: { Ethereum: 6229377.875926896 },
    change_1h: 0,
    change_1d: 2.86924102647896,
    change_7d: 11.292521460496758,
  },
  {
    id: '714',
    name: 'Voltage',
    address: '0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4',
    symbol: 'VOLT',
    url: 'https://app.voltage.finance/',
    description:
      'Built atop the powerful Fuse blockchain and ecosystem, Fuse.fi enables anyone to carry the power of DeFi in their pocket.',
    chain: 'Fuse',
    logo: 'https://icons.llama.fi/voltage.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fuse'],
    module: 'fusefi/index.js',
    twitter: 'voltfinance',
    forkedFrom: ['Uniswap', 'Compound'],
    listedAt: 1635268314,
    slug: 'voltage',
    tvl: 6104318.797673232,
    chainTvls: { 'Fuse-borrowed': 3301297.335010344, borrowed: 3301297.335010344, Fuse: 6104318.797673232 },
    change_1h: 0.6263671226985394,
    change_1d: 2.4308182252520822,
    change_7d: 6.936076794912765,
  },
  {
    id: '513',
    name: 'QuipuSwap',
    address: 'tezos:KT193D4vozYnhGJQVtw7CoxxqphqUEEwK6Vb',
    symbol: 'QUIPU',
    url: 'https://quipuswap.com',
    description:
      'QuipuSwap is an open-source protocol that provides an interface for the seamless decentralized exchange of Tezos-based tokens and XTZ. Liquidity providers are able to earn both from swap fees and baker rewards, using benefits provided by the Tezos protocol such as liquid XTZ delegation.',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/quipuswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Tezos'],
    module: 'quipuswap/index.js',
    twitter: 'QuipuSwap',
    audit_links: [
      'https://leastauthority.com/static/publications/LeastAuthority_Tezos_Foundation_QuipuSwap_Smart_Contracts_Final_Audit_Report.pdf',
    ],
    slug: 'quipuswap',
    tvl: 6032979,
    chainTvls: { Tezos: 6032979 },
    change_1h: 0,
    change_1d: 0,
    change_7d: -0.06998272710347919,
  },
  {
    id: '405',
    name: 'Tokenlon',
    address: '0x0000000000095413afc295d19edeb1ad7b71c952',
    symbol: 'LON',
    url: 'https://tokenlon.im',
    description: 'Tokenlon is a decentralized exchange and payment settlement protocol based on blockchain technology',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/tokenlon.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'tokenlon',
    cmcId: '856',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'tokenlon/index.js',
    twitter: 'tokenlon',
    audit_links: [
      'https://github.com/consenlabs/tokenlon-security/blob/master/audits/PeckShield-Audit-TokenlonV5-v1.0rc.pdf',
    ],
    slug: 'tokenlon',
    tvl: 5993467.157078064,
    chainTvls: { Ethereum: 5993467.157078064, 'Ethereum-staking': 50995659.49822619, staking: 50995659.49822619 },
    change_1h: 0.5017681015565785,
    change_1d: 4.195955445893844,
    change_7d: 14.16577443960027,
    staking: 50995659.49822619,
    fdv: 214094058,
    mcap: 27127942,
  },
  {
    id: '789',
    name: 'Ola Finance',
    address: null,
    symbol: '-',
    url: 'https://ola.finance',
    description: 'A Leap Forward in Decentralized Peer-to-Pool Lending',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/ola-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Fantom'],
    module: 'olafinance/index.js',
    twitter: 'ola_finance',
    audit_links: ['https://olafinance.gitbook.io/ola-finance/audit-reports/solidified-and-zk-labs'],
    forkedFrom: ['Compound'],
    oracles: ['Internal', 'DIA'],
    listedAt: 1636144662,
    slug: 'ola-finance',
    tvl: 5985612.008661658,
    chainTvls: { 'Fantom-borrowed': 1901710.312708384, borrowed: 1901710.312708384, Fantom: 5985612.008661658 },
    change_1h: 0.8230984445657015,
    change_1d: -0.18383385690302134,
    change_7d: null,
  },
  {
    id: '905',
    name: 'TangoSwap',
    address: '0x73BE9c8Edf5e951c9a0762EA2b1DE8c8F38B5e91',
    symbol: 'TANGO',
    url: 'https://tangoswap.cash/swap',
    description: 'Decentralized exchange DEX (Swap - Staking - Farming) on SmartBCH.',
    chain: 'smartBCH',
    logo: 'https://icons.llama.fi/tangoswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['smartBCH'],
    module: 'tangoswap/index.js',
    twitter: 'TangoSwapCash',
    audit_links: ['https://github.com/0xGuard-com/audit-reports/blob/master/tangoswap/TangoSwap_final-audit.pdf'],
    forkedFrom: ['Uniswap'],
    listedAt: 1637729126,
    slug: 'tangoswap',
    tvl: 5897686.846672502,
    chainTvls: { smartBCH: 5897686.846672502 },
    change_1h: 0.49031952552496705,
    change_1d: 9.448767552008405,
    change_7d: 11.250546805892142,
  },
  {
    id: '1225',
    name: 'VoltSwap',
    address: 'meter:0x8Df95e66Cb0eF38F91D2776DA3c921768982fBa0',
    symbol: 'VOLT',
    url: 'https://voltswap.finance',
    description:
      'VoltSwap is the first major DEX in the Meter ecosystem. It is a completely community-driven project and was introduced by the Meter.io team to showcase the capabilities of the Meter blockchain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/voltswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Theta', 'Meter'],
    module: 'voltswap/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'Meter_IO',
    oracles: [],
    listedAt: 1641871826,
    slug: 'voltswap',
    tvl: 5829294.6649093265,
    chainTvls: { Theta: 3045120.7477152594, Meter: 2784173.917194067 },
    change_1h: 0.7685591435749188,
    change_1d: -0.3665350265086431,
    change_7d: 5.287716838469976,
  },
  {
    id: '379',
    name: 'Cardstarter',
    address: '0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9',
    symbol: 'CARDS',
    url: 'https://www.cardstarter.io/',
    description: 'The first insured project accelerator and launchpad for Cardano',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/cardstarter.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cardstarter',
    cmcId: '9047',
    category: 'Launchpad',
    chains: ['Ethereum'],
    module: 'cardstarter/index.js',
    twitter: 'CardStarter',
    audit_links: ['https://t.me/Cardstarter/115433'],
    oracles: ['Chainlink'],
    slug: 'cardstarter',
    tvl: 5674292.13996685,
    chainTvls: { Ethereum: 5674292.13996685 },
    change_1h: 0.47474280926067536,
    change_1d: -0.15729538009885857,
    change_7d: 24.86192715518753,
    mcap: 16683835,
  },
  {
    id: '1504',
    name: 'Fantasm',
    address: 'fantom:0xaa621D2002b5a6275EF62d7a065A865167914801',
    symbol: 'FSM',
    url: 'https://fantasm.finance/',
    description: ' Fractional-algorithmic Synthetic token on Fantom Opera',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/fantasm.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'fantasm-fsm',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'fantasm/index.js',
    twitter: 'fantasm_finance',
    listedAt: 1646391958,
    slug: 'fantasm',
    tvl: 5668398.213707979,
    chainTvls: {
      pool2: 24541.325318094172,
      'Fantom-staking': 212545.97748644758,
      'Fantom-pool2': 24541.325318094172,
      staking: 212545.97748644758,
      Fantom: 5668398.213707979,
    },
    change_1h: 0.5773640969866705,
    change_1d: -25.623798111616907,
    change_7d: null,
    staking: 212545.97748644758,
    pool2: 24541.325318094172,
    mcap: 0,
  },
  {
    id: '1552',
    name: 'Angel Protocol',
    address: 'terra:terra1numzqm5mgr56ftd4y8mfen7705nfs4vpz5jf0s',
    symbol: 'HALO',
    url: 'https://www.angelprotocol.io',
    description:
      'Angel Protocol transforms how your charity fundraises allowing them to thrive from decentralized financial products, without the complexity',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/angel-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'angel-protocol',
    cmcId: null,
    category: 'Services',
    chains: ['Terra'],
    oracles: [],
    forkedFrom: [],
    module: 'angel-protocol/index.js',
    twitter: 'angelprotocol',
    openSource: false,
    listedAt: 1647396334,
    slug: 'angel-protocol',
    tvl: 5651064.334276159,
    chainTvls: { Terra: 5651064.334276159 },
    change_1h: 0,
    change_1d: 0.8375943748126389,
    change_7d: 2.323294653380941,
    fdv: 48227794,
    mcap: 0,
  },
  {
    id: '707',
    name: 'LuaSwap',
    address: '0xb1f66997a5760428d3a87d68b90bfe0ae64121cc',
    symbol: 'LUA',
    url: 'https://luaswap.org/#/',
    description:
      'LuaSwap is a swap protocol inspired by the previous AMM-based swap protocols such as Uniswap and SushiSwap.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/luaswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'lua-token',
    cmcId: '7216',
    category: 'Dexes',
    chains: ['Ethereum', 'TomoChain'],
    module: 'luaswap/index.js',
    twitter: 'LuaSwap',
    audit_links: ['https://github.com/tomochain/luaswap-core/tree/master/audit'],
    forkedFrom: ['Uniswap'],
    listedAt: 1635108394,
    slug: 'luaswap',
    tvl: 5573015.281870114,
    chainTvls: { TomoChain: 2419253.7937453985, Ethereum: 3153761.488124715 },
    change_1h: 0.5655534033224114,
    change_1d: null,
    change_7d: null,
    fdv: 18479258,
    mcap: 6058433,
  },
  {
    id: '375',
    name: 'Nerve Network',
    address: 'bsc:0xf0e406c49c63abf358030a299c0e00118c4c6ba5',
    symbol: 'NVT',
    url: 'https://nerve.network/',
    description:
      'Nerve is a decentralized digital asset service network based on the NULS micro-services framework, which uses the NULS ChainBox to develop a blockchain cross-chain interaction protocol.',
    chain: 'Nuls',
    logo: 'https://icons.llama.fi/nerve-network.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'nervenetwork',
    cmcId: '5906',
    category: 'Dexes',
    chains: ['Nuls'],
    module: 'nervenetwork.js',
    twitter: 'nerve_network',
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=097a33b2e955a56af85e80b13ff82a3ad20fea83871769b5c58d024b5e774164',
    ],
    slug: 'nerve-network',
    tvl: 5523183,
    chainTvls: { Nuls: 5523183 },
    change_1h: -0.08992151810531368,
    change_1d: 0.5924797273620754,
    change_7d: 10.38788975247391,
    mcap: 11378549,
  },
  {
    id: '261',
    name: 'Cometh',
    address: 'polygon:0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    symbol: 'MUST',
    url: 'https://www.cometh.io/',
    description: 'DeFi powered space game with yield generating NFT ',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/cometh.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'must',
    cmcId: '8294',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'mustcometh/index.js',
    twitter: 'MUSTCometh',
    forkedFrom: ['Uniswap'],
    slug: 'cometh',
    tvl: 5420279.599005446,
    chainTvls: { Polygon: 5420279.599005446 },
    change_1h: 0.000842032903051404,
    change_1d: 0.036158531105527913,
    change_7d: 6.60293131174312,
    fdv: 47637504,
    mcap: 4944455,
  },
  {
    id: '206',
    name: 'xToken',
    address: '0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb',
    symbol: 'XTK',
    url: 'https://xtoken.market/',
    description: 'Passive, Efficient ERC20 Staking Strategies.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/xtoken.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'xtoken',
    cmcId: '8599',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'xtoken/index.js',
    twitter: 'xtokenmarket',
    audit_links: ['https://docs.xtoken.market/misc/untitled-1#is-synlev-audited'],
    slug: 'xtoken',
    tvl: 5307761.3780748155,
    chainTvls: { Ethereum: 5307761.3780748155 },
    change_1h: 2.7915634004488794,
    change_1d: 6.087426465369077,
    change_7d: 12.900958352339444,
    fdv: 14700704,
    mcap: 3683858,
  },
  {
    id: '806',
    name: 'Glide Finance',
    address: 'esc:0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27',
    symbol: 'GLIDE',
    url: 'https://glidefinance.io',
    description:
      'Glide Finance is a Decentralized Exchange / Automated Market Maker, Yield Farming, and Staking platform running on the Elastos Smart Chain (ESC) that aims to accelerate adoption of the Elastos ecosystem by acting as a source of liquidity for users and the projects built on it.',
    chain: 'Elastos',
    logo: 'https://icons.llama.fi/glide-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Elastos'],
    module: 'glide-finance/index.js',
    twitter: 'GlideFinance',
    audit_links: ['https://docs.glidefinance.io/security/audit'],
    forkedFrom: ['Uniswap'],
    listedAt: 1636387832,
    slug: 'glide-finance',
    tvl: 5289001.856314347,
    chainTvls: { Elastos: 5289001.856314347, 'Elastos-staking': 613217.8810472346, staking: 613217.8810472346 },
    change_1h: 0.06365878253369317,
    change_1d: -2.3472557069084132,
    change_7d: 17.434350102930836,
    staking: 613217.8810472346,
  },
  {
    id: '711',
    name: 'Polymarket',
    address: null,
    symbol: '-',
    url: 'https://polymarket.com',
    description:
      'Polymarket is an information markets platform that lets you trade on the world’s most highly-debated topics (e.g. coronavirus, politics, current events, etc). On Polymarket, you build a portfolio based on your forecasts and earn a return if you are right.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polymarket.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Prediction Market',
    chains: ['Polygon'],
    oracles: ['UMA'],
    module: 'polymarket.js',
    twitter: 'PolymarketHQ',
    listedAt: 1635250158,
    slug: 'polymarket',
    tvl: 5234757.403633796,
    chainTvls: { Polygon: 5234757.403633796 },
    change_1h: 0.5260814878822231,
    change_1d: -0.7597354412981332,
    change_7d: 14.516159488014495,
  },
  {
    id: '867',
    name: 'GIBXSwap',
    address: 'bsc:0xae28714390e95b8df1ef847c58aeac23ed457702',
    symbol: 'X',
    url: 'https://gibxswap.io',
    description: 'The best decentralized exchange platform on #BSC, focuses on mainstream cryptocurrency.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/gibxswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gibx-swap',
    cmcId: '11918',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'gibxswap/index.js',
    twitter: 'GIBX_Swap',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://www.certik.org/projects/gibx-swap'],
    listedAt: 1637365166,
    slug: 'gibxswap',
    tvl: 5220648.040943643,
    chainTvls: { Binance: 5220648.040943643, 'Binance-staking': 2421953.314979053, staking: 2421953.314979053 },
    change_1h: 0.930508507405861,
    change_1d: 1.5211257765988933,
    change_7d: 8.678154437946304,
    staking: 2421953.314979053,
    mcap: 0,
  },
  {
    id: '527',
    name: 'KSwap Finance',
    address: 'okex:0xab0d1578216A545532882e420A8C61Ea07B00B12',
    symbol: 'KST',
    url: 'https://kswap.finance',
    description: 'KSwap Finance is a decentralized trading platform deployed on OKExChain.',
    chain: 'OKExChain',
    logo: 'https://icons.llama.fi/kswap-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '9479',
    category: 'Dexes',
    chains: ['OKExChain'],
    module: 'kswapfinance/index.js',
    twitter: 'kswap_finance',
    audit_links: ['https://static.kswap.finance/audit/KST_Audit_Report_EN.pdf'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    slug: 'kswap-finance',
    tvl: 5213176.659607704,
    chainTvls: { OKExChain: 5213176.659607704 },
    change_1h: 0,
    change_1d: 2.146132418894524,
    change_7d: 2.377881199136425,
  },
  {
    id: '1220',
    name: 'KillSwitch',
    address: 'bsc:0x270178366a592ba598c2e9d2971da65f7baa7c86',
    symbol: 'KSW',
    url: 'https://killswitch.finance',
    description:
      'KillSwitch is a smart yield aggregator, users are free to unstake, withdraw Liquidity Position from their funds and sell their coins instantly in one click.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/killswitch.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'killswitch',
    cmcId: '14361',
    category: 'Yield Aggregator',
    chains: ['Binance', 'Kucoin', 'Aurora'],
    module: 'killswitch/index.js',
    twitter: 'KillSwitch_Defi',
    audit_links: ['https://service.inspex.co/@inspex/rJctqA80u'],
    oracles: ['Chainlink'],
    listedAt: 1641764117,
    slug: 'killswitch',
    tvl: 5209399.861546459,
    chainTvls: { Binance: 3505162.812916043, Aurora: 825543.1216939512, Kucoin: 880560.1279119115 },
    change_1h: 0.23986591882906794,
    change_1d: -2.152811712470907,
    change_7d: 1.6366059419332828,
    fdv: 10469957,
    mcap: 0,
  },
  {
    id: '350',
    name: 'CVI Finance',
    address: '0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107',
    symbol: 'GOVI',
    url: 'https://cvi.finance/',
    description: 'CVI is a Market Fear Index for the Crypto Space - a new DeFi Primitive',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cvi-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'govi',
    cmcId: '8408',
    category: 'Synthetics',
    chains: ['Polygon', 'Arbitrum', 'Ethereum'],
    module: 'cvi/index.js',
    twitter: 'official_CVI',
    audit_links: ['https://cvi.finance/files/usdt-audit.pdf', 'https://cvi.finance/files/eth-audit.pdf'],
    oracles: ['Chainlink'],
    slug: 'cvi-finance',
    tvl: 5185581.497333074,
    chainTvls: {
      pool2: 3675740.84556441,
      'Polygon-pool2': 284178.60902911925,
      'Arbitrum-staking': 2424573.4501219015,
      'Ethereum-pool2': 3391562.2365352907,
      Ethereum: 3507933.957546187,
      'Ethereum-staking': 294602.5403383936,
      Polygon: 1677647.5397868867,
      'Polygon-staking': 2591433.760331357,
      staking: 5310609.750791652,
    },
    change_1h: 0.23996747590537382,
    change_1d: 2.1188724624477544,
    change_7d: -0.43467260286040243,
    staking: 5310609.750791652,
    pool2: 3675740.84556441,
    mcap: 14159546,
  },
  {
    id: '705',
    name: 'TTswap',
    address: null,
    symbol: '-',
    url: 'https://ttswap.space/#/swap',
    description:
      'TTSwap is a DEX (decentralized exchange) on the ThunderCore blockchain based on the model: Automated Market-Making (AMM) inspired by Uniswap.',
    chain: 'ThunderCore',
    logo: 'https://icons.llama.fi/ttswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '1450',
    category: 'Dexes',
    chains: ['ThunderCore'],
    module: 'ttswap/index.js',
    twitter: 'ThunderProtocol',
    forkedFrom: ['Uniswap'],
    listedAt: 1635107086,
    slug: 'ttswap',
    tvl: 5126297.038753024,
    chainTvls: { ThunderCore: 5126297.038753024 },
    change_1h: -0.5996085799358895,
    change_1d: 1.3355177234150943,
    change_7d: 12.881074230703788,
  },
  {
    id: '689',
    name: 'Kalata',
    address: 'bsc:0x32299c93960bb583a43c2220dc89152391a610c5',
    symbol: 'KALA',
    url: 'https://www.kalata.io/',
    description: 'Overcollateralized minting of synthetic assets',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/kalata.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'kalata',
    cmcId: '9353',
    category: 'Synthetics',
    chains: ['Binance'],
    module: 'kalata/index.js',
    twitter: 'KalataOfficial',
    listedAt: 1634737786,
    slug: 'kalata',
    tvl: 5091223.356640539,
    chainTvls: {
      'Binance-masterchef': 3895593.979017299,
      pool2: 496219.0488699263,
      masterchef: 3895593.979017299,
      'Binance-pool2': 496219.0488699263,
      Binance: 5091223.356640539,
      'Binance-staking': 276256.2871652468,
      staking: 276256.2871652468,
    },
    change_1h: -0.23246951212918532,
    change_1d: 0.0260611152153416,
    change_7d: -0.8938076723324855,
    staking: 276256.2871652468,
    pool2: 496219.0488699263,
    mcap: 0,
  },
  {
    id: '812',
    name: 'EmpireDEX',
    address: 'bsc:0xc83859c413a6ba5ca1620cd876c7e33a232c1c34',
    symbol: 'EMPIRE',
    url: 'https://app.empiredex.org/farm',
    description: '#EmpireDEX is a Multi-Chain DEX Protocol',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/empire-dex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos', 'Binance', 'Fantom', 'Avalanche', 'Ethereum', 'Polygon', 'xDai'],
    module: 'empiredex/index.js',
    twitter: 'Empire_DEX',
    forkedFrom: ['Uniswap'],
    listedAt: 1636439712,
    slug: 'empiredex',
    tvl: 5086944.977368698,
    chainTvls: {
      Cronos: 2725448.132991461,
      Binance: 1611474.267157646,
      Ethereum: 109692.58160826877,
      xDai: 59163.75836446077,
      Avalanche: 144440.19000107422,
      Polygon: 97507.72656849226,
      Fantom: 339218.320677296,
    },
    change_1h: 0.16559983370727593,
    change_1d: 0.388636519725722,
    change_7d: 12.7839053680664,
  },
  {
    id: '346',
    name: 'Yieldly',
    address: 'algorand:226701642',
    symbol: 'YLDY',
    url: 'https://yieldly.finance/',
    description:
      'Yieldly is a suite of DeFi apps on the Algorand blockchain, including no-loss prize games, multi-asset staking, and cross-chain swapping.',
    chain: 'Algorand',
    logo: 'https://icons.llama.fi/yieldly.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'yieldly',
    cmcId: '10820',
    category: 'Yield',
    chains: ['Algorand'],
    module: 'yieldly/index.js',
    twitter: 'yieldlyfinance',
    audit_links: [
      'https://github.com/HalbornSecurity/PublicReports/blob/master/Algorand%20Smart%20Contract%20Audit/Yieldly_Finance_Lottery_Algorand_Smart_Contract_Halborn_v1_1.pdf',
    ],
    oracles: ['Chainlink'],
    slug: 'yieldly',
    tvl: 5081130.274198198,
    chainTvls: { Algorand: 5081130.274198198, 'Algorand-staking': 13912555.810865512, staking: 13912555.810865512 },
    change_1h: -0.2661532989983755,
    change_1d: -4.116879062804742,
    change_7d: -3.2772116231056856,
    staking: 13912555.810865512,
    fdv: 45408601,
    mcap: 22550248,
  },
  {
    id: '1246',
    name: 'Spartan',
    address: 'bsc:0x3910db0600ea925f63c36ddb1351ab6e2c6eb102',
    symbol: 'SPARTA',
    url: 'https://spartanprotocol.org',
    description:
      'Spartan Protocol is an AMM built on top of Binance Smart Chain utilizing liquidity sensitive fees. Focusing on incentivised liquidity pools and Synths, Spartan Protocol allows users to earn revenue by providing liquidity to pools and forging + staking Synthetic Assets.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/spartan.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'spartan-protocol-token',
    cmcId: '6992',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'spartan-protocol/index.js',
    twitter: 'SpartanProtocol',
    audit_links: [
      'https://github.com/spartan-protocol/resources/blob/master/certik-audit.pdf',
      'https://code423n4.com/reports/2021-07-spartan/',
      'https://www.immunefi.com/bounty/spartanprotocol',
    ],
    listedAt: 1642022683,
    slug: 'spartan',
    tvl: 4892680.307345578,
    chainTvls: { Binance: 4892680.307345578 },
    change_1h: 0.49264284371798794,
    change_1d: -3.594673669037249,
    change_7d: 2.9007664212399504,
    fdv: 47030377,
    mcap: 14866202,
  },
  {
    id: '1254',
    name: 'XDAO',
    address: null,
    symbol: '-',
    url: 'https://www.xdao.app/',
    description:
      'XDAO - MultiChain DAO Ecosystem Built For Decentralized Future, XDAO is the fastest framework to create and manage decentralized autonomous organizations',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/xdao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Binance', 'Polygon', 'Ethereum', 'Heco', 'Fantom', 'Avalanche'],
    module: 'xdao.js',
    twitter: 'xdaoapp',
    audit_links: [
      'https://github.com/xdao-app/xdao-contracts/blob/master/audits/pessimistic/pessimistic.pdf',
      'https://github.com/xdao-app/xdao-contracts/blob/master/audits/hacken/hacken.pdf',
    ],
    listedAt: 1642109094,
    slug: 'xdao',
    tvl: 4870273.7158005815,
    chainTvls: {
      Binance: 3167563.2806411632,
      Ethereum: 107980.79514436114,
      Heco: 1214.9612580000191,
      Avalanche: 18.74147419916501,
      Polygon: 1593450.3127283836,
      Fantom: 45.62455447399102,
    },
    change_1h: 1.3545900455665674,
    change_1d: 13.918770254057762,
    change_7d: null,
  },
  {
    id: '1410',
    name: 'Cropper',
    address: 'solana:DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz',
    symbol: 'CRP',
    url: 'https://cropper.finance',
    description:
      'Cropper is an automated market maker (AMM) built on the Solana blockchain which enable lightning-fast trades, shared liquidity and new features for earning yield.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/cropper.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cropperfinance',
    cmcId: '11387',
    category: 'Dexes',
    chains: ['Solana'],
    oracles: [],
    forkedFrom: [],
    module: 'cropper.js',
    twitter: 'CropperFinance',
    audit_links: [
      'https://github.com/HalbornSecurity/PublicReports/blob/master/Solana%20Program%20Audit/Cropper_Finance_Farm_Solana_Program_Security_Audit_Report_Halborn_Final.pdf',
      'https://github.com/HalbornSecurity/PublicReports/blob/master/Solana%20Program%20Audit/Cropper_Finance_AMM_Program_Security_Audit_Report_Halborn_Final.pdf',
    ],
    listedAt: 1644868660,
    openSource: false,
    slug: 'cropper',
    tvl: 4852889.808317885,
    chainTvls: { staking: 1846761.1419479751, Solana: 4852889.808317885, 'Solana-staking': 1846761.1419479751 },
    change_1h: 0.2702708986725071,
    change_1d: 4.081742348558109,
    change_7d: 8.303394097467745,
    staking: 1846761.1419479751,
    mcap: 3515333,
  },
  {
    id: '830',
    name: 'Revest Finance',
    address: '0x120a3879da835a5af037bb2d1456bebd6b54d4ba',
    symbol: 'RVST',
    url: 'https://revest.finance',
    description:
      'The Revest Protocol pioneers a revolutionary new use of NFTs as financial tools. Individual and project level users can deposit any ERC20 into our interactive FNFTs and set custom unlocking conditions (time, value, or contract based). This high level of composability fills many of the massive gaps in the crypto landscape ranging from something as simple as new KPI based token vesting for launching dev teams to truly game changing implications for derivative and borrowing/lending platforms',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/revest-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'revest-finance',
    cmcId: '12230',
    category: 'NFT Lending',
    chains: ['Ethereum', 'Polygon', 'Fantom', 'Avalanche'],
    module: 'revest/index.js',
    twitter: 'RevestFinance',
    audit_links: ['https://solidity.finance/audits/Revest'],
    oracles: ['Chainlink', 'TWAP'],
    listedAt: 1636669757,
    slug: 'revest-finance',
    tvl: 4816836.305150332,
    chainTvls: {
      Ethereum: 4790773.459835592,
      Polygon: 16187.96346911,
      Avalanche: 218.28438000000003,
      Fantom: 9656.597465629582,
    },
    change_1h: -0.0005308091440952012,
    change_1d: 2.2815611481331217,
    change_7d: -85.27901442268248,
    fdv: 8037040,
    mcap: 4924335,
  },
  {
    id: '1109',
    name: 'APWine',
    address: '0x4104b135dbc9609fc1a9490e61369036497660c8',
    symbol: 'APW',
    url: 'https://apwine.fi',
    description:
      'Speculate on the evolution of the yield generated by different DeFi protocols. Hedge your risk on your passive revenue, converting it into futures. Tokenise the yield generated by your interest-bearing tokens. APWine splits your interest-bearing assets into Principal Tokens and Future Yield Tokens. Sell your APYs ahead of time. Claim your yield at maturity.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/apwine.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'apwine',
    cmcId: '10364',
    category: 'Yield Aggregator',
    chains: ['Ethereum', 'Polygon'],
    module: 'apwine/index.js',
    twitter: 'APWineFinance',
    listedAt: 1640630028,
    slug: 'apwine',
    tvl: 4789981.140149073,
    chainTvls: {
      pool2: 17980.853053569677,
      'Polygon-pool2': 17980.853053569677,
      Ethereum: 4398226.863318084,
      Polygon: 391754.2768309887,
      'Ethereum-staking': 959962.220710857,
      staking: 959962.220710857,
    },
    change_1h: 1.9526014890613794,
    change_1d: -0.6489993307853865,
    change_7d: -46.4969527941219,
    staking: 959962.220710857,
    pool2: 17980.853053569677,
    fdv: 50400645,
    mcap: 9712732,
  },
  {
    id: '1353',
    name: 'Kalmy App',
    address: 'bsc:0x4BA0057f784858a48fe351445C672FF2a3d43515',
    symbol: 'KALM',
    url: 'https://kalmy.app',
    description: 'Kalmy.APP is a DeFi hub. Farming, lending, swaps, NFTs - the goal is to have it all and more.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/kalmy.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'kalmar',
    cmcId: '10099',
    category: 'Yield',
    chains: ['Binance'],
    module: 'kalmy-app/index.js',
    twitter: 'kalmar_io',
    audit_links: [
      'https://hacken.io/wp-content/uploads/2021/06/Kalmar_08062021SC-Audit_Report.pdf',
      'https://hacken.io/wp-content/uploads/2021/04/03042021_Kalmar_SC_Audit_Report.pdf',
    ],
    listedAt: 1643866432,
    slug: 'kalmy-app',
    tvl: 4762144.565366137,
    chainTvls: { Binance: 4762144.565366137, 'Binance-staking': 7876566.848598413, staking: 7876566.848598413 },
    change_1h: -0.19027955131063834,
    change_1d: -0.7104302676184489,
    change_7d: 1.8166146983666067,
    staking: 7876566.848598413,
    fdv: 19437043,
    mcap: 8898885,
  },
  {
    id: '1062',
    name: 'Chest Finance',
    address: null,
    symbol: '-',
    url: 'https://www.chestfinance.xyz/',
    description:
      '​Earn yield on the largest protocols on Solana: All of the collateral deposited to Chest is actively staked to earn additional yield for the users. Earn yield on option strategies: Every week Chest vaults run automated call-cover and put-cover options to earn yield for its users',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/chest-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Options',
    chains: ['Solana'],
    module: 'chest-finance/index.js',
    twitter: 'chestfinance',
    oracles: ['Pyth'],
    listedAt: 1640040907,
    openSource: false,
    slug: 'chest-finance',
    tvl: 4746134.442801966,
    chainTvls: { Solana: 4746134.442801966 },
    change_1h: 0.6489907180473438,
    change_1d: 2.3101989402437795,
    change_7d: -0.004915454346161141,
  },
  {
    id: '1558',
    name: 'GOGOcoin',
    address: 'polygon:0xdd2af2e723547088d3846841fbdcc6a8093313d6',
    symbol: 'GOGO',
    url: 'https://www.gogocoin.io',
    description:
      'GOGOcoin is constructing the most user-friendly open source DeFi protocol for asset management and savings',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/gogocoin.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gogocoin',
    cmcId: '15652',
    category: 'Yield',
    chains: ['Polygon'],
    oracles: [],
    forkedFrom: [],
    module: 'gogocoin/index.js',
    twitter: 'GOGO_coin',
    audit_links: [
      'https://omniscia.io/gogo-staking-rewards/',
      'https://www.gogocoin.io/audit',
      'https://hacken.io/wp-content/uploads/2022/02/GogoProtocol_14022021-SCAudit_Report2.pdf',
    ],
    listedAt: 1647474520,
    slug: 'gogocoin',
    tvl: 4636010.17838436,
    chainTvls: {
      Polygon: 4636010.17838436,
      pool2: 1381653.8652019014,
      'Polygon-pool2': 1381653.8652019014,
      'Polygon-staking': 384382.94603167643,
      staking: 384382.94603167643,
    },
    change_1h: -0.17615790946275922,
    change_1d: -6.045753382242609,
    change_7d: -46.36401891040302,
    staking: 384382.94603167643,
    pool2: 1381653.8652019014,
    mcap: 0,
  },
  {
    id: '1226',
    name: 'Oin Finance',
    address: '0x9aeB50f542050172359A0e1a25a9933Bc8c01259',
    symbol: 'OIN',
    url: 'https://oin.finance',
    description: 'A robust turnkey stablecoin issuance platform built for the multi-chain universe',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/oin-finance.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'oin-finance',
    cmcId: '6870',
    category: 'Algo-Stables',
    chains: ['Near', 'Ethereum', 'Harmony'],
    module: 'oin-finance/index.js',
    twitter: 'FinanceOin',
    oracles: ['DIA'],
    audit_links: ['https://oin-finance.gitbook.io/925/misc.-resources/documentation#oindao-v3-audits'],
    listedAt: 1641873083,
    slug: 'oin-finance',
    tvl: 4625677.246041328,
    chainTvls: { Ethereum: 1189100.875163729, Near: 2287730.18816321, Harmony: 1148846.1827143892 },
    change_1h: 0.631786257544789,
    change_1d: 7.50391793973894,
    change_7d: 1.134476627405931,
    mcap: 2425725,
  },
  {
    id: '579',
    name: 'iTrust Finance',
    address: '0xF88b137cfa667065955ABD17525e89EDCF4D6426',
    symbol: 'ITG',
    url: 'https://itrust.finance',
    description:
      'iTrust Finance creates mutually beneficial relationships between stakers and insurance protocols by maximising rewards and growing cover capacity for all participants of the DAO, and the wider DeFi community.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/itrust-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'itrust-governance-token',
    cmcId: '11652',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'iTrustfinance/index.js',
    twitter: 'iTrustFinance',
    audit_links: ['https://assets.website-files.com/5f99eb79d508ca853be5f2e8/60fd80c015025a7033cf6ede_iTrust.pdf'],
    slug: 'itrust-finance',
    tvl: 4604388.146185809,
    chainTvls: { Ethereum: 4604388.146185809 },
    change_1h: 0.06927008190551476,
    change_1d: 4.027555900448405,
    change_7d: 15.274287915886447,
    mcap: 0,
  },
  {
    id: '344',
    name: 'ZKSwap',
    address: '0xe4815ae53b124e7263f08dcdbbb757d41ed658c6',
    symbol: 'ZKS',
    url: 'https://zks.org/en',
    description: 'ZKSwap is a ZK-Rollups based layer-2 DEX with AMM model.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/zkswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'zkswap',
    cmcId: '8202',
    category: 'Chain',
    chains: ['Ethereum'],
    module: 'zkswap/index.js',
    twitter: 'ZKSwapOfficial',
    slug: 'zkswap',
    tvl: 4538211.194436702,
    chainTvls: { Ethereum: 4538211.194436702 },
    change_1h: 0.000004267687828019007,
    change_1d: 2.2476815653009794,
    change_7d: 9.106659043541868,
    fdv: 168447019,
    mcap: 37639486,
  },
  {
    id: '1577',
    name: 'Sturdy',
    address: null,
    symbol: '-',
    url: 'https://app.sturdy.finance',
    description:
      'Sturdy is a first of its kind DeFi protocol for interest-free borrowing and high yield lending. Rather than charging borrowers interest, Sturdy stakes their collateral and passes the yield to lenders. This model changes the relationship between borrowers and lenders to make Sturdy the first positive-sum lending protocol.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/sturdy.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Fantom'],
    oracles: ['TWAP'],
    forkedFrom: [],
    module: 'sturdy/index.js',
    twitter: 'SturdyFinance',
    audit_links: ['https://www.certik.com/projects/sturdy'],
    listedAt: 1648061916,
    slug: 'sturdy',
    tvl: 4509861.309326099,
    chainTvls: { 'Fantom-borrowed': 4376376.5241814805, borrowed: 4376376.5241814805, Fantom: 4509861.309326099 },
    change_1h: 1.4431661287999304,
    change_1d: 5.25707228159942,
    change_7d: null,
  },
  {
    id: '890',
    name: 'Oh Finance',
    address: '0x16ba8efe847ebdfef99d399902ec29397d403c30',
    symbol: 'OH',
    url: 'https://oh.finance',
    description:
      'Oh! Finance is a decentralized, yield-generation protocol that offers optimized strategies to do more with your DeFi Dollar. Oh! Finance aggregates strategies from trusted DeFi protocols under a tokenized Yield Index, allowing depositors to gain exposure to multiple protocols with a single, gas-efficient transaction.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/oh-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'oh-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Moonriver', 'Avalanche', 'Ethereum'],
    module: 'oh-finance/index.js',
    twitter: 'OhFinanceDefi',
    audit_links: [
      'https://assets.oh.finance/OhFinance_Halborn_Audit.pdf',
      'https://assets.oh.finance/OhFinance_ArmorLabs_Audit.pdf',
    ],
    listedAt: 1637587886,
    slug: 'oh-finance',
    tvl: 4507240.171130931,
    chainTvls: { Ethereum: 108350.70377227229, Moonriver: 3429916.0189520577, Avalanche: 968973.4484066016 },
    change_1h: -0.23249619593110538,
    change_1d: 10.519541506559165,
    change_7d: 1.045344911472995,
    fdv: 3516832,
    mcap: 1084683,
  },
  {
    id: '593',
    name: 'Tranche Finance',
    address: '0x0aee8703d34dd9ae107386d3eff22ae75dd616d1',
    symbol: 'SLICE',
    url: 'https://tranche.finance',
    description:
      'Tranche splits any yield-generating asset from the DeFi ecosystem into two perpetual assets: a fixed rate asset that lets you manage your risk, and a variable rate asset that maximizes your returns.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/tranche-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'tranche-finance',
    cmcId: '8637',
    category: 'Yield',
    chains: ['Ethereum', 'Fantom', 'Polygon', 'Avalanche'],
    module: 'tranche/index.js',
    twitter: 'TrancheFinance',
    audit_links: ['https://docs.tranche.finance/tranchefinance/governance/security-and-development#security-audits'],
    slug: 'tranche-finance',
    tvl: 4472498.305605516,
    chainTvls: {
      Ethereum: 3894592.584113673,
      Avalanche: 139.03692710524103,
      Polygon: 103343.90284894277,
      Fantom: 474422.7817157953,
    },
    change_1h: 0.3065960792750957,
    change_1d: 0.5658008438151256,
    change_7d: 0.1636685381821934,
    mcap: 7074844,
  },
  {
    id: '1472',
    name: 'Electrik Finance',
    address: 'null',
    symbol: '-',
    url: 'https://electrik.finance/',
    description: 'Yield Optimizer on Klaytn Network',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/electrikfinance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Klaytn'],
    oracles: [],
    forkedFrom: ['Beefy Finance'],
    module: 'electrikfinance/index.js',
    twitter: 'electrik_info',
    listedAt: 1645823765,
    slug: 'electrik-finance',
    tvl: 4458209.421490247,
    chainTvls: { Klaytn: 4458209.421490247 },
    change_1h: 0.14880705378594428,
    change_1d: -5.008522795618632,
    change_7d: 45.91089040690045,
  },
  {
    id: '1185',
    name: 'MarginSwap',
    address: '0xaa4e3edb11afa93c41db59842b29de64b72e355b',
    symbol: 'MFI',
    url: 'https://marginswap.finance',
    description:
      'On-chain spot and margin trading protocol for crypto assets. Swap hundreds of tokens with up to 5x leverage',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/marginswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'marginswap',
    cmcId: '8411',
    category: 'Dexes',
    chains: ['Avalanche', 'Ethereum', 'Polygon', 'Binance'],
    module: 'marginswap/index.js',
    twitter: 'marginswap',
    listedAt: 1641252045,
    slug: 'marginswap',
    tvl: 4420055.956535254,
    chainTvls: {
      Binance: 1103.847365165031,
      Ethereum: 155508.76819062448,
      Avalanche: 4176567.7084589237,
      Polygon: 86875.63252054025,
    },
    change_1h: 1.0866401013973785,
    change_1d: 1.336271834514676,
    change_7d: 1.1982852364359644,
    fdv: 1313859,
    mcap: 926553,
  },
  {
    id: '442',
    name: 'Universe XYZ',
    address: '0x618679df9efcd19694bb1daa8d00718eacfa2883 ',
    symbol: 'XYZ',
    url: 'https://universe.xyz',
    description:
      'Universe XYZ - The NFT Universe Built on Ethereum. Launch your own community-driven NFT universe baked with social tools, media services, and distribution - underpinned by the native $XYZ token.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/universe-xyz.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'universe-xyz',
    cmcId: '10979',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'universe/index.js',
    twitter: 'universe_xyz',
    audit_links: ['https://github.com/UniverseXYZ/xyzDAO-PM/tree/master/audits'],
    slug: 'universe-xyz',
    tvl: 4393184.828546385,
    chainTvls: { Ethereum: 4393184.828546385, pool2: 3461492.410937624, 'Ethereum-pool2': 3461492.410937624 },
    change_1h: 0,
    change_1d: 22.71528527689979,
    change_7d: 29.824037527326084,
    pool2: 3461492.410937624,
    fdv: 83048022,
    mcap: 40236989,
  },
  {
    id: '788',
    name: 'Bridge Mutual',
    address: '0x725c263e32c72ddc3a19bea12c5a0479a81ee688',
    symbol: 'BMI',
    url: 'https://www.bridgemutual.io',
    description: 'A risk coverage platform for stablecoins, protocols, smart contracts, exchanges and more.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bridge-mutual.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bridge-mutual',
    cmcId: '8364',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'bridge-mutual/index.js',
    twitter: 'Bridge_Mutual',
    audit_links: [
      'https://uploads-ssl.webflow.com/5fac3e348dbd5932a7578690/60da267d76850e5acfe4e4c2_Bridge%20Mutual%20SC%20Audit_%20ZOkyo.pdf',
      'https://consensys.net/diligence/audits/2021/03/bridge-mutual/',
    ],
    listedAt: 1636133245,
    slug: 'bridge-mutual',
    tvl: 4367337.962091258,
    chainTvls: { Ethereum: 4367337.962091258, 'Ethereum-staking': 1409476.265871016, staking: 1409476.265871016 },
    change_1h: -0.22694610778444257,
    change_1d: 2.5200047530311735,
    change_7d: -26.129198496191947,
    staking: 1409476.265871016,
    fdv: 14790976,
    mcap: 5980830,
  },
  {
    id: '719',
    name: 'Universe Finance',
    address: '0xe4b5936dce1820f84509c89cce0f28c87988bad8',
    symbol: 'UNT',
    url: 'https://www.universe.finance/',
    description:
      'Universe Finance is a Uniswap V3 liquidity management platform based on risk grading and quantitative strategies.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/universe-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'universe-finance-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum', 'Polygon'],
    module: 'universe-finance/index.js',
    twitter: 'Universe_FIN',
    audit_links: [
      'https://www.certik.org/projects/universefinance',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-UniverseFinance-v1.0.pdf',
    ],
    listedAt: 1635395305,
    slug: 'universe-finance',
    tvl: 4337504.692072945,
    chainTvls: { Ethereum: 4288075.375033178, Polygon: 49429.31703976625 },
    change_1h: 1.1398377168884082,
    change_1d: 3.4943947792907437,
    change_7d: 6.845372096171062,
    mcap: 0,
  },
  {
    id: '1136',
    name: 'Rose',
    address: 'aurora:0xdcD6D4e2B3e1D1E1E6Fa8C21C8A323DcbecfF970',
    symbol: 'ROSE',
    url: 'https://app.rose.fi',
    description:
      'Rose is a liquidity protocol on Aurora composed of a stablecoin & wrapped assets exchange, and a Collateralized Debt Position (CDP) based stablecoin utilizing interest bearing tokens as collateral.',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/rose.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Aurora'],
    module: 'rose/index.js',
    twitter: 'RoseOnAurora',
    listedAt: 1640737553,
    slug: 'rose',
    tvl: 4323743.149217706,
    chainTvls: { 'Aurora-staking': 468644.6051384081, Aurora: 4323743.149217706, staking: 468644.6051384081 },
    change_1h: -0.14077238676013337,
    change_1d: 1.884111558881301,
    change_7d: -9.770426197778775,
    staking: 468644.6051384081,
  },
  {
    id: '1053',
    name: 'MooniSwap',
    address: null,
    symbol: '-',
    url: 'https://mooniswap.exchange/#/swap',
    description:
      'Next gen. AMM protocol by 1inch! Redistributes earnings to liquidity providers, capitalizes on user slippages and protects traders from front-running attacks.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/mooniswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '1102',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'mooniswap/index.js',
    twitter: 'mooniswap',
    audit_links: [
      'https://dapp.org.uk/reports/mooniswap.html',
      'https://mooniswap.exchange/docs/mooniswap-audit-report-2.pdf',
      'https://mooniswap.exchange/docs/mooniswap-audit-report-3.pdf',
    ],
    listedAt: 1639945104,
    slug: 'mooniswap',
    tvl: 4316079.239455124,
    chainTvls: { Ethereum: 4316079.239455124 },
    change_1h: 0.7935820469118227,
    change_1d: 5.5480934274374505,
    change_7d: 18.494376438561403,
  },
  {
    id: '320',
    name: 'Depth',
    address: 'heco:0x48c859531254f25e57d1c1a8e030ef0b1c895c27',
    symbol: 'DEP',
    url: 'https://depth.fi/',
    description:
      'Depth is a safe and high efficient stablecoin asset management protocol, based on the HECO Chain. As a trusted stablecoin swap manager, Depth allows users earn interest on deposits and swap of stablecoins in a safe and secure environment, with good depth, low slippage, and low transaction fees.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/depth.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'depth-token',
    cmcId: '9164',
    category: 'Dexes',
    chains: ['Heco', 'Binance'],
    module: 'depth/index.js',
    twitter: 'DepthFi',
    audit_links: ['https://docs.depth.fi/audit'],
    slug: 'depth',
    tvl: 4243065.553627465,
    chainTvls: { Binance: 1176690.4333698226, Heco: 3066373.2238274016 },
    change_1h: -0.002636812319522619,
    change_1d: -0.0480579088691826,
    change_7d: -3.931514253634802,
    fdv: 1877838,
    mcap: 168862,
  },
  {
    id: '1377',
    name: 'Maia DAO',
    address: 'metis:0x72c232D56542Ba082592DEE7C77b1C6CFA758BCD',
    symbol: 'MAIA',
    url: 'https://maiadao.xyz',
    description:
      'MAIA is the first decentralized community owned reserve currency in Metis Andromeda. Including a growing ecosystem of products - starting with Hermes Protocol - and an array of treasury investment strategies.',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/maia-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'maia',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Metis'],
    oracles: [],
    module: 'maia-dao/index.js',
    twitter: 'MaiaDAOMetis',
    language: 'Solidity',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1644441697,
    slug: 'maia-dao',
    tvl: 4205859.119058847,
    chainTvls: { Metis: 4205859.119058847, 'Metis-staking': 9149313.10675525, staking: 9149313.10675525 },
    change_1h: 0.004906906517703646,
    change_1d: 0.533219324363742,
    change_7d: null,
    staking: 9149313.10675525,
    fdv: 1811797,
    mcap: 0,
  },
  {
    id: '566',
    name: 'MacaronSwap',
    address: 'bsc:0xacb2d47827c9813ae26de80965845d80935afd0b',
    symbol: 'MCRN',
    url: 'https://macaronswap.finance',
    description:
      'MacaronSwap is a DEX that allows users to be Liquidity Provider, Farming, Staking, Exchange, Bridge Assets across chains and more...',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/macaronswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'macaronswap',
    cmcId: '8880',
    category: 'Dexes',
    chains: ['Binance', 'Polygon'],
    module: 'macaron/index.js',
    twitter: 'macaronswap',
    audit_links: ['https://www.certik.org/projects/macaronswap'],
    slug: 'macaronswap',
    tvl: 4201427.199796519,
    chainTvls: {
      Binance: 3769179.776149797,
      Polygon: 432247.42364672205,
      'Binance-Treasury': 5636.554177333251,
      'Polygon-Treasury': 760.4292938445257,
      Treasury: 6396.983471177777,
    },
    change_1h: 0.9965640626787007,
    change_1d: 2.2790368549895845,
    change_7d: 3.6913341999367475,
    fdv: 9893513,
    mcap: 603655,
  },
  {
    id: '322',
    name: 'Yogi Finance',
    address: 'bsc:0x88888C8783a88aD40d995073Ab7FBbe8d34aCdA8',
    symbol: 'YOGI',
    url: 'https://yogi.fi',
    description: 'Automated portfolio manager, liquidity provider, and price sensor. Optimized for Polygon & BSC.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/yogi-finance.svg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Polygon', 'Binance'],
    module: 'yogi/index.js',
    twitter: 'yogi_finance',
    audit_links: ['https://docs.yogi.fi/core-concepts/security/audits'],
    forkedFrom: ['Balancer'],
    slug: 'yogi-finance',
    tvl: 4184101.4066764344,
    chainTvls: { Binance: 12638.270800741437, Polygon: 4171463.135875693 },
    change_1h: -0.05200551258432995,
    change_1d: -0.05200551258432995,
    change_7d: 0.0215182348636489,
  },
  {
    id: '601',
    name: 'Warp Protocol',
    address: '0xed40834a13129509a89be39a9be9c0e96a0ddd71',
    symbol: 'WARP',
    url: 'https://www.warp.finance',
    description:
      'Warp Finance is an open-source protocol on Ethereum that is creating a novel use case for unused Liquidity Provider (LP) tokens by allowing them to be used as collateral for borrowing',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/warp-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'warp-finance',
    cmcId: '8439',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'warp/index.js',
    twitter: 'warpfinance',
    audit_links: [
      'https://github.com/warp-finance/warpv2/blob/c0d26e0b1a74dccbe042aae65cc964f0d391630a/audits/Trail%20of%20Bits%20Second%20Audit%20Warp%20Finance.pdf',
    ],
    slug: 'warp-protocol',
    tvl: 4177647.9421821553,
    chainTvls: { Ethereum: 4177647.9421821553 },
    change_1h: 0.00004866114761625795,
    change_1d: 1.7511061176775513,
    change_7d: 10.704285045850554,
    mcap: 6298197,
  },
  {
    id: '1459',
    name: 'Elysia',
    address: '0x4da34f8264cb33a5c9f17081b9ef5ff6091116f4',
    symbol: 'ELFI',
    url: 'https://www.elyfi.world/en',
    description:
      'ELYFI is the lending protocol that lends cryptocurrency using asset tokens minted in ELYSIA as collateral.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/elysia.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'elyfi',
    cmcId: '18186',
    category: 'Lending',
    chains: ['Ethereum', 'Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'elysia/index.js',
    twitter: 'Elysia_HQ',
    audit_links: [
      'https://www.certik.com/projects/elysia',
      'https://github.com/elysia-dev/elyfi-token/tree/main/audit',
    ],
    listedAt: 1645646372,
    slug: 'elysia',
    tvl: 4118416.4601474674,
    chainTvls: {
      Binance: 845912.4825927493,
      Ethereum: 3272503.977554718,
      'Binance-staking': 329911.4011612153,
      'Ethereum-staking': 614253.397045486,
      staking: 944164.7982067014,
    },
    change_1h: -0.10168809697172776,
    change_1d: 1.5524706286826415,
    change_7d: 122.25620601123174,
    staking: 944164.7982067014,
    mcap: 0,
  },
  {
    id: '888',
    name: 'Revault',
    address: 'bsc:0x4fdd92bd67acf0676bfc45ab7168b3996f7b4a3b',
    symbol: 'REVA',
    url: 'https://www.revault.network/',
    description:
      'Revault aims to become the Google search of Defi. Revault was born to solve the two major pains in Defi - finding the right place (including risk) to invest your assets, and maintaining that position to always be on optimum.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/revault.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'revault-network',
    cmcId: '12754',
    category: 'Yield',
    chains: ['Binance'],
    module: 'revault/index.js',
    twitter: 'revaultnetwork',
    audit_links: ['https://paladinsec.co/projects/revault-network/', 'https://www.certik.org/projects/revault'],
    listedAt: 1637556774,
    slug: 'revault',
    tvl: 4073887.6481260695,
    chainTvls: { Binance: 4073887.6481260695 },
    change_1h: 0.04190915194448053,
    change_1d: 1.7528151921624158,
    change_7d: -4.297542560597265,
    fdv: 21991528,
    mcap: 5411476,
  },
  {
    id: '630',
    name: 'Huckleberry',
    address: 'moonriver:0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
    symbol: 'FINN',
    url: 'https://www.huckleberry.finance/#/swap',
    description: 'Huckleberry is a brand-new kind of community driven AMM crosschain DEX ',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/huckleberry.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonriver'],
    module: 'huckleberry/index.js',
    twitter: 'HuckleberryDEX',
    audit_links: [
      'https://github.com/HuckleberryDex/huckleberry-contracts/blob/main/Smart%20contract%20security%20audit%20report-Huckleberry.pdf',
    ],
    forkedFrom: ['Uniswap', 'Compound'],
    slug: 'huckleberry',
    tvl: 4067821.5990253915,
    chainTvls: {
      'Moonriver-staking': 488249.61639734945,
      Moonriver: 4067821.5990253915,
      borrowed: 322677.59134298965,
      'Moonriver-borrowed': 322677.59134298965,
      staking: 488249.61639734945,
    },
    change_1h: 0.31620153574107235,
    change_1d: -2.2760419572453827,
    change_7d: null,
    staking: 488249.61639734945,
  },
  {
    id: '1179',
    name: 'ExinSwap',
    address: null,
    symbol: '-',
    url: 'https://app.exinswap.com',
    description: 'ExinSwap is the automated market making trading platform of Exin.',
    chain: 'Mixin',
    logo: 'https://icons.llama.fi/exinswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Mixin'],
    module: 'exinswap/index.js',
    twitter: null,
    listedAt: 1641193239,
    slug: 'exinswap',
    tvl: 3966386,
    chainTvls: { Mixin: 3966386 },
    change_1h: -0.032809033339859184,
    change_1d: -0.34219526521796695,
    change_7d: 9.297181946675053,
  },
  {
    id: '953',
    name: 'Nearpad',
    address: '0xea7cc765ebc94c4805e3bff28d7e4ae48d06468a',
    symbol: 'PAD',
    url: 'https://www.nearpad.io',
    description: 'Launchpad & DeFi hub of the NEAR ecosystem.',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/nearpad.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'nearpad',
    cmcId: '11590',
    category: 'Dexes',
    chains: ['Aurora'],
    module: 'nearpad/index.js',
    twitter: 'NearPad',
    forkedFrom: ['Uniswap'],
    listedAt: 1638393212,
    slug: 'nearpad',
    tvl: 3940269.159658411,
    chainTvls: { Aurora: 3940269.159658411 },
    change_1h: 1.4120421960846414,
    change_1d: 1.8442443239202788,
    change_7d: 6.730496197175114,
    fdv: 21622605,
    mcap: 1222151,
  },
  {
    id: '1521',
    name: 'Horizon Protocol',
    address: 'bsc:0xc0eff7749b125444953ef89682201fb8c6a917cd',
    symbol: 'HZN',
    url: 'https://horizonprotocol.com/',
    description:
      'Horizon Protocol facilitates the on-chain trading of synthetic assets that represent the real economy. Seeks to provide exposure to real-world assets risk/return profiles via smart contracts on the binance smart chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/horizon-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'horizon-protocol',
    cmcId: '9237',
    category: 'Derivatives',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    forkedFrom: ['Synthetix'],
    module: 'horizon/index.js',
    twitter: 'HorizonProtocol',
    audit_links: ['https://static.horizonprotocol.com/Horizon-Protocol-Smart-Contract-Audit-Report.pdf'],
    listedAt: 1646762203,
    slug: 'horizon-protocol',
    tvl: 3879420.5636281553,
    chainTvls: {
      Binance: 3879420.5636281553,
      'Binance-staking': 749372.1066023132,
      pool2: 1025133.2936872928,
      'Binance-pool2': 1025133.2936872928,
      staking: 749372.1066023132,
    },
    change_1h: 0.6131052066595117,
    change_1d: 1.7633400692245829,
    change_7d: null,
    staking: 749372.1066023132,
    pool2: 1025133.2936872928,
    fdv: 7498355,
    mcap: 1974250,
  },
  {
    id: '1161',
    name: 'NiiFi',
    address: 'nahmii:0x604efD2Ec4aFC77Ba9827685eCad54C8EdcA041B',
    symbol: 'NIIFI',
    url: 'https://www.niifi.com/',
    description:
      'NiiFi is built on nahmii, a patent-pending scaling technology allowing for instant finality, low cost and predictable gas/transaction fees, interoperability, and composability. NiiFi has been built with the future in mind.',
    chain: 'Nahmii',
    logo: 'https://icons.llama.fi/niifi.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'niifi',
    cmcId: '9825',
    category: 'Dexes',
    chains: ['Nahmii'],
    module: 'niifi.js',
    twitter: 'NiiFiDAO',
    forkedFrom: ['Uniswap'],
    listedAt: 1640904409,
    slug: 'niifi',
    tvl: 3846061.250605826,
    chainTvls: { Nahmii: 3846061.250605826 },
    change_1h: 0.3482476260445253,
    change_1d: 1.1430359905239271,
    change_7d: 5.677197217284487,
    mcap: 0,
  },
  {
    id: '571',
    name: 'Aladdin DAO',
    address: '0xb26C4B3Ca601136Daf98593feAeff9E0CA702a8D',
    symbol: 'ALD',
    url: 'https://www.aladdin.club',
    description:
      'AladdinDAO is a decentralized network to shift crypto investments from venture capitalists to wisdom of crowds through collective value discovery. At AladdinDAO, a group of world class experts in DeFi known as the AladdinDAO Boule identifies the most promising DeFi projects and enables AladdinDAO community members to enjoy the returns of their liquidity mining programs. As a result, the protocol will help to reduce information asymmetry and optimize asset allocations for the DeFi space overall.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/aladdin-dao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'aladdindao',
    cmcId: '10272',
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'aladdin-dao/index.js',
    twitter: 'aladdindao',
    audit_links: [
      'https://www.aladdin.club/audits/PeckShield-Audit-Report-AladdinDAO2-v1.0.pdf',
      'https://www.certik.org/projects/aladdindao',
    ],
    forkedFrom: ['Yearn Finance'],
    slug: 'aladdin-dao',
    tvl: 3829674.8047024095,
    chainTvls: { Ethereum: 3829674.8047024095 },
    change_1h: -2.313313984814158e-9,
    change_1d: 1.1743125226423672,
    change_7d: 6.746220354804677,
  },
  {
    id: '1485',
    name: 'WhaleEx',
    address: 'eos:whaleextoken',
    symbol: 'WAL',
    url: 'https://www.whaleex.com/',
    description:
      "WhaleEx is world's first exchange to implement multi-signature smart contract for decentralized asset custody.",
    chain: 'EOS',
    logo: 'https://icons.llama.fi/whaleex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '4395',
    category: 'Dexes',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'whaleex/index.js',
    twitter: 'WhaleExchange',
    listedAt: 1646137364,
    slug: 'whaleex',
    tvl: 3805782.300193643,
    chainTvls: { EOS: 3805782.300193643 },
    change_1h: -0.23565619060025256,
    change_1d: 0.11185264382329763,
    change_7d: 4.554600253304102,
  },
  {
    id: '629',
    name: 'Cozy Finance',
    address: '-',
    symbol: '-',
    url: 'https://www.cozy.finance',
    description: 'Cozy is an open-source protocol for automated and trust-minimized Protection Markets.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/cozy-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'cozy/index.js',
    twitter: 'cozyfinance',
    audit_links: ['https://www.cozy.finance/audits/quantstamp.pdf', 'https://www.cozy.finance/audits/peckshield.pdf'],
    forkedFrom: ['Compound'],
    slug: 'cozy-finance',
    tvl: 3803739.238455732,
    chainTvls: { Ethereum: 3803739.238455732, 'Ethereum-borrowed': 494069.4929078563, borrowed: 494069.4929078563 },
    change_1h: 0.5879322952712585,
    change_1d: 1.728540430327314,
    change_7d: 1.7356242428348168,
  },
  {
    id: '378',
    name: 'MakiSwap',
    address: 'heco:0x5fad6fbba4bba686ba9b8052cf0bd51699f38b93',
    symbol: 'MAKI',
    url: 'https://makiswap.com',
    description:
      "Makiswap is an automated market maker (AMM). You can think of an AMM as a primitive robotic market maker that is always willing to quote prices between two assets according to a simple pricing algorithm. Makiswap can be farmed that can be found on the 'rewards' section on this page.",
    chain: 'Heco',
    logo: 'https://icons.llama.fi/makiswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'makiswap',
    cmcId: '10232',
    category: 'Dexes',
    chains: ['Heco'],
    module: 'makiswap/index.js',
    twitter: 'MakiSwap',
    audit_links: ['https://www.certik.org/projects/makiswap'],
    oracles: ['Chainlink'],
    slug: 'makiswap',
    tvl: 3758521.684250767,
    chainTvls: { Heco: 3758521.684250767, 'Heco-staking': 1178186.3661370808, staking: 1178186.3661370808 },
    change_1h: -0.4276066980605151,
    change_1d: -2.8923825822635933,
    change_7d: null,
    staking: 1178186.3661370808,
    mcap: 3570371,
  },
  {
    id: '1421',
    name: 'Enso Finance',
    address: null,
    symbol: '-',
    url: 'https://www.enso.finance',
    description:
      'Social trading redefined. Create social strategies for yield farming, indexes and arbitrary calls as a person, community, or DAO',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/enso-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Indexes',
    chains: ['Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'enso-finance/index.js',
    twitter: 'EnsoFinance',
    listedAt: 1645036681,
    slug: 'enso-finance',
    tvl: 3748022.450855305,
    chainTvls: { Ethereum: 3748022.450855305 },
    change_1h: 0.5075853441601055,
    change_1d: 3.561456955782006,
    change_7d: 14.034426951737956,
  },
  {
    id: '910',
    name: 'Golff Protocol',
    address: '0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c',
    symbol: 'GOF',
    url: 'https://golff.finance/#/',
    description:
      'Golff is positioned as a one-stop services encrypted bank, committing to creating a light, open and free financial world where everyone can participate.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/golff-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'golff',
    cmcId: '7034',
    category: 'Yield',
    chains: ['Ethereum', 'Heco', 'Binance'],
    module: 'golff-finance/index.js',
    twitter: 'GolffProtocol',
    audit_links: ['https://github.com/golfffinance/audit'],
    listedAt: 1637812811,
    slug: 'golff-protocol',
    tvl: 3742757.7190966764,
    chainTvls: {
      pool2: 237761.82511282002,
      'Heco-staking': 2368.791027370404,
      'Binance-pool2': 88066.13537144296,
      'Ethereum-pool2': 50689.960410205116,
      Binance: 154450.83490850613,
      Ethereum: 2658553.10610769,
      'Binance-staking': 1271.659241870472,
      Heco: 929753.7780804803,
      'Ethereum-staking': 18659.909876398517,
      'Heco-pool2': 99005.72933117194,
      staking: 22300.36014563939,
    },
    change_1h: 0.16477670943837097,
    change_1d: 3.2698985885171794,
    change_7d: 10.26611177747887,
    staking: 22300.36014563939,
    pool2: 237761.82511282002,
    fdv: 6250173,
    mcap: 1327252,
  },
  {
    id: '1203',
    name: 'StoneDefi',
    address: '0xe63d6B308BCe0F6193AeC6b7E6eBa005f41e36AB',
    symbol: 'STN',
    url: 'https://stonedefi.io/',
    description: 'One stop solid yield staking for cross-chain assets.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/stonedefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'stone-token',
    cmcId: '9043',
    category: 'Yield Aggregator',
    chains: ['Polygon', 'Binance', 'Ethereum', 'Avalanche'],
    module: 'stonedefi/index.js',
    twitter: 'DefiStone',
    audit_links: ['https://www.certik.com/projects/stonedefi'],
    forkedFrom: ['Yearn Finance'],
    listedAt: 1641490683,
    slug: 'stonedefi',
    tvl: 3740531.130579008,
    chainTvls: {
      pool2: 85189.72826880243,
      Avalanche: 12611.937721506301,
      'Polygon-pool2': 28140.734183441247,
      'Binance-pool2': 29608.4976644153,
      'Ethereum-pool2': 27440.496420945885,
      Binance: 1235445.3790708901,
      Ethereum: 679761.0193448309,
      'Binance-staking': 6890.901763997549,
      'Ethereum-staking': 30668.775609877484,
      Polygon: 1812712.7944417805,
      'Polygon-staking': 4925.012730198298,
      staking: 42484.690104073336,
    },
    change_1h: 0.19907487906894517,
    change_1d: 2.158444595411055,
    change_7d: 8.671003668940187,
    staking: 42484.690104073336,
    pool2: 85189.72826880243,
    mcap: 1702301,
  },
  {
    id: '555',
    name: 'Genshiro',
    address: 'bsc:0x2cd14cba3f26254beed1d78158cd2b6f91809600',
    symbol: 'GENS',
    url: 'https://genshiro.equilibrium.io',
    description:
      'Genshiro is a cross-chain protocol that helps users seamlessly earn yield, borrow, and trade on margin. Using asset portfolios instead of single collateral vaults',
    chain: 'Polkadot',
    logo: 'https://icons.llama.fi/genshiro.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'genshiro',
    cmcId: '10278',
    category: 'Lending',
    chains: ['Polkadot'],
    module: 'genshiro/index.js',
    twitter: 'GenshiroDeFi',
    audit_links: ['https://drive.google.com/file/d/1HBF2h71Wgs0bUqlmftulp81dgFTPTfUm/view'],
    oracles: ['Band', 'Bluzelle', 'Internal'],
    slug: 'genshiro',
    tvl: 3727357.7099490003,
    chainTvls: { Polkadot: 3727357.7099490003 },
    change_1h: -0.1451625977088895,
    change_1d: 0.7949683515162889,
    change_7d: 4.054309822443898,
    mcap: 0,
  },
  {
    id: '1107',
    name: 'WaterLoan',
    address: 'csc:0x355bf937D42dd19eE60f730D505B0aC03B03e2ae',
    symbol: 'WAT',
    url: 'https://waterloan.finance',
    description: 'Waterloan is a decentralized lending protocol for CoinEx Smart Chain.',
    chain: 'CSC',
    logo: 'https://icons.llama.fi/waterloan.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['CSC'],
    module: 'waterloan/index.js',
    twitter: 'realwaterloan',
    oracles: ['Chainlink'],
    forkedFrom: ['Aave'],
    listedAt: 1640628751,
    slug: 'waterloan',
    tvl: 3719182.5133474544,
    chainTvls: { CSC: 3719182.5133474544, 'CSC-borrowed': 835351.3521080936, borrowed: 835351.3521080936 },
    change_1h: 0.16065974447356268,
    change_1d: -1.4556809053388804,
    change_7d: -1.4573451375265165,
  },
  {
    id: '1412',
    name: 'Crema Finance',
    address: null,
    symbol: '-',
    url: 'https://www.crema.finance',
    description:
      'Crema Finance is a powerful concentrated liquidity protocol built on Solana that provides superior performance for both traders and liquidity providers. It changes the Solana DeFi game by introducing a series of innovations to improve the overall capital efficiency and trading depth.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/crema-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Solana'],
    oracles: [],
    forkedFrom: [],
    module: 'crema.js',
    twitter: 'Crema_Finance',
    listedAt: 1644868985,
    openSource: false,
    slug: 'crema-finance',
    tvl: 3681669.49363902,
    chainTvls: { Solana: 3681669.49363902 },
    change_1h: -0.013888998036065914,
    change_1d: 0.09703379299688208,
    change_7d: 12.155691172405298,
  },
  {
    id: '205',
    name: 'Goose Finance',
    address: 'bsc:0xf952fc3ca7325cc27d15885d37117676d25bfda6',
    symbol: 'EGG',
    url: 'https://www.goosedefi.com/',
    description: '2nd generation yield farming on binance smart chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/goose-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'goose-finance',
    cmcId: '8449',
    category: 'Farm',
    chains: ['Binance'],
    module: 'goose.js',
    twitter: 'Goosedefi',
    audit_links: ['https://www.goosedefi.com/files/hackenAudit.pdf', 'https://www.certik.org/projects/goose-finance'],
    forkedFrom: ['Uniswap'],
    slug: 'goose-finance',
    tvl: 3675780.22,
    chainTvls: { Binance: 3675780.22 },
    change_1h: 0.6285160404697194,
    change_1d: 0.9957823387017868,
    change_7d: 6.6237852142297555,
    fdv: 1017635,
    mcap: 983079,
  },
  {
    id: '498',
    name: 'Pilot Protocol',
    address: 'heco:0x52ee54dd7a68e9cf131b0a57fd6015c74d7140e2',
    symbol: 'PTD',
    url: 'https://p.td',
    description:
      'Pilot is a smart over lending protocol on Heco chain, it is the first over-lending product on the Heco chain.',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/pilot-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'pilot',
    cmcId: '9039',
    category: 'Lending',
    chains: ['Heco'],
    module: 'pilotprotocol/index.js',
    twitter: 'Pilotprotocol',
    slug: 'pilot-protocol',
    tvl: 3642809.0453187935,
    chainTvls: { Heco: 3642809.0453187935 },
    change_1h: -0.10100957001304778,
    change_1d: 1.1237435500230077,
    change_7d: -0.46368104322560555,
    mcap: 0,
  },
  {
    id: '453',
    name: 'Wild Credit',
    address: '0x403d512ab96103562dcafe4635545e8ee2753f6e',
    symbol: 'WILD',
    url: 'https://wild.credit',
    description: 'Wild is a permissionless lending protocol featuring isolated lending pairs.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/wild-credit.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wild-credit',
    cmcId: '10998',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'wildcredit/index.js',
    twitter: 'WildCredit',
    audit_links: ['https://code423n4.com/reports/2021-07-wildcredit/'],
    oracles: ['Chainlink'],
    slug: 'wild-credit',
    tvl: 3623059.3378426544,
    chainTvls: { Ethereum: 3623059.3378426544, 'Ethereum-borrowed': 798635.8881626588, borrowed: 798635.8881626588 },
    change_1h: -1.3812110786462455,
    change_1d: 8.975103664172423,
    change_7d: 63.606477669434184,
    fdv: 13381406,
    mcap: 5405301,
  },
  {
    id: '497',
    name: 'Float Protocol',
    address: '0x24a6a37576377f63f194caa5f518a60f45b42921',
    symbol: 'BANK',
    url: 'https://floatprotocol.com',
    description:
      'Float Protocol is the decentralised monetary system of the future. At its core is the FLOAT token, a floating stablecoin that defends purchasing power in the long-run.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/float-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'float-protocol',
    cmcId: '8612',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'float/index.js',
    twitter: 'FloatProtocol',
    audit_links: ['https://drive.google.com/file/d/1Vg3nCThlArC44JFj7Di5HMWziPGMFbvl/view'],
    slug: 'float-protocol',
    tvl: 3621655.800135532,
    chainTvls: { Ethereum: 3621655.800135532 },
    change_1h: 0.5581744139310842,
    change_1d: 3.889304279301143,
    change_7d: 13.417566471331128,
    fdv: 3905568,
    mcap: 3122421,
  },
  {
    id: '490',
    name: 'Plenty Defi',
    address: 'tezos:KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
    symbol: 'PLENTY',
    url: 'https://www.plentydefi.com',
    description:
      'Plenty aims to intertwine many decentralized markets and instruments. Thus far, a decentralized exchange and yield instruments have been developed, with plenty more to come.',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/plenty-defi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'plenty-defi',
    cmcId: null,
    category: 'Dexes',
    chains: ['Tezos'],
    module: 'plenty/index.js',
    twitter: 'PlentyDeFi',
    audit_links: ['https://plenty-defi.notion.site/Audits-70fdccf107ec42feb0bcf720cb6c5ba5'],
    slug: 'plenty-defi',
    tvl: 3597565,
    chainTvls: { 'Tezos-staking': 0, Tezos: 3597565, staking: 0 },
    change_1h: 0.20274150861519047,
    change_1d: 2.0339776224172823,
    change_7d: 9.571319665617821,
    staking: 0,
  },
  {
    id: '1088',
    name: 'ErgoDEX',
    address: 'ergo:-',
    symbol: 'ERG',
    url: 'https://ergodex.io',
    description:
      'A non-custodial, decentralised exchange that allows a quick, effortless and secure transfer of liquidity between the Ergo and Cardano networks.',
    chain: 'Ergo',
    logo: 'https://icons.llama.fi/ergodex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'ergo',
    cmcId: '1555',
    category: 'Dexes',
    chains: ['Ergo'],
    module: 'ergodex.js',
    twitter: 'ErgoDex',
    oracles: ['Internal'],
    listedAt: 1640305029,
    slug: 'ergodex',
    tvl: 3540289.1848997404,
    chainTvls: { Ergo: 3540289.1848997404 },
    change_1h: -0.9286538776202065,
    change_1d: -6.693685287375601,
    change_7d: 12.90709186702199,
    mcap: 273293056,
  },
  {
    id: '697',
    name: 'Cook Finance',
    address: '0xFF75CEd57419bcaEBe5F05254983b013B0646eF5',
    symbol: 'COOK',
    url: 'https://www.cook.finance',
    description:
      'Cook Finance is a transparent and flexible DeFi Index platform. This two sided platform is suited to a diverse range of users to make it easy to select from a menu of indexes across multiple chains. At the same time on the other side of the platform, index creators can utilize tools to create virtually any imaginable strategy and easily offer it up to the Cook community.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cook-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cook',
    cmcId: '8997',
    category: 'Indexes',
    chains: ['Avalanche', 'Heco', 'Binance', 'Ethereum'],
    module: 'cookfinance/index.js',
    twitter: 'cook_finance',
    audit_links: ['https://github.com/CookFinance/cook-token/tree/master/audits'],
    listedAt: 1634930710,
    slug: 'cook-finance',
    tvl: 3523829.5943762176,
    chainTvls: {
      'Avalanche-staking': 43709.18044685822,
      pool2: 654326.664762924,
      Avalanche: 2009498.6839662753,
      'Heco-staking': 11058.5122371232,
      'Binance-pool2': 98532.10795439656,
      'Ethereum-pool2': 300045.8577435665,
      Binance: 1196162.3775996126,
      Ethereum: 318168.5328103292,
      'Binance-staking': 80581.15020056964,
      'Ethereum-staking': 235115.8907802279,
      'Avalanche-pool2': 240661.67322450475,
      'Heco-pool2': 15087.025840456163,
      staking: 370464.733664779,
    },
    change_1h: -0.0334087872947606,
    change_1d: -0.8616000538870736,
    change_7d: 0.16180098857572034,
    staking: 370464.733664779,
    pool2: 654326.664762924,
    mcap: 1867394,
  },
  {
    id: '873',
    name: 'BSCSwap',
    address: 'bsc:0xacc234978a5eb941665fd051ca48765610d82584',
    symbol: 'BSWAP',
    url: 'https://bscswap.com/#/list',
    description: 'Superior cross-chain AMM with trader focused features',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bscswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    forkedFrom: ['Uniswap'],
    chains: ['Binance'],
    module: 'bscswap/index.js',
    twitter: null,
    listedAt: 1637375764,
    slug: 'bscswap',
    tvl: 3483916.0497792647,
    chainTvls: { Binance: 3483916.0497792647, 'Binance-staking': 0, staking: 0 },
    change_1h: 0.6163896826286503,
    change_1d: 1.3271908995339032,
    change_7d: 8.133961720079185,
    staking: 0,
  },
  {
    id: '608',
    name: 'Cap',
    address: 'arbitrum:0x43044f861ec040db59a7e324c40507addb673142',
    symbol: 'CAP',
    url: 'https://cap.finance',
    description: 'Trade markets from your wallet.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/cap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'cap',
    cmcId: null,
    category: 'Dexes',
    chains: ['Arbitrum'],
    module: 'cap/index.js',
    twitter: 'CapDotFinance',
    oracles: ['Chainlink'],
    slug: 'cap',
    tvl: 3441589.049316141,
    chainTvls: {
      'Arbitrum-staking': 1264646.9273234797,
      Arbitrum: 3441589.049316141,
      Treasury: 561145.7893421696,
      'Arbitrum-Treasury': 561145.7893421696,
      staking: 1264646.9273234797,
    },
    change_1h: 3.2203949378496475,
    change_1d: 7.173339648625827,
    change_7d: -13.48794464056158,
    staking: 1264646.9273234797,
    fdv: 6973942,
    mcap: 5811618,
  },
  {
    id: '1423',
    name: 'Bodh Finance',
    address: null,
    symbol: '-',
    url: 'https://bodh.finance',
    description:
      'Bodh Finance is a decentralized protocol that establishes money markets with algorithmically set interest rates based on supply and demand. This will allow users to lend and borrow assets on Boba Network.',
    chain: 'Boba',
    logo: 'https://icons.llama.fi/bodh-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Boba'],
    oracles: ['Witnet'],
    forkedFrom: ['Compound'],
    module: 'bodhfinance/index.js',
    twitter: 'BodhFinance',
    listedAt: 1645037027,
    slug: 'bodh-finance',
    tvl: 3421469.2466563606,
    chainTvls: { Boba: 3421469.2466563606, 'Boba-borrowed': 1261545.1423463414, borrowed: 1261545.1423463414 },
    change_1h: 0.19102001939508284,
    change_1d: 0.5381617660196838,
    change_7d: -7.352025313392261,
  },
  {
    id: '561',
    name: 'Alita Finance',
    address: 'bsc:0x557233E794d1a5FbCc6D26dca49147379ea5073c',
    symbol: 'ALI',
    url: 'https://app.alita.finance',
    description: 'A DEX that is connecting Centralized and Decentralized Trading Platforms Worldwide.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/alita-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'alita',
    cmcId: '11599',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'alita-finance/index.js',
    twitter: 'AlitaFinance',
    audit_links: ['https://www.certik.org/projects/alitafinance'],
    forkedFrom: ['Uniswap'],
    slug: 'alita-finance',
    tvl: 3403377.3327630265,
    chainTvls: { Binance: 3403377.3327630265, 'Binance-staking': 3487408.770497234, staking: 3487408.770497234 },
    change_1h: 0.4234731179550266,
    change_1d: 1.146854959998393,
    change_7d: 1.2214056571943672,
    staking: 3487408.770497234,
    fdv: 32447410,
    mcap: 0,
  },
  {
    id: '1006',
    name: 'Thorstarter',
    address: '0x69fa0feE221AD11012BAb0FdB45d444D3D2Ce71c',
    symbol: 'XRUNE',
    url: 'https://thorstarter.org',
    description: 'Multichain IDO platform born on @thorchain governed by the Valhalla Venture DAO',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/thorstarter.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'thorstarter',
    cmcId: '10260',
    category: 'Launchpad',
    chains: ['Ethereum'],
    module: 'xrune/index.js',
    twitter: 'thorstarter',
    listedAt: 1639176726,
    slug: 'thorstarter',
    tvl: 3401697.092834188,
    chainTvls: { Ethereum: 3401697.092834188, 'Ethereum-staking': 880770.53401405, staking: 880770.53401405 },
    change_1h: 0.5793892483258389,
    change_1d: -3.810308027945439,
    change_7d: -22.441709487320963,
    staking: 880770.53401405,
    fdv: 133042934,
    mcap: 15186920,
  },
  {
    id: '826',
    name: 'MinMax Finance',
    address: 'iotex:0xe45d95a66cfF6aB5E9b796CF5A36F0669AF3Ec98',
    symbol: 'MAX',
    url: 'https://minmax.finance',
    description:
      'MinMax Finance aims to be the leading cross-chain stablecoin AMM on IoTeX. Built on top of Saddle, MinMax is the first next generation stablecoin AMM protocol on IoTeX. MinMax provides the liquidity foundation for all kind of stablecoins. As IoTeX ’s core cross-chain liquidity network, MinMax helps facilitate the transfer of assets between IoTeX and other blockchains. Users deposit crypto assets into our IoTeX liquidity pools to earn passive yield from transaction fees, token-based incentives, and eventually automated DeFi strategies.',
    chain: 'IoTeX',
    logo: 'https://icons.llama.fi/minmax-finance.png',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['IoTeX'],
    module: 'minmax-finance/index.js',
    twitter: 'minmaxfinance',
    oracles: [],
    forkedFrom: ['Saddle Finance'],
    listedAt: 1636629439,
    slug: 'minmax-finance',
    tvl: 3379549.13040225,
    chainTvls: { IoTeX: 3379549.13040225 },
    change_1h: 0.000060280890366470885,
    change_1d: 0.07534318842172638,
    change_7d: 6.5021256309925235,
  },
  {
    id: '1572',
    name: 'DeFIL',
    address: '0x09ce2b746c32528b7d864a1e3979bd97d2f095ab',
    symbol: 'DFL',
    url: 'https://defil.org/',
    description: 'DeFIL is a decentralized Filecoin lending and finance service platform.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/defil.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'defil',
    cmcId: '11740',
    category: 'Lending',
    chains: ['Ethereum', 'Binance'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'defil/index.js',
    twitter: 'defilofficial',
    audit_links: [
      'https://www.slowmist.com/security-audit-certificate.html?id=e84a975074cb9aef9299f9dec92311fd1458d0bbb4163adfadac8e16e0da3c61',
    ],
    listedAt: 1647978428,
    slug: 'defil',
    tvl: 3361735.293930872,
    chainTvls: {
      Binance: 548487.3280786965,
      Ethereum: 2813247.9658521754,
      'Binance-staking': 2660.5572571625885,
      'Ethereum-staking': 4931604.211158409,
      'Ethereum-borrowed': 0,
      borrowed: 0,
      staking: 4934264.768415571,
    },
    change_1h: -0.2359742231613211,
    change_1d: 0.046582970872151463,
    change_7d: null,
    staking: 4934264.768415571,
    mcap: 0,
  },
  {
    id: '1182',
    name: 'ChargeDeFi',
    address: 'bsc:0x1C6bc8e962427dEb4106aE06A7fA2d715687395c',
    symbol: 'CHARGE',
    url: 'https://www.chargedefi.fi',
    description:
      'ChargeDeFi is a combination of an Algorithmic Stablecoin with rebase mechanics. With Charge the project aims to lay the foundations of a sustainable ecosystem on the Binance smart chain. It features rebasing mechanics to protect the value of the $Static token and keep it pegged to $1 BUSD.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/chargedefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'chargedefi-charge',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Binance'],
    module: 'chargedefi/index.js',
    twitter: 'ChargeDeFi',
    audit_links: ['https://www.certik.com/projects/chargedefi'],
    listedAt: 1641195222,
    slug: 'chargedefi',
    tvl: 3337899.3716477137,
    chainTvls: {
      Binance: 3337899.3716477137,
      'Binance-staking': 4625439.493033129,
      pool2: 1675995.3892860163,
      'Binance-pool2': 1675995.3892860163,
      staking: 4625439.493033129,
    },
    change_1h: 0.0116715928903659,
    change_1d: 3.9853334043890953,
    change_7d: 8.834873985923082,
    staking: 4625439.493033129,
    pool2: 1675995.3892860163,
    mcap: 0,
  },
  {
    id: '1462',
    name: 'deBridge',
    address: null,
    symbol: '-',
    url: 'https://debridge.finance',
    description:
      'deBridge is a cross-chain interoperability and liquidity transfer protocol that allows the decentralized transfer of arbitrary data and assets between various blockchains',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/debridge.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Bridge',
    chains: ['Ethereum', 'Binance', 'Arbitrum', 'Polygon', 'Heco'],
    oracles: [],
    forkedFrom: [],
    module: 'debridge/index.js',
    twitter: 'deBridgeFinance',
    audit_links: ['https://github.com/debridge-finance/debridge-security'],
    listedAt: 1645648202,
    slug: 'debridge',
    tvl: 3322898.350588125,
    chainTvls: {
      Binance: 6217.938455308179,
      Ethereum: 3308844.2165004127,
      Heco: 88.96390663314293,
      Polygon: 2947.485015625959,
      Arbitrum: 4799.746710145323,
    },
    change_1h: -0.39794923687732364,
    change_1d: 0.47008601955383256,
    change_7d: 25.17406987106567,
  },
  {
    id: '486',
    name: 'unFederalReserve',
    address: '0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6',
    symbol: 'eRSDL',
    url: 'https://www.unfederalreserve.com',
    description:
      'unFederalReserve is a banking SaaS company built on blockchain technology. Our banking products are designed for smaller U.S. Treasury chartered banks and non-bank lenders in need of greater liquidity without sacrificing security or compliance.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/unfederalreserve.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'unfederalreserve',
    cmcId: '7553',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'unfederalreserve/index.js',
    twitter: 'Unfederalreser1',
    forkedFrom: ['Compound'],
    audit_links: ['https://lending-docs.unfederalreserve.com/docs/security/#security-audits'],
    slug: 'unfederalreserve',
    tvl: 3227205.9850529474,
    chainTvls: { Ethereum: 3227205.9850529474, 'Ethereum-borrowed': 1141199.7954727071, borrowed: 1141199.7954727071 },
    change_1h: 0.9147502740200366,
    change_1d: 5.7725489270205514,
    change_7d: null,
    fdv: 8595649,
    mcap: 7133947,
  },
  {
    id: '533',
    name: 'OpenOcean',
    address: 'bsc:0x9029fdfae9a03135846381c7ce16595c3554e10a',
    symbol: 'OOE',
    url: 'https://openocean.finance',
    description:
      "OpenOcean is the world's first full aggregation protocol for crypto trading that source liquidity from DeFi and CeFi, and enable cross-chain swaps.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/openocean.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'openocean',
    cmcId: '9938',
    category: 'Dexes',
    chains: ['Binance', 'Ethereum', 'Avalanche'],
    module: 'openocean.js',
    twitter: 'OpenOceanGlobal',
    audit_links: ['https://docs.openocean.finance/security-and-auditing'],
    slug: 'openocean',
    tvl: 3204811.6409400003,
    chainTvls: {
      'Avalanche-staking': 20254.44876,
      pool2: 43876.974414,
      Avalanche: 20254.44876,
      'Binance-pool2': 43876.974414,
      Binance: 2751667.836372,
      Ethereum: 432889.355808,
      'Binance-staking': 2707790.861958,
      'Ethereum-staking': 432889.355808,
      staking: 3160934.666526,
    },
    change_1h: -1.6323200073579187,
    change_1d: -4.608469932018593,
    change_7d: 2.0097155589412665,
    staking: 3160934.666526,
    pool2: 43876.974414,
    mcap: 17979328,
  },
  {
    id: '1474',
    name: 'AtomicHub',
    address: null,
    symbol: '-',
    url: 'https://eos.atomichub.io',
    description:
      'AtomicHub is a one stop solution for creating, trading, buying and selling NFTs that is already used by millions of loyal users.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/atomichub.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Wax', 'EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'atomichub/index.js',
    twitter: 'AtomicHub',
    listedAt: 1645824171,
    slug: 'atomichub',
    tvl: 3198776.473023894,
    chainTvls: { EOS: 32469.473777999996, Wax: 3166306.9992458937 },
    change_1h: 0.7962841154006952,
    change_1d: 0.7790719456926638,
    change_7d: 31.644456074697757,
  },
  {
    id: '564',
    name: 'Fabric',
    address: 'solana:token/EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96',
    symbol: 'FAB',
    url: 'https://stake.fsynth.io',
    description: 'FABRIC is a synthetic asset issuance protocol built on Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/fabric.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'fabric',
    cmcId: '11462',
    category: 'Yield',
    chains: ['Solana'],
    module: 'fabric/index.js',
    twitter: 'official_fabric',
    openSource: false,
    slug: 'fabric',
    tvl: 3159403.8459398,
    chainTvls: { Solana: 3159403.8459398 },
    change_1h: -0.14569273825951257,
    change_1d: 1.2735680058288779,
    change_7d: 2.021695488481569,
    mcap: 7040694,
  },
  {
    id: '984',
    name: 'GalaxyGoogle DAO',
    address: 'avax:0xf2f7ce610a091b94d41d69f4ff1129434a82e2f0',
    symbol: 'GG',
    url: 'https://app.galaxygoggle.money',
    description: 'Galaxy Goggle DAO is a decentralized reserve currency protocol based on the GG token',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/galaxygoogle-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'galaxygoogle-dao',
    cmcId: '15268',
    category: 'Reserve Currency',
    chains: ['Binance', 'Avalanche'],
    module: 'galaxygoggle/index.js',
    twitter: 'GalaxyGoggleDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638809285,
    slug: 'galaxygoogle-dao',
    tvl: 3158724.8115404234,
    chainTvls: {
      Binance: 3158723.2980757495,
      'Avalanche-staking': 0,
      'Binance-staking': 0,
      Avalanche: 1.5134646741147653,
      staking: 0,
    },
    change_1h: -0.4304708906410468,
    change_1d: -0.32873977165888846,
    change_7d: -1.0013014610821216,
    staking: 0,
    fdv: 241.79,
    mcap: 0,
  },
  {
    id: '907',
    name: 'CyborgSwap',
    address: 'cronos:0xA46d5775c18837e380eFB3d8Bf9D315bcd028AB1',
    symbol: 'CBO',
    url: 'https://cyborgswap.io',
    description:
      'NFT oriented DEX on Cronos Chain. Trade, farm, earn and now stake your owned NFTs from several Cronos projects.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/cyborgswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'chronoswap/index.js',
    twitter: 'CyborgSwapDEX',
    forkedFrom: ['Uniswap'],
    audit_links: [
      'https://www.certik.com/projects/chronoswap',
      'https://github.com/Tech-Audit/Smart-Contract-Audits/blob/main/TECHAUDIT_CHRONOSWAP.pdf',
    ],
    listedAt: 1637714110,
    slug: 'cyborgswap',
    tvl: 3152670.4325939603,
    chainTvls: { Cronos: 3152670.4325939603, 'Cronos-staking': 866226.4237676773, staking: 866226.4237676773 },
    change_1h: 0.31834855113621074,
    change_1d: -20.454549614214187,
    change_7d: -36.97617153362223,
    staking: 866226.4237676773,
  },
  {
    id: '729',
    name: 'AnchorSwap',
    address: 'bsc:0x4aac18De824eC1b553dbf342829834E4FF3F7a9F',
    symbol: 'ANCHOR',
    url: 'https://anchorswap.app/',
    description: 'AnchorSwap is a Decentralized Exchange and Automatic Liquidity Acquisition Yield Farm',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/anchorswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'anchorswap',
    cmcId: '12578',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'anchorswap/index.js',
    twitter: 'AnchorSwap',
    audit_links: ['https://docs.anchorswap.finance/uploads/techrate.pdf'],
    listedAt: 1635544563,
    slug: 'anchorswap',
    tvl: 3089716.5817862717,
    chainTvls: { Binance: 3089716.5817862717, 'Binance-staking': 3411061.516927625, staking: 3411061.516927625 },
    change_1h: 0.00005474846570052705,
    change_1d: 0.8963316387593068,
    change_7d: -5.091263982377015,
    staking: 3411061.516927625,
    mcap: 5509178,
  },
  {
    id: '678',
    name: 'JSwap',
    address: 'okex:0x5fac926bf1e638944bb16fb5b787b5ba4bc85b0a',
    symbol: 'JF',
    url: 'https://app.jswap.finance/#/swap',
    description: 'Jswap Finance is the community-driven multi-chain compatible Dex and assets management protocol.',
    chain: 'OKExChain',
    logo: 'https://icons.llama.fi/jswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['OKExChain'],
    module: 'jswap-finance/index.js',
    twitter: 'Jswap_Finance',
    forkedFrom: ['Uniswap'],
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=928799684ad96ef4ed4b0c0fb12a5fae085456f874b19dc4300195b32a5a1431',
      'https://www.slowmist.com/en/security-audit-certificate.html?id=e18f32468d1c52dc0682fbf1c1137df933fabb09526b124c84cd391fce7149bb',
    ],
    listedAt: 1634496168,
    slug: 'jswap',
    tvl: 3082537.6471863296,
    chainTvls: { 'OKExChain-staking': 1897120.6305772595, OKExChain: 3082537.6471863296, staking: 1897120.6305772595 },
    change_1h: 0.004932970569427653,
    change_1d: -0.6744338318819416,
    change_7d: null,
    staking: 1897120.6305772595,
  },
  {
    id: '391',
    name: 'PantherSwap',
    address: 'bsc:0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
    symbol: 'PANTHER',
    url: 'https://pantherswap.com/',
    description:
      'PantherSwap is the first automatic liquidity acquisition yield farm and AMM decentralized exchange running on Binance Smart Chain with lots of unique and creative features that let you earn and win.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pantherswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'pantherswap',
    cmcId: '9778',
    category: 'Yield',
    chains: ['Binance'],
    module: 'pantherswap/index.js',
    twitter: 'PantherSwap',
    audit_links: ['https://docs.pantherswap.com/security/audits'],
    forkedFrom: ['Uniswap'],
    slug: 'pantherswap',
    tvl: 3065190.4862895394,
    chainTvls: { Binance: 3065190.4862895394 },
    change_1h: -0.007794991457473088,
    change_1d: 0.9450961566758878,
    change_7d: 2.0616648210863104,
    mcap: 0,
  },
  {
    id: '411',
    name: 'Pippi',
    address: 'heco:0xaaae746b5e55d14398879312660e9fde07fbc1dc',
    symbol: 'PIPI',
    url: 'https://app.pippi.finance',
    description:
      'Pippi Finance is a decentralized exchange running on Huobi ECO Chain, with lots of features that let you earn and win tokens. It is fast, cheap and easily accessible as it is not difficult to use.',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/pippi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'pippi-finance',
    cmcId: '9502',
    category: 'Dexes',
    chains: ['Heco'],
    module: 'pippifinance/index.js',
    twitter: 'pippishrimpswap',
    audit_links: ['https://github.com/Shrimp-Labs/audit/blob/master/Pippi%20Shrimp_audit.pdf'],
    forkedFrom: ['Uniswap'],
    slug: 'pippi',
    tvl: 3034652.033002542,
    chainTvls: { Heco: 3034652.033002542 },
    change_1h: -0.0902352173235812,
    change_1d: 2.3757588328683426,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '995',
    name: 'Drops',
    address: '0x6bB61215298F296C55b19Ad842D3Df69021DA2ef',
    symbol: 'DOP',
    url: 'https://drops.co',
    description: 'Get more value from your NFT & DeFi assets',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/drops.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'drops-ownership-power',
    cmcId: '9675',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'drops/index.js',
    twitter: 'dropsnft',
    forkedFrom: ['Compound'],
    listedAt: 1638943874,
    slug: 'drops',
    tvl: 3021521.2772681336,
    chainTvls: {
      borrowed: 5091022.944328686,
      staking: 1005404.7605781663,
      Ethereum: 3021521.2772681336,
      'Ethereum-staking': 1005404.7605781663,
      'Ethereum-borrowed': 5091022.944328686,
    },
    change_1h: 0,
    change_1d: 1.0062186575093364,
    change_7d: 6.4950547411678485,
    staking: 1005404.7605781663,
    fdv: 57449359,
    mcap: 18805955,
  },
  {
    id: '544',
    name: 'SoulSwap',
    address: 'fantom:0xe2fb177009ff39f52c0134e8007fa0e4baacbd07',
    symbol: 'SOUL',
    url: 'https://soul.sh',
    description:
      'Soul is an algorithmic, cross-chain AMM and P2P lending protocol (on Fantom) built for traders, investors, developers, and visionaries seeking to unlock a universe of open financial applications.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/soulswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'soul-swap',
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'soulswap/index.js',
    twitter: 'SoulSwapFinance',
    forkedFrom: ['Uniswap'],
    slug: 'soulswap',
    tvl: 3013174.143683617,
    chainTvls: {
      'Fantom-borrowed': 148161.4675665237,
      'Fantom-staking': 697813.5754994551,
      borrowed: 148161.4675665237,
      staking: 697813.5754994551,
      Fantom: 3013174.143683617,
    },
    change_1h: 0.4630857146585612,
    change_1d: -0.6772616651304162,
    change_7d: -1.6774395468239902,
    staking: 697813.5754994551,
    fdv: 7550916,
    mcap: 1218664,
  },
  {
    id: '1213',
    name: 'Ocean Protocol',
    address: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
    symbol: 'OCEAN',
    url: 'https://oceanprotocol.com',
    description:
      'Ocean Protocol unlocks the value of data. Data owners and consumers use Ocean Market app to publish, discover, and consume data in a secure, privacy-preserving fashion. OCEAN holders stake liquidity to data pools. Ocean datatokens wrap data services as industry-standard ERC20 tokens.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/ocean-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ocean-protocol',
    cmcId: '3911',
    category: 'Services',
    chains: ['Ethereum', 'Binance', 'Moonriver', 'Polygon'],
    module: 'ocean-protocol/index.js',
    twitter: 'oceanprotocol',
    audit_links: ['https://github.com/oceanprotocol/contracts/blob/main/docs/REP-Ocean-08_10_2020.pdf'],
    oracles: ['Chainlink'],
    listedAt: 1641595980,
    slug: 'ocean-protocol',
    tvl: 3009516.875895784,
    chainTvls: { Binance: 2030.8880104298587, Ethereum: 3007361.8210163955, Moonriver: 124.1668689582171, Polygon: 0 },
    change_1h: 2.842170943040401e-14,
    change_1d: 1.1196045546041802,
    change_7d: null,
    mcap: 278723554,
  },
  {
    id: '695',
    name: 'Dinosaur Eggs',
    address: 'bsc:0x9a78649501bbaac285ea4187299471b7ad4abd35',
    symbol: 'DSG',
    url: 'https://dsgmetaverse.com',
    description:
      'The dinosaur world is a metaverse built on the Binance Smart Chain (BSC) which includes a trading platform and NFT exchange market, followed by a social networking platform in the future.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/dinosaur-eggs.svg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '12549',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'dinosaureggs/index.js',
    twitter: 'dsgmetaverse',
    audit_links: ['https://www.certik.org/projects/dinosaureggs'],
    listedAt: 1634851801,
    forkedFrom: ['Uniswap'],
    slug: 'dinosaur-eggs',
    tvl: 3009260.8452367303,
    chainTvls: { Binance: 3009260.8452367303 },
    change_1h: -0.8635605372810318,
    change_1d: -0.4136063798794396,
    change_7d: -3.2991069033860896,
  },
  {
    id: '572',
    name: 'Cyclone',
    address: '0x8861cff2366c1128fd699b68304ad99a0764ef9a',
    symbol: 'CYC',
    url: 'https://cyclone.xyz',
    description:
      'Cyclone is a cross-chain, non-custodial, universal privacy-preserving protocol with the decentralized governance. Cyclone applies zkSNARKs to enable transactional privacy for all DeFi components by breaking the on-chain link between depositor and recipient addresses.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cyclone.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cyclone-protocol',
    cmcId: '8590',
    category: 'Yield',
    chains: ['Ethereum', 'Binance', 'IoTeX', 'Polygon'],
    module: 'cyclone/index.js',
    twitter: 'cycloneprotocol',
    audit_links: ['https://docs.cyclone.xyz/audit'],
    slug: 'cyclone',
    tvl: 2970554.5907000005,
    chainTvls: {
      pool2: 4057187.2955988217,
      'IoTeX-staking': 102382.68166842914,
      'Polygon-pool2': 364423.7107820704,
      'IoTeX-pool2': 2027224.4532879016,
      'Binance-pool2': 1155502.5984271346,
      'Ethereum-pool2': 510036.5331017154,
      Binance: 162026.6167,
      IoTeX: 50824.131,
      Ethereum: 2751851.18,
      'Binance-staking': 131734.4536269298,
      Polygon: 5852.663,
      staking: 234117.13529535895,
    },
    change_1h: -10.590813382753012,
    change_1d: -7.963995832429518,
    change_7d: -1.9471106182598135,
    staking: 234117.13529535895,
    pool2: 4057187.2955988217,
    mcap: 0,
  },
  {
    id: '402',
    name: 'Hunny Finance',
    address: 'bsc:0x565b72163f17849832A692A3c5928cc502f46D69',
    symbol: 'HUNNY',
    url: 'https://hunny.finance',
    description:
      'Hunny aims to provide users the easiest way to join the bandwagon of the DeFi world, built on top of PancakeSwap, giving you the tastiest CAKEs topped with HUNNY. We support the underlying DeFi technology and ecosystem to compound user yields through the Binance Smart Chain (BSC)',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/hunny-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'pancake-hunny',
    cmcId: '10514',
    category: 'Yield',
    chains: ['Binance'],
    module: 'hunnyfinance/index.js',
    twitter: 'HunnyFinance',
    audit_links: ['https://www.certik.org/projects/pancakehunny'],
    slug: 'hunny-finance',
    tvl: 2963298.2464105687,
    chainTvls: {
      Binance: 2963298.2464105687,
      'Binance-staking': 880850.2923904216,
      pool2: 938810.7854738934,
      'Binance-pool2': 938810.7854738934,
      staking: 880850.2923904216,
    },
    change_1h: 0.05560744799137751,
    change_1d: 1.4149966947203865,
    change_7d: -0.24415777177594578,
    staking: 880850.2923904216,
    pool2: 938810.7854738934,
    fdv: 1769134,
    mcap: 1222175,
  },
  {
    id: '1231',
    name: 'Vesq',
    address: 'polygon:0x29f1e986fca02b7e54138c04c4f503dddd250558',
    symbol: 'VESQ',
    url: 'https://www.vesq.io',
    description:
      'VESQ is a decentralized reserve currency protocol available on the Polygon (MATIC) Network based on the VSQ token.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/vesq.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'vesq',
    cmcId: '16737',
    category: 'Reserve Currency',
    chains: ['Polygon'],
    module: 'vesq/index.js',
    twitter: 'VESQHQ',
    audit_links: ['https://paladinsec.co/projects/vesq'],
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641875968,
    slug: 'vesq',
    tvl: 2939233.9334715633,
    chainTvls: { Polygon: 2939233.9334715633, 'Polygon-staking': 3341392.2622317355, staking: 3341392.2622317355 },
    change_1h: 2.3331590170982963e-7,
    change_1d: 0.8127503095123529,
    change_7d: 6.795570946253221,
    staking: 3341392.2622317355,
    mcap: 0,
  },
  {
    id: '747',
    name: 'MuesliSwap',
    address: 'cardano:8a1cfae21368b8bebbbed9800fec304e95cce39a2a57dc35e2e3ebaa.MILK',
    symbol: 'MILK',
    url: 'https://muesliswap.com',
    description: 'MuesliSwap is a new decentralized exchange (DEX) operating on the Smart Bitcoin Cash blockchain',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/muesliswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cardano', 'smartBCH'],
    module: 'muesliswap/index.js',
    twitter: 'MuesliSwapTeam',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://github.com/mlabs-haskell/audit'],
    listedAt: 1635842482,
    slug: 'muesliswap',
    tvl: 2908862.8758888897,
    chainTvls: {
      Cardano: 2329923.364469548,
      'Cardano-staking': 16999374.346499998,
      smartBCH: 578939.5114193417,
      staking: 16999374.346499998,
    },
    change_1h: 0.7961330853382549,
    change_1d: 7.828407198693554,
    change_7d: 25.970851917384664,
    staking: 16999374.346499998,
  },
  {
    id: '387',
    name: 'Bearn',
    address: 'bsc:0x81859801b01764d4f0fa5e64729f5a6c3b91435b',
    symbol: 'BFI',
    url: 'https://www.bearn.fi/',
    description:
      'bEarn Fi is a cross-chain product in Decentralized Finance (DeFi) that at its core provides yield generation, algorithmic stablecoin, bridge, gaming aggregation, treasury, AMM DEX, and governance on multi-chain: Binance Smart Chain blockchain (BSC) and Ethereum blockchain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bearn.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'bearn-fi',
    cmcId: '8796',
    category: 'Yield',
    chains: ['Binance'],
    module: 'bearnfi/index.js',
    twitter: 'BearnFi',
    forkedFrom: ['Uniswap', 'Compound'],
    slug: 'bearn',
    tvl: 2896031.5790618276,
    chainTvls: { Binance: 2896031.5790618276 },
    change_1h: -0.14657153985744742,
    change_1d: 0.08361704820391935,
    change_7d: 0.4749698186395932,
    mcap: 30204,
  },
  {
    id: '301',
    name: 'Nerve',
    address: 'bsc:0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
    symbol: 'NRV',
    url: 'https://nerve.fi',
    description:
      'Nerve is a trustless bridge and stableswap AMM designed to on-ramp & trade pegged assets quickly with minimal slippage.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/nerve.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'nerve-finance',
    cmcId: '8755',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'nerve/index.js',
    twitter: 'NerveFinance',
    audit_links: ['https://docs.nerve.fi/fundamentals/audits-and-security'],
    forkedFrom: ['Saddle Finance'],
    slug: 'nerve',
    tvl: 2879987.507015131,
    chainTvls: { Binance: 2879987.507015131, 'Binance-staking': 133483.4620114576, staking: 133483.4620114576 },
    change_1h: 0.1863904317540772,
    change_1d: 0.21552275335659488,
    change_7d: 1.9232303747889006,
    staking: 133483.4620114576,
    fdv: 156256967,
    mcap: 77747624,
  },
  {
    id: '1253',
    name: 'HakuSwap',
    address: 'avax:0x695Fa794d59106cEbd40ab5f5cA19F458c723829',
    symbol: 'HAKU',
    url: 'https://hakuswap.com',
    description:
      'HakuSwap is a crypto world for users to trade, earn, and game. It is the premier choice for projects on Avalanche with features including AMM, NFT, and GameFi.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/hakuswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'hakuswap/index.js',
    twitter: 'HakuSwap',
    audit_links: ['https://hakuswap.com/audit.pdf'],
    listedAt: 1642109094,
    forkedFrom: ['Uniswap'],
    slug: 'hakuswap',
    tvl: 2852981.533551613,
    chainTvls: { 'Avalanche-staking': 230576.69987236185, Avalanche: 2852981.533551613, staking: 230576.69987236185 },
    change_1h: 0.3301700706743276,
    change_1d: 3.3795689070514072,
    change_7d: -13.009196459856824,
    staking: 230576.69987236185,
  },
  {
    id: '193',
    name: 'Unit Protocol',
    address: '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5',
    symbol: 'DUCK',
    url: 'https://app.unit.xyz/',
    description:
      'Unit protocol is a decentralized borrowing protocol that allows you to mint USDP stablecoin using a variety of tokens as collateral.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/unit-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'unit-protocol-duck',
    cmcId: '7977',
    category: 'CDP',
    chains: ['Ethereum'],
    module: 'unit.js',
    twitter: 'unitprotocol',
    audit_links: ['https://github.com/unitprotocol/protocol_docs'],
    slug: 'unit-protocol',
    tvl: 2837208.0186551497,
    chainTvls: { Ethereum: 2837208.0186551497 },
    change_1h: 0.8401064174774717,
    change_1d: 0.8566080676171168,
    change_7d: 12.75702275561919,
    fdv: 7940477,
    mcap: 3116881,
  },
  {
    id: '1458',
    name: 'Equilibrium',
    address: 'eos:EOSDT',
    symbol: 'EOSDT',
    url: 'https://eosdt.com',
    description: 'The #1 decentralized stable currency on EOS that does more than a dollar',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/equilibrium.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'equilibrium-eosdt',
    cmcId: '4017',
    category: 'CDP',
    chains: ['EOS'],
    oracles: ['Oraclize.it', 'Delphioracle', 'LiquidApps'],
    forkedFrom: [],
    module: 'equilibrium/index.js',
    twitter: 'EOSDT',
    language: 'C++',
    listedAt: 1645645017,
    slug: 'equilibrium',
    tvl: 2810401.06798635,
    chainTvls: { EOS: 2810401.06798635 },
    change_1h: -0.34282477229949393,
    change_1d: -1.2551184334239167,
    change_7d: 16.75181035587505,
    mcap: 4644006,
  },
  {
    id: '804',
    name: 'Gyro',
    address: 'bsc:0x1b239abe619e74232c827fbe5e49a4c072bd869d',
    symbol: 'GYRO',
    url: 'https://gyro.money',
    description:
      "Gyro consists of a treasury and liquidity that is managed and owned by the protocol itself. Gyro's algorithm dynamically mints GYRO tokens based on a bond mechanism, and high staking rewards that are designed to control inflation and dilution.",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/gyro.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'gyro',
    cmcId: '12703',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'gyro.js',
    twitter: 'GyroDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1636378556,
    slug: 'gyro',
    tvl: 2808809.6083271746,
    chainTvls: { Binance: 2808809.6083271746, 'Binance-staking': 90336.90446960706, staking: 90336.90446960706 },
    change_1h: -0.07005953274259014,
    change_1d: 0.17921677026104987,
    change_7d: -1.8218139424730424,
    staking: 90336.90446960706,
    fdv: 5557299,
    mcap: 5557299,
  },
  {
    id: '1330',
    name: 'Beluga',
    address: 'fantom:0x4a13a2cf881f5378def61e430139ed26d843df9a',
    symbol: 'BELUGA',
    url: 'https://beluga.fi',
    description:
      'A cross-chain yield optimizer on #Fantom and #0xPolygon with innovative strategies to earn the best yields.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/beluga.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'beluga-fi',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'beluga/index.js',
    twitter: 'beluga_fi',
    audit_links: ['https://callisto.network/beluga-protocol-security-audit'],
    listedAt: 1643486104,
    slug: 'beluga',
    tvl: 2792978.8303131335,
    chainTvls: { Fantom: 2792978.8303131335 },
    change_1h: 0.48834614497377515,
    change_1d: -3.7556167648886856,
    change_7d: 31.818481906399967,
    fdv: 7401085,
    mcap: 0,
  },
  {
    id: '203',
    name: 'Siren',
    address: '0xD23Ac27148aF6A2f339BD82D0e3CFF380b5093de',
    symbol: 'SI',
    url: 'https://sirenmarkets.com/',
    description: 'Decentralized option markets on Ethereum.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/siren.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'siren',
    cmcId: '8701',
    category: 'Options',
    chains: ['Polygon', 'Ethereum'],
    module: 'siren/index.js',
    twitter: 'sirenprotocol',
    audit_links: ['https://docs.sirenmarkets.com/faq-general/for-users#is-the-siren-protocol-safe'],
    slug: 'siren',
    tvl: 2775964.6913907137,
    chainTvls: { Ethereum: 719902.1880941169, Polygon: 2056062.5032965967 },
    change_1h: 0.5580157181257732,
    change_1d: 2.5617031220252358,
    change_7d: 42.85385907423827,
    fdv: 42586176,
    mcap: 9558944,
  },
  {
    id: '152',
    name: 'Augur',
    address: '0x221657776846890989a759ba2973e427dff5c9bb',
    symbol: 'REP',
    url: 'https://www.augur.net/',
    description:
      'A transparent exchange with no limit on what you can bet on, no max limits on the amount you can bet and no rollover requirements.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/augur.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'augur',
    cmcId: '1104',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'augur/index.js',
    twitter: 'AugurProject',
    audit_links: ['https://github.com/AugurProject/augur-audits'],
    oracles: ['Chainlink'],
    slug: 'augur',
    tvl: 2762461.0554807466,
    chainTvls: { Ethereum: 2762461.0554807466 },
    change_1h: 0.605380893269313,
    change_1d: 3.9418116106904506,
    change_7d: 14.974493068254603,
    fdv: 115610870,
    mcap: 115610870,
  },
  {
    id: '521',
    name: 'Pizza',
    address: 'eos:PIZZA',
    symbol: 'PIZZA',
    url: 'https://pizza.finance',
    description: 'Pizza Finance is a decentralized EOS-based lending platform.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/pizza.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'pizza-usde',
    cmcId: '5831',
    category: 'Lending',
    chains: ['EOS'],
    module: 'pizza/index.js',
    twitter: 'PizzaProFi',
    oracles: ['Internal'],
    slug: 'pizza',
    tvl: 2753307.4208,
    chainTvls: { EOS: 2753307.4208 },
    change_1h: -0.11508310191675264,
    change_1d: -2.414595023664276,
    change_7d: 10.70584598311261,
    mcap: 0,
  },
  {
    id: '639',
    name: 'EnterDAO',
    address: '0xd779eEA9936B4e323cDdff2529eb6F13d0A4d66e',
    symbol: 'ENTR',
    url: 'https://enterdao.xyz',
    description:
      'Through a lineup of core products, EnterDAO aims to empower users, metaverse land owners, investors, brands, and degens of all kinds by tackling pain points like access to metaverse land, its capital efficiency, as well as UX and onboarding of metaverse games.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/enterdao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'enterdao',
    cmcId: '12228',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'enterdao/index.js',
    twitter: 'EnterDao',
    slug: 'enterdao',
    tvl: 2744382.654289329,
    chainTvls: { Ethereum: 2744382.654289329 },
    change_1h: 0.22126065699907826,
    change_1d: -3.1853744653303693,
    change_7d: -21.044136298654152,
    fdv: 29235708,
    mcap: 8955462,
  },
  {
    id: '1033',
    name: 'OneSwap',
    address: '0x0b342c51d1592c41068d5d4b4da4a68c0a04d5a4',
    symbol: 'ONES',
    url: 'https://www.oneswap.net',
    description:
      'A fully decentralized exchange protocol on Smart Contract, with permission-free token listing and automated market making.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/oneswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'oneswap-dao-token',
    cmcId: '1170',
    category: 'Dexes',
    chains: ['CSC', 'Ethereum', 'Binance', 'Tron'],
    module: 'oneswap/index.js',
    twitter: 'OneSwap',
    audit_links: ['https://github.com/oneswap/contract_audit_report'],
    listedAt: 1639786180,
    slug: 'oneswap',
    tvl: 2728422.7538602343,
    chainTvls: {
      Binance: 349604.40476400073,
      Ethereum: 1021072.4159039996,
      CSC: 1158788.1299133822,
      Tron: 198957.8032788517,
    },
    change_1h: -0.10228298364584987,
    change_1d: 1.1709950596445822,
    change_7d: 6.826720601925189,
    mcap: 6035018,
  },
  {
    id: '842',
    name: 'Matrix Farm',
    address: null,
    symbol: '-',
    url: 'https://matrix.farm',
    description:
      'Matrix is a Farm Aggregator that helps users to simplify their experience on the Fantom vast ecosystem. Indeed, being a Farm Aggregator, Matrix allows users to easily stake their FTM within different liquidity pools.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/matrix-farm.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Fantom'],
    module: 'matrix.farm/index.js',
    twitter: 'farm_matrix',
    audit_links: ['https://solidity.finance/audits/MatrixFarm'],
    forkedFrom: ['Yearn Finance'],
    listedAt: 1637025517,
    slug: 'matrix-farm',
    tvl: 2721503.069747891,
    chainTvls: { Fantom: 2721503.069747891 },
    change_1h: 1.9582616276283034,
    change_1d: 1.4462643547282283,
    change_7d: 35.82310530429368,
  },
  {
    id: '558',
    name: 'Magic Land',
    address: 'arbitrum:0x2c852D3334188BE136bFC540EF2bB8C37b590BAD',
    symbol: 'MAGIC',
    url: 'https://magicland.fi',
    description:
      'Magicland is an emerging sustainable decentralized one-stop DeFI portal that starts with yield farming powered on Arbitrium.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/magic-land.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['IoTeX', 'Arbitrum'],
    module: 'magicland/index.js',
    twitter: '0xMagicland',
    slug: 'magic-land',
    tvl: 2710580.279019326,
    chainTvls: { IoTeX: 2652907.4618207854, Arbitrum: 57672.81719854055 },
    change_1h: -0.0045850054271880936,
    change_1d: 4725.617151233686,
    change_7d: -0.9876882869807417,
  },
  {
    id: '1097',
    name: 'Niob Finance',
    address: 'bsc:0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b',
    symbol: 'NIOB',
    url: 'https://niob.finance/',
    description:
      'Decentralized Exchange and Automatic Liquidity Acquisition Yield Farm running currently on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/niob.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'niob/index.js',
    twitter: 'niobfinance',
    listedAt: 1640525223,
    slug: 'niob-finance',
    tvl: 2693751.4154861555,
    chainTvls: {
      'Binance-masterchef': 2693751.4154861555,
      pool2: 2332967.7790740984,
      masterchef: 2693751.4154861555,
      'Binance-pool2': 2332967.7790740984,
      Binance: 2693751.4154861555,
      'Binance-staking': 5906016.874757767,
      staking: 5906016.874757767,
    },
    change_1h: 0.19676655811804267,
    change_1d: 1.3733733516682065,
    change_7d: -1.744136089813935,
    staking: 5906016.874757767,
    pool2: 2332967.7790740984,
  },
  {
    id: '361',
    name: 'Whiteheart',
    address: '0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44',
    symbol: 'WHITE',
    url: 'https://www.whiteheart.finance/',
    description: 'Hedge contracts by Whiteheart help #DeFi users to protect USD value of their $ETH & $WBTC',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/whiteheart.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'whiteheart',
    cmcId: '8120',
    category: 'Options',
    chains: ['Ethereum'],
    module: 'whiteheart/index.js',
    twitter: 'WhiteheartDeFi',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Whiteheart-v1.0.pdf',
    ],
    slug: 'whiteheart',
    tvl: 2663516.888837643,
    chainTvls: { Ethereum: 2663516.888837643 },
    change_1h: -0.10977031198321185,
    change_1d: -0.0020169272529528826,
    change_7d: 0.021638382721420157,
    fdv: 2480559,
    mcap: 2480559,
  },
  {
    id: '730',
    name: 'Exodia',
    address: 'fantom:0x3b57f3feaaf1e8254ec680275ee6e7727c7413c7',
    symbol: 'EXOD',
    url: 'https://exodia.fi/',
    description: '$EXOD is a decentralized reserve currency that remains unbound by a peg.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/exodia.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Fantom'],
    module: 'exodia/index.js',
    twitter: 'EXODIAFinance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1635545329,
    slug: 'exodia',
    tvl: 2626653.469725939,
    chainTvls: { 'Fantom-staking': 1451903.9022202694, staking: 1451903.9022202694, Fantom: 2626653.469725939 },
    change_1h: 0.32959058182819945,
    change_1d: -2.216232096918276,
    change_7d: -7.2905584675154245,
    staking: 1451903.9022202694,
  },
  {
    id: '575',
    name: 'Coinswap Space',
    address: 'bsc:0x3bc5798416c1122bcfd7cb0e055d50061f23850d',
    symbol: 'CSS',
    url: 'https://www.coinswap.space',
    description: 'CoinSwap is a DEX with a suite of products available in Decentralized Finance.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/coinswap-space.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'coinswap-space',
    cmcId: '10705',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'coinswap/index.js',
    twitter: 'CoinSwap_Space',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://www.certik.org/projects/coinswap'],
    slug: 'coinswap-space',
    tvl: 2612292.2479563467,
    chainTvls: { Binance: 2612292.2479563467 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '1174',
    name: 'AuroraSwap',
    address: 'aurora:0x12c87331f086c3C926248f964f8702C0842Fd77F',
    symbol: 'BRL',
    url: 'https://www.auroraswap.finance',
    description: 'New Innovative DEX on Aurora Chain.',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/auroraswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'auroraswap',
    cmcId: null,
    category: 'Dexes',
    chains: ['Aurora'],
    module: 'auroraswap/index.js',
    twitter: 'AuroraSwap',
    forkedFrom: ['Uniswap'],
    oracles: ['DIA'],
    listedAt: 1641071171,
    slug: 'auroraswap',
    tvl: 2597704.9300445095,
    chainTvls: { 'Aurora-staking': 67095.7632005734, Aurora: 2597704.9300445095, staking: 67095.7632005734 },
    change_1h: 0.12356651971082044,
    change_1d: -1.078292177073024,
    change_7d: -11.81621155795024,
    staking: 67095.7632005734,
  },
  {
    id: '426',
    name: 'Mint Club',
    address: 'bsc:0x1f3af095cda17d63cad238358837321e95fc5915',
    symbol: 'MINT',
    url: 'https://mint.club',
    description:
      'Mint Club is a smart token building platform that has no need to code and provides instant liquidity. Anyone can launch a smart token with just a few simple clicks.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/mint-club.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mint-club',
    cmcId: '10977',
    category: 'Launchpad',
    chains: ['Binance'],
    module: 'mint-club/index.js',
    twitter: 'MintClubPro',
    audit_links: ['https://github.com/Steemhunt/mint.club-contract/tree/main/audits'],
    slug: 'mint-club',
    tvl: 2585733.5238551195,
    chainTvls: { Binance: 2585733.5238551195 },
    change_1h: 0.4385975981297463,
    change_1d: 0.7968013163731911,
    change_7d: 4.04570115876588,
    fdv: 13177420,
    mcap: 13177420,
  },
  {
    id: '916',
    name: 'Pika Protocol',
    address: null,
    symbol: '-',
    url: 'https://pikaprotocol.com',
    description: 'A Perpetual Swap Exchange',
    chain: 'Optimism',
    logo: 'https://icons.llama.fi/pika-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Derivatives',
    chains: ['Optimism'],
    module: 'pika.js',
    twitter: 'PikaProtocol',
    audit_links: [
      'https://www.certik.com/projects/pikaprotocol',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-PikaPerpV2-v1.0.pdf',
    ],
    oracles: ['Chainlink'],
    listedAt: 1637890789,
    slug: 'pika-protocol',
    tvl: 2530172.86410831,
    chainTvls: { Optimism: 2530172.86410831 },
    change_1h: -0.15899649872788757,
    change_1d: -1.0637288693426683,
    change_7d: -19.877458428525614,
  },
  {
    id: '1188',
    name: 'EverRise',
    address: '0x0cD022ddE27169b20895e0e2B2B8A33B25e63579',
    symbol: 'RISE',
    url: 'https://www.everrise.com',
    description:
      'EverRise is a blockchain technology company that offers bridging and security solutions across blockchains through an ecosystem of decentralized applications.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/everrise.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'everrise',
    cmcId: '15257',
    category: 'Services',
    chains: ['Binance', 'Ethereum', 'Polygon', 'Fantom', 'Avalanche'],
    module: 'everrise/index.js',
    twitter: 'EverRise',
    audit_links: ['https://www.certik.com/projects/everrise'],
    oracles: ['Chainlink'],
    listedAt: 1641256925,
    slug: 'everrise',
    tvl: 2524213.5698822024,
    chainTvls: {
      'Avalanche-staking': 498696.5947203382,
      pool2: 6410030.172056452,
      Avalanche: 172.42980729108865,
      'Fantom-staking': 345751.06179872004,
      'Polygon-pool2': 1610960.2880542753,
      'Binance-pool2': 0,
      'Ethereum-pool2': 2172293.143715461,
      Binance: 916731.6326869038,
      Ethereum: 896850.8693852097,
      'Binance-staking': 27873682.959590856,
      'Ethereum-staking': 3931571.077483066,
      Polygon: 673440.28527484,
      'Avalanche-pool2': 1544694.1643875823,
      'Polygon-staking': 7212171.364659009,
      'Fantom-pool2': 1082082.5758991335,
      Fantom: 37018.35272795789,
      staking: 39861873.05825199,
    },
    change_1h: 0,
    change_1d: 2.460056659623305,
    change_7d: 14.465741062635345,
    staking: 39861873.05825199,
    pool2: 6410030.172056452,
    fdv: 77623056,
    mcap: 75717455,
  },
  {
    id: '945',
    name: 'Jade Protocol',
    address: 'bsc:0x7ad7242A99F21aa543F9650A56D141C57e4F6081',
    symbol: 'JADE',
    url: 'https://jadeprotocol.io/',
    description: 'Jade Protocol is a decentralized reserve currency platform on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/jade-protocol.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'jade-protocol',
    cmcId: '14938',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'jade-protocol/index.js',
    twitter: 'JadeProtocol',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638309367,
    slug: 'jade-protocol',
    tvl: 2521956.534059279,
    chainTvls: { Binance: 2521956.534059279, 'Binance-staking': 15303719.782079775, staking: 15303719.782079775 },
    change_1h: -0.3298008380317867,
    change_1d: 0.11532133111099085,
    change_7d: -0.0670921630534167,
    staking: 15303719.782079775,
    mcap: 21918936,
  },
  {
    id: '535',
    name: 'Teddy Cash',
    address: 'avax:0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC',
    symbol: 'TEDDY',
    url: 'https://teddy.cash',
    description: 'Teddy Cash is a fork of the Liquity protocol for the Avalanche C-Chain.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/teddy-cash.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'teddy',
    cmcId: '11893',
    category: 'CDP',
    chains: ['Avalanche'],
    module: 'teddy/index.js',
    twitter: 'TeddyCashLive',
    forkedFrom: ['Liquity'],
    slug: 'teddy-cash',
    tvl: 2512744.906812768,
    chainTvls: {
      pool2: 6694.987932684271,
      'Avalanche-Treasury': 564909.2505129,
      Avalanche: 2512744.906812768,
      'Avalanche-staking': 150604.70952755588,
      'Avalanche-pool2': 6694.987932684271,
      Treasury: 564909.2505129,
      staking: 150604.70952755588,
    },
    change_1h: 2.2732146686682313,
    change_1d: 4.325818443756674,
    change_7d: 7.600155942472256,
    staking: 150604.70952755588,
    pool2: 6694.987932684271,
    fdv: 1427545,
    mcap: 237873,
  },
  {
    id: '606',
    name: 'RocketSwap',
    address: '-',
    symbol: 'RSWP',
    url: 'https://rocketswap.exchange',
    description:
      'Rocketswap (RSWP) is a community developed AMM (Automated Market Maker) which resides on the Lamden Blockchain.',
    chain: 'Lamden',
    logo: 'https://icons.llama.fi/rocketswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Lamden'],
    module: 'rocketswap.js',
    twitter: 'RSwapOfficial',
    forkedFrom: ['Uniswap'],
    slug: 'rocketswap',
    tvl: 2504657.965126178,
    chainTvls: { Lamden: 2504657.965126178 },
    change_1h: 0.4825180450225304,
    change_1d: 3.188321983283899,
    change_7d: 10.851487151914199,
  },
  {
    id: '728',
    name: 'DefiPlaza',
    address: '0x2f57430a6ceda85a67121757785877b4a71b8e6d',
    symbol: 'DFP2',
    url: 'https://defiplaza.net/',
    description: 'DefiPlaza is the low-cost distributed exchange on Ethereum',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/defiplaza.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'defiplaza',
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'defiplaza.js',
    twitter: 'DefiPlaza',
    audit_links: [
      'https://github.com/pessimistic-io/audits/blob/main/DeFi%20Plaza%20Security%20Analysis%20by%20Pessimistic.pdf',
    ],
    listedAt: 1635540662,
    slug: 'defiplaza',
    tvl: 2485353.836472242,
    chainTvls: { Ethereum: 2485353.836472242 },
    change_1h: 0.020997018466076156,
    change_1d: 1.0935267156493467,
    change_7d: 4.959824519851935,
    fdv: 2294498,
    mcap: 0,
  },
  {
    id: '499',
    name: 'Polycat',
    address: 'polygon:0x3a3df212b7aa91aa0402b9035b098891d276572b',
    symbol: 'FISH',
    url: 'https://polycat.finance',
    description:
      '​Polycat is a value-oriented, sustainable and decentralized hybrid yield optimizer (yield farm and yield aggregator) running on the Polygon blockchain.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polycat.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'polycat-finance',
    cmcId: '10134',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'polycat/index.js',
    twitter: 'PolycatFinance',
    audit_links: ['https://docs.polycat.finance/security#audit'],
    forkedFrom: ['Uniswap'],
    slug: 'polycat',
    tvl: 2439689.81862329,
    chainTvls: {
      Polygon: 2439689.81862329,
      pool2: 0,
      'Polygon-pool2': 0,
      'Polygon-staking': 736416.83235396,
      staking: 736416.83235396,
    },
    change_1h: 0.6915967300714811,
    change_1d: null,
    change_7d: 7.2429220769923575,
    staking: 736416.83235396,
    pool2: 0,
    mcap: 0,
  },
  {
    id: '218',
    name: 'cVault Finance',
    address: '0x62359ed7505efc61ff1d56fef82158ccaffa23d7',
    symbol: 'CORE',
    url: 'https://cvault.finance/',
    description: 'First high yield farmable deflationary DeFi token.\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/cvault-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cvault-finance',
    cmcId: '7242',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'core/index.js',
    twitter: 'CORE_Vault',
    audit_links: ['https://arcadiamgroup.com/audits/CoreFinal.pdf'],
    slug: 'cvault-finance',
    tvl: 2428314.753642914,
    chainTvls: { Ethereum: 2428314.753642914 },
    change_1h: 1.8269163923519756,
    change_1d: 9.577948239411143,
    change_7d: 11.134587151603384,
    fdv: 73484729,
    mcap: 73484729,
  },
  {
    id: '401',
    name: 'ApeRocket',
    address: 'bsc:0xe486a69e432fdc29622bf00315f6b34c99b45e80',
    symbol: 'SPACE',
    url: 'https://aperocket.finance',
    description:
      'ApeRocket Finance is a suite of products in Decentralized Finance (DeFi) that provides yield optimization strategies through the Binance Smart Chain, using ApeSwap liquidity.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/aperocket.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'space-token',
    cmcId: '10441',
    category: 'Yield',
    chains: ['Binance'],
    module: 'aperocket/index.js',
    twitter: 'ApeRocketFi',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-ApeRocket-v1.0.pdf',
    ],
    slug: 'aperocket',
    tvl: 2387195.8092407878,
    chainTvls: { Binance: 2387195.8092407878 },
    change_1h: -0.9871205772293195,
    change_1d: -1.5500993823287246,
    change_7d: 11.055571944467275,
    mcap: 0,
  },
  {
    id: '223',
    name: 'DerivaDEX',
    address: '0x3a880652f47bfaa771908c07dd8673a787daed3a',
    symbol: 'DDX',
    url: 'http://derivadex.com',
    description: 'The next generation of derivatives trading.  \r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/derivadex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'derivadao',
    cmcId: '7228',
    category: 'Derivatives',
    chains: ['Ethereum'],
    module: 'derivadex/index.js',
    twitter: 'DDX_official',
    audit_links: ['https://certificate.quantstamp.com/full/deriva-dex'],
    slug: 'derivadex',
    tvl: 2359006.116278144,
    chainTvls: { Ethereum: 2359006.116278144 },
    change_1h: -0.1383879799031007,
    change_1d: 0.0005119813940552831,
    change_7d: -0.030183980947754208,
    fdv: 300436547,
    mcap: 78397907,
  },
  {
    id: '722',
    name: 'Summit Defi',
    address: 'fantom:0x8f9bccb6dd999148da1808ac290f2274b13d7994',
    symbol: 'SUMMIT',
    url: 'https://summitdefi.com',
    description:
      'Summit is bringing new & unique features to the Fantom DeFi space, starting off with a "Gamified Yield Multiplying" Protocol first on $FTM',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/summit-defi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'summit-defi',
    cmcId: '13123',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'summitdefi/index.js',
    twitter: 'Summitdefi',
    audit_links: ['https://docs.summitdefi.com/code/audit-completed'],
    listedAt: 1635464909,
    slug: 'summit-defi',
    tvl: 2334788.2735889074,
    chainTvls: { 'Fantom-staking': 636261.4948310022, staking: 636261.4948310022, Fantom: 2334788.2735889074 },
    change_1h: 0.3897957186597125,
    change_1d: -5.106743803938187,
    change_7d: -7.015652005685922,
    staking: 636261.4948310022,
    mcap: 0,
  },
  {
    id: '352',
    name: 'Iron Finance',
    address: 'polygon:0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef',
    symbol: 'IRON',
    url: 'https://iron.finance/',
    description: 'Building a multi-chain partial-collateralized algorithmic stablecoin ecosystem.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/iron-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'iron-stablecoin',
    cmcId: null,
    category: 'CDP',
    chains: ['Polygon', 'Fantom', 'Avalanche'],
    module: 'ironfinance/index.js',
    twitter: 'IronFinance',
    audit_links: ['https://docs.iron.finance/bsc/audits', 'https://docs.iron.finance/iron-finance-on-polygon/audit'],
    forkedFrom: ['Frax'],
    slug: 'iron-finance',
    tvl: 2281370.0918517984,
    chainTvls: {
      Avalanche: 10735.785137228831,
      'Polygon-borrowed': 562653.8348631148,
      'Fantom-borrowed': 120.30446301246238,
      Polygon: 2207711.656433823,
      borrowed: 562774.1393261272,
      Fantom: 62922.650280746384,
    },
    change_1h: -2.842170943040401e-14,
    change_1d: 0.225101664266262,
    change_7d: -0.833627047723823,
    mcap: 0,
  },
  {
    id: '670',
    name: 'CarbonSwap',
    address: 'energyweb:0x9cd9CAECDC816C3E7123A4F130A91A684D01f4Dc',
    symbol: 'SUSU',
    url: 'https://carbonswap.exchange/#/swap',
    description:
      'CarbonSwap is a family of interconnected green, sustainability-focused DeFi & NFT products, including initially an automated market-making (AMM) decentralized exchange (DEX) and a bi-directional ERC-to-ERC bridge (Omnibridge) between the Ethereum Mainnet and Energy Web Chain.',
    chain: 'EnergyWeb',
    logo: 'https://icons.llama.fi/carbonswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['EnergyWeb'],
    module: 'carbonswap/index.js',
    twitter: 'carbonswap_fi',
    forkedFrom: ['Uniswap'],
    listedAt: 1634259695,
    slug: 'carbonswap',
    tvl: 2275929.036877717,
    chainTvls: { EnergyWeb: 2275929.036877717 },
    change_1h: 2.5680073914353727,
    change_1d: null,
    change_7d: 6.83388007024071,
  },
  {
    id: '599',
    name: 'Cub Finance',
    address: 'bsc:0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1',
    symbol: 'CUB',
    url: 'https://cubdefi.com',
    description:
      "The goal of Cub Finance is to adopt and build DeFi-based smart contracts for the entire LeoFinance project. Through BEP20 LEO (bLEO), CUB and the adoption of existing use cases in the space, we're using the basic starting point of the Cub Finance yield platform to build a new generation of DeFi apps to expand and service our community of thousands of MAUs.",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/cub-finance.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cub-finance',
    cmcId: '8858',
    category: 'Farm',
    chains: ['Binance'],
    module: 'cubfinance/index.js',
    twitter: 'FinanceLeo',
    audit_links: ['https://www.certik.org/projects/cubfinance'],
    forkedFrom: ['Goose Finance', 'Autofarm'],
    slug: 'cub-finance',
    tvl: 2274095.645358462,
    chainTvls: { Binance: 2274095.645358462 },
    change_1h: 0.38094031980084253,
    change_1d: 1.2654275059013003,
    change_7d: 7.019106715962863,
    mcap: 2869009,
  },
  {
    id: '1432',
    name: 'PegaSys',
    address: '0xE18c200A70908c89fFA18C628fE1B83aC0065EA4',
    symbol: 'PSYS',
    url: 'https://pegasys.finance',
    description: 'Swap, earn, and build with the leading decentralized crypto trading protocol on Syscoin.',
    chain: 'Syscoin',
    logo: 'https://icons.llama.fi/pegasys.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Syscoin'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'pegasys/index.js',
    twitter: 'PegasysDEX',
    listedAt: 1645139464,
    slug: 'pegasys',
    tvl: 2240770.6467235056,
    chainTvls: { Syscoin: 2240770.6467235056 },
    change_1h: 0,
    change_1d: -0.9383030560667862,
    change_7d: 4.735989229527831,
  },
  {
    id: '1375',
    name: 'Animal Farm',
    address: 'bsc:0x3A4C15F96B3b058ab3Fb5FAf1440Cc19E7AE07ce',
    symbol: 'PIGS',
    url: 'https://theanimal.farm',
    description: 'Masterchef Yield farm on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/animal-farm.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Binance'],
    oracles: [],
    module: 'animal-farm/index.js',
    twitter: 'DRIPcommunity',
    language: 'Solidity',
    forkedFrom: ['Sushiswap'],
    listedAt: 1644437338,
    slug: 'animal-farm',
    tvl: 2234818.2418691493,
    chainTvls: {
      'Binance-masterchef': 2234818.2418691493,
      pool2: 7098164.461926125,
      masterchef: 2234818.2418691493,
      'Binance-pool2': 7098164.461926125,
      Binance: 2234818.2418691493,
      'Binance-staking': 9340398.249223696,
      staking: 9340398.249223696,
    },
    change_1h: -0.010674522123338193,
    change_1d: -5.609489502767801,
    change_7d: -52.52292738330754,
    staking: 9340398.249223696,
    pool2: 7098164.461926125,
  },
  {
    id: '568',
    name: 'Scientix',
    address: 'bsc:0x2CFC48CdFea0678137854F010b5390c5144C0Aa5',
    symbol: 'SCIX',
    url: 'https://scientix.finance',
    description:
      'Future-yield-backed Synthetic Token. Interest-free, Self-repaying and Liquidation-free Loans. Your collateral earns you yields in the background',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/scientix.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'scientix',
    cmcId: '11784',
    category: 'Synthetics',
    chains: ['Binance'],
    module: 'scientixfinance/index.js',
    twitter: 'ScientixFinance',
    audit_links: [
      'https://app.inspex.co/library/scientix#?scope=scientix-stakingpool-vault-strategy-votingescrow',
      'https://github.com/sec-bit/scientix-audit-report/blob/main/Scientix_Protocol_Report_EN.pdf',
    ],
    forkedFrom: ['Alchemix'],
    slug: 'scientix',
    tvl: 2227935.22,
    chainTvls: { Binance: 2227935.22 },
    change_1h: -0.0649166316666765,
    change_1d: 4.631684353285536,
    change_7d: 1.7398402121131085,
    mcap: 0,
  },
  {
    id: '1500',
    name: 'Alcor Exchange',
    address: null,
    symbol: '-',
    url: 'https://alcor.exchange',
    description:
      'Alcor is a lego of decentralized finance built on multi-chain, and a provider of solutions in one tap.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/alcor-exchange.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Wax', 'EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'alcor/index.js',
    twitter: 'theparallel_io',
    audit_links: ['https://t.me/alcorexchange/38987'],
    listedAt: 1646333163,
    slug: 'alcor-exchange',
    tvl: 2224658.9156848276,
    chainTvls: { EOS: 8181.481353, Wax: 2216477.434331828 },
    change_1h: 0.4518216188121613,
    change_1d: 4.260057812946798,
    change_7d: 20.54007944963378,
  },
  {
    id: '664',
    name: 'OakFarm',
    address: 'okex:0xc8644956a0c9334a82f26f5773f5dc090d095d2a',
    symbol: 'OKF',
    url: 'https://oakfarm.io',
    description:
      'OKFarm is a high-frequency yield farming optimizor running exclusively on the OKExChain (OEC) and Binance Smart Chain(BSC).',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/oakfarm.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['OKExChain', 'Binance'],
    module: 'oakfarm.js',
    twitter: 'OakFarm_io',
    audit_links: ['https://oakfarm.io/certik.pdf'],
    listedAt: 1634028339,
    slug: 'oakfarm',
    tvl: 2216963.6731241825,
    chainTvls: {
      'OKExChain-staking': 1278.0313724281739,
      OKExChain: 2216956.051108187,
      'Binance-masterchef': 7.62201599523,
      'OKExChain-masterchef': 2216956.051108187,
      pool2: 173.75238979698602,
      masterchef: 2216963.6731241825,
      'Binance-pool2': 0,
      Binance: 7.62201599523,
      'Binance-staking': 0,
      'OKExChain-pool2': 173.75238979698602,
      staking: 1278.0313724281739,
    },
    change_1h: -0.22694610778441415,
    change_1d: 0.10057301012187736,
    change_7d: -0.6587938899067609,
    staking: 1278.0313724281739,
    pool2: 173.75238979698602,
  },
  {
    id: '1528',
    name: 'Blindex',
    address: 'rsk:0x6542a10E68cEAc1Fa0641ec0D799a7492795AAC1',
    symbol: 'BDX',
    url: 'https://blindex.io/',
    description:
      'Blindex is a multi-currency stablecoin DeFi platform. Mint, swap, trade, invest and redeem any FX-pegged coin.All coins are partially collateralized, algorithmically stabilized and completely decentralized.',
    chain: 'RSK',
    logo: 'https://icons.llama.fi/blindex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['RSK'],
    oracles: ['Internal'],
    forkedFrom: ['Frax'],
    module: 'blindex/index.js',
    twitter: 'BlinDEXio',
    audit_links: ['https://docs.blindex.io/smart-contracts/audits'],
    listedAt: 1646949250,
    slug: 'blindex',
    tvl: 2213976.636139167,
    chainTvls: { RSK: 2213976.636139167 },
    change_1h: 0.6600404993727125,
    change_1d: 1.2641172909721519,
    change_7d: 65.82025348663464,
  },
  {
    id: '870',
    name: 'Defily',
    address: 'kardia:0xd675ff2b0ff139e14f86d87b7a6049ca7c66d76e',
    symbol: 'DFL',
    url: 'https://defily.io/#/',
    description:
      'Defily is the first-ever cross-chain Decentralized Finance project on KardiaChain, which will soon bridge to Ethereum, Binance Smart Chain, Harmony, Avalanche, and Tomochain. Defily focuses on bringing DeFi to everyone in a fun and an easy to understand way!',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/defily.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'defily',
    cmcId: null,
    category: 'Yield',
    chains: ['Binance', 'Kardia', 'Harmony'],
    module: 'defilyio/index.js',
    twitter: 'defilyfinance',
    audit_links: ['https://docs.defily.io/resources/smart-contracts-audit'],
    listedAt: 1637374068,
    slug: 'defily',
    tvl: 2186508.6193356058,
    chainTvls: {
      'Binance-staking': 210684.98146934246,
      'Kardia-staking': 210135.8525500987,
      'Harmony-staking': 259726.42985613958,
      staking: 680547.2638755807,
    },
    change_1h: 0.8502360748435791,
    change_1d: 4.018432783995792,
    change_7d: 15.514988329110494,
    staking: 680547.2638755807,
    mcap: 0,
  },
  {
    id: '576',
    name: 'UnoRe',
    address: 'bsc:0x474021845C4643113458ea4414bdb7fB74A01A77',
    symbol: 'UNO',
    url: 'https://unore.io',
    description:
      'Reinsurance is the process of one entity (the reinsurer) taking on all or part of the risk covered under a policy issued by an insurance company in consideration of a premium payment. In other words, it is insurance for insurance companies.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/unore.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'uno-re',
    cmcId: '8875',
    category: 'Insurance',
    chains: ['Ethereum', 'Binance'],
    module: 'unore/index.js',
    twitter: 'unoreinsure',
    audit_links: ['https://github.com/Uno-Re/audit-reports'],
    oracles: ['Chainlink'],
    slug: 'unore',
    tvl: 2166447.728971328,
    chainTvls: { Binance: 499941.5, Ethereum: 1666506.2289713281 },
    change_1h: 0.14645318363146487,
    change_1d: -7.29492283796769,
    change_7d: 8.947709030268385,
    fdv: 82723881,
    mcap: 12670105,
  },
  {
    id: '455',
    name: 'ZooCoin',
    address: 'fantom:0x09e145a1d53c0045f41aeef25d8ff982ae74dd56 ',
    symbol: 'ZOO',
    url: 'https://zoocoin.cash',
    description:
      "Zoo is an ecosystem of decentralized applications powered by Fantom network. Primary project's token (ZOO) is utilized within the ecosystem among various of developed projects - ZooWallet (portfolio monitoring), ZooPet (NFT marketplace), ZooCharts (charting provider), ZooDex (decentralized exchange), ZooTrade (DEX aggregator).",
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/zoocoin.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'zoo-coin',
    cmcId: '9007',
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'zoodex/index.js',
    twitter: 'CoinZoo',
    forkedFrom: ['Uniswap'],
    slug: 'zoocoin',
    tvl: 2153026.822506676,
    chainTvls: { Fantom: 2153026.822506676 },
    change_1h: 0.9248029683861034,
    change_1d: -3.326160114930815,
    change_7d: 5.399441618724481,
    mcap: 0,
  },
  {
    id: '1245',
    name: 'Proxy',
    address: 'polygon:0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591',
    symbol: 'PRXY',
    url: 'https://prxy.fi',
    description:
      'Proxy Finance (PRXY) offers a Bitcoin Yield Strategies utilizing DeFi 2.0 including (3,3) Staking, (1,1) Bonding and (2,2) Programs for other assets. The Proxy Finance network consists of BTCpx token which is a BTC Wrapper as well as the PRXY Governance Token',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/proxy.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'proxy',
    cmcId: '11148',
    category: 'Yield',
    chains: ['Polygon', 'Ethereum'],
    module: 'prxy/index.js',
    twitter: 'BTC_proxy',
    audit_links: [
      'https://github.com/Quillhash/Audit_Reports/blob/master/BTC%20Proxy%20Smart%20Contracts%20Audit%20Report%20-%20QuillAudits.pdf',
    ],
    listedAt: 1642021085,
    slug: 'proxy',
    tvl: 2148428.551014575,
    chainTvls: {
      Ethereum: 66773.6194146,
      Polygon: 2081654.9315999753,
      'Ethereum-staking': 0,
      'Polygon-staking': 2525626.1499105613,
      staking: 2525626.1499105613,
    },
    change_1h: -0.06139475320976828,
    change_1d: 2.581691782231161,
    change_7d: 13.686931331448832,
    staking: 2525626.1499105613,
    mcap: 0,
  },
  {
    id: '1286',
    name: 'OmniDex',
    address: 'telos:0xd2504a02fABd7E546e41aD39597c377cA8B0E1Df',
    symbol: 'CHARM',
    url: 'https://home.omnidex.finance',
    description:
      'OmniDex is an automated market-making (AMM) decentralized exchange. Swap, Farm and earn rewards on the first native DEX built on the Telos EVM!',
    chain: 'Telos',
    logo: 'https://icons.llama.fi/omnidex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Telos'],
    module: 'omnidex/index.js',
    twitter: 'OmniDex1',
    forkedFrom: ['Uniswap'],
    listedAt: 1642579561,
    slug: 'omnidex',
    tvl: 2139127.3327279603,
    chainTvls: { Telos: 2139127.3327279603 },
    change_1h: -58.847657854021485,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '906',
    name: 'Impossible Finance',
    address: 'bsc:0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
    symbol: 'IF',
    url: 'https://impossible.finance',
    description:
      'Impossible Finance is a DeFi-native ecosystem of multi-chain products. The core vision of IF is to nurture and accelerate top-tier blockchain projects as a product-first incubator.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/impossible-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'impossible-finance',
    cmcId: '10932',
    category: 'Launchpad',
    chains: ['Binance'],
    module: 'impossiblefi/index.js',
    twitter: 'impossiblefi',
    audit_links: ['https://github.com/ImpossibleFinance/audits'],
    forkedFrom: ['Uniswap'],
    listedAt: 1637783764,
    slug: 'impossible-finance',
    tvl: 2134017.055998106,
    chainTvls: { Binance: 2134017.055998106, 'Binance-staking': 14575917.505047152, staking: 14575917.505047152 },
    change_1h: 0.7150965618875489,
    change_1d: 0.5600003402292941,
    change_7d: 2.0078403259419417,
    staking: 14575917.505047152,
    fdv: 9974991,
    mcap: 4477002,
  },
  {
    id: '1343',
    name: 'Devil Finance',
    address: 'fantom:0x174c7106AEeCdC11389f7dD21342F05f46CCB40F',
    symbol: 'DEVIL',
    url: 'https://devilfinance.io/',
    description:
      'Devil Finance is a Decentralized Yield Optimizer platform that allows its users to earn compound interest on their crypto holdings',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/devil-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'devil-finance',
    cmcId: '17125',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'devil-finance/index.js',
    twitter: 'DevilFinance_io',
    audit_links: [
      'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_Devil.pdf',
    ],
    listedAt: 1643804913,
    slug: 'devil-finance',
    tvl: 2131024.07385596,
    chainTvls: { pool2: 226009.11058090292, staking: 576953.2193423721, Fantom: 2131024.07385596 },
    change_1h: 0.14023907870499386,
    change_1d: -2.680555136652302,
    change_7d: -4.4666478468710125,
    staking: 576953.2193423721,
    pool2: 226009.11058090292,
    mcap: 0,
  },
  {
    id: '250',
    name: 'BasketDAO',
    address: '0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb',
    symbol: 'BASK',
    url: 'https://basketdao.org/',
    description: 'A community controlled no fee token basket that you are PAID to hold.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/basketdao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'basketdao',
    cmcId: '9157',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'basketdao/index.js',
    twitter: 'BasketDAOOrg',
    audit_links: ['https://github.com/basketdao/contracts/tree/main/audits'],
    slug: 'basketdao',
    tvl: 2084749.6898284452,
    chainTvls: { Ethereum: 2084749.6898284452 },
    change_1h: 0,
    change_1d: 0.02423660087846713,
    change_7d: 5.173465544935922,
    fdv: 183976,
    mcap: 111885,
  },
  {
    id: '139',
    name: 'SnowSwap',
    address: '0xfe9A29aB92522D14Fc65880d817214261D8479AE',
    symbol: 'SNOW',
    url: 'https://snowswap.org/',
    description: "SnowSwap is designed for low slippage stablecoin swaps and is based on Curve's AMM.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/snowswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'snowswap',
    cmcId: '7367',
    category: 'Dexes',
    chains: ['Ethereum', 'Polygon'],
    module: 'snowswap.js',
    twitter: 'snow_swap',
    audit_links: ['https://hackmd.io/@9GUQpanJRF6cloQ0fwyPFw/r1_ctUuqv'],
    forkedFrom: ['Saddle Finance', 'Uniswap'],
    oracles: ['Chainlink'],
    slug: 'snowswap',
    tvl: 2060267.5098781039,
    chainTvls: { Ethereum: 1870271.1874537095, Polygon: 189996.3224243942 },
    change_1h: -0.009254132163675877,
    change_1d: 3.3479483889547623,
    change_7d: 15.431671120467087,
    fdv: 829979,
    mcap: 580428,
  },
  {
    id: '569',
    name: 'Crunchy Network',
    address: 'tezos:KT1XPFjZqCULSnqfKaaYy8hJjeY63UNSGwXg',
    symbol: 'crDAO',
    url: 'https://crunchy.network',
    description:
      'Crunchy provides DeFi services and solutions on Tezos to projects, developers, and end users. We like to think of ourselves as a DeFi-as-a-Service (DaaS) platform. Crunchy is maintained by independent developers and is governed by CrDAO holders.',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/crunchy-network.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Tezos'],
    module: 'crunchynetwork/index.js',
    twitter: 'CrunchyTez',
    slug: 'crunchy-network',
    tvl: 2036157,
    chainTvls: { Tezos: 2036157 },
    change_1h: 1.1134533590566775,
    change_1d: -1.210802124871364,
    change_7d: 5.924720174294109,
  },
  {
    id: '1156',
    name: 'Cykura',
    address: 'solana:BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg',
    symbol: 'CYS',
    url: 'https://cykura.io',
    description:
      'Cykura is the first CLMM on Solana that solves the problem of capital inefficiency and maximizes returns for liquidity providers while offering a smooth and fast experience for traders.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/cykura.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'cykura',
    cmcId: '10217',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'cykura/index.js',
    twitter: 'cykurafi',
    listedAt: 1640830327,
    slug: 'cykura',
    tvl: 2022817.0109746673,
    chainTvls: { Solana: 2022817.0109746673 },
    change_1h: -0.04871766835886149,
    change_1d: -0.26791784450810496,
    change_7d: 59.03063491776476,
  },
  {
    id: '384',
    name: 'Firebird',
    address: 'polygon:0xd78c475133731cd54dadcb430f7aae4f03c1e660',
    symbol: 'HOPE',
    url: 'https://app.firebird.finance/one-pool',
    description:
      'Firebird is a one-stop DeFi platform that aims to revolutionize DeFi services by meeting all your DeFi needs.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/firebird.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'firebird/index.js',
    twitter: 'FinanceFirebird',
    forkedFrom: ['Uniswap'],
    audit_links: [
      'https://docs.firebird.finance/security-safu/audits',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Firebird-Swap-v1.0.pdf',
    ],
    slug: 'firebird',
    tvl: 1989379.0213775558,
    chainTvls: { Polygon: 1989379.0213775558 },
    change_1h: 7.831040704786574e-8,
    change_1d: 1.8738343933093518,
    change_7d: 10.270446061855253,
  },
  {
    id: '990',
    name: 'Powerbomb Finance',
    address: null,
    symbol: '-',
    url: 'https://www.powerbomb.finance',
    description:
      'DeFi yield customizer. Deposit liquidity, choose what you want to earn your yields in. Live on Avalanche and Harmony.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/powerbomb-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'powerbomb/index.js',
    twitter: 'powerbombfi',
    oracles: ['Chainlink'],
    listedAt: 1638882894,
    slug: 'powerbomb-finance',
    tvl: 1968778.1871490001,
    chainTvls: { Avalanche: 1968778.1871490001 },
    change_1h: 0.00019684302954203758,
    change_1d: 0.007775888984483004,
    change_7d: 3.155562038164689,
  },
  {
    id: '1367',
    name: 'WOWswap',
    address: 'bsc:0x4DA996C5Fe84755C80e108cf96Fe705174c5e36A',
    symbol: 'WOW',
    url: 'https://wowswap.io',
    description:
      'WOWswap is a decentralized leveraged trading protocol built on Ethereum, Solana, Arbitrum, Avalanche, Binance Smart Chain, IoTeX, Polygon and HECO Chain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/wowswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'wowswap',
    cmcId: '8605',
    category: 'Dexes',
    chains: ['Binance', 'Avalanche', 'Polygon', 'IoTeX', 'Ethereum', 'Heco', 'Metis'],
    module: 'wowswap/index.js',
    twitter: 'Wowswap_io',
    audit_links: ['https://hacken.io/wp-content/uploads/2021/06/WowSwap_03062021SCAudit_Report_2.pdf'],
    listedAt: 1644226957,
    slug: 'wowswap',
    tvl: 1964829.4096439574,
    chainTvls: {
      Metis: 175.87116117595986,
      Binance: 1361508.2260778798,
      IoTeX: 45916.53241038833,
      Ethereum: 23239.12687871692,
      Heco: 188.76774467241387,
      Avalanche: 454954.6379020887,
      Polygon: 78846.2474690351,
    },
    change_1h: 0.6876862456967103,
    change_1d: 2.5119857709472058,
    change_7d: -4.715276209607069,
    mcap: 2926979,
  },
  {
    id: '516',
    name: 'Qubit',
    address: 'bsc:0x17b7163cf1dbd286e262ddc68b553d899b93f526',
    symbol: 'QBT',
    url: 'https://qbt.fi',
    description: 'Lend to Ascend, Borrow for Tomorrow. Revolutionary Lending Platfrom',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/qubit.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'qubit',
    cmcId: '11414',
    category: 'Lending',
    chains: ['Binance', 'Klaytn'],
    module: 'qubit/index.js',
    twitter: 'QubitFin',
    audit_links: [
      'https://github.com/PancakeBunny-finance/qubit-finance/blob/master/audits/PeckShield-Audit-Report-Qubit-v1.0.pdf',
    ],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    slug: 'qubit',
    tvl: 1956617.9231151443,
    chainTvls: { Binance: 1871071.5099979353, Klaytn: 85546.41311720893 },
    change_1h: 0.08615902134903308,
    change_1d: 2.054532406489031,
    change_7d: 10.798997619925487,
    mcap: 0,
  },
  {
    id: '459',
    name: 'Multiplier',
    address: '0x8a6f3BF52A26a21531514E23016eEAe8Ba7e7018',
    symbol: 'MXX',
    url: 'https://multiplier.finance',
    description:
      'Multiplier is an algorithmic money market system designed to bring secure and unique lending and borrowing opportunities like flash loans onto the Binance Smart Chain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/multiplier.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'multiplier',
    cmcId: '6583',
    category: 'Lending',
    chains: ['Binance', 'Ethereum'],
    module: 'multiplierfinance/index.js',
    twitter: 'MultiplierMXX',
    audit_links: ['https://mcl-docs.multiplier.finance/developers/security-and-audits'],
    oracles: ['Chainlink'],
    slug: 'multiplier',
    tvl: 1955155.9971529066,
    chainTvls: { Binance: 1943778.2955613474, Ethereum: 11377.7015915593 },
    change_1h: -0.03125167000280271,
    change_1d: 2.7081656879577025,
    change_7d: 3.138743385360243,
    mcap: 681662,
  },
  {
    id: '237',
    name: 'Swipe',
    address: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
    symbol: 'SXP',
    url: 'https://swap.swipe.org',
    description: 'Swipe Swap enables a cross-chain automated market maker protocol on Binance Smart Chain & Ethereum.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/swipe.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'swipe',
    cmcId: '4279',
    category: 'Dexes',
    chains: ['Binance', 'Ethereum'],
    module: 'swipeswap.js',
    twitter: 'Swipe',
    audit_links: ['https://www.certik.org/projects/swipe'],
    oracles: ['Chainlink', 'Band'],
    slug: 'swipe',
    tvl: 1946709.7809379245,
    chainTvls: { Binance: 1562031.9728169623, Ethereum: 384677.80812096223 },
    change_1h: 0,
    change_1d: 0.09213210245123094,
    change_7d: 1.1719353460846662,
    mcap: 807461137,
  },
  {
    id: '878',
    name: 'Unilend',
    address: '0x0202be363b8a4820f3f4de7faf5224ff05943ab1',
    symbol: 'UFT',
    url: 'https://unilend.finance',
    description:
      'UniLend is a comprehensive permissionless DeFi protocol. Anyone can list any asset on UniLend to access decentralized trading, lending/borrowing, and the industry’s most cost-effective flash loans.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/unilend.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'unlend-finance',
    cmcId: '7412',
    category: 'Lending',
    chains: ['Polygon', 'Ethereum', 'Binance'],
    module: 'unilend/index.js',
    twitter: 'UniLend_Finance',
    audit_links: ['https://docs.unilend.finance/the-protocol/audit-reports'],
    listedAt: 1637380340,
    slug: 'unilend',
    tvl: 1929590.99618502,
    chainTvls: { Binance: 14764.723510828866, Ethereum: 890024.4993091526, Polygon: 1024801.7733650384 },
    change_1h: 1.2315665731357512,
    change_1d: -0.2757481923284075,
    change_7d: 11.5837153693586,
    mcap: 27490527,
  },
  {
    id: '921',
    name: 'DAO Maker',
    address: '0x0f51bb10119727a7e5ea3538074fb341f56b09ad',
    symbol: 'DAO',
    url: 'https://daomaker.com/vault',
    description:
      'DAO Maker creates growth technologies and funding frameworks for startups, while simultaneously reducing risks for investors.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dao-maker.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'dao-maker',
    cmcId: '8420',
    category: 'Services',
    chains: ['Ethereum', 'Polygon', 'Binance'],
    module: 'daomaker/index.js',
    twitter: 'TheDaoMaker',
    audit_links: [
      'https://learn.daomaker.com/audits',
      'https://drive.google.com/file/d/18Anm53T_BbcRQLWHMthkQgvtLC6CDGW0/view',
      'https://drive.google.com/file/d/1LCgxsQCz7vCDKK1AFxsInpKkVt_1q3q1/view',
      'https://drive.google.com/file/d/1OpTWnZmhKu04kKYgFx89Nrh2Y68mhlv_/view',
      'https://drive.google.com/file/d/1v78FH681imjrnXzjc4Ci_F4bW8oI19q5/view',
      'https://drive.google.com/file/d/17qQwtqSe6uqLKfKYgLQHGc4GyImVx3iM/view',
    ],
    listedAt: 1637977412,
    slug: 'dao-maker',
    tvl: 1928162.249820768,
    chainTvls: {
      Binance: 10892.948944312,
      Ethereum: 1760820.2419022059,
      Polygon: 156449.05897425,
      'Ethereum-staking': 135002300.21239462,
      staking: 135002300.21239462,
    },
    change_1h: -4.381730737540252,
    change_1d: 6564.46531094265,
    change_7d: 7290.228609530446,
    staking: 135002300.21239462,
    fdv: 751748882,
    mcap: 244667006,
  },
  {
    id: '530',
    name: 'Avaware',
    address: 'avax:0x78ea17559b3d2cf85a7f9c2c704eda119db5e6de',
    symbol: 'AVE',
    url: 'https://avaware.network',
    description: 'Avaware.Network is a DeFi hub that features farming, a launchpad, and several NFT collections.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/avaware.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'avaware',
    cmcId: '11293',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'avaware/index.js',
    twitter: 'AvawareAVE',
    slug: 'avaware',
    tvl: 1917295.5551670203,
    chainTvls: { Avalanche: 1917295.5551670205, staking: 63733.05 },
    change_1h: -0.22230444144859973,
    change_1d: 6.561409833976924,
    change_7d: 9.249653453963774,
    staking: 63733.05,
    mcap: 471696,
  },
  {
    id: '410',
    name: 'Swamp Finance',
    address: 'bsc:0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d',
    symbol: 'SWAMP',
    url: 'https://swamp.finance',
    description: 'Swamp.finance is a yield optimizer hosted on Binance Smart Chain (BSC).',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/swamp-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'swampy',
    cmcId: '9082',
    category: 'Yield',
    chains: ['Binance'],
    module: 'swampfinance/index.js',
    twitter: 'SwampFinance',
    audit_links: ['https://docs.swamp.finance/audits'],
    slug: 'swamp-finance',
    tvl: 1883029.9741034615,
    chainTvls: { Binance: 1883029.9741034615 },
    change_1h: 0.3184952418187237,
    change_1d: -5.921001651576489,
    change_7d: -2.3129071856849492,
    fdv: 17560.67,
    mcap: 0,
  },
  {
    id: '1206',
    name: 'WagmiDAO',
    address: 'harmony:0x8750f5651af49950b5419928fecefca7c82141e3',
    symbol: 'GMI',
    url: 'https://wagmidao.io',
    description:
      'WagmiDAO is the first multichain reserve-backed decentralised exchange (RDEX). It’s focused on continuously growing its own Treasury and the value of its native token.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/wagmidao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'wagmidao',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Harmony'],
    module: 'wagmidao/index.js',
    twitter: 'WagmiDAO_',
    audit_links: ['https://docs.wagmidao.io/resources/audits'],
    forkedFrom: ['Uniswap', 'Olympus DAO'],
    listedAt: 1641514188,
    slug: 'wagmidao',
    tvl: 1877644.8034597295,
    chainTvls: { 'Harmony-staking': 530416.3935838237, Harmony: 1877644.8034597295, staking: 530416.3935838237 },
    change_1h: 0.33349581466497114,
    change_1d: 0.23014248292592754,
    change_7d: 3.3335518877813968,
    staking: 530416.3935838237,
    fdv: 18791285,
    mcap: 1442721,
  },
  {
    id: '991',
    name: 'MidasDAO',
    address: 'avax:0x39912d83acb4a373321387300f4fbe88aa5d6f14',
    symbol: 'CROWN',
    url: 'https://midasdao.org',
    description:
      'MidasDAO introduce #DeFiX - automated treasury growth protocol with a steady flow of revenues from Metaverse, P2E & farming that rewards $CROWN investors.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/midasdao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'midasdao',
    cmcId: '15606',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'midasdao.js',
    twitter: 'Midas_DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638897038,
    slug: 'midasdao',
    tvl: 1865033.8622929326,
    chainTvls: { 'Avalanche-staking': 4433753.673325118, Avalanche: 1865033.8622929326, staking: 4433753.673325118 },
    change_1h: 0.19240798847492613,
    change_1d: 0.5168762065153913,
    change_7d: -0.30864266250027583,
    staking: 4433753.673325118,
    mcap: 0,
  },
  {
    id: '825',
    name: 'NFT20',
    address: '0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81',
    symbol: 'MUSE',
    url: 'https://nft20.io',
    description:
      'Trade, swap & sell NFTs. The NFT20 protocol offers NFT liquidity pools to help developers build the next generation of NFT apps.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/nft20.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'muse-2',
    cmcId: '7805',
    category: 'NFT Marketplace',
    chains: ['Ethereum', 'Polygon'],
    module: 'nft20.js',
    twitter: 'niftymuseum',
    listedAt: 1636626538,
    slug: 'nft20',
    tvl: 1862981.342409137,
    chainTvls: {
      pool2: 2671258.640662401,
      'Ethereum-pool2': 2671258.640662401,
      Ethereum: 1862919.3933735297,
      Polygon: 61.94903560716425,
      'Ethereum-staking': 5755637.387855963,
      staking: 5755637.387855963,
    },
    change_1h: 0.5938794267006671,
    change_1d: 2.228508679175306,
    change_7d: 11.81093010930823,
    staking: 5755637.387855963,
    pool2: 2671258.640662401,
    fdv: 27819552,
    mcap: 25069843,
  },
  {
    id: '988',
    name: 'Vaporwave',
    address: 'aurora:0x2451dB68DeD81900C4F16ae1af597E9658689734',
    symbol: 'VWAVE',
    url: 'https://www.vaporwave.farm',
    description: 'Vaporwave Finance is a yield optimizer on the Aurora blockchain.',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/vaporwave.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Aurora'],
    module: 'vaporwave.js',
    twitter: 'vwavefinance',
    audit_links: ['https://paladinsec.co/projects/vaporwave-finance'],
    oracles: ['TWAP'],
    forkedFrom: ['Beefy Finance'],
    listedAt: 1638881757,
    slug: 'vaporwave',
    tvl: 1812197.55,
    chainTvls: { Aurora: 1812197.55 },
    change_1h: -4.637430858820792,
    change_1d: -4.223860818657855,
    change_7d: 3.5386900502846856,
  },
  {
    id: '621',
    name: 'Trava Finance',
    address: 'bsc:0x0391be54e72f7e001f6bbc331777710b4f2999ef',
    symbol: 'TRAVA',
    url: 'https://trava.finance',
    description:
      "TRAVA is the world's first decentralized lending marketplace. TRAVA performs blockchain data analysis to optimize pool parameters, calculate credit score, and thus increases profit and decreases risks for all users.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/trava-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'trava-finance',
    cmcId: '11209',
    category: 'Lending',
    chains: ['Binance', 'Fantom'],
    module: 'trava/index.js',
    twitter: 'trava_finance',
    audit_links: ['https://hacken.io/audits/#trava_finance', 'https://www.certik.org/projects/trava'],
    oracles: ['Chainlink', 'OraiChain'],
    slug: 'trava-finance',
    tvl: 1795733.8663022995,
    chainTvls: { Binance: 1410805.3120155458, Fantom: 384928.5542867537 },
    change_1h: 0.35499573665236994,
    change_1d: -4.190178632588442,
    change_7d: -25.69900970962196,
    fdv: 16119666,
    mcap: 1640622,
  },
  {
    id: '1547',
    name: 'Jumbo Exchange',
    address: 'near:token.jumbo_exchange.near',
    symbol: 'JUMBO',
    url: 'https://jumbo.exchange/swap',
    description: 'Jumbo provides Ecosystem-Wide Liquidity for users and projects',
    chain: 'Near',
    logo: 'https://icons.llama.fi/jumbo-exchange.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Near'],
    oracles: [],
    forkedFrom: [],
    module: 'jumboexchange/index.js',
    twitter: 'jumbo_exchange',
    listedAt: 1647374487,
    slug: 'jumbo-exchange',
    tvl: 1788104.870943289,
    chainTvls: { Near: 1788104.870943289 },
    change_1h: 0.00028727779594817093,
    change_1d: 1.6745253277120895,
    change_7d: 28.838802336344116,
  },
  {
    id: '392',
    name: 'WardenSwap',
    address: 'bsc:0x0feadcc3824e7f3c12f40e324a60c23ca51627fc',
    symbol: 'WAD',
    url: 'https://www.wardenswap.com',
    description:
      'Wardenswap is not just another decentralized exchange (DEX). When you trade on Wardenswap, you will receive a much better price than any other DEX. If there is another pool that has a price ready to be arbitraged, Wardenswap will also take the deal for you. Wardenswap is the gateway to all decentralized exchanges on the earth.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/wardenswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'warden',
    cmcId: '8981',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'wardenswap/index.js',
    twitter: 'WardenSwap',
    audit_links: ['https://www.certik.org/projects/wardenswap'],
    forkedFrom: ['Uniswap'],
    slug: 'wardenswap',
    tvl: 1772938.632293172,
    chainTvls: { Binance: 1772938.632293172, 'Binance-staking': 323789.6318084328, staking: 323789.6318084328 },
    change_1h: -0.07402166686961209,
    change_1d: -0.30342325448677343,
    change_7d: 5.118063856519257,
    staking: 323789.6318084328,
    fdv: 40097614,
    mcap: 9553370,
  },
  {
    id: '785',
    name: 'LootSwap',
    address: 'harmony:0xbda99c8695986b45a0dd3979cc6f3974d9753d30',
    symbol: 'LOOT',
    url: 'https://lootswap.finance',
    description:
      'LootSwap is a multi-tier crypto project with three big milestones to achieve. The first is launching a DEX on Harmony One. The second biggest milestones for the project are the GUILDs launch and the NFT marketplace. LootSwap is aiming to be an all-in-one solution from crypto to gamer.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/lootswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'lootswap/index.js',
    twitter: 'lootswapfinance',
    forkedFrom: ['Uniswap'],
    listedAt: 1636058049,
    slug: 'lootswap',
    tvl: 1725453.1526579391,
    chainTvls: { Harmony: 1725453.1526579391 },
    change_1h: 2.866071456936808,
    change_1d: -1.4715845039568478,
    change_7d: 5.492315420638889,
  },
  {
    id: '1469',
    name: 'BtnGroup',
    address: 'secret:secret1yxcexylwyxlq58umhgsjgstgcg2a0ytfy4d9lt',
    symbol: 'BUTT',
    url: 'https://btn.group',
    description:
      'Home of the $BUTT, Button Swap (DEX aggregator), Pools (Yield optimizers), Mount Doom, Block Locker, Password Manager and many other Secret Network DAPPS and APPS.',
    chain: 'Secret',
    logo: 'https://icons.llama.fi/btngroup.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'buttcoin-2',
    cmcId: null,
    category: 'Yield',
    chains: ['Secret'],
    oracles: [],
    forkedFrom: [],
    module: 'btn-group.js',
    twitter: 'BtnGroup',
    listedAt: 1645820777,
    slug: 'btngroup',
    tvl: 1697237.83,
    chainTvls: { Secret: 1697237.83 },
    change_1h: 0.6586375103785542,
    change_1d: 0.27100453042683625,
    change_7d: 19.844237456559185,
    fdv: 14496539,
    mcap: 1556923,
  },
  {
    id: '1285',
    name: 'Alphadex',
    address: '0x8E7Cd893D8f371051a39aA65976Bca22d7b02A60',
    symbol: 'ROAR',
    url: 'https://alphadex.io',
    description: 'The Ultimate Cross-Chain AMM, Incubator and NFT Launchpad powered by #Moonriver',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/alphadex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonriver'],
    module: 'alphadex.js',
    twitter: 'thealphadex',
    forkedFrom: ['Uniswap'],
    listedAt: 1642569490,
    slug: 'alphadex',
    tvl: 1675132.0050790347,
    chainTvls: { Moonriver: 1675132.0050790347 },
    change_1h: 1.3232515940267149,
    change_1d: 3.7474267218075,
    change_7d: 3.046615740970452,
  },
  {
    id: '1227',
    name: 'Empyrean DAO',
    address: 'aurora:0xE9F226a228Eb58d408FdB94c3ED5A18AF6968fE1',
    symbol: 'EMPYR',
    url: 'https://empyrean.fi',
    description: 'The Decentralized Reserve Currency on Aurora',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/empyrean-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'empyrean',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Aurora'],
    module: 'empyrean/index.js',
    twitter: 'empyreanfi',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641873771,
    slug: 'empyrean-dao',
    tvl: 1641323.7609227924,
    chainTvls: { 'Aurora-staking': 822666.0402648344, Aurora: 1641323.7609227924, staking: 822666.0402648344 },
    change_1h: -0.23756331217876436,
    change_1d: -1.020102217768951,
    change_7d: -2.407962293020063,
    staking: 822666.0402648344,
    fdv: 599175,
    mcap: 0,
  },
  {
    id: '1475',
    name: 'MahaDAO',
    address: 'polygon:0xedd6ca8a4202d4a36611e2fff109648c4863ae19',
    symbol: 'MAHA',
    url: 'https://mahadao.com',
    description:
      'MahaDAO, which is releasing ARTH, a decentralized non-depreciating algorithmic token, which derives its value from a basket of uncorrelated assets, the relative weights of which are automatically adjusted by automatic buying and selling in response to changes in the price of each individual asset relative to the others.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mahadao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mahadao',
    cmcId: '8043',
    category: 'Lending',
    chains: ['Polygon', 'Binance'],
    oracles: ['Chainlink', 'Umbrella Network'],
    forkedFrom: ['Liquity'],
    module: 'mahadao/index.js',
    twitter: 'TheMahaDAO',
    listedAt: 1645824445,
    slug: 'mahadao',
    tvl: 1639218.285795104,
    chainTvls: {
      pool2: 1468656.1009115202,
      'Polygon-pool2': 748836.9396147048,
      'Binance-pool2': 719819.1612968154,
      Binance: 781627.6346849877,
      Polygon: 857590.6511101163,
      'Polygon-staking': 2451265.6686633327,
      staking: 2451265.6686633327,
    },
    change_1h: -0.03641548404512207,
    change_1d: 3.823617389580903,
    change_7d: 19.317857467410434,
    staking: 2451265.6686633327,
    pool2: 1468656.1009115202,
    mcap: 13743533,
  },
  {
    id: '923',
    name: 'LeonicornSwap',
    address: 'bsc:0x2c8368f8f474ed9af49b87eac77061beb986c2f1',
    symbol: 'LEOS',
    url: 'https://dex.leonicornswap.com/home',
    description: 'Advanced AMM with dual token model on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/leonicornswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'leonicorn-swap',
    cmcId: '10762',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'leonicornswap/index.js',
    twitter: 'swapleonicorn',
    audit_links: ['https://hacken.io/audits/#leonicorn_swap'],
    forkedFrom: ['Uniswap'],
    listedAt: 1638083864,
    slug: 'leonicornswap',
    tvl: 1634339.8341023833,
    chainTvls: { Binance: 1634339.8341023833, 'Binance-staking': 2803805.151140284, staking: 2803805.151140284 },
    change_1h: 0.000015736986824776977,
    change_1d: 0.015340590473584825,
    change_7d: 32.90156171995454,
    staking: 2803805.151140284,
    mcap: 2593642,
  },
  {
    id: '1098',
    name: 'Templar DAO',
    address: 'bsc:0x19e6bfc1a6e4b042fb20531244d47e252445df01',
    symbol: 'TEM',
    url: 'https://templar.finance/',
    description:
      'Templar is the first decentralized reserve currency protocol available on the Binance Smart Chain based on the TEM token.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/templar-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'templardao',
    cmcId: '15288',
    category: 'Reserve Currency',
    chains: ['Binance', 'Moonriver', 'Harmony'],
    module: 'templar/index.js',
    twitter: 'TemplarDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640528431,
    slug: 'templar-dao',
    tvl: 1624566.521652017,
    chainTvls: {
      Moonriver: 392776.0453894835,
      'Harmony-staking': 645070.9194471549,
      Harmony: 139911.06666413153,
      'Moonriver-staking': 990293.2572453775,
      Binance: 1091879.409598402,
      'Binance-staking': 2027722.997419562,
      staking: 3663087.174112094,
    },
    change_1h: 0.0007669384019379777,
    change_1d: 0.5549103390628716,
    change_7d: -2.2336440441555254,
    staking: 3663087.174112094,
    mcap: 3641867,
  },
  {
    id: '386',
    name: 'PolyQuity',
    address: 'polygon:0x5a3064CbDCCF428ae907796cF6aD5a664CD7F3d8',
    symbol: 'PYQ',
    url: 'https://polyquity.org',
    description:
      'PolyQuity is a decentralized borrowing protocol that allows you to draw interest-free loans against Matic used as collateral. Loans are paid out in PUSD (a USD pegged stablecoin) and need to maintain a minimum collateral ratio of 110%. In addition to the collateral, the loans are secured by a Stability Pool containing PUSD and by fellow borrowers collectively acting as guarantors of last resort. Learn more about these mechanisms in our documentation.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polyquity.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'polyquity',
    cmcId: null,
    category: 'CDP',
    chains: ['Polygon'],
    module: 'polyquity/index.js',
    twitter: 'polyquity_org',
    audit_links: ['https://github.com/PolyQuity/audits/tree/main'],
    forkedFrom: ['Liquity'],
    slug: 'polyquity',
    tvl: 1624061.1880546496,
    chainTvls: { Polygon: 1624061.1880546496 },
    change_1h: 0.4941388931000006,
    change_1d: 0.16786530931931054,
    change_7d: 9.267142147012876,
    mcap: 0,
  },
  {
    id: '159',
    name: 'Growth DeFi',
    address: '0x09e64c2B61a5f1690Ee6fbeD9baf5D6990F8dFd0',
    symbol: 'GRO',
    url: 'https://growthdefi.com/',
    description:
      'gToken holders can benefit from the positive effects of compounded interest, high liquidity and a share of arbitragers profit without suffering from impermanent loss.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/growth-defi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'growth-defi',
    cmcId: '6718',
    category: 'Yield',
    chains: ['Binance', 'Avalanche', 'Ethereum'],
    module: 'growthdefi/index.js',
    twitter: 'GrowthDefi',
    audit_links: ['https://consensys.net/diligence/audits/2020/12/growth-defi-v1/'],
    oracles: ['Chainlink'],
    slug: 'growth-defi',
    tvl: 1620576.989322383,
    chainTvls: {
      Binance: 1620576.4132358576,
      'Binance-staking': 8504.583310756834,
      Avalanche: 0.5760865253362453,
      'Ethereum-staking': 0,
      'Binance-Treasury': 949777.7395335849,
      Treasury: 949777.7395335849,
      staking: 8504.583310756834,
    },
    change_1h: 0.16896959371948128,
    change_1d: 0.8762124591066538,
    change_7d: 17.380019800194276,
    staking: 8504.583310756834,
    mcap: 4212416,
  },
  {
    id: '142',
    name: 'Swerve',
    address: '0xB8BAa0e4287890a5F79863aB62b7F175ceCbD433',
    symbol: 'SWRV',
    url: 'https://swerve.fi/',
    description: 'Swerve is a community fork of curve.fi designed for efficient stablecoin trading.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/swerve.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'swerve-dao',
    cmcId: '6901',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'swerve.js',
    twitter: 'SwerveFinance',
    audit_links: [
      'https://github.com/crypticlabs/swerve-audit-report/blob/master/Swerve%20Finance%20Audit%20-%20by%20Cryptic%20Labs%20v3.pdf',
    ],
    forkedFrom: ['Curve'],
    slug: 'swerve',
    tvl: 1591024.34,
    chainTvls: { Ethereum: 1591024.34 },
    change_1h: 0,
    change_1d: 0.0004192285306459098,
    change_7d: 0.0010364499458717091,
    fdv: 9670378,
    mcap: 4747025,
  },
  {
    id: '364',
    name: 'Volmex',
    address: '-',
    symbol: '-',
    url: 'https://volmex.finance/',
    description: 'Tokenized volatility protocol built on Ethereum',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/volmex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum', 'Polygon', 'Arbitrum'],
    module: 'volmex/index.js',
    twitter: 'volmexfinance',
    audit_links: ['https://docs.volmex.finance/security/audits'],
    slug: 'volmex',
    tvl: 1587268.6761837737,
    chainTvls: { Ethereum: 742740.9793587576, Polygon: 475867.89895143814, Arbitrum: 368659.79787357786 },
    change_1h: -0.15564897678711986,
    change_1d: 0.41679950431907287,
    change_7d: -2.354859271650625,
  },
  {
    id: '1074',
    name: 'AutoShark',
    address: 'bsc:0xdd97ab35e3c0820215bc85a395e13671d84ccba2',
    symbol: 'JAWS',
    url: 'https://autoshark.finance',
    description:
      'AutoShark Finance is the 1st Hybrid AMM and Yield Optimizer, offering unparalleled access to farming opportunities through the use of superior yield strategies, auto-compounding vaults, and NFT-powered farming.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/autoshark.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'autoshark',
    cmcId: '10303',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'autoshark/index.js',
    twitter: 'AutoSharkFin',
    audit_links: ['https://docs.autoshark.finance/audit'],
    forkedFrom: ['Uniswap'],
    listedAt: 1640115841,
    slug: 'autoshark',
    tvl: 1586608.7282435915,
    chainTvls: { Binance: 1586608.7282435915, 'Binance-staking': 357315.4788103392, staking: 357315.4788103392 },
    change_1h: 0.48496337395123135,
    change_1d: -0.790529015543413,
    change_7d: -6.544268287225748,
    staking: 357315.4788103392,
    mcap: 0,
  },
  {
    id: '1575',
    name: 'Penguin',
    address: null,
    symbol: '-',
    url: 'https://png.fi',
    description: 'A Liquidity-as-a-Service Platform, built on Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/penguin.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Solana'],
    oracles: ['Coingecko'],
    forkedFrom: [],
    module: 'penguin-finance/index.js',
    twitter: 'png_fi',
    listedAt: 1648061569,
    slug: 'penguin',
    tvl: 1572160.7549661864,
    chainTvls: { Solana: 1572160.7549661864 },
    change_1h: 0.12990464320685646,
    change_1d: 0.4088484138487445,
    change_7d: null,
  },
  {
    id: '605',
    name: 'Telos Swap',
    address: 'telos:-',
    symbol: 'SWAP',
    url: 'https://www.tswaps.com',
    description:
      'Telos swaps allow for the exchange of any listed Telos token for any other Telos token using a simple swap interface.',
    chain: 'Telos',
    logo: 'https://icons.llama.fi/telos-swap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Telos'],
    module: 'telos-swap/index.js',
    twitter: null,
    slug: 'telos-swap',
    tvl: 1569404.177783429,
    chainTvls: { Telos: 1569404.177783429 },
    change_1h: 3.073489220297006,
    change_1d: -2.795593359459872,
    change_7d: 5.578398626502263,
  },
  {
    id: '723',
    name: 'Potluck Protocol',
    address: 'fantom:0x49894fcc07233957c35462cfc3418ef0cc26129f',
    symbol: 'FANG',
    url: 'http://fantom.potluckprotocol.com',
    description: 'Defi AMM with gamified products and features.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/potluck-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'fang-token',
    cmcId: '12671',
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'potluckprotocol/index.js',
    twitter: 'PotluckProtocol',
    audit_links: ['https://dessertswap.finance/audits/Potluck%20BSC-FTM%20audit%2011437133.pdf'],
    oracles: ['Internal'],
    forkedFrom: ['Uniswap'],
    listedAt: 1635465731,
    slug: 'potluck-protocol',
    tvl: 1567904.9729987208,
    chainTvls: { 'Fantom-staking': 232811.78148543392, staking: 232811.78148543392, Fantom: 1567904.9729987208 },
    change_1h: 1.1197808016209905,
    change_1d: -0.5490281261990333,
    change_7d: 9.68081150401639,
    staking: 232811.78148543392,
    fdv: 96195943,
    mcap: 0,
  },
  {
    id: '644',
    name: 'PadSwap',
    address: 'bsc:0x463e737d8f740395abf44f7aac2d9531d8d539e9',
    symbol: 'TOAD',
    url: 'https://padswap.exchange',
    description: 'Cross Chain AMM, Advanced Liquidity Solutions, DeFi product solves, Yield farming.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/padswap.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'toad-network',
    cmcId: '9983',
    category: 'Dexes',
    chains: ['Binance', 'Moonbeam', 'Moonriver'],
    module: 'padswap/index.js',
    twitter: 'ToadNetwork',
    audit_links: ['https://dapps.padswap.exchange/pad_audit_report.pdf', 'https://toad.network/toad_audit_report.pdf'],
    forkedFrom: ['Uniswap'],
    slug: 'padswap',
    tvl: 1554353.3556495255,
    chainTvls: {
      Binance: 800800.180573872,
      'Binance-staking': 379055.67305299774,
      Moonriver: 162757.3644200547,
      staking: 379055.67305299774,
      Moonbeam: 590795.8106555986,
    },
    change_1h: -0.003586820664310153,
    change_1d: 0.8565412887526094,
    change_7d: 4.537282397078485,
    staking: 379055.67305299774,
    mcap: 0,
  },
  {
    id: '282',
    name: 'Yam Finance',
    address: '0x0AaCfbeC6a24756c20D41914F2caba817C0d8521',
    symbol: 'YAM',
    url: 'https://yam.finance/',
    description:
      'Yam Finance is a Decentralized Autonomous Organization building three core products. Innovative synthetic assets (degnerative.finance), permissionless insurance (Umbrella), and ground-breaking treasury management services (DAO House). The YAM token was fair launched and the DAO is an open community where anybody can participate.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/yam-finance.png',
    audits: '3',
    audit_note: null,
    gecko_id: 'yam-2',
    cmcId: '7131',
    category: 'Synthetics',
    chains: ['Ethereum'],
    module: 'degenerative/index.js',
    twitter: 'YamFinance',
    audit_links: ['https://github.com/yam-finance/audits'],
    forkedFrom: ['Compound'],
    slug: 'yam-finance',
    tvl: 1518756.569041475,
    chainTvls: { Ethereum: 1518756.569041475 },
    change_1h: 0.5715493323624941,
    change_1d: 3.732264826033372,
    change_7d: 14.125659416432597,
    fdv: 3790072,
    mcap: 3613645,
  },
  {
    id: '512',
    name: 'Feeder Finance',
    address: 'bsc:0x67d66e8Ec1Fd25d98B3Ccd3B19B7dc4b4b7fC493',
    symbol: 'FEED',
    url: 'https://www.feeder.finance',
    description:
      'Feeder Finance reimagines the DeFi journey with a simplified user experience, unique diversified vaults, and multichain vault aggregation -- Think less, Earn more.The Feeder Finance platform will feature a uniquely simplified user experience to turn the hunt for APY at countless platforms, into one simple stop. Our unique vault strategies that focus on the bigger picture, allow a single deposit to spread across multiple destinations; auto-compounding, auto-rebalancing, and optimizing for risk adjusted returns every step of the way',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/feeder-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'feeder-finance',
    cmcId: '10109',
    category: 'Yield',
    chains: ['Binance', 'Fantom'],
    module: 'feederfinance/index.js',
    twitter: 'FeederFinance',
    audit_links: ['https://docs.feeder.finance/audits'],
    slug: 'feeder-finance',
    tvl: 1509880.7917301878,
    chainTvls: {
      pool2: 986649.6742822339,
      'Binance-pool2': 986649.6742822339,
      Binance: 897632.13616659,
      'Binance-staking': 3714566.7122565983,
      staking: 3714566.7122565983,
      Fantom: 612248.6555635977,
    },
    change_1h: -0.026600073539100322,
    change_1d: 0.033569535387940164,
    change_7d: 7.732602051909581,
    staking: 3714566.7122565983,
    pool2: 986649.6742822339,
    mcap: 5103656,
  },
  {
    id: '1015',
    name: 'Atlas USV',
    address: 'polygon:0xAC63686230f64BDEAF086Fe6764085453ab3023F',
    symbol: 'USV',
    url: 'https://app.atlasusv.com/#/dashboard',
    description:
      'Atlas USV is a community-driven project to build a universal, decentralized, and widely accessible DeFi base layer.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/atlas-usv.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Polygon'],
    module: 'atlas-usv/index.js',
    twitter: 'atlasusv',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639349755,
    slug: 'atlas-usv',
    tvl: 1491225.9286961183,
    chainTvls: { Polygon: 1491225.9286961183, 'Polygon-staking': 592748054.0956784, staking: 592748054.0956784 },
    change_1h: -0.08102135510122821,
    change_1d: 0.5571599011410058,
    change_7d: 3.910800266916965,
    staking: 592748054.0956784,
  },
  {
    id: '700',
    name: 'Poof Cash',
    address: 'celo:0x00400FcbF0816bebB94654259de7273f4A05c762',
    symbol: 'POOF',
    url: 'https://www.poof.cash',
    description: 'Decentralized, private DeFi for EVMs.',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/poof-cash.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'poofcash',
    cmcId: '12109',
    category: 'Lending',
    chains: ['Celo'],
    module: 'poofcash/index.js',
    twitter: 'PoofCash',
    audit_links: [
      'https://uploads-ssl.webflow.com/606120c25fb119221b762612/615f8469329e45337d809a9a_Poof_Labs_Fall_Audit_Bramah.pdf',
    ],
    listedAt: 1635102185,
    slug: 'poof-cash',
    tvl: 1486866.9273527476,
    chainTvls: { Celo: 1486866.9273527476 },
    change_1h: 1.1207597549931734,
    change_1d: 1.0783750251946316,
    change_7d: 6.838846522526325,
    fdv: 972723,
    mcap: 0,
  },
  {
    id: '734',
    name: 'OpenSwap',
    address: 'harmony:0x01a4b054110d57069c1658afbc46730529a3e326',
    symbol: 'OPENX',
    url: 'https://app.openswap.one',
    description:
      'OpenSwap is a DeFi protocol on the Harmony network with simple yield farming capabilities; cross-chain tools for bridging the Harmony, Ethereum, and Binance Smart Chain networks; and easy ways for users to trade, provide liquidity, and earn collected fees from transactions.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/openswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'openswap_harmony/index.js',
    twitter: 'openswap_one',
    audit_links: [
      'https://github.com/0xGuard-com/audit-reports/blob/master/openswap/OpenSwap_final-audit-report_v1.pdfaudit',
      'https://github.com/0xGuard-com/audit-reports/blob/master/openswap_v2/OpenSwapV2_final-audit-report.pdf',
    ],
    forkedFrom: ['Uniswap'],
    listedAt: 1635798336,
    slug: 'openswap',
    tvl: 1484586.7209667428,
    chainTvls: { Harmony: 1484586.7209667428 },
    change_1h: 0.1533369287523243,
    change_1d: -1.5384370479885945,
    change_7d: 7.439163509319727,
  },
  {
    id: '1468',
    name: 'Pact',
    address: null,
    symbol: '-',
    url: 'https://app.pact.fi',
    description:
      'Pact is a decentralised Automated Market Maker (AMM) built on the Algorand protocol, offering deep liquidity and low transaction fees.',
    chain: 'Algorand',
    logo: 'https://icons.llama.fi/pact.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Algorand'],
    oracles: [],
    forkedFrom: [],
    module: 'pactfi.js',
    twitter: 'pact_fi',
    audit_links: ['https://github.com/runtimeverification/publications/blob/main/reports/smart-contracts/Pact_Fi.pdf'],
    listedAt: 1645731534,
    slug: 'pact',
    tvl: 1461021.2962820006,
    chainTvls: { Algorand: 1461021.2962820006 },
    change_1h: 0,
    change_1d: 6.3811556135920995,
    change_7d: 18.91680792967645,
  },
  {
    id: '1338',
    name: 'Warp',
    address: 'polygon:0x3F46a70adB395cddb81FF9bFE3B62aDae1B44816',
    symbol: 'WARP',
    url: 'https://warp.bond/',
    description:
      'Warp is a new GameFi protocol, combining NFT starships, multiple staking pools, and deflationary tokenomics.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/warp.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'warp-bond',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Polygon'],
    module: 'warpbond/index.js',
    twitter: 'WarpBond',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1643505246,
    slug: 'warp',
    tvl: 1446557.8298962142,
    chainTvls: { Polygon: 1446557.8298962142, 'Polygon-staking': 23442.67148401112, staking: 23442.67148401112 },
    change_1h: 2.842170943040401e-14,
    change_1d: -0.14094211989797145,
    change_7d: -0.5976380595129029,
    staking: 23442.67148401112,
    mcap: 0,
  },
  {
    id: '1281',
    name: 'veDAO',
    address: 'fantom:0x911da02c1232a3c3e1418b834a311921143b04d7',
    symbol: 'WeVE',
    url: 'https://www.vedao.io/',
    description: 'A masterchef',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/vedao.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'vedao',
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'weve/index.js',
    twitter: '_veDAO_',
    forkedFrom: ['Uniswap'],
    listedAt: 1642528930,
    slug: 'vedao',
    tvl: 1445045.2954544108,
    chainTvls: {
      pool2: 72207.47205387813,
      'Fantom-staking': 0,
      masterchef: 1445045.2954544108,
      'Fantom-masterchef': 1445045.2954544108,
      'Fantom-pool2': 72207.47205387813,
      Fantom: 1445045.2954544108,
      staking: 0,
    },
    change_1h: 0.3202374357667708,
    change_1d: -0.5028842936673072,
    change_7d: 6.602741875758895,
    staking: 0,
    pool2: 72207.47205387813,
    fdv: 5354916,
    mcap: 3664522,
  },
  {
    id: '317',
    name: 'HyperSwap',
    address: '-',
    symbol: 'ORI',
    url: 'https://hyperjump.fi/home-ftm.html',
    description: 'AMM',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/hyperswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'hyperswap/index.js',
    twitter: 'hyper_swap',
    audit_links: ['https://docs.hyperjump.fi/essentials/audits'],
    forkedFrom: ['Uniswap'],
    slug: 'hyperswap',
    tvl: 1428468.6868056753,
    chainTvls: { Fantom: 1428468.6868056753 },
    change_1h: 0.8089570390756364,
    change_1d: 1.0671799190984643,
    change_7d: 9.335746862647937,
  },
  {
    id: '1373',
    name: 'Antimatter',
    address: '0x9B99CcA871Be05119B2012fd4474731dd653FEBe',
    symbol: 'MATTER',
    url: 'https://antimatter.finance',
    description:
      'Antimatter is positioned to be the gateway for DeFi derivatives, including four main products now, Dual Investment, Bull & Bear Tokens, Financial NFTs, as well as Antimatter DAO.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/antimatter.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'antimatter',
    cmcId: '8603',
    category: 'Options',
    chains: ['Binance', 'Ethereum', 'Arbitrum', 'Avalanche'],
    module: 'antimatterDAO/index.js',
    twitter: 'antimatterdefi',
    audit_links: ['https://docs.antimatter.finance/developers/auditing-report'],
    listedAt: 1644433965,
    slug: 'antimatter',
    tvl: 1413967.9186419128,
    chainTvls: {
      Avalanche: 31380.19139717348,
      Binance: 1300284.5868455048,
      Ethereum: 42928.969144565315,
      'Ethereum-staking': 2875989.3825802165,
      Arbitrum: 39374.17125466916,
      staking: 2875989.3825802165,
    },
    change_1h: 0.2408880615416109,
    change_1d: 2.0013875384721445,
    change_7d: 10.889391784802797,
    staking: 2875989.3825802165,
    mcap: 12805623,
  },
  {
    id: '457',
    name: 'Fuji DAO',
    address: '-',
    symbol: '-',
    url: 'https://www.fujidao.org',
    description: 'First De-Fi borrowing aggregator',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/fuji-dao.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum', 'Fantom'],
    module: 'fujidao/index.js',
    audit_links: [
      'https://github.com/Fujicracy/fuji-protocol/blob/main/audits/Fuji_Securing_2021-07.pdf',
      'https://github.com/Fujicracy/fuji-protocol/blob/main/audits/Fuji_TrailOfBits_2021-11.pdf',
    ],
    twitter: 'FujiFinance',
    slug: 'fuji-dao',
    tvl: 1412954.8671976193,
    chainTvls: { Ethereum: 945494.3294198374, Fantom: 467460.53777778184 },
    change_1h: 0.5797592337629283,
    change_1d: 2.8300976132094036,
    change_7d: 14.386270355133263,
  },
  {
    id: '321',
    name: 'Ram Protocol',
    address: 'thundercore:0xfe146d5710015d4075355fb7be8d133346ec63c2',
    symbol: 'RAM',
    url: 'https://rammer.finance/#/',
    description:
      'Ram is a non-custodial liquidity protocol for earning interest on deposits and borrowing assets on the ThunderCore chain.',
    chain: 'ThunderCore',
    logo: 'https://icons.llama.fi/ram-protocol.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['ThunderCore'],
    module: 'ram.js',
    twitter: null,
    forkedFrom: ['Compound'],
    slug: 'ram-protocol',
    tvl: 1411038.4304069132,
    chainTvls: {
      borrowed: 2110806.174683819,
      'ThunderCore-borrowed': 2110806.174683819,
      ThunderCore: 1411038.4304069132,
    },
    change_1h: -0.2292863516578052,
    change_1d: 0.7436401970076503,
    change_7d: 1.7490455690773103,
  },
  {
    id: '1584',
    name: 'Valas Finance',
    address: 'bsc:0xB1EbdD56729940089Ecc3aD0BBEEB12b6842ea6F',
    symbol: 'VALAS',
    url: 'https://valasfinance.com/',
    description:
      'Valas is a decentralised non-custodial liquidity market protocol where users can participate as depositors or borrowers.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/valas-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Binance'],
    module: 'valas/index.js',
    twitter: 'ValasFinance',
    oracles: ['Chainlink'],
    forkedFrom: ['Aave'],
    slug: 'valas-finance',
    tvl: 1367420.118998884,
    chainTvls: {
      pool2: 0,
      'Binance-borrowed': 27845.44394243625,
      'Binance-pool2': 0,
      Binance: 1367420.118998884,
      'Binance-staking': 0,
      borrowed: 27845.44394243625,
      staking: 0,
    },
    change_1h: -0.08351894677157645,
    change_1d: null,
    change_7d: null,
    staking: 0,
    pool2: 0,
  },
  {
    id: '1050',
    name: 'LeagueDAO',
    address: '0x7b39917f9562c8bc83c7a6c2950ff571375d505d',
    symbol: 'LEAG',
    url: 'https://leaguedao.com/',
    description:
      'LeagueDAO is a decentralized open source project developing Tokenized Fantasy Sports and Gaming Protocols.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/leaguedao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'leaguedao-governance-token',
    cmcId: '14131',
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    module: 'leaguedao/index.js',
    twitter: 'LeagueDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639895243,
    slug: 'leaguedao',
    tvl: 1364841.9620487564,
    chainTvls: { Ethereum: 1364841.9620487564, 'Ethereum-staking': 556965.5051924274, staking: 556965.5051924274 },
    change_1h: 0.26642634405669696,
    change_1d: -1.0402168048145342,
    change_7d: -22.270391595545178,
    staking: 556965.5051924274,
    fdv: 6385432,
    mcap: 135157,
  },
  {
    id: '827',
    name: 'Layer2 Finance',
    address: null,
    symbol: '-',
    url: 'https://layer2.finance',
    description:
      'Layer2.Finance is a layer-2 scaling technology that acts as a low-cost and trust-free gateway for users to explore and benefit from the existing DeFi ecosystem without the concerns of high transaction (gas) costs offsetting their gains.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/layer2-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'l2finance/index.js',
    twitter: 'layer2finance',
    audit_links: ['https://github.com/celer-network/layer2-finance-v2-contracts/tree/main/audit'],
    listedAt: 1636631447,
    slug: 'layer2-finance',
    tvl: 1355529.3036853597,
    chainTvls: { Ethereum: 1355529.3036853597 },
    change_1h: -0.12225217128953147,
    change_1d: 0.0015781085495660818,
    change_7d: 0.005046227482722543,
  },
  {
    id: '939',
    name: 'R U Generous',
    address: 'avax:0xb8ef3a190b68175000b74b4160d325fd5024760e',
    symbol: 'RUG',
    url: 'https://www.rug.farm/#/dashboard',
    description: 'R U Generous is a decentralized reserve currency protocol and DAO available on the Avalanche Network',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/r-u-generous.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'r-u-generous',
    cmcId: '14971',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'rugdao/index.js',
    twitter: 'RUGenerous',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638200165,
    slug: 'r-u-generous',
    tvl: 1355234.654552049,
    chainTvls: { 'Avalanche-staking': 358723.8052385646, Avalanche: 1355234.654552049, staking: 358723.8052385646 },
    change_1h: 0,
    change_1d: -0.10877143004813661,
    change_7d: -6.642698933033017,
    staking: 358723.8052385646,
    mcap: 0,
  },
  {
    id: '1385',
    name: 'Algebra DEX',
    address: 'polygon:0x0169ec1f8f639b32eec6d923e24c2a2ff45b9dd6',
    symbol: 'ALGB',
    url: 'https://app.algebra.finance',
    description:
      'Algebra is the next-generation decentralized exchange supporting concentrated liquidity built on Polygon Network. Algebra goal is to create AMM for the main blockchains. Algebra introduces unique DEX features like Adaptive fees, Built-in farming, and Deflationary tokens support. Algebra’s team is formed by experienced managers who have already launched successful projects: MyWish, a smart contract builder supporting 9 blockchains, and Rubic, a multi-chain swap protocol.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/algebra-dex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'algebra',
    cmcId: '13211',
    category: 'Dexes',
    chains: ['Polygon'],
    oracles: [],
    module: 'algebra/index.js',
    twitter: 'CryptoAlgebra',
    language: 'Solidity',
    audit_links: ['https://algebra.finance/static/Hacken_Algebra_Audit-317911476f86c632be77388fa51852c0.pdf'],
    listedAt: 1644509096,
    slug: 'algebra-dex',
    tvl: 1345784.522555359,
    chainTvls: { Polygon: 1345784.522555359, 'Polygon-staking': 859954.0624577514, staking: 859954.0624577514 },
    change_1h: 0.5389814327739373,
    change_1d: 1.4484027519474125,
    change_7d: -19.927834258236686,
    staking: 859954.0624577514,
    fdv: 10968608,
    mcap: 0,
  },
  {
    id: '525',
    name: 'Cycle Finance',
    address: 'avax:0x81440c939f2c1e34fc7048e518a637205a632a74',
    symbol: 'CYCLE',
    url: 'https://www.cycle.finance',
    description: 'The Cycle Protocol is a yield optimizer containing vaults and a liquidity mining program.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/cycle-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'cycle-token',
    cmcId: '11753',
    category: 'Yield Aggregator',
    chains: ['Avalanche'],
    module: 'cyclefinance/index.js',
    twitter: 'CycleProtocol',
    forkedFrom: ['Yearn Finance'],
    slug: 'cycle-finance',
    tvl: 1344372.887193409,
    chainTvls: { 'Avalanche-staking': 187344.38208603195, Avalanche: 1344372.887193409, staking: 187344.38208603195 },
    change_1h: 0.00006024787715830371,
    change_1d: 4.41434824865523,
    change_7d: 3.450160027999189,
    staking: 187344.38208603195,
    fdv: 466932,
    mcap: 221712,
  },
  {
    id: '298',
    name: 'Hyper Finance',
    address: 'bsc:0x9a319b959e33369C5eaA494a770117eE3e585318',
    symbol: 'HYFI',
    url: 'https://hyfi.pro',
    description:
      'Hyper Finance (HyFi.pro) is a decentralized aggregate mining platform based on the ‌Binance‌ Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/hyper-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'hyper-finance',
    cmcId: '9235',
    category: 'Yield',
    chains: ['Binance'],
    module: 'hyfi.js',
    twitter: 'hyper_finance',
    audit_links: [
      'https://www.certik.org/projects/hyperfinance',
      'https://hyfi.pro/SlowMistAuditReport-HyperFinance-en.pdf',
    ],
    slug: 'hyper-finance',
    tvl: 1344271.5957552607,
    chainTvls: { Binance: 1344271.5957552607 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 0,
    mcap: 0,
  },
  {
    id: '428',
    name: 'DinoSwap',
    address: 'polygon:0xaa9654becca45b5bdfa5ac646c939c62b527d394',
    symbol: 'DINO',
    url: 'https://dinoswap.exchange',
    description:
      'DinoSwap is a cross-chain infrastructure project that builds liquidity for layer-one blockchains, AMMs and partnering projects.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/dinoswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'dinoswap',
    cmcId: '10777',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'dinoswap/index.js',
    twitter: 'DinoSwapHQ',
    audit_links: ['https://www.certik.org/projects/dinoswap'],
    slug: 'dinoswap',
    tvl: 1337628.761765304,
    chainTvls: { Polygon: 1337628.761765304 },
    change_1h: 0.44240711774723707,
    change_1d: -19.375160999964038,
    change_7d: -58.1682215698137,
    mcap: 2333361,
  },
  {
    id: '1539',
    name: 'CityCoins',
    address: null,
    symbol: '-',
    url: 'https://www.citycoins.co',
    description:
      'CityCoins gives communities the power to improve their cities, while providing crypto rewards to individual contributors and city governments alike. Each city has their own coin, starting with Miami and MiamiCoin (MIA).',
    chain: 'Stacks',
    logo: 'https://icons.llama.fi/citycoins.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Stacks'],
    oracles: [],
    forkedFrom: [],
    module: 'citycoins/index.js',
    twitter: 'mineCityCoins',
    language: 'Clarity',
    listedAt: 1647046627,
    slug: 'citycoins',
    tvl: 1325918.22,
    chainTvls: { staking: 18171403.18171785, Stacks: 1325918.22, 'Stacks-staking': 18171403.18171785 },
    change_1h: 1.3605442176870781,
    change_1d: -15.95801710930293,
    change_7d: 6.55693711207536,
    staking: 18171403.18171785,
  },
  {
    id: '1456',
    name: 'Solhero Finance',
    address: 'solana:Hero6s7zJXsw9hfCXLVR5stLqgCok3E7CCkpQEoLAk2g',
    symbol: 'HERO',
    url: 'https://www.solhero.finance/',
    description:
      'Swap, earn, and claim on the decentralized, community driven platformBy encoding Solhero characters and items into NFTs, players can become its owners and bring them economic value through our marketplace.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/solhero.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '18189',
    category: 'Yield',
    chains: ['Solana'],
    oracles: [],
    forkedFrom: [],
    module: 'solhero/index.js',
    twitter: 'SolheroFi',
    listedAt: 1645643109,
    openSource: false,
    slug: 'solhero-finance',
    tvl: 1282862.8848070244,
    chainTvls: {
      'Solana-staking': 5005.490247678206,
      pool2: 10.023246458673512,
      Solana: 1282862.8848070244,
      'Solana-pool2': 10.023246458673512,
      staking: 5005.490247678206,
    },
    change_1h: 1.4363520315718148,
    change_1d: 3.953040263717895,
    change_7d: 13.789671065786123,
    staking: 5005.490247678206,
    pool2: 10.023246458673512,
  },
  {
    id: '341',
    name: 'Nsure',
    address: '0x20945ca1df56d237fd40036d47e866c7dccd2114',
    symbol: 'NSURE',
    url: 'https://nsure.network/',
    description: 'Open Insurance Platform for Open Finance',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/nsure.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'nsure-network',
    cmcId: '7231',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'nsure/index.js',
    twitter: 'Nsure_network',
    oracles: ['Chainlink'],
    slug: 'nsure',
    tvl: 1263594.1508840057,
    chainTvls: { Ethereum: 1263594.1508840057 },
    change_1h: 1.0779075815159587,
    change_1d: 3.3752288186876314,
    change_7d: 15.979853593566332,
    fdv: 6150738,
    mcap: 1459400,
  },
  {
    id: '1031',
    name: 'PandaSwap',
    address: 'bsc:0x47DcC83a14aD53Ed1f13d3CaE8AA4115f07557C0',
    symbol: 'PNDA',
    url: 'https://www.pandaswap.xyz/#/',
    description:
      "Panda is the first franchise from Bao Finance's multi chain empire and sets foot in the Binance Smart Chain (BSC).",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pandaswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'pandaswap/index.js',
    twitter: 'thebaoman',
    listedAt: 1639773745,
    slug: 'pandaswap',
    tvl: 1254710.7680558313,
    chainTvls: {
      'Binance-masterchef': 1254710.7680558315,
      pool2: 111331.3921419182,
      masterchef: 1254710.7680558315,
      'Binance-pool2': 111331.3921419182,
      Binance: 1254710.7680558313,
      'Binance-staking': 0,
      staking: 0,
    },
    change_1h: 0.000006314566832088531,
    change_1d: 1.1713505891186173,
    change_7d: 7.113366538304305,
    staking: 0,
    pool2: 111331.3921419182,
  },
  {
    id: '400',
    name: 'Amun Tokens',
    address: '-',
    symbol: 'DFI,DMX',
    url: 'https://tokens.amun.com',
    description:
      "Amun’s Index tokens are composed of the top eight DeFi tokens in the Ethereum ecosystem by market capitalization for (DFI) and on price momentum for (DMX). Amun's provides investors with exposure to protocols that provide trading, lending and borrowing, yield farming, data oracles, insurance, prediction markets, synthetic assets and stable coins. The index’s components are equally weighted and rebalanced monthly. Holders own fractions of the underlying tokens, which can be redeemed by burning the index token.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/amun-tokens.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'amun-defi-index',
    cmcId: null,
    category: 'Indexes',
    chains: ['Polygon', 'Ethereum'],
    module: 'amun/index.js',
    twitter: 'amuntokens',
    slug: 'amun-tokens',
    tvl: 1250498.5284051597,
    chainTvls: { Ethereum: 389820.2820350227, Polygon: 860678.246370137 },
    change_1h: 1.6428094740987547,
    change_1d: 1.6040768795633369,
    change_7d: 14.731018752727621,
    mcap: 0,
  },
  {
    id: '847',
    name: 'PhotonSwap Finance',
    address: 'cronos:0xbdd4e5660839a088573191A9889A262c0Efc0983',
    symbol: 'PHOTON',
    url: 'https://photonswap.finance',
    description: 'AMM styled decentralized exchange (DEX) on Cronos ',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/photonswap-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'photonswap/index.js',
    twitter: 'photonswap_fi',
    forkedFrom: ['Uniswap'],
    listedAt: 1637042970,
    slug: 'photonswap-finance',
    tvl: 1241094.5200737172,
    chainTvls: { Cronos: 1241094.5200737172 },
    change_1h: -0.4448161954038028,
    change_1d: 0.22672875578304286,
    change_7d: 17.52271214223255,
  },
  {
    id: '829',
    name: 'Standard Protocol',
    address: 'shiden:0x722377A047e89CA735f09Eb7CccAb780943c4CB4',
    symbol: 'STND',
    url: 'https://standard.tech',
    description: 'Self-sovereign money on multichains',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/standard-protocol.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'standard-protocol',
    cmcId: '9251',
    category: 'Dexes',
    chains: ['Metis', 'Shiden', 'Ethereum'],
    module: 'standard-tech/index.js',
    twitter: 'standarddefi',
    forkedFrom: ['Uniswap'],
    oracles: ['DIA', 'Internal'],
    listedAt: 1636671594,
    slug: 'standard-protocol',
    tvl: 1240730.794849895,
    chainTvls: { Metis: 712977.2346969022, Ethereum: 253076.1880808402, Shiden: 274677.37207215256 },
    change_1h: 2.2638733036492624,
    change_1d: 2.523176290600105,
    change_7d: 10.266859760474077,
    fdv: 23100214,
    mcap: 6467000,
  },
  {
    id: '736',
    name: 'aSOL',
    address: null,
    symbol: '-',
    url: 'https://asol.so',
    description: 'aSOL is the standard for transacting with staked SOL tokens.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/asol.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Liquid Staking',
    chains: ['Solana'],
    module: 'asol/index.js',
    twitter: 'aSOLprotocol',
    listedAt: 1635800533,
    slug: 'asol',
    tvl: 1215724.6426990419,
    chainTvls: { Solana: 1215724.6426990419 },
    change_1h: 2.377644137510586,
    change_1d: 1.6433295384120186,
    change_7d: 23.0701047283504,
  },
  {
    id: '860',
    name: 'CougarSwap',
    address: 'bsc:0x26d88b1e61e22da3f1a1ba95a1ba278f6fcef00b',
    symbol: 'CGS',
    url: 'https://cougarswap.io',
    description: 'Cougar Ecosystem - Advanced Yield Farming On Multiple Chains',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cougarswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cougar-token',
    cmcId: '13106',
    category: 'Yield',
    chains: ['Cronos', 'Moonbeam', 'Binance', 'Harmony', 'Avalanche', 'Polygon', 'Fantom'],
    module: 'cougarswap/index.js',
    twitter: 'cougarswap',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/CougarSwap.pdf'],
    listedAt: 1637344672,
    slug: 'cougarswap',
    tvl: 1188416.0827268607,
    chainTvls: {
      'Harmony-pool2': 3901.2948138364495,
      'Avalanche-masterchef': 14100.251152539638,
      'Harmony-staking': 4908.08489052191,
      'Fantom-staking': 1716.8150761787153,
      'Polygon-pool2': 9836.416132589125,
      masterchef: 1188416.0827268604,
      'Cronos-staking': 144206.60109644092,
      Harmony: 15141.45686761948,
      'Binance-pool2': 46136.25931632788,
      Binance: 26335.07177969886,
      'Binance-staking': 60594.50204261307,
      'Moonbeam-staking': 52841.0617917025,
      Avalanche: 14100.251152539638,
      'Harmony-masterchef': 15141.45686761948,
      'Fantom-masterchef': 3332.826309472896,
      'Avalanche-pool2': 1910.4783824706547,
      Fantom: 3332.8263094728964,
      staking: 268722.48092550354,
      Cronos: 613904.1394978404,
      'Binance-masterchef': 26335.07177969886,
      pool2: 772810.1090352882,
      'Polygon-masterchef': 7790.865474592338,
      Moonbeam: 507811.471645097,
      'Cronos-masterchef': 613904.1394978403,
      'Avalanche-staking': 0,
      Polygon: 7790.865474592338,
      'Cronos-pool2': 459978.53641366353,
      'Polygon-staking': 4455.416028046446,
      'Fantom-pool2': 4568.94823054495,
      'Moonbeam-pool2': 246478.17574585555,
      'Moonbeam-masterchef': 507811.471645097,
    },
    change_1h: -0.016624608785591022,
    change_1d: 7.405555205452401,
    change_7d: 25.627075361327627,
    staking: 268722.48092550354,
    pool2: 772810.1090352882,
    fdv: 1986807,
    mcap: 525327,
  },
  {
    id: '1517',
    name: 'Domination Finance',
    address: null,
    symbol: '-',
    url: 'https://domination.finance',
    description: "The world's first DEX for dominance trading.",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/domination-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Synthetics',
    chains: ['Boba', 'Polygon', 'Ethereum'],
    oracles: ['UMA'],
    forkedFrom: [],
    module: 'domfi/index.js',
    twitter: 'dominationfi',
    audit_links: ['https://docs.domination.finance/zokyo-domfi-vault-audit.pdf'],
    listedAt: 1646684579,
    slug: 'domination-finance',
    tvl: 1183761.9027059705,
    chainTvls: { Boba: 783304.2895602143, Ethereum: 56379.13519031845, Polygon: 344078.4779554378 },
    change_1h: -0.02039828138522637,
    change_1d: -0.16724436673588627,
    change_7d: -1.2779916135066571,
  },
  {
    id: '415',
    name: 'NAOS Finance',
    address: '0x4a615bb7166210cce20e6642a6f8fb5d4d044496',
    symbol: 'NAOS',
    url: 'https://naos.finance',
    description:
      'A DeFi lending protocol allowing lenders and SME borrowers to facilitate permissionless and borderless loaning/borrowing transactions',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/naos-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'naos-finance',
    cmcId: '9504',
    category: 'Lending',
    chains: ['Ethereum', 'Binance'],
    module: 'naos/index.js',
    twitter: 'naos_finance',
    audit_links: ['https://certificate.quantstamp.com/full/naos-formation'],
    slug: 'naos-finance',
    tvl: 1168730.4396695879,
    chainTvls: {
      pool2: 534052.9460513187,
      'Binance-pool2': 197169.0601026304,
      'Ethereum-pool2': 336883.88594868826,
      Binance: 546323.0029784373,
      Ethereum: 622407.4366911505,
      'Binance-staking': 1625152.7850461039,
      'Ethereum-staking': 1101872.9004483153,
      staking: 2727025.685494419,
    },
    change_1h: -0.19752299607104362,
    change_1d: 0.06897252925071484,
    change_7d: -1.8167992556069805,
    staking: 2727025.685494419,
    pool2: 534052.9460513187,
    mcap: 11627760,
  },
  {
    id: '293',
    name: 'Lydia',
    address: 'avax:0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084',
    symbol: 'LYD',
    url: 'https://www.lydia.finance/',
    description: 'AMM',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/lydia.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'lydia-finance',
    cmcId: '11191',
    category: 'Dexes',
    chains: ['Avalanche'],
    forkedFrom: ['Uniswap'],
    module: 'lydia/index.js',
    twitter: 'LydiaFinance',
    audit_links: ['https://docs.lydia.finance/security/certik-audit'],
    slug: 'lydia',
    tvl: 1166801.6931392672,
    chainTvls: { Avalanche: 1166801.6931392672 },
    change_1h: -0.3015257098143138,
    change_1d: -1.675814877607067,
    change_7d: -5.877471781876679,
    mcap: 0,
  },
  {
    id: '1259',
    name: 'Pillar',
    address: null,
    symbol: '-',
    url: 'https://pillarprotocol.com',
    description: 'A decentralized protocol to govern stablecoin issuance on the Zilliqa Blockchain.',
    chain: 'Zilliqa',
    logo: 'https://icons.llama.fi/pillar.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Zilliqa'],
    module: 'pillar/index.js',
    twitter: 'DevPillar',
    listedAt: 1642144681,
    slug: 'pillar',
    tvl: 1164751.7532020758,
    chainTvls: { Zilliqa: 1164751.7532020758 },
    change_1h: -0.3051494672482278,
    change_1d: -8.526719893126241,
    change_7d: 121.74255882760838,
  },
  {
    id: '1399',
    name: 'Fastyield Finance',
    address: 'fantom:0x0299461eE055bbb6dE11fAfE5a0636A0C3Bd5E8d',
    symbol: 'FAST',
    url: 'https://fastyield.app',
    description: 'Fastyield is a yield optimizer, which enables yield aggregation for holders and users of FAST token.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/fastyield-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'fastyield/index.js',
    twitter: 'fastyieldapp',
    listedAt: 1644597833,
    slug: 'fastyield-finance',
    tvl: 1161964.1356613825,
    chainTvls: {
      pool2: 27812.505825194825,
      'Fantom-staking': 0,
      'Fantom-pool2': 27812.505825194825,
      staking: 0,
      Fantom: 1161964.1356613825,
    },
    change_1h: 0.48810300168786114,
    change_1d: -0.04607387949957342,
    change_7d: 7.5721325837640165,
    staking: 0,
    pool2: 27812.505825194825,
  },
  {
    id: '417',
    name: 'Solyard',
    address: 'solana:8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz',
    symbol: 'YARD',
    url: 'https://solyard.finance',
    description: 'Solyard is New Yield Farming Aggregator Optimizer protocol on Solana',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/solyard.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'solyard-finance',
    cmcId: '11927',
    category: 'Yield',
    chains: ['Solana'],
    module: 'solyard.js',
    twitter: 'SolyardFinance',
    audit_links: ['https://github.com/SolyardFinance/Audit/blob/master/SlowMistAuditReport-SolYard.pdf'],
    openSource: false,
    slug: 'solyard',
    tvl: 1137478.3750341283,
    chainTvls: {
      pool2: 990.6026029227137,
      staking: 17320.684213555916,
      Solana: 1137478.3750341283,
      'Solana-staking': 17320.684213555916,
      'Solana-pool2': 990.6026029227137,
    },
    change_1h: -0.008360439798693164,
    change_1d: 0.05447984816538565,
    change_7d: 0.285773907894054,
    staking: 17320.684213555916,
    pool2: 990.6026029227137,
    fdv: 1273406,
    mcap: 92322,
  },
  {
    id: '409',
    name: 'CafeSwap',
    address: 'bsc:0x790be81c3ca0e53974be2688cdb954732c9862e1',
    symbol: 'BREW',
    url: 'https://cafeswap.finance',
    description:
      'CafeSwap is a yield farming and staking platform on BSC Chain, We have chosen BSC because of its low tnx fees and faster speed. We have built this project to provide the best experience with farming while regulating the supply, We believe in partnerships hence we aim to bring all BSC DeFi ecosystems in one place to have a friendly ecosystem for all of us.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/cafeswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cafeswap-token',
    cmcId: '8481',
    category: 'Dexes',
    chains: ['Binance', 'Polygon'],
    module: 'cafeswap/index.js',
    twitter: 'CafeSwapFinance',
    audit_links: ['https://docs.cafeswap.finance/audits'],
    forkedFrom: ['Uniswap'],
    slug: 'cafeswap',
    tvl: 1135976.7286815867,
    chainTvls: {
      Binance: 993589.1691823108,
      'Binance-staking': 61875.1724135244,
      Polygon: 142387.559499276,
      'Polygon-staking': 315487.39214114664,
      staking: 377362.56455467106,
    },
    change_1h: 0.05111341527113211,
    change_1d: 0.40475917615363244,
    change_7d: 17.540330135220117,
    staking: 377362.56455467106,
    mcap: 172708,
  },
  {
    id: '1570',
    name: 'Rikkei Finance',
    address: 'bsc:0xe17fbdf671f3cce0f354cacbd27e03f4245a3ffe',
    symbol: 'RIFI',
    url: 'https://rikkei.finance/',
    description: 'Enabling A Safe and Secure Open Lending Cross-chain, NFT Lending and Insurance',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/rikkei-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'rikkei-finance',
    cmcId: '10676',
    category: 'Lending',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    forkedFrom: ['Compound'],
    module: 'rikkei-finance/index.js',
    twitter: 'RikkeiFinance',
    audit_links: ['https://hacken.io/audits/#rikkei'],
    listedAt: 1647974345,
    slug: 'rikkei-finance',
    tvl: 1122947.5548103137,
    chainTvls: { Binance: 1122947.5548103137, 'Binance-borrowed': 1952564.7245802726, borrowed: 1952564.7245802726 },
    change_1h: -0.17049049248394965,
    change_1d: -0.6293954033243239,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '1064',
    name: 'Ideamarket',
    address: 'arbitrum:0xB41bd4C99dA73510d9e081C5FADBE7A27Ac1F814',
    symbol: 'IMO',
    url: 'https://ideamarket.io',
    description:
      'Ideamarket replaces the "arbiter of credibility" function (typically played by media corporations) with a public market.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/ideamarket.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ideamarket',
    cmcId: null,
    category: 'Derivatives',
    chains: ['Arbitrum'],
    module: 'ideamarket/index.js',
    twitter: 'ideamarket_io',
    audit_links: ['https://docs.ideamarket.io/contracts/audit'],
    listedAt: 1640082000,
    slug: 'ideamarket',
    tvl: 1113035.259158174,
    chainTvls: { Arbitrum: 1113035.259158174 },
    change_1h: -0.2275449101796454,
    change_1d: 0.7577817421230577,
    change_7d: 0.725142831669757,
    fdv: 8972129,
    mcap: 0,
  },
  {
    id: '215',
    name: 'Strudel Finance',
    address: '0x297d33e17e61c2ddd812389c2105193f8348188a',
    symbol: 'TRDL',
    url: 'https://strudel.finance',
    description: 'Strudel Finance provides the first one-way, trustless bridge linking Bitcoin and Ethereum.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/strudel-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'strudel-finance',
    cmcId: '8390',
    category: 'Bridge',
    chains: ['Ethereum'],
    module: 'strudel.js',
    twitter: 'EnterTheStrudel',
    slug: 'strudel-finance',
    tvl: 1112911.02,
    chainTvls: { Ethereum: 1112911.02 },
    change_1h: -0.12321186175211096,
    change_1d: 1.4014629492208144,
    change_7d: 11.347550754330399,
    fdv: 625598,
    mcap: 0,
  },
  {
    id: '846',
    name: 'KLend',
    address: 'bsc:0xf95995d642c652e9a7d124d546a7bdf297c6d0fb',
    symbol: 'KLT',
    url: 'https://klend.finance/app',
    description:
      'KLend aim to be the best on-chain lending platform, build distributed financial ecology, KLend is first DAO based decentralized lending protocol outside ETH, we use the over-collateralized mortgage lending model inspired by Compound coupled with the EPS style liquidity mining model empowers the capital utilization of DEFI-Ecology.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/klend.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'klend',
    cmcId: '9426',
    category: 'Lending',
    chains: ['Binance', 'OKExChain'],
    module: 'klend/index.js',
    twitter: 'oklend4',
    audit_links: ['https://github.com/klend-lab/klend-protocol/blob/main/slowmist.pdf'],
    forkedFrom: ['Compound'],
    listedAt: 1637033021,
    slug: 'klend',
    tvl: 1109285.5521730338,
    chainTvls: {
      OKExChain: 417817.40589253453,
      pool2: 7727.32990526448,
      'Binance-borrowed': 241002.84578393336,
      'Binance-pool2': 4324.564969292736,
      Binance: 691468.1462804993,
      borrowed: 368472.1098210785,
      'OKExChain-borrowed': 127469.26403714513,
      'OKExChain-pool2': 3402.7649359717434,
    },
    change_1h: 0.6004724274792039,
    change_1d: -1.3823219718031936,
    change_7d: 2.9615351141925714,
    pool2: 7727.32990526448,
    mcap: 0,
  },
  {
    id: '922',
    name: 'Big Data Protocol',
    address: '0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e',
    symbol: 'BDP',
    url: 'https://www.bigdataprotocol.com',
    description:
      'Big Data Protocol tokenizes commercially valuable data through a network of 14,141 professional data providers and makes the data token liquid on Uniswap. Users earn data by providing liquidity to data tokens.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/big-data-protocol.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'big-data-protocol',
    cmcId: '8708',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'bigdataprotocol/index.js',
    twitter: 'bigdataprotocol',
    listedAt: 1637977582,
    slug: 'big-data-protocol',
    tvl: 1109013.1630090121,
    chainTvls: { Ethereum: 1109013.1630090121, 'Ethereum-staking': 441619.5305686608, staking: 441619.5305686608 },
    change_1h: 0.8773593437488358,
    change_1d: 4.310135777436685,
    change_7d: 17.665843416085792,
    staking: 441619.5305686608,
    fdv: 6150501,
    mcap: 1857388,
  },
  {
    id: '799',
    name: 'Rubicon',
    address: null,
    symbol: '-',
    url: 'https://www.rubicon.finance',
    description:
      'Democratizing the open order book and bringing decentralized exchange to the masses. Built on Ethereum L2.',
    chain: 'Optimism',
    logo: 'https://icons.llama.fi/rubicon.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Optimism'],
    module: 'rubicon/index.js',
    twitter: 'rubicondefi',
    audit_links: ['https://docs.rubicon.finance/audits'],
    listedAt: 1636304524,
    slug: 'rubicon',
    tvl: 1108958.73464936,
    chainTvls: { Optimism: 1108958.73464936 },
    change_1h: 2.842170943040401e-14,
    change_1d: 1.4282802156630083,
    change_7d: 4.490393174046133,
  },
  {
    id: '698',
    name: 'EvolutionLand',
    address: '0x9f284e1337a815fe77d2ff4ae46544645b20c5ff',
    symbol: 'KTON',
    url: 'http://evolution.land',
    description:
      'Evolution Land is a virtual simulation blockchain game that supports cross-chain interactions. Evolution Land integrates various gameplays, including management, collection, education, gene, battle, auction, and game which will continuously be evolving.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/evolutionland.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'darwinia-commitment-token',
    cmcId: '5931',
    category: 'Gaming',
    chains: ['Ethereum', 'Polygon', 'Heco'],
    module: 'evolutionland/index.js',
    twitter: 'Evolution_Land',
    audit_links: ['https://www.certik.org/projects/evolutionland'],
    oracles: ['Chainlink'],
    listedAt: 1634932898,
    slug: 'evolutionland',
    tvl: 1103346.021394411,
    chainTvls: { Ethereum: 1019515.2835228933, Heco: 32135.419101365962, Polygon: 51695.318770151796 },
    change_1h: 0.8920111961032688,
    change_1d: -0.6989118655383919,
    change_7d: 21.137961720547736,
    fdv: 3225398,
    mcap: 1404821,
  },
  {
    id: '650',
    name: 'Augmented Finance',
    address: '0xb3ed706b564bba9cab64042f4e1b391be7bebce5',
    symbol: 'AGF',
    url: 'https://augmented.finance',
    description:
      'Augmented Finance is a DeFi liquidity protocol for high-yield lending and low-interest borrowing of digital assets, launched fairly and enabled by artificial intelligence (AI).',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/augmented-finance.svg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum', 'Avalanche', 'Binance'],
    module: 'augmented-finance/index.js',
    twitter: 'augmentedfin',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Augmented-v1.0.pdf',
    ],
    oracles: ['Chainlink'],
    slug: 'augmented-finance',
    tvl: 1094120.5162905399,
    chainTvls: { Binance: 182779.800306691, Ethereum: 497070.3185667014, Avalanche: 414270.39741714747 },
    change_1h: 0.3487928292882998,
    change_1d: 0.23867491735781243,
    change_7d: 4.868086842002555,
  },
  {
    id: '256',
    name: 'Allinx',
    address: 'bsc:0xd60D91EAE3E0F46098789fb593C06003253E5D0a',
    symbol: 'INX',
    url: 'https://allinx.io',
    description: 'Simplify financial use cases for you.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/allinx.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'allinx.js',
    twitter: 'allinxproject',
    slug: 'allinx',
    tvl: 1092381.58,
    chainTvls: { Binance: 1092381.58 },
    change_1h: 0.04152470983073897,
    change_1d: 2.2560303524680023,
    change_7d: 10.580418505554334,
  },
  {
    id: '1275',
    name: 'PERI Finance',
    address: '0x5d30ad9c6374bf925d0a75454fa327aacf778492',
    symbol: 'PERI',
    url: 'https://pynths.com',
    description: 'Decentralized cross-chain based synthetic asset issuance / exchange protocol',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/peri-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'peri-finance',
    cmcId: '9550',
    category: 'Synthetics',
    chains: ['Polygon', 'Ethereum', 'Binance'],
    module: 'perifinance/index.js',
    twitter: 'PERIfinance',
    audit_links: ['https://www.certik.org/projects/perifinance'],
    oracles: ['Chainlink'],
    listedAt: 1642450048,
    slug: 'peri-finance',
    tvl: 1087341.7661269999,
    chainTvls: { Binance: 214662.881504, Ethereum: 235993.38554299998, Polygon: 636685.49908 },
    change_1h: -0.11158841158840005,
    change_1d: 0.38051052667047713,
    change_7d: -1.3807107855772784,
    fdv: 4919511,
    mcap: 1840367,
  },
  {
    id: '1429',
    name: 'GoodDollar',
    address: '0x67C5870b4A41D4Ebef24d2456547A03F1f3e094B',
    symbol: 'G$',
    url: 'https://www.gooddollar.org',
    description:
      'GoodDollar is a universal basic income protocol and DAO to fight inequality that leverages staking and market incentives to sustainably fund the distribution of free crypto to underserved members all over the world. As of today, GoodDollar UBI serves over 300,000 members from 180 territories; 70,000 which are daily active users.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/gooddollar.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '6961',
    category: 'Staking',
    chains: ['Ethereum', 'Fuse'],
    oracles: [],
    forkedFrom: [],
    module: 'gooddollar/index.js',
    twitter: 'gooddollarorg',
    audit_links: ['https://github.com/GoodDollar/GoodProtocol/tree/master/audits'],
    listedAt: 1645138963,
    slug: 'gooddollar',
    tvl: 1085224.0149725885,
    chainTvls: {
      'Fuse-staking': 204292.93387165814,
      Ethereum: 1085224.0149725885,
      'Fuse-Treasury': 42232.74073026394,
      Fuse: 0,
      Treasury: 42232.74073026394,
      staking: 204292.93387165814,
    },
    change_1h: 4.4994101244810736e-7,
    change_1d: 0.03313871760393283,
    change_7d: 4.759985702434506,
    staking: 204292.93387165814,
  },
  {
    id: '339',
    name: 'Lien',
    address: '0xab37e1358b639fd877f015027bb62d3ddaa7557e',
    symbol: 'LIEN',
    url: 'https://lien.finance/',
    description: 'A protocol for creating Options and Stablecoins out of ETH',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/lien.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'lien',
    cmcId: '6705',
    category: 'Options',
    chains: ['Ethereum'],
    module: 'lien/index.js',
    twitter: 'LienFinance',
    audit_links: ['https://lien.finance/risk'],
    oracles: ['Chainlink'],
    slug: 'lien',
    tvl: 1081924.5777114176,
    chainTvls: { Ethereum: 1081924.5777114176 },
    change_1h: 0.6233285570915541,
    change_1d: 4.025344316270193,
    change_7d: 15.655981701078048,
    fdv: 1740739,
    mcap: 617743,
  },
  {
    id: '1282',
    name: 'Rubic',
    address: '0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3',
    symbol: 'RBC',
    url: 'https://rubic.exchange',
    description:
      'Rubic is the Multi-Chain Swaps protocol that enables users to complete swaps between 10,500+ assets on Ethereum, Binance Smart Chain, Polygon, Avalanche, Moonriver, Fantom, Solana and Harmony in 1 click.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/rubic.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'rubic',
    cmcId: '1393',
    category: 'Cross Chain',
    chains: [
      'Binance',
      'Avalanche',
      'Arbitrum',
      'Polygon',
      'Aurora',
      'Fantom',
      'Ethereum',
      'Moonriver',
      'Harmony',
      'Solana',
    ],
    module: 'rubic/index.js',
    twitter: 'CryptoRubic',
    audit_links: ['https://www.certik.com/projects/rubic-finance', 'https://rubic.exchange/assets/pdf/fairyproof.pdf'],
    listedAt: 1642536396,
    slug: 'rubic',
    tvl: 1073694.6147952115,
    chainTvls: {
      Moonriver: 79158.54211684424,
      Avalanche: 158418.86420232715,
      Harmony: 53845.25224531339,
      Binance: 238553.88148729925,
      Ethereum: 90528.09429958285,
      Aurora: 105364.60784937673,
      'Binance-staking': 2524745.1194741945,
      Polygon: 112148.80893496991,
      Solana: 14612.837250983099,
      Arbitrum: 120352.13733623848,
      Fantom: 100711.58907227629,
      staking: 2524745.1194741945,
    },
    change_1h: 0.48587679127334127,
    change_1d: 4.3805581741436015,
    change_7d: -1.0600050627666064,
    staking: 2524745.1194741945,
    fdv: 42939298,
    mcap: 38229826,
  },
  {
    id: '724',
    name: 'Handle Fi',
    address: '0xdb298285fe4c5410b05390ca80e8fbe9de1f259b',
    symbol: 'FOREX',
    url: 'https://handle.fi',
    description:
      'handle.fi is a decentralised multi currency stablecoin protocol. The handle protocol allows users to create (borrow) multi-currency stablecoins; called fxTokens. fxTokens are collateral backed stablecoins representing, and soft pegged to, a range of currencies.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/handle-fi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'handle-fi',
    cmcId: '11794',
    category: 'CDP',
    chains: ['Ethereum', 'Arbitrum'],
    module: 'handlefi/index.js',
    twitter: 'handle_fi',
    oracles: ['Chainlink'],
    audit_links: ['https://drive.google.com/file/d/1WMbvtLCA07z5Jlg9k4tV76x8yDWV66-Z/view'],
    listedAt: 1635466605,
    slug: 'handle-fi',
    tvl: 1069923.299594014,
    chainTvls: {
      Ethereum: 542584.3087871886,
      pool2: 394399.6511658282,
      Arbitrum: 527338.9908068255,
      'Arbitrum-pool2': 394399.6511658282,
    },
    change_1h: 0.4239856201441796,
    change_1d: -16.17422436185774,
    change_7d: -0.34700818353839225,
    pool2: 394399.6511658282,
    fdv: 57700287,
    mcap: 3240210,
  },
  {
    id: '1117',
    name: 'Crow Finance',
    address: 'cronos:0x285c3329930a3fd3C7c14bC041d3E50e165b1517',
    symbol: 'CROW',
    url: 'https://crowfi.app/',
    description: 'Built on the Cronos Network. Stake & Swap from your wallet.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/crowfi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '8533',
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'crowfi/index.js',
    twitter: 'crowfi_',
    audit_links: ['https://www.certik.com/projects/crowdefi'],
    listedAt: 1640643322,
    forkedFrom: ['Uniswap'],
    slug: 'crow-finance',
    tvl: 1060616.6413614366,
    chainTvls: { Cronos: 1060616.6413614366 },
    change_1h: 0.44555265205701744,
    change_1d: -0.9293046382291124,
    change_7d: 12.357659277916142,
  },
  {
    id: '552',
    name: 'SeaDex',
    address: 'moonriver:0x6fc9651f45B262AE6338a701D563Ab118B1eC0Ce',
    symbol: 'CWS',
    url: 'https://dex.seascape.network/#/swap',
    description: 'SeaDex is a DEX on the Kusama Blockchain. Running on the Moonriver parachain.',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/seadex.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'crowns',
    cmcId: '8365',
    category: 'Dexes',
    chains: ['Moonriver'],
    module: 'seascape/index.js',
    twitter: 'seascapenetwork',
    forkedFrom: ['Uniswap'],
    slug: 'seadex',
    tvl: 1057733.3119874483,
    chainTvls: { Moonriver: 1057733.3119874483 },
    change_1h: 2.3150765182057285,
    change_1d: 5.115563520279906,
    change_7d: 24.602547755241872,
    fdv: 44162880,
    mcap: 10159802,
  },
  {
    id: '1529',
    name: 'Gamblefi',
    address: 'cronos:0xD465b6B4937D768075414D413e981Af0b49349Cc',
    symbol: 'BETIFY',
    url: 'https://gamblefi.io/',
    description:
      "GambleFi is a Rebasing DAO protocol that features various external services meant to increase the protocol's treasury and redistribute dividends to users.",
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/gamblefi.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'betify',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Cronos'],
    oracles: [],
    forkedFrom: ['Wonderland'],
    module: 'gamblefi/index.js',
    twitter: 'GambleFinance',
    listedAt: 1646949250,
    slug: 'gamblefi',
    tvl: 1052208.7035405308,
    chainTvls: { Cronos: 1052208.7035405308, 'Cronos-staking': 917461.0471281172, staking: 917461.0471281172 },
    change_1h: -0.940927630401788,
    change_1d: 3.1156899132248554,
    change_7d: -2.955748747541989,
    staking: 917461.0471281172,
    mcap: 0,
  },
  {
    id: '666',
    name: 'OrcaDAO',
    address: 'avax:0x8b1d98a91f853218ddbb066f20b8c63e782e2430',
    symbol: 'ORCA',
    url: 'https://www.avai.finance',
    description:
      'OrcaDAO is the first native Stablecoin (AVAI), and Governance token (ORCA) pair that allow for lending using crypto collateral at 0% interest, built on and for the Avalanche Network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/orcadao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'orcadao',
    cmcId: '12473',
    category: 'Lending',
    chains: ['Avalanche'],
    module: 'orcadao/index.js',
    twitter: 'OrcaDAO',
    audit_links: ['https://paladinsec.co/projects/orcadao'],
    listedAt: 1634100536,
    slug: 'orcadao',
    tvl: 1051007.0310684785,
    chainTvls: {
      'Avalanche-staking': 37382.31856870039,
      'Avalanche-Treasury': 60059.82446559847,
      pool2: 45103.4902882024,
      Avalanche: 1051007.0310684785,
      'Avalanche-pool2': 45103.4902882024,
      borrowed: 614706.5859420473,
      Treasury: 60059.82446559847,
      'Avalanche-borrowed': 614706.5859420473,
      staking: 37382.31856870039,
    },
    change_1h: 0.4944112023372469,
    change_1d: 2.2954468622032635,
    change_7d: 6.519359521432833,
    staking: 37382.31856870039,
    pool2: 45103.4902882024,
    mcap: 0,
  },
  {
    id: '327',
    name: 'YouSwap',
    address: 'heco:0x9364e119ad76e0346126afcbdf5c9f0189500cc5',
    symbol: 'YOU',
    url: 'https://www.youswap.com/',
    description: 'AMM',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/youswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'youswap',
    cmcId: '10070',
    category: 'Dexes',
    forkedFrom: ['Uniswap'],
    chains: ['Heco', 'Ethereum', 'Binance'],
    module: 'youswap/index.js',
    twitter: 'YouSwap_Global',
    audit_links: ['https://youswap-1.gitbook.io/youswap/develop/audit-agency'],
    slug: 'youswap',
    tvl: 1050237.3896560017,
    chainTvls: { Binance: 109731.55615215012, Ethereum: 155257.13779096576, Heco: 785248.6957128859 },
    change_1h: -0.07899909534636151,
    change_1d: 0.7967619009402682,
    change_7d: 1.7019338507226536,
    fdv: 723087,
    mcap: 0,
  },
  {
    id: '404',
    name: 'BXH',
    address: 'heco:0xcbd6cb9243d8e3381fea611ef023e17d1b7aedf0',
    symbol: 'BXH',
    url: 'https://bxh.com',
    description: 'BXH (Bitcoin Dex on HECO) is an innovative Dex trading platform developed based on HECO Chain.',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/bxh.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bxh',
    cmcId: null,
    category: 'Yield',
    chains: ['Heco'],
    forkedFrom: ['Uniswap'],
    module: 'bxh/index.js',
    twitter: 'BXH_Blockchain',
    audit_links: ['https://mos-wallet-public.oss-cn-hangzhou.aliyuncs.com/mos/BXH/bxh_audit.pdf'],
    slug: 'bxh',
    tvl: 1042376.777207118,
    chainTvls: { Heco: 1042376.777207118 },
    change_1h: 0.18255270963057058,
    change_1d: 1.58970773742017,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '742',
    name: 'Furucombo',
    address: 'polygon:0x6ddb31002abc64e1479fc439692f7ea061e78165',
    symbol: 'COMBO',
    url: 'https://furucombo.app',
    description:
      "Furucombo starts from a tool built for end-users to optimize their DeFi strategy simply by drag and drop, and it's now a DeFi portal to optimize your crypto wealth.",
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/furucombo.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'furucombo',
    cmcId: '8259',
    category: 'Yield',
    chains: ['Polygon'],
    module: 'furucombo/index.js',
    twitter: 'furucombo',
    audit_links: ['https://docs.furucombo.app/audit'],
    listedAt: 1635804510,
    slug: 'furucombo',
    tvl: 1041348.8509267491,
    chainTvls: { Polygon: 1041348.8509267491 },
    change_1h: 0.10084229708840553,
    change_1d: 3.497012369997293,
    change_7d: -88.57859191630524,
    fdv: 15553014,
    mcap: 4749124,
  },
  {
    id: '462',
    name: 'Augury Finance',
    address: 'polygon:0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e',
    symbol: 'OMEN',
    url: 'https://augury.finance',
    description:
      'Augury Finance is a financial ecosystem on the Polygon blockchain, which focuses on trust, community ownership, and sustainable returns.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/augury-finance.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: 'augury-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'auguryfinance/index.js',
    twitter: 'AuguryFinance',
    slug: 'augury-finance',
    tvl: 1029747.3706168372,
    chainTvls: { Polygon: 1029747.3706168372, 'Polygon-Treasury': 479588.46627048485, Treasury: 479588.46627048485 },
    change_1h: 0.1524458679676286,
    change_1d: -1.653827475875886,
    change_7d: 0.33858654591914217,
    mcap: 0,
  },
  {
    id: '646',
    name: 'UniFi',
    address: '0x441761326490cacf7af299725b6292597ee822c2',
    symbol: 'UNFI',
    url: 'https://unifiprotocol.com',
    description:
      'Unifi Protocol is a group of non-custodial, interoperable smart contracts linking multiple blockchains together into one large DeFi marketplace.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/unifi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'unifi-protocol-dao',
    cmcId: '1412',
    category: 'Dexes',
    chains: [
      'Binance',
      'Tron',
      'IoTeX',
      'Harmony',
      'Icon',
      'Ontology',
      'Ethereum',
      'Avalanche',
      'Polygon',
      'Bittorrent',
    ],
    module: 'unifi-protocol/index.js',
    twitter: 'unifiprotocol',
    audit_links: [
      'https://drive.google.com/file/d/10Qdqj-ry4OhsQX3SesSRc1nEsNZ8UFkD/view',
      'https://medium.com/unifiprotocol/unifi-protocol-passes-slowmist-audit-9694b55b77ba',
    ],
    slug: 'unifi',
    tvl: 1023698.4916789101,
    chainTvls: {
      Icon: 48319.01508851,
      Ontology: 25401.05566814,
      Avalanche: 12569.68338641,
      Harmony: 66724.41682869,
      Bittorrent: 969.02907169,
      Binance: 430510.6464689401,
      IoTeX: 164125.15601465007,
      Ethereum: 16396.63949309,
      Polygon: 12099.740089230003,
      Tron: 246583.10956955995,
    },
    change_1h: 0,
    change_1d: 0.6090351224258939,
    change_7d: -8.287948003666628,
    fdv: 62481596,
    mcap: 32967564,
  },
  {
    id: '645',
    name: 'LatteSwap',
    address: 'bsc:0xa269a9942086f5f87930499dc8317ccc9df2b6cb',
    symbol: 'LATTE',
    url: 'https://app.latteswap.com',
    description: 'A caffeine-filled, NFT-integrated DEX platform on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/latteswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'latteswap',
    cmcId: '12324',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'latte/index.js',
    twitter: 'latteswapOG',
    audit_links: ['https://docs.latteswap.com/latteswap/security/audits'],
    forkedFrom: ['Uniswap'],
    slug: 'latteswap',
    tvl: 1016721.2036302395,
    chainTvls: { Binance: 1016721.2036302395, 'Binance-staking': 58444.61504070084, staking: 58444.61504070084 },
    change_1h: -0.40411138980475414,
    change_1d: -3.5992620176836425,
    change_7d: -4.862195222635393,
    staking: 58444.61504070084,
    mcap: 0,
  },
  {
    id: '1398',
    name: '1Beam',
    address: 'moonbeam:0x19d2f0CF1FC41DE2b8fd4A98065AB9284E05Cf29',
    symbol: '1BEAM',
    url: 'https://1beam.io',
    description: '1Beam is building a decentralised application ecosystem on the Moonbeam Ecosystem.',
    chain: 'Moonbeam',
    logo: 'https://icons.llama.fi/1beam.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonbeam'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: '1beam/index.js',
    twitter: '1beamio',
    language: 'Solidity',
    audit_links: ['https://docs.1beam.io/audit'],
    listedAt: 1644597493,
    slug: '1beam',
    tvl: 995051.557903497,
    chainTvls: { Moonbeam: 995051.557903497 },
    change_1h: 0.04909084424508592,
    change_1d: -20.95667734793774,
    change_7d: -32.50905722109138,
  },
  {
    id: '1032',
    name: 'Percent Finance',
    address: 'eth:0xbc16da9df0a22f01a16bc0620a27e7d6d6488550',
    symbol: 'PCT',
    url: 'https://percent.finance/',
    description: 'A 100% community-owned fork of compoundfinance',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/percent-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'percent',
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'percent/index.js',
    twitter: 'PercentFinance',
    listedAt: 1639774643,
    forkedFrom: ['Compound'],
    slug: 'percent-finance',
    tvl: 979345.513778641,
    chainTvls: {
      pool2: 331740.1873467363,
      'Ethereum-pool2': 331740.1873467363,
      Ethereum: 979345.513778641,
      'Ethereum-staking': 3115566.1159380674,
      'Ethereum-borrowed': 52.379153433901884,
      borrowed: 52.379153433901884,
      staking: 3115566.1159380674,
    },
    change_1h: 0.24006353376584855,
    change_1d: 5.558152394709666,
    change_7d: 18.73391199595136,
    staking: 3115566.1159380674,
    pool2: 331740.1873467363,
    fdv: 30643010,
    mcap: 13144477,
  },
  {
    id: '674',
    name: 'RskSwap',
    address: null,
    symbol: '-',
    url: 'https://rskswap.com',
    description: 'RSK Swap is a fork of the Uniswap Protocol adapted for the RSK network.',
    chain: 'RSK',
    logo: 'https://icons.llama.fi/rskswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['RSK'],
    module: 'rskswap/index.js',
    twitter: 'RskSwap',
    forkedFrom: ['Uniswap'],
    listedAt: 1634321814,
    slug: 'rskswap',
    tvl: 971641.6918178414,
    chainTvls: { RSK: 971641.6918178414 },
    change_1h: 0,
    change_1d: 1.765718095724722,
    change_7d: 10.682892363097125,
  },
  {
    id: '628',
    name: 'Float Capital',
    address: 'polygon:0x01309A1Ec476871760D9Ea454628500BCcc1E011',
    symbol: 'FLT',
    url: 'https://float.capital',
    description:
      'Float DAO is a novel protocol, creating an efficient magic internet asset marketplace, governed entirely by code, allowing anyone around the world to get involved.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/float-capital.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Derivatives',
    chains: ['Polygon', 'Avalanche'],
    module: 'float-capital/index.js',
    twitter: 'float_capital',
    audit_links: ['https://code423n4.com/reports/2021-08-floatcapital'],
    oracles: ['Chainlink'],
    slug: 'float-capital',
    tvl: 968549.034786769,
    chainTvls: { Avalanche: 152588.20044775272, Polygon: 815960.8343390162 },
    change_1h: 0.0000055740213156241225,
    change_1d: -30.87261498205072,
    change_7d: 12.056084463908064,
  },
  {
    id: '685',
    name: 'Tesseract',
    address: null,
    symbol: '-',
    url: 'https://tesr.finance/#/',
    description:
      'In collaboration with the Yearn team we bring you the true power of DeFi by using the best Yearn strategies adapted for Polygon. Add liquidity to Tesseract and generate juicy yields.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/tesseract.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'tesseract/index.js',
    twitter: 'tesseract_fi',
    forkedFrom: ['Yearn Finance'],
    listedAt: 1634673391,
    slug: 'tesseract',
    tvl: 966674.7583454603,
    chainTvls: { Polygon: 966674.7583454603 },
    change_1h: -0.003705948792799063,
    change_1d: -0.4185657672269798,
    change_7d: -3.0601915001791014,
  },
  {
    id: '549',
    name: 'Vigor',
    address: 'eos:VIG',
    symbol: 'VIG',
    url: 'https://vigor.ai',
    description:
      'Vigor protocol is an institutional grade financial engineering DeFi lending platform delivering autonomous price discovery between crypto lenders and borrowers.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/vigor.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'vig',
    cmcId: '6562',
    category: 'Lending',
    chains: ['EOS'],
    module: 'vigor/index.js',
    twitter: 'vigorprotocol',
    audit_links: [
      'https://s3.eu-central-1.wasabisys.com/audit-certificates/Smart%20Contract%20Audit%20Certificate%20-%20Vigorprotocol.pdf',
    ],
    slug: 'vigor',
    tvl: 959378.3230866692,
    chainTvls: { EOS: 959378.3230866692 },
    change_1h: -0.23570945242029495,
    change_1d: -0.8646485766362986,
    change_7d: 4.307228985743578,
    mcap: 693701,
  },
  {
    id: '692',
    name: 'HurricaneSwap',
    address: 'avax:0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
    symbol: 'HCT',
    url: 'https://hurricaneswap.com',
    description: 'HurricaneSwap is the 1st cross-chain liquidity DEX based on Avalanche.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/hurricaneswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'hurricaneswap-token',
    cmcId: '11885',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'hurricaneswap.js',
    twitter: 'HurricaneSwap',
    audit_links: [
      'https://github.com/HurricaneSam/AuditReport/blob/main/PeckShield-Audit-Report-HurricanSwapV2-v1.0rc.pdf',
    ],
    forkedFrom: ['Uniswap'],
    listedAt: 1634834188,
    slug: 'hurricaneswap',
    tvl: 959129.9516140597,
    chainTvls: { Avalanche: 959129.9516140597, 'Avalanche-staking': 959181.2217234178, staking: 959181.2217234178 },
    change_1h: 0,
    change_1d: 1.4386581711021336,
    change_7d: 13.677992312888861,
    staking: 959181.2217234178,
    fdv: 10640884,
    mcap: 0,
  },
  {
    id: '976',
    name: 'WingSwap',
    address: 'fantom:0xF24be6c063Bee7c7844dD90a21fdf7d783d41a94',
    symbol: 'WIS',
    url: 'https://wingswap.io',
    description:
      'WingSwap is a dedicated Decentralized Exchange (DEX) and a staking platform where users can provide liquidity to the DEX, trade different types of assets in a decentralized way and get rewarded for doing so.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/wingswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'wingswap/index.js',
    twitter: 'WingSwapFTM',
    audit_links: ['https://wingswap.gitbook.io/wingswap/getting-started/audit-1'],
    forkedFrom: ['Uniswap'],
    listedAt: 1638612818,
    slug: 'wingswap',
    tvl: 958670.8655387808,
    chainTvls: { 'Fantom-staking': 50844.462471192106, staking: 50844.462471192106, Fantom: 958670.8655387808 },
    change_1h: 0.26611617380369523,
    change_1d: -0.13048614256233293,
    change_7d: -6.19686652890482,
    staking: 50844.462471192106,
  },
  {
    id: '1502',
    name: 'The Parallel',
    address: 'bsc:0xd07e82440a395f3f3551b42da9210cd1ef4f8b24',
    symbol: 'PRL',
    url: 'https://theparallel.io',
    description:
      'The Parallel is the birth and evolution of an infinitely evolving GameFi metaverse, aiming to bring the world together to craft and launch a massive diversity of social, entertainment, gaming and economic systems',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/the-parallel.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'the-parallel',
    cmcId: '15669',
    category: 'Gaming',
    chains: ['Binance'],
    oracles: ['Coingecko'],
    forkedFrom: [],
    module: 'the-parallel/index.js',
    twitter: 'theparallel_io',
    audit_links: [
      'https://github.com/verichains/public-audit-reports/blob/main/Verichains%20Public%20Audit%20Report%20-%20TheParallel%20-%20v1.1.pdf',
    ],
    listedAt: 1646333199,
    slug: 'the-parallel',
    tvl: 944023.5542380365,
    chainTvls: { Binance: 944023.5542380365, 'Binance-staking': 708332.53148686, staking: 708332.53148686 },
    change_1h: 0.2709380596765669,
    change_1d: 2.802561497824314,
    change_7d: -3.7874530477338197,
    staking: 708332.53148686,
    fdv: 96841455,
    mcap: 2921885,
  },
  {
    id: '1007',
    name: 'SafeSwap',
    address: 'polygon:0xd0cfd20e8bbdb7621b705a4fd61de2e80c2cd02f',
    symbol: 'SSGTX',
    url: 'https://safeswap-matic.yfdai.finance/#/swap',
    description:
      'SafeSwap is a decentralized crypto-asset exchange (DEX), enabling the listing and trading of native crypto tokens.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/safeswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Polygon', 'Ethereum'],
    module: 'safeswap/index.js',
    twitter: 'DexSafeSwap',
    forkedFrom: ['Uniswap'],
    listedAt: 1639276917,
    slug: 'safeswap',
    tvl: 940593.5246866709,
    chainTvls: { Ethereum: 285921.0783087579, Polygon: 654672.4463779129 },
    change_1h: 0.1124894034014261,
    change_1d: 1.851702567537103,
    change_7d: 3.577726750291859,
  },
  {
    id: '1049',
    name: 'Globiance',
    address: 'xdc:xdc34514748f86a8da01ef082306b6d6e738f777f5a',
    symbol: 'GBEX',
    url: 'https://dex.globiance.com',
    description:
      'GlobianceDEX is the first decentralized exchange built on the leading XDC (XinFin) blockchain. Developed as an Automated-market-maker (AMM) on XDC, its goal is to become a launchpad for all XRC20 tokens, facilitate instant swaps within XDC & across different chains.',
    chain: 'XDC',
    logo: 'https://icons.llama.fi/globiance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['XDC'],
    module: 'globiancedex/index.js',
    twitter: 'globiance',
    audit_links: ['https://dexapi.globiance.com/assets/GlobianceDEXAudit.pdf'],
    forkedFrom: ['Uniswap'],
    listedAt: 1639872890,
    slug: 'globiance',
    tvl: 915494.1502322201,
    chainTvls: { XDC: 915494.1502322201 },
    change_1h: 0.011387923023647772,
    change_1d: 0.7169367610219126,
    change_7d: 22.43681819910313,
  },
  {
    id: '1400',
    name: 'Lizard',
    address: 'oasis:0x6a977d5f48D57eF125C98469D511Ef4E0cE44E10',
    symbol: 'LIZ',
    url: 'https://lizard.exchange',
    description:
      'Lizard Exchange is the first DEX in Oasis Emerald Paratime to contribute with full exchange analytics, taxed token implementation and EIP support with the objective to welcome new builders and users alike.',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/lizard-exchange.png',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Oasis'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'LizardExchange/index.js',
    twitter: 'LizardExchange',
    listedAt: 1644598160,
    slug: 'lizard',
    tvl: 911726.0950079488,
    chainTvls: { Oasis: 911726.0950079488 },
    change_1h: -0.16012383153834264,
    change_1d: 0.2301495491540635,
    change_7d: 9.687719729215075,
  },
  {
    id: '248',
    name: 'ForceDAO',
    address: '0x2c31b10ca416b82cec4c5e93c615ca851213d48d',
    symbol: 'FORCE',
    url: 'https://forcedao.com/',
    description: 'Your portal for cross-chain yield ',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/forcedao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'force-dao',
    cmcId: '9112',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'forcedao/index.js',
    twitter: 'force_dao',
    slug: 'forcedao',
    tvl: 880010.7205704289,
    chainTvls: { Ethereum: 880010.7205704289 },
    change_1h: -0.7879002824600576,
    change_1d: -1.81698903334852,
    change_7d: -2.109076077068167,
    mcap: 85046,
  },
  {
    id: '935',
    name: 'Pole Aggregator',
    address: 'solana:PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3',
    symbol: 'POLE',
    url: 'https://pole.finance',
    description: 'Pole Aggregator is the leveraged yield aggregator built on Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/pole-aggregator.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Solana'],
    module: 'pole/index.js',
    twitter: 'Pole_Finance',
    listedAt: 1638189746,
    openSource: false,
    slug: 'pole-aggregator',
    tvl: 878755.9139294998,
    chainTvls: { Solana: 878755.9139294998 },
    change_1h: 0,
    change_1d: -53.39838549083152,
    change_7d: -56.00734669244348,
  },
  {
    id: '669',
    name: 'Shield',
    address: 'bsc:0x1ef6a7e2c966fb7c5403efefde38338b1a95a084',
    symbol: 'SLD',
    url: 'https://shieldex.io',
    description:
      "Shield is building a one-of-a-kind decentralized protocol for future derivatives infrastructure, enabling global borderless access to finance. Shield's closing in on being one of the 1st projects to deliver on-chain perpetual options without the risk of margin liquidation",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/shield.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'shield-dao',
    cmcId: '9662',
    category: 'Options',
    chains: ['Binance'],
    module: 'shield/index.js',
    twitter: 'shield_dao',
    audit_links: [
      'https://github.com/slowmist/Knowledge-Base/blob/master/open-report/Smart%20Contract%20Security%20Audit%20Report%20-%20Shield.pdf',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Shield-v1.0.pdf',
    ],
    oracles: ['Chainlink'],
    listedAt: 1634242012,
    slug: 'shield',
    tvl: 870653.8841271967,
    chainTvls: { Binance: 870653.8841271967 },
    change_1h: 0,
    change_1d: 0.2967993758307301,
    change_7d: -1.7882475246860992,
    mcap: 0,
  },
  {
    id: '563',
    name: 'Timewarp',
    address: '0x485d17a6f1b8780392d53d64751824253011a260',
    symbol: 'TIME',
    url: 'https://timewarp.finance',
    description: 'TimeWarp is a DeFi staking program for TIME, the native token of the Chrono.tech ecosystem.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/timewarp.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'chronobank',
    cmcId: '1556',
    category: 'Staking',
    chains: ['Binance', 'Ethereum'],
    module: 'timewarp/index.js',
    twitter: 'ChronotechNews',
    slug: 'timewarp',
    tvl: 855571.3257440645,
    chainTvls: {
      Binance: 522409.1504815471,
      Ethereum: 333162.17526251735,
      'Binance-staking': 8357737.319229163,
      'Ethereum-staking': 21402906.02927977,
      staking: 29760643.34850893,
    },
    change_1h: 0.9113866854639383,
    change_1d: -0.024638722034325156,
    change_7d: -0.8696528158404533,
    staking: 29760643.34850893,
    mcap: 57708125,
  },
  {
    id: '1170',
    name: 'MetaReserve',
    address: 'bsc:0x000c6322Df760155bBe4F20F2EDD8F4Cd35733A6',
    symbol: 'POWER',
    url: 'https://metareserve.finance/',
    description:
      'MetaReserve is a Decentralized Reserve Currency Protocol that aims to take the lead in setting a new benchmark in the wild west world of Decentralized Finance (DeFi).',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/metareserve.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'metareserve/index.js',
    twitter: 'MetaReserveDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641061623,
    slug: 'metareserve',
    tvl: 852783.322161434,
    chainTvls: { Binance: 852783.322161434, 'Binance-staking': 573525.5463935231, staking: 573525.5463935231 },
    change_1h: 0.3856889375631454,
    change_1d: -0.7925878786736433,
    change_7d: 4.437940956362382,
    staking: 573525.5463935231,
  },
  {
    id: '1312',
    name: 'Epsylon Finance',
    address: null,
    symbol: '-',
    url: 'https://www.epsylon.finance/',
    description:
      'Epsylon.finance is a single asset, multi-strategy yield generation. Epsylon divides yield vaults by a risk profile (Safe, Moderate, Aggressive), simplifying the user experience and maximizing profits for each risk.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/epsylon.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Fantom'],
    module: 'epsylon/index.js',
    twitter: 'epsylon_finance',
    forkedFrom: ['Yearn Finance'],
    listedAt: 1643083764,
    slug: 'epsylon-finance',
    tvl: 830274.6746178935,
    chainTvls: { Fantom: 830274.6746178935 },
    change_1h: 0.2306556734228593,
    change_1d: 23.546598413206766,
    change_7d: 156.8059950328281,
  },
  {
    id: '274',
    name: 'Olive Cash',
    address: 'avax:0x617724974218A18769020A70162165A539c07E8a',
    symbol: 'OLIVE',
    url: 'https://avax.olive.cash',
    description: 'OliveCash is a cross chain Yield Farming project running on Binance Smart Chain Avalanche chain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/olive-cash.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'olivecash',
    cmcId: '10685',
    category: 'Yield',
    chains: ['Binance', 'Avalanche', 'Fantom'],
    module: 'olivecash/index.js',
    twitter: 'OliveCashBsc',
    audit_links: ['https://olive-cash.gitbook.io/olive-cash/bsc-1/audit-1'],
    slug: 'olive-cash',
    tvl: 821024.1359554261,
    chainTvls: { Binance: 313637.0566880258, Avalanche: 285706.6108649565, Fantom: 221680.46840244377 },
    change_1h: 0.07301024261332145,
    change_1d: -1.140733127528776,
    change_7d: -8.932931800627316,
    mcap: 111316,
  },
  {
    id: '735',
    name: 'BecoSwap',
    address: 'kardia:0x2Eddba8b949048861d2272068A94792275A51658',
    symbol: 'BECO',
    url: 'https://becoswap.com',
    description: 'Trade, Earn, Win and GameFi on KardiaChain.',
    chain: 'Kardia',
    logo: 'https://icons.llama.fi/becoswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Kardia'],
    module: 'becoswap/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'beco_swap',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/Becoswap.pdf'],
    listedAt: 1635800029,
    slug: 'becoswap',
    tvl: 813846.4919181291,
    chainTvls: { 'Kardia-staking': 332149.3245476614, Kardia: 813846.4919181291, staking: 332149.3245476614 },
    change_1h: 0,
    change_1d: 6.255515278976986,
    change_7d: 13.641377545843739,
    staking: 332149.3245476614,
  },
  {
    id: '367',
    name: 'PinkSwap',
    address: 'bsc:0x702b3f41772e321aacCdea91e1FCEF682D21125D',
    symbol: 'PINKS',
    url: 'https://www.pinkswap.finance/',
    description:
      'Sale, swap, earn, lock all on one decentralized, community driven platform. Earn PinkS by staking LP & tokens. Welcome to PinkArmy Family!',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pinkswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'pinkswap-token',
    cmcId: '1384',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'pinkswap/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'pinkmoonfinance',
    audit_links: [
      'https://github.com/TechRate/Smart-Contract-Audits/blob/main/PinkSwap%20Token%20Full%20Smart%20Contract%20Security%20Audit.pdf',
    ],
    slug: 'pinkswap',
    tvl: 811736.8636041804,
    chainTvls: { Binance: 811736.8636041804 },
    change_1h: 0.5399735971984683,
    change_1d: 1.857614828783099,
    change_7d: 7.2913957850100815,
    mcap: 0,
  },
  {
    id: '1190',
    name: 'Magnet DAO',
    address: 'avax:0x1d60109178c48e4a937d8ab71699d8ebb6f7c5de',
    symbol: 'MAG',
    url: 'https://magnetdao.finance/',
    description: 'Magnet DAO is developing an advanced crypto incubator.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/magnet-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'magnet-dao',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    module: 'magnet-dao/index.js',
    twitter: 'Magnet_DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641334964,
    slug: 'magnet-dao',
    tvl: 807359.9662368778,
    chainTvls: { 'Avalanche-staking': 5193621.735625252, Avalanche: 807359.9662368778, staking: 5193621.735625252 },
    change_1h: -0.1529037098779895,
    change_1d: -1.2111085429307593,
    change_7d: 4.722825377737763,
    staking: 5193621.735625252,
    fdv: 29787724673109,
    mcap: 4130828,
  },
  {
    id: '165',
    name: 'Cover Protocol',
    address: '0x5d8d9f5b96f4438195be9b99eee6118ed4304286',
    symbol: 'COVER',
    url: 'https://www.coverprotocol.com/',
    description: 'A peer-to-peer coverage market.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/cover-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cover-protocol',
    cmcId: '8175',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'cover.js',
    twitter: 'CoverProtocol',
    audit_links: ['https://github.com/CoverProtocol/cover-security/tree/master/audits'],
    slug: 'cover-protocol',
    tvl: 795877.97,
    chainTvls: { Ethereum: 795877.97 },
    change_1h: -2.842170943040401e-14,
    change_1d: -4.263256414560601e-14,
    change_7d: -4.263256414560601e-14,
    fdv: 2194193,
    mcap: 2474858,
  },
  {
    id: '1509',
    name: 'Solanax',
    address: 'solana:5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61',
    symbol: 'SOLD',
    url: 'https://solanax.org',
    description:
      'SOLANAX is a decentralized and non-custodial automated liquidity mechanism supporting trades within the Solana ecosystem',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/solanax.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'solanax',
    cmcId: '11297',
    category: 'Dexes',
    chains: ['Solana'],
    oracles: [],
    forkedFrom: [],
    module: 'solanax.js',
    twitter: 'Solanaxorg',
    listedAt: 1646426734,
    slug: 'solanax',
    tvl: 783931.3033623216,
    chainTvls: { Solana: 783931.3033623216 },
    change_1h: -0.22694610778444257,
    change_1d: 0.1209115455867078,
    change_7d: 1.1385735265157848,
    fdv: 3368046,
    mcap: 854250,
  },
  {
    id: '1197',
    name: 'Sentre',
    address: 'solana:SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M',
    symbol: 'SNTR',
    url: 'https://hub.sentre.io/welcome',
    description: 'An on-chain AMM powering the evolution of DeFi.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/sentre.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'sentre',
    cmcId: '12979',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'sentre.js',
    twitter: 'SentreProtocol',
    listedAt: 1641420058,
    openSource: false,
    slug: 'sentre',
    tvl: 778152.9209174758,
    chainTvls: { Solana: 778152.9209174758 },
    change_1h: 0,
    change_1d: -0.21808308177911329,
    change_7d: 1.5310528927841887,
    fdv: 8687538,
    mcap: 428202,
  },
  {
    id: '287',
    name: '0.exchange',
    address: '0xf0939011a9bb95c3b791f0cb546377ed2693a574',
    symbol: 'ZERO',
    url: 'https://www.0.exchange/',
    description: "The world's first Multi-DEX platform",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/0.exchange.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'zero-exchange',
    cmcId: '8293',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'zerodex/index.js',
    twitter: 'OfficialZeroDEX',
    forkedFrom: ['Uniswap'],
    oracles: ['Chainlink'],
    slug: '0.exchange',
    tvl: 774501.8946875891,
    chainTvls: { Avalanche: 774501.8946875891 },
    change_1h: 0.3466868851121916,
    change_1d: -3.3108209390004646,
    change_7d: 34.36473182917902,
    fdv: 3016504,
    mcap: 1190725,
  },
  {
    id: '1029',
    name: 'SpicySwap',
    address: 'tezos:KT1CS2xKGHNPTauSh5Re4qE3N9PCfG5u4dPx',
    symbol: 'SPI',
    url: 'https://spicyswap.xyz/#/app',
    description: 'Next-generation DEX built on Tezos',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/spicyswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Tezos'],
    module: 'spicyswap/index.js',
    twitter: 'SpicySwap',
    listedAt: 1639687807,
    slug: 'spicyswap',
    tvl: 767807.0580911232,
    chainTvls: { Tezos: 767807.0580911232 },
    change_1h: 0,
    change_1d: -0.0862715333880999,
    change_7d: 13.467116666269135,
  },
  {
    id: '1471',
    name: 'BetHash',
    address: 'eos:eoshashcoins',
    symbol: 'HASH',
    url: 'https://bethash.io/',
    description: 'BetHash.io uses blockchain hash value to draw the game results to guarantee absolute fairness.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/bethash.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Prediction Market',
    chains: ['EOS'],
    oracles: ['Internal'],
    forkedFrom: [],
    module: 'bethash/index.js',
    twitter: null,
    listedAt: 1645823305,
    slug: 'bethash',
    tvl: 767300.3943779999,
    chainTvls: { EOS: 767300.3943779999 },
    change_1h: -0.06729145865598696,
    change_1d: -4.2907124582501694,
    change_7d: 15.455779692761368,
  },
  {
    id: '746',
    name: 'Nileriver',
    address: 'moonriver:0xc64B360913BA4500E93E9b68dBf8dD20BDb57Dc0',
    symbol: 'NIL',
    url: 'https://www.nileriver.finance',
    description: 'Full-Service DeFi on Moonriver (and more) with countless streams of possibilities!',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/nileriver.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Moonriver'],
    module: 'nileriver/index.js',
    twitter: 'NileRiver_Labs',
    forkedFrom: ['Balancer'],
    listedAt: 1635821585,
    slug: 'nileriver',
    tvl: 758982.4750003989,
    chainTvls: { Moonriver: 758982.4750003989 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 0,
  },
  {
    id: '803',
    name: 'OtterClam',
    address: 'polygon:0x4d6A30EFBE2e9D7A9C143Fce1C5Bb30d9312A465',
    symbol: 'CLAM',
    url: 'https://www.otterclam.finance',
    description:
      "OtterClam is the first decentralized reserve memecoin protocol on Polygon Network based on the CLAM token. Each CLAM token is backed by a basket of assets (e.g. MAI/QUICK/MATIC) in the Otter's treasury, giving it an intrinsic value that it cannot fall below. OtterClam also introduces economic and game-theoretic dynamics into the market through staking and bonding.",
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/otterclam.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'otter-clam',
    cmcId: '13935',
    category: 'Reserve Currency',
    chains: ['Polygon'],
    module: 'otterclam.js',
    twitter: 'otterclam',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1636363218,
    slug: 'otterclam',
    tvl: 745264.6705974694,
    chainTvls: { Polygon: 745264.6705974694, 'Polygon-staking': 1896213.9677163006, staking: 1896213.9677163006 },
    change_1h: -0.16925788427357702,
    change_1d: 0.12499193668098485,
    change_7d: -4.643581252629986,
    staking: 1896213.9677163006,
    mcap: 0,
  },
  {
    id: '999',
    name: 'Luxor Money',
    address: 'fantom:0x6671E20b83Ba463F270c8c75dAe57e3Cc246cB2b',
    symbol: 'LUX',
    url: 'https://Luxor.money',
    description:
      'LUX is a decentralized reserve currency that remains unbound by a peg. Luxor is the first multi-chain, decentralized reserve currency protocol available on the Fantom Opera Network based on the LUX token.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/luxor-money.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'luxor',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Fantom'],
    module: 'luxor/index.js',
    twitter: 'LuxorMoney',
    forkedFrom: ['Olympus DAO'],
    oracles: ['Chainlink'],
    listedAt: 1639028773,
    slug: 'luxor-money',
    tvl: 744560.9271704342,
    chainTvls: { 'Fantom-staking': 263429.7756261782, staking: 263429.7756261782, Fantom: 744560.9271704342 },
    change_1h: 0.768336439669028,
    change_1d: -0.9083809998219152,
    change_7d: 9.67519241775004,
    staking: 263429.7756261782,
    fdv: 6498.17,
    mcap: 0,
  },
  {
    id: '958',
    name: 'Ester Finance',
    address: 'fantom:0x181f3f22c9a751e2ce673498a03e1fdfc0ebbfb6',
    symbol: 'EST',
    url: 'https://ester.finance',
    description: 'Ester is the first native yield farming optimizer on Fantom network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/esterfinance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'ester-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'ester/index.js',
    twitter: 'EsterFinance',
    listedAt: 1638458112,
    slug: 'ester-finance',
    tvl: 743699.943985554,
    chainTvls: {
      pool2: 3257.2296708633567,
      'Fantom-staking': 993.0598146271212,
      'Fantom-pool2': 3257.2296708633567,
      staking: 993.0598146271212,
      Fantom: 743699.943985554,
    },
    change_1h: 2.670729994492362,
    change_1d: -0.38460625211456545,
    change_7d: 18.493598457684172,
    staking: 993.0598146271212,
    pool2: 3257.2296708633567,
    mcap: 0,
  },
  {
    id: '465',
    name: 'Lixir Finance',
    address: '0xd0345d30fd918d7682398acbcdf139c808998709',
    symbol: 'LIX',
    url: 'https://lixir.finance',
    description:
      'Lixir Finance is a fully automated and optimized concentrated liquidity manager. Through Lixir, users and other protocols can deploy liquidity in a tight price range on AMMs like Uniswap V3 and Trident without having to worry about the added complexity of active management - Lixir automates this for them. By deploying liquidity in a tight range around the current price, both capital efficiency and liquidity conditions improve dramatically, and liquidity providers enjoy greatly increased LP fees. In order to maximize efficiency, Lixir makes sure the liquidity maintains in a tight range around the constantly changing price by actively managing it.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/lixir-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'lixir-protocol',
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'lixir/index.js',
    twitter: 'lixirfinance',
    audit_links: ['https://lixir-finance.gitbook.io/lixir-doc/resources/security/audits'],
    slug: 'lixir-finance',
    tvl: 739801.5120890465,
    chainTvls: { Ethereum: 739801.5120890465, 'Ethereum-staking': 56394.52226554836, staking: 56394.52226554836 },
    change_1h: 0.07103486929193537,
    change_1d: 0.8760384420787091,
    change_7d: 2.7794026747382645,
    staking: 56394.52226554836,
    fdv: 2522022,
    mcap: 586535,
  },
  {
    id: '1574',
    name: 'Viva',
    address: 'vite:tti_a23c2f75791efafe5fada99e',
    symbol: 'VIVA',
    url: 'https://app.vivafi.org',
    description: 'Stake in Viva pools to earn rewards',
    chain: 'Vite',
    logo: 'https://icons.llama.fi/viva.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Vite'],
    oracles: [],
    forkedFrom: [],
    module: 'viva/index.js',
    twitter: 'VivaStakingPool',
    language: 'Solidity++',
    listedAt: 1648005896,
    slug: 'viva',
    tvl: 739281.73993348,
    chainTvls: { Vite: 739281.73993348 },
    change_1h: 0,
    change_1d: -5.015650366661134,
    change_7d: null,
  },
  {
    id: '477',
    name: 'SakePerp',
    address: '0x066798d9ef0833ccc719076dab77199ecbd178b0',
    symbol: 'SAKE',
    url: 'https://www.sakeperp.fi',
    description:
      'SAKE is a transaction-driven decentralized perpetual contract and spot trading protocol. SakePerp.fi is the first on-chain perpetual contract platform with a dynamic AMM market maker mechanism that supports single asset collateralization for liquidity providers.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/sakeperp.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'sake-token',
    cmcId: '6997',
    category: 'Dexes',
    chains: ['Ethereum', 'Binance'],
    module: 'sakeperp/index.js',
    twitter: 'Sake_Perp_Swap',
    audit_links: [
      'https://sakeperp.fi/audit-report/Smart-Contract-Security-Audit-Report-SakePerp-Protocol-EN.pdf',
      'https://sakeswap.finance/SAKEen.pdf',
    ],
    slug: 'sakeperp',
    tvl: 738368.8091558953,
    chainTvls: {
      Binance: 51676.94153507282,
      Ethereum: 686691.8676208224,
      'Binance-staking': 33422.039249749,
      'Ethereum-staking': 84304.1164072974,
      staking: 117726.15565704642,
    },
    change_1h: -0.00002773874874151261,
    change_1d: 2.9998907084777215,
    change_7d: 11.23527215722595,
    staking: 117726.15565704642,
    fdv: 1208341,
    mcap: 685014,
  },
  {
    id: '1493',
    name: 'GoblinGold',
    address: null,
    symbol: '-',
    url: 'https://app.goblin.gold',
    description:
      'Goblin Gold DAO is an automated crypto growth engine that leverages customized risk strategies to generate DeFi yield. The protocol is designed to invest in several products while tokenizing your positions, allowing composability with other features.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/goblingold.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Solana'],
    oracles: [],
    forkedFrom: [],
    module: 'goblingold.js',
    twitter: 'goblingold_fi',
    listedAt: 1646175619,
    openSource: false,
    slug: 'goblingold',
    tvl: 726228,
    chainTvls: { Solana: 726228 },
    change_1h: 0.08448006174073441,
    change_1d: 0.3581911347694273,
    change_7d: 200.67444200171406,
  },
  {
    id: '840',
    name: 'BunnyPark',
    address: 'bsc:0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1',
    symbol: 'BP',
    url: 'https://www.bunnypark.com/',
    description:
      'BunnyPark is a DeFi + NFT project deployed on BSC focusing on NFT SaaSization and product standardization that allows the output of NFT development standards, diverse gameplay, product rules and application values into a series of standard smart contracts.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bunnypark.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bunnypark',
    cmcId: '10904',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'bunnypark/index.js',
    twitter: 'BunnyPark_BSC',
    audit_links: [
      'https://static.bunnypark.com/image/BunnyPark/pdf/SlowMistAuditReportBunnyPark-en.pdf',
      'https://www.certik.org/projects/bunnypark',
      'https://static.bunnypark.com/image/BunnyPark/pdf/PeckShield-Audit-Report-ERC20-BP-v1.0.pdf',
    ],
    listedAt: 1636950993,
    slug: 'bunnypark',
    tvl: 723249.7236242568,
    chainTvls: { Binance: 723249.7236242568, 'Binance-staking': 299750.2517324927, staking: 299750.2517324927 },
    change_1h: 2.318194716782827,
    change_1d: 3.8476859227623663,
    change_7d: -7.54246361764632,
    staking: 299750.2517324927,
    mcap: 0,
  },
  {
    id: '1298',
    name: 'Axion',
    address: 'polygon:0x839f1a22a59eaaf26c85958712ab32f80fea23d9',
    symbol: 'AXN',
    url: 'https://axion.network',
    description:
      "The world's best staking ecosystem is here. 8% fixed-rate APR. Daily bitcoin divs. Curated token launchpad. Community voting. NFT stakes, and more! This is $AXN",
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/axion.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'axion',
    cmcId: '7676',
    category: 'Staking',
    chains: ['Polygon'],
    module: 'axion/index.js',
    twitter: 'axion_network',
    audit_links: ['https://axion.network/news/updates/halborn-audit-results', 'https://solidity.finance/audits/Axion'],
    listedAt: 1642725126,
    slug: 'axion',
    tvl: 722384.5127271,
    chainTvls: { Polygon: 722384.5127271, 'Polygon-staking': 10263150.449386561, staking: 10263150.449386561 },
    change_1h: 0,
    change_1d: 1.8625094445470154,
    change_7d: 11.296311592866218,
    staking: 10263150.449386561,
    mcap: 43431003,
  },
  {
    id: '257',
    name: 'XDeFi',
    address: '0x000000000000d0151e748d25b766e77efe2a6c83',
    symbol: 'XDEX',
    url: 'https://xdefi.com/',
    description:
      'xDeFi is a well-established DeFi protocol stack. Many best practices are introduced in xDeFi ecosystem, including xDEX, xHalfLife, xOption, xSTA and many other DeFi building blocks which are essential for a well-ordered market.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/xdefi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'xdefi-governance-token',
    cmcId: '9087',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'xdefi/index.js',
    twitter: 'xdefilab',
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=7b91209f526407299aee5f99688b62fb2c337a4316a6b305719a9e3cd5442348',
      'https://www.certik.org/projects/xdefi',
    ],
    slug: 'xdefi',
    tvl: 719745.8292276941,
    chainTvls: { Ethereum: 719745.8292276941 },
    change_1h: -0.09975492121219531,
    change_1d: -1.8196380200150486,
    change_7d: 30.67789490778557,
    fdv: 1167474,
    mcap: 0,
  },
  {
    id: '235',
    name: 'PEAKDEFI',
    address: '0x630d98424efe0ea27fb1b3ab7741907dffeaad78',
    symbol: 'PEAK',
    url: 'https://www.peakdefi.com/',
    description: 'A decentralized asset management fund created to grow your wealth',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/peakdefi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'marketpeak',
    cmcId: '5354',
    category: 'Services',
    chains: ['Ethereum', 'Binance'],
    module: 'peakdefi/index.js',
    twitter: 'PEAKDEFI',
    audit_links: ['https://certificate.quantstamp.com/full/peakdefi'],
    slug: 'peakdefi',
    tvl: 710670.2291154705,
    chainTvls: {
      Ethereum: 710670.2291154705,
      'Binance-staking': 17884518.482736956,
      'Ethereum-staking': 49159985.97531622,
      staking: 67044504.45805317,
    },
    change_1h: 0.8853656985465079,
    change_1d: 1.8000663631654987,
    change_7d: 10.000210344228861,
    staking: 67044504.45805317,
    mcap: 76749535,
  },
  {
    id: '289',
    name: 'YetiSwap',
    address: 'avax:0x488F73cddDA1DE3664775fFd91623637383D6404',
    symbol: 'YTS',
    url: 'https://www.yetiswap.app/',
    description:
      'A decentralized exchange for Avalanche and Ethereum assets with fast settlement, low transaction fees.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/yetiswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'yetiswap',
    cmcId: '10657',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'yetiswap/index.js',
    twitter: 'YetiSwap',
    forkedFrom: ['Uniswap'],
    slug: 'yetiswap',
    tvl: 700961.8668326514,
    chainTvls: { Avalanche: 700961.8668326514 },
    change_1h: 0,
    change_1d: 3.3708299001870614,
    change_7d: -3.028144654131154,
    mcap: 650874,
  },
  {
    id: '619',
    name: 'Vee Finance',
    address: 'avax:0x3709e8615e02c15b096f8a9b460ccb8ca8194e86',
    symbol: 'VEE',
    url: 'https://vee.finance/home',
    description: 'Vee Finance is a DeFi lending platform for traditional financed and crypto users alike.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/vee-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'vee-finance',
    cmcId: '11961',
    category: 'Lending',
    chains: ['Heco', 'Avalanche'],
    module: 'vee-finance/index.js',
    twitter: 'VeeFinance',
    audit_links: ['https://vee.finance/home#contract'],
    forkedFrom: ['Compound'],
    oracles: ['Chainlink'],
    slug: 'vee-finance',
    tvl: 691182.7401058299,
    chainTvls: { Heco: 367078.611001945, Avalanche: 324104.1291038849 },
    change_1h: -0.18691577738466947,
    change_1d: -57.10757635519706,
    change_7d: -57.093570986923396,
    fdv: 8585809,
    mcap: 0,
  },
  {
    id: '429',
    name: 'BambooDeFi',
    address: '0xf56842Af3B56Fd72d17cB103f92d027bBa912e89 ',
    symbol: 'BAMBOO',
    url: 'https://www.bamboodefi.com',
    description:
      'A new generation DeFi that brings together the best of protocols like Uniswap v2, SushiSwap, YAM ,Compound with security improvements & new protocols like BBYP.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bamboodefi.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bamboo-defi',
    cmcId: '8389',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    module: 'bamboodefi/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'FiBamboo',
    audit_links: [
      'https://www.bamboodefi.com/assets/Bamboo%20DeFi%20-%20Security%20Audit%20Report.pdf',
      'https://www.bamboodefi.com/assets/bridge_audit.pdf',
    ],
    slug: 'bamboodefi',
    tvl: 689566.62368688,
    chainTvls: { Binance: 452711.3979218879, Ethereum: 236855.22576499206 },
    change_1h: 0.7081639066319383,
    change_1d: 2.784781080266214,
    change_7d: 10.690523778130753,
    mcap: 0,
  },
  {
    id: '1086',
    name: 'Zencha Finance',
    address: '0x7445Efc8A6F1279434133408BEdeb6Eb4d1537Ab',
    symbol: 'ZENC',
    url: 'https://www.zencha.finance/#/',
    description: 'The First StableSwap Exchange & Liquidity Pool  Brewing on BobaNetwork',
    chain: 'Boba',
    logo: 'https://icons.llama.fi/zencha-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Boba'],
    module: 'zencha/index.js',
    twitter: 'ZenchaFinance',
    listedAt: 1640300190,
    slug: 'zencha-finance',
    tvl: 687196.8074093449,
    chainTvls: { Boba: 687196.8074093449 },
    change_1h: -0.20171890901772827,
    change_1d: 0.12009340860697648,
    change_7d: -2.5600872725563164,
  },
  {
    id: '1262',
    name: 'Saros',
    address: 'solana:SSwapUtytfBdBn1b9NUGG6foMVPtcWgpRU32HToDUZr',
    symbol: 'SAROS',
    url: 'https://saros.finance',
    description:
      'Saros Finance is the ultimate DeFi platform native to Solana, with trading, staking, and yield farming services. Built by Coin98 Labs, Saros Finance aims to adopt millions of users to DeFi.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/saros.svg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Solana'],
    module: 'saros/index.js',
    twitter: 'Saros_Finance',
    audit_links: ['https://app.inspex.co/library/saros-finance#?scope=saros-finance'],
    listedAt: 1642193205,
    openSource: false,
    slug: 'saros',
    tvl: 672707.9983461255,
    chainTvls: { Solana: 672707.9983461255 },
    change_1h: 0.6165599528216603,
    change_1d: -1.7443475068539698,
    change_7d: -50.0702157624798,
  },
  {
    id: '869',
    name: 'Pop Town',
    address: '0x7fc3ec3574d408f3b59cd88709bacb42575ebf2b',
    symbol: 'POP!',
    url: 'https://new.pop.town',
    description:
      'A platform that allows two projects to certify their partnership and connects single-sided Liquidity Pool funding on Uniswap by matching providers.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/pop-town.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'pop',
    cmcId: '9520',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'poptown/index.js',
    twitter: 'PoPt0wn',
    listedAt: 1637371449,
    slug: 'pop-town',
    tvl: 663331.7316212534,
    chainTvls: { Ethereum: 663331.7316212534, 'Ethereum-staking': 96248.52889406697, staking: 96248.52889406697 },
    change_1h: 0.22798684240427747,
    change_1d: -0.8255060648771178,
    change_7d: 0.01594328464939565,
    staking: 96248.52889406697,
    fdv: 8618998,
    mcap: 0,
  },
  {
    id: '943',
    name: 'Shinobi',
    address: null,
    symbol: '-',
    url: 'https://shinobi.ubiq.ninja/#/swap',
    description: 'Shinobi is a protocol for creating liquidity and trading ERC-20 tokens on Ubiq.',
    chain: 'Ubiq',
    logo: 'https://icons.llama.fi/shinobi.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ubiq'],
    module: 'shinobi/index.js',
    twitter: 'ubiqsmart',
    forkedFrom: ['Uniswap'],
    listedAt: 1638297195,
    slug: 'shinobi',
    tvl: 661546.7820229272,
    chainTvls: { Ubiq: 661546.7820229272 },
    change_1h: -2.5578147576505046,
    change_1d: 8.6224207367692,
    change_7d: 2.665618036699783,
  },
  {
    id: '1201',
    name: 'Zunami Protocol',
    address: null,
    symbol: '-',
    url: 'https://www.zunami.io',
    description:
      "Zunami Protocol is the DAO that works with stablecoins and solves the main issues of current yield-farming protocols by streamlining interaction with DeFi, making it easier and cheaper while increasing profitability by differentiating and rebalancing users' funds",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/zunami-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'zunami/index.js',
    twitter: 'ZunamiProtocol',
    audit_links: ['https://github.com/ZunamiLab/ZunamiProtocol/tree/main/audit'],
    listedAt: 1641421911,
    slug: 'zunami-protocol',
    tvl: 638429.4896212395,
    chainTvls: { Ethereum: 638429.4896212395 },
    change_1h: 0.048054592503362414,
    change_1d: 23.388853068726206,
    change_7d: 90.96299491519432,
  },
  {
    id: '430',
    name: 'Acumen',
    address: 'solana:-',
    symbol: 'ACM',
    url: 'https://acumen.network',
    description:
      'The Acumen protocol is an autonomous, non-custodial interest rate protocol native to the Solana blockchain that uses algorithmically set interest rates to enable open financial applications.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/acumen.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Solana'],
    module: 'acumen/index.js',
    twitter: 'acumenofficial',
    oracles: ['Pyth'],
    openSource: false,
    slug: 'acumen',
    tvl: 636159.2315193465,
    chainTvls: { Solana: 636159.2315193465 },
    change_1h: -0.12259199457417935,
    change_1d: 0.17673140252220776,
    change_7d: -19.324742042058773,
  },
  {
    id: '1359',
    name: 'CapitalDEX',
    address: '0xf56b164efd3cfc02ba739b719b6526a6fa1ca32a',
    symbol: 'CGT',
    url: 'https://capitaldex.exchange/#/swap',
    description: 'The #1 AMM and yield farm on SKALE',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/capitaldex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'curio-governance',
    cmcId: '8131',
    category: 'Dexes',
    chains: ['Ethereum', 'Curio'],
    module: 'capitaldex/index.js',
    twitter: 'Capitaldex_',
    forkedFrom: ['Uniswap'],
    listedAt: 1643937401,
    slug: 'capitaldex',
    tvl: 625354.6488162453,
    chainTvls: { Ethereum: 394734.33248671895, Curio: 230620.31632952628 },
    change_1h: 0.055403642342668036,
    change_1d: 0.3543958740893629,
    change_7d: 52.570674589066215,
    fdv: 2856446,
    mcap: 707047,
  },
  {
    id: '1464',
    name: 'Tropykus Finance',
    address: null,
    symbol: '-',
    url: 'https://app.tropykus.com',
    description: 'Tropykus is a DeFi for Bitcoin lending and borrowing platform focused on Latanamerica.',
    chain: 'RSK',
    logo: 'https://icons.llama.fi/tropykus-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['RSK'],
    oracles: ['Money On Chain'],
    forkedFrom: ['Compound'],
    module: 'tropykus/index.js',
    twitter: 'tropykus',
    audit_links: [
      'https://firebasestorage.googleapis.com/v0/b/tropycofinance.appspot.com/o/Tropykus%20-%20Smart%20Contract%20Audit%20v210714.pdf?alt=media&token=f4f8e0d5-d577-45a1-85be-4e2d10cc210f',
      'https://firebasestorage.googleapis.com/v0/b/tropycofinance.appspot.com/o/Tropykus%20-%20Smart%20Contract%20Audit%20v211214.pdf?alt=media&token=9a1df569-bce4-4be8-9e3b-bcdd57061b65',
    ],
    listedAt: 1645678297,
    slug: 'tropykus-finance',
    tvl: 624437.1076833671,
    chainTvls: { RSK: 624437.1076833671 },
    change_1h: -0.03252995971190842,
    change_1d: -0.12484709555059226,
    change_7d: 8.130121339953462,
  },
  {
    id: '838',
    name: 'Component',
    address: '0x9f20Ed5f919DC1C1695042542C13aDCFc100dcab',
    symbol: 'CMP',
    url: 'https://component.finance/',
    description:
      'Component aims to provide flexible pool settings suitable for any token project, freedom of pools creation, and fair community protocol governance token distribution.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/component.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'component',
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum', 'Binance', 'xDai'],
    module: 'component/index.js',
    twitter: 'componentx',
    listedAt: 1636875507,
    slug: 'component',
    tvl: 618769.1611672828,
    chainTvls: {
      Binance: 163661.12624915,
      Ethereum: 393591.93686895963,
      xDai: 61516.09804917319,
      'Ethereum-staking': 27043.323616935515,
      staking: 27043.323616935515,
    },
    change_1h: 0.24158116726107437,
    change_1d: 4.298835705705841,
    change_7d: 11.025428695212753,
    staking: 27043.323616935515,
    fdv: 82986,
    mcap: 77000,
  },
  {
    id: '793',
    name: 'Cashio',
    address: 'solana:CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT',
    symbol: 'CASH',
    url: 'https://cashio.app',
    description: 'Decentralized money on Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/cashio.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'cashio-dollar',
    cmcId: null,
    category: 'Payments',
    chains: ['Solana'],
    module: 'cashio/index.js',
    twitter: 'cashioapp',
    listedAt: 1636191361,
    slug: 'cashio',
    tvl: 615625.9292168821,
    chainTvls: { Solana: 615625.9292168821 },
    change_1h: 0.4575951027473195,
    change_1d: 3.0770903020884504,
    change_7d: -97.87723357974708,
    fdv: 2019.33,
    mcap: 0,
  },
  {
    id: '973',
    name: 'XEUS',
    address: 'bsc:0x4e141769366634d9c4e498257fa7ec204d22b634',
    symbol: 'XEUS',
    url: 'https://xeus.finance/#/stake',
    description:
      'Xeus Finance is a decentralized finance reserve currency protocol built on Binance Smart Chain (BSC). The protocol is powered by the $XEUS token, which is backed by a basket of assets like BUSD, LPs, etc.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/xeus.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'xeus',
    cmcId: '13976',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'xeus/index.js',
    twitter: 'xeus_finance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638595802,
    slug: 'xeus',
    tvl: 613862.4715436222,
    chainTvls: { Binance: 613862.4715436222, 'Binance-staking': 119501.42468646672, staking: 119501.42468646672 },
    change_1h: -0.3467450328928976,
    change_1d: -0.026011053300393883,
    change_7d: -0.996100822022342,
    staking: 119501.42468646672,
    mcap: 5184.13,
  },
  {
    id: '473',
    name: 'Baguette',
    address: 'avax:0xa1144a6a1304bd9cbb16c800f7a867508726566e',
    symbol: 'BAG',
    url: 'https://baguette.exchange',
    description:
      'A community-backed decentralized exchange for Avalanche assets, but with the perfect smell of freshly baked bread.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/baguette.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'baguette',
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'baguette/index.js',
    twitter: 'Baguette_avax',
    forkedFrom: ['Uniswap'],
    slug: 'baguette',
    tvl: 610883.7064632914,
    chainTvls: { Avalanche: 610883.7064632914 },
    change_1h: 0.36981690457483296,
    change_1d: 4.1903839109102705,
    change_7d: 12.594009670075224,
    mcap: 166614,
  },
  {
    id: '925',
    name: 'Oddz',
    address: '0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6',
    symbol: 'ODDZ',
    url: 'https://www.oddz.fi/',
    description: 'Multi-chain derivatives trading platform',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/oddz.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'oddz',
    cmcId: '8717',
    category: 'Options',
    chains: ['Avalanche', 'Binance'],
    module: 'oddz/index.js',
    twitter: 'oddz_finance',
    audit_links: ['https://github.com/oddz-finance/audits'],
    oracles: ['Chainlink'],
    listedAt: 1638117141,
    slug: 'oddz',
    tvl: 610050.5539992793,
    chainTvls: {
      pool2: 679519.8197292278,
      'Binance-pool2': 339759.9098646139,
      Binance: 100351.13116861437,
      'Binance-staking': 374359.1528709259,
      Avalanche: 509699.4228306649,
      'Avalanche-staking': 752991.6816652113,
      'Avalanche-pool2': 339759.9098646139,
      staking: 1127350.8345361373,
    },
    change_1h: -0.4294127442624074,
    change_1d: -0.348278221238445,
    change_7d: -9.493546648077938,
    staking: 1127350.8345361373,
    pool2: 679519.8197292278,
    fdv: 14160581,
    mcap: 8266339,
  },
  {
    id: '1364',
    name: 'Narwhalswap',
    address: 'bsc:0xa1303e6199b319a891b79685f0537d289af1fc83',
    symbol: 'NAR',
    url: 'https://narwhalswap.org/#/swap',
    description:
      'Swap on Narwhalwap are a simple way to trade one BEP-20 token for another via automated liquidity pools. The liquidity provided to the exchange comes from Liquidity Providers who stake their tokens in Pools.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/narwhalswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'narwhalswap/index.js',
    twitter: 'narwhalswap',
    forkedFrom: ['Uniswap'],
    listedAt: 1644153456,
    slug: 'narwhalswap',
    tvl: 608712.8244897978,
    chainTvls: { Binance: 608712.8244897978 },
    change_1h: -0.08569333465553086,
    change_1d: 0.4880409431053039,
    change_7d: 3.945219632328275,
  },
  {
    id: '941',
    name: 'UniPower',
    address: '0xF2f9A7e93f845b3ce154EfbeB64fB9346FCCE509',
    symbol: 'POWER',
    url: 'https://unipower.network',
    description: 'UniPower is the first experimental PoL (Proof of Liquidity) token - powered by the uniswap dex',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/unipower.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'unipower',
    cmcId: '5660',
    category: 'Staking',
    chains: ['Ethereum', 'Polygon'],
    module: 'unipower/index.js',
    twitter: 'UniPowerToken',
    listedAt: 1638212710,
    slug: 'unipower',
    tvl: 601845.2944988221,
    chainTvls: {
      pool2: 357661.1060314415,
      'Polygon-pool2': 649.5198809047072,
      'Ethereum-pool2': 357011.5861505368,
      Ethereum: 601845.2944988221,
      'Ethereum-staking': 57286.504025230155,
      Polygon: 0,
      'Polygon-staking': 1900.0558387005537,
      staking: 59186.559863930706,
    },
    change_1h: 0.6233285570915683,
    change_1d: 4.025344316270221,
    change_7d: 15.352847485693587,
    staking: 59186.559863930706,
    pool2: 357661.1060314415,
    mcap: 310561,
  },
  {
    id: '1279',
    name: 'Tulip',
    address: 'oasis:0x9e832CaE5d19e7ff2f0D62881D1E33bb16Ac9bdc',
    symbol: 'TULIP',
    url: 'https://tulip.money/',
    description: 'The first algorithmic stablecoin on Emerald ParaTime, pegged to the price of 1 ROSE via seigniorage.',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/tulip.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Oasis'],
    module: 'tulip/index.js',
    twitter: 'MoneyTulip',
    oracles: ['TWAP'],
    forkedFrom: ['Tomb Finance'],
    listedAt: 1642456433,
    slug: 'tulip',
    tvl: 596186.8352261932,
    chainTvls: { Oasis: 596186.8352261932, 'Oasis-staking': 0, staking: 0 },
    change_1h: -0.19600295240481103,
    change_1d: 5.885581446034507,
    change_7d: -37.56590801973893,
    staking: 0,
  },
  {
    id: '228',
    name: 'FutureSwap',
    address: '0x0e192d382a36de7011f795acc4391cd302003606',
    symbol: 'FST',
    url: 'https://www.futureswap.com/',
    description: 'Futureswap is a decentralized perpetuals exchange governed by the community.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/futureswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'futureswap',
    cmcId: '8961',
    category: 'Derivatives',
    chains: ['Ethereum'],
    module: 'futureswap/index.js',
    twitter: 'futureswapx',
    audit_links: ['https://docs.futureswap.com/protocol/audits'],
    oracles: ['Chainlink'],
    slug: 'futureswap',
    tvl: 596183.8548061135,
    chainTvls: { Ethereum: 596183.8548061135 },
    change_1h: 0.22848583878251816,
    change_1d: 1.824067946916557,
    change_7d: 6.58344247291393,
    fdv: 233544885,
    mcap: 43994352,
  },
  {
    id: '676',
    name: 'Phoenix Finance',
    address: 'polygon:0x9C6BfEdc14b5C23E3900889436Edca7805170f01',
    symbol: 'PHX',
    url: 'https://www.phx.finance',
    description: 'Phoenix Finance. The decentralized derivatives marketplace.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/phoenix-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'phoenix-token',
    cmcId: null,
    category: 'Options',
    chains: ['Polygon', 'Binance', 'Wanchain'],
    module: 'phoenix/index.js',
    twitter: 'Phoenix__PHX',
    audit_links: ['https://github.com/Phoenix-Finance/Pdfs'],
    oracles: ['Chainlink'],
    listedAt: 1634418530,
    slug: 'phoenix-finance',
    tvl: 594572.35689419,
    chainTvls: { Binance: 191224.2241478, Wanchain: 69047.10054393999, Polygon: 334301.03220245003 },
    change_1h: -0.0656447055157372,
    change_1d: 1.2129654175153064,
    change_7d: 5.536492508600105,
    fdv: 1171615,
    mcap: 345812,
  },
  {
    id: '1176',
    name: 'Tropical Finance',
    address: 'smartbch:0x24d8d5Cbc14FA6A740c3375733f0287188F8dF3b',
    symbol: 'DAIQUIRI',
    url: 'https://app.tropical.finance',
    description:
      'Tropical Finance is a state of the art DEX and yield farming application on SmartBCH Chain with unique features which offers its investors brand new ways to maximize APRs.',
    chain: 'smartBCH',
    logo: 'https://icons.llama.fi/tropical-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['smartBCH'],
    module: 'tropicalfinance/index.js',
    twitter: 'tropicalfi_',
    audit_links: ['https://tropical.finance/audit.pdf'],
    forkedFrom: ['Uniswap'],
    listedAt: 1641171227,
    slug: 'tropical-finance',
    tvl: 593917.457905937,
    chainTvls: {
      'smartBCH-masterchef': 180685.25,
      'smartBCH-staking': 53230.38869333045,
      masterchef: 180685.25,
      smartBCH: 593917.457905937,
      staking: 53230.38869333045,
    },
    change_1h: 2.842170943040401e-14,
    change_1d: 17.811681645242857,
    change_7d: 18.93157794293863,
    staking: 53230.38869333045,
  },
  {
    id: '1009',
    name: 'CheeseDAO',
    address: 'harmony:0xBbD83eF0c9D347C85e60F1b5D2c58796dBE1bA0d',
    symbol: 'CHEEZ',
    url: 'https://www.cheesedao.xyz/#/dashboard',
    description:
      "CheeseDAO attempts to stand on the shoulders' of giants, whose contributions to the ecosystem put all the pieces in place necessary to launch a decentralized gamified risk protocol owned and governed by the players",
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/cheesedao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Harmony'],
    module: 'cheesedao/index.js',
    twitter: 'CheezDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639328374,
    slug: 'cheesedao',
    tvl: 588605.8536241149,
    chainTvls: { 'Harmony-staking': 699990.2582018297, Harmony: 588605.8536241149, staking: 699990.2582018297 },
    change_1h: -0.11373431756834407,
    change_1d: 1.396954062477647,
    change_7d: -9.324629873329542,
    staking: 699990.2582018297,
  },
  {
    id: '574',
    name: 'Fuzz Finance',
    address: 'harmony:0x984b969a8e82f5ce1121ceb03f96ff5bb3f71fee',
    symbol: 'FUZZ',
    url: 'https://fuzz.fi',
    description:
      'Fuzz.Fi is a swap platform that gives incentives to users who stake to the Fuzz.Fi validator through the use of our FUZZ token.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/fuzz-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'fuzz-finance',
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'fuzzfinance',
    twitter: 'FuzzFinance',
    forkedFrom: ['Uniswap'],
    slug: 'fuzz-finance',
    tvl: 587942.1508543713,
    chainTvls: { 'Harmony-staking': 645138.8984926306, Harmony: 587942.1508543713, staking: 645138.8984926306 },
    change_1h: 2.4079394328713164,
    change_1d: -1.5074685513450419,
    change_7d: -69.56422686218761,
    staking: 645138.8984926306,
    fdv: 5734541,
    mcap: 0,
  },
  {
    id: '268',
    name: 'UniTrade',
    address: '0x6f87d756daf0503d08eb8993686c7fc01dc44fb1',
    symbol: 'TRADE',
    url: 'https://unitrade.app/',
    description: 'Crosschain tool that allows users to place limit and market orders on DEXes of multiple chains',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/unitrade.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'unitrade',
    cmcId: '6195',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'unitrade/index.js',
    twitter: 'UniTradeApp',
    audit_links: [
      'https://github.com/solidified-platform/audits/blob/master/Audit%20Report%20-%20%20Unitrade%20%5B06.10.2020%5D.pdf',
    ],
    slug: 'unitrade',
    tvl: 584082.5320564092,
    chainTvls: { Ethereum: 584082.5320564092 },
    change_1h: 0.10367651764158836,
    change_1d: -1.9582765695575546,
    change_7d: 10.60210739003864,
    fdv: 3688935,
    mcap: 2640009,
  },
  {
    id: '1478',
    name: 'GTON Capital',
    address: 'fantom:0xc1be9a4d5d45beeacae296a7bd5fadbfc14602c4',
    symbol: 'GTON',
    url: 'https://gton.capital',
    description:
      'GTON Capital Ecosystem unites several mutually reinforcing DeFi 2.0 protocols with the aim of conducting algorithmic governance of liquidity and volume',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/gton-capital.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gton-capital',
    cmcId: '10006',
    category: 'Reserve Currency',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'gton.js',
    twitter: 'GtonCapital',
    audit_links: ['https://www.certik.com/projects/graviton'],
    listedAt: 1645901181,
    slug: 'gton-capital',
    tvl: 574714.1084479343,
    chainTvls: { 'Fantom-staking': 2483679.019434314, staking: 2483679.019434314, Fantom: 574714.1084479343 },
    change_1h: 0.06703219123600945,
    change_1d: 373.9957892043206,
    change_7d: 448.70299118114553,
    staking: 2483679.019434314,
    mcap: 6502848,
  },
  {
    id: '1445',
    name: 'Aelin',
    address: '0xa9c125bf4c8bb26f299c00969532b66732b1f758',
    symbol: 'Aelin',
    url: 'https://aelin.xyz',
    description: 'Aelin is a fundraising protocol built on Ethereum and launched on Optimism.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/aelin.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'aelin',
    cmcId: '16991',
    category: 'Services',
    chains: ['Optimism', 'Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'aelin/index.js',
    twitter: 'aelinprotocol',
    listedAt: 1645521923,
    slug: 'aelin',
    tvl: 571899.0028517761,
    chainTvls: {
      Optimism: 571268.7299399528,
      'Optimism-pool2': 3746244.724523941,
      'Optimism-staking': 9999872.685787046,
      pool2: 5980115.457098889,
      'Ethereum-pool2': 2233870.732574948,
      Ethereum: 630.272911823254,
      staking: 9999872.685787046,
    },
    change_1h: -0.3160970367848961,
    change_1d: 0.12435674153721266,
    change_7d: -1.5749269726245956,
    staking: 9999872.685787046,
    pool2: 5980115.457098889,
    mcap: 0,
  },
  {
    id: '1063',
    name: 'Embr Finance',
    address: 'avax:0xD81D45E7635400dDD9c028839e9a9eF479006B28',
    symbol: 'EMBR',
    url: 'https://embr.finance',
    description:
      "We leverage the 'Best in Breed' DeFi protocols to offer decentralized investment strategies. Built on Balancer V2, Embr is the first next-generation AMM protocol on Avalanche.",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/embr-finance.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'embr/index.js',
    twitter: 'EmbrFinance',
    forkedFrom: ['Balancer'],
    listedAt: 1640080742,
    slug: 'embr-finance',
    tvl: 569495.791004101,
    chainTvls: { Avalanche: 569495.791004101 },
    change_1h: 0.31537501553049196,
    change_1d: 0.3669063561044794,
    change_7d: -6.509873773289399,
  },
  {
    id: '284',
    name: 'Serum Swap',
    address: null,
    symbol: 'SRM',
    url: 'https://swap.projectserum.com/',
    description: 'AMM',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/serum-swap.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '1204',
    category: 'Dexes',
    chains: ['Solana'],
    module: 'serum-swap/index.js',
    twitter: 'ProjectSerum',
    openSource: false,
    slug: 'serum-swap',
    tvl: 569012.2004137143,
    chainTvls: { Solana: 569012.2004137143 },
    change_1h: -0.4360360019832683,
    change_1d: 2.729566895726279,
    change_7d: 14.551839517245696,
  },
  {
    id: '688',
    name: 'HaloDAO',
    address: '0xe94b97b6b43639e238c851a7e693f50033efd75c',
    symbol: 'RNBW',
    url: 'https://www.halodao.com/',
    description:
      'HaloDAO connects traditional and decentralised finance by providing the decentralised infrastructure for next generation payments and settlement.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/halodao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'rainbow-token',
    cmcId: '10429',
    category: 'Yield',
    chains: ['Ethereum', 'Polygon'],
    module: 'halodao/index.js',
    twitter: 'HaloDAOFinance',
    audit_links: ['https://docs.halodao.com/contract-audits/overview'],
    listedAt: 1634698714,
    slug: 'halodao',
    tvl: 565893.5036171236,
    chainTvls: {
      pool2: 102140.551892505,
      'Ethereum-pool2': 102140.551892505,
      Ethereum: 420418.9159997507,
      'Ethereum-staking': 277115.67273751844,
      Polygon: 145474.58761737286,
      'Polygon-staking': 0,
      staking: 277115.67273751844,
    },
    change_1h: 0,
    change_1d: -2.0575315570308135,
    change_7d: -2.3085931330460454,
    staking: 277115.67273751844,
    pool2: 102140.551892505,
    fdv: 2721822,
    mcap: 735666,
  },
  {
    id: '396',
    name: 'Polydex',
    address: '-',
    symbol: 'PLX',
    url: 'https://www.polydex.fi',
    description: 'AMM And Dexes Aggregator',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polydex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '1444',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'polydex/index.js',
    twitter: 'polydexfi',
    forkedFrom: ['Uniswap'],
    audit_links: [
      'https://github.com/polydex-fi/polydex-swap-core/blob/5fdd6b85550235800cd980ccb8fab9bd5247a7af/PolydexSwap.pdf',
    ],
    slug: 'polydex',
    tvl: 560177.1220559559,
    chainTvls: { Polygon: 560177.1220559559 },
    change_1h: 0.603157387779234,
    change_1d: -1.6358934755432415,
    change_7d: 5.185429150102777,
  },
  {
    id: '675',
    name: 'Dark Matter',
    address: 'fantom:0x90e892fed501ae00596448aecf998c88816e5c0f',
    symbol: 'DMD',
    url: 'https://www.dmu.zone',
    description:
      'Dark Matter employs its native token, DMD, in all aspects of its ecosystem, including governance. At launch, you are able to stake, pool, and earn DMD. Additional use cases are in development, featuring NFTs and more.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/dark-matter.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'dark-matter-defi',
    cmcId: '12675',
    category: 'Farm',
    chains: ['Fantom'],
    module: 'darkmatter/index.js',
    twitter: 'darkmatterdefi',
    forkedFrom: ['Goose Finance'],
    listedAt: 1634342862,
    slug: 'dark-matter',
    tvl: 556325.4327778105,
    chainTvls: {
      pool2: 132316.77479492605,
      'Fantom-staking': 213022.83261410365,
      'Fantom-pool2': 132316.77479492605,
      staking: 213022.83261410365,
      Fantom: 556325.4327778105,
    },
    change_1h: 0.5352435634211616,
    change_1d: 1.1956569403635626,
    change_7d: 13.669183607549627,
    staking: 213022.83261410365,
    pool2: 132316.77479492605,
    fdv: 4974113,
    mcap: 0,
  },
  {
    id: '1568',
    name: 'Kaddex',
    address: null,
    symbol: '-',
    url: 'https://kaddex.com/',
    description: 'Kaddex the evolution of DeFi on Kadena.',
    chain: 'Kadena',
    logo: 'https://icons.llama.fi/kaddex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Kadena'],
    oracles: [],
    forkedFrom: [],
    module: 'kaddex/index.js',
    twitter: 'KaddeXofficial',
    listedAt: 1647877802,
    slug: 'kaddex',
    tvl: 555212.6038409818,
    chainTvls: { Kadena: 555212.6038409818 },
    change_1h: 0.6380191753666082,
    change_1d: 2.3610878163444937,
    change_7d: 17.62727280802497,
  },
  {
    id: '1055',
    name: 'FIAT DAO',
    address: '0xEd1480d12bE41d92F36f5f7bDd88212E381A3677',
    symbol: 'FDT',
    url: 'https://fiatdao.com/',
    description:
      'Fiat Protocol allows users to collateralize fixed income assets and mint FIAT with high loan-to-value ratios and low origination fees.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/fiat-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'fiat-dao-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'fiatdao/index.js',
    twitter: 'fiatdao',
    listedAt: 1639957923,
    slug: 'fiat-dao',
    tvl: 550549.2610417119,
    chainTvls: { Ethereum: 550549.2610417119, 'Ethereum-staking': 14146729.348501114, staking: 14146729.348501114 },
    change_1h: 0.18742076704481292,
    change_1d: 0.6985331183963837,
    change_7d: 8.234682670031646,
    staking: 14146729.348501114,
    fdv: 194031993,
    mcap: 17742306,
  },
  {
    id: '1516',
    name: 'RHEA DAO',
    address: 'klaytn:0x0758fB651282581F86316514E8F5021493E9eD83',
    symbol: 'RHEA',
    url: 'https://rheadao.finance',
    description: 'Next Stage of decentralized reserve currency protocol.',
    chain: 'Klaytn',
    logo: 'https://icons.llama.fi/rhea-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Klaytn'],
    oracles: [],
    forkedFrom: ['Olympus DAO'],
    module: 'rhea/index.js',
    twitter: 'RheaDao_fi',
    listedAt: 1646683868,
    slug: 'rhea-dao',
    tvl: 543962.69337228,
    chainTvls: { 'Klaytn-staking': 918044.5695349432, Klaytn: 543962.69337228, staking: 918044.5695349432 },
    change_1h: 0.06559736009019446,
    change_1d: 1.7313287276945033,
    change_7d: 14.812287575459578,
    staking: 918044.5695349432,
  },
  {
    id: '1581',
    name: 'HebeSwap',
    address: 'ethereumclassic:0x88d8C3Dc6B5324f34E8Cf229a93E197048671abD',
    symbol: 'HEBE',
    url: 'https://hebeswap.com',
    description:
      'Hebeswap is a protocol for automated token exchange on Ethereum Classic. It was launched on December 29, 2021. Hebeswap a simple smart contract interface for swapping ERC20 tokens. It has a formalized model for pooling liquidity reserves. It serves as an open-source frontend interface for traders and liquidity providers and is committed to providing free and decentralized asset exchange.',
    chain: 'EthereumClassic',
    logo: 'https://icons.llama.fi/hebeswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['EthereumClassic'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'hebeswap/index.js',
    twitter: 'BlockHebe',
    listedAt: 1648276449,
    slug: 'hebeswap',
    tvl: 543811.6277472937,
    chainTvls: { EthereumClassic: 543811.6277472937 },
    change_1h: 3.597432656129527,
    change_1d: 9.210384379489668,
    change_7d: null,
  },
  {
    id: '1358',
    name: 'Kassandra DAO',
    address: 'avax:0xf32398dae246c5f672b52a54e9b413dffcae1a44',
    symbol: 'KACY',
    url: 'https://kassandra.finance',
    description:
      'The $aHYPE Social Index reflects the performance of a portfolio selected from the most socially active cryptocurrencies in the past 30 days, using Heimdall Social Score data.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/kassandra-dao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'kassandra',
    cmcId: '17587',
    category: 'Indexes',
    chains: ['Avalanche'],
    module: 'kassandra/index.js',
    twitter: 'dao_kassandra',
    audit_links: ['https://www.certik.com/projects/kassandra-finance'],
    listedAt: 1643914440,
    slug: 'kassandra-dao',
    tvl: 541473.4624980005,
    chainTvls: { Avalanche: 541473.4624980005 },
    change_1h: 1.264803116838749,
    change_1d: 4.299731503734634,
    change_7d: 21.350866861691074,
    fdv: 8719407,
    mcap: 688455,
  },
  {
    id: '1093',
    name: 'SuperFarm',
    address: '0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
    symbol: 'SUPER',
    url: 'https://superfarm.com/',
    description: 'Cross-chain protocol allowing anyone to deploy DeFi and NFT farms with no code required.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/superfarm.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'superfarm',
    cmcId: '8290',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'superfarm/index.js',
    twitter: 'SuperFarmDAO',
    listedAt: 1640518619,
    slug: 'superfarm',
    tvl: 537308.2383564687,
    chainTvls: {
      Ethereum: 537308.2383564687,
      pool2: 19544150.23330285,
      'Ethereum-staking': 62757051.566701055,
      staking: 62757051.566701055,
      'Ethereum-pool2': 19544150.23330285,
    },
    change_1h: 0.07158334872980276,
    change_1d: 1.7240802876519865,
    change_7d: 8.162159958034891,
    staking: 62757051.566701055,
    pool2: 19544150.23330285,
    fdv: 814830311,
    mcap: 234127174,
  },
  {
    id: '168',
    name: 'Dev Protocol',
    address: '0x5caf454ba92e6f2c929df14667ee360ed9fd5b26',
    symbol: 'DEV',
    url: 'https://devprtcl.com/',
    description: 'Making open activities sustainable with staking\r\n\r\n',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/dev-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'dev-protocol',
    cmcId: '5990',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'dev.js',
    twitter: 'devprtcl',
    audit_links: ['https://github.com/dev-protocol/protocol/blob/main/docs/audit-report__quantstamp--sept-15-2020.pdf'],
    slug: 'dev-protocol',
    tvl: 529885.4107754648,
    chainTvls: { Ethereum: 529885.4107754648 },
    change_1h: 15.705467599886362,
    change_1d: 7.382550335570471,
    change_7d: 12.989763733732147,
    fdv: 10542014,
    mcap: 2341939,
  },
  {
    id: '1134',
    name: 'DuckyDeFi',
    address: 'cronos:0xFD71FC52D34eD1Cfc8363e5528285B12b6b942c2',
    symbol: 'DEGG',
    url: 'https://duckydefi.com/',
    description: 'The first fair launch DEX on Cronos Chain.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/duckydefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'duckydefi/index.js',
    twitter: 'DuckyDeFi',
    audit_links: ['https://rugdoc.io/project/duckydefi-exchange/'],
    listedAt: 1640733460,
    forkedFrom: ['Uniswap'],
    slug: 'duckydefi',
    tvl: 529872.2132941585,
    chainTvls: { Cronos: 529872.2132941585 },
    change_1h: -0.11767909761411488,
    change_1d: -5.039439492938229,
    change_7d: -5.475563582569762,
  },
  {
    id: '1239',
    name: 'DuneSwap',
    address: 'emerald:0xaC5487bFE2502eCc06e057912b6F4946471093b9',
    symbol: 'DUNE',
    url: 'https://www.duneswap.com/exchange/swap',
    description:
      'Duneswap is an Automated Market Maker/Decentralized Exchange native to Oasis Emerald ParaTime Network. DUNE is the native token to the platform which can be attained by farming/staking or trading for.',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/duneswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Oasis'],
    module: 'duneswap.js',
    twitter: 'DuneSwap',
    forkedFrom: ['Uniswap'],
    listedAt: 1641941263,
    slug: 'duneswap',
    tvl: 526393.6304567448,
    chainTvls: { Oasis: 526393.6304567448 },
    change_1h: -0.20529027270129063,
    change_1d: -4.549355740058331,
    change_7d: -6.31516910126993,
  },
  {
    id: '615',
    name: 'BNBMiner Finance',
    address: '-',
    symbol: '-',
    url: 'https://bnbminer.finance',
    description:
      'The object of the game is hiring more miners, sooner and more often than other players. This in turn earns you more BNB faster.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bnbminer-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Binance'],
    module: 'bnbminer/index.js',
    twitter: 'Da_Developer',
    slug: 'bnbminer-finance',
    tvl: 525322.7945181186,
    chainTvls: { Binance: 525322.7945181186 },
    change_1h: -0.08178919612215907,
    change_1d: 3.1242396960049916,
    change_7d: 12.838880302569208,
  },
  {
    id: '312',
    name: 'Stacker Ventures',
    address: '0xe0955f26515d22e347b17669993fcefcc73c3a0a',
    symbol: 'STACK',
    url: 'https://stacker.vc/',
    description:
      'Stacker Ventures is a community-run protocol for initiating and managing pooled capital on the blockchain.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/stacker-ventures.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'stacker-ventures',
    cmcId: '8629',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'stacker/index.js',
    twitter: 'StackerVentures',
    audit_links: [
      'https://docs.stacker.vc/docs-stacker-ventures/audits/active-yield-fund-audit-report',
      'https://docs.stacker.vc/docs-stacker-ventures/audits/smart-contract-audit-report',
    ],
    slug: 'stacker-ventures',
    tvl: 514455.0883079153,
    chainTvls: { Ethereum: 514455.0883079153 },
    change_1h: 0,
    change_1d: 2.9583592292058256,
    change_7d: 13.545085275888553,
    mcap: 905607,
  },
  {
    id: '537',
    name: 'ArbiNYAN',
    address: 'arbitrum:0xeD3fB761414DA74b74F33e5c5a1f78104b188DfC',
    symbol: 'NYAN',
    url: 'https://arbinyan.xyz',
    description: 'First fair launch token on Arbitrum',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/arbinyan.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'arbinyan',
    cmcId: '11854',
    category: 'Yield',
    chains: ['Arbitrum'],
    module: 'arbinyan/index.js',
    twitter: 'arbinyan',
    slug: 'arbinyan',
    tvl: 510586.3468749165,
    chainTvls: {
      pool2: 69961.52724222021,
      'Arbitrum-staking': 79989.8462382227,
      Arbitrum: 510586.3468749165,
      'Arbitrum-pool2': 69961.52724222021,
      staking: 79989.8462382227,
    },
    change_1h: 0.6233285570915541,
    change_1d: 4.025344316270193,
    change_7d: 15.281413929074432,
    staking: 79989.8462382227,
    pool2: 69961.52724222021,
    fdv: 964488,
    mcap: 0,
  },
  {
    id: '356',
    name: 'Dopple Finance',
    address: 'bsc:0x844fa82f1e54824655470970f7004dd90546bb28',
    symbol: 'DOP',
    url: 'https://dopple.finance/',
    description: 'Stablecoin DeFi Ecosystem on #BSC',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dopple-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'dopple-finance',
    cmcId: '9489',
    category: 'Algo-Stables',
    chains: ['Binance', 'Fantom', 'Harmony'],
    module: 'dopplefinance/index.js',
    twitter: 'dopplefi',
    audit_links: ['https://dopple.gitbook.io/dopple-finance/certik-audit'],
    slug: 'dopple-finance',
    tvl: 505883.4756224488,
    chainTvls: { Binance: 505727.7268390086, Harmony: 1.7964536739476145, Fantom: 153.95232976621767 },
    change_1h: 0.015991756978792182,
    change_1d: 0.10489619404438599,
    change_7d: -0.20803776358935977,
    mcap: 0,
  },
  {
    id: '699',
    name: 'Polly Finance',
    address: 'polygon:0x4C392822D4bE8494B798cEA17B43d48B2308109C',
    symbol: 'POLLY',
    url: 'https://www.pollyfinance.com/',
    description:
      'Polly Finance allows users to create their own "nests" (a token that represents a basket of underlying tokens) to manage diverse assets, purchase them easily, and automate their farming.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polly-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'polly',
    cmcId: '12618',
    category: 'Indexes',
    chains: ['Polygon'],
    module: 'pollyfinance/index.js',
    twitter: 'thebaoman',
    listedAt: 1634972259,
    slug: 'polly-finance',
    tvl: 505566.8447742761,
    chainTvls: { Polygon: 505566.8447742761, pool2: 286216.7893667451, 'Polygon-pool2': 286216.7893667451 },
    change_1h: 5.410941759444611,
    change_1d: 9.656543733589416,
    change_7d: 19.346476191961898,
    pool2: 286216.7893667451,
    fdv: 2422202,
    mcap: 0,
  },
  {
    id: '1347',
    name: 'YieldFields',
    address: 'bsc:0x04d50c032f16a25d1449ef04d893e95bcc54d747',
    symbol: 'FIELD',
    url: 'https://yieldfields.finance/',
    description: 'YieldFields is an Automated Market Maker (AMM) with the Exchange at the heart of YieldFields. ',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/yieldfields.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'yieldfields/index.js',
    twitter: 'yieldfields',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://rugdoc.io/project/yieldfields/'],
    listedAt: 1643834444,
    slug: 'yieldfields',
    tvl: 495704.0838066878,
    chainTvls: { Binance: 495704.0838066878 },
    change_1h: -0.007290905139356596,
    change_1d: 0.4346856305109412,
    change_7d: 0.506928797186859,
  },
  {
    id: '179',
    name: 'Varen',
    address: '0x72377f31e30a405282b522d588aebbea202b4f23',
    symbol: 'VRN',
    url: 'https://varen.finance',
    description: 'Varen is an Ethereum based multi-chain DeFi hub.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/varen.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'varen',
    cmcId: '11676',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'varen/index.js',
    twitter: 'varenfinance',
    audit_links: ['https://certificate.quantstamp.com/full/linkswap'],
    forkedFrom: ['Uniswap'],
    slug: 'varen',
    tvl: 488505.15053917054,
    chainTvls: {
      Ethereum: 488505.15053917054,
      pool2: 674533.299450025,
      'Ethereum-staking': 256425.9131430569,
      staking: 256425.9131430569,
      'Ethereum-pool2': 674533.299450025,
    },
    change_1h: 0.841837814400705,
    change_1d: -0.5335393427599513,
    change_7d: 12.50531524771101,
    staking: 256425.9131430569,
    pool2: 674533.299450025,
    fdv: 15427192,
    mcap: 8185372,
  },
  {
    id: '1192',
    name: 'PolkaEx',
    address: '0xe6f143a0e0a8f24f6294ce3432ea10fad0206920',
    symbol: 'PKEX',
    url: 'https://www.polkaex.io',
    description:
      'PolkaEx is a cross-chain Dex and Launchpad on Polkadot, using Astar Network to provide higher TPS and lower transaction fees.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/polkaex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'polkaex',
    cmcId: null,
    category: 'Dexes',
    chains: ['Astar', 'Shiden', 'Ethereum', 'Binance', 'Polygon'],
    module: 'polkaex/index.js',
    twitter: 'PolkaExOfficial',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://www.certik.com/projects/polkaex'],
    listedAt: 1641360374,
    slug: 'polkaex',
    tvl: 485765.28591206565,
    chainTvls: {
      'Astar-staking': 67293.525085762,
      'Shiden-staking': 118748.47954906437,
      Binance: 0,
      Ethereum: 13.619,
      Astar: 399107.06329493574,
      'Binance-staking': 2908.082121739126,
      Shiden: 86644.60361712993,
      Polygon: 0,
      'Polygon-staking': 648.5170156477366,
      staking: 189598.60377221322,
    },
    change_1h: -0.06438030300192565,
    change_1d: 5.54577274281101,
    change_7d: null,
    staking: 189598.60377221322,
    mcap: 181816,
  },
  {
    id: '663',
    name: 'Pods Finance',
    address: null,
    symbol: '-',
    url: 'https://www.pods.finance',
    description:
      'Pods is a decentralized non-custodial options protocol that allows users to create calls and/or puts and trade them in the Options AMM.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/pods-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Options',
    chains: ['Polygon', 'Ethereum', 'Arbitrum'],
    module: 'podsfinance/index.js',
    twitter: 'PodsFinance',
    audit_links: ['https://docs.pods.finance/security/audits'],
    oracles: ['Chainlink'],
    listedAt: 1634027456,
    slug: 'pods-finance',
    tvl: 480234.84021654614,
    chainTvls: { Ethereum: 138793.70289619113, Polygon: 325849.7446872106, Arbitrum: 15591.39263314441 },
    change_1h: -0.09010030941252012,
    change_1d: -2.940692936297367,
    change_7d: -9.168673497000682,
  },
  {
    id: '1424',
    name: 'Volta DAO',
    address: 'fantom:0x4c9993c7107495020c2ce9a13d11839f48ecd2e6',
    symbol: 'VOLTa',
    url: 'https://app.voltadao.finance/',
    description: 'Volta DAO is a decentralized reserve currency built on Fantom and AVAX',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/volta-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Fantom', 'Avalanche'],
    oracles: [],
    forkedFrom: ['Olympus DAO'],
    module: 'volta-dao/index.js',
    twitter: 'VoltaDAO',
    listedAt: 1645046863,
    slug: 'volta-dao',
    tvl: 467804.64195332036,
    chainTvls: {
      'Avalanche-staking': 31779.251215744927,
      Avalanche: 202786.6609028203,
      'Fantom-staking': 132060.80420829402,
      staking: 163840.05542403896,
      Fantom: 265017.98105050006,
    },
    change_1h: -0.2275449101796312,
    change_1d: -0.21943644459749123,
    change_7d: -8.973564063063776,
    staking: 163840.05542403896,
  },
  {
    id: '956',
    name: 'Swarm Markets',
    address: '0xb17548c7b510427baac4e267bea62e800b247173',
    symbol: 'SMT',
    url: 'https://swarm.markets',
    description:
      'Swarm Markets is the world’s first licensed* high-liquidity DeFi protocol — the convenience and transparency of DeFi with the confidence of financial market compliance.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/swarm-markets.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'swarm-markets',
    cmcId: '11821',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'swarm-markets/index.js',
    twitter: 'SwarmMarkets',
    audit_links: ['https://docs.swarm.markets/reference/smart-contract-audit'],
    forkedFrom: ['Balancer'],
    listedAt: 1638414799,
    slug: 'swarm-markets',
    tvl: 465289.69842830405,
    chainTvls: { Ethereum: 465289.69842830405 },
    change_1h: 0.8110521215022573,
    change_1d: -12.64458362035107,
    change_7d: -6.62890488612085,
    mcap: 1592858,
  },
  {
    id: '1537',
    name: 'Solrise Finance',
    address: 'solana:SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr',
    symbol: 'SLRS',
    url: 'https://solrise.finance/',
    description:
      'A fully decentralized fund management and investment protocol. Choose a fund to invest in, or create your own with just a few clicks.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/solrise-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'solrise-finance',
    cmcId: '9989',
    category: 'Indexes',
    chains: ['Solana'],
    oracles: [],
    forkedFrom: [],
    module: 'solrise-finance/index.js',
    twitter: 'SolriseFinance',
    audit_links: [],
    listedAt: 1647034707,
    slug: 'solrise-finance',
    tvl: 461892.40402975766,
    chainTvls: { 'Solana-staking': 2688236.1342188935, Solana: 461892.40402975766, staking: 2688236.1342188935 },
    change_1h: -0.2609287238808662,
    change_1d: -0.684725416457951,
    change_7d: 4.112519193201635,
    staking: 2688236.1342188935,
    mcap: 12864648,
  },
  {
    id: '1238',
    name: 'Minotaur Money',
    address: 'cronos:0x3A1138075bd97a33F23A87824b811146FA44288E',
    symbol: 'MINO',
    url: 'https://minotaur.money/',
    description: 'Decentralized Reserve Currency and VC on Cronos Network',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/minotaur-money.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Cronos'],
    module: 'minotaur-money/index.js',
    twitter: 'Minotaur_Money',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641938251,
    slug: 'minotaur-money',
    tvl: 456365.29416333156,
    chainTvls: { Cronos: 456365.29416333156, 'Cronos-staking': 1053056.3540880217, staking: 1053056.3540880217 },
    change_1h: 0.4332983140220108,
    change_1d: -6.811235642189388,
    change_7d: 9.952512226214623,
    staking: 1053056.3540880217,
  },
  {
    id: '1155',
    name: 'SheepDex',
    address: 'bsc:0x6a428ff9bfec2c8f676b8c905d49146c6106af90',
    symbol: 'SPC',
    url: 'https://sheepdex.org/#/home',
    description: '1st DEX integrating Spot and Derivatives & 1st Concentrated Liquidity V3 DEX on #BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/sheepdex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'sheepdex/index.js',
    twitter: 'SheepDex',
    listedAt: 1640829982,
    slug: 'sheepdex',
    tvl: 449672.1900918417,
    chainTvls: { Binance: 449672.1900918417 },
    change_1h: 0.13269946037219427,
    change_1d: -36.199850161884804,
    change_7d: -61.1725663087619,
  },
  {
    id: '816',
    name: 'xSigma',
    address: '0x7777777777697cfeecf846a76326da79cc606517',
    symbol: 'SIG',
    url: 'https://xsigma.fi',
    description:
      'xSigma Finance is a decentralized stablecoin exchange that provides the cheapest transactions for customers and a mining platform for liquidity providers.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/xsigma.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'xsigma/index.js',
    twitter: 'xSigma5',
    listedAt: 1636442561,
    slug: 'xsigma',
    tvl: 449021.29873962497,
    chainTvls: {
      Ethereum: 449021.29873962497,
      pool2: 188633.1161204346,
      'Ethereum-staking': 89247.9624555221,
      staking: 89247.9624555221,
      'Ethereum-pool2': 188633.1161204346,
    },
    change_1h: -0.18781104973845686,
    change_1d: 0.09990765895391007,
    change_7d: -15.98043044441124,
    staking: 89247.9624555221,
    pool2: 188633.1161204346,
  },
  {
    id: '815',
    name: 'PureSwap',
    address: 'bsc:0x481F0557FB3BB5eE461FD47F287b1ca944aD89bc',
    symbol: 'PURE',
    url: 'https://pureswap.finance',
    description:
      'PureSwap is a decentralized exchange running on Binance Smart Chain with lots of other features that let you earn and win tokens.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pureswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'pureswap/index.js',
    twitter: 'Pureswap1',
    forkedFrom: ['Uniswap'],
    listedAt: 1636441951,
    slug: 'pureswap',
    tvl: 442238.3703790299,
    chainTvls: { Binance: 442238.3703790299 },
    change_1h: 0,
    change_1d: 1.244944004988298,
    change_7d: 7.706404227646658,
  },
  {
    id: '582',
    name: 'Ante Finance',
    address: '-',
    symbol: '-',
    url: 'https://ante.finance',
    description: 'Smart Tests for Smart Contracts',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ante-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'ante/index.js',
    twitter: 'AnteFinance',
    oracles: [],
    slug: 'ante-finance',
    tvl: 434072.6230735422,
    chainTvls: { Ethereum: 434072.6230735422 },
    change_1h: 0.6233285570915683,
    change_1d: 8.355220937429038,
    change_7d: 26.360355224754954,
  },
  {
    id: '1075',
    name: 'SpiritDAO',
    address: 'avax:0xa72eab7327e48bde0ff873eaf1d4fecf6036504f',
    symbol: 'GHOST',
    url: 'https://dapp.spiritdao.finance/',
    description:
      'SpiritDAO is a decentralized reserve currency, forked from the successful and secure Olympus DAO. Spirit will rely on the bond-and-stake methodology, in order to raise treasury funds and reward early supporters.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/spiritdao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'spirit-dao/index.js',
    twitter: 'spirits_dao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640174051,
    slug: 'spiritdao',
    tvl: 429970.25031622284,
    chainTvls: { 'Avalanche-staking': 19869.48717292425, Avalanche: 429970.25031622284, staking: 19869.48717292425 },
    change_1h: -0.05079032984430398,
    change_1d: 0.12107468193873672,
    change_7d: -0.8144239732051233,
    staking: 19869.48717292425,
  },
  {
    id: '702',
    name: 'Coffin Finance',
    address: 'fantom:0x593ab53baffaf1e821845cf7080428366f030a9c',
    symbol: 'COFFIN',
    url: 'https://coffin.finance',
    description:
      "Coffin Finance protocol is a partially collateralized stable token project. Coffin Finance team believes in the potential of Fantom Opera, so rather than using USDC, it uses FTM and COFFIN (the protocol's native token) as collaterals to mint CoUSD stable tokens. This makes it truly decentralized! A big part of the platform revenue will be given back to the community through its high yield farming options, which includes both staking liquidity pairs and single asset staking.",
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/coffin-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'coffin-finance',
    cmcId: '12765',
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: 'coffin/index.js',
    twitter: 'CoffinFinance',
    audit_links: ['https://github.com/CoffinFinance/coffin-contracts/tree/main/audits'],
    listedAt: 1635104456,
    slug: 'coffin-finance',
    tvl: 428707.174258398,
    chainTvls: { 'Fantom-staking': 5386.159662069895, staking: 5386.159662069895, Fantom: 428707.174258398 },
    change_1h: 0.8383282141887207,
    change_1d: 0.07438050819972375,
    change_7d: 5.788308855702965,
    staking: 5386.159662069895,
    fdv: 3318084,
    mcap: 0,
  },
  {
    id: '485',
    name: 'TendieSwap',
    address: 'bsc:0x9853A30C69474BeD37595F9B149ad634b5c323d9',
    symbol: 'TENDIE',
    url: 'https://www.tendieswap.org',
    description:
      'TendieSwap is a prediction market DeFi platform running on the Binance Smart Chain and Polygon Network. Beyond it’s prediction markets, TendieSwap also offers a Decentralized Exchange (AMM), Yield Farming, and Staking platform. TENDIE is the native currency of the platform. With this token, you will get access to many of the incredible features the TendieSwap protocol offers.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/tendieswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'tendieswap',
    cmcId: '11034',
    category: 'Dexes',
    chains: ['Binance'],
    forkedFrom: ['Uniswap'],
    module: 'tendieswap/index.js',
    twitter: 'tendie_swap',
    slug: 'tendieswap',
    tvl: 426911.7961387839,
    chainTvls: { Binance: 426911.7961387839, 'Binance-staking': 4177655.5392339793, staking: 4177655.5392339793 },
    change_1h: 0.04617423733772341,
    change_1d: 6.081957793471489,
    change_7d: 7.583497070238991,
    staking: 4177655.5392339793,
    mcap: 8628803,
  },
  {
    id: '1024',
    name: 'DefySwap',
    address: 'fantom:0x84b0b7718f8480A9eDa3133Fd385D7EDf2b1d1c4',
    symbol: 'DFY',
    url: 'https://defyswap.finance/',
    description: 'Automated Market Maker with Limit Order Features, Impermanent Loss Protection & Futuristic UI',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/defyswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'defyswap/index.js',
    twitter: 'DefySwap',
    audit_links: ['https://defiyield.app/audit-database/shellboxes/defyswap'],
    forkedFrom: ['Uniswap'],
    listedAt: 1639510919,
    slug: 'defyswap',
    tvl: 426632.9710863273,
    chainTvls: { 'Fantom-staking': 21955.095745219336, staking: 21955.095745219336, Fantom: 426632.9710863273 },
    change_1h: 0,
    change_1d: -0.08754680938898218,
    change_7d: -25.597490077213067,
    staking: 21955.095745219336,
  },
  {
    id: '848',
    name: 'Talent Protocol',
    address: null,
    symbol: '-',
    url: 'https://www.talentprotocol.com',
    description:
      'Talent Protocol is a web3 platform where talent can easily launch a social token (called Talent Tokens) to accelerate their career, and where supporters can back people they believe in.',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/talent-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Celo'],
    module: 'talentprotocol/index.js',
    twitter: 'talentprotocol',
    audit_links: ['https://drive.google.com/file/d/1XKrcECxlIs0e3H7gmD0Avqsah_IuJB4H/view'],
    listedAt: 1637116337,
    slug: 'talent-protocol',
    tvl: 424824.8933718529,
    chainTvls: { Celo: 424824.8933718529 },
    change_1h: 0.07283701124234199,
    change_1d: 3.0640563637725364,
    change_7d: 4.127272987822451,
  },
  {
    id: '985',
    name: 'Mobius Finance',
    address: 'polygon:0x2db0db271a10661e7090b6758350e18f6798a49d',
    symbol: 'MOT',
    url: 'https://mobius.finance',
    description:
      'Mobius Finance is the first multi-collateral decentralized trading protocol that allows users to create and trade synthetic assets with zero slippage, built on top of Polygon',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/mobius-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'mobius-finance',
    cmcId: '11322',
    category: 'Synthetics',
    chains: ['Polygon'],
    module: 'mobiusfinance/index.js',
    twitter: 'Mobius_Finance',
    audit_links: ['https://github.com/mobiusfinance/ContractsV1/tree/main/audit'],
    oracles: ['Chainlink'],
    listedAt: 1638877544,
    slug: 'mobius-finance',
    tvl: 422611,
    chainTvls: { Polygon: 422611 },
    change_1h: 0.01680314289771445,
    change_1d: -0.8981312772458523,
    change_7d: -8.362714613446428,
    mcap: 0,
  },
  {
    id: '896',
    name: 'KwikSwap',
    address: '0x286c0936c7eaf6651099ab5dab9ee5a6cb5d229d',
    symbol: 'KWIK',
    url: 'https://home.kwikswap.org',
    description:
      'Kwikswap is a revolutionary Multi Cross Chain Swap Protocol with Layer 2 Scaling powered by Ethereum, Polkadot, Shiden, Astar (formerly Plasm), Reef Chain, BSC & Acala Network. Use Kwikswap for Swapping, Market Creation, Provide Liquidity & Staking',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kwikswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'kwikswap-protocol',
    cmcId: '10101',
    category: 'Dexes',
    chains: ['Ethereum', 'Shiden', 'Polygon', 'Binance'],
    module: 'Kwikswap/index.js',
    twitter: 'kwikswapdex',
    forkedFrom: ['Uniswap'],
    listedAt: 1637684497,
    slug: 'kwikswap',
    tvl: 418363.1769689657,
    chainTvls: {
      'Shiden-staking': 4412.020692138278,
      Binance: 1524.9390824280995,
      Ethereum: 181778.23106693634,
      Shiden: 166929.8154820624,
      'Ethereum-staking': 10529.139015091752,
      Polygon: 68130.19133753882,
      'Polygon-staking': 0,
      staking: 14941.15970723003,
    },
    change_1h: -0.19627157440353926,
    change_1d: -1.152351058990888,
    change_7d: 5.688731932147235,
    staking: 14941.15970723003,
    mcap: 38256,
  },
  {
    id: '1005',
    name: 'UPFI Network',
    address: 'solana:EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7',
    symbol: 'UPS',
    url: 'https://upfi.network',
    description: 'UPFI - Stablecoin partially backed by collateral and partially stabilized algorithmically',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/upfi-network.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'upfi-network',
    cmcId: '14322',
    category: 'Algo-Stables',
    chains: ['Solana'],
    module: 'upfi.js',
    twitter: 'upfi_network',
    audit_links: [
      'https://www.rdauditors.com/wp-content/uploads/2021/11/UPFI-Network-Smart-Contract-Security-Report.pdf',
    ],
    oracles: ['Pyth', 'DIA'],
    listedAt: 1639176180,
    openSource: false,
    slug: 'upfi-network',
    tvl: 418338.9450447893,
    chainTvls: { pool2: 418338.9450447893, Solana: 418338.9450447893, 'Solana-pool2': 418338.9450447893 },
    change_1h: 0.12488498220378119,
    change_1d: 0.24649253445217312,
    change_7d: 0.4863599333953914,
    pool2: 418338.9450447893,
    fdv: 2581236,
    mcap: 0,
  },
  {
    id: '1457',
    name: 'OGX',
    address: 'eos:OGX-eos-organixtoken',
    symbol: 'OGX',
    url: 'https://ogx.network/#/',
    description: 'Synthetic assets issuance and trading protocol',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/ogx.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'organix',
    cmcId: null,
    category: 'Synthetics',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'ogx/index.js',
    twitter: 'OrganixProtocol',
    listedAt: 1645643109,
    slug: 'ogx',
    tvl: 416518.0755094831,
    chainTvls: { EOS: 416518.0755094831 },
    change_1h: -0.4805636682880987,
    change_1d: -2.4339149229938073,
    change_7d: -30.12842931189627,
    mcap: 0,
  },
  {
    id: '940',
    name: 'Soda Protocol',
    address: null,
    symbol: '-',
    url: 'https://sodaprotocol.com/',
    description: 'A Smart Lending Protocol with Credit System',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/soda-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Solana'],
    module: 'soda-protocol/index.js',
    twitter: 'SodaProtocol',
    audit_links: ['https://www.certik.com/projects/sodaprotocol'],
    oracles: ['Chainlink', 'Pyth', 'Switchboard'],
    listedAt: 1638211002,
    openSource: false,
    slug: 'soda-protocol',
    tvl: 414105.8403656088,
    chainTvls: { Solana: 414105.8403656088 },
    change_1h: 0.005397101218406419,
    change_1d: 1.0373299831940557,
    change_7d: -1.9464641193724788,
  },
  {
    id: '495',
    name: 'HFIone',
    address: 'heco:0x98fc3b60ed4a504f588342a53746405e355f9347',
    symbol: 'HFI',
    url: 'https://hfi.one/#/en',
    description: 'HFI.one is a decentralized aggregate mining platform based on the Huobi ecological chain HECO.',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/hfione.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'hecofi',
    cmcId: '8540',
    category: 'Yield',
    chains: ['Heco'],
    module: 'hfione/index.js',
    twitter: 'fi_heco',
    audit_links: ['https://hfi.one/slowmist.pdf'],
    slug: 'hfione',
    tvl: 405904.48986417335,
    chainTvls: { Heco: 405904.48986417335 },
    change_1h: 2.842170943040401e-14,
    change_1d: 1.8706575884395562,
    change_7d: 4.986631081300715,
    mcap: 0,
  },
  {
    id: '308',
    name: 'Ruler',
    address: '0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8',
    symbol: 'RULER',
    url: 'https://rulerprotocol.com/',
    description: 'No-liquidation lending protocol',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ruler.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ruler-protocol',
    cmcId: '8698',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'ruler/index.js',
    twitter: 'RulerProtocol',
    audit_links: ['https://docs.rulerprotocol.com/security'],
    oracles: ['Chainlink'],
    slug: 'ruler',
    tvl: 405426.9938721353,
    chainTvls: { Ethereum: 405426.9938721353 },
    change_1h: -1.0450961678051982,
    change_1d: -0.9468710795838007,
    change_7d: -1.537452686531907,
    fdv: 198148,
    mcap: 44096,
  },
  {
    id: '1342',
    name: 'Croblanc',
    address: 'cronos:0xd3cecbe5639d05aed446da11f08d495ca6bf359f',
    symbol: 'CROBLANC',
    url: 'https://app.croblanc.com/farms',
    description: 'Defi yield optimizer for Cronos',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/croblanc.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'croblanc',
    cmcId: '17363',
    category: 'Yield',
    chains: ['Cronos'],
    module: 'croblanc/index.js',
    twitter: 'croblancdotcom',
    listedAt: 1643803105,
    slug: 'croblanc',
    tvl: 395411.04042850155,
    chainTvls: {
      Cronos: 395411.04042850155,
      'Cronos-Treasury': 546.8182913530117,
      pool2: 4682.397612123277,
      'Cronos-pool2': 4682.397612123277,
      Treasury: 546.8182913530117,
    },
    change_1h: -0.09081937031973553,
    change_1d: 0.2565837285739576,
    change_7d: 9.568021622863327,
    pool2: 4682.397612123277,
    fdv: 26817,
    mcap: 0,
  },
  {
    id: '1566',
    name: 'Anedak',
    address: null,
    symbol: '-',
    url: 'https://anedak.com/',
    description: 'Anedak paves the way for token creation on the Kadena Network.',
    chain: 'Kadena',
    logo: 'https://icons.llama.fi/anedak.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Kadena'],
    oracles: [],
    forkedFrom: [],
    module: 'anedak/index.js',
    twitter: null,
    listedAt: 1647812879,
    slug: 'anedak',
    tvl: 392872.13196848857,
    chainTvls: { Kadena: 392872.13196848857 },
    change_1h: -0.6545449102023753,
    change_1d: -1.9383783950712115,
    change_7d: 13.410149363805516,
  },
  {
    id: '1211',
    name: 'ThorusFi',
    address: 'avax:0xAE4AA155D2987B454C29450ef4f862CF00907B61',
    symbol: 'THO',
    url: 'https://thorus.fi/',
    description:
      'Thorus is an all in one cross-chain DeFi Platform with an adaptable treasury system, and a token holder first approach. All protocol functions are designed to reinforce this mentality',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/thorusfi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'thorus',
    cmcId: '16901',
    category: 'Dexes',
    chains: ['Avalanche', 'Moonbeam'],
    module: 'thorusfi/index.js',
    twitter: 'ThorusFi',
    audit_links: ['https://thorus.fi/Thorus_Bonds-Inspex_Audit.pdf'],
    listedAt: 1641573897,
    forkedFrom: ['Uniswap'],
    slug: 'thorusfi',
    tvl: 389277.3225305151,
    chainTvls: {
      'Avalanche-staking': 209183.96587058436,
      'Moonbeam-staking': 7186.485228565963,
      Avalanche: 379700.89440297545,
      staking: 216370.45109915032,
      Moonbeam: 9576.428127539628,
    },
    change_1h: 5.968558980384842e-13,
    change_1d: -34.30080498838488,
    change_7d: -88.06456635326711,
    staking: 216370.45109915032,
    fdv: 12219379,
    mcap: 689292,
  },
  {
    id: '169',
    name: 'Basis Cash',
    address: '0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a',
    symbol: 'BAC',
    url: 'https://basis.cash/',
    description: 'Basis Cash is a censorship-resistant and fairly distributed implementation of the Basis Protocol.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/basis-cash.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'basis-cash',
    cmcId: '7813',
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'basis-cash.js',
    twitter: 'BasisCash',
    audit_links: ['https://github.com/basisdollar/basisdollar-protocol#audit'],
    slug: 'basis-cash',
    tvl: 385749.94680000003,
    chainTvls: { Ethereum: 385749.94680000003 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 0,
    fdv: 691218,
    mcap: 691218,
  },
  {
    id: '625',
    name: "Arbi's Finance",
    address: 'arbitrum:0x9f20de1fc9b161b34089cbeae888168b44b03461',
    symbol: 'ARBIS',
    url: 'https://arbis.finance',
    description: "Arbi's Finance is the premier autocompounder on the Arbitrum network.",
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/arbis-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'arbis-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Arbitrum'],
    module: 'arbis.js',
    twitter: 'arbis_finance',
    slug: 'arbis-finance',
    tvl: 385125,
    chainTvls: { Arbitrum: 385125 },
    change_1h: 0,
    change_1d: 1.2727296632788523,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '1365',
    name: 'ETHA Lend',
    address: '0x59e9261255644c411afdd00bd89162d09d862e38',
    symbol: 'ETHA',
    url: 'https://www.ethalend.org',
    description: 'The agnostic #DeFi yield optimizer.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/ethalend.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'etha-lend',
    cmcId: '8709',
    category: 'Yield',
    chains: ['Polygon'],
    module: 'ethalend/index.js',
    twitter: 'ethalend',
    language: 'Solidity',
    audit_links: ['https://www.certik.com/projects/ethalend'],
    listedAt: 1644188153,
    slug: 'etha-lend',
    tvl: 381982.8126157685,
    chainTvls: {
      Polygon: 381982.8126157685,
      pool2: 52915.288372840834,
      'Polygon-pool2': 52915.288372840834,
      'Polygon-staking': 61689.82744464936,
      staking: 61689.82744464936,
    },
    change_1h: 0.34963561281773536,
    change_1d: -1.0977774529414859,
    change_7d: -31.836946902473983,
    staking: 61689.82744464936,
    pool2: 52915.288372840834,
    fdv: 1195044,
    mcap: 315015,
  },
  {
    id: '224',
    name: 'Domani Protocol',
    address: '0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0',
    symbol: 'DEXTF',
    url: 'https://domani.finance/',
    description: 'Empowering anyone to mint, trade and redeem non-custodial oracle-less tokenized portfolios.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/domani-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dextf',
    cmcId: '8691',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'dextf/index.js',
    twitter: 'DomaniProtocol',
    audit_links: ['https://docs.dextf.com/documentation/introduction/security-assessment-report'],
    slug: 'domani-protocol',
    tvl: 381667.60542778886,
    chainTvls: { Ethereum: 381667.60542778886 },
    change_1h: 0.983734623883123,
    change_1d: 2.9508102788974497,
    change_7d: 13.72227136917769,
    fdv: 4075516,
    mcap: 862787,
  },
  {
    id: '175',
    name: 'N3RD Finance',
    address: '0x32c868f6318d6334b2250f323d914bc2239e4eee',
    symbol: 'N3RDz',
    url: 'https://n3rd.finance/',
    description: 'High Yield, Deflationary Farming.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/n3rd-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'n3rd-finance',
    cmcId: '8019',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'n3rd.js',
    twitter: 'nerdfinance1',
    forkedFrom: ['cVault Finance'],
    slug: 'n3rd-finance',
    tvl: 379128,
    chainTvls: { Ethereum: 379128 },
    change_1h: 0.019258418962948554,
    change_1d: 3.121149562223067,
    change_7d: 14.911996217367985,
    fdv: 257639,
    mcap: 204807,
  },
  {
    id: '634',
    name: 'Singular Farm',
    address: 'avax:0xF9A075C9647e91410bF6C402bDF166e1540f67F0',
    symbol: 'SING',
    url: 'https://singular.farm',
    description:
      'Dual earning Yield Farm with USDT earning bank. Earn $JOE and $SING at once with your LP with high APR. Launched as an official partner of TraderJoe.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/singular-farm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'sing-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche', 'Binance', 'Polygon', 'Fantom'],
    module: 'singularfarm/index.js',
    twitter: 'singularfarm',
    audit_links: ['https://solidity.finance/audits/MasterSing'],
    slug: 'singular-farm',
    tvl: 374922.4776376095,
    chainTvls: {
      Binance: 93822.22433362766,
      Polygon: 53398.10571236351,
      Avalanche: 186956.93091155012,
      Fantom: 40745.2166800682,
    },
    change_1h: 0.32570053083796324,
    change_1d: 0.014281974087410276,
    change_7d: -5.26429606922602,
    fdv: 13214.26,
    mcap: 4338.61,
  },
  {
    id: '883',
    name: 'SmartDEX',
    address: null,
    symbol: '-',
    url: 'https://www.smartdex.app',
    description:
      "SmartDEX is a decentralized exchange developed by the Autonio Team. It is designed to be the world’s first 'smart DEX', giving users the ability to use high-frequency market makers and automated trading algorithms on a decentralized exchange.",
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/smartdex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '1366',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'smartdex/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'AI_smartdex',
    listedAt: 1637392829,
    slug: 'smartdex',
    tvl: 373823.3351293219,
    chainTvls: { Polygon: 373823.3351293219 },
    change_1h: 0.11347153777765584,
    change_1d: -1.477276921870768,
    change_7d: 5.905264683619222,
  },
  {
    id: '474',
    name: 'Canary',
    address: 'avax:0x8d88e48465f30acfb8dac0b3e35c9d6d7d36abaf',
    symbol: 'CNR',
    url: 'https://canary.exchange',
    description:
      'Canary Exchange is a decentralized exchange (DEX) which runs on Avalanche, the most promising and the fastest blockchain!',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/canary.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'canary',
    cmcId: '10555',
    category: 'Yield Aggregator',
    chains: ['Avalanche'],
    module: 'canary/index.js',
    twitter: 'CanaryDex',
    forkedFrom: ['Uniswap'],
    slug: 'canary',
    tvl: 371679.64182176284,
    chainTvls: { Avalanche: 371679.64182176284 },
    change_1h: 1.3668953573260296,
    change_1d: -1.0903756926684025,
    change_7d: 2.498604043378762,
    mcap: 0,
  },
  {
    id: '662',
    name: 'Artemis Protocol',
    address: 'harmony:0xd74433b187cf0ba998ad9be3486b929c76815215',
    symbol: 'MIS',
    url: 'https://app.artemisprotocol.one/',
    description:
      'Artemis is a multi-product DeFi Protocol built to incubate and bootstrap Harmony-based projects and ideas.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/artemis-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'artemis',
    cmcId: null,
    category: 'Launchpad',
    chains: ['Harmony'],
    module: 'artemis/index.js',
    audit_links: ['https://paladinsec.co/projects/artemis-ifo/'],
    twitter: 'ArtemisProtoco1',
    slug: 'artemis-protocol',
    tvl: 370133.55561,
    chainTvls: { 'Harmony-pool2': 2096834.5098792003, pool2: 2096834.5098792003, Harmony: 370133.55561 },
    change_1h: -0.22694610778442836,
    change_1d: 0.09992710728046461,
    change_7d: -0.22694610778442836,
    pool2: 2096834.5098792003,
    mcap: 0,
  },
  {
    id: '652',
    name: 'EmiSwap',
    address: '0x5a75a093747b72a0e14056352751edf03518031d',
    symbol: 'ESW',
    url: 'https://emiswap.com/',
    description:
      'EmiSwap is an audited, high APR, and cross-chain AMM DEX that offers users a secure environment to extract daily high yields from liquidity mining and farming.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/emiswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'emiswap',
    cmcId: '11153',
    category: 'Dexes',
    chains: ['Polygon', 'Ethereum', 'Kucoin'],
    module: 'emiswap/index.js',
    twitter: 'EmiSwap',
    forkedFrom: ['Uniswap'],
    audit_links: [
      'https://emiswap.com/docs/06042021_Emiswap_SC_Audit_Report.pdf',
      'https://emiswap.com/docs/12072021_Emiswap_SC_Audit_Report_V2.pdf',
    ],
    slug: 'emiswap',
    tvl: 368751.56580609706,
    chainTvls: {
      Ethereum: 145889.43073528985,
      Polygon: 215928.3475053039,
      'Ethereum-staking': 6424.170520815592,
      Kucoin: 6933.787565503344,
      staking: 6424.170520815592,
    },
    change_1h: -0.18133893365248355,
    change_1d: 3.23693393448346,
    change_7d: 6.470766788921296,
    staking: 6424.170520815592,
    mcap: 0,
  },
  {
    id: '754',
    name: 'BossSwap',
    address: 'harmony:0xE7bBE0E193FdFe95d2858F5C46d036065f8F735c',
    symbol: 'BOSS',
    url: 'https://www.bossswap.finance',
    description:
      'Boss Swap Finance is an unique DEX launching on the Harmony Protocol. We’ve taken some of our favorite features from other projects and added them together to make BOSS swap with some BOSS unique style.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/bossswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'boss-swap/index.js',
    twitter: 'BossSwapFinance',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://www.bossswap.finance/audit'],
    listedAt: 1635966094,
    slug: 'bossswap',
    tvl: 368203.56973002496,
    chainTvls: { Harmony: 368203.56973002496 },
    change_1h: -0.00018193948611155975,
    change_1d: -0.19683710601559312,
    change_7d: 12.390195483114866,
  },
  {
    id: '1043',
    name: 'SunflowerSwap',
    address: '0x8ab98c28295ea3bd2db6ac8b3ca57a625c054bd1',
    symbol: 'SFR',
    url: 'https://sunflowerswap.finance',
    description:
      'Sunflower Swap is an ETH-based NFT decentralized platform which adopts DeFi protocol. It firstly uses AMM and SLP',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/sunflowerswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'sunflowerswap/index.js',
    twitter: 'Sunflower_Swap',
    forkedFrom: ['Uniswap'],
    listedAt: 1639807342,
    slug: 'sunflowerswap',
    tvl: 364260.18939399114,
    chainTvls: { Ethereum: 364260.18939399114, 'Ethereum-staking': 0, staking: 0 },
    change_1h: 0.1038629609857935,
    change_1d: 7.7011800315885495,
    change_7d: 12.836998020387824,
    staking: 0,
  },
  {
    id: '309',
    name: 'Wheat Protocol',
    address: 'bsc:0x3ab63309F85df5D4c3351ff8EACb87980E05Da4E',
    symbol: 'WHEAT',
    url: 'https://wheat.growthdefi.com/',
    description:
      'Growth DeFi is a decentralized ecosystem that focuses on capital efficiency and maximizing returns through a variety of products. The goal is to maximize token holder value and maintain the best yields & products in the market. The ecosystem is composed of three tokens: WHEAT: Inflationary to begin, becomes deflationary over time - makes high yield farming on BSC sustainable. gROOT: Deflationary, limited supply - managed, diversified treasury investing with revenue share. GRO: Deflationary, governance with staking rewards earns revenue from everything built',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/wheat-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'wheat-token',
    cmcId: '9229',
    category: 'Yield',
    chains: ['Binance'],
    module: 'wheat.js',
    twitter: 'GrowthDefi',
    slug: 'wheat-protocol',
    tvl: 362506.61,
    chainTvls: { Binance: 362506.61 },
    change_1h: 10.390592249802083,
    change_1d: -2.207726209502468,
    change_7d: -5.299173526342898,
    mcap: 109594,
  },
  {
    id: '540',
    name: 'Moonswap',
    address: 'moonriver:0xB3FB48bF090bEDFF4f6F93FFb40221742E107db7',
    symbol: 'mSWAP',
    url: 'https://swap.moonswap.in/#/swap',
    description: 'The first truly decentralized DEX on Moonriver network.',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/moonswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '12072',
    category: 'Dexes',
    chains: ['Moonriver'],
    module: 'moonswap/index.js',
    twitter: 'MoonSwapDEX',
    forkedFrom: ['Uniswap'],
    slug: 'moonswap',
    tvl: 356958.5394608235,
    chainTvls: { Moonriver: 356958.5394608235 },
    change_1h: 1.1859161494239316,
    change_1d: -3.955636667876817,
    change_7d: 1.406253032642553,
  },
  {
    id: '590',
    name: 'Parrot Defi',
    address: 'arbitrum:0x78055dAA07035Aa5EBC3e5139C281Ce6312E1b22',
    symbol: 'PPEGG',
    url: 'https://parrotdefi.com',
    description: 'Yield farming and staking on the Arbitrum network.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/parrot-defi.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['IoTeX', 'Harmony', 'Polygon', 'Arbitrum'],
    module: 'parrotegg/index.js',
    twitter: 'Parrotdefi1',
    forkedFrom: ['Goose Finance'],
    slug: 'parrot-defi',
    tvl: 349109.3701258079,
    chainTvls: {
      IoTeX: 280872.26140087354,
      Polygon: 19621.035734471345,
      Arbitrum: 10307.69124605916,
      Harmony: 38308.38174440385,
    },
    change_1h: 0.023117222035068608,
    change_1d: 0.7819375435207121,
    change_7d: 2.8297702781817833,
  },
  {
    id: '1204',
    name: 'BooFinance',
    address: 'avax:0xb00f1ad977a949a3ccc389ca1d1282a2946963b0',
    symbol: 'BOOFI',
    url: 'https://www.boofinance.io/',
    description:
      'The Boo Finance Protocol is pioneering DeFi 3.0 with optimized, competitive yield-farming and staking with deflationary NFTs and DAO on the Avalanche Network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/boofinance.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'boo-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'boofinance/index.js',
    twitter: 'Boo_Finance',
    oracles: ['TWAP'],
    listedAt: 1641511342,
    slug: 'boofinance',
    tvl: 347767.0017885705,
    chainTvls: {
      'Avalanche-staking': 364206.41565222805,
      pool2: 413682.66165635176,
      Avalanche: 347767.0017885705,
      'Avalanche-pool2': 413682.66165635176,
      staking: 364206.41565222805,
    },
    change_1h: 0.835488791502172,
    change_1d: 3.081078183961324,
    change_7d: 5.086457605909402,
    staking: 364206.41565222805,
    pool2: 413682.66165635176,
    mcap: 0,
  },
  {
    id: '542',
    name: 'NewDex',
    address: 'bsc:0x996f56299a5b7c4f825a44886e07dafc4660b794',
    symbol: 'DEX',
    url: 'https://newdex.io',
    description:
      'Newdex is committed to taking advantage of the composability of DeFi to aggregate the depth of DEX in multiple chains, develop the aggregated DEX with "best depth, best price and best experience", provide users with good decentralized trading experience, and become multi-chain DEX trading entry.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/newdex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'newdex-token',
    cmcId: '702',
    category: 'Dexes',
    chains: ['EOS'],
    module: 'newdex/index.js',
    twitter: 'NewdexOfficial',
    audit_links: ['https://support.newdex.net/hc/en-us/articles/4406436034189-PeckShield-Security-Audit-Report'],
    slug: 'newdex',
    tvl: 343112.11757506494,
    chainTvls: { EOS: 343112.11757506494 },
    change_1h: 0,
    change_1d: -11.399551869675818,
    change_7d: -38.49535119357655,
    mcap: 2425721,
  },
  {
    id: '559',
    name: 'Dapp Account DAO',
    address: 'eos:DAD',
    symbol: 'DAD',
    url: 'https://superdad.finance',
    description:
      'DAPP Account DAO is a cross-chain DeFi platform that utilizes DAO governance to launch and manage best DeFi strategies to maximize profits for token holders.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/dapp-account-dao.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['EOS'],
    module: 'dad/index.js',
    twitter: 'DAPPAccountDAO',
    audit_links: ['https://dappaccountdao.gitbook.io/dappaccountdao/audit'],
    slug: 'dapp-account-dao',
    tvl: 342906.27106475615,
    chainTvls: { EOS: 342906.27106475615 },
    change_1h: 1.0444947240970066,
    change_1d: -0.7276345509335158,
    change_7d: 27.886412824063257,
  },
  {
    id: '978',
    name: 'ChickenSwap',
    address: '0xE63684BcF2987892CEfB4caA79BD21b34e98A291',
    symbol: 'KFC',
    url: 'https://www.chickenswap.org/#/',
    description:
      'ChickenSwap DEX is a Decentralized Exchange, similar to Uniswap. All users can provide liquidity and trade crypto assets here without any centralized orderbook.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/chickenswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'chicken',
    cmcId: '7169',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'chickenswap/index.js',
    twitter: 'ChefGrandpa',
    forkedFrom: ['Uniswap'],
    listedAt: 1638681578,
    slug: 'chickenswap',
    tvl: 338569.09286389436,
    chainTvls: {
      Ethereum: 338569.09286389436,
      pool2: 2184.181064407197,
      'Ethereum-staking': 8893.581370905002,
      staking: 8893.581370905002,
      'Ethereum-pool2': 2184.181064407197,
    },
    change_1h: 0.6133305381794258,
    change_1d: 0.9996058623926558,
    change_7d: -1.1096501235563778,
    staking: 8893.581370905002,
    pool2: 2184.181064407197,
    mcap: 0,
  },
  {
    id: '328',
    name: 'SteakBank Finance',
    address: 'bsc:0xbb53fcab7a3616c5be33b9c0af612f0462b01734',
    symbol: 'SBF',
    url: 'https://steakbank.finance/',
    description: 'Liquid staking for BNB',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/steakbank-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'steakbank-finance',
    cmcId: '9953',
    category: 'Staking',
    chains: ['Binance'],
    module: 'steakbank/index.js',
    twitter: 'SteakBank',
    audit_links: ['https://docs.steakbank.finance/audits'],
    slug: 'steakbank-finance',
    tvl: 332484.6669823282,
    chainTvls: { Binance: 332484.6669823282 },
    change_1h: 0.6199528744309504,
    change_1d: -0.21849627972234487,
    change_7d: 9.300315618269025,
    fdv: 777514,
    mcap: 59502,
  },
  {
    id: '600',
    name: 'Value Liquid',
    address: '0x49e833337ece7afe375e44f4e3e8481029218e5c',
    symbol: 'VALUE',
    url: 'https://valueliquid.io/#/swap',
    description:
      'Value Liquid is an automated market-maker built on Ethereum that allows anyone to create trading pools with flexible ratio pairs, add liquidity, and earn trading fees.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/value-liquid.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'valueliquid/index.js',
    twitter: 'value_defi',
    audit_links: ['https://valuedefi.io/audit'],
    forkedFrom: ['Balancer'],
    slug: 'value-liquid',
    tvl: 330611.37566681876,
    chainTvls: { Ethereum: 330611.37566681876 },
    change_1h: 0.7059837190676745,
    change_1d: 2.8107617001767125,
    change_7d: 10.063975592171516,
  },
  {
    id: '216',
    name: 'Auctus',
    address: '0xc12d099be31567add4e4e4d0d45691c3f58f5663',
    symbol: 'AUC',
    url: 'https://auctus.org/',
    description: 'Auctus brings on-chain options to the ecosystem.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/auctus.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'auctus',
    cmcId: '2653',
    category: 'Options',
    chains: ['Ethereum'],
    module: 'auctus/index.js',
    twitter: 'AuctusOptions',
    audit_links: ['https://docs.auctus.org/security#audits'],
    oracles: ['Chainlink'],
    slug: 'auctus',
    tvl: 329947.9445285913,
    chainTvls: { Ethereum: 329947.9445285913 },
    change_1h: 0.82593625217973,
    change_1d: -26.453979503214725,
    change_7d: -26.431277771708423,
    fdv: 1161025,
    mcap: 626740,
  },
  {
    id: '1526',
    name: 'Metavault DAO',
    address: 'fantom:0x27746007e821aeec6f9c65cbfda04870c236346c',
    symbol: 'MVD',
    url: 'https://metavault.org',
    description:
      'Metavault is a blockchain-based community controlled investment platform and decentralised venture capital vehicle that allows anyone to invest in the latest and most profitable blockchain, DeFi and metaverse projects and strategies.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/metavault-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'metavault',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Olympus DAO'],
    module: 'metavault/index.js',
    twitter: 'MetavaultDAO',
    listedAt: 1646854791,
    slug: 'metavault-dao',
    tvl: 328559.99396865,
    chainTvls: { 'Fantom-staking': 1025220.419840921, staking: 1025220.419840921, Fantom: 328559.99396865 },
    change_1h: -0.6551253188317361,
    change_1d: 0.6867579136307285,
    change_7d: 25.97053199363519,
    staking: 1025220.419840921,
    mcap: 1063156,
  },
  {
    id: '743',
    name: 'Neku',
    address: 'arbitrum:0x3Bf0880fd26E49c46d1A1e69ADb268889B4be840',
    symbol: 'NEKU',
    url: 'https://neku.io',
    description: 'Neku Finance is a decentralized and multi-chain lending protocol',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/neku.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Moonriver', 'Arbitrum', 'Binance'],
    module: 'neku/index.js',
    twitter: 'NekuFinance',
    audit_links: ['https://www.certik.org/projects/nekufinance', 'https://bitriseaudits.com/project/85'],
    forkedFrom: ['Compound'],
    listedAt: 1635819540,
    slug: 'neku',
    tvl: 320656.70611730026,
    chainTvls: {
      'Arbitrum-borrowed': 138035.06811211022,
      Moonriver: 191062.0812533621,
      'Binance-borrowed': 0.066649733832,
      'Moonriver-borrowed': 43291.653917719945,
      Binance: 9142.872842753826,
      Arbitrum: 120451.75202118432,
      borrowed: 181326.788679564,
    },
    change_1h: 0.13767161780637593,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '584',
    name: '1Swap',
    address: 'moonriver:0x3516a7588C2E6FFA66C9507eF51853eb85d76e5B',
    symbol: '1SWAP',
    url: 'https://1swap.fi',
    description: 'Stableswap DEX on Moonriver',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/1swap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Moonriver'],
    module: '1swap/index.js',
    twitter: '1swapfi',
    forkedFrom: ['Saddle Finance'],
    slug: '1swap',
    tvl: 319419.7772561861,
    chainTvls: { Moonriver: 319419.7772561861 },
    change_1h: -0.11953358645250489,
    change_1d: 2.8166237820551885,
    change_7d: -1.8292287498126996,
  },
  {
    id: '395',
    name: 'Waka Finance',
    address: 'fantom:0xf61ccde1d4bb76ced1daa9d4c429cca83022b08b',
    symbol: 'WAKA',
    url: 'https://waka.finance',
    description:
      'Waka Finance is building a DeFi Hub to support the Fantom ecosystem. Starting with an AMM (WakaSwap), there is a roadmap to have support for liquid staking derivatives, lending & borrowing, yield generation, leverage products, and NFTs',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/waka-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'waka-finance',
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'wakafinance.js',
    twitter: 'WakaFinance',
    audit_links: ['https://waka-finance-2.gitbook.io/waka-finance/documentation/audit'],
    slug: 'waka-finance',
    tvl: 319315.67790374014,
    chainTvls: { Fantom: 319315.67790374014 },
    change_1h: 0.007907866714873535,
    change_1d: -0.2614469222901761,
    change_7d: 9.19918154633767,
    mcap: 0,
  },
  {
    id: '269',
    name: 'YFII',
    address: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    symbol: 'YFII',
    url: 'https://dfi.money/',
    description: 'Yield aggregator',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/yfii.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: 'yfii-finance',
    cmcId: '5957',
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'yfii/index.js',
    twitter: 'DfiMoney',
    audit_links: ['https://github.com/yfii/audit'],
    forkedFrom: ['Yearn Finance'],
    slug: 'yfii',
    tvl: 318704.9084431086,
    chainTvls: { Ethereum: 318704.9084431086 },
    change_1h: 0.381875032847546,
    change_1d: 0.8342189507769149,
    change_7d: 3.7998658974126727,
    fdv: 94839285,
    mcap: 94204125,
  },
  {
    id: '1013',
    name: 'Stargate Finance',
    address: 'bsc:0x141381f07Fa31432243113Cda2F617d5d255d39a',
    symbol: 'SGT',
    url: 'https://www.stargate.zone/#/stake',
    description:
      'Stargate Finance is a decentralized reserve currency protocol based on the SGT token on Binance Smart Chain. Economically, it imitates the model created by OlympusDao. ',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/stargate-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'stargate-finance/index.js',
    twitter: 'StarGate_Fin',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639331903,
    slug: 'stargate-finance',
    tvl: 315199.1728597099,
    chainTvls: { Binance: 315199.1728597099, 'Binance-staking': 56571.50339774235, staking: 56571.50339774235 },
    change_1h: 0,
    change_1d: 0.07714836323641805,
    change_7d: -0.6403849644828483,
    staking: 56571.50339774235,
  },
  {
    id: '222',
    name: 'DeFiner',
    address: '0x054f76beed60ab6dbeb23502178c52d6c5debe40',
    symbol: 'FIN',
    url: 'https://definer.org/',
    description:
      'DeFiner empowers users to embrace the new digital economy and unlock instant value from their crypto assets through earning, lending, and borrowing.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/definer.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'definer',
    cmcId: '7225',
    category: 'Lending',
    chains: ['OKExChain', 'Ethereum'],
    module: 'definer/index.js',
    twitter: 'DeFinerOrg',
    audit_links: ['https://docs.definer.org/'],
    oracles: ['Chainlink'],
    slug: 'definer',
    tvl: 314493.12278650305,
    chainTvls: { Ethereum: 135976.57118794924, OKExChain: 178516.5515985538 },
    change_1h: 0.22998990346951587,
    change_1d: 15.236606765260063,
    change_7d: 5.1696716322128395,
    fdv: 15323375,
    mcap: 2928424,
  },
  {
    id: '1242',
    name: 'SleepEarn Finance',
    address: 'kardia:0xb697231730C004110A86f51BfF4B8DD085c0CB28',
    symbol: 'SEN',
    url: 'https://sleepearn.finance',
    description:
      'SleepEarn Finance is a Decentralized, Yield Optimizer platform on KardiaChain that allows its users to earn compound interest on their crypto holdings.',
    chain: 'Kardia',
    logo: 'https://icons.llama.fi/sleepearn-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'sleepearn-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Kardia'],
    module: 'sleepearn/index.js',
    twitter: 'sleepearn',
    listedAt: 1641951111,
    slug: 'sleepearn-finance',
    tvl: 311760.2300000001,
    chainTvls: { Kardia: 311760.2300000001 },
    change_1h: 0,
    change_1d: 7.919643402966699,
    change_7d: 31.72671729031893,
    fdv: 309593,
    mcap: 0,
  },
  {
    id: '1341',
    name: 'CryptoMate',
    address: 'stacks:SP32NTG209B861QBHF4TH0C86QB0A12TY2F16WHMY.cryptomate-token',
    symbol: 'CMT',
    url: 'https://cryptomate.me/',
    description:
      "CryptoMate is a decentralized ecosystem and an AI guide for the people who don't know how to access and benefit from the Crypto Economy.",
    chain: 'Stacks',
    logo: 'https://icons.llama.fi/cryptomate.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Stacks'],
    module: 'cryptomate.js',
    twitter: 'CryptoMate_defi',
    language: 'Clarity',
    audit_links: ['https://source-of-clarity.com/protocols/crytomate-swapper'],
    listedAt: 1643802716,
    slug: 'cryptomate',
    tvl: 299042.7208450644,
    chainTvls: { Stacks: 299042.7208450644 },
    change_1h: 2.5843164071393403,
    change_1d: 4.845756234405997,
    change_7d: 23.05052478495054,
  },
  {
    id: '1177',
    name: 'Whale Loans',
    address: 'bsc:0x453939C0270e9405876C7f047aDE3932FD2d7a51',
    symbol: 'HUMP',
    url: 'https://whale.loans',
    description:
      'Whale.loans is a venture focused on designing a scalable and defendable ecosystem that removes the complexity in and around DeFi and distributes value to its users. In layman’s terms, we are creating an “easy button” for a better user experience.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/whale-loans.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'whaleloans/index.js',
    twitter: 'WhaleLoans',
    audit_links: ['https://github.com/Whale-loans/audits/blob/main/audits/Blocksec/whale_v1.4.pdf'],
    oracles: ['Chainlink'],
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641173125,
    slug: 'whale-loans',
    tvl: 296449.3745447079,
    chainTvls: { Binance: 296449.3745447079, 'Binance-staking': 272280.7183644261, staking: 272280.7183644261 },
    change_1h: -0.32558704385819226,
    change_1d: 10.856601123435297,
    change_7d: 12.09150996316886,
    staking: 272280.7183644261,
  },
  {
    id: '1349',
    name: 'Degen Haus',
    address: 'fantom:0x9F3c6e1bD483cd977DF9B36734E1cD684f1Be62',
    symbol: 'DOPE',
    url: 'https://app.degen.haus/',
    description:
      'Degen Haus is a place that should have been here years ago. Are you sick of teddy bear/dog/cat memes and other regurgitated cyrpto-themes? Time for something unique and different. That’s where Degen Haus comes in!',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/degenhaus.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'degenhaus/index.js',
    twitter: 'HouseDegen',
    listedAt: 1643835964,
    forkedFrom: ['Uniswap'],
    slug: 'degen-haus',
    tvl: 296028.8907727203,
    chainTvls: { 'Fantom-staking': 27521.339108975364, staking: 27521.339108975364, Fantom: 296028.8907727203 },
    change_1h: 0.6356303795462992,
    change_1d: 0.1981888364320099,
    change_7d: -13.199648735369564,
    staking: 27521.339108975364,
  },
  {
    id: '1060',
    name: 'Entropyfi',
    address: 'polygon:0x28accA4ed2F6186c3D93e20e29e6e6a9Af656341',
    symbol: 'ERP',
    url: 'https://www.entropyfi.com',
    description:
      'SEntropyfi is a decentralized platform that can potentially enable Defi users to amplify their yield for 10x by playing lossless games (e.g., market prediction, insurance, etc.). ',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/entropyfi.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'entropyfi',
    cmcId: '11525',
    category: 'Prediction Market',
    chains: ['Polygon'],
    module: 'entropyfi/index.js',
    twitter: 'entropyfi',
    listedAt: 1640025906,
    slug: 'entropyfi',
    tvl: 294234.3608295232,
    chainTvls: { Polygon: 294234.3608295232, pool2: 49743.16276399429, 'Polygon-pool2': 49743.16276399429 },
    change_1h: 0.2543641496837665,
    change_1d: -4.868020166099427,
    change_7d: -2.8612219030053154,
    pool2: 49743.16276399429,
    fdv: 22659182,
    mcap: 0,
  },
  {
    id: '1271',
    name: 'Immortal',
    address: 'celo:0xE685d21b7B0FC7A248a6A8E03b8Db22d013Aa2eE',
    symbol: 'IMMO',
    url: 'https://www.immortaldao.finance/',
    description: 'Immortal is a decentralized reserve currency protocol on Celo blockchain based on the IMMO token.',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/immortal.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'immortaldao',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Celo'],
    module: 'immortal/index.js',
    twitter: 'ImmortalDAO_Fi',
    audit_links: ['https://github.com/ImmortalDAO-Fi/immortaldao-contracts/issues/3'],
    forkedFrom: ['Olympus DAO'],
    listedAt: 1642284185,
    slug: 'immortal',
    tvl: 293113.02114559873,
    chainTvls: { 'Celo-staking': 926450.0917609932, Celo: 293113.02114559873, staking: 926450.0917609932 },
    change_1h: -0.7063212849551803,
    change_1d: -0.9013193217767395,
    change_7d: -12.523176039409861,
    staking: 926450.0917609932,
    fdv: 268911,
    mcap: 0,
  },
  {
    id: '273',
    name: 'ACoconut',
    address: 'bsc:0x5B45A9be49c94236E127EFCc601b7e7a1a485d0a',
    symbol: 'AC',
    url: 'http://acoconut.fi/',
    description:
      'ACoconut provides the first cross-chain yield aggregator for BTC DeFi users. ACoconut v2 consists of two open protocols: BTC+ and acBTC. BTC+ is a liquidity and yield aggregator that optimizes and distributes yield on individual chain. acBTC is a decentralized bridge that carries BTC across chains.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/acoconut.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'acoconut',
    cmcId: '7382',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    module: 'acoconut/index.js',
    twitter: null,
    audit_links: ['https://docs.acoconut.fi/security'],
    slug: 'acoconut',
    tvl: 292372.5197809901,
    chainTvls: { Binance: 229084.53598612006, Ethereum: 63287.98379487 },
    change_1h: 0.15464206258037905,
    change_1d: 1.3407642114046325,
    change_7d: 10.400283524793764,
    fdv: 3057780,
    mcap: 293532,
  },
  {
    id: '1089',
    name: 'KACO Finance',
    address: 'bsc:0xf96429A7aE52dA7d07E60BE95A3ece8B042016fB',
    symbol: 'KAC',
    url: 'https://www.kaco.finance/',
    description: 'Fast growing Defi on BSC and Shiden! Fractionalize and farm NFTs.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kaco-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '12250',
    category: 'Dexes',
    chains: ['Shiden', 'Binance'],
    module: 'kaco/index.js',
    twitter: 'KacOFinance',
    audit_links: ['https://www.certik.com/projects/coinversation'],
    forkedFrom: ['Uniswap'],
    listedAt: 1640426184,
    slug: 'kaco-finance',
    tvl: 291208.7978855865,
    chainTvls: {
      Binance: 140656.84573908383,
      'Binance-staking': 41731.94642536951,
      Shiden: 150551.9521465027,
      'Shiden-staking': 0,
      staking: 41731.94642536951,
    },
    change_1h: 0.7274732816995595,
    change_1d: 3.502851299410665,
    change_7d: 9.60966606808941,
    staking: 41731.94642536951,
  },
  {
    id: '844',
    name: 'ZYXSwap',
    address: null,
    symbol: '-',
    url: 'https://app.zyxswap.com/#/swap',
    description: 'ZYXSwap is an AMM styled decentralized exchange (DEX) on ZYX Network',
    chain: 'ZYX',
    logo: 'https://icons.llama.fi/zyxswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['ZYX'],
    module: 'zyxswap/index.js',
    twitter: 'zyx_swap',
    forkedFrom: ['Uniswap'],
    listedAt: 1637030880,
    slug: 'zyxswap',
    tvl: 285693.5166763511,
    chainTvls: { ZYX: 285693.5166763511 },
    change_1h: 0.5851035791471446,
    change_1d: -3.309591754263522,
    change_7d: 22.690926769640967,
  },
  {
    id: '981',
    name: 'Kafe Finance',
    address: 'moonriver:0x52f04C806EB82930F40D410259b7AF8E18d3BDc9',
    symbol: 'KAFE',
    url: 'https://moon.kafe.finance/',
    description:
      'KafeFinance is a cross-chain yield optimizer that provides a single platform to auto-compound farms from a variety of projects on MoonRiver and Cronos. KafeFinance has also expanded the platform to include tools such as a portfolio dashboard and zapper.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kafefinance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'kafe-finance',
    cmcId: '12139',
    category: 'Yield',
    chains: ['Cronos', 'Moonriver'],
    module: 'kafefinance/index.js',
    twitter: 'kafefinance',
    audit_links: ['https://github.com/Tibereum/obelisk-audits/blob/main/Kukafe.pdf'],
    listedAt: 1638720617,
    slug: 'kafe-finance',
    tvl: 281972.7183,
    chainTvls: {
      Cronos: 208835.763318,
      Moonriver: 73136.954982,
      'Moonriver-pool2': 5427.512454,
      pool2: 5427.512454,
      'Moonriver-staking': 3089.15334,
      staking: 3089.15334,
    },
    change_1h: -0.057570176369083015,
    change_1d: -0.08109951264167137,
    change_7d: -5.327926437274726,
    staking: 3089.15334,
    pool2: 5427.512454,
  },
  {
    id: '969',
    name: 'DeHive',
    address: '0x62dc4817588d53a056cbbd18231d91ffccd34b2a',
    symbol: 'DHV',
    url: 'https://dehive.finance',
    description:
      'DeHive is a multi-chain asset management protocol that provides smart asset management of tokenized portfolios.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dehive.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dehive',
    cmcId: '8867',
    category: 'Indexes',
    chains: ['Ethereum', 'Binance', 'xDai', 'Polygon'],
    module: 'dehive/index.js',
    twitter: 'dehive_finance',
    audit_links: [
      'https://github.com/DeHive-finance/dehive-audits/blob/main/DeHive-Hacken-Audit.pdf',
      'https://github.com/DeHive-finance/dehive-audits/blob/main/DeHive-Zokyo-Audit.pdf',
    ],
    listedAt: 1638565180,
    slug: 'dehive',
    tvl: 280956.4772028201,
    chainTvls: {
      pool2: 92754.26998559228,
      'Polygon-pool2': 26737.26494294543,
      'xDai-pool2': 48929.6444588571,
      'Binance-pool2': 14738.056058871505,
      'Ethereum-pool2': 2349.3045249182533,
      Binance: 69429.96756571362,
      Ethereum: 131531.21676192532,
      xDai: 53258.02793223579,
      'Binance-staking': 45861.379536610555,
      'Ethereum-staking': 62997.47194479541,
      Polygon: 26737.264942945425,
      'Polygon-staking': 53330.61892046188,
      'xDai-staking': 0,
      staking: 162189.47040186785,
    },
    change_1h: 1.0885373113616197,
    change_1d: 0.9431767064671277,
    change_7d: -7.618341016852142,
    staking: 162189.47040186785,
    pool2: 92754.26998559228,
    mcap: 1079394,
  },
  {
    id: '960',
    name: '1BCH',
    address: '0x77d4b6e44a53bbda9a1d156b32bb53a2d099e53d',
    symbol: '1BCH',
    url: 'https://1bch.com',
    description: 'Creating a more complete Smartbch DEX and DeFi experience together.',
    chain: 'smartBCH',
    logo: 'https://icons.llama.fi/1bch.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['smartBCH'],
    forkedFrom: ['Uniswap'],
    module: '1bch/index.js',
    twitter: '1BCHOfficial',
    audit_links: ['https://github.com/interfinetwork/smart-contract-audits/blob/main/1BCH_AuditReport_InterFi.pdf'],
    listedAt: 1638461835,
    slug: '1bch',
    tvl: 277847.0098005113,
    chainTvls: {
      'smartBCH-masterchef': 0,
      'smartBCH-staking': 50470.977816559265,
      masterchef: 0,
      smartBCH: 277847.0098005113,
      staking: 50470.977816559265,
    },
    change_1h: 0.9590554916124177,
    change_1d: -5.017677766480304,
    change_7d: -6.976276626736137,
    staking: 50470.977816559265,
  },
  {
    id: '782',
    name: 'Polywhale Finance',
    address: 'polygon:0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b',
    symbol: 'KRILL',
    url: 'https://polywhale.finance/',
    description: 'Polywhale Finance is a 3rd generation yield farm on Polygon',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polywhale.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'polywhale',
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'polywhale/index.js',
    twitter: 'polywhalefi',
    audit_links: [
      'https://hacken.io/wp-content/uploads/2021/04/290432021_Polywhale_SC_Audit_Report-v2.pdf',
      'https://paladinsec.co/projects/polywhale/',
    ],
    listedAt: 1636043142,
    slug: 'polywhale-finance',
    tvl: 271399.00205850025,
    chainTvls: {
      Polygon: 271399.00205850025,
      pool2: 19302.299915842636,
      'Polygon-pool2': 19302.299915842636,
      'Polygon-staking': 25944.48433304594,
      staking: 25944.48433304594,
    },
    change_1h: 0.3061172422902416,
    change_1d: 1.54760137124768,
    change_7d: 11.299521956228986,
    staking: 25944.48433304594,
    pool2: 19302.299915842636,
    mcap: 0,
  },
  {
    id: '665',
    name: 'Multi-Chain Miner',
    address: null,
    symbol: '-',
    url: 'https://multichain-miner.net',
    description: 'Multi-Chain Miner is the largest DeFi Yield Mining project.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/multi-chain-miner.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance', 'Fantom', 'Polygon', 'Avalanche'],
    module: 'multichainMiner.js',
    twitter: 'multichainminer',
    listedAt: 1634038934,
    slug: 'multi-chain-miner',
    tvl: 269931.34606307524,
    chainTvls: {
      Binance: 123843.6078675059,
      Polygon: 49494.38689679785,
      Avalanche: 35904.520887198596,
      Fantom: 60688.830411572875,
    },
    change_1h: 0.6013796016231368,
    change_1d: 1.5507792944689385,
    change_7d: 7.651486346773126,
  },
  {
    id: '1488',
    name: 'DMD Finance',
    address: 'eos:DMD-eos-eosdmdtokens',
    symbol: 'DMD',
    url: 'https://dmd.finance',
    description: 'Diamond (dmd.finance) is the first yield farming on EOS.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/dmd-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dmd',
    cmcId: '6963',
    category: 'Staking',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'dmd/index.js',
    twitter: 'Dmd_Finance',
    language: 'C++',
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=6a6b302408026276c2e7b9adb64a660ea086e4f81af9c35f344ac4dac0ce16ff',
    ],
    listedAt: 1646159379,
    slug: 'dmd-finance',
    tvl: 268075.0352977615,
    chainTvls: { EOS: 268075.0352977615 },
    change_1h: 0.5302389436470492,
    change_1d: -0.6242161368887764,
    change_7d: 8.740645124036234,
    mcap: 0,
  },
  {
    id: '510',
    name: 'Gaj Finance',
    address: 'polygon:0xf4b0903774532aee5ee567c02aab681a81539e92',
    symbol: 'GAJ',
    url: 'https://gaj.finance',
    description:
      'Gaj, previously known as PolyGaj, is a multichain platform covering the two most promising subsects of cryptocurrency namely DeFi and NFT.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/gaj-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'gaj',
    cmcId: '11417',
    category: 'Yield',
    chains: ['Avalanche', 'Polygon'],
    module: 'GajFinance/index.js',
    twitter: 'GajFinance',
    audit_links: ['https://solidity.finance/audits/Gaj-NFT-Farming'],
    slug: 'gaj-finance',
    tvl: 267383.43705921876,
    chainTvls: {
      'Avalanche-staking': 672.3884533793165,
      pool2: 14733.005532744155,
      Avalanche: 267383.4370592187,
      'Polygon-pool2': 90.10188827447286,
      Polygon: 0,
      'Avalanche-pool2': 14642.903644469683,
      'Polygon-staking': 11912.132191582086,
      staking: 12584.520644961403,
    },
    change_1h: -0.011515020133060716,
    change_1d: -0.03735765886941067,
    change_7d: 0.28583719730444557,
    staking: 12584.520644961403,
    pool2: 14733.005532744155,
    fdv: 207971,
    mcap: 192780,
  },
  {
    id: '818',
    name: 'CashCow Protocol',
    address: 'bsc:0xe5bd6c5b1c2df8f499847a545838c09e45f4a262',
    symbol: 'MILK',
    url: 'https://cashcowprotocol.com',
    description:
      'CashCow Protocol is a community driven project with the vision of creating an NFT based Play to Earn Blockchain Game.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/cashcow-protocol.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '13439',
    category: 'Yield',
    chains: ['Binance'],
    module: 'cashcowprotocol/index.js',
    twitter: 'CashCowProtocol',
    listedAt: 1636445037,
    slug: 'cashcow-protocol',
    tvl: 267303.55935623863,
    chainTvls: { Binance: 267303.55935623863 },
    change_1h: 0.7607284309213043,
    change_1d: 3.1026334861609257,
    change_7d: 2.3526650648110916,
  },
  {
    id: '446',
    name: 'MiningTycoon',
    address: 'heco:0x8b70512b5248e7c1f0f6996e2fde2e952708c4c9',
    symbol: 'NT',
    url: 'https://nextype.finance',
    description:
      'NEXTYPE is an integrated application ecosystem among game, NFT and DeFi that can be cross-chain (cross platform), which will create a more efficient and free NFT asset swapping protocol for the blockchain world, and provide users with high-quality multiform Dapps based on games + DeFi, games + NFT synthesis, and NFT + DeFi.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/miningtycoon.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'nextype-finance',
    cmcId: '10742',
    category: 'Staking',
    chains: ['Binance'],
    module: 'miningtycoon/index.js',
    twitter: 'NEXTYPE1',
    audit_links: ['https://www.certik.org/projects/nextype'],
    slug: 'miningtycoon',
    tvl: 264289.9870962624,
    chainTvls: { Binance: 264289.9870962624, 'Binance-staking': 1086448.6519839307, staking: 1086448.6519839307 },
    change_1h: -0.2248013909276807,
    change_1d: -0.300964411728188,
    change_7d: -6.24058405010382,
    staking: 1086448.6519839307,
    mcap: 0,
  },
  {
    id: '786',
    name: 'Bagels Finance',
    address: 'bsc:0xbb238fce6e2ee90781cd160c9c6eaf3a4cfad801',
    symbol: 'BAGEL',
    url: 'https://app.bagels.finance',
    description:
      'Bagels Finance is the first cross-chain leveraged yield farming protocol(multi-chain). Users can deposit ETH, WBTC, USDT, BUSD, BNB and other assets into Bagels smart contracts to earn the highest APY in the market; Bagels aims to provide users with 2x-10x leverage to earn 2x-10x APY in yield of leveraged farming and transaction fees of LP providers. It provides better liquidity for BSC, Ethereum Layer2 and other ecosystems.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bagel-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bagel',
    cmcId: '10547',
    category: 'Yield',
    chains: ['Binance'],
    module: 'bagel-finance/index.js',
    twitter: 'BagelsFinance',
    audit_links: ['https://www.certik.org/projects/bagelsfinance'],
    oracles: ['TWAP'],
    listedAt: 1636058525,
    slug: 'bagels-finance',
    tvl: 262916.9268667158,
    chainTvls: { Binance: 262916.9268667158 },
    change_1h: -0.005349194327976647,
    change_1d: -0.3144215700575046,
    change_7d: -3.4656721027043034,
    fdv: 312813,
    mcap: 73866,
  },
  {
    id: '330',
    name: 'Aquarius',
    address: 'fantom:0x0f7ADb77B6334B9B5A3c4f78aAa073eA1D915bF6',
    symbol: 'AQU',
    url: 'https://aquarius.fi/',
    description:
      'Aquarius is a decentralized borrowing protocol with interest-free loans, high capital efficiency. we expect Aquarius to be the next MakerDao in the Fantom.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/aquarius.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'aquarius-fi',
    cmcId: '12559',
    category: 'CDP',
    chains: ['Fantom'],
    module: 'aquarius/index.js',
    twitter: 'Aquarius__Fi',
    audit_links: ['https://docs.aquarius.fi/security/audit-report'],
    forkedFrom: ['Liquity'],
    slug: 'aquarius',
    tvl: 259339.4914169289,
    chainTvls: { Fantom: 259339.4914169289 },
    change_1h: 0,
    change_1d: -0.38566964589487895,
    change_7d: 11.298546508449277,
    mcap: 0,
  },
  {
    id: '424',
    name: 'SafeDollar',
    address: 'polygon:0x66C59Dded4EF01a3412a8B019B6e41D4a8C49A35',
    symbol: 'SDO',
    url: 'https://www.safedollar.fi',
    description: 'The next generation algorithmic stablecoin on Polygon',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/safedollar.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'safedollar',
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'safedollar/index.js',
    twitter: 'safedollarfi',
    slug: 'safedollar',
    tvl: 258871.05446566988,
    chainTvls: { Polygon: 258871.05446566988 },
    change_1h: -0.1180365618094612,
    change_1d: 0.34918017988105987,
    change_7d: 1.7318967753626282,
    mcap: 0,
  },
  {
    id: '1292',
    name: 'Radial',
    address: 'fantom:0x79360aF49edd44F3000303ae212671ac94bB8ba7',
    symbol: 'RDL',
    url: 'https://www.radialfinance.com',
    description: 'Automated coordination strategies to maximize yield on Solidly. Inspired by Convex.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/radial.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'radial-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'radial/index.js',
    twitter: 'RadialFinance',
    forkedFrom: ['Uniswap'],
    listedAt: 1642629505,
    slug: 'radial',
    tvl: 254421.95307419053,
    chainTvls: { Fantom: 254421.95307419053 },
    change_1h: 0.8860145358900411,
    change_1d: 0.25921884669864426,
    change_7d: -4.676585517630215,
    fdv: 9047360,
    mcap: 0,
  },
  {
    id: '839',
    name: 'Hades Swap',
    address: 'polis:0xf1498e8103359fD96c5E08fb34b4C249B619025a',
    symbol: 'SOUL',
    url: 'https://hadesswap.finance',
    description: 'Hades Swap is a community-driven AMM and DeFi Hub for the Polis Olympus blockchain.',
    chain: 'Polis',
    logo: 'https://icons.llama.fi/hades-swap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Polis'],
    module: 'hadesswap/index.js',
    twitter: 'HadesSwap',
    forkedFrom: ['Uniswap'],
    listedAt: 1636925267,
    slug: 'hades-swap',
    tvl: 252170.91200371712,
    chainTvls: { Polis: 252170.91200371712 },
    change_1h: 0.01741912620056496,
    change_1d: 0.2278150272260575,
    change_7d: 3.4569964384450316,
  },
  {
    id: '1219',
    name: 'Trapeza Protocol',
    address: 'bsc:0x414a36a4b79Ee1D4b454AB798E4179ffC00b1641',
    symbol: 'FIDL',
    url: 'https://www.trapeza.finance/',
    description: 'Trapeza Protocol is a decentralized reserve currency protocol available on the BSC.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/trapeza-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'trapeza-protocol/index.js',
    twitter: 'TrapezaProtocol',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641752771,
    slug: 'trapeza-protocol',
    tvl: 250128.6353318125,
    chainTvls: { Binance: 250128.6353318125, 'Binance-staking': 14319.310222271439, staking: 14319.310222271439 },
    change_1h: 0,
    change_1d: 3.2608421998154142,
    change_7d: -1.5621568910202086,
    staking: 14319.310222271439,
  },
  {
    id: '478',
    name: 'Yapeswap',
    address: '0x757bc268bd50da88b2d0cf1966652b18e56ca803',
    symbol: 'YAPE',
    url: 'https://yape.exchange',
    description:
      'Yapeswap is an Automated Market Maker that enables liquidity providers to earn additional yield by investing idle token reserves from liquidity pools into yield farming and aggregator services hosted by Yearn Finance. The protocol is maintained by independent developers and governed by veYAPE holders.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/yapeswap.png',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'yapeswap/index.js',
    twitter: 'yapeswap',
    forkedFrom: ['Uniswap'],
    slug: 'yapeswap',
    tvl: 249078.20080605897,
    chainTvls: { Ethereum: 249078.20080605897 },
    change_1h: 0,
    change_1d: 8.001892402186868,
    change_7d: 17.73560005649253,
  },
  {
    id: '725',
    name: 'BiShares',
    address: 'fantom:0xd2f38621c3C65300ECabA7020c05d1350f9C265c',
    symbol: 'BISON',
    url: 'https://bishares.finance',
    description:
      'BiShares provides safe crypto diversification through decentralized Exchange Traded Funds (dETFs) on multiple chains. Bison is BiShares governance token and is used to vote for all the proposals related to the protocol',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bishares.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bishares',
    cmcId: '10994',
    category: 'Indexes',
    chains: ['Binance', 'Fantom'],
    module: 'bishares/index.js',
    twitter: 'BiSharesFinance',
    audit_links: [
      'https://bishares.finance/assets/docs/bishares-defiyield-audit.pdf',
      'https://bishares.finance/assets/docs/bishares-gordian-audit.pdf',
    ],
    forkedFrom: ['Indexed Finance'],
    listedAt: 1635467248,
    slug: 'bishares',
    tvl: 248704.0364172,
    chainTvls: { Binance: 239123.69215098, Fantom: 9580.34426622 },
    change_1h: 0.008152700891713494,
    change_1d: 0.6546583957249652,
    change_7d: -11.78109489420143,
    mcap: 73187,
  },
  {
    id: '299',
    name: 'Levinswap',
    address: 'xdai:0x1698cd22278ef6e7c0df45a8dea72edbea9e42aa',
    symbol: 'LEVIN',
    url: 'https://levinswap.org/',
    description: 'AMM DEX on xDai powered by the LightningDAO',
    chain: 'xDai',
    logo: 'https://icons.llama.fi/levinswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'levin',
    cmcId: '1353',
    category: 'Dexes',
    chains: ['xDai'],
    module: 'levinswap/index.js',
    twitter: 'levinswap',
    forkedFrom: ['Uniswap'],
    slug: 'levinswap',
    tvl: 247617.72284921198,
    chainTvls: { xDai: 247617.72284921198, staking: 7896.897767293438, 'xDai-staking': 7896.897767293438 },
    change_1h: 0.0008746625074280701,
    change_1d: 6.231337617195521,
    change_7d: null,
    staking: 7896.897767293438,
    mcap: 29335,
  },
  {
    id: '1277',
    name: 'Paprprintr',
    address: 'bsc:0x246475df8703be0c2ba2f8d0fb7248d95cc1ba26',
    symbol: 'PAPR',
    url: 'https://paprprintr.finance',
    description:
      'We aim to connect DeFi with the real world. paprprintr at its core, is an algorithmic stablecoin driven by elastic expansion and burn.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/paprprintr.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'paprprintr-finance',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Kucoin', 'Binance', 'Aurora', 'Polygon', 'Fantom', 'OKExChain'],
    module: 'paprprintr/index.js',
    twitter: 'paprprintr',
    audit_links: ['https://docs.paprprintr.finance/audit'],
    forkedFrom: ['Basis Cash'],
    listedAt: 1642453213,
    slug: 'paprprintr',
    tvl: 247403.9435092893,
    chainTvls: {
      'Fantom-staking': 1467.141036178818,
      'Polygon-pool2': 98172.41966359956,
      'Aurora-pool2': 15593.396157442194,
      'Binance-pool2': 32403.920756289266,
      Binance: 33778.51362161695,
      'Binance-staking': 15798.023231257175,
      'Kucoin-pool2': 38060.91354844342,
      Fantom: 10772.939544553914,
      staking: 89458.77007195291,
      'Aurora-staking': 4160.707565672112,
      'OKExChain-staking': 0,
      OKExChain: 23.74986575325,
      pool2: 208547.92980299843,
      Kucoin: 176729.25842742863,
      Aurora: 13261.491730373249,
      'Kucoin-staking': 53634.77868385237,
      Polygon: 12837.990319563318,
      'Polygon-staking': 14398.119554992447,
      'Fantom-pool2': 24317.279677223985,
      'OKExChain-pool2': 0,
    },
    change_1h: 0.19818161736660045,
    change_1d: -7.039386540076123,
    change_7d: -6.406172135694348,
    staking: 89458.77007195291,
    pool2: 208547.92980299843,
    mcap: 0,
  },
  {
    id: '534',
    name: 'Thales',
    address: '0x03e173ad8d1581a4802d3b532ace27a62c5b81dc',
    symbol: 'THALES',
    url: 'https://thales.market',
    description:
      'Thales is an Ethereum protocol that allows the creation of peer-to-peer parimutuel markets that anyone can join. This building block is the foundation of novel on-chain initiatives, from a platform for AMM-based positional markets to immersive gamified experiences, and much more.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/thales.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'thales',
    cmcId: '11973',
    category: 'Options',
    chains: ['Optimism', 'Ethereum'],
    module: 'thales/index.js',
    twitter: 'thalesmarket',
    audit_links: ['https://iosiro.com/audits/thales-airdrop-and-staking-smart-contract-audit'],
    slug: 'thales',
    tvl: 244726.88710983287,
    chainTvls: {
      Optimism: 239276.78212783288,
      'Optimism-pool2': 511190.0487042127,
      'Optimism-staking': 0,
      pool2: 590735.0458402835,
      'Ethereum-pool2': 79544.99713607084,
      Ethereum: 5450.104982,
      staking: 0,
    },
    change_1h: 0.049130673202142816,
    change_1d: 0.5244962282512518,
    change_7d: 0.7225726627251561,
    staking: 0,
    pool2: 590735.0458402835,
    fdv: 51425371,
    mcap: 8166151,
  },
  {
    id: '993',
    name: 'O2 DAO',
    address: 'avax:0xaa2439dbad718c9329a5893a51a708c015f76346',
    symbol: 'OTWO',
    url: 'https://app.otwodao.com',
    description: 'A decentralized treasury-backed currency protocol backed by OHM.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/o2-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'o2-dao/index.js',
    twitter: '0xO2DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638915245,
    slug: 'o2-dao',
    tvl: 243341.95849276744,
    chainTvls: { 'Avalanche-staking': 22294.840271716886, Avalanche: 243341.95849276744, staking: 22294.840271716886 },
    change_1h: 0.20033914681843612,
    change_1d: -0.8437340798685113,
    change_7d: 28.99958711109369,
    staking: 22294.840271716886,
  },
  {
    id: '1579',
    name: 'BeefStake',
    address: null,
    symbol: '-',
    url: 'https://stake.vitc.org',
    description:
      'BeefStake is a staking platform on the Vite network! Stake a multitude of cross-chain tokens for rewards.',
    chain: 'Vite',
    logo: 'https://icons.llama.fi/beefstake.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Vite'],
    oracles: [],
    forkedFrom: ['Viva'],
    module: 'beefstake/index.js',
    twitter: 'HexaCapital',
    language: 'Solidity++',
    listedAt: 1648148802,
    slug: 'beefstake',
    tvl: 242267.8613294199,
    chainTvls: { Vite: 242267.8613294199 },
    change_1h: -0.09554464239138838,
    change_1d: 837.443747607023,
    change_7d: null,
  },
  {
    id: '994',
    name: 'Life DAO',
    address: 'avax:0x5684a087c739a2e845f4aaaabf4fbd261edc2be8',
    symbol: 'LF',
    url: 'https://lifedao.finance',
    description:
      "LifeDAO is a decentralised, reserve currency protocol built on the Avalanche network by the LifeDAO team. The LF (Also sometimes called LIFE) token is the protocol's main asset.",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/life-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'life-dao',
    cmcId: '15289',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'lifedao/index.js',
    twitter: 'Life_DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638941466,
    slug: 'life-dao',
    tvl: 241733.07097163852,
    chainTvls: { 'Avalanche-staking': 1472257.3445603165, Avalanche: 241733.07097163852, staking: 1472257.3445603165 },
    change_1h: -0.025411175086077264,
    change_1d: -0.61300102541108,
    change_7d: -4.472467617147345,
    staking: 1472257.3445603165,
    fdv: 46318,
    mcap: 0,
  },
  {
    id: '903',
    name: 'MiniSwap',
    address: '0x4d953cf077c0c95ba090226e59a18fcf97db44ec',
    symbol: 'MINI',
    url: 'https://www.miniswap.org',
    description:
      'We introduce MiniSwap to enable a connected network of DeFi ecosystems. MiniSwap provides a decentralised platform for exercising financial products.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/miniswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'mini',
    cmcId: '6405',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'minidex.js',
    twitter: 'Mini_Swap',
    forkedFrom: ['Uniswap'],
    listedAt: 1637725995,
    slug: 'miniswap',
    tvl: 236598.42926030722,
    chainTvls: { Ethereum: 236598.42926030722 },
    change_1h: 1.4897278456245289,
    change_1d: 4.478971994063642,
    change_7d: 11.63516078017146,
    mcap: 0,
  },
  {
    id: '755',
    name: 'Arrow',
    address: null,
    symbol: '-',
    url: 'https://arrowprotocol.com',
    description:
      'Arrow allows individuals, protocols, and DAOs on Solana to launch staking derivatives which redirect yield to a different address.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/arrow.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Solana'],
    module: 'arrow/index.js',
    twitter: 'ArrowProtocol',
    listedAt: 1635961535,
    slug: 'arrow',
    tvl: 236478.79172629214,
    chainTvls: { Solana: 236478.79172629214 },
    change_1h: -0.1544286135780908,
    change_1d: 0.07975946561933256,
    change_7d: -99.17467733182852,
  },
  {
    id: '1467',
    name: 'Farmtom',
    address: 'fantom:0x4243cCC302A98B577678d87A53c75593199315A3',
    symbol: 'FRTM',
    url: 'https://farmtom.com',
    description: 'Third Gen Farming built on Fantom for dividends, yields, and buybacks',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/farmton.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'farmton.js',
    twitter: 'FarmtomFi',
    listedAt: 1645731411,
    slug: 'farmtom',
    tvl: 236109.70003202127,
    chainTvls: {
      pool2: 4074.430685005639,
      'Fantom-staking': 746.0518844368024,
      masterchef: 236109.70003202127,
      'Fantom-masterchef': 236109.70003202127,
      'Fantom-pool2': 4074.430685005639,
      Fantom: 236109.70003202127,
      staking: 746.0518844368024,
    },
    change_1h: 0.0009631710136375204,
    change_1d: 0.40282988778915296,
    change_7d: -12.463543686357937,
    staking: 746.0518844368024,
    pool2: 4074.430685005639,
  },
  {
    id: '170',
    name: 'FinNexus',
    address: '0xef9cd7882c067686691b6ff49e650b43afbbcc6b',
    symbol: 'FNX',
    url: 'https://finnexus.io/',
    description:
      'FinNexus Options makes it easy to buy and exercise BTC, ETH, BNB, LINK, SNX, MKR, WAN options with pooled liquidity in a secure and decentralized way on Ethereum, BSC & Wanchain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/finnexus.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'finnexus',
    cmcId: '5712',
    category: 'Options',
    chains: ['Ethereum', 'Binance', 'Wanchain'],
    module: 'finnexus/index.js',
    twitter: 'fin_nexus',
    audit_links: ['https://github.com/Phoenix-Finance/Pdfs/blob/master/PeckShield-Audit-FinnexusOptionsV1.0.pdf'],
    oracles: ['Chainlink', 'Band'],
    slug: 'finnexus',
    tvl: 235018.51952975534,
    chainTvls: { Binance: 46085.52749795345, Wanchain: 5476.862019513066, Ethereum: 183456.13001228884 },
    change_1h: -0.07104379313592801,
    change_1d: 0.31496162365205294,
    change_7d: -0.04962526849099902,
    fdv: 1336688,
    mcap: 2708697,
  },
  {
    id: '928',
    name: 'Fortress',
    address: 'avax:0xf6d46849db378ae01d93732585bec2c4480d1fd5',
    symbol: 'FORT',
    url: 'https://app.fortressdao.finance',
    description: 'An adventure in treasure management on Avalanche',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/fortress-DAO.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'fortressdao',
    cmcId: '14861',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'fortress/index.js',
    twitter: 'FortressDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638132365,
    slug: 'fortress',
    tvl: 229799.41446509637,
    chainTvls: { 'Avalanche-staking': 2348947.246218313, Avalanche: 229799.41446509637, staking: 2348947.246218313 },
    change_1h: 0,
    change_1d: -2.0667847840363436,
    change_7d: -8.924919306112017,
    staking: 2348947.246218313,
    mcap: 1919627,
  },
  {
    id: '481',
    name: 'SonicSwap',
    address: 'harmony:one1rczu3d57gy5ff87w795pr2qeauh4t5e7hqnyza',
    symbol: 'SONIC',
    url: 'https://sonicswap.io',
    description: 'DeFi project on Harmony protocol that listens to the community.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/sonicswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'sonicswap/index.js',
    twitter: 'SonicSwapIO',
    forkedFrom: ['Uniswap'],
    slug: 'sonicswap',
    tvl: 228811.0559895282,
    chainTvls: { Harmony: 228811.0559895282 },
    change_1h: 0.5239271990468524,
    change_1d: 2.611389113148377,
    change_7d: 3.7541693479786744,
  },
  {
    id: '1322',
    name: 'Alligator',
    address: 'avax:0x43C812Ba28cb061b1Be7514145A15C9E18a27342',
    symbol: 'GTR',
    url: 'https://www.alligator.exchange/',
    description: 'Alligator is a decentralized trading platform on Avalanche',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/alligator.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'alligator-exchange/index.js',
    twitter: 'alligator_defi',
    forkedFrom: ['Uniswap'],
    listedAt: 1643391258,
    slug: 'alligator',
    tvl: 227874.2989219465,
    chainTvls: { 'Avalanche-staking': 12393.909306109967, Avalanche: 227874.2989219465, staking: 12393.909306109967 },
    change_1h: 0.5085827845569497,
    change_1d: -8.481638010335672,
    change_7d: -11.75335936305531,
    staking: 12393.909306109967,
  },
  {
    id: '263',
    name: 'QIAN',
    address: '0x65d9bc970aa9b2413027fa339f7f179b3f3f2604',
    symbol: 'KUN',
    url: 'https://qian.finance/',
    description:
      'QIAN protocol provides a set of open architecture allowing anyone to generate stable-value tokens, anytime and anywhere.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/qian.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'chemix-ecology-governance-token',
    cmcId: '7721',
    category: 'Synthetics',
    chains: ['Ethereum'],
    module: 'qian/index.js',
    twitter: null,
    audit_links: ['https://qian.finance/PeckShield-Audit-Report-QIAN2-v1.0.pdf'],
    slug: 'qian',
    tvl: 227024.33445201372,
    chainTvls: { Ethereum: 227024.33445201372 },
    change_1h: 0,
    change_1d: 0.2848049537040396,
    change_7d: 1.1205017439380498,
    mcap: 0,
  },
  {
    id: '841',
    name: 'SWAPP',
    address: 'cronos:0x245a551ee0F55005e510B239c917fA34b41B3461',
    symbol: 'SWAPP',
    url: 'https://dex.swapp.ee/#/swap',
    description:
      'SWAPP Protocol is a decentralized, fairly launched, ETH-paired utility token used to both facilitate yield farming rewards in the Swapp DeFi platform as well as serve as the form of rewards within the Swapp smartphone app (on ios and android).',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/swapp.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'swapp',
    cmcId: '10783',
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'swapp/index.js',
    twitter: 'SwappFi',
    forkedFrom: ['Uniswap'],
    listedAt: 1637002081,
    slug: 'swapp',
    tvl: 226998.12522612896,
    chainTvls: { Cronos: 226998.12522612896 },
    change_1h: 0,
    change_1d: -0.19058032225623833,
    change_7d: 7.6215366006159115,
    mcap: 0,
  },
  {
    id: '865',
    name: 'Seeder Finance',
    address: 'bsc:0x1CbDdf83De068464Eba3a4E319bd3197a7EEa12c',
    symbol: 'LEAF',
    url: 'https://seeder.finance',
    description: 'A fair-launch yield farming & play to earn protocol on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/seeder-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'seeder-finance',
    cmcId: '11120',
    category: 'Yield',
    chains: ['Binance'],
    module: 'seeder/index.js',
    twitter: 'SeederFinance',
    audit_links: ['https://inspex.co/reports/Inspex_AUDIT2021005_SeederFinance_Core_FullReport_v1.0.pdf'],
    listedAt: 1637350422,
    slug: 'seeder-finance',
    tvl: 226325.1819722129,
    chainTvls: { Binance: 226325.1819722129 },
    change_1h: 0.6580529957959271,
    change_1d: 0.22208581620073176,
    change_7d: 11.169236822789102,
    mcap: 0,
  },
  {
    id: '1299',
    name: 'FTMguru',
    address: 'fantom:0xf43Cc235E686d7BC513F53Fbffb61F760c3a1882',
    symbol: 'ELITE',
    url: 'https://ftm.guru',
    description:
      'A Multi-faceted growth hacker for indie projects, ftm.guru provides numerous Tools, Services and niche utility-applications to users and bespoke product customization, liquidity enhancement and data analytics products to De-Fi innovators',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/ftmguru.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ftm-guru',
    cmcId: '13436',
    category: 'Options',
    chains: ['Fantom'],
    module: 'ftmguru/index.js',
    twitter: 'FTM1337',
    oracles: ['Band'],
    listedAt: 1642725601,
    slug: 'ftmguru',
    tvl: 226195.1801361759,
    chainTvls: { pool2: 0, 'Fantom-staking': 0, 'Fantom-pool2': 0, staking: 0, Fantom: 226195.1801361759 },
    change_1h: 0.7400703452973261,
    change_1d: -0.25191666249178013,
    change_7d: 8.957300973143361,
    staking: 0,
    pool2: 0,
    fdv: 3004521,
    mcap: 0,
  },
  {
    id: '444',
    name: 'PolyDEX-Cryption Network',
    address: 'polygon:0xd1e6354fb05bf72a8909266203dab80947dceccf',
    symbol: 'CNT',
    url: 'https://www.polydex.org',
    description: 'Redefining your DEX Experience. Completely gasless, instant and cross chain AMM DEX',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polydex-cryption-network.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cryption-network',
    cmcId: '9747',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'polyDEX-cryption-network/index.js',
    twitter: 'Polydex_',
    audit_links: ['https://www.certik.org/projects/cryptionnetwork'],
    forkedFrom: ['Uniswap'],
    slug: 'polydex-cryption-network',
    tvl: 225400.9698914086,
    chainTvls: { Polygon: 225400.9698914086 },
    change_1h: 0.3465808540140074,
    change_1d: 16.665529697470532,
    change_7d: 27.084995968571747,
    fdv: 2251857,
    mcap: 213154,
  },
  {
    id: '790',
    name: 'PolygonFarm Finance',
    address: 'polygon:0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
    symbol: 'SPADE',
    url: 'https://polygonfarm.finance/',
    description:
      'PolygonFarm Finance is a community led next generation stable-yield farming protocol established exclusively on the Polygon ecosystem.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polygonfarm-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'polygonfarm-finance',
    cmcId: '11017',
    category: 'Yield',
    chains: ['Polygon'],
    module: 'polygonfarm-finance/index.js',
    twitter: 'PolygonFarmFi',
    audit_links: [
      'https://github.com/TechRate/Smart-Contract-Audits/blob/main/SPADE%20Standart%20Smart%20Contract%20Security%20Audit.pdf',
      'https://paladinsec.co/projects/polygonfarm/',
      'https://rugdoc.io/project/polygonfarm/',
    ],
    listedAt: 1636147585,
    slug: 'polygonfarm-finance',
    tvl: 224683.39579067117,
    chainTvls: {
      pool2: 12358.996155681845,
      'Polygon-pool2': 12358.996155681845,
      masterchef: 224683.39579067117,
      'Polygon-masterchef': 224683.39579067117,
      Polygon: 224683.39579067117,
      'Polygon-staking': 4515.872453266499,
      staking: 4515.872453266499,
    },
    change_1h: 2.842170943040401e-14,
    change_1d: 1.4525801102657425,
    change_7d: 11.043889685429818,
    staking: 4515.872453266499,
    pool2: 12358.996155681845,
    mcap: 0,
  },
  {
    id: '911',
    name: 'SumSwap',
    address: '0x043c308bb8a5ae96d0093444be7f56459f1340b1',
    symbol: 'SUM',
    url: 'https://www.sumswap.org/#/',
    description:
      'The Sumswap unites decentralized protocols whose synergy enables the most lucrative, fastest and protected operations in the DeFi space.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/sumswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'sumswap',
    cmcId: '11208',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'sumswap/index.js',
    twitter: 'sum_swap',
    audit_links: ['https://www.certik.com/projects/sumswap'],
    forkedFrom: ['Uniswap'],
    listedAt: 1637813174,
    slug: 'sumswap',
    tvl: 214211.25540048836,
    chainTvls: { Ethereum: 214211.25540048836 },
    change_1h: 0,
    change_1d: 9.006988806290494,
    change_7d: -2.55003174168246,
    mcap: 0,
  },
  {
    id: '1000',
    name: 'Brinc Finance',
    address: 'arbitrum:0xB5de3f06aF62D8428a8BF7b4400Ea42aD2E0bc53',
    symbol: 'BRC',
    url: 'https://brinc.fi',
    description:
      'The Brinc protocol allows for the decentralized creation and governance of a token supply using a bonding curve algorithm that is hard-coded into solidity smart contracts.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/brinc.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Arbitrum'],
    module: 'brinc/index.js',
    twitter: 'BrincFi',
    audit_links: ['https://www.certik.com/projects/brincfi'],
    listedAt: 1639098493,
    slug: 'brinc-finance',
    tvl: 213913.74048798758,
    chainTvls: { Arbitrum: 213913.74048798758 },
    change_1h: -0.2275449101796312,
    change_1d: 0.18611522474468245,
    change_7d: -0.46390763764200926,
  },
  {
    id: '1483',
    name: 'V3S Finance',
    address: 'cronos:0xC7e99a2f064Cf86aF91Db252a9D8Bc16e6fE7427',
    symbol: 'V3S',
    url: 'https://www.v3s.finance',
    description:
      'V3S Finance, the first ecosystem running around an algorithmic token pegged to VVS on Cronos chain. Build by a team of VVS Diamond holders',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/v3s-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Cronos'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'v3s-finance/index.js',
    twitter: 'v3sfinance',
    listedAt: 1646099642,
    slug: 'v3s-finance',
    tvl: 209717.57078564996,
    chainTvls: {
      Cronos: 209717.57078564996,
      pool2: 1032250.0480015036,
      'Cronos-pool2': 1032250.0480015036,
      'Cronos-staking': 1569458.320279369,
      staking: 1569458.320279369,
    },
    change_1h: -0.02222651711744561,
    change_1d: -9.224967006936069,
    change_7d: -82.78189484852618,
    staking: 1569458.320279369,
    pool2: 1032250.0480015036,
  },
  {
    id: '529',
    name: 'ShadeCash',
    address: 'fantom:0x3a3841f5fa9f2c283ea567d5aeea3af022dd2262',
    symbol: 'SHADE',
    url: 'https://shade.cash',
    description: 'Shade.Cash is a decentralized protocol for private transactions on the Fantom Opera network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/shadecash.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'shade-cash',
    cmcId: null,
    category: 'Privacy',
    chains: ['Fantom'],
    module: 'shadecash/index.js',
    twitter: 'ShadeCash_',
    forkedFrom: ['Tornado Cash'],
    slug: 'shadecash',
    tvl: 204218.18349999998,
    chainTvls: {
      pool2: 92214.15336280945,
      'Fantom-staking': 2278.7713944886023,
      'Fantom-pool2': 92214.15336280945,
      staking: 2278.7713944886023,
      Fantom: 204218.18349999998,
    },
    change_1h: 0,
    change_1d: 0.49222900557548144,
    change_7d: 9.32824036423878,
    staking: 2278.7713944886023,
    pool2: 92214.15336280945,
    fdv: 1375888,
    mcap: 0,
  },
  {
    id: '1381',
    name: 'Bright Union',
    address: '0xbeab712832112bd7664226db7cd025b153d3af55',
    symbol: 'BRIGHT',
    url: 'https://brightunion.io',
    description: 'DeFi coverage aggregator',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bright-union.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bright-union',
    cmcId: '11193',
    category: 'Insurance',
    chains: ['Ethereum'],
    oracles: ['Chainlink'],
    module: 'bright-union/index.js',
    twitter: 'bright_union',
    language: 'Solidity',
    audit_links: [
      'https://github.com/Bright-Union/security/blob/199fa66e474d4cbc003db3b0ed11bda105d32a6d/audits/Bright%20Union%20BV_audit.pdf',
      'https://github.com/Bright-Union/security/blob/199fa66e474d4cbc003db3b0ed11bda105d32a6d/audits/Bright%20Union%20Staking_audit.pdf',
    ],
    listedAt: 1644447080,
    slug: 'bright-union',
    tvl: 203222.933053989,
    chainTvls: {
      Ethereum: 203222.933053989,
      pool2: 167150.09691038012,
      'Ethereum-staking': 335745.239873522,
      staking: 335745.239873522,
      'Ethereum-pool2': 167150.09691038012,
    },
    change_1h: -0.008655801793736373,
    change_1d: 0.5440163211186047,
    change_7d: 10.454841599676953,
    staking: 335745.239873522,
    pool2: 167150.09691038012,
    fdv: 8282935,
    mcap: 1341269,
  },
  {
    id: '820',
    name: 'Doki Doki Finance',
    address: '0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544',
    symbol: 'DOKI',
    url: 'https://dokidoki.finance/',
    description: 'Doki Doki is a gamified marketplace platform that combines NFTs, Gachapon Machines, & yield-farming.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dokidoki.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'doki-doki-finance',
    cmcId: '7376',
    category: 'Yield',
    chains: ['Ethereum', 'Polygon'],
    module: 'dokidoki/index.js',
    twitter: 'dokidokifinance',
    audit_links: ['https://defiyield.app/audit-database/defiyield/doki_doki_finance'],
    listedAt: 1636475962,
    slug: 'doki-doki-finance',
    tvl: 200861.47468407475,
    chainTvls: {
      pool2: 140764.5989169848,
      'Polygon-pool2': 53675.11889996496,
      'Ethereum-pool2': 87089.48001701983,
      Ethereum: 200861.47468407475,
      'Ethereum-staking': 230686.36291466147,
      Polygon: 0,
      'Polygon-staking': 14168.206334916957,
      staking: 244854.56924957843,
    },
    change_1h: 1.1788612863053487,
    change_1d: 3.2122585214380877,
    change_7d: 15.301400075714298,
    staking: 244854.56924957843,
    pool2: 140764.5989169848,
    fdv: 477937,
    mcap: 476999,
  },
  {
    id: '1553',
    name: 'BaksDAO',
    address: 'bsc:0x8bd22c61b409587d24d87fb56e88bd84e5d91596',
    symbol: 'BAKS',
    url: 'https://baksdao.com',
    description:
      'BaksDAO is a DeFi platform where you can get a perpetual loan secured by cryptocurrencies or get high payback in the currency of an asset by depositing.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/baksdao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'baksdao',
    cmcId: '18603',
    category: 'Lending',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'baksdao/index.js',
    twitter: 'BaksDao',
    listedAt: 1647396396,
    slug: 'baksdao',
    tvl: 199308.78340397682,
    chainTvls: { Binance: 199308.78340397682 },
    change_1h: 0,
    change_1d: 2.920370274490594,
    change_7d: 10.932036285386062,
    mcap: 0,
  },
  {
    id: '445',
    name: 'CobraSwap',
    address: 'bsc:0x2c449ba613873e7b980faf2b686207d7bd205541',
    symbol: 'COBRA',
    url: 'https://cobra.exchange',
    description:
      "CobraSwap is VenomDAO's decentralized exchange and yield farming protocol on the Binance Smart Chain. Following the recent success establishing ViperSwap as the leading DEX on Harmony Protocol. Featuring a unique rewards mechanism inspired by BAO Finance, CobraSwap provides some of the most competitive yields for liquidity providers.",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/cobraswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'cobraswap/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'VenomDAO',
    slug: 'cobraswap',
    tvl: 195968.21619311091,
    chainTvls: { Binance: 195968.21619311091 },
    change_1h: -2.664180430592026,
    change_1d: -1.0633170532033773,
    change_7d: -6.3797494993178105,
  },
  {
    id: '957',
    name: 'Flame DeFi',
    address: 'tezos:KT1Wa8yqRBpFCusJWgcQyjhRz7hUQAmFxW7j',
    symbol: 'FLAME',
    url: 'https://flamedefi.io',
    description: 'Tezos DeFi platform with YieldFarming, NFT, FlameDex, Decentralized exchange, Gaming and casino',
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/flamedefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'flame',
    cmcId: null,
    category: 'Yield',
    chains: ['Tezos'],
    module: 'flamedefi/index.js',
    twitter: 'FlameDeFi',
    audits_link: ['https://flamedefi.io/images/functori-flamedefi-dex-report-2.1.pdf'],
    listedAt: 1638377772,
    slug: 'flame-defi',
    tvl: 194993.6446185,
    chainTvls: { Tezos: 194993.6446185 },
    change_1h: 1.0367044638235825,
    change_1d: -3.782094316020192,
    change_7d: 6.4247156496382445,
    fdv: 118441,
    mcap: 0,
  },
  {
    id: '471',
    name: 'Complus Network',
    address: 'avax:0x3711c397b6c8f7173391361e27e67d72f252caad',
    symbol: 'COM',
    url: 'https://complus.exchange',
    description:
      'Complus Network is an (AMM) decentralized crypto exchange (DEX) and yield farming protocol on Avalanche.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/complus-network.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'complus-network',
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    forkedFrom: ['Uniswap'],
    module: 'complus/index.js',
    twitter: 'complusnetwork',
    slug: 'complus-network',
    tvl: 194473.45832412824,
    chainTvls: { Avalanche: 194473.45832412824 },
    change_1h: 0.48268396121093815,
    change_1d: 2.233529123679375,
    change_7d: 3.7498142311138167,
    mcap: 0,
  },
  {
    id: '1047',
    name: 'UnusDao',
    address: 'bsc:0xb91ec4f9d7d12a1ac145a7ae3b78afb45856c9c8',
    symbol: 'UDO',
    url: 'https://unusdao.finance/',
    description: 'A decentralized reserve currency protocol on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/unusdao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'unus-dao',
    cmcId: '15196',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'unusdao/index.js',
    twitter: 'UnusDao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639821224,
    slug: 'unusdao',
    tvl: 190511.83541745215,
    chainTvls: { Binance: 190511.83541745215, 'Binance-staking': 334488.9009905457, staking: 334488.9009905457 },
    change_1h: -0.276758348478495,
    change_1d: 1.7214461930382896,
    change_7d: 2.756729210084984,
    staking: 334488.9009905457,
    fdv: 85752,
    mcap: 0,
  },
  {
    id: '1548',
    name: 'Chaotic Finance',
    address: 'moonriver:0xf4c6850B6e3288E81Be542909b76865a0BdF9585',
    symbol: 'CHAOS',
    url: 'https://chaotic.finance/',
    description: 'The first community-driven delta-one asset protocol on Moonriver',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/chaotic-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Derivatives',
    chains: ['Moonriver'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'chaotic.js',
    twitter: 'Chaotic_Finance',
    listedAt: 1647374644,
    slug: 'chaotic-finance',
    tvl: 185841.8077010501,
    chainTvls: { Moonriver: 185841.8077010501 },
    change_1h: 0.49380017873991733,
    change_1d: -2.262820676980155,
    change_7d: 85.69018297069329,
  },
  {
    id: '1067',
    name: 'Dios Finance',
    address: 'bsc:0x08eEcF5d03bDA3dF2467F6AF46b160c24D931DE7',
    symbol: 'DIOS',
    url: 'https://dios.finance/',
    description: 'Dios Finance is a Community-owned Ecosystem backed by Crypto Treasury on Binance Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/dios-finance.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'dios-finance',
    cmcId: '15989',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'diosfinance/index.js',
    twitter: 'DiosFinance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640083689,
    slug: 'dios-finance',
    tvl: 182148.03478253196,
    chainTvls: { Binance: 182148.03478253196, 'Binance-staking': 746800.7383967616, staking: 746800.7383967616 },
    change_1h: -0.16624868086194056,
    change_1d: -8.704491008444052,
    change_7d: -74.58071489926938,
    staking: 746800.7383967616,
    fdv: 1023234,
    mcap: 856922,
  },
  {
    id: '989',
    name: 'KryptoDex',
    address: 'cronos:0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
    symbol: 'KRX',
    url: 'https://kryptodex.org',
    description:
      'KryptoDEX is powered by InterDeFi with many winning components: a DEX with a very attractive trading fee, a launchpad with great IDO track record, a NFT marketplace, unique 3-type referral system and more.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/kryptodex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'kryptodex/index.js',
    twitter: 'KryptoDex',
    audit_links: [
      'https://github.com/polydex-fi/polydex-swap-core/blob/5fdd6b85550235800cd980ccb8fab9bd5247a7af/PolydexSwap.pdf',
    ],
    forkedFrom: ['Uniswap'],
    listedAt: 1638882307,
    slug: 'kryptodex',
    tvl: 182097.37842900347,
    chainTvls: { Cronos: 182097.37842900347, 'Cronos-staking': 62901.39700686227, staking: 62901.39700686227 },
    change_1h: 0.0716442871291747,
    change_1d: 0.01153889363152416,
    change_7d: 2.5443150942194848,
    staking: 62901.39700686227,
  },
  {
    id: '371',
    name: 'KokomoSwap',
    address: 'bsc:0x9a7B87651C6C93cedb831B2B3b5549142a813Cfe',
    symbol: 'KOKOMO',
    url: 'https://kokomoswap.io/#/',
    description:
      'KokomoSwap is a Binance Smart Chain based AMM (automated market maker) DEX(decentralized exchange) designed to facilitate the seamless transaction of crypto assets in a fun-filled and profitable manner.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/kokomoswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'kokomo-token',
    cmcId: '10709',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'kokomoswap/index.js',
    twitter: 'KokomoSwap',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://docs.kokomoswap.io/risk-and-security-1/audit'],
    slug: 'kokomoswap',
    tvl: 180415.25561642527,
    chainTvls: { Binance: 180415.25561642527 },
    change_1h: -0.03482866315651734,
    change_1d: 1.095919952005346,
    change_7d: 15.805429688429555,
    mcap: 0,
  },
  {
    id: '998',
    name: 'KokoSwap',
    address: 'bsc:0x7320c128e89ba4584ab02ed1c9c96285b726443c',
    symbol: 'KOKO',
    url: 'https://kokoswap.org/',
    description:
      'KokoSwap is a unique platform which combines exciting and upcoming features of NFT trades and blockchain gaming.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/kokoswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'kokoswap',
    cmcId: '11375',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'kokoswap/index.js',
    twitter: 'kokoswap',
    forkedFrom: ['Uniswap'],
    listedAt: 1639000954,
    slug: 'kokoswap',
    tvl: 180391.25680368007,
    chainTvls: { Binance: 180391.25680368007 },
    change_1h: -0.013301986388682963,
    change_1d: 1.082472186493817,
    change_7d: 15.790025265935043,
    fdv: 1141626122,
    mcap: 0,
  },
  {
    id: '156',
    name: 'zLot',
    address: '0xA8e7AD77C60eE6f30BaC54E2E7c0617Bd7B5A03E',
    symbol: 'zLot',
    url: 'https://zlot.finance/',
    description: 'The first staking protocol built upon Hegic.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/zlot.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'zlot',
    cmcId: '7602',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'zlot/index.js',
    twitter: 'zLOTFinance',
    slug: 'zlot',
    tvl: 178910.15963170823,
    chainTvls: { Ethereum: 178910.15963170823 },
    change_1h: -1.3900451257740798,
    change_1d: -19.30404012642572,
    change_7d: 22.688615579647802,
    mcap: 349153,
  },
  {
    id: '174',
    name: 'BT.Finance',
    address: '0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a',
    symbol: 'BT',
    url: 'https://bt.finance/',
    description: 'The best and sustainable yield from other excellent DeFi yield aggregator and platforms.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bt.finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bt-finance',
    cmcId: '8558',
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'bt-finance.js',
    twitter: 'btdotfinance',
    audit_links: ['https://github.com/btdotfinance/bt-finance/tree/master/audits'],
    slug: 'bt.finance',
    tvl: 176584.49,
    chainTvls: { Ethereum: 176584.49 },
    change_1h: 0.0059068698992348345,
    change_1d: -1.1211472567023577,
    change_7d: 12.390212613097006,
    mcap: 44403,
  },
  {
    id: '718',
    name: 'Alium Swap',
    address: 'bsc:0x7C38870e93A1f959cB6c533eB10bBc3e438AaC11',
    symbol: 'ALM',
    url: 'https://alium.finance/',
    description: 'Alium Swap is a Decentralized AMM Exchange with Multi-blockchain Option and NFTs',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/alium.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'alium-swap',
    cmcId: '10428',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'aliumswap/index.js',
    twitter: 'AliumSwap',
    audit_links: [
      'https://github.com/chainsulting/Smart-Contract-Security-Audits/blob/master/Aliumswap/02_Smart%20Contract%20Audit_Aliumswap.pdf',
      'https://github.com/chainsulting/Smart-Contract-Security-Audits/blob/master/Aliumswap/02_Smart%20Contract%20Audit_AliumSwap_Core.pdf',
      'https://www.certik.org/projects/aliumswap',
      'https://drive.google.com/file/d/1Vd03-3ro62UvYMLeR8PHrmP5AeVBSD6v/view',
      'https://drive.google.com/file/d/1DuyR97HWPk8Nxe5VJ8S9l3cdCZQwkGK5/view',
      'https://drive.google.com/file/d/1oC_wrSwjw_47scZBeuHavTvtjwWmwQXS/view',
    ],
    listedAt: 1635389421,
    forkedFrom: ['Uniswap'],
    slug: 'alium-swap',
    tvl: 174585.21345438418,
    chainTvls: { Binance: 174585.21345438418, 'Binance-staking': 125773.68162433385, staking: 125773.68162433385 },
    change_1h: 0.11059639903980667,
    change_1d: 1.4045716200529625,
    change_7d: 7.191831740570194,
    staking: 125773.68162433385,
    mcap: 0,
  },
  {
    id: '858',
    name: 'Piggybank DAO',
    address: 'avax:0x0E75CDb914DdCcA683357dD94d26Caa39BC14B16',
    symbol: 'PB',
    url: 'https://piggybankdao.finance',
    description:
      'Piggybank is a decentralized reserve currency protocol based on the PB token. Each PB token is backed by a basket of assets (e.g. MIM, LPs) in the PiggyBankDAO treasury, giving it an intrinsic value that it cannot fall below. PiggyBankDAO also introduces unique economic and game-theoretic dynamics into the market through staking and bonding.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/piggybank-dao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'piggy-bank',
    cmcId: '14780',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'piggybankdao/index.js',
    twitter: 'PiggyBankDAO',
    audit_links: ['https://piggybankdao.finance/TECHAUDIT_PIGGYBANK.pdf'],
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637289184,
    slug: 'piggybank-dao',
    tvl: 159532.835120198,
    chainTvls: { 'Avalanche-staking': 30696.389199491125, Avalanche: 159532.835120198, staking: 30696.389199491125 },
    change_1h: 0,
    change_1d: 0.12107468193873672,
    change_7d: -0.7965621840975956,
    staking: 30696.389199491125,
    fdv: 33684,
    mcap: 0,
  },
  {
    id: '877',
    name: 'DungeonSwap',
    address: 'bsc:0x14c358b573a4cE45364a3DBD84BBb4Dae87af034',
    symbol: 'DND',
    url: 'https://dungeonswap.app',
    description:
      'DungeonSwap is a decentralized table-top RPG style web game on the Binance Smart Chain with play-to-earn, yield farming features and NFTs.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/dungeonswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dungeonswap',
    cmcId: '9578',
    category: 'Yield',
    chains: ['Binance'],
    module: 'dungeonswap/index.js',
    twitter: 'DungeonSwap',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/DungeonSwap.pdf'],
    listedAt: 1637378691,
    slug: 'dungeonswap',
    tvl: 158017.6123314407,
    chainTvls: { Binance: 158017.6123314407 },
    change_1h: -0.09542953667879317,
    change_1d: 2.277807817069217,
    change_7d: 4.124310506975547,
    fdv: 224821,
    mcap: 121821,
  },
  {
    id: '1567',
    name: 'LuckyChip',
    address: 'bsc:0x6012c3a742f92103d238f1c8306cf8fbcdeca8b3',
    symbol: 'LC',
    url: 'https://luckychip.io/',
    description: 'Most Transparent Casino with 100% Profit Sharing on Blockchain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/luckychip.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Prediction Market',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'luckychip/index.js',
    twitter: 'LuckyChip_io',
    listedAt: 1647812879,
    slug: 'luckychip',
    tvl: 154774.32131920505,
    chainTvls: { Binance: 154774.32131920505, 'Binance-staking': 15114.893836180221, staking: 15114.893836180221 },
    change_1h: -0.08641503605242917,
    change_1d: 0.9413435465714457,
    change_7d: 4.521873417139773,
    staking: 15114.893836180221,
  },
  {
    id: '684',
    name: 'Hpdex',
    address: 'hpd:0x6383f770f1eec68e80ac0c5527be71a11b4d182c',
    symbol: 'HPD',
    url: 'https://app.hpdex.org/#/swap',
    description: 'HPDex is a live community-driven decentralized exchange released on High Performance Blockchain',
    chain: 'HPB',
    logo: 'https://icons.llama.fi/hpdex.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['HPB'],
    module: 'hpdex/index.js',
    forkedFrom: ['Uniswap'],
    twitter: null,
    listedAt: 1634594197,
    slug: 'hpdex',
    tvl: 153775.24597835494,
    chainTvls: { HPB: 153775.24597835494 },
    change_1h: 0,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '1014',
    name: 'TempoDAO',
    address: 'avax:0x88a425b738682f58c0ff9fcf2cceb47a361ef4cf',
    symbol: 'TEMPO',
    url: 'https://earn.tempodao.gg/#/stake',
    description:
      'Tempo DAO is a fork of the already successful Olympus DAO available on the Avalanche Network. The TEMPO token is a revolutionary reserve backed gaming currency for the metaverse. Tempo DAO will use the TEMPO reserve-backed currency to help establish a treasury that rewards all those who decide to stake in the project. ',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/tempodao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'tempo-dao',
    cmcId: '15379',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'tempodao/index.js',
    twitter: 'TempoDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639332627,
    slug: 'tempodao',
    tvl: 153370.19263561224,
    chainTvls: { 'Avalanche-staking': 244906.00647573575, Avalanche: 153370.19263561224, staking: 244906.00647573575 },
    change_1h: -0.02541455092401179,
    change_1d: -0.30480088996711174,
    change_7d: 1.1666606711137888,
    staking: 244906.00647573575,
    fdv: 29557,
    mcap: 0,
  },
  {
    id: '1533',
    name: 'LIFE',
    address: 'fantom:0x8877E4B70C50CF275C2B77d6a0F69a312F5eE236',
    symbol: 'LIFE',
    url: 'https://lif3.exchange/',
    description:
      'LIFE is a reward and governance token integrating DeFi yield farming on the Fantom network. Earn yield from your ERC-20 tokens and LP tokens by staking and earning LIFE rewards.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/blindex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'life/index.js',
    twitter: 'DefiFatfire',
    listedAt: 1646953849,
    slug: 'life',
    tvl: 150737.0544870768,
    chainTvls: {
      pool2: 1868.4268266578983,
      'Fantom-staking': 943.8314475848503,
      masterchef: 150737.05448707682,
      'Fantom-masterchef': 150737.05448707682,
      'Fantom-pool2': 1868.4268266578983,
      Fantom: 150737.0544870768,
      staking: 943.8314475848503,
    },
    change_1h: 0.7670938412783812,
    change_1d: 1.9958963176372606,
    change_7d: -34.42664612065414,
    staking: 943.8314475848503,
    pool2: 1868.4268266578983,
  },
  {
    id: '924',
    name: 'ButterSwap',
    address: 'heco:0xbf84214ea409a369774321727595f218889ed943',
    symbol: 'BUTTER',
    url: 'https://www.butterswap.me/',
    description: 'The leading AMM DEX / yield farming / NFT gaming platform on HECO audited by CertiK and Knownsec.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/butterswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'butterswap',
    cmcId: '10531',
    category: 'Dexes',
    chains: ['Binance', 'Heco'],
    module: 'butterswap/index.js',
    twitter: 'ButterSwap',
    audit_links: ['https://docs.butterswap.me/audit'],
    forkedFrom: ['Uniswap'],
    listedAt: 1638085420,
    slug: 'butterswap',
    tvl: 150322.52216379312,
    chainTvls: {
      Binance: 93558.53916293103,
      'Binance-staking': 1951.5817456220784,
      Heco: 56763.9830008621,
      'Heco-staking': 32891.55180483158,
      staking: 34843.133550453655,
    },
    change_1h: -0.09995091818500157,
    change_1d: -13.816941150357238,
    change_7d: null,
    staking: 34843.133550453655,
    mcap: 0,
  },
  {
    id: '1304',
    name: 'Buffer Finance',
    address: 'bsc:0xa296ad1c47fe6bdc133f39555c1d1177bd51fbc5',
    symbol: 'IBFR',
    url: 'https://www.buffer.finance',
    description:
      'Buffer is a decentralized peer to pool options trading platform. Where all options and predictions are minted as NFTs',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/buffer-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'ibuffer-token',
    cmcId: '12440',
    category: 'Options',
    chains: ['Binance'],
    module: 'buffer/index.js',
    twitter: 'Buffer_Finance',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Buffer-v1.0.pdf',
    ],
    oracles: ['Chainlink'],
    forkedFrom: ['Hegic'],
    listedAt: 1642780144,
    slug: 'buffer-finance',
    tvl: 150310.93112313258,
    chainTvls: { Binance: 150310.93112313258, 'Binance-staking': 371822.77017000003, staking: 371822.77017000003 },
    change_1h: 0.6660105737761057,
    change_1d: 1.0132747499722825,
    change_7d: 5.461348298336304,
    staking: 371822.77017000003,
    fdv: 5253618,
    mcap: 380582,
  },
  {
    id: '433',
    name: 'DAOventures',
    address: '0x77dce26c03a9b833fc2d7c31c22da4f42e9d9582',
    symbol: 'DVD',
    url: 'https://www.daoventures.co',
    description:
      'DAOventures is a DeFi technologies based automated investment fund that automatically invests into DeFi protocols and generates returns for the fund based on risk mandate.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/daoventures.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'daoventures',
    cmcId: '8478',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'daoventures/index.js',
    twitter: 'VenturesDao',
    audit_links: ['https://github.com/daoventures/dao-audits'],
    slug: 'daoventures',
    tvl: 149929.05501001517,
    chainTvls: { Ethereum: 149929.05501001517, 'Ethereum-staking': 77866.28060672103, staking: 77866.28060672103 },
    change_1h: 0.696726700942051,
    change_1d: 2.4348996270883134,
    change_7d: 13.26496360687301,
    staking: 77866.28060672103,
    fdv: 487978,
    mcap: 306515,
  },
  {
    id: '992',
    name: 'Ubiquity DAO',
    address: '0x4e38d89362f7e5db0096ce44ebd021c3962aa9a0',
    symbol: 'UBQ',
    url: 'https://ubq.fi',
    description:
      'As an organization, it is committed to becoming the bank of the metaverse, while building open-source developer tooling and community management tools to augment the operating efficiency of DAOs.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ubiquitydao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'ubiquity',
    cmcId: null,
    category: 'Services',
    chains: ['Ethereum'],
    module: 'ubiquitydao/index.js',
    twitter: 'UbiquityDAO',
    listedAt: 1638903349,
    slug: 'ubiquity-dao',
    tvl: 149770.90112012468,
    chainTvls: { Ethereum: 149770.90112012468 },
    change_1h: -0.16252422906403297,
    change_1d: 0.12610595126723467,
    change_7d: -0.233640240934065,
    mcap: 0,
  },
  {
    id: '532',
    name: 'Twindex',
    address: 'bsc:0x41171d5770c4c68686d1af042ada88a45b02f82b',
    symbol: 'TWX',
    url: 'https://twindex.com/#/swap',
    description:
      'Twindex enables the trading of synthetic assets pegged to real-world prices in the most accessible way with its unique mechanism.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/twindex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'twindex',
    cmcId: '11795',
    category: 'Options',
    chains: ['Binance'],
    module: 'twindex/index.js',
    twitter: 'twindexcom',
    oracles: ['Chainlink'],
    forkedFrom: ['Uniswap'],
    slug: 'twindex',
    tvl: 147554.8505362084,
    chainTvls: { Binance: 147554.8505362084, 'Binance-staking': 0, staking: 0 },
    change_1h: 0.03813387451701544,
    change_1d: -3.1616934393180998,
    change_7d: 0.43997038346567763,
    staking: 0,
    mcap: 1907.04,
  },
  {
    id: '440',
    name: 'LoTerra',
    address: 'terra:terra1ez46kxtulsdv07538fh5ra5xj8l68mu8eg24vr',
    symbol: 'LOTA',
    url: 'https://loterra.io',
    description: 'LoTerra is a decentralized lottery regulated by a smart contract and managed by LOTA holders.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/loterra.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'loterra',
    cmcId: '11673',
    category: 'Prediction Market',
    chains: ['Terra'],
    module: 'loterra/index.js',
    twitter: 'LoTerra_LOTA',
    audit_links: ['https://docs.loterra.io/loterra/resources/security-audit#security-audit-report'],
    oracles: ['Terrand'],
    openSource: true,
    slug: 'loterra',
    tvl: 147247.21948034,
    chainTvls: { 'Terra-Treasury': 276062.71936586, Terra: 147247.21948034, Treasury: 276062.71936586 },
    change_1h: 0,
    change_1d: 4915459.918062662,
    change_7d: -47.422202430980434,
    mcap: 0,
  },
  {
    id: '369',
    name: 'YFDAI',
    address: '0xf4CD3d3Fda8d7Fd6C5a500203e38640A70Bf9577',
    symbol: 'YFDAI',
    url: 'https://yfdai.finance/',
    description: 'Bridging the Worlds of Traditional Finance and Decentralised Networks',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/yfdai.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 'yfdai-finance',
    cmcId: '6938',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'yfdai/index.js',
    twitter: 'YfdaiF',
    audit_links: [
      'https://attest.im/attestation/searchResult?qurey=4c533d00766c4b2a9761fad5da78b56f',
      'https://firebasestorage.googleapis.com/v0/b/gitbook-28427.appspot.com/o/assets%2F-MSgvIS2RfKE66pfkI81%2F-MUv-RfymyB6PvCRlnwz%2F-MUv1a71AjJ6p3Bgz5ui%2FChannels-Audit-Report-030321(1).pdf?alt=media&token=1af91ea6-d3d0-4ba3-8784-5dce6a907930',
    ],
    slug: 'yfdai',
    tvl: 145391.53717546194,
    chainTvls: { Ethereum: 145391.53717546194, 'Ethereum-staking': 1322370.097158435, staking: 1322370.097158435 },
    change_1h: -0.05595615621987804,
    change_1d: 0.6545203609965569,
    change_7d: 1.5141693187810148,
    staking: 1322370.097158435,
    mcap: 766351,
  },
  {
    id: '370',
    name: 'Merlin',
    address: 'bsc:0xDA360309C59CB8C434b28A91b823344a96444278',
    symbol: 'MERL',
    url: 'https://www.merlinlab.com/farm',
    description:
      'Merlin is an auto-compounding yield aggregator with an ecosystem optimized to focus on safe and sustainable maximum yield return.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/merlin.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'merlin',
    cmcId: '9853',
    category: 'Yield',
    chains: ['Binance'],
    module: 'merlinlab/index.js',
    twitter: 'MerlinLab_',
    audit_links: ['https://merlinlab.gitbook.io/merlin/security-and-risks'],
    oracles: ['Chainlink'],
    slug: 'merlin',
    tvl: 141141.49104658657,
    chainTvls: { Binance: 141141.49104658657 },
    change_1h: 0.7505990996161671,
    change_1d: 3.5017119656780125,
    change_7d: 20.679958159488024,
    mcap: 0,
  },
  {
    id: '837',
    name: 'ArenaSwap',
    address: 'bsc:0x2A17Dc11a1828725cdB318E0036ACF12727d27a2',
    symbol: 'ARENA',
    url: 'https://www.arenaswap.com/',
    description:
      'ArenaSwap is a decentralized exchange platform built on BSC (Binance Smart Chain) that focuses on gamification of yield farming and gambling.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/arenaswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'arena-token',
    cmcId: '11082',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'arenaswap/index.js',
    twitter: 'arenaswap',
    audit_links: [
      'https://rugdoc.io/project/arenaswap/',
      'https://github.com/TechRate/Smart-Contract-Audits/blob/main/ArenaSwap%20Standart%20Smart%20Contract%20Security%20Audit.pdf',
    ],
    listedAt: 1636837386,
    slug: 'arenaswap',
    tvl: 140620.99995539978,
    chainTvls: { Binance: 140620.99995539978, 'Binance-staking': 82884.21844007773, staking: 82884.21844007773 },
    change_1h: -0.04345559286589662,
    change_1d: null,
    change_7d: 8.333862360102856,
    staking: 82884.21844007773,
    fdv: 99465,
    mcap: 0,
  },
  {
    id: '733',
    name: 'Stabilize',
    address: 'avax:0x37d87e316CB4e35163881fDb6c6Bc0CdBa91dc0A',
    symbol: 'SET',
    url: 'https://stabilize.fi',
    description:
      'Stabilize is a decentralized borrowing protocol2.0 that allows you to draw interest-free loans against basic asset & ibTKNs used as collaterals.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/stabilizefi.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'CDP',
    chains: ['Avalanche'],
    module: 'stabilizefi/index.js',
    twitter: 'stabilize_fi',
    oracles: ['Chainlink'],
    listedAt: 1635796872,
    forkedFrom: ['Liquity'],
    slug: 'stabilize',
    tvl: 140236.67482292867,
    chainTvls: {
      'Avalanche-staking': 3011.9678944459997,
      pool2: 8726.96758934893,
      Avalanche: 140236.67482292867,
      'Avalanche-pool2': 8726.96758934893,
      staking: 3011.9678944459997,
    },
    change_1h: 0.5074504290496549,
    change_1d: 2.971222385016887,
    change_7d: 6.381217852472233,
    staking: 3011.9678944459997,
    pool2: 8726.96758934893,
  },
  {
    id: '834',
    name: 'BSCStation',
    address: 'bsc:0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D',
    symbol: 'BSCS',
    url: 'https://bscstation.finance/',
    description: 'BSCStation (BSCS) aims to build a Full-Stack DEFI with NFT Auction on the Binance Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bsc-station.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bsc-station',
    cmcId: '9345',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'bscstation/index.js',
    twitter: 'bscstation',
    audit_links: [
      'https://www.certik.org/projects/bscstation',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BSCStationStartPools-v1.0.pdf',
      'https://github.com/Quillhash/Audit_Reports/blob/master/BSCS%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf',
    ],
    listedAt: 1636756382,
    slug: 'bscstation',
    tvl: 139723.52724461126,
    chainTvls: { Binance: 139723.52724461126, 'Binance-staking': 582457.6229163683, staking: 582457.6229163683 },
    change_1h: 0.42192965672767,
    change_1d: 4.250229107338228,
    change_7d: 0.4985371699626171,
    staking: 582457.6229163683,
    mcap: 2472107,
  },
  {
    id: '1116',
    name: 'VultureSwap',
    address: 'cronos:0x8A07A7C25CF225Ed06d5e5Ad253C96D824B588D0',
    symbol: 'VULTR',
    url: 'https://vultureswap.finance',
    description:
      'VultureSwap is a DEX on Cronos Chain which offers token swap/trading at the lowest price on the whole Cronos Defi space.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/vultureswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'vultureswap/index.js',
    twitter: 'VultureSwap',
    audit_links: [
      'https://www.certik.com/projects/vultureswap',
      'https://paladinsec.co/projects/vultureswap',
      'https://rugdoc.io/project/vultureswap',
    ],
    listedAt: 1640645851,
    forkedFrom: ['Uniswap'],
    slug: 'vultureswap',
    tvl: 138469.386099411,
    chainTvls: { Cronos: 138469.386099411 },
    change_1h: -0.014898353465724767,
    change_1d: -12.477049777692827,
    change_7d: -51.740324619207065,
  },
  {
    id: '893',
    name: 'RetroDefi',
    address: 'bsc:0xa6e53f07bD410df069e20Ced725bdC9135146Fe9',
    symbol: 'RCUBE',
    url: 'https://www.retrodefi.net',
    description: 'Multi-Chain Yield Optimizer',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/retrodefi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'retro-defi',
    cmcId: '10590',
    category: 'Yield',
    chains: ['Polygon', 'Binance'],
    module: 'retrodefi/index.js',
    twitter: 'retrodefi',
    audit_links: ['https://retrodefi.gitbook.io/retro-defi/audit'],
    listedAt: 1637624204,
    slug: 'retrodefi',
    tvl: 134490.08471460515,
    chainTvls: {
      pool2: 672.9381241936179,
      'Polygon-pool2': 2.363825e-17,
      'Binance-pool2': 672.9381241936179,
      Binance: 1274.6485594253752,
      'Binance-staking': 45222.930432443216,
      Polygon: 133215.43615517978,
      'Polygon-staking': 96829.14884222126,
      staking: 142052.07927466446,
    },
    change_1h: 0.8096817678940056,
    change_1d: 1.6930805609956678,
    change_7d: 1.265391618139006,
    staking: 142052.07927466446,
    pool2: 672.9381241936179,
    mcap: 0,
  },
  {
    id: '1019',
    name: 'OpenDAO',
    address: 'bsc:0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
    symbol: 'pOPEN',
    url: 'https://opendao.io/',
    description:
      'The Permissionless Stablecoin Minting Protocol, Mint USDO with the tokens you own. Trade USDO for the tokens you want.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/opendao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'open-governance-token',
    cmcId: '7783',
    category: 'Algo-Stables',
    chains: ['Binance', 'Ethereum'],
    module: 'opendao/index.js',
    twitter: 'opendaoprotocol',
    audit_links: ['https://opendao.gitbook.io/transparency/audits'],
    listedAt: 1639418306,
    forkedFrom: ['Compound'],
    slug: 'opendao',
    tvl: 133956.8854748492,
    chainTvls: {
      Binance: 124008.3481986745,
      Ethereum: 9948.537276174708,
      pool2: 26312.951769144292,
      'Ethereum-pool2': 26312.951769144292,
    },
    change_1h: 0.273476314802636,
    change_1d: -2.886253691223999,
    change_7d: -29.148917666364,
    pool2: 26312.951769144292,
    fdv: 3416160,
    mcap: 396321,
  },
  {
    id: '1096',
    name: 'PureFi',
    address: 'bsc:0xe2a59d5e33c6540e18aaa46bf98917ac3158db0d',
    symbol: 'UFI',
    url: 'https://www.purefi.io',
    description: 'PureFi — one-stop compliance protocol for decentralized finances',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/purefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'purefi',
    cmcId: '10973',
    category: 'Yield',
    chains: ['Binance', 'Polygon', 'Ethereum'],
    module: 'purefi/index.js',
    twitter: 'Purefi_Protocol',
    audit_links: ['https://www.purefi.io/files/27072021_PureFi_Audit_Report.pdf'],
    listedAt: 1640525223,
    slug: 'purefi',
    tvl: 129422.71478955267,
    chainTvls: {
      'Binance-staking': 1495331.7786555802,
      Polygon: 129422.71478955267,
      'Ethereum-staking': 4844.502066937957,
      'Polygon-staking': 34.77848673148857,
      staking: 1500211.0592092497,
    },
    change_1h: 0.9916629793594609,
    change_1d: 6.040081546874987,
    change_7d: 8.960044909667772,
    staking: 1500211.0592092497,
    fdv: 7329365,
    mcap: 2260002,
  },
  {
    id: '927',
    name: 'Pidao Finance',
    address: 'bsc:0x1215ed20aa507578ca352e195016f289e7a17f3a',
    symbol: 'PID',
    url: 'https://www.pidao.finance/',
    description:
      'PIDAO is a decentralized reserve currency protocol based on the PID token and aims at building a community-owned decentralized financial infrastructure for the crypto world',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pidao-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'pidao',
    cmcId: '14163',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'pidao-finance/index.js',
    twitter: 'PIDAOFinance',
    audit_links: ['https://www.certik.com/projects/pidaofinance'],
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638126989,
    slug: 'pidao-finance',
    tvl: 129119.35821506096,
    chainTvls: { Binance: 129119.35821506096, 'Binance-staking': 71318.22626997517, staking: 71318.22626997517 },
    change_1h: 0,
    change_1d: 0.20654893669475882,
    change_7d: -1.1776043998414991,
    staking: 71318.22626997517,
    mcap: 0,
  },
  {
    id: '887',
    name: 'SwapperChan',
    address: 'boba:0xb5cb0975716a4aa6438d3fa02364886e13fd678c',
    symbol: 'WAIFU',
    chains: ['Boba'],
    url: 'https://swapperchan.com/swap',
    description: 'A DEX haven for Simps to unite in the world of crypto.',
    chain: 'Boba',
    logo: 'https://icons.llama.fi/swapperchan.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    module: 'swapperchan/index.js',
    twitter: 'SwapperChan',
    forkedFrom: ['Uniswap'],
    listedAt: 1637511326,
    slug: 'swapperchan',
    tvl: 127465.69104541653,
    chainTvls: { Boba: 127465.69104541653 },
    change_1h: 0,
    change_1d: -2.427820047156189,
    change_7d: 2.6995391561277984,
  },
  {
    id: '1234',
    name: 'Agile Finance',
    address: 'cronos:0xA4434AFEae0dECb9820D906BF01b13291D00651a',
    symbol: 'AGL',
    url: 'https://www.agilefi.org',
    description:
      'Money Markets DeFi platform for lenders and borrowers, that also features own AMM, IDO Launchpad, and NFT Marketplace with Governance AGL token.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/agile-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'agile',
    cmcId: null,
    category: 'Lending',
    chains: ['Cronos'],
    module: 'agile/index.js',
    twitter: 'agiledefi',
    audit_links: [
      'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_AgileFinance.pdf',
    ],
    forkedFrom: ['Compound', 'Uniswap'],
    listedAt: 1641906290,
    slug: 'agile-finance',
    tvl: 126194.74822541272,
    chainTvls: { 'Cronos-borrowed': 223013.28118714865, Cronos: 126194.74822541272, borrowed: 223013.28118714865 },
    change_1h: 0.10020642033434513,
    change_1d: 6.806903878273985,
    change_7d: 10.710884599791015,
    mcap: 0,
  },
  {
    id: '683',
    name: 'GoSwap',
    address: 'gochain:0x67bBB47f6942486184f08a671155FCFA6cAd8d71',
    symbol: 'FAST',
    url: 'https://goswap.exchange/#/swap',
    description:
      'Uniswap fork on GoChain. Transactions settle in seconds and cost less than a penny. And you can still trade all your favorite Ethereum tokens such as WBTC and ETH as well as GoChain native tokens.',
    chain: 'GoChain',
    logo: 'https://icons.llama.fi/goswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['GoChain'],
    module: 'GoSwap/index.js',
    twitter: 'goswapper',
    forkedFrom: ['Uniswap'],
    listedAt: 1634580919,
    slug: 'goswap',
    tvl: 125902.1527638828,
    chainTvls: { GoChain: 125902.1527638828 },
    change_1h: 0.1684297927409233,
    change_1d: 5.949864907704907,
    change_7d: 9.610773425986594,
  },
  {
    id: '737',
    name: 'Dino.Exchange',
    address: 'bsc:0xf317932ee2c30fa5d0e14416775977801734812d',
    symbol: 'DINO',
    url: 'https://dino.exchange',
    description:
      'A one-stop DeFi hub with realigned incentives for network participants via revenue sharing & community-driven network effects.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/dino.exchange.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'dino-exchange',
    cmcId: '9621',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'dinoexchange/index.js',
    twitter: 'dinoexchange',
    forkedFrom: ['Uniswap'],
    listedAt: 1635801116,
    slug: 'dino.exchange',
    tvl: 125753.99045608281,
    chainTvls: { Binance: 125753.99045608281, 'Binance-staking': 98803.61700713068, staking: 98803.61700713068 },
    change_1h: -0.1461506513163755,
    change_1d: 0.4434720098754923,
    change_7d: -1.8543171264805096,
    staking: 98803.61700713068,
    mcap: 367306,
  },
  {
    id: '192',
    name: 'Governor DAO',
    address: '0x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc',
    symbol: 'GDAO',
    url: 'https://governordao.org/',
    description:
      'All projects can benefit from decentralized governance. Governor DAO aids new and existing projects alike in transitioning to a DAO via Governance-as-a-Service.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/governor-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'governor-dao',
    cmcId: '7694',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'gdao.js',
    twitter: 'Governor_DAO',
    slug: 'governor-dao',
    tvl: 124845.67,
    chainTvls: { Ethereum: 124845.67 },
    change_1h: -0.43871485328854476,
    change_1d: 2.818519307615759,
    change_7d: 10.640090854749815,
    mcap: 1691564,
  },
  {
    id: '811',
    name: 'Kebab Finance',
    address: 'bsc:0x7979F6C54ebA05E18Ded44C4F986F49a5De551c2',
    symbol: 'KEBAB',
    url: 'https://kebabfinance.com/',
    description:
      'Kebab Finance is a DEX using AMM technology. Started out as a PancakeSwap fork and have grown to forge their own identity and community.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/kebab-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'kebab-token',
    cmcId: '8334',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'kebab-finance/index.js',
    twitter: 'kebabfinance',
    audit_links: ['https://www.certik.org/projects/kebab'],
    forkedFrom: ['Uniswap'],
    listedAt: 1636430124,
    slug: 'kebab-finance',
    tvl: 121514.4452161557,
    chainTvls: { Binance: 121514.4452161557, 'Binance-staking': 36253.75622966578, staking: 36253.75622966578 },
    change_1h: -0.023195577658981392,
    change_1d: 0.47176100233716056,
    change_7d: 3.475455929113622,
    staking: 36253.75622966578,
    mcap: 0,
  },
  {
    id: '891',
    name: 'OneDAO Finance',
    address: 'harmony:0x947394294F75D7502977AC6813FD99f77C2931ec',
    symbol: 'ODAO',
    url: 'https://onedao.finance',
    description: 'The Decentralized Reserve Currency from The Light.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/onedao-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'onedao-finance',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Harmony'],
    module: 'onedaofinance/index.js',
    twitter: 'onedao_finance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637587915,
    slug: 'onedao-finance',
    tvl: 118781.62595919313,
    chainTvls: { 'Harmony-staking': 57081.00351580704, Harmony: 118781.62595919313, staking: 57081.00351580704 },
    change_1h: -0.002383680991314918,
    change_1d: 1.3905531281026953,
    change_7d: 11.387001652400414,
    staking: 57081.00351580704,
    mcap: 0,
  },
  {
    id: '300',
    name: 'BondAppetit',
    address: '0x28A06c02287e657ec3F8e151A13C36A1D43814b0',
    symbol: 'BAG',
    url: 'https://bondappetit.io/',
    description:
      'BondAppétit, a DeFi protocol for business loans. BondAppétit provides an infrastructure that connects decentralized finance and traditional debt instruments with the participation of regulated securities and cryptocurrency brokers/dealers.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bondappetit.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bondappetit-gov-token',
    cmcId: '9322',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'bond-appetit.js',
    twitter: 'DeFiBonds',
    audit_links: [
      'https://github.com/mixbytes/audits_public/tree/4fc7d333e3df57586e0f96cc551819e2c93f3ae9/BondAppetit',
      'https://github.com/HashEx/public_audits/blob/32a680c5b8f3a784ef3273c324e0841341f3abc2/BondApp%C3%A9tit/BondAppetit%20report.pdf',
    ],
    slug: 'bondappetit',
    tvl: 117428.91,
    chainTvls: { Ethereum: 117428.91 },
    change_1h: 0.13278397219347937,
    change_1d: 6.918471427701263,
    change_7d: 11.428782085296604,
    fdv: 2579825,
    mcap: 280682,
  },
  {
    id: '835',
    name: 'Suterusu',
    address: '0xaa2ce7ae64066175e0b90497ce7d9c190c315db4',
    symbol: 'SUTER',
    url: 'https://suterusu.io',
    description: 'The Suterusu Protocol is a second-layer private payment infrastructure for smart contract platforms.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/suterusu.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'suterusu',
    cmcId: '4841',
    category: 'Services',
    chains: ['Binance', 'Ethereum'],
    module: 'suter-shield/index.js',
    twitter: 'suterusu_io',
    audit_links: ['https://docs.suterusu.io/suterusu-protocol-1/security-audit'],
    listedAt: 1636757032,
    slug: 'suterusu',
    tvl: 117052.91237699999,
    chainTvls: { Binance: 113846.67081299999, Ethereum: 3206.241564 },
    change_1h: -0.10512861506509807,
    change_1d: null,
    change_7d: -74.3122850100545,
    fdv: 31769117,
    mcap: 11560909,
  },
  {
    id: '1202',
    name: 'BananaFarm',
    address: 'boba:0xc67b9b1b0557aeafa10aa1ffa1d7c87087a6149e',
    symbol: 'BANA',
    url: 'https://bananafarm.io',
    description: 'Autocompounding Yield Optimizere on Boba Network',
    chain: 'Boba',
    logo: 'https://icons.llama.fi/bananafarm.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Boba'],
    module: 'banana.js',
    twitter: 'bananafarm_io',
    listedAt: 1641423757,
    slug: 'bananafarm',
    tvl: 115948.9706843432,
    chainTvls: {
      'Boba-pool2': 1602.329623598878,
      Boba: 115948.9706843432,
      'Boba-staking': 3300.9358104325984,
      pool2: 1602.329623598878,
      staking: 3300.9358104325984,
    },
    change_1h: 0.017397871032301282,
    change_1d: 0.007605509445767211,
    change_7d: 1.0839778628968872,
    staking: 3300.9358104325984,
    pool2: 1602.329623598878,
  },
  {
    id: '351',
    name: 'Gravity Finance',
    address: 'polygon:0x874e178a2f3f3f9d34db862453cd756e7eab0381',
    symbol: 'GFI',
    url: 'https://gravityfinance.io/',
    description:
      'Gravity Finance is a DeFi platform. Its Governance Token has a true intrinsic value (backed by bitcoin) that employs deflationary tokenomics.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/gravity-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'gravity-finance',
    cmcId: '10324',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'gravity-finance/index.js',
    twitter: 'Gravity_Finance',
    slug: 'gravity-finance',
    tvl: 115794.3507253071,
    chainTvls: { Polygon: 115794.3507253071 },
    change_1h: 0.48523815758979083,
    change_1d: 4.536282058610539,
    change_7d: null,
    mcap: 1902747,
  },
  {
    id: '1473',
    name: 'SportBet',
    address: 'null',
    symbol: '-',
    url: 'https://sportbet.one/',
    description: 'Decentralized sportsbook on EOS.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/sportbet.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'sbet',
    cmcId: '6591',
    category: 'Prediction Market',
    chains: ['EOS'],
    oracles: ['Internal'],
    forkedFrom: [],
    module: 'sportbet/index.js',
    twitter: 'SportBetOne',
    listedAt: 1645824211,
    slug: 'sportbet',
    tvl: 113244.80105235412,
    chainTvls: { EOS: 113244.80105235412 },
    change_1h: 1.8594794160646302,
    change_1d: 9.54803875088912,
    change_7d: 28.04955526411632,
    mcap: 0,
  },
  {
    id: '325',
    name: 'MochiSwap',
    address: 'bsc:0x055dab90880613a556a5ae2903b2682f8a5b8d27',
    symbol: 'MOCHI',
    url: 'https://mochiswap.io',
    description:
      'MochiSwap is a Decentralized DEX exchange and supports users around the world offering decentralized asset swapping and liquidity pools, security is high concern and the DAO is under control with a "Gnosis Safe” multisignature wallet. MochiSwap currently supports Harmony ONE, Binance Smart Chain along with Incentivized liquidity pools for yield mining.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/mochiswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'mochiswap',
    cmcId: '1350',
    category: 'Dexes',
    chains: ['Binance', 'Harmony'],
    module: 'mochiswap/index.js',
    forkedFrom: ['Sushiswap'],
    twitter: 'mochiswap',
    slug: 'mochiswap',
    tvl: 111315.64666045486,
    chainTvls: {
      Binance: 67934.95421114346,
      'Binance-staking': 157103.47568755908,
      'Harmony-staking': 0,
      Harmony: 43380.6924493114,
      staking: 157103.47568755908,
    },
    change_1h: null,
    change_1d: -1.0904225403051555,
    change_7d: -0.59250181668979,
    staking: 157103.47568755908,
    mcap: 0,
  },
  {
    id: '1535',
    name: 'Cubo',
    address: 'polygon:0x381d168de3991c7413d46e3459b48a5221e3dfe4',
    symbol: 'CUBO',
    url: 'https://cubo.money',
    description:
      'When cubo and dai tokens are deposited to mint liquidity node tokens, I check the dai token balance in the contract, also the balance of their treasury address.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/cubo.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'cubo',
    cmcId: '18278',
    category: 'Yield',
    chains: ['Polygon'],
    oracles: [],
    forkedFrom: [],
    module: 'cubo/index.js',
    twitter: 'cubo_money',
    listedAt: 1646960451,
    slug: 'cubo',
    tvl: 100501.85160000001,
    chainTvls: { Polygon: 100501.85160000001, 'Polygon-Treasury': 323719.2871853245, Treasury: 323719.2871853245 },
    change_1h: 0,
    change_1d: 30.695864168435435,
    change_7d: -23.688170399885195,
    mcap: 0,
  },
  {
    id: '1113',
    name: 'Mama DAO',
    address: 'polygon:0xA9a779aeA33b6f40CfC78A29Cf56Fc7e6fb329AB',
    symbol: 'MAMA',
    url: 'http://mamadao.co',
    description:
      'MAMA aims to become a community-owned mutual fund that sets out to help build the future of Metaverse and DeFi gaming.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/mama-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Polygon'],
    module: 'mama-dao/index.js',
    twitter: 'OfficialMamaDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640641579,
    slug: 'mama-dao',
    tvl: 100244.47847163762,
    chainTvls: { Polygon: 100244.47847163762, 'Polygon-staking': 63944.75506051728, staking: 63944.75506051728 },
    change_1h: -0.2275449101796454,
    change_1d: 0.17676130009741087,
    change_7d: -1.8850599699794799,
    staking: 63944.75506051728,
  },
  {
    id: '267',
    name: 'S Finance',
    address: '0x8a6ACA71A218301c7081d4e96D64292D3B275ce0',
    symbol: 'SFG',
    url: 'https://s.finance/',
    description: 'S.Finance is a stable coin trading protocol, introducing a new token governance plan.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/s-finance.svg',
    audits: '2',
    audit_note: null,
    gecko_id: 's-finance',
    cmcId: '7187',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'sfinance/index.js',
    twitter: 'SFinanceEx',
    audit_links: ['https://s.finance/risks'],
    slug: 's-finance',
    tvl: 98454.1041121706,
    chainTvls: { Ethereum: 98454.1041121706 },
    change_1h: 0.0429566876321843,
    change_1d: 0.10016863217272487,
    change_7d: -0.1345788618604331,
    mcap: 0,
  },
  {
    id: '902',
    name: 'HSwap',
    address: null,
    symbol: '-',
    url: 'https://www.hswap.com/#/',
    description: 'An innovative DEX based on an automatic market maker mechanism on HECO',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/hswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '1438',
    category: 'Dexes',
    chains: ['Heco'],
    module: 'hswap.js',
    twitter: 'HswapDEX',
    forkedFrom: ['Uniswap'],
    audit_links: ['https://www.hswap.com/slowmist-zh.pdf', 'https://www.hswap.com/lianan-zh.pdf'],
    listedAt: 1637724237,
    slug: 'hswap',
    tvl: 96594.84080261888,
    chainTvls: { Heco: 96594.84080261888 },
    change_1h: 0.013970616955589321,
    change_1d: -7.1479526258900705,
    change_7d: -6.582902417756188,
  },
  {
    id: '701',
    name: 'Behodler',
    address: '0x155ff1A85F440EE0A382eA949f24CE4E0b751c65',
    symbol: 'EYE',
    url: 'https://behodler.io',
    description:
      'Efficient, low cost, single sided Automated Market Maker (AMM) with a universal liquidity token, $SCX. Offering lower impermanent loss than traditional AMMs, and shining as a wholesaler of yield strategies, for half the gas of alternative options.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/behodler.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'beholder',
    cmcId: '7414',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'behodler/index.js',
    twitter: 'BehodlerAMM',
    oracles: ['Internal'],
    listedAt: 1635103000,
    slug: 'behodler',
    tvl: 92290.19265353543,
    chainTvls: { Ethereum: 92290.19265353543 },
    change_1h: -0.22694610778442836,
    change_1d: 0.13642318833611,
    change_7d: 6.860713188965505,
    mcap: 1628591,
  },
  {
    id: '550',
    name: 'FreeRiver',
    address: 'moonriver:0x63f2adf5f76f00d48fe2cbef19000af13bb8de82',
    symbol: 'FREE',
    url: 'https://freeriver.exchange/#/swap',
    description: 'DEX on Moonriver network',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/freeriver.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'freeriver',
    cmcId: '11964',
    category: 'Dexes',
    chains: ['Moonriver'],
    module: 'freeriver/index.js',
    twitter: 'FreeRiverX',
    forkedFrom: ['Uniswap'],
    slug: 'freeriver',
    tvl: 90216.79353120206,
    chainTvls: { Moonriver: 90216.79353120206 },
    change_1h: 2.3717239338863862,
    change_1d: -0.15032544925239222,
    change_7d: 1.6936968181333896,
  },
  {
    id: '1141',
    name: 'Avalps',
    address: 'avax:0xf481Eec738C46F675e077ee966A680a19210Af11',
    symbol: 'BLANC',
    url: 'https://ape.avalps.finance/',
    description:
      'Avalps is a decentralized reserve currency protocol available on the Avalanche Network based on the $BLANC token',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/avalps.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'blanc',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'avalps/index.js',
    twitter: 'AvalpsFinance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640786874,
    slug: 'avalps',
    tvl: 88197.28884251093,
    chainTvls: { 'Avalanche-staking': 567.068937818055, Avalanche: 88197.28884251093, staking: 567.068937818055 },
    change_1h: -0.049710334536726464,
    change_1d: 0.12488220331565003,
    change_7d: -0.8887549679703852,
    staking: 567.068937818055,
    fdv: 194507,
    mcap: 0,
  },
  {
    id: '466',
    name: 'Vanilla',
    address: '0xbf900809f4c73e5a3476eb183d8b06a27e61f8e5',
    symbol: 'VNL',
    url: 'https://vanilladefi.com',
    description: 'A decentralized meritocracy for investors',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/vanilla.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'vanilla',
    cmcId: '9319',
    category: 'Derivatives',
    chains: ['Ethereum'],
    module: 'vanilla/index.js',
    twitter: 'VanillaDeFi',
    audit_links: ['https://github.com/vanilladefi/contracts/tree/main/audits'],
    slug: 'vanilla',
    tvl: 85762.6478235184,
    chainTvls: { Ethereum: 85762.6478235184 },
    change_1h: 3.5567431223931294,
    change_1d: 5.837000035715505,
    change_7d: 17.891860872175513,
    mcap: 0,
  },
  {
    id: '1318',
    name: 'Cesta Finance',
    address: null,
    symbol: '-',
    url: 'https://cesta.finance',
    description:
      "Cesta Finance is a next generation DAO reserve currency on Avalanche, combining the best of yield farming index funds and the 'protocol-owned-liquidity' token model of DeFi 2.0.",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/cesta-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'cesta/index.js',
    twitter: 'CestaFinance',
    audit_links: ['https://hacken.io/audits/#cesta_finance'],
    listedAt: 1643318054,
    slug: 'cesta-finance',
    tvl: 84462.5749498476,
    chainTvls: { Avalanche: 84462.5749498476 },
    change_1h: 0.6065695702612572,
    change_1d: 4.525360893172632,
    change_7d: 7.052304963260767,
  },
  {
    id: '1040',
    name: 'MFinance',
    address: '0x06b0c26235699b15e940e8807651568b995a8e01',
    symbol: 'MG',
    url: 'https://m.finance',
    description: 'M.finance is the first cross-chain mining contract in the entire network.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/mfinance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'mfinance/index.js',
    twitter: null,
    audit_links: ['https://github.com/MagallaneFinance/Audit-report'],
    listedAt: 1639802439,
    slug: 'mfinance',
    tvl: 84003.72124412404,
    chainTvls: {
      pool2: 10142.860482533688,
      masterchef: 84003.72124412404,
      'Ethereum-pool2': 10142.860482533688,
      'Ethereum-masterchef': 84003.72124412404,
      Ethereum: 84003.72124412404,
      'Ethereum-staking': 29448.724152083963,
      staking: 29448.724152083963,
    },
    change_1h: 0,
    change_1d: 0.9836066302972597,
    change_7d: 5.580399456238254,
    staking: 29448.724152083963,
    pool2: 10142.860482533688,
  },
  {
    id: '1313',
    name: 'NoahArkDAO',
    address: 'avax:0x61a47034276eb993e1c5e67bf1375ad0a48f10f6',
    symbol: 'NRK',
    url: 'https://noahark.money/#/',
    description:
      'NoahArkDAO is a fork of Olympus on multi-chain, starting from Avalanche. Designed to provide backup for Game-Fi crypto with stablecoins and Proof of Liquidity.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/noaharkdao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'noaharkdao/index.js',
    twitter: 'NoahArkDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1643090739,
    slug: 'noaharkdao',
    tvl: 83783.81845509185,
    chainTvls: { 'Avalanche-staking': 2681.43112882684, Avalanche: 83783.81845509185, staking: 2681.43112882684 },
    change_1h: 0,
    change_1d: 0.1681103239673547,
    change_7d: -0.3508849293732794,
    staking: 2681.43112882684,
  },
  {
    id: '857',
    name: 'AliensFarm',
    address: 'tezos:KT19DUSZw7mfeEATrbWVPHRrWNVbNnmfFAE6',
    symbol: 'PAUL',
    url: 'https://aliens.farm',
    description:
      "Open-source DEX on Tezos: create your own pools, farm, earn combo rewards. Let's develop the #Tezos #DeFi ecosystem together!",
    chain: 'Tezos',
    logo: 'https://icons.llama.fi/aliensfarm.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Tezos'],
    module: 'aliensfarm/index.js',
    twitter: 'tezaliensfarm',
    forkedFrom: ['QuipuSwap'],
    listedAt: 1637268641,
    slug: 'aliensfarm',
    tvl: 82542,
    chainTvls: { Tezos: 82542 },
    change_1h: -0.11375187569582579,
    change_1d: -0.11375187569582579,
    change_7d: -7.039969366954608,
  },
  {
    id: '1546',
    name: 'Burrow',
    address: null,
    symbol: '-',
    url: 'https://beta.burrow.cash',
    description:
      "Burrow is a decentralized, non-custodial pool-based interest rates platform that enables users to supply assets to earn interest, and to borrow against them to unlock liquidity. Burrow is similar in nature to Aave, Compound, and other pool-based protocols. Burrow runs natively on the NEAR blockchain, a layer 1, proof-of-stake, sharded blockchain with a WebAssembly runtime. The Burrow protocol's smart contracts are written in Rust.",
    chain: 'Near',
    logo: 'https://icons.llama.fi/burrow.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Near'],
    oracles: [],
    forkedFrom: [],
    module: 'burrow.cash/index.js',
    twitter: 'burrowcash',
    listedAt: 1647296278,
    slug: 'burrow',
    tvl: 80293.19956234154,
    chainTvls: { Near: 80293.19956234154 },
    change_1h: 1.687738163557981,
    change_1d: -10.221911620926079,
    change_7d: 8.707148082419948,
  },
  {
    id: '1068',
    name: 'Thoreum Finance',
    address: 'bsc:0x580dE58c1BD593A43DaDcF0A739d504621817c05',
    symbol: 'THOREUM',
    url: 'https://thoreum.finance/',
    description: 'The World First Liquidity Mining Coin with Static Rewards.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/thoreum-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'thoreum',
    cmcId: '10787',
    category: 'Yield',
    chains: ['Binance'],
    module: 'thoreum/index.js',
    twitter: 'ThoreumFinance',
    audit_links: [
      'https://github.com/TechRate/Smart-Contract-Audits/blob/main/Thoreum.pdf',
      'https://paladinsec.co/assets/audits/20210621_Paladin_Thoreum_Finance.pdf',
      'https://www.certik.com/projects/thoreumfinance',
    ],
    listedAt: 1640086003,
    slug: 'thoreum-finance',
    tvl: 80159.6253484252,
    chainTvls: {
      'Binance-masterchef': 80159.6253484252,
      pool2: 177878.26961396812,
      masterchef: 80159.6253484252,
      'Binance-pool2': 177878.26961396812,
      Binance: 80159.6253484252,
      'Binance-staking': 224476.24933746917,
      staking: 224476.24933746917,
    },
    change_1h: 0.17010450905416974,
    change_1d: 1.2503545522274635,
    change_7d: 7.823499556104068,
    staking: 224476.24933746917,
    pool2: 177878.26961396812,
    mcap: 2172873,
  },
  {
    id: '511',
    name: 'Frost Finance',
    address: 'avax:0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1',
    symbol: 'TUN',
    url: 'https://frostfinance.farm',
    description: 'Safe Yield Farming on AVAX',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/frost-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'tundra-token',
    cmcId: '11429',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'frostfinance/index.js',
    twitter: 'Frost_FI',
    slug: 'frost-finance',
    tvl: 79420.21272570624,
    chainTvls: {
      Avalanche: 79420.21272570624,
      'Avalanche-staking': 11774.829911416417,
      pool2: 17191.993483451566,
      'Avalanche-pool2': 17191.993483451566,
      staking: 11774.829911416417,
    },
    change_1h: 0.378929393977927,
    change_1d: 1.2615523501747106,
    change_7d: 5.609716583132979,
    staking: 11774.829911416417,
    pool2: 17191.993483451566,
    fdv: 234425,
    mcap: 0,
  },
  {
    id: '1380',
    name: 'Minimax',
    address: null,
    symbol: '-',
    url: 'https://www.minimax.finance',
    description:
      'Minimax.finance is an aggregator of staking and yield farming opportunities with stop loss and take profit.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/minimax.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    module: 'minimax/index.js',
    twitter: 'MinimaxFinance',
    language: 'Solidity',
    listedAt: 1644446314,
    slug: 'minimax',
    tvl: 78394.7628625036,
    chainTvls: { Binance: 78394.7628625036 },
    change_1h: 1.7327928953305474,
    change_1d: 2.2849898165490146,
    change_7d: 2887.1597317598366,
  },
  {
    id: '1382',
    name: 'Waterfall BSC',
    address: 'bsc:0xFdf36F38F5aD1346B7f5E4098797cf8CAE8176D0',
    symbol: 'WATERFALL',
    url: 'https://bsc.defiwaterfall.com',
    description: 'Waterfall, a new Stable & Smart DeFi Token on BSC Network',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/waterfall-bsc.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    oracles: [],
    module: 'waterfallbsc/index.js',
    twitter: 'defi_waterfall',
    language: 'Solidity',
    audit_links: ['https://paladinsec.co/projects/waterfall-finance/'],
    listedAt: 1644448582,
    slug: 'waterfall-bsc',
    tvl: 75610.710593149,
    chainTvls: {
      'Binance-masterchef': 75610.71059314898,
      pool2: 1305.972395882475,
      masterchef: 75610.71059314898,
      'Binance-pool2': 1305.972395882475,
      Binance: 75610.710593149,
      'Binance-staking': 5257.793911289673,
      staking: 5257.793911289673,
    },
    change_1h: 0.0053251103573614955,
    change_1d: 0.8830950771166783,
    change_7d: -4.356658887600219,
    staking: 5257.793911289673,
    pool2: 1305.972395882475,
  },
  {
    id: '979',
    name: 'PartySwap',
    address: 'avax:0x25afd99fcb474d7c336a2971f26966da652a92bc',
    symbol: 'PARTY',
    url: 'https://app.partyswap.io/#/home',
    description:
      'PartySwap is an application focused on bringing liquidity to the Avalanche Ecosystem through different activities starting with an AMM (Automated Market Maker)',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/partyswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'party-v2',
    cmcId: '1475',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'partyswap/index.js',
    twitter: 'partyswapdex',
    audit_links: ['https://solidity.finance/audits/PartySwap'],
    forkedFrom: ['Uniswap'],
    listedAt: 1638690045,
    slug: 'partyswap',
    tvl: 72917.95704012278,
    chainTvls: { Avalanche: 72917.95704012278, 'Avalanche-staking': 2982.9520424133125, staking: 2982.9520424133125 },
    change_1h: 0.3704879856039014,
    change_1d: 2.410262159627962,
    change_7d: -25.119491152347408,
    staking: 2982.9520424133125,
  },
  {
    id: '997',
    name: 'Piggy',
    address: 'bsc:0x1beac6df550be0ad146dd99b4726c6bec9c5c6a5',
    symbol: 'PIGGY',
    url: 'https://piggy.fi',
    description:
      'Piggy is a decentralized borrowing protocol that offers interest-free, efficiently collateralized loans backed by $BNB.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/piggy.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'piggy',
    cmcId: '12735',
    category: 'CDP',
    chains: ['Binance'],
    module: 'piggy/index.js',
    twitter: 'Piggy_Fi',
    audit_links: ['https://www.certik.org/projects/piggy'],
    forkedFrom: ['Liquity'],
    listedAt: 1638999656,
    slug: 'piggy',
    tvl: 71996.06181963731,
    chainTvls: { Binance: 71996.06181963731 },
    change_1h: -0.018185124568105948,
    change_1d: 1.2716896198404157,
    change_7d: -79.42178602140046,
    fdv: 473749,
    mcap: 0,
  },
  {
    id: '1163',
    name: 'Trick or Treat Farm',
    address: 'fantom:0xA5aFce54270D9afA6a80464bBD383BE506888e6A',
    symbol: 'TRICK',
    url: 'https://trickortreat.farm/',
    description:
      'Trick or Treat Farm aims to provide a safe and fortuitous place for newcomers and veterans alike to increase the yields on cryptocurrency tokens deposited in the farm.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/trick-or-treat-farm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'trick',
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'trickortreat/index.js',
    twitter: 'tricktreatfarm',
    audit_links: [
      'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_TrickOrTreat.pdf',
    ],
    forkedFrom: ['Goose Finance'],
    listedAt: 1640909035,
    slug: 'trick-or-treat-farm',
    tvl: 71877.63970376091,
    chainTvls: {
      pool2: 2319.0597469388913,
      'Fantom-staking': 4063.0464536760546,
      'Fantom-pool2': 2319.0597469388913,
      staking: 4063.0464536760546,
      Fantom: 71877.63970376091,
    },
    change_1h: -0.003293593014703333,
    change_1d: -3.3040902262755623,
    change_7d: 0.2599452543067713,
    staking: 4063.0464536760546,
    pool2: 2319.0597469388913,
    fdv: 228.58,
    mcap: 0,
  },
  {
    id: '1288',
    name: 'Mover',
    address: '0x3fa729b4548becbad4eab6ef18413470e6d5324c',
    symbol: 'MOVE',
    url: 'https://viamover.com',
    description:
      'Mover helps you earn the highest and safest yield on your digital assets with our automatic portfolio management system. You can also send, receive and swap 30000+ digital assets on Ethereum.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/moverdao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'holyheld-2',
    cmcId: '7371',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'mover/index.js',
    twitter: 'viaMover',
    listedAt: 1642594631,
    slug: 'mover',
    tvl: 69302.87465286754,
    chainTvls: {
      Ethereum: 69302.87465286754,
      pool2: 657043.2146838224,
      'Ethereum-staking': 1771955.0626095342,
      staking: 1771955.0626095342,
      'Ethereum-pool2': 657043.2146838224,
    },
    change_1h: -0.11158841158841426,
    change_1d: -0.011700000000018917,
    change_7d: -0.011700000000018917,
    staking: 1771955.0626095342,
    pool2: 657043.2146838224,
    mcap: 3948352,
  },
  {
    id: '1439',
    name: 'The Don Finance',
    address: 'fantom:0x62e96896d417dd929a4966f2538631ad5af6cb46',
    symbol: 'THEDON',
    url: 'https://thedon.finance',
    description: 'Defi yield farm and smart token on Fantom',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/the-don-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'thedon/index.js',
    twitter: 'TheDonFinance1',
    listedAt: 1645301986,
    slug: 'the-don-finance',
    tvl: 67667.86651193486,
    chainTvls: {
      pool2: 211.17151073113288,
      'Fantom-staking': 79.20212752951036,
      masterchef: 67667.86651193486,
      'Fantom-masterchef': 67667.86651193486,
      'Fantom-pool2': 211.17151073113288,
      Fantom: 67667.86651193486,
      staking: 79.20212752951036,
    },
    change_1h: 0.4025162120597514,
    change_1d: 1.946289033479644,
    change_7d: 4.868841011346149,
    staking: 79.20212752951036,
    pool2: 211.17151073113288,
  },
  {
    id: '640',
    name: 'KingDefi',
    address: 'bsc:0x1446f3cedf4d86a9399e49f7937766e6de2a3aab',
    symbol: 'KRW',
    url: 'https://kingdefi.io',
    description:
      'KingDeFi is a DeFi project combining two main areas: analytics and monitoring where we provide a market overview, liquidity pool search engine and portfolio tracking to users and farming as we are a yield optimizer project on BSC and Solana.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kingdefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'krown',
    cmcId: '11024',
    category: 'Yield',
    chains: ['Binance', 'Polygon'],
    module: 'kingdefi/index.js',
    twitter: 'KingDefi2',
    audit_links: [
      'https://hacken.io/wp-content/uploads/2021/09/KingDefi_16092021_SCAudit_Report_4.pdf',
      'https://kingdefi.io/assets/files/CertikAuditFarms.pdf',
      'https://kingdefi.io/assets/files/CertikAuditPools.pdf',
      'https://kingdefi.io/assets/files/AuditFarms.pdf',
    ],
    slug: 'kingdefi',
    tvl: 66237.74728714503,
    chainTvls: {
      Binance: 58780.22625587688,
      'Binance-staking': 162228.99971753548,
      Polygon: 7457.521031268155,
      staking: 162228.99971753548,
    },
    change_1h: 0.2908493117603257,
    change_1d: 3.183864594198724,
    change_7d: 9.376864196917495,
    staking: 162228.99971753548,
    fdv: 289319,
    mcap: 211018,
  },
  {
    id: '479',
    name: 'Shiba Fantom',
    address: 'fantom:0x9ba3e4f84a34df4e08c112e1a0ff148b81655615',
    symbol: 'SHIBA',
    url: 'https://shibafantom.finance',
    description: 'A collection of DEX, Token, and NFT Market!',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/shiba-fantom.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'shiba-fantom',
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'Shibafantom/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'ShibaFantom',
    slug: 'shiba-fantom',
    tvl: 66042.92672544823,
    chainTvls: { Fantom: 66042.92672544823 },
    change_1h: 1.0920293950157571,
    change_1d: 0.3451958398294437,
    change_7d: 9.073388787697652,
    mcap: 0,
  },
  {
    id: '1037',
    name: 'RagnarokDAO',
    address: 'avax:0x46fCCf447f74ec97a521615fcE111118597071f7',
    symbol: 'RGK',
    url: 'https://app.ragnarokdao.finance/',
    description: 'RagnarokDAO is a decentralized reserve currency on the Avalanche network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/ragnarokdao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'ragnarokdao',
    cmcId: '16099',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'ragnarokdao/index.js',
    twitter: 'RagnarokDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639797366,
    slug: 'ragnarokdao',
    tvl: 65392.36465617564,
    chainTvls: { 'Avalanche-staking': 45351.462357292316, Avalanche: 65392.36465617564, staking: 45351.462357292316 },
    change_1h: 0,
    change_1d: 0.060574612902655645,
    change_7d: -0.9285277282474453,
    staking: 45351.462357292316,
    fdv: 64128874,
    mcap: 0,
  },
  {
    id: '884',
    name: 'CorgiSwap',
    address: 'bsc:0x2a2cD8b1F69EB9dda5d703b3498d97080C2F194F',
    symbol: 'CORIS',
    url: 'https://corgiswap.org',
    description:
      'The most popular AMM on BSC! Earn CORIS through yield farming, then stake it in Pools to earn more tokens! I, NFTs, Wallet and more',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/corgiswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '11832',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'corgiswap.js',
    twitter: 'corgidogeestate',
    forkedFrom: ['Uniswap'],
    listedAt: 1637394147,
    slug: 'corgiswap',
    tvl: 63980.69165912265,
    chainTvls: { Binance: 63980.69165912265, 'Binance-staking': 109672.22212921437, staking: 109672.22212921437 },
    change_1h: -0.2843429379157527,
    change_1d: 1.2310405877862536,
    change_7d: 10.719703336527303,
    staking: 109672.22212921437,
  },
  {
    id: '1184',
    name: 'CyberTime',
    address: 'bsc:0x655a46cd88e18a338ece048228a388c25bfda9f3',
    symbol: 'CTF',
    url: 'https://cybertime.finance',
    description:
      'CyberTime Finance is a decentralized application platform designed to be an ecosystem containing farming, DAO, NFT, Mystery Boxes and a game called Daopolis. The project runs on Binance Smart Chain (BSC).',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/cybertime.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'cybertime-finance',
    cmcId: '8791',
    category: 'Services',
    chains: ['Binance'],
    module: 'cybertime/index.js',
    twitter: 'cybertime_eth',
    audit_links: ['https://www.certik.com/projects/cybertime'],
    listedAt: 1641242781,
    slug: 'cybertime',
    tvl: 63797.574064025015,
    chainTvls: {
      Binance: 63797.574064025015,
      'Binance-staking': 2909.861785928555,
      pool2: 21610.911229639183,
      'Binance-pool2': 21610.911229639183,
      staking: 2909.861785928555,
    },
    change_1h: 0.307803156980583,
    change_1d: 3.1633623372853634,
    change_7d: 9.978665402318626,
    staking: 2909.861785928555,
    pool2: 21610.911229639183,
    fdv: 34407,
    mcap: 0,
  },
  {
    id: '1334',
    name: 'PearZap',
    address: 'bsc:0xdf7c18ed59ea738070e665ac3f5c258dcc2fbad8',
    symbol: 'PEAR',
    url: 'https://app.pearzap.com',
    description: 'High yields farming and cross-chain - #Polygon & #BSC',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/pearzap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: '11105',
    category: 'Yield',
    chains: ['Polygon', 'Binance', 'Fantom'],
    module: 'pearzap/index.js',
    twitter: 'pearzap',
    listedAt: 1643486822,
    slug: 'pearzap',
    tvl: 63179.0330981967,
    chainTvls: {
      'Binance-masterchef': 17206.213153442342,
      pool2: 15329.259208534691,
      'Fantom-staking': 2586.7311074770464,
      'Polygon-pool2': 8530.58996225181,
      masterchef: 63179.0330981967,
      'Binance-pool2': 3384.886719704086,
      'Polygon-masterchef': 45261.47693611064,
      Binance: 17206.21315344234,
      'Binance-staking': 1108.4701626904746,
      Polygon: 45261.47693611064,
      'Polygon-staking': 3060.754763781522,
      'Fantom-masterchef': 711.3430086437216,
      'Fantom-pool2': 3413.782526578797,
      Fantom: 711.3430086437216,
      staking: 6755.956033949043,
    },
    change_1h: 0.5609192321435188,
    change_1d: 0.8628522101467411,
    change_7d: -0.9228341199372636,
    staking: 6755.956033949043,
    pool2: 15329.259208534691,
  },
  {
    id: '1101',
    name: 'SecureDAO',
    address: 'fantom:0x8183C18887aC4386CE09Dbdf5dF7c398DAcB2B5a',
    symbol: 'SCR',
    url: 'https://www.securedao.finance/',
    description:
      'SecureDAO is the decentralized reserve currency protocol available on the Fantom Network based on the SCR token.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/securedao.png',
    audit: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Fantom'],
    module: 'securedao/index.js',
    twitter: 'SecureDao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640537322,
    slug: 'securedao',
    tvl: 61849.66575899401,
    chainTvls: { 'Fantom-staking': 91947.13753522553, staking: 91947.13753522553, Fantom: 61849.66575899401 },
    change_1h: 0.27199293324878226,
    change_1d: 0.1210746819387225,
    change_7d: -0.7768699259116687,
    staking: 91947.13753522553,
  },
  {
    id: '1413',
    name: 'Savanna Finance',
    address: 'cronos:0x654bAc3eC77d6dB497892478f854cF6e8245DcA9',
    symbol: 'SVN',
    url: 'https://svn.finance',
    description:
      'The first and also largest algorithmic stablecoin platform on Cronos. $SVN is pegged to the price of 1 MMF via seigniorage.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/savannah-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'savanna',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Cronos'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'svn/index.js',
    twitter: 'MMFcrypto',
    audit_links: ['https://mmfinance.gitbook.io/docs/audit'],
    listedAt: 1644884068,
    slug: 'savanna-finance',
    tvl: 61762.51464922521,
    chainTvls: {
      Cronos: 61762.51464922521,
      pool2: 183438733.3072111,
      'Cronos-pool2': 183438733.3072111,
      'Cronos-staking': 121396865.13252379,
      staking: 121396865.13252379,
    },
    change_1h: -0.42997688933731126,
    change_1d: -1.3192561010907013,
    change_7d: -23.53420639028724,
    staking: 121396865.13252379,
    pool2: 183438733.3072111,
    mcap: 71713157,
  },
  {
    id: '323',
    name: 'Fluity',
    address: 'bsc:0x83b325dbA77d55644619a4Ff29D42EE4487BCf31',
    symbol: 'FLTY',
    url: 'https://fluity.finance/',
    description:
      'http://Fluity.finance is a decentralized borrowing protocol with interest-free loans, high capital efficiency, and censorship-resistant stablecoins on BSC.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/fluity.png',
    audits: '3',
    audit_note: null,
    gecko_id: 'fluity',
    cmcId: '9722',
    category: 'CDP',
    chains: ['Binance'],
    module: 'fluity/index.js',
    twitter: 'FluityFinance',
    audit_links: ['https://docs.fluity.finance/audits'],
    forkedFrom: ['Liquity'],
    slug: 'fluity',
    tvl: 61577.6,
    chainTvls: { Binance: 61577.6 },
    change_1h: -0.01818512456812016,
    change_1d: 1.3432870210363888,
    change_7d: 8.145853310712809,
    mcap: 0,
  },
  {
    id: '531',
    name: 'Kuu Finance',
    address: '-',
    symbol: '-',
    url: 'https://kuu.finance',
    description:
      'KUU is a decentralized protocol designed to provide reinforcing liquidity to on-chain keepers to maximize on-chain profit through liquidations, DEX arbitrage and other on-chain opportunities.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/kuu-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'kuufinance/index.js',
    twitter: 'KuuFinance',
    slug: 'kuu-finance',
    tvl: 60062.00863330533,
    chainTvls: { Avalanche: 60062.00863330533 },
    change_1h: 0.9698908771434986,
    change_1d: 3.326824673729732,
    change_7d: 7.750868765512209,
  },
  {
    id: '1344',
    name: 'FATFIRE DEFI',
    address: 'fantom:0xa5ee311609665Eaccdbef3BE07e1223D9dBe51de',
    symbol: 'FATFIRE',
    url: 'https://fatfire.finance/',
    description:
      'FATFIRE is a new Smart DeFi Rewards Token & Yield Generator on the Fantom Network - with ZERO deposit fees.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/fatfire.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'fatfire/index.js',
    twitter: 'DefiFatfire',
    audit_links: ['https://rugdoc.io/project/fatfire-finance/'],
    listedAt: 1643805926,
    slug: 'fatfire-defi',
    tvl: 59801.65779061886,
    chainTvls: {
      pool2: 593.0939642047698,
      'Fantom-staking': 186.04474948222094,
      masterchef: 59801.657790618854,
      'Fantom-masterchef': 59801.657790618854,
      'Fantom-pool2': 593.0939642047698,
      Fantom: 59801.65779061886,
      staking: 186.04474948222094,
    },
    change_1h: 0.5606405733146005,
    change_1d: 6.140759847970671,
    change_7d: -4.894020183725871,
    staking: 186.04474948222094,
    pool2: 593.0939642047698,
  },
  {
    id: '904',
    name: 'Typhoon Cash',
    address: '0x79256db1bdb6259315a1a3d7dd237f69cadfd8fc',
    symbol: 'PHOON',
    url: 'https://typhoon.cash',
    description: 'A New Protocol for Yield-Capable Private Transactions.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/typhoon-cash.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'typhoon-cash',
    cmcId: null,
    category: 'Privacy',
    chains: ['Ethereum'],
    module: 'typhoon.js',
    twitter: 'TyphoonCash',
    audit_links: ['https://drive.google.com/file/d/1AZlcJmF1WJE1km3p0ajUKP6vTqhcvqh3/view'],
    forkedFrom: ['Tornado Cash'],
    listedAt: 1637727721,
    slug: 'typhoon-cash',
    tvl: 59408.50875,
    chainTvls: { Ethereum: 59408.50875 },
    change_1h: 0.6233285570915541,
    change_1d: 4.025344316270193,
    change_7d: 15.352847485693587,
    mcap: 0,
  },
  {
    id: '741',
    name: 'Vaulty Finance',
    address: 'bsc:0x38a5cbe2fb53d1d407dd5a22c4362daf48eb8526',
    symbol: 'VLTY',
    url: 'https://vaulty.fi',
    description: 'Automatic Yield Optimizer with lowest fees',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/vaulty-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'vaulty-token',
    cmcId: '11189',
    category: 'Yield',
    chains: ['Binance'],
    module: 'vaulty/index.js',
    twitter: 'VaultyFi',
    audit_links: [
      'https://vaultyfinance.s3.eu-central-1.amazonaws.com/Vaulty-Audit-by-Web3Go.pdf',
      'https://www.rdauditors.com/wp-content/uploads/2021/10/Vaulty-Smart-Contract-Security-Audit-Report.pdf',
    ],
    oracles: ['TWAP'],
    forkedFrom: ['Harvest Finance'],
    listedAt: 1635803932,
    slug: 'vaulty-finance',
    tvl: 59361.54594956095,
    chainTvls: { Binance: 59361.54594956095, 'Binance-staking': 19214.85978525624, staking: 19214.85978525624 },
    change_1h: -0.27664497104788666,
    change_1d: 1.2180125953437937,
    change_7d: -4.641541339738893,
    staking: 19214.85978525624,
    mcap: 0,
  },
  {
    id: '947',
    name: 'PolyShield',
    address: 'polygon:0xf239e69ce434c7fb408b05a0da416b14917d934e',
    symbol: 'SHI3LD',
    url: 'https://polyshield.finance',
    description: 'PolyShield is a yield farming protocol on Polygon',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polyshield.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'polyshield',
    cmcId: '11580',
    category: 'Yield',
    chains: ['Polygon'],
    module: 'polyshield/index.js',
    twitter: 'TeamPolyshield',
    audit_links: ['https://paladinsec.co/projects/polyshield', 'https://rugdoc.io/project/polyshield-finance'],
    listedAt: 1638358117,
    slug: 'polyshield',
    tvl: 59089.01103384793,
    chainTvls: {
      pool2: 2257.696829317749,
      'Polygon-pool2': 2257.696829317749,
      masterchef: 59089.011033847935,
      'Polygon-masterchef': 59089.011033847935,
      Polygon: 59089.01103384793,
      'Polygon-staking': 902.2902372452318,
      staking: 902.2902372452318,
    },
    change_1h: 0.17125507367066461,
    change_1d: 1.5550763775243723,
    change_7d: 8.226272023821977,
    staking: 902.2902372452318,
    pool2: 2257.696829317749,
    mcap: 0,
  },
  {
    id: '974',
    name: 'TaiChi',
    address: 'bsc:0xe49bfc53a195a62d78a941a1967d7b0f83a47c14',
    symbol: 'TAC',
    url: 'https://www.taichidao.io',
    description:
      'TaiChi is a decentralized reserve currency protocol available on the BSC (Binance Smart Chain) network based on the TAC token. Each TAC token is backed by a basket of assets (e.g., BUSD, BUSD-TAC LP, wBNB, etc.)',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/taichi.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'taichi',
    cmcId: '13743',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'taichi/index.js',
    twitter: 'TaiChi_Dao_',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638599279,
    slug: 'taichi',
    tvl: 58793.56800230078,
    chainTvls: { Binance: 58793.56800230078, 'Binance-staking': 0.000032802763668, staking: 0.000032802763668 },
    change_1h: -0.19003083005860333,
    change_1d: 0.06217995358461792,
    change_7d: -0.3408542171673048,
    staking: 0.000032802763668,
    mcap: 26927,
  },
  {
    id: '1520',
    name: 'Paraluni',
    address: 'bsc:0x43Feaca246392C23EE5A0Db618fCd81a74b91726',
    symbol: 'T42',
    url: 'https://paraluni.org',
    description: 'Paraluni,based on the BSC,a no holding currency, no ICO, no pre-mining decentralized platform.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/paraluni.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'Paraluni/index.js',
    twitter: 'paraluni',
    audit_links: ['http://www.auditlions.com/en/index.php?c=index&id=65'],
    listedAt: 1646692701,
    slug: 'paraluni',
    tvl: 58754.86612562966,
    chainTvls: { Binance: 58754.86612562966, pool2: 88.06452168591397, 'Binance-pool2': 88.06452168591397 },
    change_1h: -0.22752154645874612,
    change_1d: 0.10150546756284484,
    change_7d: -32.84368565019771,
    pool2: 88.06452168591397,
  },
  {
    id: '439',
    name: 'The Grand Banks',
    address: 'bsc:0xee814f5b2bf700d2e843dc56835d28d095161dd9 ',
    symbol: 'GRAND',
    url: 'https://www.thegrandbanks.finance',
    description:
      'The Grand Banks brings yield farming back to basics. No barrier for entry, no multiple steps to farming — just the simple, easy depositing of your token of choice to start yield farming.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/the-grand-banks.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'the-grand-banks',
    cmcId: '11276',
    category: 'Yield',
    chains: ['Binance', 'Polygon', 'Moonriver'],
    module: 'thegrandbanks/index.js',
    twitter: 'Grandbanks13',
    audit_links: ['https://www.certik.org/projects/grandbanks'],
    slug: 'the-grand-banks',
    tvl: 57232.10803792897,
    chainTvls: {
      Moonriver: 1914.9972462859005,
      Binance: 48678.91396001511,
      'Binance-staking': 1889.2112766524049,
      Polygon: 6638.196831627959,
      'Polygon-staking': 0,
      staking: 1889.2112766524049,
    },
    change_1h: 0.08880853397599253,
    change_1d: 1.305216493196724,
    change_7d: 1.0316637787742593,
    staking: 1889.2112766524049,
    mcap: 0,
  },
  {
    id: '1580',
    name: 'LowcostSwap',
    address: 'bsc:0xDBfe47255CbA4A7623985444E730719E9F958E67',
    symbol: 'LOWCOST',
    url: 'https://www.lowcostswap.com/',
    description:
      'At Lowcostswap, in addition to opting for very low deposit fees and practically zero transfer fees, we also choose to maintain the pioneering idea of decentralized finance, Keep it simple!',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/lowcostswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'lowcostswap/index.js',
    twitter: 'LowcostSwap',
    audit_links: [
      'https://rugdoc.io/project/lowcost-swap/',
      'https://github.com/lowcostswap/Audit/blob/main/Lowcost.pdf',
      'https://jagosafer.io/lowcost-swap',
    ],
    listedAt: 1648210430,
    slug: 'lowcostswap',
    tvl: 56594.99540135712,
    chainTvls: {
      'Binance-masterchef': 56594.99540135712,
      pool2: 1808.1245133972282,
      masterchef: 56594.99540135712,
      'Binance-pool2': 1808.1245133972282,
      Binance: 56594.99540135712,
      'Binance-staking': 995.3458382342927,
      staking: 995.3458382342927,
    },
    change_1h: 0.71265122040667,
    change_1d: 4.704631211543344,
    change_7d: null,
    staking: 995.3458382342927,
    pool2: 1808.1245133972282,
  },
  {
    id: '1370',
    name: 'Symblox',
    address: 'velas:VL31RHdHR79Ko3SBZthwpoxCJSGoYeiHJh',
    symbol: 'SYX',
    url: 'https://symblox.io',
    description: 'Symblox is a decentralized synthetic asset issuance and trading protocol on the blockchain.',
    chain: 'Velas',
    logo: 'https://icons.llama.fi/symblox.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Synthetics',
    chains: ['Velas'],
    module: 'symblox.js',
    twitter: 'SymbloxDeFi',
    language: 'Solidity',
    audit_links: ['https://github.com/symblox/symblox-yield-farming/tree/master/audit'],
    listedAt: 1644230892,
    slug: 'symblox',
    tvl: 55907.549518451255,
    chainTvls: { Velas: 55907.549518451255 },
    change_1h: 0.1985238905132718,
    change_1d: 1.801240247081438,
    change_7d: 11.702460999033732,
  },
  {
    id: '1287',
    name: 'Crafting Finance',
    address: '0x508df5aa4746be37b5b6a69684dfd8bdc322219d',
    symbol: 'CRF',
    url: 'https://crafting.finance',
    description: 'Crafting is a protocol for forging and trading Rafts, which are smart synthetic assets',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/omnidex.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'crafting-finance',
    cmcId: '16100',
    category: 'Synthetics',
    chains: ['Aurora', 'Ethereum'],
    module: 'crafting/index.js',
    twitter: 'CraftingFinance',
    listedAt: 1642594471,
    slug: 'crafting-finance',
    tvl: 55757.82260619855,
    chainTvls: { Ethereum: 2320.9959101613, Aurora: 53436.82669603725 },
    change_1h: 2.0969202475511537,
    change_1d: 2.772948654963983,
    change_7d: 20.301626096826993,
    fdv: 37404287,
    mcap: 0,
  },
  {
    id: '1023',
    name: 'Overnight',
    address: null,
    symbol: '-',
    url: 'https://ovnstable.io',
    description: 'Overnight is a DeFi protocol behind USD+, a yield generating stablecoin with daily profit payout.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/overnight.jpg',
    audits: '1',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Polygon'],
    module: 'ovnstable.js',
    twitter: 'ovnstable',
    audit_links: ['https://github.com/ovnstable/ovnstable-app/blob/master/audits/hacken_2022-02-03.pdf'],
    listedAt: 1639428732,
    slug: 'overnight',
    tvl: 55650.86955236847,
    chainTvls: { Polygon: 55650.86955236847 },
    change_1h: -0.11130407973699619,
    change_1d: -0.35273529569965945,
    change_7d: 111.7484880369934,
  },
  {
    id: '1080',
    name: 'XUSD Money',
    address: '0x1c9BA9144505aaBa12f4b126Fda9807150b88f80',
    symbol: 'XUSD',
    url: 'https://xusd.money',
    description: 'Fairly launched partial-collateralized stablecoin.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/xusd-money.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '8316',
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'xusdmoney/index.js',
    twitter: 'xusd_stable',
    forkedFrom: ['Frax'],
    listedAt: 1640222404,
    slug: 'xusd-money',
    tvl: 54368.25518303413,
    chainTvls: {
      Ethereum: 54368.25518303413,
      pool2: 1915.4263898245076,
      'Ethereum-staking': 0,
      staking: 0,
      'Ethereum-pool2': 1915.4263898245076,
    },
    change_1h: 0.2252235561847442,
    change_1d: 2.016640236923209,
    change_7d: 7.03328071333118,
    staking: 0,
    pool2: 1915.4263898245076,
  },
  {
    id: '1436',
    name: 'SpectreFi',
    address: 'fantom:0x2834d4F4cC40bd7D78c07E2D848358364222C272',
    symbol: 'SPECT',
    url: 'https://spectrefi.com',
    description:
      'Revolutionary DeFi Yield Farm on Fantom chain with referral system to give you that extra boost. Astral Farm also has auto-burn and auto-liquidity to ensure sustainably native price.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/spectrefi.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'spectrefi/index.js',
    twitter: 'SpectreDefi',
    listedAt: 1645299360,
    slug: 'spectrefi',
    tvl: 51647.393513864765,
    chainTvls: {
      pool2: 595.1264451808829,
      'Fantom-staking': 0,
      'Fantom-pool2': 595.1264451808829,
      staking: 0,
      Fantom: 51647.393513864765,
    },
    change_1h: 0.000003214426300246487,
    change_1d: 0.8884208057181837,
    change_7d: -26.45818028537154,
    staking: 0,
    pool2: 595.1264451808829,
  },
  {
    id: '1320',
    name: 'Railgun',
    address: '0xe76c6c83af64e4c60245d8c7de953df673a7a33d',
    symbol: 'RAIL',
    url: 'https://railgun.org',
    description:
      'RAILGUN secures privacy for DEX trading and lending. Built without any bridge or layer-2, RAILGUN is a smart contract system that gives zk-SNARK privacy to any transaction or smart contract interaction on Ethereum.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/railgun.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'railgun',
    cmcId: '3271',
    category: 'Privacy',
    chains: ['Ethereum', 'Binance', 'Polygon'],
    module: 'railgun/index.js',
    twitter: 'railgun_project',
    audit_links: [
      'http://assets.railgun.ch/docs/audits/Railgun%20Audit%20Report%20-%20Zokyo%20November%202021.pdf',
      'https://bafybeiakdhweojvf7zvtp2tr32alzam4yyifbolf7fgrmsshalea7fv55m.ipfs.cf-ipfs.com/docs/Railgun%20Audit%20Report%20-%20ABDK%20July%202021.pdf',
    ],
    listedAt: 1643326249,
    slug: 'railgun',
    tvl: 50780.5949090278,
    chainTvls: {
      Binance: 0,
      'Ethereum-Treasury': 7424223.508138151,
      Ethereum: 50780.5949090278,
      'Binance-staking': 0,
      'Ethereum-staking': 98751809.60246907,
      Polygon: 0,
      'Polygon-staking': 0,
      'Binance-Treasury': 0,
      'Polygon-Treasury': 0,
      Treasury: 7424223.508138151,
      staking: 98751809.60246907,
    },
    change_1h: 0.09853031919733723,
    change_1d: 0.7456844324644578,
    change_7d: 5.7256732343626595,
    staking: 98751809.60246907,
    fdv: 226871800,
    mcap: 130451285,
  },
  {
    id: '1221',
    name: 'Argano',
    address: 'polygon:0x4e125214Db26128B35c24c66113C63A83029e433',
    symbol: 'AGO',
    url: 'https://www.argano.io',
    description:
      'The protocol for yield generation and DEX trading, created by & for the DeFi community. Providing synthetic assets: USD and BTC pegged tokens.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/argano.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Synthetics',
    chains: ['Polygon'],
    module: 'argano/index.js',
    twitter: 'argano_io',
    audit_links: ['https://github.com/Tibereum/obelisk-audits/blob/main/Argano.pdf'],
    oracles: ['Chainlink', 'TWAP'],
    forkedFrom: ['Frax', 'Uniswap'],
    listedAt: 1641764731,
    slug: 'argano',
    tvl: 50117.53290095505,
    chainTvls: { Polygon: 50117.53290095505 },
    change_1h: -0.702394718564932,
    change_1d: -1.0754068916929498,
    change_7d: -18.660873021128396,
  },
  {
    id: '898',
    name: 'BeGlobal Finance',
    address: 'bsc:0xcF958B53EC9340886d72bb4F5F2977E8C2aB64D3',
    symbol: 'GLB',
    url: 'https://dapp.beglobal.finance/',
    description:
      'BeGlobal.finance aims to be your preferred DeFi site offering trading (swaps), optimizer (auto-compound), NFTs with multiple utilies and highest yields vaults, among with other features that will be released in the near future.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/beglobal.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'beglobal-finance',
    cmcId: '14459',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'beglobal/index.js',
    twitter: 'Beglobaldefi',
    listedAt: 1637698204,
    slug: 'beglobal-finance',
    tvl: 48942.84783356753,
    chainTvls: { Binance: 48942.84783356753, 'Binance-staking': 637.6409051708697, staking: 637.6409051708697 },
    change_1h: 0.044869815571260574,
    change_1d: 1.5965082090482383,
    change_7d: -17.2527969080205,
    staking: 637.6409051708697,
    mcap: 0,
  },
  {
    id: '975',
    name: 'Omicron',
    address: 'arbirtum:0x86b3353387f560295a8fa7902679735e5f076bd5',
    symbol: 'OMIC',
    url: 'https://omic.finance',
    description:
      'Omicron is a decentralized reserve currency protocol available on the Arbitrum Network based on the OMIC token. Each OMIC token is backed by a basket of assets (e.g. MIM, OMIC-MIM LP) in the OMIC treasury, giving it an intrinsic value that it cannot fall below.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/omicron.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'omicron',
    cmcId: '14095',
    category: 'Reserve Currency',
    chains: ['Arbitrum'],
    module: 'omicron/index.js',
    twitter: 'OmicDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638600191,
    slug: 'omicron',
    tvl: 48566.39434903712,
    chainTvls: { 'Arbitrum-staking': 14729.26546806228, Arbitrum: 48566.39434903712, staking: 14729.26546806228 },
    change_1h: 0.03462901461195145,
    change_1d: 0.025991403304971072,
    change_7d: -5.635095083336822,
    staking: 14729.26546806228,
    fdv: 3527461,
    mcap: 0,
  },
  {
    id: '627',
    name: 'Mean Finance',
    address: '-',
    symbol: '-',
    url: 'https://mean.finance',
    description:
      'Mean Finance is the state-of-the-art DCA protocol. Our protocol enables users to Dollar Cost Average (DCA) any ERC20 into any ERC20 with their preferred period frequency by leveraging the Uniswap V3 TWAP oracles.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/mean-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'meanfinance/index.js',
    twitter: 'mean_fi',
    oracles: ['TWAP'],
    slug: 'mean-finance',
    tvl: 48097.78073051093,
    chainTvls: { Ethereum: 48097.78073051093 },
    change_1h: 0.33197031779698705,
    change_1d: 2.3618587376679017,
    change_7d: 3.898648481583038,
  },
  {
    id: '1072',
    name: 'IFSwap',
    address: 'csc:0x6925435f9d1cb710abeb680ec7ef3f8c5e1b2639',
    symbol: 'IFS',
    url: 'https://ifswap.finance',
    description: 'IFSwap is an open AMM model dex protocol for CSC.',
    chain: 'CSC',
    logo: 'https://icons.llama.fi/ifswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['CSC'],
    module: 'ifswap/index.js',
    twitter: 'if_networks',
    forkedFrom: ['Uniswap'],
    listedAt: 1640105053,
    slug: 'ifswap',
    tvl: 47670.525523477816,
    chainTvls: { CSC: 47670.525523477816, 'CSC-staking': 66085.2621065119, staking: 66085.2621065119 },
    change_1h: -0.22694610778441415,
    change_1d: 0.004753349732283141,
    change_7d: -0.6073217754486677,
    staking: 66085.2621065119,
  },
  {
    id: '447',
    name: 'Illuvium',
    address: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
    symbol: 'ILV',
    url: 'https://illuvium.io',
    description:
      'A fully decentralized protocol and a collection and Auto Battler Game launching on Immutable X. Illuvium represents a new age in gaming.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/illuvium.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'illuvium',
    cmcId: '8719',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'illuvium/index.js',
    twitter: 'illuviumio',
    audit_links: ['https://certificate.quantstamp.com/full/illuvium-yield-farming-rewards'],
    slug: 'illuvium',
    tvl: 47026.55658827625,
    chainTvls: {
      Ethereum: 47026.55658827625,
      pool2: 278785189.141124,
      'Ethereum-staking': 116459490.59494898,
      staking: 116459490.59494898,
      'Ethereum-pool2': 278785189.141124,
    },
    change_1h: 0,
    change_1d: 2.917132872058076,
    change_7d: 34.34152494862934,
    staking: 116459490.59494898,
    pool2: 278785189.141124,
    fdv: 6643429548,
    mcap: 432345173,
  },
  {
    id: '638',
    name: 'Meso Finance',
    address: 'fantom:0x4D9361A86D038C8adA3db2457608e2275B3E08d4',
    symbol: 'fMESO',
    url: 'https://www.meso.finance',
    description:
      'Meso Finance, is building up on becoming a multi-chain project that can provide a multitude of products and services. We strive to provide awesome experiences for our users and as profitable returns on their investments. Initially, we will be launching as a yield farm in Fantom to distribute our fMESO token that will be used in the services we are building on. We help investors to protect their funds as we provide a transparent environment so that users can use our services worry-free.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/meso-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'mesofinance/index.js',
    twitter: 'mesofinance',
    audit_links: ['https://paladinsec.co/projects/meso-finance'],
    slug: 'meso-finance',
    tvl: 47004.4022021208,
    chainTvls: {
      pool2: 3806.8735726673376,
      'Fantom-staking': 1014.0168768948846,
      masterchef: 47004.4022021208,
      'Fantom-masterchef': 47004.4022021208,
      'Fantom-pool2': 3806.8735726673376,
      Fantom: 47004.4022021208,
      staking: 1014.0168768948846,
    },
    change_1h: -0.07232463185629001,
    change_1d: 0.4365576574222274,
    change_7d: -5.90054358653471,
    staking: 1014.0168768948846,
    pool2: 3806.8735726673376,
  },
  {
    id: '1071',
    name: 'Polkamarkets',
    address: '0xd478161c952357f05f0292b56012cd8457f1cfbf',
    symbol: 'POLK',
    url: 'https://www.polkamarkets.com',
    description:
      'Polkamarkets is an Autonomous Prediction Market Protocol built for cross-chain information exchange and trading, where users take positions on outcomes of real-world events in a decentralized and interoperable platform, running on EVM.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/polkamarkets.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'polkamarkets',
    cmcId: '8579',
    category: 'Options',
    chains: ['Ethereum', 'Moonbeam', 'Moonriver'],
    module: 'polkamarkets/index.js',
    twitter: 'polkamarkets',
    audit_links: [
      'https://www.certik.com/projects/polkamarkets',
      'https://hacken.io/wp-content/uploads/2021/10/Polkamarkets_06102021SCAudit_Report_2.pdf',
      'https://drive.google.com/file/u/3/d/121EaqOJPGuxhXeRWIGe4KHiWxTA8OO0P/view?usp=sharing',
    ],
    listedAt: 1640096152,
    slug: 'polkamarkets',
    tvl: 46260.91573932863,
    chainTvls: {
      Moonriver: 13635.596285363736,
      Moonbeam: 14207.76284587547,
      'Moonriver-staking': 7254.89973552752,
      Ethereum: 18417.556608089428,
      'Moonbeam-staking': 8.359028,
      'Ethereum-staking': 22.169596,
      staking: 7285.428359527519,
    },
    change_1h: 0.30877338337018045,
    change_1d: 2.962678586001587,
    change_7d: 25.429458850406704,
    staking: 7285.428359527519,
    fdv: 35353614,
    mcap: 25631370,
  },
  {
    id: '1123',
    name: 'Salem Finance',
    address: 'cronos:0x637CB66526244029407046867E1E0DFD28b2294E',
    symbol: 'SALEM',
    url: 'https://salem.finance/',
    description: 'Salem Finance is a Yield Farming Protocol.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/salem-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Cronos', 'Polygon', 'Fantom'],
    module: 'salem/index.js',
    twitter: 'SalemFinance',
    audit_links: ['https://paladinsec.co/projects/salem-finance/'],
    listedAt: 1640717105,
    slug: 'salem-finance',
    tvl: 44555.02188889557,
    chainTvls: {
      Cronos: 31482.089926259236,
      pool2: 1822.2151256607135,
      'Fantom-staking': 261.155863188626,
      'Polygon-pool2': 158.41997761604676,
      'Cronos-staking': 2343.99735021929,
      Polygon: 11593.503512823425,
      'Cronos-pool2': 1566.7813796987225,
      'Polygon-staking': 256.617283264613,
      'Fantom-pool2': 97.01376834594429,
      Fantom: 1479.4284498129068,
      staking: 2861.770496672529,
    },
    change_1h: 1.3478727908435815,
    change_1d: 1.8012653206347835,
    change_7d: 0.24389622911893127,
    staking: 2861.770496672529,
    pool2: 1822.2151256607135,
  },
  {
    id: '889',
    name: '8ightDAO',
    address: 'harmony:0xcda2fdeee5c382e401c04dc929e53ababf6c8109',
    symbol: 'EIGHT',
    chains: ['Harmony'],
    url: 'https://app.8ight.finance/#/dashboard',
    description: 'Ohm fork',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/8ightdao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    module: 'eight/index.js',
    twitter: '8ight_finance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637560657,
    slug: '8ightdao',
    tvl: 44061.645770654126,
    chainTvls: { 'Harmony-staking': 12440.717348890359, Harmony: 44061.645770654126, staking: 12440.717348890359 },
    change_1h: -0.2275449101796312,
    change_1d: 0.17676130009741087,
    change_7d: -0.327018943170458,
    staking: 12440.717348890359,
  },
  {
    id: '1152',
    name: 'RobiniaSwap',
    address: 'bsc:0xAfAEEe58a58867c73245397C0F768FF041D32d70',
    symbol: 'RBS',
    url: 'https://robiniaswap.com/',
    description: 'Robinia Swap is the Yield Farm DeFi and AMM decentralized exchange running on Binance Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/robiniaswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'robiniaswap-token',
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'robiniaswap/index.js',
    twitter: 'robiniaswap',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/September/RobiniaSwap.pdf'],
    listedAt: 1640818441,
    slug: 'robiniaswap',
    tvl: 43811.556827132335,
    chainTvls: {
      'Binance-masterchef': 43811.556827132335,
      pool2: 5304.405857161741,
      masterchef: 43811.556827132335,
      'Binance-pool2': 5304.405857161741,
      Binance: 43811.556827132335,
      'Binance-staking': 6963.121916821173,
      staking: 6963.121916821173,
    },
    change_1h: 0.0008882385860147224,
    change_1d: 1.5641276551906884,
    change_7d: 2.406204901750513,
    staking: 6963.121916821173,
    pool2: 5304.405857161741,
    mcap: 0,
  },
  {
    id: '696',
    name: 'Agave',
    address: 'xdai:0x3a97704a1b25f08aa230ae53b352e2e72ef52843',
    symbol: 'AGVE',
    url: 'https://agave.finance',
    description:
      'Agave rewards depositors with passive income and lets them use their deposits as collateral to borrow and lend digital assets. Forked from Aave.',
    chain: 'xDai',
    logo: 'https://icons.llama.fi/agave.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'agave-token',
    cmcId: '9453',
    category: 'Lending',
    chains: ['xDai'],
    module: 'agave.js',
    twitter: 'Agave_lending',
    forkedFrom: ['Aave'],
    listedAt: 1634918843,
    slug: 'agave',
    tvl: 42348.98414828224,
    chainTvls: {
      xDai: 42348.98414828224,
      'xDai-borrowed': 12959349.764074376,
      borrowed: 12959349.764074376,
      staking: 973800.7670391941,
      'xDai-staking': 973800.7670391941,
    },
    change_1h: 0,
    change_1d: 29.96817083718767,
    change_7d: 50.69118450522197,
    staking: 973800.7670391941,
    fdv: 9873248,
    mcap: 2744753,
  },
  {
    id: '919',
    name: 'Union Finance',
    address: '0x226f7b842e0f0120b7e194d05432b3fd14773a9d',
    symbol: 'UNN',
    url: 'https://unn.finance',
    description:
      'UNION is a technology platform that combines bundled protection and a liquid secondary market with a multi-token model.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/union-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'union-protocol-governance-token',
    cmcId: '8056',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'union/index.js',
    twitter: 'UNNFinance',
    audit_links: ['https://github.com/UNIONProtocolFoundation/union-protocol/tree/main/docs/audits'],
    listedAt: 1637893210,
    slug: 'union-finance',
    tvl: 42344.98181741885,
    chainTvls: { Ethereum: 42344.98181741885 },
    change_1h: 0,
    change_1d: 0,
    change_7d: -28.78954314081483,
    mcap: 1069094,
  },
  {
    id: '649',
    name: 'SwapMatic',
    address: 'polygon:0x94C18174840F80D49d59DC3a1742aF0B884A8184',
    symbol: 'SWAM',
    url: 'https://swapmatic.io',
    description:
      'SwapMatic empowers innovative blockchain projects by providing an Automated Market Making (AMM) and yield farming platform on Polygon L2 chain.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/swapmatic.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'swapmatic/index.js',
    twitter: 'SwapMatic',
    forkedFrom: ['Uniswap'],
    slug: 'swapmatic',
    tvl: 41610.85998385061,
    chainTvls: { Polygon: 41610.85998385061 },
    change_1h: 1.2866663507144835,
    change_1d: 1.180004336140314,
    change_7d: 11.050869657460808,
  },
  {
    id: '526',
    name: 'Zabu Finance',
    address: 'avax:0xdd453dbd253fa4e5e745047d93667ce9da93bbcf',
    symbol: 'ZABU',
    url: 'https://zabu.finance',
    description:
      'Zabu Finance helps you maximize your yield through a full-fledged ecosystem with yield-aggregation, yield farming, staking, fundraising and focuses on bringing DeFi to everyone in a fun and an easy to understand way!',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/zabu-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'zabu-token',
    cmcId: '11680',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'zabufinance/index.js',
    twitter: 'zabufinance',
    forkedFrom: ['Autofarm'],
    slug: 'zabu-finance',
    tvl: 41125.58887763376,
    chainTvls: { Avalanche: 41125.58887763376 },
    change_1h: 2.1968037031118115,
    change_1d: 3.7698185735987266,
    change_7d: 10.008133658688607,
    fdv: 15460.58,
    mcap: 0,
  },
  {
    id: '787',
    name: 'Squid Stake',
    address: 'bsc:0xAE61e7dc989718E700C046a2483e93513eDCA484',
    symbol: 'SQUID',
    url: 'https://squidstake.com',
    description:
      'Squid Stake is a decentralized yield farm aimed at providing high passive income through select farms and pools via Stable Pegged APR (SPA)',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/squid-stake.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'squidstake/index.js',
    twitter: 'SquidStake',
    audit_links: ['https://docs.squidstake.com/contracts'],
    forkedFrom: ['Uniswap'],
    listedAt: 1636132129,
    slug: 'squid-stake',
    tvl: 40312.814019256555,
    chainTvls: { Binance: 40312.814019256555, pool2: 0, 'Binance-pool2': 0 },
    change_1h: 0.16685504576530263,
    change_1d: -0.8738622617481013,
    change_7d: -2.2199477654563964,
    pool2: 0,
  },
  {
    id: '1499',
    name: 'Lavafall',
    address: 'fantom:0x7A0Ac775d290A7a3016f153d757Fbc3c4De62488',
    symbol: 'Lavafall',
    url: 'https://lava.defiwaterfall.com',
    description: 'Yield farm on Fantom',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/lavafalls.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'lavafall/index.js',
    twitter: 'defi_waterfall',
    audit_links: ['https://paladinsec.co/projects/waterfall-finance/'],
    listedAt: 1646308732,
    slug: 'lavafall',
    tvl: 39701.24201465961,
    chainTvls: {
      pool2: 301.9661257867358,
      'Fantom-staking': 0,
      'Fantom-pool2': 301.9661257867358,
      staking: 0,
      Fantom: 39701.24201465961,
    },
    change_1h: 0.18078218093857856,
    change_1d: -8.26886226081966,
    change_7d: -9.130749220844919,
    staking: 0,
    pool2: 301.9661257867358,
  },
  {
    id: '592',
    name: 'Defi Yield Protocol',
    address: '0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17',
    symbol: 'DYP',
    url: 'https://dyp.finance',
    description:
      'The DeFi Yield Protocol (DYP) is a unique platform that offers solutions for yield farming, staking, NFTs, and enabling users to leverage the advanced trading tools of the DYP.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/defi-yield-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'defi-yield-protocol',
    cmcId: '8080',
    category: 'Yield',
    chains: ['Avalanche', 'Binance', 'Ethereum'],
    module: 'defiyieldprotocol/index.js',
    twitter: 'dypfinance',
    audit_links: ['https://dyp.finance/audit'],
    slug: 'defi-yield-protocol',
    tvl: 39478.215546620704,
    chainTvls: {
      'Avalanche-staking': 3339.3510138005176,
      pool2: 440623.0333565894,
      'Binance-pool2': 293101.0159219386,
      'Ethereum-pool2': 138018.01353605956,
      Ethereum: 39478.215546620704,
      'Binance-staking': 825845.6631163968,
      'Ethereum-staking': 70010.93425575981,
      'Avalanche-pool2': 9504.003898591276,
      staking: 899195.948385957,
    },
    change_1h: 0.4071361172875356,
    change_1d: 1.9718032435379627,
    change_7d: 8.277330063554487,
    staking: 899195.948385957,
    pool2: 440623.0333565894,
    fdv: 4751084,
    mcap: 3791326,
  },
  {
    id: '153',
    name: 'CoFiX',
    address: '0x1a23a6bfbadb59fa563008c0fb7cf96dfcf34ea1',
    symbol: 'COFI',
    url: 'https://cofix.io',
    description: 'CoFiX is the most efficient DEX on Ethereum.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/cofix.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'cofix',
    cmcId: '7381',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'cofix.js',
    twitter: 'CoFiXProtocol',
    audit_links: ['https://github.com/Computable-Finance/CoFiX/tree/master/security_audit'],
    slug: 'cofix',
    tvl: 39156.2186,
    chainTvls: { Ethereum: 39156.2186 },
    change_1h: -0.3000712775652232,
    change_1d: 3.8939740796648152,
    change_7d: 15.143551810514168,
    mcap: 1642230,
  },
  {
    id: '567',
    name: 'Newland',
    address: '-',
    symbol: '-',
    url: 'https://newland.finance',
    description: 'Newland is a one-stop decentralized ecological platform that integrates multiple public chains',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/newland.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Heco', 'Ethereum'],
    module: 'newland/index.js',
    twitter: 'Newlandplatform',
    audit_links: ['https://www.fairyproof.com/doc/Newland-Audit-Report-080321.pdf'],
    slug: 'newland',
    tvl: 38684.25826223196,
    chainTvls: {
      'Heco-Treasury': 0.0000018635966830038893,
      Ethereum: 9396.696242023321,
      Heco: 29287.562020208636,
      Treasury: 0.0000018635966830038893,
    },
    change_1h: -0.4903710578230971,
    change_1d: 1.6281734664497662,
    change_7d: 3.9152920146755292,
  },
  {
    id: '1564',
    name: 'Philetairus Socius',
    address: 'fantom:0xc7Cc9D4010387Fc48e77a4Dc871FA39c26efaEEF',
    symbol: 'SOCIUS',
    url: 'https://www.philetairus-socius.com/',
    description:
      'The latest Yield Farm on Fantom (FTM) with APRs Reset System which helps investors to protect their funds as we provide a transparent environment so that users can use our services worry-free and helps to bring in stable passive income by staking! ',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/philetairus-socius.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'philetairussocius/index.js',
    twitter: 'philetairus',
    audit_links: ['https://rugdoc.io/project/philetairus-socius/'],
    listedAt: 1647811513,
    slug: 'philetairus-socius',
    tvl: 37696.357232544826,
    chainTvls: {
      pool2: 5230.563412333106,
      'Fantom-staking': 3414.839876669816,
      masterchef: 37696.35723254483,
      'Fantom-masterchef': 37696.35723254483,
      'Fantom-pool2': 5230.563412333106,
      Fantom: 37696.357232544826,
      staking: 3414.839876669816,
    },
    change_1h: 2.3623429450230446,
    change_1d: null,
    change_7d: -87.3202876831692,
    staking: 3414.839876669816,
    pool2: 5230.563412333106,
  },
  {
    id: '1352',
    name: 'Astral Financial',
    address: 'moonbeam:0x073Af3264c1F486c8cea4188167253F3b1e2A308',
    symbol: 'ASTRAL',
    url: 'https://astral.financial/#/moonbeam',
    description:
      'Astral Financial is the first native yield optimizer on Moonbeam. We offer competitively low fees and astronomical APYs.',
    chain: 'Moonbeam',
    logo: 'https://icons.llama.fi/astral-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Moonbeam'],
    module: 'astral.js',
    twitter: 'AstralFinancial',
    oracles: ['TWAP'],
    forkedFrom: ['Beefy Finance'],
    listedAt: 1643865773,
    slug: 'astral-financial',
    tvl: 36475.630000000005,
    chainTvls: { Moonbeam: 36475.630000000005 },
    change_1h: -0.05184335743281565,
    change_1d: 0.07286880021905517,
    change_7d: 2.5548437524162324,
  },
  {
    id: '594',
    name: 'Defirex',
    address: 'bsc:0x74b3abb94e9e1ecc25bd77d6872949b4a9b2aacf',
    symbol: 'DFX',
    url: 'https://defirex.org',
    description:
      'DeFireX is a group of smart contracts that works with leading DeFi projects for ensuring its users the most reliable and highly profitable level of liquidity mining at minimal fees.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/defirex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'defirex',
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'defirex/index.js',
    twitter: 'DeFireXorg',
    audit_links: [
      'https://github.com/pessimistic-io/audits/blob/main/DeFireX%20Security%20Audit%20by%20Pessimistic%20Public.pdf',
    ],
    slug: 'defirex',
    tvl: 36176.79617470874,
    chainTvls: { Ethereum: 36176.79617470874 },
    change_1h: 0,
    change_1d: 0.36493487980231976,
    change_7d: 0.4433667206047005,
    mcap: 0,
  },
  {
    id: '1388',
    name: 'Parasset',
    address: '0x139cec55d1ec47493dfa25ca77c9208aba4d3c68',
    symbol: 'ASET',
    url: 'https://www.parasset.top/#/',
    description:
      'Parassets such as PUSD, PETH are generated by protocol collateral and anchor the underlying asset with an intrinsic value of 1:1',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/parasset.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'parasset',
    cmcId: '9660',
    category: 'CDP',
    chains: ['Ethereum'],
    oracles: [],
    module: 'parasset/index.js',
    twitter: 'Parasset2021',
    language: 'Solidity',
    listedAt: 1644527281,
    slug: 'parasset',
    tvl: 34691.123161031326,
    chainTvls: { Ethereum: 34691.123161031326, 'Ethereum-staking': 4505645.062816875, staking: 4505645.062816875 },
    change_1h: 0.6233285570915541,
    change_1d: 4.025344316270221,
    change_7d: 15.352847485693587,
    staking: 4505645.062816875,
    fdv: 10672883,
    mcap: 0,
  },
  {
    id: '1484',
    name: 'BastilleDeLaBouje',
    address: 'fantom:0xcef2b88d5599d578c8d92E7a6e6235FBfaD01eF4',
    symbol: 'BASTILLE',
    url: 'https://bastille.bouje.finance/',
    description:
      'Bastille De La Bouje is the third layer of the Bouje Finance ecosystem bringing a solid APR Reset concept back in, focusing on safety and sustainability of our users, furthermore having a Dynamic APR structure to maximise and bring the highest yields to our users.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/bastilledelabouje.png',
    audits: '1',
    audit_note: null,
    gecko_id: 'bastille-de-la-bouje',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'bastilledelabouje/index.js',
    twitter: 'BoujeFinance',
    listedAt: 1646136844,
    slug: 'bastilledelabouje',
    tvl: 34118.38517313626,
    chainTvls: {
      pool2: 7569.728619361259,
      'Fantom-staking': 5077.967660019277,
      masterchef: 34118.38517313626,
      'Fantom-masterchef': 34118.38517313626,
      'Fantom-pool2': 7569.728619361259,
      Fantom: 34118.38517313626,
      staking: 5077.967660019277,
    },
    change_1h: 0.10378030510493375,
    change_1d: 5.363759604378785,
    change_7d: -45.363743058511275,
    staking: 5077.967660019277,
    pool2: 7569.728619361259,
    mcap: 0,
  },
  {
    id: '986',
    name: 'ArbiRise Finance',
    address: 'arbitrum:0xeb6f8eebc83d85120f9299b84da558825685570e',
    symbol: 'ARF',
    url: 'https://www.arbirise.finance',
    description: 'ArbiRiseFinance is a set of tools for DeFi users on Arbitrum.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/arbirise-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'arbirise-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Arbitrum'],
    module: 'arbirise-finance/index.js',
    twitter: 'ArbiRiseFinance',
    listedAt: 1638878302,
    slug: 'arbirise-finance',
    tvl: 32664.722067977997,
    chainTvls: { Arbitrum: 32664.722067977997 },
    change_1h: 1.3528575384814445,
    change_1d: -6.285244691143404,
    change_7d: 0.9581178283229548,
    fdv: 42423,
    mcap: 26485,
  },
  {
    id: '616',
    name: 'Amy Finance',
    address: 'arbitrum:0x8Fbd420956FDD301f4493500fd0BCaAa80f2389C',
    symbol: 'AMY',
    url: 'https://amy.finance',
    description: 'Amy Finance is a Layer 2 based & LP-friendly Lending Protocol.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/amy-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'amy-finance',
    cmcId: null,
    category: 'Lending',
    chains: ['Arbitrum'],
    module: 'amyfinance/index.js',
    twitter: 'amyfinance',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-Amy-v1.0.pdf',
    ],
    slug: 'amy-finance',
    tvl: 31408.57627352499,
    chainTvls: { Arbitrum: 31408.57627352499 },
    change_1h: 0,
    change_1d: -1.4696126754544991,
    change_7d: -14.572392643274029,
    mcap: 0,
  },
  {
    id: '1324',
    name: 'FuryLabs Finance',
    address: 'fantom:0xB1822A7ee73DD7de6Eda328A0681f8E1779CC4B6',
    symbol: 'FURY',
    url: 'https://furylabsfinance.com',
    description: 'Masterchef yield farming on Fantom',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/furylabs-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'furylabsfinance/index.js',
    twitter: 'FuryLabs_',
    audit_links: ['https://fury-labs.gitbook.io/faq/security'],
    forkedFrom: ['Uniswap'],
    listedAt: 1643397175,
    slug: 'furylabs-finance',
    tvl: 30677.79726735079,
    chainTvls: {
      pool2: 574.0404907658019,
      'Fantom-staking': 239.02761162586802,
      masterchef: 30677.79726735079,
      'Fantom-masterchef': 30677.79726735079,
      'Fantom-pool2': 574.0404907658019,
      Fantom: 30677.79726735079,
      staking: 239.02761162586802,
    },
    change_1h: 0.2626814094070795,
    change_1d: -0.47112375060606837,
    change_7d: -14.635736286087138,
    staking: 239.02761162586802,
    pool2: 574.0404907658019,
  },
  {
    id: '936',
    name: 'Manifest',
    address: '0x21585BBcD5bDC3f5737620cf0Db2E51978cf60ac',
    symbol: 'MNFST',
    url: 'https://manifest.gg',
    description:
      'Manifest is a collective of creators, innovators, and people who want more out of life. Stake MNFST for sMNFST to own the future of decentralized business.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/pole-aggregator.svg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Services',
    chains: ['Ethereum'],
    module: 'manifest/index.js',
    twitter: 'manifest',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638190661,
    slug: 'manifest',
    tvl: 30227.451605484326,
    chainTvls: { Ethereum: 30227.451605484326, 'Ethereum-staking': 71099.25967130114, staking: 71099.25967130114 },
    change_1h: 0.335621663910473,
    change_1d: 2.121271792467013,
    change_7d: 25.103969870549278,
    staking: 71099.25967130114,
  },
  {
    id: '856',
    name: 'Fortune DAO',
    address: 'cronos:0xcA558149225Fb386B9c26716e8C35a650C74d35e',
    symbol: 'FORT',
    url: 'https://fortunedao.com',
    description:
      'FortuneDAO is the first is a decentralized reserve currency on Cronos network. Using our treasury, we will farm, buy and accumulate native tokens in Cronos. As stated repeatedly, this will help support the Cronos ecosystem and also allows us to become a blackhole for Cronos.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/fortune-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Cronos'],
    module: 'fortunedao.js',
    twitter: 'Fortune_DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637266964,
    slug: 'fortune-dao',
    tvl: 30075.65090865304,
    chainTvls: { Cronos: 30075.65090865304, 'Cronos-staking': 45896.86495501842, staking: 45896.86495501842 },
    change_1h: 0.034721770550135034,
    change_1d: -2.1841002227215114,
    change_7d: -16.488996606045276,
    staking: 45896.86495501842,
  },
  {
    id: '1308',
    name: 'Frog Nation Farm',
    address: 'fantom:0xfa5c941bc491ee6dc1e933f38d01d8b5d5637205',
    symbol: 'FROG',
    url: 'https://www.frognation.farm/',
    description: 'Frog Nation Farm is a yield farm on the Fantom Opera Network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/frog-nation-farm.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'frog-nation-farm',
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'frog-nation-farm/index.js',
    twitter: 'frognation_farm',
    forkedFrom: ['Goose Finance'],
    listedAt: 1642809400,
    slug: 'frog-nation-farm',
    tvl: 29319.197821496524,
    chainTvls: {
      pool2: 3486.232396747889,
      'Fantom-staking': 4669.544949903922,
      masterchef: 29319.197821496524,
      'Fantom-masterchef': 29319.197821496524,
      'Fantom-pool2': 3486.232396747889,
      Fantom: 29319.197821496524,
      staking: 4669.544949903922,
    },
    change_1h: -0.3862414191812178,
    change_1d: -5.434218218826501,
    change_7d: -1.0710419105946158,
    staking: 4669.544949903922,
    pool2: 3486.232396747889,
    mcap: 0,
  },
  {
    id: '1090',
    name: 'Spicy DAO',
    address: 'avax:0x6007fca39b5398feac4d06d75435a564a086bab8',
    symbol: 'SPC',
    url: 'https://app.spicydao.finance/',
    description: 'OHM fork and nodes on AVAX',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/spicy-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'spicy-dao/index.js',
    twitter: 'Spicy_DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640428311,
    slug: 'spicy-dao',
    tvl: 28768.22733958631,
    chainTvls: {
      'Avalanche-staking': 0.036799667542425536,
      Avalanche: 28768.22733958631,
      staking: 0.036799667542425536,
    },
    change_1h: -0.050790329844318194,
    change_1d: 0.12107468193873672,
    change_7d: -0.7768699259116545,
    staking: 0.036799667542425536,
  },
  {
    id: '554',
    name: 'StormSwap',
    address: 'avax:0x6afd5a1ea4b793cc1526d6dc7e99a608b356ef7b',
    symbol: 'STORM',
    url: 'https://stormswap.finance',
    description: 'StormSwap is a decentralized yield farming & yield aggegrator protocol powered by Avalanche',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/stormswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'storm-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche', 'Cronos'],
    module: 'stormswap/index.js',
    twitter: 'StormSwap_Fi',
    audit_links: ['https://paladinsec.co/projects/stormswap-finance/'],
    slug: 'stormswap',
    tvl: 27998.340736875,
    chainTvls: {
      'Avalanche-masterchef': 23550.596134382897,
      Cronos: 4447.744602492105,
      pool2: 7878.5919977451595,
      masterchef: 27998.340736875,
      'Cronos-staking': 0,
      'Cronos-masterchef': 4447.744602492105,
      Avalanche: 23550.596134382897,
      'Avalanche-staking': 1366.1069435221643,
      'Cronos-pool2': 920.5339023598139,
      'Avalanche-pool2': 6958.058095385346,
      staking: 1366.1069435221643,
    },
    change_1h: 0.706945948692848,
    change_1d: 0.07292842883327921,
    change_7d: 6.6287083598033405,
    staking: 1366.1069435221643,
    pool2: 7878.5919977451595,
    fdv: 31158,
    mcap: 0,
  },
  {
    id: '1039',
    name: 'Mercurity',
    address: '0xe1b583dc66e0a24fd9af2dc665f6f5e48978e106',
    symbol: 'MEE',
    url: 'https://www.mercurity.finance',
    description:
      'Mercurity is an open DeFi platform powered by swap. The most important problems we aim to solve are: low capital utilization, impermanent loss risk, and poor community governance capabilities.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/mercurity.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mercurity-finance',
    cmcId: '1228',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'mercurity/index.js',
    twitter: 'MercurityDefi',
    audit_links: ['https://docs.mercurity.finance/en/security-audit'],
    listedAt: 1639800866,
    slug: 'mercurity',
    tvl: 27349.478715628433,
    chainTvls: { Ethereum: 27349.478715628433 },
    change_1h: 1.4608807226351672,
    change_1d: 3.225606047067501,
    change_7d: 10.112219790534255,
    mcap: 0,
  },
  {
    id: '1268',
    name: 'Cronofi Finance',
    address: 'cronos:0x3Df064069Ba2c8B395592E7834934dBC48BbB955',
    symbol: 'CRONO',
    url: 'https://www.cronofifinance.com/',
    description: 'Farming on Cronos.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/cronofi-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Cronos'],
    module: 'cronofi-finance/index.js',
    twitter: 'Cronofi_Finance',
    audit_links: ['https://rugdoc.io/project/cronofi/'],
    listedAt: 1642197745,
    slug: 'cronofi-finance',
    tvl: 26715.20321570534,
    chainTvls: {
      Cronos: 26715.20321570534,
      pool2: 1076.7035326243695,
      masterchef: 26715.20321570534,
      'Cronos-staking': 47.85178001877,
      'Cronos-masterchef': 26715.20321570534,
      'Cronos-pool2': 1076.7035326243695,
      staking: 47.85178001877,
    },
    change_1h: -0.06623306297602483,
    change_1d: 0.36755913745061264,
    change_7d: 26.798815006665606,
    staking: 47.85178001877,
    pool2: 1076.7035326243695,
  },
  {
    id: '1180',
    name: 'Liquidrium',
    address: null,
    symbol: '-',
    url: 'https://www.liquidrium.com',
    description: 'Provide liquidity to a variety of high returning, actively managed UniswapV3 pairs',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/liquidrium.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon', 'Ethereum', 'Arbitrum'],
    module: 'liquidrium/index.js',
    twitter: null,
    audit_links: ['https://www.certik.com/projects/liquidrium', 'https://hacken.io/audits/#liquidrium'],
    listedAt: 1641193955,
    slug: 'liquidrium',
    tvl: 26642.41528019932,
    chainTvls: { Ethereum: 672.1702103901812, Polygon: 25830.768423618407, Arbitrum: 139.47664619073063 },
    change_1h: 0.11773329459414583,
    change_1d: 1.7124872743570734,
    change_7d: 12.57166212647347,
  },
  {
    id: '1489',
    name: 'DolphinSwap',
    address: 'eos:DOP-eos-dop.efi',
    symbol: 'DOP',
    url: 'https://dolphinswap.io',
    description: 'DolphinSwap supports multiple proportional market-making mechanisms',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/dolphinswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['EOS'],
    oracles: [],
    forkedFrom: [],
    module: 'dolphinswap/index.js',
    twitter: 'Dmd_Finance',
    language: 'C++',
    audit_links: [
      'https://www.slowmist.com/en/security-audit-certificate.html?id=d7be22a7a68e2a72f3c9c1266effbc65e2b14bde7dfd4667bda1b81fbb37e192',
    ],
    listedAt: 1646159389,
    slug: 'dolphinswap',
    tvl: 26639.865001092156,
    chainTvls: { EOS: 26639.865001092156 },
    change_1h: -0.37532776974086346,
    change_1d: -0.3798312828762249,
    change_7d: 18.22593056760573,
  },
  {
    id: '314',
    name: 'Gondola Finance',
    address: 'avax:0xd606199557c8ab6f4cc70bd03facc96ca576f142',
    symbol: 'GDL',
    url: 'https://gondola.finance/',
    description:
      'Gondola Finance connects any asset on Avalanche. Gondola is a fork of Saddle, but empowered by Avalanche’s high throughput and subsecond finality. Leveraging the StableSwap algorithm, we will connect any asset on Avalanche to solve the fragmented liquidity problem due to multiple bridges.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/gondola-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'gondola-finance',
    cmcId: '10449',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'gdl/index.js',
    twitter: 'gondola_finance',
    forkedFrom: ['Saddle Finance'],
    slug: 'gondola-finance',
    tvl: 26603.816690159874,
    chainTvls: { Avalanche: 26603.816690159874 },
    change_1h: 1.1844377707326004,
    change_1d: -0.9184455665463389,
    change_7d: 0.49817268929379566,
    mcap: 0,
  },
  {
    id: '335',
    name: 'Flashstake',
    address: '0x20398ad62bb2d930646d45a6d4292baa0b860c1f',
    symbol: 'FLASH',
    url: 'https://flashstake.io/',
    description:
      'Flashstaking introduces the concept of locking money today and earning money from the future - the time travel of money. All done in a decentralized, instantaneous, and permissionless manner.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/flashstake.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'flashstake/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'flashstake',
    audit_links: ['https://t.co/ngdoQ1FYv1?amp=1'],
    slug: 'flashstake',
    tvl: 25608.558925739242,
    chainTvls: { Ethereum: 25608.558925739242 },
    change_1h: -0.22694610778444257,
    change_1d: 0.09992710728046461,
    change_7d: -0.22694610778444257,
  },
  {
    id: '1149',
    name: 'Koala Defi',
    address: 'bsc:0xb2ebaa0ad65e9c888008bf10646016f7fcdd73c3',
    symbol: 'NALIS',
    url: 'https://koaladefi.finance/',
    description:
      'High security crosschain Yieldfarming DAPP and crypto platform running on the Binance Smart Chain (BSC) and Polygon chain using the ApeSwap Exchange.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/koala-defi.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Binance', 'Polygon'],
    module: 'koala-defi/index.js',
    twitter: 'KoalaDefi',
    audit_links: [
      'https://doc.koaladefi.finance/security/audits/audit-binance-smart-chain-bsc',
      'https://doc.koaladefi.finance/security/audits/audit-polygon',
    ],
    forkedFrom: ['Goose Finance'],
    listedAt: 1640808769,
    slug: 'koala-defi',
    tvl: 24781.3882685868,
    chainTvls: {
      pool2: 3758.1185047734493,
      'Polygon-pool2': 253.44713189568515,
      'Binance-pool2': 3504.671372877764,
      Binance: 15661.54167055359,
      'Binance-staking': 1798.5323087211027,
      Polygon: 9119.846598033211,
      'Polygon-staking': 196.222433317638,
      staking: 1994.7547420387407,
    },
    change_1h: -0.005534539511614867,
    change_1d: 0.5542639505101477,
    change_7d: 0.12483077297081024,
    staking: 1994.7547420387407,
    pool2: 3758.1185047734493,
  },
  {
    id: '796',
    name: 'Draco Force',
    address: 'fantom:0x8d05B42749428C26613deB12f8989Cb8D1f5c17f',
    symbol: 'DRC',
    url: 'https://dracoforce.com',
    description: '#1 generation decentralized Stable-Yield aggregating Farm on #FTM',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/draco-force.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Fantom'],
    module: 'dracoforce/index.js',
    twitter: 'DracoForceDeFi',
    listedAt: 1636220121,
    slug: 'draco-force',
    tvl: 23368.978885594664,
    chainTvls: {
      pool2: 668.0133120386826,
      'Fantom-staking': 441.67426084927433,
      'Fantom-pool2': 668.0133120386826,
      staking: 441.67426084927433,
      Fantom: 23368.978885594664,
    },
    change_1h: 0.6910398439081717,
    change_1d: 3.435983919470374,
    change_7d: 14.532037199062728,
    staking: 441.67426084927433,
    pool2: 668.0133120386826,
  },
  {
    id: '1150',
    name: 'WraithSwap',
    address: 'fantom:0x4CF098d3775Bd78a4508a13E126798Da5911b6cd',
    symbol: 'WRA',
    url: 'https://wraithswap.finance/',
    description: 'Community Powered AMM on Fantom',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/wraithswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'wraithswap/index.js',
    twitter: 'wraithswap',
    listedAt: 1640810152,
    forkedFrom: ['Uniswap'],
    slug: 'wraithswap',
    tvl: 20505.211533569174,
    chainTvls: { 'Fantom-staking': 6673.768458722423, staking: 6673.768458722423, Fantom: 20505.211533569174 },
    change_1h: 2.842170943040401e-14,
    change_1d: 2.0355760713038222,
    change_7d: 2.300262449552477,
    staking: 6673.768458722423,
  },
  {
    id: '390',
    name: 'Qilin',
    address: '-',
    symbol: '-',
    url: 'https://qilin.fi/',
    description:
      'Qilin is a decentralized volatility protocol that provides the ability to long volalitity of any crypto asset.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/qilin.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'qilin/index.js',
    twitter: 'QilinProtocol',
    audit_links: ['https://github.com/CodexDao/QiLin/tree/main/audit'],
    slug: 'qilin',
    tvl: 20264.818266825223,
    chainTvls: { Ethereum: 20264.818266825223 },
    change_1h: -0.11158841158838584,
    change_1d: -0.011700000000004707,
    change_7d: -1.9469129377789045,
  },
  {
    id: '918',
    name: 'Klondike Finance',
    address: '0xbf15797bb5e47f6fb094a4abdb2cfc43f77179ef',
    symbol: 'KLONX',
    url: 'https://klondike.finance',
    description: 'Algorithmic synthetics platform',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/klondike-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'klondike-finance-v2',
    cmcId: '8892',
    category: 'Synthetics',
    chains: ['Ethereum'],
    module: 'klondike/index.js',
    twitter: 'KlondikeFinance',
    listedAt: 1637892596,
    slug: 'klondike-finance',
    tvl: 20071.38178958794,
    chainTvls: {
      Ethereum: 20071.38178958794,
      pool2: 235626.2580498,
      'Ethereum-staking': 0,
      staking: 0,
      'Ethereum-pool2': 235626.2580498,
    },
    change_1h: 0.1283002767319914,
    change_1d: 0.17676130009741087,
    change_7d: -0.3270189431704722,
    staking: 0,
    pool2: 235626.2580498,
    fdv: 589088,
    mcap: 345409,
  },
  {
    id: '1157',
    name: 'LiquidSwap',
    address: null,
    symbol: '-',
    url: 'https://liquidswap.org/#/swap',
    description:
      'Liquidswap is the first to introduce Network Integrated AMM Protocol which rewards native cryptocurrency(XLC) for providing on-chain liquidity.',
    chain: 'Liquidchain',
    logo: 'https://icons.llama.fi/liquidswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'liquidchain',
    cmcId: null,
    category: 'Dexes',
    chains: ['Liquidchain'],
    module: 'liquidswap/index.js',
    twitter: 'LiquidChainXLC',
    forkedFrom: ['Uniswap'],
    listedAt: 1640874908,
    slug: 'liquidswap',
    tvl: 19954.633189490996,
    chainTvls: { Liquidchain: 19954.633189490996 },
    change_1h: 0,
    change_1d: 0.9663027186835649,
    change_7d: -10.402338744295974,
    fdv: 950.79,
    mcap: 0,
  },
  {
    id: '740',
    name: 'Elephantdex',
    address: 'harmony:0xC30a7F9c216B945Ff8ACFB389e955A637eB0f478',
    symbol: 'ELEPHANT',
    url: 'https://elephant.ac',
    description: 'Elephant Dex is a decentralized HRC20 token exchange on the Harmony network.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/elephantdex.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'elephantdex/index.js',
    twitter: 'elephant_dex',
    forkedFrom: ['Uniswap'],
    listedAt: 1635803086,
    slug: 'elephantdex',
    tvl: 19564.22067878038,
    chainTvls: { Harmony: 19564.22067878038 },
    change_1h: 1.4415254967877047,
    change_1d: 0.7221597422183663,
    change_7d: 14.994138910531078,
  },
  {
    id: '809',
    name: 'PolyYeld Finance',
    address: 'polygon:0x1fd6cF265fd3428F655378a803658942095b4C4e',
    symbol: 'YELD',
    url: 'https://app.polyyeld.finance/',
    description:
      'PolyYeld Finance is a next-generation yield farming protocol on the Polygon network with lots of unique and creative features that enable you to earn a passive income.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polyyeld.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'polyyeld-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'polyyeld/index.js',
    twitter: 'PolyYeldFinance',
    audit_links: ['https://paladinsec.co/projects/polyyeld-v2/'],
    listedAt: 1636409314,
    slug: 'polyyeld-finance',
    tvl: 19159.574412842852,
    chainTvls: {
      pool2: 7378.242596261913,
      'Polygon-pool2': 7378.242596261913,
      masterchef: 19159.574412842852,
      'Polygon-masterchef': 19159.574412842852,
      Polygon: 19159.574412842852,
      'Polygon-staking': 3901.64571092855,
      staking: 3901.64571092855,
    },
    change_1h: 0.39322521692632506,
    change_1d: 1.4067411900211368,
    change_7d: 12.091717784183203,
    staking: 3901.64571092855,
    pool2: 7378.242596261913,
    mcap: 0,
  },
  {
    id: '1132',
    name: 'Genesys',
    address: 'fantom:0xf8b234a1ce59991006930de8b0525f9013982746',
    symbol: 'DNA',
    url: 'https://genesys.app/',
    description:
      'Genesys is a new decentralized yield farming project running on Fantom Opera. Built upon proven features and logic to generate solid yields and passive income.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/genesys.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dna-genesys',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'genesys/index.js',
    twitter: 'Genesys_ftm',
    audit_links: ['https://paladinsec.co/projects/genesys/'],
    listedAt: 1640732720,
    slug: 'genesys',
    tvl: 19146.26981413778,
    chainTvls: {
      pool2: 471.0478983860273,
      'Fantom-staking': 577.6207896127619,
      'Fantom-pool2': 471.0478983860273,
      staking: 577.6207896127619,
      Fantom: 19146.26981413778,
    },
    change_1h: -0.17894791053217318,
    change_1d: 0.13742174381306427,
    change_7d: 0.8371567447234867,
    staking: 577.6207896127619,
    pool2: 471.0478983860273,
    fdv: 217.39,
    mcap: 0,
  },
  {
    id: '1236',
    name: 'Waterfall Finance',
    address: 'fantom:0x6b2a7B82d3F7a6e1F5A5831aB40666Ec717645d5',
    symbol: 'WATERFALL',
    url: 'https://app.defiwaterfall.com',
    description: 'Waterfall, a new Stable & Smart DeFi Token on #Fantom Network',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/waterfallfinance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'waterfall-governance-token',
    cmcId: '12572',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'waterfallfinance/index.js',
    twitter: 'defi_waterfall',
    audit_links: ['https://paladinsec.co/projects/waterfall-finance/'],
    listedAt: 1641934055,
    slug: 'waterfall-finance',
    tvl: 18748.27611611591,
    chainTvls: {
      pool2: 1538.427606605008,
      'Fantom-staking': 1191.4339795953733,
      'Fantom-pool2': 1538.427606605008,
      staking: 1191.4339795953733,
      Fantom: 18748.27611611591,
    },
    change_1h: 0.7630771159555962,
    change_1d: -0.10554043341922181,
    change_7d: 8.468498636395339,
    staking: 1191.4339795953733,
    pool2: 1538.427606605008,
    fdv: 29113555,
    mcap: 4504203,
  },
  {
    id: '1193',
    name: 'OBSwap',
    address: 'eos:issue.ob',
    symbol: 'OBT',
    url: 'https://swap.defiob.com',
    description: 'EOS trading platform - Safe, Fluency, Concise, Flexible fees, High APY.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/obswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['EOS'],
    module: 'obswap/index.js',
    twitter: 'defiob',
    listedAt: 1641399104,
    slug: 'obswap',
    tvl: 18365.9521225912,
    chainTvls: { EOS: 18365.9521225912 },
    change_1h: 0,
    change_1d: 13.126638738573519,
    change_7d: 7.325350984717048,
  },
  {
    id: '1435',
    name: 'Cemetery Finance',
    address: 'fantom:0x8bD04EE83a6076d1216237C8B91f7EeE3AccaB35',
    symbol: 'HAUNT',
    url: 'https://www.cemetery.finance',
    description:
      'Cemetery Finance is a farm with the simple purpose of generating passive income for users of the fantom ecosystem with sustainable and persistent gains, bringing balanced issuances and greater incentives to holders of the native HAUNT token.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/cementary-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'cemetery/index.js',
    twitter: 'cemeteryfinance',
    listedAt: 1645299174,
    slug: 'cemetery-finance',
    tvl: 17646.504989469286,
    chainTvls: {
      pool2: 178.69534051298405,
      'Fantom-staking': 0,
      'Fantom-pool2': 178.69534051298405,
      staking: 0,
      Fantom: 17646.504989469286,
    },
    change_1h: 0.08396165847138093,
    change_1d: 1.0513593779626547,
    change_7d: -22.713918887235565,
    staking: 0,
    pool2: 178.69534051298405,
  },
  {
    id: '262',
    name: 'Primitive',
    address: '-',
    symbol: '-',
    url: 'https://primitive.finance/',
    description: 'Primitive is an open source and non-custodial options protocol for any Ethereum asset.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/primitive.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Options',
    chains: ['Ethereum'],
    module: 'primitive/index.js',
    twitter: 'PrimitiveFi',
    audit_links: ['https://docs.primitive.finance/traders/trader-risks#have-your-contracts-been-audited'],
    slug: 'primitive',
    tvl: 17098.408006351776,
    chainTvls: { Ethereum: 17098.408006351776 },
    change_1h: 0.3620951629451383,
    change_1d: 3.058639389595214,
    change_7d: 11.202713551150751,
  },
  {
    id: '966',
    name: 'CerberusDAO',
    address: '0x8a14897ea5f668f36671678593fae44ae23b39fb',
    symbol: '3DOG',
    url: 'https://cerberusdao.finance',
    description:
      'Cerberus is a decentralized reserve currency protocol launching under the 3DOG (🐶,🐶,🐶) token. Each 3DOG token is backed by a basket of assets, starting with meme coins, e.g. Shiba Inu (SHIB), Ethereum (wETH), and, eventually, stablecoins (DAI)',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/cerberusdao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'cerburus',
    cmcId: '15249',
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    module: 'cerberusdao/index.js',
    twitter: 'DaoCerberus',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638495881,
    slug: 'cerberusdao',
    tvl: 16842.0006185103,
    chainTvls: { Ethereum: 16842.0006185103, 'Ethereum-staking': 142875.2444558842, staking: 142875.2444558842 },
    change_1h: 0.8623787279913699,
    change_1d: 5.964514911287296,
    change_7d: 18.538851351351354,
    staking: 142875.2444558842,
    fdv: 7211971,
    mcap: 7211971,
  },
  {
    id: '920',
    name: 'Raven',
    address: 'fantom:0x175cbf2809acfd7521fdd387d65aac523fe4076f',
    symbol: 'RAVEN',
    url: 'https://raven.scarecrow.fi',
    description: 'Yield Farming on FTM',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/raven.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'raven',
    cmcId: '14919',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'scarecrow-raven/index.js',
    twitter: 'scarecrowdefi',
    forkedFrom: ['Uniswap'],
    listedAt: 1637947348,
    slug: 'raven',
    tvl: 16818.091310743548,
    chainTvls: { Fantom: 16818.091310743548 },
    change_1h: 0.23601963560682293,
    change_1d: -0.10801311782809364,
    change_7d: 7.582807954658392,
    fdv: 7720.48,
    mcap: 0,
  },
  {
    id: '866',
    name: 'Reverse',
    address: 'harmony:0xed0b4b0f0e2c17646682fc98ace09feb99af3ade',
    symbol: 'RVRS',
    url: 'https://app.reverseprotocol.one',
    description:
      'Reverse is a yield generating and community-led treasury protocol featuring rebasing yields and protocol-owned liquidity',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/reverse.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'reverse-protocol',
    cmcId: 'null',
    category: 'Reserve Currency',
    chains: ['Harmony'],
    module: 'reverse/index.js',
    twitter: 'RVRSProtocol',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637351714,
    slug: 'reverse',
    tvl: 16489.740069547795,
    chainTvls: { 'Harmony-staking': 1635683.1950465108, Harmony: 16489.740069547795, staking: 1635683.1950465108 },
    change_1h: -0.29880478087650886,
    change_1d: 79.35548099074182,
    change_7d: 291.2543690191069,
    staking: 1635683.1950465108,
    fdv: 99961945,
    mcap: 0,
  },
  {
    id: '872',
    name: 'Skeleton Finance',
    address: 'fantom:0xDB5Ad0D8c8acE03609596165C748B6986ce79767',
    symbol: 'SKULL',
    url: 'https://skeleton.finance',
    description:
      'Skeleton Finance is a yield optimizer on Fantom Opera. Users can deposit various tokens and get their yield auto-compounded. SKULL token is given as bonus reward on top of their yield. SKULL token is native yield farming token to Skeleton.finance. SKULL holders receive protocol fees and will be able to vote on protocol changes.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/skeleton-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'skeleton/index.js',
    twitter: 'SkeletonFinance',
    audit_links: ['https://skeleton.finance/static/files/SmartContract_Audit_Solidproof_Skeleton.pdf'],
    listedAt: 1637375100,
    slug: 'skeleton-finance',
    tvl: 16219.156541065891,
    chainTvls: { Fantom: 16219.156541065891 },
    change_1h: 0.04656133594440348,
    change_1d: -0.8629374402756298,
    change_7d: 5.389749932695324,
  },
  {
    id: '1490',
    name: 'Demodyfi',
    address: 'bsc:0x002d8563759f5e1eaf8784181f3973288f6856e4',
    symbol: 'DMOD',
    url: 'https://www.demodyfi.com',
    description:
      'Demodyfi is able to build a next-generation DEX that extends its DeFi product line into a single-source, cross-chain market. The ability to do both pools and cross-chain swaps, powered by the Polkadot ecosystem, can provide higher throughput for faster and cheaper transactions while remaining connected to the Ethereum Network and other blockchains for liquidity.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/demodyfi.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'demodyfi',
    cmcId: '9713',
    category: 'Dexes',
    chains: ['Moonbeam', 'Binance', 'Ethereum'],
    oracles: ['Internal'],
    forkedFrom: [],
    module: 'demodyfi/index.js',
    twitter: 'Demodyfi',
    listedAt: 1646160467,
    slug: 'demodyfi',
    tvl: 16132.40552212266,
    chainTvls: {
      pool2: 31585.388631481557,
      'Binance-pool2': 8883.527415810313,
      Moonbeam: 16132.40552212266,
      'Ethereum-pool2': 22701.861215671244,
      Binance: 0,
      Ethereum: 0,
      'Binance-staking': 29574.623822640002,
      'Ethereum-staking': 28930.33735968,
      staking: 58504.96118232,
    },
    change_1h: 0,
    change_1d: -7.058073437942198,
    change_7d: -27.984470072137583,
    staking: 58504.96118232,
    pool2: 31585.388631481557,
    fdv: 3575738,
    mcap: 271184,
  },
  {
    id: '677',
    name: 'BorgSwap',
    address: 'bsc:0xE9828045632b32E2aaC7f3a4C1511C0d8965576C',
    symbol: 'Fantom',
    url: 'https://borgswap.exchange',
    description:
      'BORGSWAP offers Vaults, Staking and Yield Farms for the Alpha Quadrant with innovative DeFi token mechanisms like token burn and tax.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/borgswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'borgswap/index.js',
    twitter: 'BorgToken',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/BorgSwap.pdf'],
    listedAt: 1634494841,
    slug: 'borgswap',
    tvl: 15986.071721640305,
    chainTvls: { masterchef: 15986.071721640305, 'Fantom-masterchef': 15986.071721640305, Fantom: 15986.071721640305 },
    change_1h: -30.722363230396212,
    change_1d: -31.034435099994212,
    change_7d: -23.57790093778489,
  },
  {
    id: '892',
    name: 'SmolSwap',
    address: 'cronos:0x2Ad63Da83d6ff5dA9E716DcaE844D4f157405BDd',
    symbol: 'SMOL',
    url: 'https://smolswap.com',
    description:
      'SmolSwap is the cutest fair launch decentralised trading platform on the Cronos chain where you can trade and earn in a safe, simple, and fun manner.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/smolswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'smolswap/index.js',
    twitter: 'SmolSwap',
    forkedFrom: ['Uniswap'],
    listedAt: 1637605616,
    slug: 'smolswap',
    tvl: 14237.30073285015,
    chainTvls: { Cronos: 14237.30073285015, 'Cronos-staking': 1512.4555843127862, staking: 1512.4555843127862 },
    change_1h: -0.16667898764704603,
    change_1d: -23.77123188205114,
    change_7d: -9.150439048754649,
    staking: 1512.4555843127862,
  },
  {
    id: '1264',
    name: 'SmartDEXBCH',
    address: 'smartbch:0x47c259DFe165Cef3e429C9B66bf9ce9dc3e68aC2',
    symbol: 'DSMART',
    url: 'https://smartdex.cash/',
    description:
      'SmartDex is as the DEX on SmartBCH that is using very potent mechanisms behind the scenes that rewards holders of dSMART, through solo staking and revenue accrual models.',
    chain: 'smartBCH',
    logo: 'https://icons.llama.fi/smartdexbch.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['smartBCH'],
    module: 'smartdexbch/index.js',
    twitter: 'smartdogebch',
    listedAt: 1642194196,
    slug: 'smartdexbch',
    tvl: 13057.183364698163,
    chainTvls: { smartBCH: 13057.183364698163 },
    change_1h: 0.5179293396400766,
    change_1d: -0.04948405179302995,
    change_7d: -38.546832251243345,
  },
  {
    id: '539',
    name: 'Carbon Finance',
    address: 'arbitrum:0xfa42DA1bd08341537a44a4ca9D236D1c00A98b40',
    symbol: 'CARBON',
    url: 'https://carbonfi.io',
    description: 'Farming protocol on Arbitrum',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/carbon-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'carbon-finance',
    cmcId: '11872',
    category: 'Yield',
    chains: ['Arbitrum'],
    module: 'carbon/index.js',
    twitter: 'carbonfi_io',
    forkedFrom: ['ArbiNYAN'],
    slug: 'carbon-finance',
    tvl: 12984.346500668831,
    chainTvls: {
      pool2: 810.2234556281837,
      'Arbitrum-staking': 1940.4483215412747,
      Arbitrum: 12984.346500668831,
      'Arbitrum-pool2': 810.2234556281837,
      staking: 1940.4483215412747,
    },
    change_1h: 0.6233285570915683,
    change_1d: 4.025344316270221,
    change_7d: 13.03000688470992,
    staking: 1940.4483215412747,
    pool2: 810.2234556281837,
    fdv: 4960.45,
    mcap: 0,
  },
  {
    id: '1562',
    name: 'PanKUKU',
    address: 'bsc:0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    symbol: 'KUKU',
    url: 'https://pankuku.net',
    description: 'a Hyper Deflationary Token and AMM styled Dex running on BNB Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pankuku.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: ['Uniswap'],
    module: 'pankuku/index.js',
    twitter: 'pankukuofficial',
    listedAt: 1647666879,
    slug: 'pankuku',
    tvl: 12839.331307702012,
    chainTvls: { Binance: 12839.331307702012 },
    change_1h: -0.01818512456812016,
    change_1d: 1.3432870210363603,
    change_7d: 5.8729024064188735,
  },
  {
    id: '1144',
    name: 'Banksy Farm',
    address: 'avax:0x9C26e24ac6f0EA783fF9CA2bf81543c67cf446d2',
    symbol: 'BANKSY',
    url: 'https://banksy.farm/',
    description: 'The New Generation Multi-Network Yield Farming enhanced by NFTs',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/banksy-farm.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'banksyfarm/index.js',
    twitter: 'BanksyFarm',
    audit_links: ['https://paladinsec.co/projects/banksy-farm/'],
    listedAt: 1640800467,
    slug: 'banksy-farm',
    tvl: 12685.65748517317,
    chainTvls: {
      'Avalanche-masterchef': 12685.65748517317,
      pool2: 5699.503879488422,
      masterchef: 12685.65748517317,
      Avalanche: 12685.65748517317,
      'Avalanche-staking': 1883.380494309132,
      'Avalanche-pool2': 5699.503879488422,
      staking: 1883.380494309132,
    },
    change_1h: -0.2597634489391396,
    change_1d: 0.16309884588964962,
    change_7d: -22.722685450510454,
    staking: 1883.380494309132,
    pool2: 5699.503879488422,
  },
  {
    id: '1329',
    name: 'Spiral Finance',
    address: 'fantom:0x8C739564345DfCb7e4C7e520B0E8fA142c358a78',
    symbol: 'SPR',
    url: 'https://spiral.farm/',
    description: 'Yield aggregator that combines DAO mechanics and boosting the whole ecosystem.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/spiral-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'spiral',
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Fantom'],
    module: 'spiral-finance/index.js',
    twitter: 'SpiralFinance',
    listedAt: 1643486349,
    slug: 'spiral-finance',
    tvl: 12596.33280070672,
    chainTvls: { pool2: 5483.224439772892, 'Fantom-pool2': 5483.224439772892, Fantom: 12596.33280070672 },
    change_1h: 0.08440319888921977,
    change_1d: 0.375332343888374,
    change_7d: -82.11146769258427,
    pool2: 5483.224439772892,
    mcap: 0,
  },
  {
    id: '656',
    name: 'Sapphire Defi',
    address: 'fantom:0xfa7d8c3CccC90c07c53feE45A7a333CEC40B441B',
    symbol: 'SAPPHIRE',
    url: 'https://sapphiredefi.com',
    description: 'A multichain DeFi ecosystem',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/sapphire-defi.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'sapphire/index.js',
    twitter: 'DefiSapphire',
    slug: 'sapphire-defi',
    tvl: 12506.487368977501,
    chainTvls: {
      pool2: 1444.8127186293939,
      'Fantom-staking': 1647.999910717589,
      masterchef: 12506.487368133125,
      'Fantom-masterchef': 12506.487368133125,
      'Fantom-pool2': 1444.8127186293939,
      Fantom: 12506.487368977501,
      staking: 1647.999910717589,
    },
    change_1h: 0.1885588543806449,
    change_1d: -0.1648088550952309,
    change_7d: -4.027923856340038,
    staking: 1647.999910717589,
    pool2: 1444.8127186293939,
  },
  {
    id: '948',
    name: 'MonoX',
    address: '0x2920f7d6134f4669343e70122ca9b8f19ef8fa5d',
    symbol: 'MONO',
    url: 'https://monox.finance/',
    description:
      'MonoX is a new DeFi protocol using a single token design for liquidity pools (instead of using pool pairs). This is made possible by grouping deposited tokens into a virtual pair with the vCASH stablecoin.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/monox.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'monox',
    cmcId: '1509',
    category: 'Dexes',
    chains: ['Polygon', 'Ethereum'],
    module: 'monox/index.js',
    twitter: 'MonoXFinance',
    audit_links: [
      'https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/MonoX_Smart_Contract_Security_Audit_Report_Halborn_v1_1.pdf?hss_channel=lcp-28871137',
      'https://drive.google.com/file/d/1nFS62QDifV4B8Mjo9Pj_rJ4fUujeVfq_/view?usp=sharing',
      'https://drive.google.com/file/d/1WtlRAAfBF4HyQcUHKSJtT6MD7-mPN5Vz/view?usp=sharing',
      'https://drive.google.com/file/d/1V2GRFZYIXPY3omYndR0N_NgnDM9LM_3B/view?usp=sharing',
    ],
    listedAt: 1638360205,
    slug: 'monox',
    tvl: 12478.514003234415,
    chainTvls: { Ethereum: 542.9376942672948, Polygon: 11935.57630896712 },
    change_1h: 0,
    change_1d: 0.26341470755346563,
    change_7d: 1.9120498714157748,
    mcap: 0,
  },
  {
    id: '635',
    name: 'Blizzard',
    address: 'avax:0xb147656604217a03fe2c73c4838770df8d9d21b8',
    symbol: 'BLIZZ',
    url: 'https://blizzard.network',
    description:
      'Blizzard is an advanced yield accumulator that helps users (even with little technical know-how) to achieve an optimised farming adventure, helping them to farm and stake.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/blizzard.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'blizzard-network',
    cmcId: '11966',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'blizzard/index.js',
    twitter: 'BlizzardAVAX',
    slug: 'blizzard',
    tvl: 12245.500662132907,
    chainTvls: { 'Avalanche-staking': 0, Avalanche: 12245.500662132907, staking: 0 },
    change_1h: 1.5713825680999918,
    change_1d: 9.912789856049912,
    change_7d: 10.826779720968077,
    staking: 0,
    mcap: 0,
  },
  {
    id: '1130',
    name: 'Buffaloswap',
    address: 'bsc:0x10a49f1fc8c604ea7f1c49bcc6ab2a8e58e77ea5',
    symbol: 'BUFF',
    url: 'https://www.buffaloswap.org/',
    description: 'Safe, Fair & Transparent Yield Farming Optimizer on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/buffaloswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'buffalo-swap',
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'buffaloswap/index.js',
    twitter: 'BuffaloswapDex',
    audit_links: [
      'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_BuffaloToken.pdf',
    ],
    listedAt: 1640731950,
    slug: 'buffaloswap',
    tvl: 12201.867726523893,
    chainTvls: {
      'Binance-masterchef': 12201.867726523897,
      pool2: 5092.9471371177915,
      masterchef: 12201.867726523897,
      'Binance-pool2': 5092.9471371177915,
      Binance: 12201.867726523893,
      'Binance-staking': 3073.2552941353015,
      staking: 3073.2552941353015,
    },
    change_1h: 0.8360663352756177,
    change_1d: 1.2104009574409815,
    change_7d: 1.7416133902435007,
    staking: 3073.2552941353015,
    pool2: 5092.9471371177915,
    fdv: 10514.04,
    mcap: 0,
  },
  {
    id: '1363',
    name: 'Oni Exchange',
    address: 'bsc:0x6c77BB19C69d66bEA9E3CDAea108A76eA8D2Fd2A',
    symbol: 'ONI',
    url: 'https://oni.exchange',
    description:
      'ONI.exchange is an intelligent decentralized exchange running on Binance Smart Chain, with AMM, NFTs, and Yield Farming.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/oni.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'oni-exchange',
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'oni/index.js',
    twitter: 'oniexchange',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/Oni%20MasterChef.pdf'],
    forkedFrom: ['Uniswap'],
    listedAt: 1644152759,
    slug: 'oni-exchange',
    tvl: 11992.027029617313,
    chainTvls: { Binance: 11992.027029617313, 'Binance-staking': 2411.5905030888007, staking: 2411.5905030888007 },
    change_1h: -0.01818512456812016,
    change_1d: -3.195215956612003,
    change_7d: -9.955817884264619,
    staking: 2411.5905030888007,
    fdv: 79571,
    mcap: 0,
  },
  {
    id: '1135',
    name: 'Meowswap',
    address: 'bsc:0xE8658B07c555E9604329A6a0A82FF6D9c6F68D2F',
    symbol: 'MEOW',
    url: 'https://meowswap.net/',
    description: 'DEX and farming on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/meowswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'meowswap',
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'meowswap/index.js',
    twitter: 'meowswap',
    audit_links: ['https://www.certik.org/projects/meowswap'],
    listedAt: 1640733854,
    slug: 'meowswap',
    tvl: 11932.903541973934,
    chainTvls: {
      'Binance-masterchef': 11932.903541973932,
      pool2: 8360.72386991784,
      masterchef: 11932.903541973932,
      'Binance-pool2': 8360.72386991784,
      Binance: 11932.903541973934,
      'Binance-staking': 6357.9443002770095,
      staking: 6357.9443002770095,
    },
    change_1h: -0.06383305139998185,
    change_1d: -5.452650537777231,
    change_7d: -16.40830984914811,
    staking: 6357.9443002770095,
    pool2: 8360.72386991784,
    mcap: 0,
  },
  {
    id: '1160',
    name: 'Baker Guild',
    address: 'fantom:0xfe27133f2e8c8539363883d914bccb4b21ebd28a',
    symbol: 'BREAD',
    url: 'https://bakerguild.com/',
    description:
      'Baker Guild $BREAD is a decentralized autonomous organization (DAO) & new yield farming protocol on Fantom Opera.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/baker-guild.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'baker-guild',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'baker-guild-finance/index.js',
    twitter: 'BakerGuild',
    audit_links: ['https://paladinsec.co/projects/bakerguild/'],
    listedAt: 1640903066,
    slug: 'baker-guild',
    tvl: 11828.140421642358,
    chainTvls: {
      pool2: 1934.3159814089597,
      'Fantom-staking': 3907.3524679722905,
      'Fantom-pool2': 1934.3159814089597,
      staking: 3907.3524679722905,
      Fantom: 11828.140421642358,
    },
    change_1h: 0.1754503003801915,
    change_1d: -0.9643361661317869,
    change_7d: -9.802359425658025,
    staking: 3907.3524679722905,
    pool2: 1934.3159814089597,
    fdv: 5085.39,
    mcap: 0,
  },
  {
    id: '1216',
    name: 'Farm Hero',
    address: 'bsc:0x9b26e16377ad29a6ccc01770bcfb56de3a36d8b2',
    symbol: 'HERO',
    url: 'https://farmhero.io',
    description: 'FarmHero is a novel protocol that mixes NFT, gaming and DEFI concepts.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/farm-hero.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Gaming',
    chains: ['Binance', 'OKExChain', 'Polygon'],
    module: 'farmhero/index.js',
    twitter: 'FarmHeroIO',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-FarmHero-v1.0.pdf',
      'https://www.certik.com/projects/farmhero',
    ],
    listedAt: 1641669482,
    slug: 'farm-hero',
    tvl: 11248.985375416287,
    chainTvls: {
      'OKExChain-staking': 0,
      OKExChain: 3340.203560414024,
      pool2: 24071.644271823287,
      'Polygon-pool2': 0,
      'Binance-pool2': 24071.644271823287,
      Binance: 4894.1999613505795,
      'Binance-staking': 84058.0392671109,
      Polygon: 3014.5818536516826,
      'Polygon-staking': 24540.392489177055,
      'OKExChain-pool2': 0,
      staking: 108598.43175628796,
    },
    change_1h: -0.23637234760484205,
    change_1d: -0.5082020720248437,
    change_7d: -5.611149759310692,
    staking: 108598.43175628796,
    pool2: 24071.644271823287,
  },
  {
    id: '880',
    name: 'MarshmallowDeFi',
    address: 'bsc:0x787732f27d18495494cea3792ed7946bbcff8db2',
    symbol: 'MASH',
    url: 'https://marshmallowdefi.com',
    description:
      'MarshmallowDeFi (MASH) is the next generation of Automated Market Making (AMM) decentralized exchange with a deflationary governance token model. We are your go-to yield farm running on Binance Smart Chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/marshmallowdefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'marshmellowdefi',
    cmcId: '9352',
    category: 'Yield',
    chains: ['Binance'],
    module: 'marshamallowdefi/index.js',
    twitter: 'SwapMarshmallow',
    audit_links: ['https://marshmallowswap.gitbook.io/marshmallowswap/in-the-pipeworks/audit'],
    listedAt: 1637381592,
    slug: 'marshmallowdefi',
    tvl: 11090.314411540987,
    chainTvls: {
      Binance: 11090.314411540987,
      'Binance-staking': 4953.647376087706,
      pool2: 9247.314232239647,
      'Binance-pool2': 9247.314232239647,
      staking: 4953.647376087706,
    },
    change_1h: 0.57958654012522,
    change_1d: 1.2294803404548986,
    change_7d: 6.226333719720984,
    staking: 4953.647376087706,
    pool2: 9247.314232239647,
    mcap: 0,
  },
  {
    id: '1223',
    name: 'TiPiDao',
    address: 'bsc:0xd0253dbe5606c9fa01db67eb10be5c3675c2b117',
    symbol: 'TPD',
    url: 'https://dao.takeprofit.fi/',
    description: 'TiPiDao is a decentralized reserve currency protocol based on the TPD token.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/tipidao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'tipidao/index.js',
    twitter: 'TakeProfit_Fi',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641837565,
    slug: 'tipidao',
    tvl: 10811.655283806936,
    chainTvls: { Binance: 10811.655283806936, 'Binance-staking': 3296.0723243318084, staking: 3296.0723243318084 },
    change_1h: -0.2848793316797469,
    change_1d: 0.1302082983017243,
    change_7d: 1.3935670094054728,
    staking: 3296.0723243318084,
  },
  {
    id: '1151',
    name: 'The Seed Farm',
    address: 'avax:0x37427C72f3534d854EE462d18f42aD5fbE74AA2B',
    symbol: 'SEED',
    url: 'https://theseedfarm.app/',
    description: 'The Seed Farm is a decentralized yield farming project running on Avalanche.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/the-seed-farm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'the-seed-farm',
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'theseedfarm/index.js',
    twitter: 'The_Seed_Farm',
    audit_links: ['https://rugdoc.io/project/the-seed-farm/'],
    listedAt: 1640813980,
    slug: 'the-seed-farm',
    tvl: 10399.631716602991,
    chainTvls: {
      'Avalanche-masterchef': 10399.63171660299,
      pool2: 326.72276065205403,
      masterchef: 10399.63171660299,
      Avalanche: 10399.631716602991,
      'Avalanche-staking': 157.23449597675918,
      'Avalanche-pool2': 326.72276065205403,
      staking: 157.23449597675918,
    },
    change_1h: 1.0133274827535956,
    change_1d: 4.1725701712302765,
    change_7d: 9.717397134076137,
    staking: 157.23449597675918,
    pool2: 326.72276065205403,
    fdv: 3041.3,
    mcap: 0,
  },
  {
    id: '1357',
    name: 'ADAX Pro',
    address: 'cardano:asset1k3zkpw8lk6009qr5zshxeuewe0n0c9we6gg9ed',
    symbol: 'ADAX',
    url: 'https://dex.adax.pro',
    description:
      'ADAX is an automated liquidity protocol that facilitates trades within the Cardano ecosystem in a completely decentralized and non-custodial way.',
    chain: 'Cardano',
    logo: 'https://icons.llama.fi/adax-pro.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'adax',
    cmcId: '10833',
    category: 'Dexes',
    chains: ['Cardano'],
    module: 'adaxPro/index.js',
    twitter: 'adax_pro',
    audit_links: ['https://www.btblock.io/post/btblock-completes-cardano-smart-contract-review-for-adax'],
    listedAt: 1643889165,
    slug: 'adax-pro',
    tvl: 10352.11104,
    chainTvls: { Cardano: 10352.11104 },
    change_1h: -0.025157232704415833,
    change_1d: -2.3008462618983287,
    change_7d: 1.1104107102436984,
    mcap: 11020246,
  },
  {
    id: '523',
    name: 'CryptoYieldFocus',
    address: 'avax:0x411491859864797792308723Fc417f11BbA18D1b',
    symbol: 'CYF',
    url: 'https://www.cryptoyieldfocus.com',
    description:
      'Newly-launched yield farm and AMM based on Avalanche. Our goal is to create a strong and trustworthy foundation for which to be built upon by other businesses and clients alike. Brand new functions and utilities will be added upon our CYF token to sustain an enjoyable and immersive environment for our users',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/cryptoyieldfocus.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Avalanche'],
    module: 'cryptoyieldfocus/index.js',
    twitter: 'CryptYieldFocus',
    forkedFrom: ['Goose Finance'],
    slug: 'cryptoyieldfocus',
    tvl: 9894.223899807406,
    chainTvls: {
      'Avalanche-staking': 0,
      pool2: 12.542062262587084,
      Avalanche: 9894.223899807406,
      'Avalanche-pool2': 12.542062262587084,
      staking: 0,
    },
    change_1h: 0.7965377602706667,
    change_1d: 3.5611399190262176,
    change_7d: 8.369359242129846,
    staking: 0,
    pool2: 12.542062262587084,
  },
  {
    id: '1077',
    name: 'Bring.Finance',
    address: 'ethereum:0x3Ecb96039340630c8B82E5A7732bc88b2aeadE82',
    symbol: 'BRNG',
    url: 'https://bring.finance',
    description: 'Bring.Finance is a decentralized cross-chain staking platform. ',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bring.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'bring-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    module: 'bring/index.js',
    twitter: 'BringFinance',
    listedAt: 1640177167,
    slug: 'bring.finance',
    tvl: 9680.944420451584,
    chainTvls: {
      pool2: 4674.939868586394,
      'Binance-pool2': 4674.939868586394,
      Binance: 9680.944420312173,
      Ethereum: 1.3941162179897834e-7,
      'Binance-staking': 7694.922373166464,
      'Ethereum-staking': 1.3405928333250999e-11,
      staking: 7694.922373166478,
    },
    change_1h: 0.08347039360336339,
    change_1d: 0.9088693613430507,
    change_7d: 13.213348055458752,
    staking: 7694.922373166478,
    pool2: 4674.939868586394,
    mcap: 0,
  },
  {
    id: '1131',
    name: 'Cyber Dog',
    address: 'cronos:0x7a6a832eB5F58245F7d75eD980cED849D69A98FD',
    symbol: 'CBRDOG',
    url: 'https://cyberdog.finance/',
    description:
      'Cyberdog offers features like vaults, incentive pools, NFT marketplace, layers in order to create much more utility to the community.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/cyber-dog.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Cronos'],
    module: 'cyberdog-finance/index.js',
    twitter: 'cyberdogdefi',
    audit_links: ['https://rugdoc.io/project/cyber-dog-financv/', 'https://jagosafer.io/cyberdog'],
    listedAt: 1640732399,
    slug: 'cyber-dog',
    tvl: 9514.427527873653,
    chainTvls: {
      Cronos: 9514.427527873653,
      pool2: 170.43190791404322,
      masterchef: 9514.427527873653,
      'Cronos-staking': 260.6313118230466,
      'Cronos-masterchef': 9514.427527873653,
      'Cronos-pool2': 170.43190791404322,
      staking: 260.6313118230466,
    },
    change_1h: 0.11088032145147508,
    change_1d: 0.40420220068990886,
    change_7d: 2.177292896349897,
    staking: 260.6313118230466,
    pool2: 170.43190791404322,
  },
  {
    id: '822',
    name: 'FantomPup',
    address: 'fantom0x900825EA297c7c5D1F6fA54146849BC41EdDAf29',
    symbol: 'FPUP',
    url: 'https://fantompup.finance',
    description: 'Deflationary yield farm on Fantom Network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/fantompup.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'ftm-pup-token',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'fantompup/index.js',
    twitter: 'PolyPup1',
    audit_links: ['https://paladinsec.co/projects/polypup-finance'],
    listedAt: 1636487151,
    slug: 'fantompup',
    tvl: 8985.689599185285,
    chainTvls: {
      pool2: 3217.668245143762,
      'Fantom-staking': 6164.001139438614,
      'Fantom-pool2': 3217.668245143762,
      staking: 6164.001139438614,
      Fantom: 8985.689599185285,
    },
    change_1h: 0.4395501841946867,
    change_1d: 0.11242577590451219,
    change_7d: 4.596972805884562,
    staking: 6164.001139438614,
    pool2: 3217.668245143762,
    fdv: 191761,
    mcap: 0,
  },
  {
    id: '727',
    name: 'ScareCrow Finance',
    address: 'fantom:0x46e1Ee17f51c52661D04238F1700C547dE3B84A1',
    symbol: 'SCARE',
    url: 'https://app.scarecrow.fi/',
    description: 'Yield Farming on FTM',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/scarecrow.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'scarecrow',
    cmcId: '12827',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'scarecrow/index.js',
    twitter: 'scarecrowdefi',
    audit_links: ['https://app.scarecrow.fi/audits/Solidproof.pdf'],
    listedAt: 1635540063,
    slug: 'scarecrow-finance',
    tvl: 7994.62855233204,
    chainTvls: {
      pool2: 1471.9289683330514,
      'Fantom-staking': 161.65713299088705,
      'Fantom-pool2': 1471.9289683330514,
      staking: 161.65713299088705,
      Fantom: 7994.62855233204,
    },
    change_1h: 0.2704192714787723,
    change_1d: -2.2768922816609347,
    change_7d: 7.333943189763417,
    staking: 161.65713299088705,
    pool2: 1471.9289683330514,
    fdv: 12881.93,
    mcap: 0,
  },
  {
    id: '819',
    name: 'Helios Prime',
    address: null,
    symbol: '-',
    url: 'https://heliosprime.live',
    description:
      'Helios Prime enables everyday investors the opportunity to invest in an index fund, which tracks the performance of a wide range of projects helping to diversify risk.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/helios-prime.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'heliosprime/index.js',
    twitter: 'HeliosPrimeOne',
    listedAt: 1636448140,
    slug: 'helios-prime',
    tvl: 7991.8488109541395,
    chainTvls: { Ethereum: 7991.8488109541395 },
    change_1h: -0.006246678109945947,
    change_1d: 1.924492524173587,
    change_7d: 7.6191043279245605,
  },
  {
    id: '1165',
    name: 'Swing DAO',
    address: 'avax:0x19fbfa5987efecceff41b190759a9d883a4bea21',
    symbol: 'GNIWS',
    url: 'https://swingdao.finance/',
    description: 'Decentralized reserve currency with no team allocation.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/swing-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'swing-dao/index.js',
    twitter: 'SwingDAOO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640937090,
    slug: 'swing-dao',
    tvl: 7892.309998513388,
    chainTvls: {
      'Avalanche-staking': 0.004662227351934959,
      Avalanche: 7892.309998513388,
      staking: 0.004662227351934959,
    },
    change_1h: -0.05079032984430398,
    change_1d: 0.12107468193873672,
    change_7d: -0.7768699259116545,
    staking: 0.004662227351934959,
  },
  {
    id: '850',
    name: 'Polyroll',
    address: 'polygon:0xc68e83a305b0fad69e264a1769a0a070f190d2d6',
    symbol: 'ROLL',
    url: 'https://polyroll.org',
    description:
      'Polyroll is a decentralized gaming platform built on Polygon Network and Chainlink. Unlike traditional games that operate in black boxes, Polyroll runs on smart contracts that are fair, transparent, non-custodian, and immutable.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polyroll.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'polyroll',
    cmcId: '10848',
    category: 'Yield',
    chains: ['Polygon'],
    module: 'polyroll.js',
    twitter: 'PolyrollOrg',
    audit_links: ['https://docs.polyroll.org/security'],
    oracles: ['Chainlink'],
    listedAt: 1637119818,
    slug: 'polyroll',
    tvl: 7813.250427586012,
    chainTvls: { Polygon: 7813.250427586012, 'Polygon-staking': 19896.846241106465, staking: 19896.846241106465 },
    change_1h: 0.2672006122362376,
    change_1d: 2.195241093884647,
    change_7d: 14.05465503878014,
    staking: 19896.846241106465,
    fdv: 43379,
    mcap: 18126.36,
  },
  {
    id: '1328',
    name: 'Astral Farm',
    address: 'fantom:0xd95322C0D069B51a41ed2D94A39617C2ACbcE636',
    symbol: 'ASTRAL',
    url: 'https://astral-farm.com',
    description:
      'Revolutionary DeFi Yield Farm on Fantom chain with referral system to give you that extra boost. Astral Farm also has auto-burn and auto-liquidity to ensure sustainably native price.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/astral-farm.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'astralfarm/index.js',
    twitter: 'Astral_FTM',
    oracles: [],
    forkedFrom: ['Goose Finance'],
    listedAt: 1643481742,
    slug: 'astral-farm',
    tvl: 7773.66274221055,
    chainTvls: {
      pool2: 1363.2502568869313,
      'Fantom-staking': 439.10862506480174,
      'Fantom-pool2': 1363.2502568869313,
      staking: 439.10862506480174,
      Fantom: 7773.66274221055,
    },
    change_1h: 0.5136115961077934,
    change_1d: 0.26940832103667844,
    change_7d: 6.123161771457177,
    staking: 439.10862506480174,
    pool2: 1363.2502568869313,
  },
  {
    id: '1309',
    name: 'Gaia DAO',
    address: 'avax:0x9f6aedca032b1092e08b848fc9d6f29139370837',
    symbol: 'GAIA',
    url: 'https://gaiadao.money/',
    description: 'Gaia DAO’s mission is to fight climate change by all means and save humanity.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/gaia-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'gaia-dao',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'gaia-dao/index.js',
    twitter: 'GaiaDAO_fi',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1642872155,
    slug: 'gaia-dao',
    tvl: 7275.280621000089,
    chainTvls: { 'Avalanche-staking': 6682.71097124757, Avalanche: 7275.280621000089, staking: 6682.71097124757 },
    change_1h: 0.17470110950890216,
    change_1d: 1.732910579585507,
    change_7d: 5.0171881930491935,
    staking: 6682.71097124757,
    mcap: 0,
  },
  {
    id: '416',
    name: 'WeStarter',
    address: 'heco:0x910651F81a605a6Ef35d05527d24A72fecef8bF0',
    symbol: 'WAR',
    url: 'https://www.westarter.org',
    description:
      'WeStarter is a cross-chain token initial swap platform which based on HECO network. Through the fluency of the product and sufficient industry resources, it carries the requirement of resources for a variety of asset types. With the role of gatekeeper, through the decentralized incentive and governance mechanism of tokens, to achieve the goal of selecting high-quality assets.',
    chain: 'Heco',
    logo: 'https://icons.llama.fi/westarter.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'westarter',
    cmcId: '9757',
    category: 'Launchpad',
    chains: ['Heco'],
    module: 'westater/index.js',
    twitter: 'westarter_org',
    audit_links: [
      'https://www.westarter.org/static/media/Security%20Assessment%20for%20WeStarter%20-%20Starter.4901202a.pdf',
    ],
    slug: 'westarter',
    tvl: 7089.392853399733,
    chainTvls: { Heco: 7089.392853399733 },
    change_1h: -1.2247970623125042,
    change_1d: 0.2671919786977526,
    change_7d: -0.6500346112780164,
    mcap: 0,
  },
  {
    id: '1389',
    name: 'Polyquail',
    address: 'polygon:0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa',
    symbol: 'CHK',
    url: 'https://www.polyquail.finance',
    description: 'Polygon yield farm',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/polyquail.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    oracles: [],
    module: 'polyquail/index.js',
    twitter: 'polyquail',
    language: 'Solidity',
    audit_links: [
      'https://paladinsec.co/projects/polyquail-layer-3-chk/',
      'https://rugdoc.io/project/polyquail-chk-layer/',
    ],
    listedAt: 1644528043,
    slug: 'polyquail',
    tvl: 6759.040481111145,
    chainTvls: {
      Polygon: 6759.040481111145,
      pool2: 85.41822499939374,
      'Polygon-pool2': 85.41822499939374,
      'Polygon-staking': 0,
      staking: 0,
    },
    change_1h: -0.0431195849971715,
    change_1d: 0.8896731830642608,
    change_7d: 8.289604055847917,
    staking: 0,
    pool2: 85.41822499939374,
  },
  {
    id: '1319',
    name: 'Moneyrain Finance',
    address: 'fantom:0x9ce66ef13d88cb1bc567e47459841483c5d9457c',
    symbol: 'MONEYRAIN',
    url: 'https://defimoneyrain.com',
    description: 'Moneyrain, a new Stable & Smart DeFi Token on Fantom Network',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/moneyrain-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'moneyrain-finance',
    cmcId: '17612',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'moneyrainfinance/index.js',
    twitter: 'defi_waterfall',
    audit_links: ['https://defi-waterfall.gitbook.io/moneyrain-finance/security/paladin-audit'],
    listedAt: 1643325049,
    slug: 'moneyrain-finance',
    tvl: 6330.4377491355,
    chainTvls: {
      pool2: 2462.0802038510838,
      'Fantom-staking': 1708.682513189158,
      'Fantom-pool2': 2462.0802038510838,
      staking: 1708.682513189158,
      Fantom: 6330.4377491355,
    },
    change_1h: -0.002486073093990626,
    change_1d: 0.25086629998773446,
    change_7d: 0.8872850218750017,
    staking: 1708.682513189158,
    pool2: 2462.0802038510838,
    mcap: 0,
  },
  {
    id: '1442',
    name: 'Kyrios Finance',
    address: 'fantom:0xdbf8a44f447cf6fa300fa84c2aac381724b0c6dd',
    symbol: 'KYRIOS',
    url: 'https://kyrios.finance/',
    description: 'Yield Farming Protocol on Fantom',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/kyrios-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    audit_links: [
      'https://github.com/solidproof/projects/blob/main/Kyrios%20Finance/SmartContract_Audit_Solidproof_Kyrios_Finance.pdf',
      'https://531035661-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FhccNVLK8kuMkleYXpAJW%2Fuploads%2FCosEw4NkBoipdEtd883t%2FSmartContract_Audit_Solidproof_Kyrios_Finance.pdf?alt=media&token=78b83a6b-db33-4d51-88f4-346fbbd2467e',
    ],
    module: 'kyrios/index.js',
    twitter: 'KyriosFinance',
    listedAt: 1645429975,
    slug: 'kyrios-finance',
    tvl: 6222.238410462586,
    chainTvls: {
      pool2: 784.7828294316726,
      'Fantom-staking': 190.85592410747682,
      masterchef: 6222.238410462586,
      'Fantom-masterchef': 6222.238410462586,
      'Fantom-pool2': 784.7828294316726,
      Fantom: 6222.238410462586,
      staking: 190.85592410747682,
    },
    change_1h: 0.8060667288089007,
    change_1d: 0.3058320191115911,
    change_7d: 7.1863343519873695,
    staking: 190.85592410747682,
    pool2: 784.7828294316726,
  },
  {
    id: '1417',
    name: 'GemMine',
    address: 'fantom:0x1e2a499fAefb88B2d085d7036f3f7895542b09De',
    symbol: 'GEMMINE',
    url: 'https://gemmine.defiwaterfall.com',
    description:
      'GemMine - this is not just a farm! It will bring together and help projects such as Waterfall, Knights of Fantom, Moneyrain & MyMine',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/gemmine.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'gemmine/index.js',
    twitter: 'defi_waterfall',
    listedAt: 1644970013,
    slug: 'gemmine',
    tvl: 6157.708847849003,
    chainTvls: {
      pool2: 1276.730309750555,
      'Fantom-staking': 2412.1871895280447,
      'Fantom-pool2': 1276.730309750555,
      staking: 2412.1871895280447,
      Fantom: 6157.708847849003,
    },
    change_1h: -8.792989376615878e-7,
    change_1d: -2.46998947611921,
    change_7d: -41.416531617689586,
    staking: 2412.1871895280447,
    pool2: 1276.730309750555,
  },
  {
    id: '1437',
    name: 'Nightmare Finance',
    address: 'fantom:0x465d67204A8F7c02cd35288Cc7712526359FB0a9',
    symbol: 'FEAR',
    url: 'https://www.nightmarefinance.com',
    description:
      'Powered by Fantom (FTM) with its high-speed consensus mechanism, Earn FEAR through yield farming or via our Incentives Pools and Vault Staking',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/nightmare-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'nightmare/index.js',
    twitter: 'Nightmare_fi',
    listedAt: 1645299462,
    slug: 'nightmare-finance',
    tvl: 6054.963667328179,
    chainTvls: {
      pool2: 48.51910799110964,
      'Fantom-staking': 0,
      'Fantom-pool2': 48.51910799110964,
      staking: 0,
      Fantom: 6054.963667328179,
    },
    change_1h: 0.4617949446572993,
    change_1d: 1.837562976499001,
    change_7d: 12.292108390512894,
    staking: 0,
    pool2: 48.51910799110964,
  },
  {
    id: '1406',
    name: 'Cupid Farm',
    address: 'fantom:0xD4C000c09bfeF49ABBd5c3728fcec3a42c68eBa1',
    symbol: 'ARROWS',
    url: 'https://www.coconuts.finance/',
    description:
      'Cupid Farm is a new yield farming & yield aggregator protocol, powered by the Fantom Network. Our main focus is to deliver a safe, sustainable farm with a strong and active community!',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/cupid-farm.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Morpheus Swap'],
    module: 'cupid/index.js',
    twitter: 'cupid_farm',
    language: 'Solidity',
    listedAt: 1644782746,
    slug: 'cupid-farm',
    tvl: 5952.501070778471,
    chainTvls: {
      pool2: 54126.70693079559,
      'Fantom-staking': 148781.38239625393,
      'Fantom-pool2': 54126.70693079559,
      staking: 148781.38239625393,
      Fantom: 5952.501070778471,
    },
    change_1h: 0.06476204791809437,
    change_1d: -0.5579253977331007,
    change_7d: 7.0729144880669,
    staking: 148781.38239625393,
    pool2: 54126.70693079559,
  },
  {
    id: '757',
    name: 'Brahma',
    address: null,
    symbol: '-',
    url: 'https://www.brahma.fi',
    description:
      'Brahma increases the GDP of DeFi by building easy-to-invest vaults, which abstract the underlying complexity of primitives and give a systematic, risk-adjusted way to capture yield.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/brahma.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'brahmafi-aastra/index.js',
    twitter: 'BrahmaFi',
    oracles: ['Coingecko'],
    listedAt: 1635963794,
    slug: 'brahma',
    tvl: 5879.993547254321,
    chainTvls: { Ethereum: 5879.993547254321 },
    change_1h: -0.011700000000004707,
    change_1d: -0.011700000000004707,
    change_7d: -14.850664821963946,
  },
  {
    id: '1129',
    name: 'BlackBird Finance',
    address: 'cronos:0x9A3d8759174f2540985aC83D957c8772293F8646',
    symbol: 'BIRD',
    url: 'https://croblackbird.finance/',
    description:
      'BlackBird Finance, a new Defi protocol powered by Cronos Network to offer exciting features to the Defi community of Cronos to earn better yields and profits.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/blackbird-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Cronos'],
    module: 'blackbird-finance/index.js',
    twitter: 'BlackBirdFIn',
    audit_links: ['https://rugdoc.io/project/black-bird-finance/', 'https://jagosafer.io/croblackbird'],
    listedAt: 1640731523,
    slug: 'blackbird-finance',
    tvl: 5857.589940740486,
    chainTvls: {
      Cronos: 5857.589940740486,
      pool2: 910.4753152335022,
      'Cronos-pool2': 910.4753152335022,
      'Cronos-staking': 716.64522269764,
      staking: 716.64522269764,
    },
    change_1h: 0.3225447241673578,
    change_1d: 2.0786017043493956,
    change_7d: 10.855431656748138,
    staking: 716.64522269764,
    pool2: 910.4753152335022,
  },
  {
    id: '1403',
    name: 'Scranton Finance',
    address: 'fantom:0xd0e7e2a4e0b7df94a095346c55665ba586d3caa4',
    symbol: 'PAPER',
    url: 'https://www.scranton.finance/',
    description: 'Scranton.Finance is a brand new yield farm on FTM focused on community and relationships.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/scranton-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'scranton-finance/index.js',
    twitter: 'Scranton_Fi',
    language: 'Solidity',
    listedAt: 1644763770,
    slug: 'scranton-finance',
    tvl: 5835.908075271864,
    chainTvls: {
      pool2: 21.994800262682354,
      'Fantom-staking': 36.36228779961145,
      masterchef: 5835.908075271864,
      'Fantom-masterchef': 5835.908075271864,
      'Fantom-pool2': 21.994800262682354,
      Fantom: 5835.908075271864,
      staking: 36.36228779961145,
    },
    change_1h: 0,
    change_1d: 1.2741443954582365,
    change_7d: 4.835169082132467,
    staking: 36.36228779961145,
    pool2: 21.994800262682354,
  },
  {
    id: '1133',
    name: 'Ice Colony',
    address: 'polygon:0x6ad1eEdDf1b1019494E6F78377d264BB2518db6F',
    symbol: 'ICE',
    url: 'https://www.icecolony.com/',
    description:
      'Ice Colony is a yield farming project with layers. The project aims to adapt the models of successful projects and build new features on them.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/ice-colony.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'ice-colony/index.js',
    twitter: 'IceColony',
    audit_links: ['https://paladinsec.co/projects/ice-colony/'],
    listedAt: 1640733119,
    slug: 'ice-colony',
    tvl: 5611.928888119697,
    chainTvls: {
      pool2: 989.1015448911705,
      'Polygon-pool2': 989.1015448911705,
      masterchef: 5611.928888119697,
      'Polygon-masterchef': 5611.928888119697,
      Polygon: 5611.928888119697,
      'Polygon-staking': 12275.494966376795,
      staking: 12275.494966376795,
    },
    change_1h: 0.5892589562322712,
    change_1d: 3.508426609057196,
    change_7d: 7.821865211480954,
    staking: 12275.494966376795,
    pool2: 989.1015448911705,
  },
  {
    id: '798',
    name: 'Adena Finance',
    address: 'harmony:0x97594E465e6B4df48e58327f566a3F79E9274655',
    symbol: 'ADENA',
    url: 'https://adena.finance',
    description:
      'Adena Finance is a decentralized exchange on the Harmony Network. Our goal is to provide a comprehensive and convenient, one-stop platform for the cryptocurrency community.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/adena-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'adenafinance/index.js',
    twitter: 'adenafinance',
    forkedFrom: ['Uniswap'],
    listedAt: 1636268324,
    slug: 'adena-finance',
    tvl: 5580.511146837113,
    chainTvls: { Harmony: 5580.511146837113 },
    change_1h: 0.6783724026917071,
    change_1d: -0.16554344413574995,
    change_7d: 9.287695981729541,
  },
  {
    id: '1376',
    name: 'Sahara Exchange',
    address: 'oasis:0x043694e7d4F10C1030A1D739694e9C2dA34ff7c7',
    symbol: 'ANKH',
    url: 'https://sahara.exchange',
    description:
      'SaharaExchange is a DEX on Oasis Emerald ParaTime that rewards holders of ANKH, through solo staking and pool staking.',
    chain: 'Oasis',
    logo: 'https://icons.llama.fi/sahara-exchange.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Oasis'],
    oracles: [],
    module: 'saharaexchange/index.js',
    twitter: 'SaharaDex',
    language: 'Solidity',
    forkedFrom: ['Uniswap'],
    listedAt: 1644440454,
    slug: 'sahara-exchange',
    tvl: 5394.061173289046,
    chainTvls: { Oasis: 5394.061173289046 },
    change_1h: -0.2840280477031456,
    change_1d: -1.757489537567352,
    change_7d: -64.01230146951679,
  },
  {
    id: '1332',
    name: 'DNA Dollar',
    address: 'cronos:0xcc57f84637b441127f2f74905b9d99821b47b20c',
    symbol: 'DNA',
    url: 'https://dnadollar.com',
    description: 'First Algorithmic $USDC pegged Stablecoin in Cronos Network',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/dna-dollar.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'dna-dollar',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Cronos'],
    module: 'dnadollar/index.js',
    twitter: 'dna_dollar',
    listedAt: 1643486428,
    slug: 'dna-dollar',
    tvl: 5157.998954506055,
    chainTvls: {
      Cronos: 5157.998954506055,
      'Cronos-Treasury': 428.8792733254761,
      pool2: 30072.13425394114,
      'Cronos-staking': 68986.2359157497,
      'Cronos-pool2': 30072.13425394114,
      Treasury: 428.8792733254761,
      staking: 68986.2359157497,
    },
    change_1h: -0.11158841161081057,
    change_1d: -0.011699999971341413,
    change_7d: -89.47788620920798,
    staking: 68986.2359157497,
    pool2: 30072.13425394114,
    fdv: 16934.36,
    mcap: 0,
  },
  {
    id: '1405',
    name: 'Coconuts Finance',
    address: null,
    symbol: '-',
    url: 'https://www.coconuts.finance',
    description:
      'Coconuts is a Yield Aggregator that expands on the idea of OHM-style Protocol-Owned Liquidity while also including some Trad-Fi concepts, such as Contingent Convertibles (CoCo) and Tranches (NuT)',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/coconuts-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Polygon', 'Avalanche'],
    oracles: [],
    forkedFrom: ['Yearn Finance'],
    module: 'coconuts-finance/index.js',
    twitter: 'CoconutsFinance',
    language: 'Solidity',
    listedAt: 1644782634,
    slug: 'coconuts-finance',
    tvl: 5111.265985495115,
    chainTvls: { Avalanche: 151.322352128382, Polygon: 4959.943633366734 },
    change_1h: 0.18840056006013128,
    change_1d: null,
    change_7d: -19.34151929150822,
  },
  {
    id: '792',
    name: 'Pinyottas',
    address: null,
    symbol: '-',
    url: 'https://pinyottas.com',
    description:
      'Pinyottas are NFTs that hold a collection of different fungible assets (a.k.a. ERC-20 tokens), so when a pinyotta is transferred to a new owner, the ERC-20 tokens move along with the pinyotta.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/pinyottas.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'NFT Marketplace',
    chains: ['Ethereum'],
    module: 'pinyottas.js',
    twitter: 'pinyottas',
    listedAt: 1636177842,
    slug: 'pinyottas',
    tvl: 4986.947247941368,
    chainTvls: { Ethereum: 4986.947247941368 },
    change_1h: 2.6329175858338374,
    change_1d: 6.397947481993029,
    change_7d: 17.38264943176351,
  },
  {
    id: '1536',
    name: 'JetMine DAO',
    address: 'fantom:0x71BE8F5F245c1F5aa5727DFdB36aAD3C71a4c26b',
    symbol: 'JETMINE',
    url: 'https://jetmine.defiwaterfall.com',
    description: 'JetMine - Is a yield farm on Fantom! It will bring together and help projects such as Lavafall',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/jetmine-dao.png',
    audits: '1',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'jetmine/index.js',
    twitter: 'defi_waterfall',
    audit_links: ['https://paladinsec.co/projects/waterfall-finance/'],
    listedAt: 1646960602,
    slug: 'jetmine-dao',
    tvl: 4848.664230999335,
    chainTvls: {
      pool2: 81.55690033256691,
      'Fantom-staking': 0,
      'Fantom-pool2': 81.55690033256691,
      staking: 0,
      Fantom: 4848.664230999335,
    },
    change_1h: 0.9400040441515785,
    change_1d: -0.26900061823842236,
    change_7d: -57.2995157978383,
    staking: 0,
    pool2: 81.55690033256691,
  },
  {
    id: '1366',
    name: 'Hotfriescoin',
    address: 'bsc:0x849741B79bc1618b46CF9ec600E94E771DEde601',
    symbol: 'HFC',
    url: 'https://www.hotfriescoin.com/',
    description:
      "A new Asset Class called Stable Progressive Coin. We aim to be the World's Most Powerful Reserve and Store of Value Currency.",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/hotfriescoin.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'hotfries/index.js',
    twitter: 'hotfriescoin',
    language: 'Solidity',
    listedAt: 1644190943,
    slug: 'hotfriescoin',
    tvl: 4785.405163233229,
    chainTvls: { Binance: 4785.405163233229 },
    change_1h: -0.33263473053891346,
    change_1d: 6.591259821481543,
    change_7d: 6.340187036235534,
  },
  {
    id: '1409',
    name: 'Tower Finance',
    address: 'polygon:0x0f4BaDF9e35b49ba7b01e69A0a6336C3888E39D7',
    symbol: 'CUBE',
    url: 'https://towerfinance.io',
    description: 'The first Fractional-algorithmic stablecoin protocol integrates DeFi 2.0 on Polygon',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/tower-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Polygon'],
    oracles: ['TWAP', 'Chainlink'],
    forkedFrom: [],
    module: 'tower-finance/index.js',
    twitter: 'tower_finance',
    audit_links: [
      'https://github.com/towerfinance/tower-v2-solidity/blob/main/audit/Tower_Finance_Smart_Contract_Audit_-_Final_Report.pdf',
    ],
    listedAt: 1644868459,
    slug: 'tower-finance',
    tvl: 4741.212702144951,
    chainTvls: {
      Polygon: 4741.212702144951,
      pool2: 5043.104058074646,
      'Polygon-pool2': 5043.104058074646,
      'Polygon-staking': 660.1376441273441,
      staking: 660.1376441273441,
    },
    change_1h: -0.09291570275378547,
    change_1d: 0.07183037212541876,
    change_7d: 8.979393228006003,
    staking: 660.1376441273441,
    pool2: 5043.104058074646,
  },
  {
    id: '965',
    name: 'MoonDao',
    address: 'moonriver:0x761cb807bfbf14a4f4ba980f29f43f009f6a18c0',
    symbol: 'MD',
    url: 'https://www.moondao.net/dashboard',
    description:
      'MoonDao is a decentralized reserve currency protocol based on the MD token. Each MD token is backed by a basket of assets (e.g. DAI, USDC) in the MoonDao treasury, giving it an intrinsic value that it cannot fall below.',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/moondao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'moondao-2',
    cmcId: '14927',
    category: 'Reserve Currency',
    chains: ['Moonriver'],
    module: 'moondao/index.js',
    twitter: 'Moon__Dao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638494448,
    slug: 'moondao',
    tvl: 4678.7491880075895,
    chainTvls: { 'Moonriver-staking': 11.24459531428231, Moonriver: 4678.7491880075895, staking: 11.24459531428231 },
    change_1h: 0.0014264644442505414,
    change_1d: 0.007254526681904849,
    change_7d: 2.323425793181144,
    staking: 11.24459531428231,
    fdv: 5668693,
    mcap: 0,
  },
  {
    id: '871',
    name: 'MintSwap',
    address: 'avax:0x7Bf4cA9aEC25aDAAf7278EEDbe959d81893e314F',
    symbol: 'MINT',
    url: 'https://mint-swap.com',
    description:
      'MintSwap, a new Stable DeFi Token on Avax Network which helps investors to protect their funds as we provide a transparent environment so that users can use our services worry-free and helps to bring in stable passive income by staking!',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/mintswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'mintswap',
    cmcId: '14049',
    category: 'Services',
    chains: ['Avalanche'],
    module: 'mintswap/index.js',
    twitter: 'SwapMint',
    listedAt: 1637374538,
    slug: 'mintswap',
    tvl: 4458.298220366415,
    chainTvls: { Avalanche: 4458.298220366415 },
    change_1h: 0.6794506263417617,
    change_1d: 5.01436998551921,
    change_7d: 10.666326876167815,
    fdv: 1863.04,
    mcap: 0,
  },
  {
    id: '1443',
    name: 'OneRing',
    address: 'fantom:0x582423c10c9e83387a96d00a69ba3d11ee47b7b5',
    symbol: 'RING',
    url: 'https://www.onering.finance',
    description: 'One Ring is a Multi-Chain Cross-Stable Yield Optimizer Platform.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/onering.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'onering',
    cmcId: '17786',
    category: 'Yield',
    chains: ['Polygon', 'Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'one-ring/index.js',
    twitter: 'Onering_Finance',
    listedAt: 1645453599,
    slug: 'onering',
    tvl: 4398.239666277191,
    chainTvls: { Polygon: 4398.239666277191, Fantom: 0 },
    change_1h: 0.00003248118456156135,
    change_1d: 0.1901662839892424,
    change_7d: -0.2664743230111952,
    fdv: 53594700,
    mcap: 3883538,
  },
  {
    id: '1372',
    name: 'Superman Swap',
    address: 'bsc:0x1a5A8873DB5b83D9594A381F33CFE2A5543A9Ec6',
    symbol: 'SUPERMAN',
    url: 'https://www.supermanswap.com/',
    description: 'Yield Farm on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/superman-swap.png',
    audits: '2',
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Binance'],
    module: 'superman-swap/index.js',
    twitter: 'SwapSuperman',
    language: 'Solidity',
    audit_links: ['https://rugdoc.io/project/superman-swap/', 'https://jagosafer.io/superman-swap'],
    listedAt: 1644364311,
    slug: 'superman-swap',
    tvl: 4303.42833635315,
    chainTvls: {
      'Binance-masterchef': 4303.42833635315,
      pool2: 125.89887942100896,
      masterchef: 4303.42833635315,
      'Binance-pool2': 125.89887942100896,
      Binance: 4303.42833635315,
      'Binance-staking': 80.47940870833216,
      staking: 80.47940870833216,
    },
    change_1h: 0.00003294471022741163,
    change_1d: 0.7224442631022896,
    change_7d: 1.7421656242199788,
    staking: 80.47940870833216,
    pool2: 125.89887942100896,
  },
  {
    id: '912',
    name: 'FLRLoans',
    address: null,
    symbol: '-',
    url: 'https://xfl.flr.finance',
    description:
      'FlareLoans is a decentralized borrowing protocol that allows the users to draw interest-free loans against SGB/WSGB used as collateral.',
    chain: 'Songbird',
    logo: 'https://icons.llama.fi/flare-loans.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'CDP',
    chains: ['Songbird'],
    module: 'flare-loans/index.js',
    twitter: 'flrfinance',
    forkedFrom: ['Liquity'],
    listedAt: 1637821444,
    slug: 'flrloans',
    tvl: 4251.05941386179,
    chainTvls: { Songbird: 4251.05941386179 },
    change_1h: 0,
    change_1d: -1.1237964236588596,
    change_7d: 45.55799941041647,
  },
  {
    id: '1232',
    name: 'TerraFloki',
    address: 'terra:terra1u2k0nkenw0p25ljsr4ksh7rxm65y466vkdewwj',
    symbol: 'TFLOKI',
    url: 'https://terrafloki.io',
    description:
      'TerraFloki is the 2nd Decentralised Exchange (DEX) launched on Terra. Main utility of TerraFloki DEX is trading $TFLOKI and NFT Tickets (needed to ensure the NFT-as-a-Subscription model of NFT distribution). NFTs are essential building blocks of the Flokiverse - an emerging P2E ecosystem with an open economy.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/terrafloki.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'terrafloki',
    cmcId: '12626',
    category: 'Dexes',
    chains: ['Terra'],
    module: 'terrafloki.js',
    twitter: 'TerraFloki',
    listedAt: 1641876770,
    slug: 'terrafloki',
    tvl: 4101.998715305946,
    chainTvls: {
      'Terra-staking': 312412.7117138653,
      pool2: 1712792.3813366697,
      Terra: 4101.998715305946,
      'Terra-pool2': 1712792.3813366697,
      staking: 312412.7117138653,
    },
    change_1h: -0.22694610778444257,
    change_1d: 2.064558311184655,
    change_7d: -11.98857885646774,
    staking: 312412.7117138653,
    pool2: 1712792.3813366697,
    mcap: 0,
  },
  {
    id: '1276',
    name: 'KCCguru',
    address: 'kcc:0x4c9fa6297d3413fbbe7ba935e14022d0e5a39dc7',
    symbol: 'KUCINO',
    url: 'https://kcc.guru',
    description:
      'kcc.guru is the home to Kucino Casino, the first Casino of KCC and the Kompound Protocol - an innovative multi-chain yield-compounder with TVL-preserving worker-incentivization mechanisms and without the hidden "migrator" (a.k.a. upgradeStrat, swtichStrategy, panic function) found in other mainstream vaults.',
    chain: 'Kucoin',
    logo: 'https://icons.llama.fi/kccguru.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Prediction Market',
    chains: ['Kucoin'],
    module: 'kccguru/index.js',
    twitter: 'KCCguru',
    listedAt: 1642451415,
    slug: 'kccguru',
    tvl: 4065.5762840786947,
    chainTvls: { 'Kucoin-staking': 0, pool2: 0, Kucoin: 4065.5762840786947, 'Kucoin-pool2': 0, staking: 0 },
    change_1h: 3.391733950716926,
    change_1d: 6.476554720775042,
    change_7d: 56.20123849798068,
    staking: 0,
    pool2: 0,
  },
  {
    id: '1394',
    name: 'MyMine',
    address: 'fantom:0x3d3121D2aeDff5e11E390027331CB544Bc3D2C59',
    symbol: 'MYMINE',
    url: 'https://mymine.defiwaterfall.com/#farms',
    description:
      'MyMine - this is not just a farm! It will bring together and help projects such as Waterfall, Knights of Fantom, Moneyrain',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/mymine.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'mymine',
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: [],
    module: 'mymine/index.js',
    twitter: 'defi_waterfall',
    language: 'Solidity',
    listedAt: 1644554424,
    slug: 'mymine',
    tvl: 3879.1302865122934,
    chainTvls: {
      pool2: 402.8935414018681,
      'Fantom-staking': 1982.6149369799023,
      'Fantom-pool2': 402.8935414018681,
      staking: 1982.6149369799023,
      Fantom: 3879.1302865122934,
    },
    change_1h: 0.6958479513857725,
    change_1d: -0.12316331508587552,
    change_7d: 5.027280791843978,
    staking: 1982.6149369799023,
    pool2: 402.8935414018681,
    mcap: 0,
  },
  {
    id: '1127',
    name: 'Zeus Finance',
    address: 'cronos:0x813658e307fA4DAF9B25e7CE1dE3b40012CA2B74',
    symbol: 'ZEU$',
    url: 'https://www.zeusfinance.app/',
    description:
      'Zeu$ Finance is a sustainable, decentralized, value-oriented, multi-token ecosystem on the newly born Cronos Network',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/zeus-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Cronos'],
    module: 'zeus-finance/index.js',
    twitter: 'ZeusFinanceCro',
    listedAt: 1640718828,
    slug: 'zeus-finance',
    tvl: 3845.362443591034,
    chainTvls: {
      Cronos: 3845.362443591034,
      pool2: 151.00426030555573,
      'Cronos-pool2': 151.00426030555573,
      'Cronos-staking': 73.593530549386,
      staking: 73.593530549386,
    },
    change_1h: -0.05662758247233057,
    change_1d: 0.1909315421182214,
    change_7d: 15.302511988761665,
    staking: 73.593530549386,
    pool2: 151.00426030555573,
  },
  {
    id: '899',
    name: 'Kimochi',
    address: 'bsc:0x4da95bd392811897cde899d25face253a424bfd4',
    symbol: 'KIMOCHI',
    url: 'https://kimochi.finance',
    description: 'Kimochi Finance is a platform fully integrated with DeFi applications on Binance Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/kimochi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'kimochi-finance',
    cmcId: '8507',
    category: 'Services',
    chains: ['Binance'],
    module: 'kimochifinance/index.js',
    twitter: 'KimochiFinance',
    audit_links: ['https://kimochi.gitbook.io/docs/audit'],
    listedAt: 1637708954,
    slug: 'kimochi',
    tvl: 3826.2885341902042,
    chainTvls: {
      'Binance-masterchef': 3826.2885341902042,
      pool2: 655.9835886623696,
      masterchef: 3826.2885341902042,
      'Binance-pool2': 655.9835886623696,
      Binance: 3826.2885341902042,
      'Binance-staking': 0,
      staking: 0,
    },
    change_1h: 1.3673170989856516,
    change_1d: 3.01600363032739,
    change_7d: 16.02757807084116,
    staking: 0,
    pool2: 655.9835886623696,
    fdv: 831232,
    mcap: 0,
  },
  {
    id: '1301',
    name: 'ApeDAO',
    address: 'fantom:0x6a2d140f66fbf1b910ec45c4cf17f9f21b195a77',
    symbol: 'vAPE',
    url: 'https://vapedao.finance/',
    description: 'Masterchef',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/vapedao.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'vapedao/index.js',
    twitter: 'apedaofinance',
    forkedFrom: ['Uniswap'],
    listedAt: 1642765130,
    slug: 'apedao',
    tvl: 3394.311499801847,
    chainTvls: {
      pool2: 92.27199430493178,
      'Fantom-staking': 0,
      masterchef: 3394.311499801847,
      'Fantom-masterchef': 3394.311499801847,
      'Fantom-pool2': 92.27199430493178,
      Fantom: 3394.311499801847,
      staking: 0,
    },
    change_1h: 1.2964778756635553,
    change_1d: 0.7279912191713578,
    change_7d: 0.07049185430514626,
    staking: 0,
    pool2: 92.27199430493178,
  },
  {
    id: '1496',
    name: 'Grave Finance',
    address: 'avax:0x3700a92dd231F0CaC37D31dBcF4c0f5cCb1db6Ca',
    symbol: 'GRAVE',
    url: 'https://grave.finance/#/',
    description: 'Grave is an algorithmic stable coin protocol pegged to the price of 0.1 AVAX.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/grave-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'grave/index.js',
    twitter: 'gravefinance',
    listedAt: 1646201924,
    slug: 'grave-finance',
    tvl: 3327.369836885439,
    chainTvls: {
      'Avalanche-staking': 1363.817819998703,
      pool2: 9660.59229532739,
      Avalanche: 3327.369836885439,
      'Avalanche-pool2': 9660.59229532739,
      staking: 1363.817819998703,
    },
    change_1h: 1.2535969145185817,
    change_1d: 6.757756798318937,
    change_7d: 1.8029334610718735,
    staking: 1363.817819998703,
    pool2: 9660.59229532739,
  },
  {
    id: '710',
    name: 'Squid-Defi',
    address: 'fantom:0xd86Be84feC60Fedf263059BB1581e00d2168e19D',
    symbol: 'SQUID',
    url: 'https://squid-defi.com',
    description: '#1 Stable DeFi Token on the Fantom Blockchain',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/squid-defi.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'squid-defi/index.js',
    twitter: 'SquidDeFi',
    forkedFrom: ['Goose Finance'],
    listedAt: 1635249537,
    slug: 'squid-defi',
    tvl: 3309.590757928763,
    chainTvls: {
      pool2: 80.13713934280028,
      'Fantom-staking': 0,
      'Fantom-pool2': 80.13713934280028,
      staking: 0,
      Fantom: 3309.590757928763,
    },
    change_1h: 4.7931964069789785,
    change_1d: 4.541936046681471,
    change_7d: 11.346051169323857,
    staking: 0,
    pool2: 80.13713934280028,
  },
  {
    id: '1099',
    name: 'Ara Finance',
    address: 'avax:0x2ced20fdfcbe72c27a607d0c9ab1c9ada598c60f',
    symbol: 'ARA',
    url: 'https://arafinance.land/',
    description:
      'Ara Finance is building a community-owned decentralized financial infrastructure to bring more stability and transparency for the world.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/ara-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'ara-finance/index.js',
    twitter: 'AraFinance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640529569,
    slug: 'ara-finance',
    tvl: 3238.155078715716,
    chainTvls: { 'Avalanche-staking': 2900.779332961822, Avalanche: 3238.155078715716, staking: 2900.779332961822 },
    change_1h: -0.05079032984430398,
    change_1d: 0.12107468193873672,
    change_7d: -9.427018460938314,
    staking: 2900.779332961822,
  },
  {
    id: '1479',
    name: 'Phantom Finance',
    address: 'fantom:0x6230a87901C2ca6b23247a9afC1f63A8ecb95B05',
    symbol: 'PHANTOM',
    url: 'https://www.phantom.farm',
    description: 'Yield Farm on Fantom',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/gton-capital.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'phantom-finance/index.js',
    twitter: 'Phantom_fi',
    listedAt: 1645901674,
    slug: 'phantom-finance',
    tvl: 3023.637717439613,
    chainTvls: {
      pool2: 307.47551036141107,
      'Fantom-staking': 0,
      'Fantom-pool2': 307.47551036141107,
      staking: 0,
      Fantom: 3023.637717439613,
    },
    change_1h: 0.6866202886412225,
    change_1d: 0.2057626474201868,
    change_7d: 5.1114078180444835,
    staking: 0,
    pool2: 307.47551036141107,
  },
  {
    id: '879',
    name: 'DeerFi',
    address: null,
    symbol: '-',
    url: 'https://deerfi.com/#/home',
    description: 'DeerFi Protocol makes flash loans available to all tokens for all users',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/deerfi.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'deerfi/index.js',
    twitter: 'deerfi_com',
    listedAt: 1637380935,
    slug: 'deerfi',
    tvl: 2992.485729006088,
    chainTvls: { Ethereum: 2992.485729006088 },
    change_1h: 1.5321713761084652,
    change_1d: 2.3052689359466,
    change_7d: 5.635330283481309,
  },
  {
    id: '1337',
    name: 'ViveLaBouje',
    address: 'fantom:0xe509db88b3c26d45f1fff45b48e7c36a8399b45a',
    symbol: 'VIVE',
    url: 'https://vive.bouje.finance',
    description:
      'Building upon the success of Bouje Finance, Vive La Bouje is a second-generation yield farming platform made on the Fantom Blockchain, aiming to have positive and steady price action. Utilizing a new-age system called APR reset (inspired by Swift Finance).',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/vivelabouje.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'vivelabouje/index.js',
    twitter: 'BoujeFinance',
    audit_links: ['https://docs.bouje.finance/security/audit'],
    listedAt: 1643492389,
    slug: 'vivelabouje',
    tvl: 2991.5423409167674,
    chainTvls: {
      pool2: 775.910097846566,
      'Fantom-staking': 155.87878042302967,
      masterchef: 2991.5423409167674,
      'Fantom-masterchef': 2991.5423409167674,
      'Fantom-pool2': 775.910097846566,
      Fantom: 2991.5423409167674,
      staking: 155.87878042302967,
    },
    change_1h: 0.2753674814534719,
    change_1d: 1.8944213675025594,
    change_7d: -23.405845687572253,
    staking: 155.87878042302967,
    pool2: 775.910097846566,
  },
  {
    id: '158',
    name: 'Dracula',
    address: '0xb78B3320493a4EFaa1028130C5Ba26f0B6085Ef8',
    symbol: 'DRC',
    url: 'https://dracula.sucks/',
    description:
      'Meta-level vampirism concept implemented in Dracula protocol delivers higher profits while mitigating those risks.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/dracula.png',
    audits: '2',
    audit_note: '2',
    gecko_id: ' dracula-token',
    cmcId: '7380',
    category: 'Yield Aggregator',
    chains: ['Ethereum'],
    module: 'dracula.js',
    twitter: 'DraculaProtocol',
    audit_links: ['https://solidity.finance/audits/Dracula/'],
    slug: 'dracula',
    tvl: 2914.1352382904597,
    chainTvls: { staking: 44352.649371784595, Ethereum: 2914.1352382904597, 'Ethereum-staking': 44352.649371784595 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 0,
    staking: 44352.649371784595,
  },
  {
    id: '1069',
    name: 'OptiNyan',
    address: 'optimism:0xA807D4Bc69b050b8D0c99542cf93903C2EFe8b4c',
    symbol: 'NYAN',
    url: 'https://optinyan.xyz',
    description: 'Fair launch token on Optimism. Not related to any other token. Not related to ZipSwap.',
    chain: 'Optimism',
    logo: 'https://icons.llama.fi/optinyan.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Optimism'],
    module: 'optinyan/index.js',
    twitter: 'optinyanxyz',
    forkedFrom: ['ArbiNYAN'],
    listedAt: 1640090956,
    slug: 'optinyan',
    tvl: 2879.3613,
    chainTvls: { Optimism: 2879.3613 },
    change_1h: 0.6233285570915683,
    change_1d: 4.025344316270221,
    change_7d: 15.352847485693587,
  },
  {
    id: '1397',
    name: 'Kromatika',
    address: 'optimism:0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789',
    symbol: 'KROM',
    url: 'https://kromatika.finance',
    description: 'Next generation order book limit trading on Uniswap.',
    chain: 'Optimism',
    logo: 'https://icons.llama.fi/kromatika.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'kromatika',
    cmcId: '14745',
    category: 'Dexes',
    chains: ['Optimism'],
    oracles: [],
    forkedFrom: [],
    module: 'kromatika/index.js',
    twitter: 'KromatikaFi',
    language: 'Solidity',
    listedAt: 1644597183,
    slug: 'kromatika',
    tvl: 2612.0402806376633,
    chainTvls: { Optimism: 2612.0402806376633 },
    change_1h: 0.2809591446812192,
    change_1d: 2.7919719996608166,
    change_7d: 53.530668028289625,
    fdv: 4919534,
    mcap: 4048873,
  },
  {
    id: '1125',
    name: 'MetaCrono',
    address: 'cronos:0x92926DAcCE437955aa47F0DFC7F5C8FCd728b36E',
    symbol: 'METACRONO',
    url: 'https://www.metacrono.finance/',
    description: 'MetaCrono is a new yield farming & yield aggregator protocol powered by Cronos.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/metacrono.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Cronos'],
    module: 'metacrono-finance/index.js',
    twitter: 'MetaCronoFarms',
    audit_links: ['https://rugdoc.io/project/metacrono-finance/'],
    listedAt: 1640717794,
    slug: 'metacrono',
    tvl: 2575.477744662227,
    chainTvls: {
      Cronos: 2575.477744662227,
      pool2: 280.1193521258434,
      masterchef: 2575.477744662227,
      'Cronos-staking': 344.65610423349176,
      'Cronos-masterchef': 2575.477744662227,
      'Cronos-pool2': 280.1193521258434,
      staking: 344.65610423349176,
    },
    change_1h: -0.20240773326050032,
    change_1d: -5.357807632102194,
    change_7d: 657.6576763172662,
    staking: 344.65610423349176,
    pool2: 280.1193521258434,
  },
  {
    id: '1164',
    name: 'DeepSeaDAO',
    address: 'avax:0xb195Af20a0fEc7E2C95b22a1c5de86a2389E40d5',
    symbol: 'SEA',
    url: 'https://www.daodeepsea.com/',
    description: 'A decentralized, reserve currency on Avalanche.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/deepseadao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'deepseadao/index.js',
    twitter: 'DeepseaDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640910261,
    slug: 'deepseadao',
    tvl: 2541.1138695414315,
    chainTvls: { 'Avalanche-staking': 4795.05848045306, Avalanche: 2541.1138695414315, staking: 4795.05848045306 },
    change_1h: 0,
    change_1d: 0.058224928153876476,
    change_7d: -0.8303407152904896,
    staking: 4795.05848045306,
  },
  {
    id: '1317',
    name: 'UniverseFTM',
    address: 'fantom:0xf346362004540F714a45c6E80c719767e087a649',
    symbol: 'UNIVERSE',
    url: 'https://www.universe-swap.com',
    description:
      'Universe, a new Stable DeFi Token on #Fantom Network which helps investors to protect their funds! Our main focus is to deliver a safe, stable farm with a strong and active community! This is a place where people can join together to earn passive income on their favorite cryptocurrencies!',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/universeftm.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'universeftm/index.js',
    twitter: 'universe_ftm',
    listedAt: 1643314901,
    slug: 'universeftm',
    tvl: 2485.1631349415325,
    chainTvls: {
      pool2: 1701.3599236519876,
      'Fantom-staking': 306.9883530219164,
      'Fantom-pool2': 1701.3599236519876,
      staking: 306.9883530219164,
      Fantom: 2485.1631349415325,
    },
    change_1h: 0.713405332586774,
    change_1d: 1.41954356281326,
    change_7d: 8.627337129181313,
    staking: 306.9883530219164,
    pool2: 1701.3599236519876,
  },
  {
    id: '931',
    name: 'Genesis Finance',
    address: 'cronos:0x2638bE7B0d033A59cbcA9139B470503F0a711379',
    symbol: 'GENESIS',
    url: 'https://genesisfinance.app',
    description:
      "Genesis Finance is the a cross chain yield aggregator that enables users to get great returns on their assets from different yield finance products farms pools vaults and the 'Garden of Eden' Genesis. Finance’s team is bringing something new on Cronos and our aim is to keep investors funds safe across different chains",
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/genesis-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Cronos'],
    module: 'genesis/index.js',
    twitter: 'cronos_genesis',
    forkedFrom: ['Uniswap'],
    listedAt: 1638134020,
    slug: 'genesis-finance',
    tvl: 2459.198360043464,
    chainTvls: {
      Cronos: 2459.198360043464,
      pool2: 89.4930864150349,
      masterchef: 2459.1983600434646,
      'Cronos-staking': 0,
      'Cronos-masterchef': 2459.1983600434646,
      'Cronos-pool2': 89.4930864150349,
      staking: 0,
    },
    change_1h: 0.0514408083663227,
    change_1d: 0.7713998155930426,
    change_7d: 9.944284826807959,
    staking: 0,
    pool2: 89.4930864150349,
  },
  {
    id: '1126',
    name: 'LemonSwap',
    address: 'bsc:0x9477477CdDC4A05419A402A9754725Bc9Ee6a40e',
    symbol: 'LEMON',
    url: 'https://lemonswap.space/',
    description:
      'LemonSwap is a new DeFi project on BSC featuring a deflationary token model with a unlimited supply of LEMON tokens and yield farming.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/lemonswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'lemonswap/index.js',
    twitter: 'LemonSwapFarm',
    audit_links: ['https://rugdoc.io/project/lemonswap/'],
    listedAt: 1640718200,
    slug: 'lemonswap',
    tvl: 2324.2836563255155,
    chainTvls: {
      'Binance-masterchef': 2324.2836563255155,
      pool2: 273.41138779663464,
      masterchef: 2324.2836563255155,
      'Binance-pool2': 273.41138779663464,
      Binance: 2324.2836563255155,
      'Binance-staking': 59.268309250335484,
      staking: 59.268309250335484,
    },
    change_1h: 0.09761330086331554,
    change_1d: 1.4692475039692567,
    change_7d: 8.80096002437385,
    staking: 59.268309250335484,
    pool2: 273.41138779663464,
  },
  {
    id: '1038',
    name: 'FireDAO',
    address: null,
    symbol: '-',
    url: 'https://firedao.com',
    description: 'FIREDAO provides automated yield optimization strategies',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/firedao.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'firedao/index.js',
    twitter: 'FIREDAO_',
    forkedFrom: ['Yearn Finance', 'Compound'],
    audit_links: ['https://github.com/FIREDAO/firedao-protocol/tree/main/audit'],
    listedAt: 1639798738,
    slug: 'firedao',
    tvl: 2206.958569748996,
    chainTvls: { Ethereum: 2206.958569748996 },
    change_1h: -0.2240350444653103,
    change_1d: 0.13655056223977624,
    change_7d: -0.18217418309097866,
  },
  {
    id: '759',
    name: 'EggTartSwap',
    address: 'harmony:0x1d245782b32bedbc7d43d313a2a9e4d916b947d6',
    symbol: 'EGGT',
    url: 'https://eggtartswap.com',
    description:
      'EggtartSwap.com is a decentralized one-stop Defi service platform on Harmony (ONE). EggtartSwap is based on automatic market maker (AMM) algorithm mechanism. Eggtartswap allows users to trade directly from their wallets, rather than trading through a third party – tokens never leaving their custody means it is 100% owned by the user.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/eggtartswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Harmony'],
    module: 'eggtartswap/index.js',
    twitter: 'EggTartSwap',
    forkedFrom: ['Uniswap'],
    listedAt: 1635981448,
    slug: 'eggtartswap',
    tvl: 1913.9659352190276,
    chainTvls: { Harmony: 1913.9659352190276 },
    change_1h: 1.6119741412419017,
    change_1d: -0.306640268154851,
    change_7d: 8.722934957541128,
  },
  {
    id: '1122',
    name: 'GrassLand Finance',
    address: 'polygon:0x54c6960fbb3e6572377980277057cf08ccad646b',
    symbol: 'WILD',
    url: 'https://grassland.finance/',
    description:
      'GrassLand Finance is a progressive layered yield farming protocol on the Polygon network which, ultimately, aims to introduce creative features that enable past layers to remain relevant beyond being used to farm future layers.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/grassland-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'grassland-finance/index.js',
    twitter: 'GrassLandFi',
    audit_links: ['https://rugdoc.io/project/grassland-finance/'],
    listedAt: 1640716561,
    slug: 'grassland-finance',
    tvl: 1847.1403495218929,
    chainTvls: {
      pool2: 12.777783625544995,
      'Polygon-pool2': 12.777783625544995,
      masterchef: 1847.1403495218926,
      'Polygon-masterchef': 1847.1403495218926,
      Polygon: 1847.1403495218929,
      'Polygon-staking': 153.49200733374397,
      staking: 153.49200733374397,
    },
    change_1h: 0.5515585477132561,
    change_1d: 2.27918974768005,
    change_7d: 13.428728730622083,
    staking: 153.49200733374397,
    pool2: 12.777783625544995,
  },
  {
    id: '1355',
    name: 'Knights of Fantom',
    address: 'fantom:0xba36266B6565C96BD77815fa898f403Cc06F64cf',
    symbol: 'KNIGHTS',
    url: 'https://www.knights-of-fantom.com',
    description: 'First play 2 earn Yield farm',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/knights-of-fantom.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'knightsfantom/index.js',
    twitter: 'knightsoffantom',
    audit_links: ['https://knights-fantom.gitbook.io/knights-of-fantom/tokenomics/security-and-safety'],
    forkedFrom: ['Sushiswap'],
    listedAt: 1643883431,
    slug: 'knights-of-fantom',
    tvl: 1741.5755685087586,
    chainTvls: {
      pool2: 1123.1652406743515,
      'Fantom-staking': 483.81714306262415,
      'Fantom-pool2': 1123.1652406743515,
      staking: 483.81714306262415,
      Fantom: 1741.5755685087586,
    },
    change_1h: 1.2526928163870252,
    change_1d: -0.5993115638049318,
    change_7d: -58.81140391139024,
    staking: 483.81714306262415,
    pool2: 1123.1652406743515,
  },
  {
    id: '264',
    name: 'Robo-Advisor for Yield',
    address: '-',
    symbol: '-',
    url: 'https://staked.us/v/robo-advisor-yield/',
    description:
      'RAY is a set of smart contracts that automatically move crypto assets to the highest yielding opportunity.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/robo-advisor-for-yield.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'ray/index.js',
    twitter: null,
    audit_links: ['https://staked.gitbook.io/staked/ray/security'],
    slug: 'robo-advisor-for-yield',
    tvl: 1720.505013333032,
    chainTvls: { Ethereum: 1720.505013333032 },
    change_1h: 0.35214680841252743,
    change_1d: 2.614246054894508,
    change_7d: 9.372968093680115,
  },
  {
    id: '914',
    name: 'VampireSwap',
    address: 'fantom:0x97058c0B5ff0E0E350e241EBc63b55906a9EADbc',
    symbol: 'VAM',
    url: 'https://vampireswap.org',
    description: 'The All-in-one DeFi Platform built on Fantom Network',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/vampireswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Fantom'],
    module: 'vampireswap/index.js',
    twitter: 'vampireswap',
    oracles: ['Chainlink'],
    listedAt: 1637883311,
    forkedFrom: ['Uniswap'],
    slug: 'vampireswap',
    tvl: 1704.6232610272675,
    chainTvls: { 'Fantom-staking': 153.45302129016588, staking: 153.45302129016588, Fantom: 1704.6232610272675 },
    change_1h: 0,
    change_1d: 0.07307073556535215,
    change_7d: 6.532375847882861,
    staking: 153.45302129016588,
  },
  {
    id: '1012',
    name: 'Pumpkin DAO',
    address: 'fantom:0x8eDDA0107D661E82df660DBa01Ff1D40FA17B70c',
    symbol: 'PUMP',
    url: 'https://app.pumpkindao.com/',
    description:
      'Pumpkin DAO is an decentralized reserve currency protocol available on the Fantom Network based on the PUMP token. Each PUMP token is backed by a basket of assets (e.g. FTM, DAI-PUMP LP Tokens, etc) in the PumpkinDAO treasury, giving it an intrinsic value that it cannot fall below.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/pumpkin-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Fantom'],
    module: 'pumpkin-dao/index.js',
    twitter: 'pumpkindao_com',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639331363,
    slug: 'pumpkin-dao',
    tvl: 1665.07546622629,
    chainTvls: { 'Fantom-staking': 3405.74041576154, staking: 3405.74041576154, Fantom: 1665.07546622629 },
    change_1h: 0,
    change_1d: 0.12212480442211415,
    change_7d: 3.533359103553096,
    staking: 3405.74041576154,
  },
  {
    id: '1112',
    name: 'Asgard Dao',
    address: 'bsc:0xa5593837af2b99021fe657c80d70d2365F5CfD38',
    symbol: 'ASGARD',
    url: 'https://asgarddao.live/',
    description: 'Asgard is a decentralized reserve currency built on Binance smart chain',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/asgard-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'asgard-dao',
    cmcId: '16229',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'asgard-dao/index.js',
    twitter: 'Asgarddao_',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640640695,
    slug: 'asgard-dao',
    tvl: 1452.2647809758223,
    chainTvls: { Binance: 1452.2647809758223, 'Binance-staking': 505.7527119735154, staking: 505.7527119735154 },
    change_1h: 2.5373123826532265,
    change_1d: 2.82197356079557,
    change_7d: -5.946459591551772,
    staking: 505.7527119735154,
    mcap: 0,
  },
  {
    id: '843',
    name: 'Unirex Finance',
    address: 'fantom:0x350a911687EB9710f1D36792F26D419577B127A8',
    symbol: 'UNIREX',
    url: 'https://www.unirex.finance',
    description:
      'Unirex Finance is a decentralized farm yield project running on Fantom Chain, Polygon (soon), and BSC (soon).',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/unirex-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    module: 'unirexfinance/index.js',
    twitter: 'UnirexFinance',
    forkedFrom: ['Goose Finance'],
    listedAt: 1637026454,
    slug: 'unirex-finance',
    tvl: 1440.6580360682156,
    chainTvls: {
      pool2: 30.60221675002012,
      'Fantom-staking': 0,
      'Fantom-pool2': 30.60221675002012,
      staking: 0,
      Fantom: 1440.6580360682156,
    },
    change_1h: 0.15828519125069818,
    change_1d: 0.9311948484418338,
    change_7d: 3.6636942353803477,
    staking: 0,
    pool2: 30.60221675002012,
  },
  {
    id: '1534',
    name: 'StrikeX',
    address: 'bsc:0xd6fdde76b8c1c45b33790cc8751d5b88984c44ec',
    symbol: 'STRX',
    url: 'https://strikex.com',
    description:
      "StrikeX is the native currency of the blockchain powered eco-system developed & deployed by the UK Registered Company StrikeX Technologies Ltd. Their flag ship product, TradeStrike, will utilise the power of tokenisation to offer trading in Stocks, Cryptocurrencies, NFT's & even Real Estate. Fully compliant, fully collateralised trading, 24/7 - 365 days a year. #ForThePeople",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/strikex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'strikecoin',
    cmcId: 'STRX',
    category: 'Dexes',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'StrikeX.js',
    twitter: 'StrikeXofficial',
    audit_links: ['https://www.certik.com/projects/trade-strike'],
    listedAt: 1646958812,
    slug: 'strikex',
    tvl: 1374.8465718688951,
    chainTvls: {
      'Binance-masterchef': 1374.8465718688951,
      pool2: 27747.853770883627,
      masterchef: 1374.8465718688951,
      'Binance-pool2': 27747.853770883627,
      Binance: 1374.8465718688951,
      'Binance-staking': 57536310.55454102,
      staking: 57536310.55454102,
    },
    change_1h: -0.17497330763792718,
    change_1d: 0.748741276966669,
    change_7d: 4.099492950688727,
    staking: 57536310.55454102,
    pool2: 27747.853770883627,
    fdv: 86655673,
    mcap: 11289030,
  },
  {
    id: '1560',
    name: 'ClaySwap',
    address: 'bsc:0xfd54aE2369a3Be69d441cAcC49F920fFEc9068Ac',
    symbol: 'CLAY',
    url: 'https://clayswap.finance',
    description: 'Yield farm on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/clayswap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'clayswap/index.js',
    twitter: 'ClaySwapFinance',
    listedAt: 1647625891,
    slug: 'clayswap',
    tvl: 1371.8258095573592,
    chainTvls: {
      'Binance-masterchef': 1371.8258095573592,
      pool2: 179.81206865458824,
      masterchef: 1371.8258095573592,
      'Binance-pool2': 179.81206865458824,
      Binance: 1371.8258095573592,
      'Binance-staking': 21.040746560818558,
      staking: 21.040746560818558,
    },
    change_1h: 0.2196014842990479,
    change_1d: 2.3054446501322445,
    change_7d: 10.219179759267988,
    staking: 21.040746560818558,
    pool2: 179.81206865458824,
  },
  {
    id: '1222',
    name: 'Photon Protocol',
    address: 'bsc:0x2F1305F0619ADa14688A4291953bd7d284f9C2a5',
    symbol: 'PHOTON',
    url: 'https://photonprotocol.io/',
    description:
      'Photon is a fork of an Olympus DAO (decentralized reserve currency platform) that has a big advantage, it lives on Binance Smart Chain (BSC).',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/photon-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'photon-protocol/index.js',
    twitter: 'photon_protocol',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641772756,
    slug: 'photon-protocol',
    tvl: 1359.726115037871,
    chainTvls: { Binance: 1359.726115037871, 'Binance-staking': 0.2799444447696768, staking: 0.2799444447696768 },
    change_1h: -0.06864441293517132,
    change_1d: 0.10474909334396898,
    change_7d: -0.13329999999999131,
    staking: 0.2799444447696768,
  },
  {
    id: '1027',
    name: 'Xmas Past',
    address: 'fantom:0xD3111Fb8BDf936B11fFC9eba3b597BeA21e72724',
    symbol: '$XPAST',
    url: 'https://www.xmas-past.com',
    description:
      'XMAS PAST brings forward the old school yield-farming style into the modern age. It offers us the redemption of one self from the rat race of real-life financial struggles, spreading the happiness in this joyous season through a safe and quality yield-farming experience.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/xmaspast.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'xmaspast/index.js',
    twitter: 'BooXmasPast',
    audit_links: ['https://paladinsec.co/projects/xmas-past'],
    listedAt: 1639570611,
    slug: 'xmas-past',
    tvl: 1326.3026240677605,
    chainTvls: {
      pool2: 108.1941875900599,
      'Fantom-staking': 1035.727978398463,
      'Fantom-pool2': 108.1941875900599,
      staking: 1035.727978398463,
      Fantom: 1326.3026240677605,
    },
    change_1h: 3.200032949735032e-8,
    change_1d: -2.615771607443989,
    change_7d: 13.737457321092307,
    staking: 1035.727978398463,
    pool2: 108.1941875900599,
  },
  {
    id: '1396',
    name: 'Veritable DAO',
    address: 'polygon:0x6397835430a5a5f8530F30C412CB217CE3f0943b',
    symbol: 'VRT',
    url: 'https://veritable.finance',
    description: 'Truly decentralized approach of the DAO control',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/veritable-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    oracles: [],
    forkedFrom: [],
    module: 'veritable.js',
    twitter: 'VeritableDao',
    language: 'Solidity',
    listedAt: 1644596897,
    slug: 'veritable-dao',
    tvl: 1042.8533531327707,
    chainTvls: { Polygon: 1042.8533531327707 },
    change_1h: 0.11395725243465904,
    change_1d: 0.059704377005218134,
    change_7d: 0.4472613637380931,
  },
  {
    id: '1212',
    name: 'SamoDAO',
    address: 'bsc:0xcd7CBc46d083A245B45E22Cb4bE2c569947Ce896',
    symbol: 'samoDAO',
    url: 'https://www.samodao.finance/',
    description: 'SamoDAO is a decentralized reserve currency platform on Binance Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/samodao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'samodao/index.js',
    twitter: 'samoDAO_finance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641586084,
    slug: 'samodao',
    tvl: 969.8531021203213,
    chainTvls: { Binance: 969.8531021203213, 'Binance-staking': 396.818436319822, staking: 396.818436319822 },
    change_1h: -0.06864441293518553,
    change_1d: 0.09874008541316925,
    change_7d: -0.5424340336034845,
    staking: 396.818436319822,
  },
  {
    id: '808',
    name: 'Swift Finance',
    address: 'avax:0x0Aa4ef05B43700BF4b6E6Dc83eA4e9C2CF6Af0fA',
    symbol: 'SWIFT',
    url: 'https://swiftfinance.farm/',
    description:
      'Swift Finance is the first yield farm that requires active management and participation by its farmer, known as an "active rotation harvest-tending" yield farm.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/swift-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'swift-finance',
    cmcId: '12658',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'swift-finance/index.js',
    twitter: 'SwiftFinanceOff',
    audit_links: ['https://rugdoc.io/project/swift-finance/'],
    listedAt: 1636397118,
    slug: 'swift-finance',
    tvl: 967.0639512284852,
    chainTvls: {
      'Avalanche-masterchef': 967.0639512284852,
      pool2: 3893.122191125067,
      masterchef: 967.0639512284852,
      Avalanche: 967.0639512284852,
      'Avalanche-staking': 2110.833334654374,
      'Avalanche-pool2': 3893.122191125067,
      staking: 2110.833334654374,
    },
    change_1h: 0,
    change_1d: 1.6680298983519322,
    change_7d: 1.9850159684627897,
    staking: 2110.833334654374,
    pool2: 3893.122191125067,
    fdv: 3.15,
    mcap: 0,
  },
  {
    id: '1042',
    name: 'Solana Farm',
    address: 'bsc:0xFEa6aB80cd850c3e63374Bc737479aeEC0E8b9a1',
    symbol: 'SOL',
    url: 'https://solanafarm.finance',
    description: 'Staking platform on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/solana-farm.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Binance'],
    module: 'solanafarm/index.js',
    twitter: 'solana_farm',
    audit_links: ['https://github.com/Tech-Audit/Smart-Contract-Audits/blob/main/TECHAUDIT_SOLANAFARM.pdf'],
    forkedFrom: ['Uniswap'],
    listedAt: 1639805654,
    slug: 'solana-farm',
    tvl: 917.7551579266136,
    chainTvls: {
      'Binance-masterchef': 917.7551579266136,
      pool2: 146.93146669547775,
      masterchef: 917.7551579266136,
      'Binance-pool2': 146.93146669547775,
      Binance: 917.7551579266136,
      'Binance-staking': 0,
      staking: 0,
    },
    change_1h: 0.24353423122087747,
    change_1d: 0.7473995966590792,
    change_7d: 11.440336691161221,
    staking: 0,
    pool2: 146.93146669547775,
  },
  {
    id: '578',
    name: "The Dragon's Lair",
    address: 'avax:0x88c090496125b751b4e3ce4d3fdb8e47dd079c57',
    symbol: 'DREGG',
    url: 'https://thedragonslair.farm',
    description:
      "The Dragon's Lair is a yield farm on the Avalanche network that serves as an on-ramp to our blockchain gaming ecosystem. This is the layer 1 of our project.",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/the-dragons-lair.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dragon-egg',
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'thedragonslair/index.js',
    twitter: 'DRGNCRYPTOGAMIN',
    audit_links: ['https://docs.thedragonslair.farm/security-and-safety'],
    slug: 'the-dragons-lair',
    tvl: 898.1976826038346,
    chainTvls: {
      'Avalanche-staking': 229.59273266690127,
      pool2: 743.1303065604286,
      Avalanche: 898.1976826038346,
      'Avalanche-pool2': 743.1303065604286,
      staking: 229.59273266690127,
    },
    change_1h: 1.1586263753352597,
    change_1d: 5.026248003005534,
    change_7d: 10.161507851331763,
    staking: 229.59273266690127,
    pool2: 743.1303065604286,
    fdv: 3780.43,
    mcap: 0,
  },
  {
    id: '1218',
    name: 'Sierra',
    address: 'avax:0x862D2E466C28dB7961E953083854e19481478842',
    symbol: 'SRA',
    url: 'https://www.sierra.money/',
    description:
      'Sierra is a decentralized reserve currency protocol available on the Avalanche Network based on the SRA token.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/sierra.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'sierra/index.js',
    twitter: 'SierraDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641750405,
    slug: 'sierra',
    tvl: 834.8594498760463,
    chainTvls: { 'Avalanche-staking': 9354.417225678093, Avalanche: 834.8594498760463, staking: 9354.417225678093 },
    change_1h: 0.15703876692190022,
    change_1d: 0.12107468193873672,
    change_7d: -0.7768699259116545,
    staking: 9354.417225678093,
  },
  {
    id: '1076',
    name: 'ArbiCheems',
    address: 'arbitrum:0x75a2f30929C539E7d4eE033c9331b89F879c0cf7',
    symbol: 'CHEEMS',
    url: 'https://arbicheems.finance/',
    description:
      "ArbiCheems is a fun, cheeseburger-loving squishy doge modeled after a particular rainbow-cat protocol but with some notable tweaks to make it more value-oriented and integrate in the Arbi's Food Court.",
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/arbicheems.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Arbitrum'],
    module: 'arbicheems/index.js',
    twitter: null,
    listedAt: 1640176219,
    slug: 'arbicheems',
    tvl: 802.5310194753761,
    chainTvls: {
      pool2: 223.2528567228197,
      'Arbitrum-staking': 3197.899656834041,
      Arbitrum: 802.5310194753761,
      'Arbitrum-pool2': 223.2528567228197,
      staking: 3197.899656834041,
    },
    change_1h: 0.4976849518760815,
    change_1d: 3.2788049635935863,
    change_7d: 11.978606505062018,
    staking: 3197.899656834041,
    pool2: 223.2528567228197,
  },
  {
    id: '1114',
    name: 'PirateDAO',
    address: 'avax:0x30ff717fc266f2dcb3adc1af4043f8c517491e66',
    symbol: 'JOLLY',
    url: 'https://piratedao.money',
    description: 'PirateDAO is a OHM fork on avalanche network',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/pirate-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'pirate-dao/index.js',
    twitter: 'DAOPirate',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640642417,
    slug: 'piratedao',
    tvl: 801.290053223316,
    chainTvls: { 'Avalanche-staking': 1161.115125937229, Avalanche: 801.290053223316, staking: 1161.115125937229 },
    change_1h: 0,
    change_1d: 0.12107468193873672,
    change_7d: -0.7768699259116545,
    staking: 1161.115125937229,
  },
  {
    id: '1092',
    name: 'AfraSwap',
    address: 'bsc:0x5badd826aefa700446fa6d784e6ff97ed6eedca9',
    symbol: 'AFRA',
    url: 'https://afraswap.io',
    description:
      "AfraSwap provides a secure and easy way to swap tokens on BSC while providing the best prices by aggregating multiple different AMM's.",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/afraswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'afraswap/index.js',
    twitter: 'afraswap',
    forkedFrom: ['Uniswap'],
    listedAt: 1640516061,
    slug: 'afraswap',
    tvl: 728.0992679003517,
    chainTvls: { Binance: 728.0992679003517, 'Binance-staking': 12188.605589361478, staking: 12188.605589361478 },
    change_1h: -0.01818512456812016,
    change_1d: 4.62055897477704,
    change_7d: 12.860349225764892,
    staking: 12188.605589361478,
  },
  {
    id: '1120',
    name: 'Caribou Finance',
    address: 'fantom:0x2dBa3ea510cf7bFCCc9c185b7c9094d687ADE503',
    symbol: 'CARIBOU',
    url: 'https://www.caribou.finance/',
    description:
      'Caribou Finance is a new DeFi project on Fantom featuring low emission rate, low supply, harvest lock & layered farming later on to our platform.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/caribou-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'caribou-finance/index.js',
    twitter: 'CaribouFinance',
    listedAt: 1640716008,
    slug: 'caribou-finance',
    tvl: 703.1407368987444,
    chainTvls: {
      pool2: 4.152709172486739,
      'Fantom-staking': 0.12015693999299999,
      masterchef: 703.1407368987444,
      'Fantom-masterchef': 703.1407368987444,
      'Fantom-pool2': 4.152709172486739,
      Fantom: 703.1407368987444,
      staking: 0.12015693999299999,
    },
    change_1h: 0.4715186394410864,
    change_1d: 2.3996190653351164,
    change_7d: 12.752021776082259,
    staking: 0.12015693999299999,
    pool2: 4.152709172486739,
  },
  {
    id: '1200',
    name: 'Tenet',
    address: '0x74159651a992952e2bf340d7628459aa4593fc05',
    symbol: 'TEN',
    url: 'https://tenet.farm',
    description:
      'Tenet is a cross-chain Automated Market Maker (AMM) connector that provides a decentralized Liquidity Tap for various tokens.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/tenet.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'tenet',
    cmcId: '8193',
    category: 'Yield',
    chains: ['Ethereum', 'Binance'],
    module: 'tenet/index.js',
    twitter: 'TenetFarm',
    listedAt: 1641421756,
    slug: 'tenet',
    tvl: 589.2865468191426,
    chainTvls: {
      pool2: 9470.864927902308,
      'Binance-pool2': 3896.5997237922656,
      'Ethereum-pool2': 5574.265204110043,
      Binance: 7.7974834674832305,
      Ethereum: 581.4890633516594,
      'Binance-staking': 0,
      'Ethereum-staking': 6439.3055152466895,
      staking: 6439.3055152466895,
    },
    change_1h: 0,
    change_1d: 3.9889286630228042,
    change_7d: 15.25121846023967,
    staking: 6439.3055152466895,
    pool2: 9470.864927902308,
    mcap: 0,
  },
  {
    id: '855',
    name: 'Risq Protocol',
    address: 'bsc:0xC2c82622a7CB7159D44CEA7e39843a495f2674De',
    symbol: 'RISQ',
    url: 'https://risq.capital',
    description:
      'RISQ is an on-chain options trading DEX and AMM on Binance Smart Chain. RISQ Protocol offers decentralized financial products and services that empower users without barriers to entry. The project’s main goal is to provide a set of tools and Dapps for risk and asset management on multiple blockchains, including Binance Smart Chain, Polygon, Ethereum, and MoonRiver!',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/risq-protocol.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'risq-protocol',
    cmcId: '12467',
    category: 'Options',
    chains: ['Binance'],
    module: 'risq.js',
    twitter: 'risqprotocol',
    audit_links: ['https://solidity.finance/audits/RisqProtocol'],
    oracles: ['Chainlink'],
    listedAt: 1637190999,
    slug: 'risq-protocol',
    tvl: 585.2113429514346,
    chainTvls: { Binance: 585.2113429514346 },
    change_1h: 0.1408811448719831,
    change_1d: 1.4669251795122307,
    change_7d: 8.70610627336994,
    mcap: 0,
  },
  {
    id: '1147',
    name: 'Emerald Swap',
    address: 'bsc:0xC3B538219B73efd57b75f616e909a728bE945bBb',
    symbol: 'EMERALD',
    url: 'https://www.emeraldswap.com/',
    description: 'Yield Farm on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/emerald-swap.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'emeraldswap/index.js',
    twitter: 'SwapEmerald',
    listedAt: 1640801746,
    slug: 'emerald-swap',
    tvl: 555.4842249824256,
    chainTvls: {
      'Binance-masterchef': 555.4842249824255,
      pool2: 155.82260435908927,
      masterchef: 555.4842249824255,
      'Binance-pool2': 155.82260435908927,
      Binance: 555.4842249824256,
      'Binance-staking': 28.089109852454666,
      staking: 28.089109852454666,
    },
    change_1h: 0.014041660988155513,
    change_1d: -7.839101106079312,
    change_7d: -4.380475121038856,
    staking: 28.089109852454666,
    pool2: 155.82260435908927,
  },
  {
    id: '861',
    name: 'Bear Finance',
    address: 'fantom:0x3b1a7770A8c97dCB21c18a2E18D60eF1B01d6DeC',
    symbol: 'BEAR',
    url: 'https://bearfinance.org',
    description:
      'Bear Finance is a reserve Defi protocol that produces BEAR tokens: backed by value and powered by yield farming.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/bear-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'bearfinance/index.js',
    twitter: 'BearFinanceOrg',
    listedAt: 1637345368,
    slug: 'bear-finance',
    tvl: 498.96984530735364,
    chainTvls: {
      pool2: 329.4716468820697,
      'Fantom-staking': 334.23402186631876,
      'Fantom-pool2': 329.4716468820697,
      staking: 334.23402186631876,
      Fantom: 498.96984530735364,
    },
    change_1h: 0.26286481623978375,
    change_1d: 0.41679987092133786,
    change_7d: -0.09970089730806819,
    staking: 334.23402186631876,
    pool2: 329.4716468820697,
  },
  {
    id: '823',
    name: 'TomoFinance',
    address: 'tomo:-',
    symbol: 'TFI',
    url: 'https://tomo.finance',
    description: 'TomoFinance is a portal to generate TAI, the stablecoin on TomoChain.',
    chain: 'TomoChain',
    logo: 'https://icons.llama.fi/tomofinance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'CDP',
    chains: ['TomoChain'],
    module: 'tomofinance.js',
    twitter: 'TomoFinance',
    listedAt: 1636488285,
    slug: 'tomofinance',
    tvl: 442.948169,
    chainTvls: { TomoChain: 442.948169 },
    change_1h: -0.011700000000004707,
    change_1d: -0.011700000000004707,
    change_7d: -0.011700000000004707,
  },
  {
    id: '687',
    name: 'JaguarSwap',
    address: 'arbitrum:0x31535F7A83083E3f34899F356ECC7246FBF2E82D',
    symbol: 'JAGUAR',
    url: 'https://arbi.jaguarswap.com',
    description:
      'JaguarSwap is a brand new DeFi project designed by an experienced development team as the 3rd generation deflationary yield farm & AMM on Arbitrum.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/jaguarswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'jaguarswap',
    cmcId: null,
    category: 'Yield',
    chains: ['Arbitrum'],
    module: 'jaguarswap.js',
    twitter: 'JaguarSwap',
    listedAt: 1634675023,
    slug: 'jaguarswap',
    tvl: 433.0893172279096,
    chainTvls: { 'Arbitrum-staking': 0, Arbitrum: 433.0893172279096, staking: 0 },
    change_1h: 0.166482801812478,
    change_1d: 1.609510801363598,
    change_7d: -4.530233988137908,
    staking: 0,
    mcap: 0,
  },
  {
    id: '1480',
    name: 'Xray Swap',
    address: 'bsc:0x12C415aAFB1A521B42251e972BB7Ce6795F7669b',
    symbol: 'XRAY',
    url: 'https://www.xrayswap.com/',
    description: 'Yield farm on BSC',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/xray-swap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'xrayswap/index.js',
    twitter: 'xrayswap',
    audit_links: ['https://rugdoc.io/project/xray-swap/'],
    listedAt: 1646019216,
    slug: 'xray-swap',
    tvl: 387.2388539856179,
    chainTvls: {
      'Binance-masterchef': 387.2388539856179,
      pool2: 71.82612922732942,
      masterchef: 387.2388539856179,
      'Binance-pool2': 71.82612922732942,
      Binance: 387.2388539856179,
      'Binance-staking': 23.70493049512826,
      staking: 23.70493049512826,
    },
    change_1h: -0.2285144205050642,
    change_1d: 0.6891690828459076,
    change_7d: -68.00744144175003,
    staking: 23.70493049512826,
    pool2: 71.82612922732942,
  },
  {
    id: '795',
    name: 'SenpaiSwap',
    address: null,
    symbol: '-',
    url: 'https://senpaiswap.com/#/',
    description: 'AMM on the Boba Network',
    chain: 'Boba',
    logo: 'https://icons.llama.fi/senpaiswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Boba'],
    module: 'senpai/index.js',
    twitter: 'senpaiswap',
    forkedFrom: ['Olympus DAO', 'Uniswap'],
    listedAt: 1636194738,
    slug: 'senpaiswap',
    tvl: 381.39111028412964,
    chainTvls: { Boba: 381.39111028412964, 'Boba-staking': 44838.090920501854, staking: 44838.090920501854 },
    change_1h: -0.011635702583006946,
    change_1d: -0.011439337447853859,
    change_7d: -0.012415305196228132,
    staking: 44838.090920501854,
  },
  {
    id: '738',
    name: 'Defi Halal',
    address: 'polygon:0x7F6758884235448f3B096738Cf98D61541f0F8F6',
    symbol: 'HALAL',
    url: 'https://defihalal.org',
    description:
      'DeFi Halal is a decentralized borrowing protocol that allows you to draw 0% interest loans against Polygon MATIC used as collateral.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/defi-halal.png',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'CDP',
    chains: ['Polygon'],
    module: 'defihalal/index.js',
    twitter: 'DefiHalal',
    oracles: ['Chainlink'],
    listedAt: 1635801672,
    forkedFrom: ['Liquity'],
    slug: 'defi-halal',
    tvl: 365.895,
    chainTvls: { Polygon: 365.895 },
    change_1h: 0,
    change_1d: 1.1695906432748444,
    change_7d: 14.569536423841043,
  },
  {
    id: '938',
    name: 'BitANT',
    address: 'optimism:0x5029c236320b8f15ef0a657054b84d90bfbeded3',
    symbol: 'BITANT',
    url: 'https://bitbtc.money/#/',
    description: 'BitANT is a MEME platform token of the BitBTC Protocol project.',
    chain: 'Optimism',
    logo: 'https://icons.llama.fi/bitant.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bitant',
    cmcId: '12763',
    category: 'Yield',
    chains: ['Optimism'],
    module: 'bitBTC.js',
    twitter: 'HelloBitANT',
    audit_links: ['https://www.certik.com/projects/bitant'],
    listedAt: 1638195393,
    slug: 'bitant',
    tvl: 339.9835,
    chainTvls: { Optimism: 339.9835, 'Optimism-staking': 19273.169098916027, staking: 19273.169098916027 },
    change_1h: 0.018798562954302156,
    change_1d: 1.4083015671325683,
    change_7d: 11.674712563259405,
    staking: 19273.169098916027,
    mcap: 0,
  },
  {
    id: '1081',
    name: 'BabyPig Finance',
    address: 'fantom:0x3a76b1b3e827cc7350e66a854eced871a81a3527',
    symbol: 'BABYPIG',
    url: 'https://www.babypig.finance',
    description:
      'Experience the Real Yield Farming on Fantom Protocol. Our ultimate aim is to built a stable passive earning platform to our investors and to become a well stable farm on Fantom.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/babypig-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'babypigfinance/index.js',
    twitter: 'BabypigCash',
    forkedFrom: ['Uniswap'],
    slug: 'babypig-finance',
    tvl: 317.7293530582916,
    chainTvls: {
      pool2: 0.11359449336078359,
      'Fantom-staking': 0,
      'Fantom-pool2': 0.11359449336078359,
      staking: 0,
      Fantom: 317.7293530582916,
    },
    change_1h: 0.04668662139961555,
    change_1d: 0.41861959938746907,
    change_7d: 5.807491663060048,
    staking: 0,
    pool2: 0.11359449336078359,
  },
  {
    id: '1434',
    name: 'NewSpace Finance',
    address: 'bsc:0xbbdaA8700A7caAAf3b4aAc1fA6Fb5fF76Fc14C56',
    symbol: 'NEWSPACE',
    url: 'https://newspace.finance',
    description:
      'Newspace is a Binance Smart Chain yield farming project with high yields and a limited supply of 58,000 NEWSPACE tokens.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/newspace-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'newspace/index.js',
    twitter: 'newspacefinance',
    listedAt: 1645298931,
    slug: 'newspace-finance',
    tvl: 314.69896515978144,
    chainTvls: {
      'Binance-masterchef': 314.69896515978144,
      pool2: 66.79580982518857,
      masterchef: 314.69896515978144,
      'Binance-pool2': 66.79580982518857,
      Binance: 314.69896515978144,
      'Binance-staking': 39.40479645638397,
      staking: 39.40479645638397,
    },
    change_1h: 0.09459196053740015,
    change_1d: 0.5346168346435576,
    change_7d: -80.89112080113343,
    staking: 39.40479645638397,
    pool2: 66.79580982518857,
  },
  {
    id: '1124',
    name: 'Mirai',
    address: 'polygon:0xC6db58E05F647e6D0EE1bf38aC2619867cb9D3cD',
    symbol: 'MIRAI',
    url: 'https://mirai.farm/',
    description:
      'Mirai is a Polygon yield farming project with a low emission rate, high yields and a limited cap of 30,000 MIRAI tokens.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/mirai.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'mirai/index.js',
    twitter: 'MiraiYieldFarm',
    audit_links: ['https://rugdoc.io/project/mirai-farm/'],
    listedAt: 1640717105,
    slug: 'mirai',
    tvl: 309.8685052397948,
    chainTvls: {
      pool2: 23.61485360068857,
      'Polygon-pool2': 23.61485360068857,
      masterchef: 309.8685052397948,
      'Polygon-masterchef': 309.8685052397948,
      Polygon: 309.8685052397948,
      'Polygon-staking': 0,
      staking: 0,
    },
    change_1h: 0.12735956833429896,
    change_1d: 0.18231893655304532,
    change_7d: 0.7759324048000025,
    staking: 0,
    pool2: 23.61485360068857,
  },
  {
    id: '1512',
    name: 'Spade Finance',
    address: 'fantom:0x300e170EC32d09A4AA312A21c6bEd1C001Dcd996',
    symbol: 'SPADE',
    url: 'https://spadefinance.farm',
    description:
      'Spade Finance, a standard Defi protocol on Fantom Network. With many exciting features added to the Platform',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/spade-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Farm',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Goose Finance'],
    module: 'spadefinance/index.js',
    twitter: 'Spadefinanceftm',
    listedAt: 1646502914,
    slug: 'spade-finance',
    tvl: 301.4467984494951,
    chainTvls: {
      pool2: 14.3808478574495,
      'Fantom-staking': 24.747887064063057,
      'Fantom-pool2': 14.3808478574495,
      staking: 24.747887064063057,
      Fantom: 301.4467984494951,
    },
    change_1h: 0.31785684351366683,
    change_1d: -24.24173844677378,
    change_7d: -83.10516212664697,
    staking: 24.747887064063057,
    pool2: 14.3808478574495,
  },
  {
    id: '1146',
    name: 'CyberFantasyFembots',
    address: 'polygon:0xe29E3D9Fa721dFA10ba879fbf0E947425dA611cB',
    symbol: 'SAFE',
    url: 'https://factoryhq.io/',
    description: 'A Yield Farm & NFT Escape Room Game.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/cyberfantasyfembots.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'cyberfantasyfembots/index.js',
    twitter: 'FantasyFembots',
    listedAt: 1640801280,
    slug: 'cyberfantasyfembots',
    tvl: 236.43908786819665,
    chainTvls: {
      pool2: 5.4794123316910035,
      'Polygon-pool2': 5.4794123316910035,
      masterchef: 236.43908786819665,
      'Polygon-masterchef': 236.43908786819665,
      Polygon: 236.43908786819665,
      'Polygon-staking': 10.799347328512999,
      staking: 10.799347328512999,
    },
    change_1h: 0.061698378820068456,
    change_1d: 1.454062052673649,
    change_7d: 7.223677047448177,
    staking: 10.799347328512999,
    pool2: 5.4794123316910035,
  },
  {
    id: '1143',
    name: 'Hotpot Finance',
    address: 'bsc:0x00438AE909739f750c5df58b222Fe0Bde900C210',
    symbol: 'MEAT',
    url: 'https://hotpot.farm/',
    description: 'Hotpot Finance aims to combine the best of all DeFi features.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/hotpot-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'hotpot-finance/index.js',
    twitter: 'Hotpot_FinOff',
    audit_links: ['https://rugdoc.io/project/hotpot-finance/'],
    listedAt: 1640799974,
    slug: 'hotpot-finance',
    tvl: 185.04810301560804,
    chainTvls: {
      'Binance-masterchef': 185.04810301560804,
      pool2: 0,
      masterchef: 185.04810301560804,
      'Binance-pool2': 0,
      Binance: 185.04810301560804,
      'Binance-staking': 123.01368370528223,
      staking: 123.01368370528223,
    },
    change_1h: 1.908101475317551,
    change_1d: 2.680622493000982,
    change_7d: 15.533150715482307,
    staking: 123.01368370528223,
    pool2: 0,
  },
  {
    id: '732',
    name: 'Bouje Finance',
    address: 'fantom:0x37F70aa9fEfc8971117BD53A1Ddc2372aa7Eec41',
    symbol: 'BOUJE',
    url: 'https://bouje.finance',
    description:
      'Bouje Finance new age Yield Farming Protocol that aims to attain sustainable and steady price action.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/bouje.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bouje-token',
    cmcId: '13522',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'boujefinance/index.js',
    twitter: 'BoujeFinance',
    audit_links: ['https://paladinsec.co/projects/bouje-finance/'],
    listedAt: 1635563349,
    slug: 'bouje-finance',
    tvl: 182.10298363278915,
    chainTvls: {
      pool2: 426.49056600648163,
      'Fantom-staking': 3370.5566184761597,
      masterchef: 182.10298363278915,
      'Fantom-masterchef': 182.10298363278915,
      'Fantom-pool2': 426.49056600648163,
      Fantom: 182.10298363278915,
      staking: 3370.5566184761597,
    },
    change_1h: 0.13425660582093712,
    change_1d: -0.23540759752879126,
    change_7d: 2.5353187420894585,
    staking: 3370.5566184761597,
    pool2: 426.49056600648163,
    fdv: 14576.33,
    mcap: 0,
  },
  {
    id: '1137',
    name: 'SunFlower Finance',
    address: 'bsc:0x3295fdE99976e6B6b477E6834b2651a22DeB1dd7',
    symbol: 'SFO',
    url: 'https://sunflowerfi.org',
    description: 'The tastiest AMM and yield farm on Binance Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/sunflower-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'sunflower-finance',
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'sunflowerfi/index.js',
    twitter: 'SunFlowerFiSFO',
    audit_links: ['https://solidity.finance/audits/Sunflower'],
    listedAt: 1640738153,
    slug: 'sunflower-finance',
    tvl: 139.7783232459955,
    chainTvls: {
      'Binance-masterchef': 139.7783232459955,
      pool2: 19583.61563454556,
      masterchef: 139.7783232459955,
      'Binance-pool2': 19583.61563454556,
      Binance: 139.7783232459955,
      'Binance-staking': 0,
      staking: 0,
    },
    change_1h: 0.061755023726135505,
    change_1d: 0.13642318833613842,
    change_7d: -0.5509054092979113,
    staking: 0,
    pool2: 19583.61563454556,
    mcap: 0,
  },
  {
    id: '1362',
    name: 'Hexal',
    address: 'bsc:0x57612d60b415ad812da9a7cf5672084796a4ab81',
    symbol: 'HXL',
    url: 'https://app.hexal.finance/',
    description: 'A comunity governed hedge fund powered by DeFi.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/hexal.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'hexal/index.js',
    twitter: 'HexalDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1644137361,
    slug: 'hexal',
    tvl: 124.38298088002504,
    chainTvls: { Binance: 124.38298088002504, 'Binance-staking': 19.16463493314646, staking: 19.16463493314646 },
    change_1h: -0.33263473053892767,
    change_1d: 0.10474909334396898,
    change_7d: -98.35529163432183,
    staking: 19.16463493314646,
  },
  {
    id: '1438',
    name: 'TreeHouse Finance',
    address: 'avax:0x948aa1f5b895a8130dcc8abc7c6e9816ce2e00fe',
    symbol: 'LEAF',
    url: 'https://www.treehouse-finance.com',
    description: 'Treehouse Finance Decentralized Government for Large Investments',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/treehouse-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: ['SushiSwap'],
    module: 'treehousefinance/index.js',
    twitter: 'treehousefnce',
    listedAt: 1645299673,
    slug: 'treehouse-finance',
    tvl: 110.97525672765977,
    chainTvls: {
      'Avalanche-masterchef': 110.97525672765977,
      pool2: 10.582662859405374,
      masterchef: 110.97525672765977,
      Avalanche: 110.97525672765977,
      'Avalanche-staking': 270.59817120197135,
      'Avalanche-pool2': 10.582662859405374,
      staking: 270.59817120197135,
    },
    change_1h: 0.5572202977729717,
    change_1d: -0.6431422531682927,
    change_7d: -25.435713502070698,
    staking: 270.59817120197135,
    pool2: 10.582662859405374,
  },
  {
    id: '1105',
    name: 'Bourbon DAO',
    address: 'bsc:0xce1ad4e2810e413e2e3684decc58a0bd01c907d9',
    symbol: 'WHISKEY',
    url: 'https://www.bourbondao.com/',
    description:
      'Bourbon DAO is a decentralized reserve currency protocol based on the WHISKEY token and aims at building a community-owned decentralized financial infrastructure for the crypto world.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bourbon-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'bourbon-dao/index.js',
    twitter: 'FinanceBourbon',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640606970,
    slug: 'bourbon-dao',
    tvl: 100.12629281173665,
    chainTvls: { Binance: 100.12629281173665, 'Binance-staking': 613.4444077760278, staking: 613.4444077760278 },
    change_1h: -0.26898229530706885,
    change_1d: -2.9675996281610537,
    change_7d: -83.53448890600097,
    staking: 613.4444077760278,
  },
  {
    id: '1121',
    name: 'AvaViking',
    address: 'avax:0xF1b0F6DF4fc3710b3497c34B0Ee366099054add8',
    symbol: 'VIKING',
    url: 'https://www.avaviking.com/',
    description:
      'AVAVIKING Finance is the next generation of decentralised finance (DeFi) and yield farm application on Avalanche blockchain.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/avaviking.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'avaviking/index.js',
    twitter: 'vikingavax',
    listedAt: 1640716250,
    slug: 'avaviking',
    tvl: 90.92984540278378,
    chainTvls: {
      'Avalanche-masterchef': 90.92984540278378,
      pool2: 78.16076342746231,
      masterchef: 90.92984540278378,
      Avalanche: 90.92984540278378,
      'Avalanche-staking': 87.71027294704538,
      'Avalanche-pool2': 78.16076342746231,
      staking: 87.71027294704538,
    },
    change_1h: 0.4785548611634596,
    change_1d: 2.1676119009449053,
    change_7d: 4.1952217372950145,
    staking: 87.71027294704538,
    pool2: 78.16076342746231,
  },
  {
    id: '1273',
    name: 'Cleopatra DAO',
    address: 'avax:0x48358BfAA1EC39AafCb0786c3e0342Db676Df93E',
    symbol: 'CAT',
    url: 'https://cleopatra-dao.xyz',
    description:
      'Yield aggregator with interest bearing tokens and extra functions like casino & collateral. The system is specifically edited to prevent whales influencing the price.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/cleopatra-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield Aggregator',
    chains: ['Avalanche'],
    module: 'cleopatradao.js',
    twitter: 'Cleopatra_DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1642404036,
    slug: 'cleopatra-dao',
    tvl: 90.55830352021869,
    chainTvls: { 'Avalanche-staking': 85.3151556675031, Avalanche: 90.55830352021869, staking: 85.3151556675031 },
    change_1h: 0,
    change_1d: 0.12107468193873672,
    change_7d: -0.7768699259116545,
    staking: 85.3151556675031,
  },
  {
    id: '1011',
    name: 'Papa DAO',
    address: 'avax:0x70b33ebC5544C12691d055b49762D0F8365d99Fe',
    symbol: 'PAPA',
    url: 'https://papadao.co/#/stake',
    description:
      '$PAPA is a decentralized reserve currency that remains unbound by a peg - that is used in the #Metaverse. We back Metaverse & GameFi Projects.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/papa-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'papa-dao',
    cmcId: '15794',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'papa-dao/index.js',
    twitter: 'PapaDAOofficial',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639330608,
    slug: 'papa-dao',
    tvl: 64.50022882600682,
    chainTvls: { 'Avalanche-staking': 158040.7199568389, Avalanche: 64.50022882600682, staking: 158040.7199568389 },
    change_1h: 1.9478652642376204,
    change_1d: 4.6695015312397885,
    change_7d: 9.148211939063742,
    staking: 158040.7199568389,
    fdv: 7927.16,
    mcap: 0,
  },
  {
    id: '977',
    name: 'Tornadao',
    address: 'avax:0xb80323c7aa915cb960b19b5cca1d88a2132f7bd1',
    symbol: 'NADO',
    url: 'https://www.tornadao.finance',
    description:
      'Tornadao is a decentralized reserve currency protocol available on the Avalanche (AVAX) Network based on the NADO token. Each NADO token is backed by a basket of assets (initially with MIM, and MIM/NADO) in the Tornadao treasury',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/tornadao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'tornadao',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'tornadao/index.js',
    twitter: 'Tornadao_fi',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638680155,
    slug: 'tornadao',
    tvl: 63.28655743511972,
    chainTvls: { 'Avalanche-staking': 4522.667707319775, Avalanche: 63.28655743511972, staking: 4522.667707319775 },
    change_1h: 0,
    change_1d: 3.664169154359314,
    change_7d: -99.70413473222753,
    staking: 4522.667707319775,
    fdv: 2152.35,
    mcap: 0,
  },
  {
    id: '932',
    name: 'Xensa',
    address: 'okex:0x01d346f740e27b0f3f5513bc4d2e132e3a43cc6e',
    symbol: 'XENSA',
    url: 'https://www.xensa.fi',
    description:
      'Xensa is a decentralised non-custodial liquidity market protocol where users can participate as depositors or borrowers. Depositors provide liquidity to the market to earn a passive income, while borrowers are able to borrow in an overcollateralised (perpetually) or undercollateralised (one-block liquidity) fashion.',
    chain: 'OKExChain',
    logo: 'https://icons.llama.fi/xensa.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'xensa',
    cmcId: null,
    category: 'Lending',
    chains: ['OKExChain'],
    module: 'xensa/index.js',
    twitter: 'XensaFi',
    oracles: ['ExOracle'],
    forkedFrom: ['Aave'],
    audit_links: ['https://github.com/XensaFi/xensa-audits'],
    listedAt: 1638134542,
    slug: 'xensa',
    tvl: 48.859495722563814,
    chainTvls: { OKExChain: 48.859495722563814 },
    change_1h: 0,
    change_1d: 0.8510142469393003,
    change_7d: 9.580896090875385,
    fdv: 4412702,
    mcap: 0,
  },
  {
    id: '1148',
    name: 'Amogus DAO',
    address: 'avax:0x43f673dEDE1A30399E7b9b600AD78193dB00D895',
    symbol: 'SUS',
    url: 'https://amogus.money/',
    description: 'Enhanced OHM Fork protocol on AVAX. Very sussy.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/amogus-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'amogus-dao/index.js',
    twitter: 'AmogusDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640802134,
    slug: 'amogus-dao',
    tvl: 44.532010291136515,
    chainTvls: { 'Avalanche-staking': 439.89119376920763, Avalanche: 44.532010291136515, staking: 439.89119376920763 },
    change_1h: -0.050790329844318194,
    change_1d: 0.1210746819387225,
    change_7d: -0.7768699259116687,
    staking: 439.89119376920763,
  },
  {
    id: '1051',
    name: 'Vortex DAO',
    address: 'avax:0x353af195c0d82820c59427BFbD318A49971AAe1a',
    symbol: 'SPACE',
    url: 'https://www.vortexdao.com/',
    description:
      'By holding, bonding or staking $SPACE Tokens, you become a member of the DAO and your tokens are backed by a portion of the Vortex DAO Treasury.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/vortex-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'vortex-dao',
    cmcId: '16142',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'vortexdao/index.js',
    twitter: 'VortexDao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639899342,
    slug: 'vortex-dao',
    tvl: 42.64475448651544,
    chainTvls: { 'Avalanche-staking': 3976.6424785761874, Avalanche: 42.64475448651544, staking: 3976.6424785761874 },
    change_1h: 0.044889387828050076,
    change_1d: 0.529824695080535,
    change_7d: -2.386202358455378,
    staking: 3976.6424785761874,
    fdv: 4683.49,
    mcap: 0,
  },
  {
    id: '1305',
    name: 'weBribe DAO',
    address: 'fantom:0xC92767054275c760490DC2ceA4d0511D670fA61C',
    symbol: 'vBRB',
    url: 'https://webribe.me',
    description:
      'WeBribe Dao is founded with the single objective to acquire governance power over ve(3,3) AMM. Participants can farm $VBRB (https://twitter.com/search?q=%24VBRB&src=cashtag_click) tokens by depositing various assets',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/webribe.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Fantom'],
    module: 'vbrb/index.js',
    twitter: 'weBribe_DAO',
    listedAt: 1642780698,
    slug: 'webribe-dao',
    tvl: 38.28279303491982,
    chainTvls: {
      pool2: 5.045360344117681,
      'Fantom-staking': 0,
      masterchef: 38.28279303491982,
      'Fantom-masterchef': 38.28279303491982,
      'Fantom-pool2': 5.045360344117681,
      Fantom: 38.28279303491982,
      staking: 0,
    },
    change_1h: 0.8409905386502601,
    change_1d: -7.137616788560948,
    change_7d: -13.312973102202307,
    staking: 0,
    pool2: 5.045360344117681,
  },
  {
    id: '1145',
    name: 'FTM Frens',
    address: 'fantom:0x4cC23f962d872938d478803c4499079517dB2666',
    symbol: 'FRENS',
    url: 'https://ftmfrens.com/',
    description: 'The perfect fren to FTM community. Meme coin with use cases.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/ftm-frens.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'ftm-frens/index.js',
    twitter: 'FTMFrens',
    audit_links: ['https://rugdoc.io/project/ftm-frens/'],
    listedAt: 1640800879,
    slug: 'ftm-frens',
    tvl: 32.6201675116191,
    chainTvls: {
      pool2: 1.2555859811056678,
      'Fantom-staking': 1.4095289831691937,
      masterchef: 32.6201675116191,
      'Fantom-masterchef': 32.6201675116191,
      'Fantom-pool2': 1.2555859811056678,
      Fantom: 32.6201675116191,
      staking: 1.4095289831691937,
    },
    change_1h: 0,
    change_1d: -1.3285870181789505e-9,
    change_7d: 12.213740450923765,
    staking: 1.4095289831691937,
    pool2: 1.2555859811056678,
  },
  {
    id: '1022',
    name: 'Hermes Defi',
    address: 'polygon:0xdab35042e63e93cc8556c9bae482e5415b5ac4b1',
    symbol: 'IRIS',
    url: 'https://www.hermesdefi.io',
    description: 'HermesDefi is a Yield Farming proyect on Polygon',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/hermes-defi.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'iris-token-2',
    cmcId: '11626',
    category: 'Yield',
    chains: ['Polygon'],
    module: 'hermes/index.js',
    twitter: 'hermesdefi',
    audit_links: ['https://hermes-defi.gitbook.io/hermes-finance/security/audits'],
    listedAt: 1639426591,
    slug: 'hermes-defi',
    tvl: 31.218739720896,
    chainTvls: { Polygon: 31.218739720896, pool2: 5768.044009581552, 'Polygon-pool2': 5768.044009581552 },
    change_1h: -0.03018882324606409,
    change_1d: 9.302549611522707,
    change_7d: 28.784440374095055,
    pool2: 5768.044009581552,
    mcap: 0,
  },
  {
    id: '388',
    name: 'NowSwap',
    address: '-',
    symbol: '-',
    url: 'https://nowswap.org',
    description: 'Nowswap is a decentralized exchange featuring low transaction fees for small-size trades.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/nowswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '1410',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'nowswap/index.js',
    forkedFrom: ['Uniswap'],
    twitter: 'nowswap_org',
    slug: 'nowswap',
    tvl: 24.150164,
    chainTvls: { Ethereum: 24.150164 },
    change_1h: 0,
    change_1d: 0,
    change_7d: 0,
  },
  {
    id: '1293',
    name: 'Manhattan',
    address: 'fantom:0x6c06b95a8465d4b275e5f177c262cd6c026e57a1',
    symbol: 'MHF',
    url: 'https://manhattan.finance',
    description: 'Enrich your tokens, earn yield.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/manhattan.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'manhattan/index.js',
    twitter: null,
    listedAt: 1642720211,
    slug: 'manhattan',
    tvl: 22.000025401132316,
    chainTvls: { Fantom: 22.000025401132316 },
    change_1h: 0.28451597575059395,
    change_1d: 0.4037603893873438,
    change_7d: 17.448604183553115,
  },
  {
    id: '1561',
    name: 'Pear DAO',
    address: 'bsc:0x6a0b66710567b6beb81A71F7e9466450a91a384b',
    symbol: 'PEX',
    url: 'https://peardao.io',
    description:
      'Pear is a non-custodial marketplace governed by the community-driven DAO that facilitates the trading of all things tradable against on-chain assets.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pear-dao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'peardao',
    cmcId: '16104',
    category: 'Dexes',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    forkedFrom: [],
    module: 'peardao/index.js',
    twitter: 'OfficialPearDAO',
    audit_links: ['https://www.certik.com/projects/peardao'],
    listedAt: 1647629790,
    slug: 'pear-dao',
    tvl: 21.04776814488184,
    chainTvls: {
      Binance: 21.04776814488184,
      'Binance-staking': 1.79816496,
      pool2: 1804874.3862935216,
      'Binance-pool2': 1804874.3862935216,
      staking: 1.79816496,
    },
    change_1h: -0.33263473053891346,
    change_1d: 12.03502781126933,
    change_7d: 298.0914988118681,
    staking: 1.79816496,
    pool2: 1804874.3862935216,
    fdv: 560176113,
    mcap: 0,
  },
  {
    id: '1106',
    name: 'Chad Finance',
    address: 'fantom:0xcce93540b80abf71b66e0a44fd71e322ce9c4d9e',
    symbol: 'CHAD',
    url: 'https://www.chadfinance.io/',
    description: 'Chad Finance is a yield farm on Fantom Opera',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/chad-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'chad-finance/index.js',
    twitter: 'ChadFtm',
    listedAt: 1640607853,
    slug: 'chad-finance',
    tvl: 19.91088403820902,
    chainTvls: {
      pool2: 1266.8602508489096,
      'Fantom-staking': 1043.1649513161155,
      masterchef: 19.91088403820902,
      'Fantom-masterchef': 19.91088403820902,
      'Fantom-pool2': 1266.8602508489096,
      Fantom: 19.91088403820902,
      staking: 1043.1649513161155,
    },
    change_1h: 0.998702993009104,
    change_1d: -0.1619940516092555,
    change_7d: 7.494501634668822,
    staking: 1043.1649513161155,
    pool2: 1266.8602508489096,
  },
  {
    id: '1119',
    name: 'Coup Farm',
    address: 'avax:0xb2f9a4380ebca7e057db0c4572b7ac90c353ce7d',
    symbol: 'COUP',
    url: 'https://coup.farm/',
    description:
      'The Coup Farm is a decentralized yield farming project running on Avalanche. Built upon proven features and logic to generate solid yields and passive income.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/coup-farm.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'coup-farm/index.js',
    twitter: 'CoupFarm',
    audit_links: ['https://rugdoc.io/project/coup-farm/'],
    listedAt: 1640715736,
    slug: 'coup-farm',
    tvl: 15.343940515276682,
    chainTvls: {
      'Avalanche-masterchef': 15.343940515276682,
      pool2: 7.182419804615999,
      masterchef: 15.343940515276682,
      Avalanche: 15.343940515276682,
      'Avalanche-staking': 4.863169033464,
      'Avalanche-pool2': 7.182419804615999,
      staking: 4.863169033464,
    },
    change_1h: -0.1422440120940962,
    change_1d: 0.015767321635777876,
    change_7d: -0.10752617453326252,
    staking: 4.863169033464,
    pool2: 7.182419804615999,
  },
  {
    id: '821',
    name: 'Undead Finance',
    address: 'fantom:0x89dD4d82F4aF70df521A76A4f0997b5Dc571917E',
    symbol: 'UNDEAD',
    url: 'https://www.undeadfinance.com',
    description: 'Yield Farming and Staking Platform Running on Fantom Network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/undead-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'undeadfinance/index.js',
    twitter: 'FinanceUndead',
    listedAt: 1636485547,
    slug: 'undead-finance',
    tvl: 10.414507695928922,
    chainTvls: {
      pool2: 19.533609418807316,
      'Fantom-staking': 0,
      'Fantom-pool2': 19.533609418807316,
      staking: 0,
      Fantom: 10.414507695928922,
    },
    change_1h: 0.7385459793581362,
    change_1d: -0.20311333993345215,
    change_7d: 25.748819312638176,
    staking: 0,
    pool2: 19.533609418807316,
  },
  {
    id: '1284',
    name: 'Paladin DAO',
    address: 'bsc:0xb4da413d7643000a84c5b62bfb1bf2077604b165',
    symbol: 'PAL',
    url: 'https://www.paladindao.com/',
    description:
      'Paladin DAO is the decentralized reserve currency protocol available on the BSC (Binance Smart Chain) based on the PD token.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/paladin-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'paladin-dao/index.js',
    twitter: 'DaoPaladin',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1642564789,
    slug: 'paladin-dao',
    tvl: 5.7564818678730925,
    chainTvls: { Binance: 5.7564818678730925, 'Binance-staking': 49651.60539654895, staking: 49651.60539654895 },
    change_1h: -0.33263473053892767,
    change_1d: 0.04269578074440972,
    change_7d: -0.10552215651546248,
    staking: 49651.60539654895,
  },
  {
    id: '470',
    name: 'Mensa Protocol',
    address: 'fantom:0x540d9ef5cd2d5F03694F85Aece84132C8F2220A4',
    symbol: 'MSA',
    url: 'https://www.mensapro.com',
    description:
      'Mensa Protocol is a decentralized lending platform based on Fantom chain, which aims to provide platform users with a decentralized and secure market to obtain loans and earn interest.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/mensa-protocol.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'mensa-protocol',
    cmcId: '11575',
    category: 'Lending',
    chains: ['Fantom'],
    module: 'mensa/index.js',
    twitter: 'MensaPro',
    audit_links: 'https://docs.mensapro.com/risk-control/audit-reports',
    slug: 'mensa-protocol',
    tvl: 1.706460412151064,
    chainTvls: { Fantom: 1.706460412151064 },
    change_1h: 0.046007763168987026,
    change_1d: 0.7350564527795882,
    change_7d: 2.652339089768276,
    fdv: 2096715,
    mcap: 0,
  },
  {
    id: '1228',
    name: 'SashimiDAO',
    address: 'avax:0xb88e3edb378ed7ddef10b86962d97fa0b8defb6d',
    symbol: 'SASHI',
    url: 'https://sashimidao.xyz',
    description:
      'SashimiDAO is a decentralized reserve currency protocol available on the Avalanche Network based on the SASHI token',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/sashimidao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'sashimidao/index.js',
    twitter: 'sashimi_dao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641873999,
    slug: 'sashimidao',
    tvl: 1.4329571786067896,
    chainTvls: { Avalanche: 1.4329571786067896, 'Avalanche-staking': 0, staking: 0 },
    change_1h: -0.05079032984430398,
    change_1d: 0.12107468193873672,
    change_7d: -0.7768699259116545,
    staking: 0,
  },
  {
    id: '859',
    name: 'Snowdog',
    address: 'avax:0xde9e52f1838951e4d2bb6c59723b003c353979b6',
    symbol: 'SDOG',
    url: 'https://www.snowdogdao.com',
    description:
      'SDOG is a decentralized reserve currency that will last 8 days. Are you ready? Available on #Avalanche. A SnowbankDAO company.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/snowdog-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'snowdog',
    cmcId: '14748',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'snowdogdao/index.js',
    twitter: 'snowdogdao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637298113,
    slug: 'snowdog',
    tvl: 0.2890112250903358,
    chainTvls: { 'Avalanche-staking': 7351.75037797316, Avalanche: 0.2890112250903358, staking: 7351.75037797316 },
    change_1h: -0.05079031719232319,
    change_1d: 0.12107474137954455,
    change_7d: -0.7768698194999644,
    staking: 7351.75037797316,
    fdv: 81333,
    mcap: 0,
  },
  {
    id: '1100',
    name: 'ValhallaDAO',
    address: 'avax:0x84506992349429dac867b2168843ffca263af6e8',
    symbol: 'VALDAO',
    url: 'https://valhalladao.app/',
    description: 'Building a VC DAO using staking and bonding. A decentralized cross chain VC.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/valhalladao.png',
    audit: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'valhalladao/index.js',
    twitter: 'valhalla_dao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640530943,
    slug: 'valhalladao',
    tvl: 0.00001730903486609229,
    chainTvls: {
      'Avalanche-staking': 380.5216416187053,
      Avalanche: 0.00001730903486609229,
      staking: 380.5216416187053,
    },
    change_1h: -0.05079032984430398,
    change_1d: 0.12107468193873672,
    change_7d: -0.7768699259116687,
    staking: 380.5216416187053,
  },
  {
    id: '1010',
    name: 'Ice DAO',
    address: 'avax:0x9235b893a3e61a14b2d02a91ec1394fbf411689e',
    symbol: 'ICE',
    url: 'https://app.icedao.finance/#/dashboard',
    description:
      'Ice DAO is an OHM fork on Avalanche blockchain. Ice DAO is aiming to create a community-driven OHM fork. Ice DAO provides high APY and bonds. GLA is the staking receipt and governance token for Ice DAO. Holders of GLA can vote for the future of Ice DAO and chose how will be used treasury funds and DAO funds.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/ice-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'ice-dao',
    cmcId: '15828',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'ice-dao/index.js',
    twitter: 'ICE_DAO_',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639328999,
    slug: 'ice-dao',
    tvl: 2.7558e-7,
    chainTvls: { 'Avalanche-staking': 136.2755072827611, Avalanche: 2.7558e-7, staking: 136.2755072827611 },
    change_1h: 0,
    change_1d: -8.760429082240776,
    change_7d: 0.47396820767097836,
    staking: 136.2755072827611,
    mcap: 0,
  },
  {
    id: '476',
    name: 'Asgard Finance',
    address: '0x0dc5189ec8cde5732a01f0f592e927b304370551',
    symbol: 'ASG',
    url: 'https://www.asgarddao.finance',
    description: 'Asgard is a decentralized reserve currency protocol based on the ASG token.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/asgard-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'asgard',
    cmcId: '4236',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'asgardfinance/index.js',
    twitter: 'AsgardDAO',
    slug: 'asgard-finance',
    tvl: 2.1912835244299997e-10,
    chainTvls: { Ethereum: 2.1912835244299997e-10, 'Ethereum-staking': 0, staking: 0 },
    change_1h: -0.29880478087650886,
    change_1d: 0.1360489776319298,
    change_7d: -0.1994017946161506,
    staking: 0,
    fdv: 15352061,
    mcap: 0,
  },
  {
    id: '1310',
    name: 'ZeroShift',
    address: 'avax:0xf436Ea4C4f2e49F0679895aEAE39dab698350eAa',
    symbol: 'ZRST',
    url: 'https://www.zeroshift.io/',
    description: 'ZeroShift is a wealth management DAO that simplifies DeFi investing on the Avalanche network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/zeroshift.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'zeroshift/index.js',
    twitter: 'ZeroShiftDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1642970944,
    slug: 'zeroshift',
    tvl: 1.623328740648e-11,
    chainTvls: { 'Avalanche-staking': 8062.007372002199, Avalanche: 1.623328740648e-11, staking: 8062.007372002199 },
    change_1h: -0.2275449101796454,
    change_1d: 0.17676130009741087,
    change_7d: -0.3270189431704864,
    staking: 8062.007372002199,
  },
  {
    id: '933',
    name: 'Nidhi-Dao',
    address: 'Polygon:0x057E0bd9B797f9Eeeb8307B35DbC8c12E534c41E',
    symbol: 'GURU',
    url: 'https://www.nidhidao.finance',
    description:
      'Nidhi is building a decentralized community-owned currency, with the vision of creating wealth and abundance for all.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/nidhidao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'nidhi-dao',
    cmcId: '15074',
    category: 'Reserve Currency',
    chains: ['Polygon'],
    module: 'nidhi-dao/index.js',
    twitter: 'NidhiDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638135181,
    slug: 'nidhi-dao',
    tvl: 5.275472454000001e-13,
    chainTvls: { Polygon: 5.275472454000001e-13, 'Polygon-staking': 564241.7194183175, staking: 564241.7194183175 },
    change_1h: 0,
    change_1d: 0.17676130009741087,
    change_7d: -0.327018943170458,
    staking: 564241.7194183175,
    mcap: 779481,
  },
  {
    id: '137',
    name: 'Bepswap',
    address: '0x3155ba85d5f96b2d030a4966af206230e46849cb',
    symbol: 'RUNE',
    url: 'https://thorchain.org/',
    description: "THORChain's bepswap",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bepswap.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['Binance'],
    module: 'bepswap.js',
    twitter: 'THORChain',
    audit_links: ['https://www.certik.org/projects/thorchain'],
    slug: 'bepswap',
    tvl: 0,
    chainTvls: { Binance: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '171',
    name: 'SynLev',
    address: '0x1695936d6a953df699c38ca21c2140d497c08bd9',
    symbol: 'SYN',
    url: 'https://synlev.com/',
    description: 'DeFi synthetic leveraged asset tokens.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/synlev.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'synlev',
    cmcId: '7520',
    category: 'Synthetics',
    chains: ['Ethereum'],
    module: 'synlev.js',
    twitter: null,
    slug: 'synlev',
    tvl: 0,
    chainTvls: { Ethereum: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    fdv: 728321,
    mcap: 190961,
  },
  {
    id: '376',
    name: 'Tomb Finance',
    address: 'fantom:0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
    symbol: 'TOMB',
    url: 'https://tomb.finance/',
    description: 'TOMB is a crosschain algorithmic stablecoin, pegged to the price of 1 FTM via seigniorage.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/tomb-finance.jpg',
    audits: '3',
    audit_note: null,
    gecko_id: 'tomb',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: 'tomb/index.js',
    twitter: 'tombfinance',
    slug: 'tomb-finance',
    tvl: 0,
    chainTvls: {
      pool2: 333140584.089952,
      'Fantom-staking': 256747408.81645188,
      'Fantom-Treasury': 59719946.30263407,
      'Fantom-pool2': 333140584.089952,
      Treasury: 59719946.30263407,
      Fantom: 0,
      staking: 256747408.81645188,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 256747408.81645188,
    pool2: 333140584.089952,
    mcap: 380766401,
  },
  {
    id: '414',
    name: 'TreeDefi',
    address: 'bsc:0x40b34cc972908060d6d527276e17c105d224559d',
    symbol: 'SEED',
    url: 'https://treedefi.com',
    description: 'TreeDefi is a defi protocol operating on the BSC (Binance Smart Chain) focused on Yield Farming.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/treedefi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'treedefi',
    cmcId: '9377',
    category: 'Yield',
    chains: ['Binance'],
    module: 'treedefi/index.js',
    twitter: 'treedefi',
    audit_links: ['https://www.certik.org/projects/treedefi'],
    slug: 'treedefi',
    tvl: 0,
    chainTvls: { Binance: 0 },
    change_1h: -100,
    change_1d: -100,
    change_7d: -100,
    mcap: 0,
  },
  {
    id: '432',
    name: 'Longdrink',
    address: 'bsc:0x5317fa16f8603be9c461def5d5a1bf28dfe42d55',
    symbol: 'LONG',
    url: 'https://longdrink.finance',
    description: 'Longdrink Finance is creating index tokens (aka longdrinks) on the Binance Smart Chain.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/longdrink.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'longdrink-finance',
    cmcId: null,
    category: 'Indexes',
    chains: ['Binance'],
    module: 'longdrink/index.js',
    twitter: 'LongdrinkDefi',
    audit_links: ['https://github.com/LongdrinkDefi/contracts/tree/main/audit'],
    forkedFrom: ['BasketDAO'],
    slug: 'longdrink',
    tvl: 0,
    chainTvls: { Binance: 0, pool2: 1101.3450531189862, 'Binance-pool2': 1101.3450531189862 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 1101.3450531189862,
    fdv: 3609.7,
    mcap: 2542.72,
  },
  {
    id: '436',
    name: 'WitSwap',
    address: '0x56ee175fe37cd461486ce3c3166e0cafccd9843f',
    symbol: 'eWIT',
    url: 'https://witswap.com/',
    description:
      'The Witnet network is a decentralized blockchain-agnostic oracle that is true to the censorship resistant nature of blockchains. It runs on its own blockchain using the WIT coin as its native currency. A bridge has been built to handle data requests from the Ethereum blockchain. Bridges from othher networks are planned in the future.\n\nThe eWIT token is a custodial, wrapped version of the Witnet coin managed by the WitSwap plaform. The token is minted by the WitSwap team whenever someone wants to convert WIT coins to eWIT tokens and kept in custody. A user on the Ethereum network can also opt to swap his eWIT tokens back to WIT coins. The intent of this token is to allow decentralized trading on Ethereum DEXs and, in the future, to pay independent bridge operators with it if they choose to accept it.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/witswap.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'ewit',
    cmcId: null,
    category: 'Oracle',
    chains: ['Ethereum'],
    module: 'witswap/index.js',
    twitter: 'WitSwap',
    audit_links: ['https://github.com/witswap/contracts/blob/master/eWit_Security_Audit_Report_Final_Mainnet.pdf'],
    slug: 'witswap',
    tvl: 0,
    chainTvls: { Ethereum: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    fdv: 0,
    mcap: 0,
  },
  {
    id: '456',
    name: 'Telcoin',
    address: 'polygon:0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32',
    symbol: 'TEL',
    url: 'https://www.telx.network',
    description:
      "TELx is a network of decentralized financial markets powered by active Telcoin users. TELx functions as the decentralized liquidity engine of the Telcoin platform's suite of user-owned, mobile based financial products, including the Send Money Smarter Network and TELxchange.",
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/telcoin.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'telcoin',
    cmcId: '2394',
    category: 'Payments',
    chains: ['Polygon'],
    module: 'telcoin/index.js',
    twitter: 'telcoin_team',
    audit_links: ['https://www.certik.org/projects/telcoin'],
    slug: 'telcoin',
    tvl: 0,
    chainTvls: { pool2: 17738384.461211838, 'Polygon-pool2': 17738384.461211838 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 17738384.461211838,
    mcap: 543806289,
  },
  {
    id: '472',
    name: 'Avalaunch',
    address: 'avax:0xd1c3f94de7e5b45fa4edbba472491a9f4b166fc4',
    symbol: 'XAVA',
    url: 'https://avalaunch.app',
    description:
      'The first protocol, exclusively for the Avalanche ecosystem, to offer promising and innovative projects a fast, secure, and efficient platform for decentralized fundraising.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/avalaunch.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'avalaunch',
    cmcId: '9797',
    category: 'Dexes',
    chains: ['Avalanche'],
    module: 'avalaunch/index.js',
    twitter: 'AvalaunchApp',
    slug: 'avalaunch',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 70608634.47386487,
      pool2: 111304.05486014644,
      Avalanche: 0,
      'Avalanche-pool2': 111304.05486014644,
      staking: 70608634.47386487,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 70608634.47386487,
    pool2: 111304.05486014644,
    fdv: 412273408,
    mcap: 91471765,
  },
  {
    id: '565',
    name: 'Sunrise Gaming',
    address: '0x692aCCdD8b86692427E0aa4752AE917Df01CC56F',
    symbol: 'SUNC',
    url: 'https://sunrisegaming-dao.com',
    description:
      'By using the blockchain technology, our objective is to create a fraud-free and fair online casino. 100% of the proceeds from the project will be returned to SUNC token holders (some will be returned to users who take part in the casino games). The SUNC token also functions as a governance token. We are aiming for a complete DAO without a centralized administrator in the future.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/sunrise-gaming.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'sunrise',
    cmcId: null,
    category: 'Prediction Market',
    chains: ['Ethereum'],
    module: 'sunrise-gaming-by-dao/index.js',
    twitter: 'SunriseGaming_d',
    audit_links: ['https://www.certik.org/projects/sunrisegaming'],
    slug: 'sunrise-gaming',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 3832748.25494997,
      'Ethereum-staking': 2455419.320436847,
      staking: 2455419.320436847,
      'Ethereum-pool2': 3832748.25494997,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2455419.320436847,
    pool2: 3832748.25494997,
    mcap: 0,
  },
  {
    id: '598',
    name: 'ShapeShift',
    address: '0xc770eefad204b5180df6a14ee197d99d808ee52d',
    symbol: 'FOX',
    url: 'https://shapeshift.com',
    description:
      'ShapeShift is the only cryptocurrency trading platform offering zero-commission crypto trading and self-custody.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/shapeshift.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'shapeshift-fox-token',
    cmcId: '8200',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'shapeshift/index.js',
    twitter: 'ShapeShift_io',
    slug: 'shapeshift',
    tvl: 0,
    chainTvls: { pool2: 3055723.201590142, 'Ethereum-pool2': 3055723.201590142 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 3055723.201590142,
    mcap: 90237817,
  },
  {
    id: '609',
    name: 'Banano',
    address: 'polygon:0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034',
    symbol: 'BAN',
    url: 'https://banano.cc',
    description:
      'Banano is a feeless, instant, rich in potassium cryptocurrency powered by DAG technology disrupting the meme economy.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/banano.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'banano',
    cmcId: '4704',
    category: 'Yield',
    chains: ['Binance', 'Polygon'],
    module: 'banano/index.js',
    twitter: 'bananocoin',
    slug: 'banano',
    tvl: 0,
    chainTvls: { Binance: 0, Polygon: 0, pool2: 0, 'Polygon-pool2': 0, 'Binance-pool2': 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 0,
    mcap: 17143732,
  },
  {
    id: '610',
    name: 'eCurve',
    address: 'eos:ECRV',
    symbol: 'ECRV',
    url: 'https://ecurve.finance',
    description:
      'eCurve is an exchange liquidity pool on EOS, designed for extremely efficient stablecoin trading and low risk, supplemental fee income for liquidity providers, without an opportunity cost.',
    chain: 'EOS',
    logo: 'https://icons.llama.fi/ecurve.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['EOS'],
    module: 'ecurve/index.js',
    twitter: 'DAPPAccountDAO',
    forkedFrom: ['Curve'],
    slug: 'ecurve',
    tvl: 0,
    chainTvls: { EOS: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '617',
    name: 'Bogged Finance',
    address: 'bsc:0xb09fe1613fe03e7361319d2a43edc17422f36b09',
    symbol: 'BOG',
    url: 'https://www.bogged.finance',
    description: 'Bogged. Finance is a DeFi tool suite for the Binance Smart Chain powered by the BOG token',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bogged-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'bogged-finance',
    cmcId: '8723',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'bogged.js',
    twitter: 'boggedfinance',
    audit_links: ['https://static.bogged.finance/audit.pdf'],
    slug: 'bogged-finance',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 5567085.718948293,
      pool2: 2171757.1863040226,
      'Binance-pool2': 2171757.1863040226,
      staking: 5567085.718948293,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 5567085.718948293,
    pool2: 2171757.1863040226,
    mcap: 10341771,
  },
  {
    id: '633',
    name: 'TotemFi',
    address: 'bsc:0x6ff1bfa14a57594a5874b37ff6ac5efbd9f9599a',
    symbol: 'TOTM',
    url: 'https://totemfi.com',
    description: "Risk free high earning prediction market, you'll grow like you do in games",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/totemfi.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'totemfi',
    cmcId: '8673',
    category: 'Prediction Market',
    chains: ['Binance'],
    module: 'totemfi/index.js',
    twitter: 'TotemFi',
    oracles: ['Chainlink'],
    slug: 'totemfi',
    tvl: 0,
    chainTvls: { Binance: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    fdv: 909686,
    mcap: 556153,
  },
  {
    id: '637',
    name: 'SYNC Network',
    address: '0xB6ff96B8A8d214544Ca0dBc9B33f7AD6503eFD32',
    symbol: 'SYNC',
    url: 'https://syncbond.com/',
    description:
      'SYNC is an ERC-20 cryptocurrency and NFT financial primitive that ‘bonds,’ or locks Uniswap liquidity pairs against SYNC tokens to make a tradeable ERC-721’s (NFTs) called a ‘CryptoBond.’',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/sync-network.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'sync-network',
    cmcId: '7812',
    category: 'NFT Lending',
    chains: ['Ethereum'],
    module: 'syncnetwork/index.js',
    twitter: 'SYNCToken',
    audit_links: ['https://blog.coinfabrik.com/cbond-smart-contract-audit/'],
    slug: 'sync-network',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 1471258.5607731845,
      'Ethereum-staking': 1244344.9553729405,
      staking: 1244344.9553729405,
      'Ethereum-pool2': 1471258.5607731845,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1244344.9553729405,
    pool2: 1471258.5607731845,
    mcap: 3062557,
  },
  {
    id: '658',
    name: 'Only1',
    address: 'solana:3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR',
    symbol: 'LIKE',
    url: 'https://only1.app',
    description:
      'Buy and Sell NFTs from your favorite content creators, earn crypto by interacting with these creators, or mint your own NFTs and start earning rewards today. Powered by Solana.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/only1.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'only1',
    cmcId: '10891',
    category: 'NFT Marketplace',
    chains: ['Solana'],
    module: 'only1.js',
    twitter: 'only1nft',
    openSource: false,
    slug: 'only1',
    tvl: 0,
    chainTvls: { 'Solana-staking': 1787182.8431152252, Solana: 0, staking: 1787182.8431152252 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1787182.8431152252,
    mcap: 7053282,
  },
  {
    id: '668',
    name: 'Swap Cat',
    address: null,
    symbol: '-',
    url: 'https://swap.cat',
    description:
      'SWAP.CAT is a primitive decentralized exchange (DEX) for ERC-20 tokens on Ethereum-compatible networks.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/swap-cat.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: [
      'xDai',
      'Avalanche',
      'Heco',
      'OKExChain',
      'Songbird',
      'Harmony',
      'Kucoin',
      'Fantom',
      'Binance',
      'EthereumClassic',
      'Celo',
      'TomoChain',
      'GoChain',
      'Ethereum',
      'EnergyWeb',
      'RSK',
      'Moonriver',
      'HPB',
      'Polygon',
      'smartBCH',
      'Arbitrum',
    ],
    module: 'swapcat/index.js',
    twitter: null,
    listedAt: 1634223504,
    slug: 'swap-cat',
    tvl: 0,
    chainTvls: {
      'xDai-offers': 24530.09691132,
      'Avalanche-offers': 28.062308820000002,
      offers: 57920.06555244003,
      'Heco-offers': 21.7440405,
      'OKExChain-offers': 3.93892044,
      'Songbird-offers': 0.999726,
      'Harmony-offers': 45.59750286,
      'Kucoin-offers': 3.03916704,
      'Fantom-offers': 0.999726,
      'Binance-offers': 527.9553006,
      'EthereumClassic-offers': 0.999726,
      'Celo-offers': 30.8915334,
      'TomoChain-offers': 0.999726,
      'GoChain-offers': 0.999726,
      EthereumClassic: 0,
      'Ethereum-offers': 32650.52130522,
      'EnergyWeb-offers': 0.999726,
      'RSK-offers': 0.999726,
      'Moonriver-offers': 4.12886838,
      'HPB-offers': 0.999726,
      'Polygon-offers': 64.09243386,
      'smartBCH-offers': 0.999726,
      'Arbitrum-offers': 0.999726,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '672',
    name: 'Fegex',
    address: '0x389999216860ab8e0175387a0c90e5c52522c945',
    symbol: 'FEG',
    url: 'https://fegtoken.com',
    description: 'The All-in-One exchange',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/fegex.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'feg-token',
    cmcId: '1437',
    category: 'Services',
    chains: ['Binance', 'Ethereum'],
    module: 'fegex/index.js',
    twitter: 'FEGtoken',
    audit_links: ['https://solidity.finance/audits/FEG'],
    listedAt: 1634319488,
    slug: 'fegex',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 35379639.73965358,
      'Ethereum-staking': 22401177.094262686,
      staking: 57780816.83391626,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 57780816.83391626,
    mcap: 144939962,
  },
  {
    id: '681',
    name: 'OraiChain',
    address: '0x4c11249814f11b9346808179cf06e71ac328c1b5',
    symbol: 'ORAI',
    url: 'https://orai.io',
    description:
      'Oraichain is a data oracle platform that aggregates and connects Artificial Intelligence APIs to smart contracts, serving as a foundational base for the creation of new Dapps leveraging AI technologies.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/oraichain.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'oraichain-token',
    cmcId: '7533',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    module: 'oraichain.js',
    twitter: 'oraichain',
    audit_links: ['https://github.com/oraichain/audits'],
    listedAt: 1634551713,
    slug: 'oraichain',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 688797.4575784691,
      'Ethereum-staking': 828473.9783720241,
      staking: 1517271.4359504932,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1517271.4359504932,
    fdv: 160213808,
    mcap: 18422936,
  },
  {
    id: '682',
    name: 'BaconDAO',
    address: 'bsc:0x0615dbba33fe61a31c7ed131bda6655ed76748b1',
    symbol: 'BACON',
    url: 'https://app.bacondao.com/',
    description:
      'BaconDAO (BACON) is a Decentralized Autonomous Organization (DAO) for collaborative learning and investing in Cryptocurrencies. The mission of the organization is to create the largest and most knowledgeable Crypto community, harnessing its group knowledge to help members learn and profit from Cryptocurrencies.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bacondao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'bacondao',
    cmcId: '10501',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    module: 'bacondao/index.js',
    twitter: 'BaconDAO_',
    audit_links: ['https://drive.google.com/file/d/1AVc_YuSKoaojLUS7oUz65LZGxJzpItRY/view'],
    listedAt: 1634577126,
    slug: 'bacondao',
    tvl: 0,
    chainTvls: {
      pool2: 37667.04803259886,
      'Binance-pool2': 28182.667996367833,
      'Ethereum-pool2': 9484.380036231028,
      Binance: 0,
      Ethereum: 0,
      'Binance-staking': 10500.22910842525,
      'Ethereum-staking': 176.83522683286006,
      staking: 10677.06433525811,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 10677.06433525811,
    pool2: 37667.04803259886,
    fdv: 1221430,
    mcap: 0,
  },
  {
    id: '686',
    name: 'YEL Finance',
    address: '0x7815bda662050d84718b988735218cffd32f75ea',
    symbol: 'YEL',
    url: 'https://yel.finance',
    description:
      'YEL aggregates multiple projects across several networks and presents them in a simple format for liquidity provision and staking opportunities.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/yel-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'yel-finance',
    cmcId: '11301',
    category: 'Yield',
    chains: ['Fantom', 'Polygon', 'Binance', 'Ethereum'],
    module: 'yel/index.js',
    twitter: 'yel_finance',
    audit_links: ['https://yield-enhancement-labs.gitbook.io/yield-enhancement-labs/other/audits'],
    listedAt: 1634674060,
    slug: 'yel-finance',
    tvl: 0,
    chainTvls: {
      pool2: 2421800.194703512,
      'Fantom-staking': 513419.4871686132,
      'Polygon-pool2': 634879.0196281245,
      'Binance-pool2': 570058.3256923871,
      'Ethereum-pool2': 570058.3256923871,
      'Binance-staking': 735832.1966153173,
      'Ethereum-staking': 259892.43957803896,
      'Polygon-staking': 261978.6894383374,
      'Fantom-pool2': 646804.523690613,
      staking: 1771122.8128003066,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1771122.8128003066,
    pool2: 2421800.194703512,
    fdv: 6364492,
    mcap: 4197240,
  },
  {
    id: '715',
    name: 'SmartCoin',
    address: 'avax:0xCC2f1d827b18321254223dF4e84dE399D9Ff116c',
    symbol: 'SMRT',
    url: 'https://smartcoin.farm',
    description:
      'SmartCoin is a yield farming protocol on Avalanche with an innovative self-feeding tokenomics system and a 100% fair launch.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/smrt.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'smartcoin-2',
    cmcId: '12917',
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'smartcoin/index.js',
    twitter: '0xSmartCoin',
    audit_links: [
      'https://paladinsec.co/pld/assets/20211018_Paladin_SmartCoin_Final_Report.pdf',
      'https://rugdoc.io/project/smartcoin/',
    ],
    listedAt: 1635279168,
    slug: 'smartcoin',
    tvl: 0,
    chainTvls: { Avalanche: 0, pool2: 22110.129996277217, 'Avalanche-pool2': 22110.129996277217 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 22110.129996277217,
    fdv: 1055132,
    mcap: 0,
  },
  {
    id: '716',
    name: 'Billion Happiness',
    address: 'bsc:0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
    symbol: 'BHC',
    url: 'https://billionhappiness.finance/',
    description:
      'Billion Happiness is a blockchain community-based project for Defi, Yield Farming, Staking and NFT Marketplace.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/billion-happiness.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'billionhappiness',
    cmcId: '7182',
    category: 'Yield',
    chains: ['Binance'],
    module: 'billion-happiness/index.js',
    twitter: 'BHC_Happiness',
    audit_links: ['https://www.certik.org/projects/billionhappiness'],
    listedAt: 1635296377,
    slug: 'billion-happiness',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 745255.8976306327,
      pool2: 136237.97489060706,
      'Binance-pool2': 136237.97489060706,
      staking: 745255.8976306327,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 745255.8976306327,
    pool2: 136237.97489060706,
    mcap: 3070612,
  },
  {
    id: '717',
    name: 'Decubate',
    address: 'bsc:0xEAc9873291dDAcA754EA5642114151f3035c67A2',
    symbol: 'DCB',
    url: 'https://platform.decubate.com/',
    description:
      "Decubate is a community-powered investment platform that's bringing the $3 trillion global startup economy onto blockchain",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/decubate.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'decubate',
    cmcId: '10563',
    category: 'Launchpad',
    chains: ['Binance'],
    module: 'decubate/index.js',
    twitter: 'decubate',
    listedAt: 1635383289,
    slug: 'decubate',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 83859.08244200597, staking: 83859.08244200597 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 83859.08244200597,
    fdv: 15940798,
    mcap: 1637397,
  },
  {
    id: '720',
    name: 'Nasdex',
    address: 'polygon:0xE8d17b127BA8b9899a160D9a07b69bCa8E08bfc6',
    symbol: 'NSDX',
    url: 'https://www.nasdex.xyz',
    description:
      'NASDEX is a decentralised app on polygon, that acts as a decentralised exchange for the trading of equities. On Nasdex, users can mint, trade, farm and short equities.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/nasdex.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'nasdex-token',
    cmcId: '12312',
    category: 'Dexes',
    chains: ['Polygon'],
    module: 'nasdex/index.js',
    twitter: 'nasdex_xyz',
    listedAt: 1635463716,
    slug: 'nasdex',
    tvl: 0,
    chainTvls: {
      Polygon: 0,
      pool2: 37500.313636825755,
      'Polygon-pool2': 37500.313636825755,
      'Polygon-staking': 233306.402536851,
      staking: 233306.402536851,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 233306.402536851,
    pool2: 37500.313636825755,
    mcap: 0,
  },
  {
    id: '750',
    name: 'Fractional Art',
    address: null,
    symbol: '-',
    url: 'https://fractional.art',
    description:
      'Fractional ownership of the world’s most sought after NFTs. Fractional reduces entry costs, increases access, and enables new communities.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/fractional-art.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'fractional-art.js',
    twitter: 'fractional_art',
    listedAt: 1635876350,
    slug: 'fractional-art',
    tvl: 0,
    chainTvls: { Ethereum: 0 },
    change_1h: -100,
    change_1d: -100,
    change_7d: -100,
  },
  {
    id: '751',
    name: 'Umami Finance',
    address: 'arbitrum:0x1622bF67e6e5747b81866fE0b85178a93C7F86e3',
    symbol: 'UMAMI',
    url: 'https://umami.finance/',
    description: 'The premier market-maker and liquidity network natively built on Arbitrum.',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/umamifinance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'umami-finance',
    cmcId: '15480',
    category: 'Reserve Currency',
    chains: ['Arbitrum'],
    module: 'umamifinance/index.js',
    twitter: 'UmamiFinance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1635877329,
    slug: 'umami-finance',
    tvl: 0,
    chainTvls: { 'Arbitrum-staking': 2218129.1060423153, Arbitrum: 0, staking: 2218129.1060423153 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2218129.1060423153,
    mcap: 0,
  },
  {
    id: '783',
    name: 'AtmosSoft',
    address: 'bsc:0xC53C65C4A925039cC149fA99fD011490D40270a3',
    symbol: 'ATMSSFT',
    url: 'https://www.atmossoftdefi.app',
    description: 'Atmosphere Software(AtmosSoft) is a next generation cross chain NFT based Collectible Card Game',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/atmossoft.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Gaming',
    chains: ['Fantom', 'Binance'],
    module: 'atmossoft.js',
    twitter: 'AtmosSoft',
    listedAt: 1636043958,
    slug: 'atmossoft',
    tvl: 0,
    chainTvls: { pool2: 6.307388040629739, 'Fantom-pool2': 0, 'Binance-pool2': 6.307388040629739, Fantom: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 6.307388040629739,
  },
  {
    id: '800',
    name: 'ZigZag',
    address: null,
    symbol: '-',
    url: 'https://trade.zigzag.exchange',
    description: 'A Layer 2 decentralized exchange (DEX) on zkSync ',
    chain: 'zkSync',
    logo: 'https://icons.llama.fi/zigzag.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Dexes',
    chains: ['zkSync'],
    module: 'zigzag/index.js',
    twitter: 'ZigZagExchange',
    listedAt: 1636305888,
    slug: 'zigzag',
    tvl: 0,
    chainTvls: { zkSync: 0, offers: 314672.09037600004, 'zkSync-offers': 314672.09037600004 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '802',
    name: 'SquidDao',
    address: '0x21ad647b8f4fe333212e735bfc1f36b4941e6ad2',
    symbol: 'SQUID',
    url: 'https://squid.xyz',
    description:
      'SquidDAO is inspired by OlympusDAO and is an ETH-based DAO with the goal of stacking as much ETH as possible, via bonding and treasury management',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/squiddao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'squid',
    cmcId: '13620',
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    module: 'squid-dao/index.js',
    twitter: 'SquidDao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1636315439,
    slug: 'squiddao',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 452824.0435113263, staking: 452824.0435113263 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 452824.0435113263,
    mcap: 525571,
  },
  {
    id: '805',
    name: 'Snowbank',
    address: 'avax:0x7d1232b90d3f809a54eeaeebc639c62df8a8942f',
    symbol: 'SB',
    url: 'https://www.snowbank.finance',
    description:
      '$SB is a decentralized reserve currency that remains unbound by a peg. Solving liquidity ownership. Vanilla OHM with a twist. Available on #Avalanche',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/snowbank.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'snowbank',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'snowbank.js',
    twitter: 'SnowbankDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1636385808,
    slug: 'snowbank',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 3883819.3460799116, Avalanche: 0, staking: 3883819.3460799116 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3883819.3460799116,
    mcap: 34667760,
  },
  {
    id: '849',
    name: 'Capital DAO',
    address: '0x3c48ca59bf2699e51d4974d4b6d284ae52076e5e',
    symbol: 'CDS',
    url: 'https://capital-dao.io',
    description:
      'Capital DAO Protocol is the First Decentralized Venture Capital Using DAO for MultiChain. It disrupts Venture 2.0 and moves to Venture 3.0',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/capital-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'capital-dao-starter-token',
    cmcId: null,
    category: 'Services',
    chains: ['Ethereum'],
    module: 'capital-dao/index.js',
    twitter: 'Capital_DAO_P',
    listedAt: 1637117495,
    slug: 'capital-dao',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 3623568.8721740404,
      'Ethereum-staking': 2217143.521047525,
      staking: 2217143.521047525,
      'Ethereum-pool2': 3623568.8721740404,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2217143.521047525,
    pool2: 3623568.8721740404,
    fdv: 148734271,
    mcap: 0,
  },
  {
    id: '851',
    name: 'Nest Protocol',
    address: '0x04abeda201850ac0124161f037efd70c74ddc74c',
    symbol: 'NEST',
    url: 'https://nestprotocol.org',
    description:
      'The NEST oracle solves the problem of price on-chain through a decentralized incentive solution, that is, the price predictor.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/nest-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'nest',
    cmcId: '5841',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'nest.js',
    twitter: 'nest_protocol',
    oracles: ['Nest'],
    listedAt: 1637122449,
    slug: 'nest-protocol',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 353530.60480245, staking: 353530.60480245 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 353530.60480245,
    fdv: 188759256,
    mcap: 38308816,
  },
  {
    id: '853',
    name: 'AxeDAO',
    address: '0x30AC8317DfB0ab4263CD8dB1C4F10749911B126C',
    symbol: 'AXE',
    url: 'https://www.axedao.finance',
    description: 'AxeDAO is a fork of OlympusDAO.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/axedao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'axedao',
    cmcId: '14609',
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    module: 'axe/index.js',
    twitter: 'AxeDao',
    audit_links: ['https://github.com/axedao/audit/blob/main/Techrate%20-%20AxeDAO.pdf'],
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637174034,
    slug: 'axedao',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 0, staking: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 0,
    fdv: 2203684,
    mcap: 0,
  },
  {
    id: '868',
    name: 'Saffron Finance',
    address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c',
    symbol: 'SFI',
    url: 'https://saffron.finance',
    description:
      'Saffron is a protocol for tokenizing on-chain assets, including contracts that otherwise impair access to utilized capital.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/saffron-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'saffron-finance',
    cmcId: '7617',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'saffron/index.js',
    twitter: 'saffronfinance_',
    audit_links: ['https://github.com/saffron-finance/saffron/tree/master/audits/saffron-staking-v2'],
    listedAt: 1637370827,
    slug: 'saffron-finance',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 1381613.8543542845,
      'Ethereum-staking': 1370275.4395533567,
      staking: 1370275.4395533567,
      'Ethereum-pool2': 1381613.8543542845,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1370275.4395533567,
    pool2: 1381613.8543542845,
    fdv: 16706451,
    mcap: 13372736,
  },
  {
    id: '875',
    name: 'Temple DAO',
    address: '0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7',
    symbol: 'TEMPLE',
    url: 'https://www.templedao.link',
    description:
      'The TempleDAO protocol aims to provide DeFi users with a safe haven where they can be sheltered from crypto market volatility while benefiting from a set of investment opportunities offering high yields and steady price appreciation.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/temple-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'temple',
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    module: 'templedao/index.js',
    twitter: 'templedao',
    listedAt: 1637376751,
    slug: 'temple-dao',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 80673627.64501686, staking: 80673627.64501686 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 80673627.64501686,
    fdv: 174671603,
    mcap: 64290130,
  },
  {
    id: '881',
    name: 'SafeOHM',
    address: 'bsc:0xBDaa094a95e452c6bA175cE9EdfeFBa04e6a51Ac',
    symbol: 'SOHM',
    url: 'https://sohm.finance',
    description:
      'SOHM is a hybrid of SafeMoon and OHM, a decentralized financial reserve protocol that provides compounding interest through its community-owned and protected treasury.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/safeohm.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'safe-ohm',
    cmcId: '14432',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'safeohm/index.js',
    twitter: 'Son_OHM',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1637382300,
    slug: 'safeohm',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 20052.994776378, staking: 20052.994776378 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 20052.994776378,
    mcap: 0,
  },
  {
    id: '894',
    name: 'Paraswap',
    address: '0xcAfE001067cDEF266AfB7Eb5A286dCFD277f3dE5',
    symbol: 'PSP',
    url: 'https://paraswap.io/',
    description:
      "ParaSwap aggregates decentralized exchanges and other DeFi services in one comprehensive interface to streamline and facilitate users' interactions with Ethereum's decentralized finance. By aggregating DEX, AMMs and PMMs, Paraswap offers its users the best exchange rate across major chains.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/paraswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'paraswap',
    cmcId: '14534',
    category: 'Dexes',
    chains: ['Ethereum'],
    module: 'paraswap.js',
    twitter: 'paraswap',
    audit_links: [
      'https://github.com/solidified-platform/audits/blob/master/Audit%20Report%20-%20ParaSwap%20%5B20.01.2021%5D.pdf',
    ],
    listedAt: 1637654600,
    slug: 'paraswap',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 453341.0226117205,
      'Ethereum-staking': 12996408.381149752,
      staking: 12996408.381149752,
      'Ethereum-pool2': 453341.0226117205,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 12996408.381149752,
    pool2: 453341.0226117205,
    fdv: 296099047,
    mcap: 59300558,
  },
  {
    id: '895',
    name: 'BlackPool',
    address: '0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da',
    symbol: 'BPT',
    url: 'https://blackpool.finance/',
    description:
      'BlackPool is a new fund operating within the NFT industry: managing a range of assets from sports cards to game items to digital art. BlackPool is the first DAO built solely for NFT gaming and trading.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/blackpool.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'blackpool-token',
    cmcId: '10402',
    category: 'Indexes',
    chains: ['Ethereum', 'Polygon'],
    module: 'blackpool.js',
    twitter: 'BlackpoolHQ',
    listedAt: 1637655423,
    slug: 'blackpool',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      Polygon: 0,
      'Ethereum-staking': 80742.08709762197,
      'Polygon-staking': 15371.967285859411,
      staking: 96114.05438348139,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 96114.05438348139,
    fdv: 281538528,
    mcap: 16008090,
  },
  {
    id: '901',
    name: 'EverestDAO',
    address: 'avax:0x3ACa5545e76746A3Fe13eA66B24BC0eBcC51E6b4',
    symbol: 'EVRT',
    url: 'https://everestdao.net',
    description:
      'Everest DAO is building an ecosystem of intriguing Dapps on Avalanche, bringing an authentic and unmatched experience, no matter your skill or knowledge.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/everestdao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'everest-token',
    cmcId: '12154',
    category: 'Options',
    chains: ['Avalanche'],
    module: 'everestdao/index.js',
    twitter: 'EverestDAO',
    listedAt: 1637714921,
    slug: 'everestdao',
    tvl: 0,
    chainTvls: {
      Avalanche: 0,
      'Avalanche-staking': 2922.3923353884716,
      pool2: 2308.945686095126,
      'Avalanche-pool2': 2308.945686095126,
      staking: 2922.3923353884716,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2922.3923353884716,
    pool2: 2308.945686095126,
    fdv: 6753.42,
    mcap: 0,
  },
  {
    id: '926',
    name: 'Metaversepro',
    address: 'bsc:0x0a2046C7fAa5a5F2b38C0599dEB4310AB781CC83',
    symbol: 'META',
    url: 'https://app.metaverse.pro',
    description: 'MetaversePRO is the Metaversal Reserve System and $Meta will be the Metaversal Reserve Currency',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/metaversepro.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '14075',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'metaversepro/index.js',
    twitter: 'MetaverseProDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638121684,
    slug: 'metaversepro',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 79820.62445510403, staking: 79820.62445510403 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 79820.62445510403,
  },
  {
    id: '929',
    name: 'RaDao',
    address: 'bsc:0xcc238200cFfdA7A5E2810086c26d5334e64F1155',
    symbol: 'RA',
    url: 'https://radao.finance',
    description: 'RaDAO is a decentralized protocol based on the $RA token – collateralized and backed by the Ra DAO.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/radao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'radao/index.js',
    twitter: 'radao_bsc',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638133081,
    slug: 'radao',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 359.6960524752298, staking: 359.6960524752298 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 359.6960524752298,
  },
  {
    id: '937',
    name: 'Hex',
    address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    symbol: 'HEX',
    url: 'https://hex.com',
    description: 'The first high interest blockchain certificate of deposit.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/hex.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'hex',
    cmcId: '5015',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'hex/index.js',
    twitter: 'HEXcrypto',
    audit_links: [
      'https://hex.com/docs/HEX-Security-Audit-by-CoinFabrik-DEC2019.pdf',
      'https://hex.com/docs/HEX%20Economics%20Audit%20by%20CoinFabrik.pdf',
    ],
    listedAt: 1638193942,
    slug: 'hex',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 9320288592.89961, staking: 9320288592.89961 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 9320288592.89961,
    mcap: 0,
  },
  {
    id: '946',
    name: 'FreeLiquid',
    address: 'eth:0xffed56a180f23fd32bc6a1d8d3c09c283ab594a8',
    symbol: 'FL',
    url: 'https://freeliquid.io',
    description:
      'FreeLiquid is a decentralized lending platform that offers funding in $USDFL stablecoins to liquidity providers',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/freeliquid.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'freeliquid',
    cmcId: '8437',
    category: 'Lending',
    chains: ['Ethereum'],
    module: 'freeliquid/index.js',
    twitter: 'freeliquidUSDFL',
    audit_links: ['https://freeliquid.io/wp/Smart_contract_security_audit_report_freeliquid.pdf'],
    listedAt: 1638358117,
    slug: 'freeliquid',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 9769.584354349385,
      'Ethereum-staking': 3053.263163559962,
      staking: 3053.263163559962,
      'Ethereum-pool2': 9769.584354349385,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3053.263163559962,
    pool2: 9769.584354349385,
    fdv: 253205,
    mcap: 0,
  },
  {
    id: '949',
    name: 'Lobis',
    address: 'eth:0xdec41db0c33f3f6f3cb615449c311ba22d418a8d',
    symbol: 'LOBI',
    url: 'https://www.lobis.finance',
    description:
      'Lobis is an Approved Olympus fork (oh yes) specialized to ensure that OlympusDAO can participate in DeFi governance. The OHMies heard our voice and joined our cause.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/lobis.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'lobis',
    cmcId: '15093',
    category: 'Reserve Currency',
    chains: ['Ethereum'],
    module: 'lobis.js',
    twitter: 'LobisHQ',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638366199,
    slug: 'lobis',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 5878363.997148983, staking: 5878363.997148983 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 5878363.997148983,
    fdv: 931882,
    mcap: 0,
  },
  {
    id: '950',
    name: 'ARCx',
    address: 'eth:0x1321f1f1aa541A56C31682c57b80ECfCCd9bB288',
    symbol: 'ARCx',
    url: 'https://arcx.money/passport',
    description:
      'ARCx is a decentralized scoring protocol that powers on-chain identity through the issuance of a DeFi Passport. The first element in the DeFi Passport is an on-chain credit score, which is issued to an identity based on an assessment of the address’ on-chain activity',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/arcx.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'arc-governance',
    cmcId: '10515',
    category: 'Insurance',
    chains: ['Ethereum'],
    module: 'arcx.js',
    twitter: 'arcxmoney',
    listedAt: 1638372155,
    slug: 'arcx',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 990732.8237188773, staking: 990732.8237188773 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 990732.8237188773,
    fdv: 14243828,
    mcap: 4311923,
  },
  {
    id: '959',
    name: 'Alpaca City',
    address: 'ethereum:0x7cA4408137eb639570F8E647d9bD7B7E8717514A',
    symbol: 'ALPA',
    url: 'https://alpaca.city',
    description: 'A miracle city where DeFi meets Alpaca NFT.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/alpacacity.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'alpaca',
    cmcId: '7618',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    module: 'alpacacity/index.js',
    twitter: 'CityAlpaca',
    listedAt: 1638460518,
    slug: 'alpaca-city',
    tvl: 0,
    chainTvls: {
      pool2: 142536.06023474276,
      'Binance-pool2': 66933.48533107825,
      'Ethereum-pool2': 75602.57490366451,
      'Ethereum-Treasury': 151191.55007143837,
      'Binance-staking': 21872.977749494185,
      'Ethereum-staking': 1003822.1616040517,
      'Binance-Treasury': 78231.63736412136,
      Treasury: 229423.18743555972,
      staking: 1025695.1393535458,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1025695.1393535458,
    pool2: 142536.06023474276,
    mcap: 1410535,
  },
  {
    id: '962',
    name: 'Retreeb',
    address: 'Fantom:0xc60d7067dfbc6f2caf30523a064f416a5af52963',
    symbol: 'TREEB',
    url: 'https://retreeb.io',
    description:
      'Retreeb is the first distributed payment solution that shares one third of its network fees to funding social and solidarity project',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/retreeb.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'treeb',
    cmcId: '12301',
    category: 'Payments',
    chains: ['Fantom'],
    module: 'retreeb.js',
    twitter: 'retreeb_io',
    listedAt: 1638464179,
    slug: 'retreeb',
    tvl: 0,
    chainTvls: { 'Fantom-staking': 19605641.21878769, staking: 19605641.21878769, Fantom: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 19605641.21878769,
    mcap: 65682412,
  },
  {
    id: '968',
    name: 'Zai Finance',
    address: '0x9d1233cc46795e94029fda81aaadc1455d510f15',
    symbol: 'ZAI',
    url: 'https://zaiprotocol.finance/',
    description:
      'Zero Collateral Dai (ZAI) is a hybrid synthetic-algorithmic stablecoin, using a self-stabilizing elastic supply mechanism.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/zai-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'zero-collateral-dai',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Ethereum'],
    module: 'zai.js',
    twitter: 'zaifinance',
    listedAt: 1638563610,
    slug: 'zai-finance',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 7106.668114677691,
      'Ethereum-staking': 0,
      staking: 0,
      'Ethereum-pool2': 7106.668114677691,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 0,
    pool2: 7106.668114677691,
    fdv: 97421,
    mcap: 73529894,
  },
  {
    id: '982',
    name: 'VikingsFinance',
    address: 'avax:0xE0474c15BC7f8213eE5bfB42f9E68B2d6BE2e136',
    symbol: 'VAL',
    url: 'https://vikings.finance',
    description: 'An experienced DeFi team building a truly optimized treasury on Avalanche',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/vikingsfinance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'vikings-finance',
    cmcId: '15611',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'vikings/index.js',
    twitter: 'VikingsFinance',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1638721025,
    slug: 'vikingsfinance',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 48297.97213125888, Avalanche: 0, staking: 48297.97213125888 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 48297.97213125888,
    fdv: 604787130452830,
    mcap: 0,
  },
  {
    id: '987',
    name: 'HoneyFarm',
    address: 'bsc:0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    symbol: 'BEE',
    url: 'https://honeyfarm.finance',
    description: 'Layered Delegated Yield Farming Project with high APR on Multi-Chains',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/honeyfarm.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'honeybee',
    cmcId: '13111',
    category: 'Yield',
    chains: ['Binance', 'Avalanche'],
    module: 'HoneyFarm/index.js',
    twitter: 'HoneyFarmFi',
    audit_links: [
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-HoneyFarm-v1.0.pdf',
      'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-HoneyFarm-v1.0.1.pdf',
    ],
    oracles: ['Chainlink'],
    listedAt: 1638879127,
    slug: 'honeyfarm',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Avalanche-staking': 1482.3155387062666,
      'Binance-staking': 530.1050590613861,
      Avalanche: 0,
      staking: 2012.4205977676527,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2012.4205977676527,
    fdv: 148368,
    mcap: 0,
  },
  {
    id: '1001',
    name: 'Giza DAO',
    address: 'fantom:0x3389492f36642f27F7bF4a7749fb3FC2c8fbB7EE',
    symbol: 'GIZA',
    url: 'https://www.gizadao.com',
    description: 'Giza is a community-driven DAO utilizing the bond model powered by Fantom Opera.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/giza-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'gizadao',
    cmcId: '15468',
    category: 'Reserve Currency',
    chains: ['Fantom'],
    module: 'gizadao/index.js',
    twitter: 'GizaDao',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1639100829,
    slug: 'giza-dao',
    tvl: 0,
    chainTvls: { 'Fantom-staking': 504004.09555736126, staking: 504004.09555736126, Fantom: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 504004.09555736126,
    fdv: 2491961,
    mcap: 0,
  },
  {
    id: '1004',
    name: 'Colony',
    address: 'avax:0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6',
    symbol: 'CLY',
    url: 'https://www.colonylab.io/',
    description: 'Community-driven Avalanche ecosystem accelerator',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/colony.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'colony',
    cmcId: '12785',
    category: 'Services',
    chains: ['Avalanche'],
    module: 'colony/index.js',
    twitter: 'Colonylab',
    listedAt: 1639173502,
    slug: 'colony',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 10000836.876650019, Avalanche: 0, staking: 10000836.876650019 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 10000836.876650019,
    fdv: 89066877,
    mcap: 0,
  },
  {
    id: '1021',
    name: 'RivrKitty',
    address: 'moonriver:0xc2b0435276139731d82ae2fa8928c9b9de0761c1',
    symbol: 'RKITTY',
    url: 'https://rivrkitty.com',
    description: 'An Emerging Play to Earn KittyVerse Blending DeFi & NFTs',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/rivrkitty.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'rivrkitty',
    cmcId: '14934',
    category: 'Yield',
    chains: ['Moonriver'],
    module: 'rivrkitty.js',
    twitter: 'RivrKitty',
    audit_links: ['https://docs.rivrkitty.com/smart-contracts/main-contracts#usdpaws-farming-contract'],
    forkedFrom: ['Uniswap'],
    listedAt: 1639421711,
    slug: 'rivrkitty',
    tvl: 0,
    chainTvls: { Moonriver: 0, 'Moonriver-pool2': 895.0106433133326, pool2: 895.0106433133326 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 895.0106433133326,
    fdv: 4159.47,
    mcap: 0,
  },
  {
    id: '1034',
    name: 'Base Protocol',
    address: '0x07150e919b4de5fd6a63de1f9384828396f25fdc',
    symbol: 'BASE',
    url: 'https://www.baseprotocol.org',
    description:
      'The Base Protocol (BASE) is a synthetic crypto asset whose price is pegged to the total market cap (cmc) of all cryptocurrencies at a ratio of 1 : 1 trillion',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/base-protocol.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'base-protocol',
    cmcId: '7838',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'baseprotocol/index.js',
    twitter: 'BaseProtocol',
    listedAt: 1639789919,
    slug: 'base-protocol',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 91807.68592221075,
      'Ethereum-staking': 16804.64051343504,
      staking: 16804.64051343504,
      'Ethereum-pool2': 91807.68592221075,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 16804.64051343504,
    pool2: 91807.68592221075,
    fdv: 394020,
    mcap: 1411947,
  },
  {
    id: '1035',
    name: 'BITPIF',
    address: '0x5197fbe1a86679ff1360e27862bf88b0c5119bd8',
    symbol: 'BPF',
    url: 'https://bitpif.com/#/swap',
    description: 'Liquidity staking and DEX',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/bitpif.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'bitpif/index.js',
    twitter: 'bitpif',
    listedAt: 1639793624,
    slug: 'bitpif',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 628.1135286060554,
      'Ethereum-staking': 0,
      staking: 0,
      'Ethereum-pool2': 628.1135286060554,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 0,
    pool2: 628.1135286060554,
  },
  {
    id: '1041',
    name: 'Numbers Protocol',
    address: '0x3496b523e5c00a4b4150d6721320cddb234c3079',
    symbol: 'NUM',
    url: 'https://www.numbersprotocol.io',
    description: 'A decentralised photo network, for creating community, value, and trust in digital media.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/numbers-protocol.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'numbers-protocol',
    cmcId: '13521',
    category: 'Services',
    chains: ['Binance', 'Ethereum'],
    module: 'numbers/index.js',
    twitter: 'numbersprotocol',
    listedAt: 1639804267,
    slug: 'numbers-protocol',
    tvl: 0,
    chainTvls: {
      pool2: 40831.2667168316,
      'Binance-pool2': 40723.43634769734,
      'Ethereum-pool2': 107.83036913425828,
      'Binance-staking': 5153.563706323817,
      'Ethereum-staking': 3.356100115758e-12,
      staking: 5153.563706323821,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 5153.563706323821,
    pool2: 40831.2667168316,
    fdv: 20738455,
    mcap: 43125662,
  },
  {
    id: '1048',
    name: 'Merit Circle',
    address: '0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6',
    symbol: 'MC',
    url: 'https://www.meritcircle.io/',
    description:
      'Merit circle is creating a DAO that develops opportunities to earn through play for people who want to help build the metaverse.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/merit-circle.png',
    audit: '2',
    audit_note: null,
    gecko_id: 'merit-circle',
    cmcId: '13523',
    category: 'Launchpad',
    chains: ['Ethereum'],
    module: 'meritcircle/index.js',
    twitter: 'MeritCircle_IO',
    audit_links: [
      'https://www.meritcircle.io/Report.pdf',
      'https://www.meritcircle.io/Quantstamp-Liquidity-Mining-Audit.pdf',
    ],
    listedAt: 1639869375,
    slug: 'merit-circle',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 58149631.060933694,
      'Ethereum-staking': 85954801.523007,
      staking: 85954801.523007,
      'Ethereum-pool2': 58149631.060933694,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 85954801.523007,
    pool2: 58149631.060933694,
    fdv: 2760757455,
    mcap: 199298718,
  },
  {
    id: '1058',
    name: 'MOBOX',
    address: 'bsc:0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
    symbol: 'MBOX',
    url: 'https://www.mobox.io/home/#/iframe/momo',
    description:
      'MOBOX is a cross platform gaming metaverse that combines automated optimized DeFi yield farming & gaming NFTs to create a GameFi #metaverse known as #MOMOverse',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/mobox.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'mobox',
    cmcId: '9175',
    category: 'Gaming',
    chains: ['Binance'],
    module: 'mobox.js',
    twitter: 'MOBOX_Official',
    audit_links: ['https://faqen.mobox.io/#audit'],
    listedAt: 1639998611,
    slug: 'mobox',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 170162835.11666647,
      pool2: 152000616.49561125,
      'Binance-pool2': 152000616.49561125,
      staking: 170162835.11666647,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 170162835.11666647,
    pool2: 152000616.49561125,
    fdv: 2701228946,
    mcap: 343355278,
  },
  {
    id: '1059',
    name: 'Sheesha Finance',
    address: 'bsc:0x232FB065D9d24c34708eeDbF03724f2e95ABE768',
    symbol: 'SHEESHA',
    url: 'https://www.sheeshafinance.io',
    description:
      'Sheesha Finance provides a next-level multichain and community-centric DeFi mutual fund where you stake, set, and forget your tokens while collecting rewards from partner projects on the ERC20 and BEP20 chains.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/sheesha-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '10337',
    category: 'Staking',
    chains: ['Binance'],
    module: 'sheesha.js',
    twitter: 'SheeshaFinance_',
    listedAt: 1640000895,
    slug: 'sheesha-finance',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 2753397.905628243,
      pool2: 3393091.215234989,
      'Binance-pool2': 3393091.215234989,
      staking: 2753397.905628243,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2753397.905628243,
    pool2: 3393091.215234989,
  },
  {
    id: '1061',
    name: 'CakeDAO',
    address: 'avax:0x4Eb49A2F9A79053866faE806fAc95A3eF5B92C05',
    symbol: 'CAKE',
    url: 'https://cakedao.finance/',
    description:
      '​CakeDao is a decentralized reserve currency protocol available on the Avalanche Network based on the CAKE token.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/cakedao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'cake',
    cmcId: '16149',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'cakedao/index.js',
    twitter: 'Cake_DAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640030248,
    slug: 'cakedao',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 5738.535959668507, Avalanche: 0, staking: 5738.535959668507 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 5738.535959668507,
    mcap: 0,
  },
  {
    id: '1065',
    name: 'The Sandbox',
    address: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
    symbol: 'SAND',
    url: 'https://www.sandbox.game',
    description:
      'The Sandbox is a virtual gaming world where players can build, own, and monetize their gaming experiences.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/the-sandbox.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'the-sandbox',
    cmcId: '6210',
    category: 'Gaming',
    chains: ['Ethereum', 'Polygon'],
    module: 'the-sandbox.js',
    twitter: 'TheSandboxGame',
    listedAt: 1640082516,
    slug: 'the-sandbox',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      Polygon: 0,
      pool2: 143768924.13824207,
      'Polygon-pool2': 137471221.3879512,
      'Ethereum-pool2': 6297702.750290864,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 143768924.13824207,
    fdv: 10814794456,
    mcap: 4143071880,
  },
  {
    id: '1066',
    name: 'Brokoli Network',
    address: '0x4674a4f24c5f63d53f22490fb3a08eaaad739ff8',
    symbol: 'BRKL',
    url: 'https://brokoli.network',
    description: 'The Green Metaverse: Plant trees with our green DEX aggregator, get NFT trees, build status',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/brokoli-network.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'brokoli',
    cmcId: '10893',
    category: 'Dexes',
    chains: ['Binance', 'Ethereum'],
    module: 'brokoli/index.js',
    twitter: 'BrokoliNetwork',
    listedAt: 1640083236,
    slug: 'brokoli-network',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 30848.387733589676,
      pool2: 299936.2757867129,
      'Binance-pool2': 285828.98210566095,
      staking: 30848.387733589676,
      'Ethereum-pool2': 14107.293681051926,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 30848.387733589676,
    pool2: 299936.2757867129,
    fdv: 16834397,
    mcap: 4008195,
  },
  {
    id: '1070',
    name: 'Nacho Finance',
    address: 'polygon:0xcD86152047e800d67BDf00A4c635A8B6C0e5C4c2',
    symbol: 'NACHO',
    url: 'https://nachoxyz.com/',
    description:
      'The NACHO token serves as the backbone of a rapidly growing ecosystem aimed at bringing high APRs to ETH holders on the Polygon Network',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/nacho-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'nacho-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Polygon'],
    module: 'nacho-finance/index.js',
    twitter: 'nacho_finance',
    forkedFrom: ['Tomb Finance'],
    audit_links: ['https://rugdoc.io/project/nachoxyz'],
    listedAt: 1640096151,
    slug: 'nacho-finance',
    tvl: 0,
    chainTvls: {
      Polygon: 0,
      pool2: 1063880.080702578,
      'Polygon-pool2': 1063880.080702578,
      'Polygon-staking': 90142.44642798265,
      staking: 90142.44642798265,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 90142.44642798265,
    pool2: 1063880.080702578,
    fdv: 191370406,
    mcap: 0,
  },
  {
    id: '1073',
    name: 'Kitty Finance',
    address: 'avax:0x788ae3b5d153d49f8db649aacba1857f744b739e',
    symbol: 'KITTY',
    url: 'https://kittyfinance.io',
    description:
      'An algorithmic token, $KITTY, aimed towards bringing liquidity and new use cases to the AVAX & MATIC network.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kitty-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'kitty-finance',
    cmcId: '15948',
    category: 'Algo-Stables',
    chains: ['Polygon', 'Avalanche'],
    module: 'kittyfinance/index.js',
    twitter: 'KittyAVAX',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1640109287,
    slug: 'kitty-finance',
    tvl: 0,
    chainTvls: {
      pool2: 462079.6335519535,
      'Polygon-pool2': 86995.4264546423,
      Avalanche: 0,
      'Avalanche-staking': 102410.72231957468,
      Polygon: 0,
      'Polygon-staking': 27261.518665532883,
      'Avalanche-pool2': 375084.20709731115,
      staking: 129672.24098510756,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 129672.24098510756,
    pool2: 462079.6335519535,
    fdv: 6688.89,
    mcap: 0,
  },
  {
    id: '1078',
    name: 'Polkastarter',
    address: 'ethereum:0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa',
    symbol: 'POLS',
    url: 'https://polkastarter.com/#upcoming-projects',
    description:
      'Empowering projects to launch decentralized and interoperable token pools and auctions. Enabling backers secure early access to crypto innovation',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/polkastarter.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'polkastarter',
    cmcId: '1166',
    category: 'Launchpad',
    chains: ['Binance', 'Ethereum'],
    module: 'polkastarter/index.js',
    twitter: 'polkastarter',
    oracles: ['DIA'],
    listedAt: 1640177869,
    slug: 'polkastarter',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 28103136.199903637,
      'Ethereum-staking': 10746838.902979672,
      staking: 38849975.10288331,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 38849975.10288331,
    fdv: 156950930,
    mcap: 138464358,
  },
  {
    id: '1082',
    name: 'Umbria Network',
    address: '0xa4bbe66f151b22b167127c770016b15ff97dd35c',
    symbol: 'UMBR',
    url: 'https://umbria.network',
    description:
      "Umbria's Narni Bridge: fastest & cheapest cross-chain liquidity bridge Ethereum & Polygon. Migrate assets almost instantly for extremely low fees",
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/umbria-network.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'umbra-network',
    cmcId: '8780',
    category: 'Cross Chain',
    chains: ['Polygon', 'Ethereum'],
    module: 'umbria/index.js',
    twitter: 'NetworkUmbria',
    forkedFrom: ['Uniswap'],
    listedAt: 1640255497,
    slug: 'umbria-network',
    tvl: 0,
    chainTvls: {
      pool2: 1141812.4135870456,
      'Polygon-pool2': 35879.72244361034,
      'Ethereum-pool2': 1105932.6911434352,
      Ethereum: 0,
      'Ethereum-staking': 64646.79185220876,
      Polygon: 0,
      'Polygon-staking': 0,
      staking: 64646.79185220876,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 64646.79185220876,
    pool2: 1141812.4135870456,
    fdv: 24887009,
    mcap: 0,
  },
  {
    id: '1083',
    name: 'Everipedia',
    address: '0x579cea1889991f68acc35ff5c3dd0621ff29b0c9',
    symbol: 'IQ',
    url: 'https://everipedia.org',
    description: "The world's largest blockchain knowledge base. Powered by the IQ Token.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/everipedia.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'everipedia',
    cmcId: '2930',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'everipedia/index.js',
    twitter: 'Everipedia',
    listedAt: 1640256058,
    slug: 'everipedia',
    tvl: 0,
    chainTvls: { 'Ethereum-staking': 3964697.741719648, staking: 3964697.741719648 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3964697.741719648,
    fdv: 242476547,
    mcap: 115712740,
  },
  {
    id: '1091',
    name: 'ZodiacDAO',
    address: 'bsc:0x98051143830fa99848e7059e97acb03b3cc62403',
    symbol: 'ZD',
    url: 'https://zodiacdao.finance/',
    description: 'Advanced protocol, Minimize Limitation, Maximize Profit. We call it “DeFi 2.0+”',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/zodiacdao.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'zodiacdao',
    cmcId: '16450',
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'zodiac/index.js',
    twitter: 'ZodiacDAO_BEP20',
    forkedFrom: ['Olympus DAO'],
    audit_links: ['https://www.certik.com/projects/zodiacdao'],
    listedAt: 1640429011,
    slug: 'zodiacdao',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 2678.728845714972, staking: 2678.728845714972 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2678.728845714972,
    mcap: 0,
  },
  {
    id: '1094',
    name: 'One Cash',
    address: '0xd90e69f67203ebe02c917b5128629e77b4cd92dc',
    symbol: 'ONC',
    url: 'https://onecash.finance/',
    description: 'Staking protocol on ethereum network',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/onecash.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'one-cash',
    cmcId: '8159',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'onecash/index.js',
    twitter: null,
    listedAt: 1640525223,
    slug: 'one-cash',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 35583.348235697704,
      'Ethereum-staking': 68495.79379395112,
      staking: 68495.79379395112,
      'Ethereum-pool2': 35583.348235697704,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 68495.79379395112,
    pool2: 35583.348235697704,
    fdv: 262498,
    mcap: 262498,
  },
  {
    id: '1095',
    name: 'Stand Cash',
    address: '0xacd8f2523a4613eee78904354187c81bb05ae2b8',
    symbol: 'SAC',
    url: 'https://stand.cash/',
    description: null,
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/standcash.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'stand-cash',
    cmcId: null,
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'standcash/index.js',
    twitter: null,
    listedAt: 1640525223,
    slug: 'stand-cash',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 418391.09123897366,
      'Ethereum-staking': 39109.50573650783,
      staking: 39109.50573650783,
      'Ethereum-pool2': 418391.09123897366,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 39109.50573650783,
    pool2: 418391.09123897366,
    fdv: 4154.09,
    mcap: 3073.8,
  },
  {
    id: '1102',
    name: 'Optidoge',
    address: 'optimism:0x93D97dbB1BB5290C78C23885E8026047dC8998A8',
    symbol: 'OPTIDOGE',
    url: 'https://optidoge.xyz/',
    description: 'Optimism Dog token',
    chain: 'Optimism',
    logo: 'https://icons.llama.fi/optidoge.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Optimism'],
    module: 'optidoge/index.js',
    forkedFrom: ['ArbiNYAN'],
    listedAt: 1640596078,
    slug: 'optidoge',
    tvl: 0,
    chainTvls: {
      Optimism: 0,
      'Optimism-pool2': 7.661037112851043,
      'Optimism-staking': 10837.644770754474,
      pool2: 7.661037112851043,
      staking: 10837.644770754474,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 10837.644770754474,
    pool2: 7.661037112851043,
  },
  {
    id: '1103',
    name: 'SnowCatDAO',
    address: 'avax:0x2558b8FBa8f678Fd3029C927CD3780f5d3C266C7',
    symbol: 'SCAT',
    url: 'https://snowcatdao.com/',
    description: 'OHM Fork on AVAX',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/snowcatdao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'snowcatdao/index.js',
    twitter: 'DaoSnowCat',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640597948,
    slug: 'snowcatdao',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 1226.802520062533, Avalanche: 0, staking: 1226.802520062533 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1226.802520062533,
  },
  {
    id: '1104',
    name: 'AgarthaDAO',
    address: 'avax:0xa5b0f5ef809fd04c9d4320211c711cb34ef812dd',
    symbol: 'CLAVIS',
    url: 'https://agartha.money/',
    description: 'Gamified risk DeFi protocol on AVAX.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/agarthadao.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'clavis',
    cmcId: '16328',
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'agarthadao/index.js',
    twitter: 'AgarthaDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1640606499,
    slug: 'agarthadao',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 5.787965395592054, Avalanche: 0, staking: 5.787965395592054 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 5.787965395592054,
    mcap: 0,
  },
  {
    id: '1111',
    name: 'Wisteria Swap',
    address: 'bsc:0xaadff17d56d80312b392ced903f3e8dbe5c3ece7',
    symbol: 'WST',
    url: 'https://wisteriaswap.com',
    description:
      'WisteriaSwap is a new DeFi 2.0 (PVC) model that combines two models: a system in which the protocol can secure its own liquidity, and a system in which inflation can be controlled by continuously burning tokens.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/wisteriaswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'wisteria-swap',
    cmcId: '16289',
    category: 'Yield',
    chains: ['Binance'],
    module: 'wisteriaswap/index.js',
    twitter: 'robiniaswap',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/December/Wisteria%20Swap.pdf'],
    listedAt: 1640631213,
    slug: 'wisteria-swap',
    tvl: 0,
    chainTvls: {
      'Binance-masterchef': 0,
      pool2: 0,
      masterchef: 0,
      'Binance-pool2': 0,
      Binance: 0,
      'Binance-staking': 260391.57743160328,
      staking: 260391.57743160328,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 260391.57743160328,
    pool2: 0,
    mcap: 0,
  },
  {
    id: '1118',
    name: 'Louverture',
    address: 'avax:0xff579d6259dEDcc80488c9b89d2820bCb5609160',
    symbol: 'LVT',
    url: 'https://www.louverture.finance/',
    description: 'The Opening of your yields across different chains. Empowered by nodes.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/louverture.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'louverture',
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'louverture/index.js',
    twitter: 'Louverture_fi',
    listedAt: 1640691832,
    slug: 'louverture',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 0, Avalanche: 0, staking: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 0,
    fdv: 792961,
    mcap: 0,
  },
  {
    id: '1128',
    name: 'KawaiiSwap',
    address: 'bsc:0x9e236b43D779B385c3279820e322ABAE249D3405',
    symbol: 'CALCIFIRE',
    url: 'https://www.kawaiiswap.finance/',
    description: 'KawaiiSwap enrichs traditional yield farming experience with gamification features.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/kawaiiswap.png',
    audits: '2',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Binance'],
    module: 'kawaiiswap-finance/index.js',
    twitter: 'kawaiiswap',
    audit_links: [
      'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_Calcifire.pdf',
      'https://github.com/solidproof/kyc-certificates/blob/main/KYC_Certificate_Calcifer_%26_Howls_Castle.png',
    ],
    listedAt: 1640719202,
    slug: 'kawaiiswap',
    tvl: 0,
    chainTvls: {
      'Binance-masterchef': 0,
      pool2: 21575.0912371528,
      masterchef: 0,
      'Binance-pool2': 21575.0912371528,
      Binance: 0,
      'Binance-staking': 21875.89868563618,
      staking: 21875.89868563618,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 21875.89868563618,
    pool2: 21575.0912371528,
  },
  {
    id: '1138',
    name: 'Valkyrie',
    address: 'terra:terra1dy9kmlm4anr92e42mrkjwzyvfqwz66un00rwr5',
    symbol: 'VKR',
    url: 'https://valkyrieprotocol.com',
    description:
      'Valkyrie Protocol is a DApp activation protocol that is designed to help protocols launch effectively.',
    chain: 'Terra',
    logo: 'https://icons.llama.fi/valkyrie.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'valkyrie-protocol',
    cmcId: '12978',
    category: 'Launchpad',
    chains: ['Terra'],
    module: 'valkyrie/index.js',
    twitter: 'valkyrie_money',
    audit_links: ['https://docs.valkyrieprotocol.com/security#audits'],
    openSource: true,
    listedAt: 1640738674,
    slug: 'valkyrie',
    tvl: 0,
    chainTvls: {
      'Terra-staking': 5482106.408683939,
      pool2: 453217.96659111546,
      'Terra-pool2': 453217.96659111546,
      staking: 5482106.408683939,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 5482106.408683939,
    pool2: 453217.96659111546,
    fdv: 110784992,
    mcap: 15164958,
  },
  {
    id: '1153',
    name: 'Umbrella Network',
    address: '0x6fC13EACE26590B80cCCAB1ba5d51890577D83B2',
    symbol: 'UMB',
    url: 'https://staking.umb.network',
    description:
      'Umbrella is the first truly decentralized oracle service providing low cost, massively scalable, and secure solutions for smart contracts.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/umbrella-network.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'umbrella-network',
    cmcId: '8385',
    category: 'Oracle',
    chains: ['Binance', 'Ethereum'],
    module: 'umbrella/index.js',
    twitter: 'UmbNetwork',
    listedAt: 1640829115,
    slug: 'umbrella-network',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 968.44202,
      pool2: 375185.9957768745,
      'Ethereum-staking': 2176092.856208309,
      staking: 2177061.298228309,
      'Ethereum-pool2': 375185.9957768745,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2177061.298228309,
    pool2: 375185.9957768745,
    fdv: 64282779,
    mcap: 21051917,
  },
  {
    id: '1158',
    name: 'Unite Finance',
    address: 'harmony:0xb4441013ea8aa3a9e35c5aca2b037e577948c59e',
    symbol: 'UNITE',
    url: 'https://unitefinance.io',
    description: 'Unite Finance is the first Tomb Finance inspired Seigniorage running on Harmony',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/unite-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'unite',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Harmony'],
    module: 'unite-finance/index.js',
    twitter: 'finance_unite',
    forkedFrom: ['Tomb Finance'],
    audit_links: [
      'https://github.com/0xGuard-com/audit-reports/blob/master/unite-finance/Unite-Finance_final-audit-report.pdf',
    ],
    listedAt: 1640898313,
    slug: 'unite-finance',
    tvl: 0,
    chainTvls: {
      'Harmony-pool2': 2493891.980962342,
      pool2: 2493891.980962342,
      'Harmony-staking': 523068.1462033673,
      Harmony: 0,
      staking: 523068.1462033673,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 523068.1462033673,
    pool2: 2493891.980962342,
    fdv: 15309.56,
    mcap: 0,
  },
  {
    id: '1159',
    name: 'Piggy Finance',
    address: 'avax:0x1a877B68bdA77d78EEa607443CcDE667B31B0CdF',
    symbol: 'PIGGY',
    url: 'https://piggyfinance.io/',
    description:
      'The $PIGGY algorithmic token serves as the backbone of a rapidly growing ecosystem aimed towards bringing liquidity and new use cases to the Avalanche network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/piggy-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'piggy-finance',
    cmcId: null,
    category: 'Yield',
    chains: ['Avalanche'],
    module: 'piggy-finance/index.js',
    twitter: 'piggy_finance',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1640899616,
    slug: 'piggy-finance',
    tvl: 0,
    chainTvls: {
      Avalanche: 0,
      'Avalanche-staking': 594053.0944981478,
      pool2: 826576.2206689047,
      'Avalanche-pool2': 826576.2206689047,
      staking: 594053.0944981478,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 594053.0944981478,
    pool2: 826576.2206689047,
    mcap: 0,
  },
  {
    id: '1168',
    name: 'Goblin FTM',
    address: 'fantom:0x81570aB82533cbd5DB529d29596180eF688c52ba',
    symbol: 'GBN',
    url: 'https://www.thegoblin.money/',
    description: 'DeFi Yield Farming on Fantom ',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/goblin-ftm.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Fantom'],
    module: 'goblin/index.js',
    twitter: 'GoblinFTM',
    listedAt: 1641051165,
    slug: 'goblin-ftm',
    tvl: 0,
    chainTvls: {
      pool2: 0,
      'Fantom-staking': 0,
      masterchef: 0,
      'Fantom-masterchef': 0,
      'Fantom-pool2': 0,
      Fantom: 0,
      staking: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 0,
    pool2: 0,
  },
  {
    id: '1169',
    name: 'Scarab Finance',
    address: 'fantom:0x2e79205648b85485731cfe3025d66cf2d3b059c4',
    symbol: 'SCARAB',
    url: 'https://scarab.finance/',
    description: 'Scarab.Finance is a brand new Tomb finance fork on the Fantom Opera network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/scarab-finance.png',
    audits: '1',
    audit_note: null,
    gecko_id: 'scarab-finance',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: 'scarabfinance/index.js',
    twitter: 'Scarab_Finance',
    audit_links: ['https://www.certik.com/projects/scarab-finance'],
    forkedFrom: ['Tomb Finance'],
    listedAt: 1641057798,
    slug: 'scarab-finance',
    tvl: 0,
    chainTvls: {
      pool2: 3106351.1524035805,
      'Fantom-staking': 1441090.485081838,
      'Fantom-pool2': 3106351.1524035805,
      staking: 1441090.485081838,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1441090.485081838,
    pool2: 3106351.1524035805,
    fdv: 264781,
    mcap: 0,
  },
  {
    id: '1175',
    name: 'Xenophon',
    address: 'bsc:0x3e29633846E8b857B7f6d7f293F895186804264b',
    symbol: 'XPH',
    url: 'https://xenophon.finance/',
    description: 'Xenophon is a decentralized reserve asset protocol built on DeFi2.0 based on XPH currency.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/xenophon.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Binance'],
    module: 'xenophon/index.js',
    twitter: 'XenophonDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641155555,
    slug: 'xenophon',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 8160.266435579445, staking: 8160.266435579445 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 8160.266435579445,
  },
  {
    id: '1186',
    name: 'Rarify',
    address: 'xdai:0x57e93bb58268de818b42e3795c97bad58afcd3fe',
    symbol: 'RARE',
    url: 'https://rare.fyi',
    description:
      'Rarify is a collection of defi applications that lets anyone earn RARE COIN equally and virtually free. Two Rare coins are created and distributed every day: One is equally distributed to the daily Claimers and one is weighted distributed to the weekly Stakers.',
    chain: 'xDai',
    logo: 'https://icons.llama.fi/rarify.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['xDai'],
    module: 'rarify.js',
    twitter: 'RarifyApps',
    listedAt: 1641253900,
    slug: 'rarify',
    tvl: 0,
    chainTvls: { xDai: 0, pool2: 29764.564042530903, 'xDai-pool2': 29764.564042530903 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 29764.564042530903,
  },
  {
    id: '1187',
    name: 'Galatea Cash',
    address: 'cronos:0x8420454d4b5f436dbCb5913609F3848350B8723A',
    symbol: 'gCASH',
    url: 'https://galatea.cash',
    description:
      'Galatea cash is the first algorithmic stablecoin protocol designed to follow the price of CRO running on the Cronos Chain.',
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/galatea-cash.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Cronos'],
    module: 'galatea/index.js',
    twitter: 'GalateaCash',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1641256326,
    slug: 'galatea-cash',
    tvl: 0,
    chainTvls: {
      pool2: 1013.1395386202772,
      staking: 472.6249401063013,
      Cronos: 0,
      'Cronos-staking': 472.6249401063013,
      'Cronos-pool2': 1013.1395386202772,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 472.6249401063013,
    pool2: 1013.1395386202772,
  },
  {
    id: '1195',
    name: 'Instrumental Finance',
    address: '0x0edf9bc41bbc1354c70e2107f80c42cae7fbbca8',
    symbol: 'STRM',
    url: 'https://www.instrumental.finance',
    description:
      'Cross-chain, cross-layer yield optimizer. Strategy hub powered by and for a cross-layer, cross-chain future for DeFi’s continuous growth.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/instrumental-finance.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'instrumental-finance',
    cmcId: '6514',
    category: 'Yield',
    chains: ['Ethereum'],
    module: 'instrumental/index.js',
    twitter: 'InstrumentalFi',
    listedAt: 1641404391,
    slug: 'instrumental-finance',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 1232407.511470479,
      'Ethereum-staking': 499197.0578598675,
      staking: 499197.0578598675,
      'Ethereum-pool2': 1232407.511470479,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 499197.0578598675,
    pool2: 1232407.511470479,
    fdv: 16553288,
    mcap: 0,
  },
  {
    id: '1196',
    name: 'Chikn Farm',
    address: 'avax:0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611',
    symbol: 'EGG',
    url: 'https://chikn.farm',
    description: '10,000 algorithmically generated, unique chikn NFTs that lay $egg',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/chikn-farm.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'chikn-egg',
    cmcId: null,
    category: 'Services',
    chains: ['Avalanche'],
    module: 'chiknfarm/index.js',
    twitter: 'chikn_nft',
    listedAt: 1641406406,
    slug: 'chikn-farm',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 14498772.10028513, Avalanche: 0, staking: 14498772.10028513 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 14498772.10028513,
    fdv: 1928598,
    mcap: 0,
  },
  {
    id: '1198',
    name: 'Chronicle',
    address: 'bsc:0x06a00715e6f92210af9d7680b584931faf71a833',
    symbol: 'XLN',
    url: 'https://www.chronicle.io',
    description:
      'Chronicle is both a publisher and marketplace for officially licensed digital collectables, also more commonly known as NFTs',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/chronicle.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'chronicle',
    cmcId: '8797',
    category: 'Services',
    chains: ['Binance'],
    module: 'chronicle/index.js',
    twitter: 'ChronicleXNL',
    listedAt: 1641421435,
    slug: 'chronicle',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 260.125012873017,
      pool2: 1017.6346019686493,
      'Binance-pool2': 1017.6346019686493,
      staking: 260.125012873017,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 260.125012873017,
    pool2: 1017.6346019686493,
    mcap: 3863993,
  },
  {
    id: '1199',
    name: 'Ferrum',
    address: '0xe5caef4af8780e59df925470b050fb23c43ca68c',
    symbol: 'FRM',
    url: 'https://ferrum.network',
    description:
      'Ferrum Network is a cross-chain Blockchain as a Service DeFi company that specializes in adding deflationary token utility and advisory services to projects across the crypto space.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/ferrum.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'ferrum-network',
    cmcId: '4228',
    category: 'Services',
    chains: ['Ethereum', 'Polygon'],
    module: 'ferrum/index.js',
    twitter: 'FerrumNetwork',
    listedAt: 1641421592,
    slug: 'ferrum',
    tvl: 0,
    chainTvls: {
      pool2: 63.05777512939467,
      'Ethereum-staking': 281877.43891793536,
      'Polygon-pool2': 63.05777512939467,
      staking: 281877.43891793536,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 281877.43891793536,
    pool2: 63.05777512939467,
    fdv: 29303154,
    mcap: 10205738,
  },
  {
    id: '1205',
    name: 'Predictcoin',
    address: 'bsc:0xbdD2E3fdb879AA42748E9D47b7359323f226BA22',
    symbol: 'PRED',
    url: 'https://predictcoin.finance/#',
    description:
      'Predictcoin is an all-in-one price prediction DAO. Stake, Farm, Vote and Predict the prices of various crypto assets.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/pridictcoin.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'predictcoin',
    cmcId: null,
    category: 'Prediction Market',
    chains: ['Binance'],
    module: 'predictcoin/index.js',
    twitter: 'PredictcoinFin',
    audit_links: [
      'https://github.com/TechRate/Smart-Contract-Audits/blob/main/August/Predictcoin%20Full%20Smart%20Contract%20Security%20Audit.pdf',
    ],
    listedAt: 1641513916,
    slug: 'predictcoin',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 55638.97640329818,
      pool2: 8386.247632343464,
      'Binance-pool2': 8386.247632343464,
      staking: 55638.97640329818,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 55638.97640329818,
    pool2: 8386.247632343464,
    mcap: 0,
  },
  {
    id: '1210',
    name: 'Voyager',
    address: '0x3C4B6E6e1eA3D4863700D7F76b36B7f3D3f13E3d',
    symbol: 'VGX',
    url: 'https://www.investvoyager.com',
    description: 'Join The Financial Revolution - crypto trading.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/voyager.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'ethos',
    cmcId: '1817',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'voyager/index.js',
    twitter: 'investvoyager',
    listedAt: 1641535544,
    slug: 'voyager',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 493719320.8115088, staking: 493719320.8115088 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 493719320.8115088,
    mcap: 0,
  },
  {
    id: '1214',
    name: 'Karma DAO',
    address: 'avax:0x5ccff6723f592c223e7b31c6872ba999a028653f',
    symbol: 'KARMA',
    url: 'https://karmadao.money/',
    description: 'OHM fork with a built in lottery game that rewards stakers weekly.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/karma-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'karma-dao/index.js',
    twitter: 'KarmaDAO_',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1641663496,
    slug: 'karma-dao',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 145710.14631731482, Avalanche: 0, staking: 145710.14631731482 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 145710.14631731482,
  },
  {
    id: '1215',
    name: 'OVR',
    address: '0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697',
    symbol: 'OVR',
    url: 'https://www.ovr.ai',
    description:
      'OVR is an AR, NFT Metaverse platform that layers over the entire world. Imagine, create & sell experiences for B2B + B2C.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/ovr.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'ovr',
    cmcId: '8144',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'ovr/index.js',
    twitter: 'OVRtheReality',
    listedAt: 1641669316,
    slug: 'ovr',
    tvl: 0,
    chainTvls: { 'Ethereum-staking': 7159152.393656517, staking: 7159152.393656517 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 7159152.393656517,
    mcap: 44116190,
  },
  {
    id: '1229',
    name: 'LooksRare',
    address: '0xf4d2888d29D722226FafA5d9B24F9164c092421E',
    symbol: 'LOOKS',
    url: 'https://looksrare.org',
    description:
      "LooksRare is the community-first NFT marketplace with rewards for participating. Buy NFTs (or sell 'em) to earn rewards.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/looksrare.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'looksrare',
    cmcId: '17081',
    category: 'NFT Marketplace',
    chains: ['Ethereum'],
    module: 'looks-rare/index.js',
    twitter: 'LooksRareNFT',
    listedAt: 1641874709,
    slug: 'looksrare',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 232213.4906184412,
      'Ethereum-staking': 697262612.0360863,
      staking: 697262612.0360863,
      'Ethereum-pool2': 232213.4906184412,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 697262612.0360863,
    pool2: 232213.4906184412,
    fdv: 2319710079,
    mcap: 569650610,
  },
  {
    id: '1235',
    name: 'Ultra Protocol',
    address: 'bsc:0x0b3f42481c228f70756dbfa0309d3ddc2a5e0f6a',
    symbol: 'ULTRA',
    url: 'https://ultraprotocol.io/',
    description:
      'Creating the foundation for innovative financial services and investment. They build and support services that contribute to the growth and security of #DeFi',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/ultra.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'ultrasafe',
    cmcId: '10195',
    category: 'Services',
    chains: ['Binance'],
    module: 'ultra/index.js',
    twitter: 'UltraProtocol',
    listedAt: 1641934055,
    slug: 'ultra-protocol',
    tvl: 0,
    chainTvls: {
      'Binance-staking': 3967.2751021308854,
      pool2: 20753.702479647785,
      'Binance-pool2': 20753.702479647785,
      staking: 3967.2751021308854,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3967.2751021308854,
    pool2: 20753.702479647785,
    mcap: 0,
  },
  {
    id: '1237',
    name: 'Bomb Money',
    address: 'bsc:0x522348779DCb2911539e76A1042aA922F9C47Ee3',
    symbol: 'BOMB',
    url: 'https://app.bomb.money/',
    description: 'BOMB is an algocoin which is designed to follow the price of BTC.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/bombmoney.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'bomb-money',
    cmcId: '15876',
    category: 'Algo-Stables',
    chains: ['Binance'],
    module: 'bombmoney/index.js',
    twitter: 'BombMoneyBSC',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1641936906,
    slug: 'bomb-money',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 761364.3281831996,
      pool2: 2665123.789444108,
      'Binance-pool2': 2665123.789444108,
      staking: 761364.3281831996,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 761364.3281831996,
    pool2: 2665123.789444108,
    mcap: 0,
  },
  {
    id: '1244',
    name: 'Crabada',
    address: 'avax:0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed',
    symbol: 'CRA',
    url: 'https://www.crabada.com',
    description:
      "Crabada is an idle game, which means that you'll be able to play it anytime and anywhere with minimal disruption to your everyday life. Crabada is bringing forth a new revolution in play-and-earn games with its unique PvP (Player Versus Player)-infused idle gameplay.",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/crabada.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'crabada',
    cmcId: '13655',
    category: 'Gaming',
    chains: ['Avalanche'],
    module: 'crabada/index.js',
    twitter: 'PlayCrabada',
    audit_links: [
      'https://github.com/verichains/public-audit-reports/blob/main/Verichains%20Public%20Audit%20Report%20-%20Crabada%20Game%20-%20v1.1.pdf',
      'https://github.com/verichains/public-audit-reports/blob/main/Verichains%20Public%20Audit%20Report%20-%20Crabada%20Sale%20-%20v1.2.pdf',
    ],
    listedAt: 1641969967,
    slug: 'crabada',
    tvl: 0,
    chainTvls: {
      'Avalanche-Treasury': 21827193.102177717,
      Avalanche: 0,
      'Avalanche-staking': 33178479.573588803,
      Treasury: 21827193.102177717,
      staking: 33178479.573588803,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 33178479.573588803,
    fdv: 1093966268,
    mcap: 98162105,
  },
  {
    id: '1248',
    name: 'Hermes Finance',
    address: 'avax:0xB15f02F9Da8CD1f99E9dd375F21dc96D25ddd82C',
    symbol: 'HERMES',
    url: 'https://hermesfinance.app/',
    description:
      'One of The first algorithmic stablecoin on Avalanche C Chain, pegged to the price of 1 AVAX via seigniorage.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/hermes-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'hermes',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    module: 'hermes-finance/index.js',
    twitter: 'hermes_avax',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1642027191,
    slug: 'hermes-finance',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 15341.399070541109,
      pool2: 242870.69757516048,
      Avalanche: 0,
      'Avalanche-pool2': 242870.69757516048,
      staking: 15341.399070541109,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 15341.399070541109,
    pool2: 242870.69757516048,
    mcap: 77400,
  },
  {
    id: '1249',
    name: '2omb Finance',
    address: 'fantom:0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE',
    symbol: '2OMB',
    url: 'https://2omb.finance/',
    description: 'A fork of tomb.finance, pegged to the price of 1 FTM via seigniorage.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/2omb-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: '2omb-finance',
    cmcId: '16936',
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: '2omb-finance/index.js',
    twitter: '2ombFinance',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1642029445,
    slug: '2omb-finance',
    tvl: 0,
    chainTvls: {
      pool2: 6076592.738629812,
      'Fantom-staking': 1115976.904649496,
      'Fantom-pool2': 6076592.738629812,
      staking: 1115976.904649496,
      Fantom: 0,
    },
    change_1h: -100,
    change_1d: -100,
    change_7d: -100,
    staking: 1115976.904649496,
    pool2: 6076592.738629812,
    fdv: 52495,
    mcap: 0,
  },
  {
    id: '1251',
    name: 'Kandyland Finance',
    address: 'avax:0x37ded665a387a6f170fb60376b3057f09df6c0ea',
    symbol: 'KANDY',
    url: 'https://app.kandyland.finance/',
    description: 'Kandy is a decentralized reserve currency built on the AVAX network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/kandyland-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Reserve Currency',
    chains: ['Avalanche'],
    module: 'kandyland-finance/index.js',
    twitter: 'KandylandDAO',
    forkedFrom: ['Olympus DAO'],
    listedAt: 1642102448,
    slug: 'kandyland-finance',
    tvl: 0,
    chainTvls: { 'Avalanche-staking': 1450.5034100176367, Avalanche: 0, staking: 1450.5034100176367 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1450.5034100176367,
  },
  {
    id: '1252',
    name: 'Luminous Finance',
    address: 'tron:TDBNKiYQ8yfJtT5MDP3byu7f1npJuG2DBN',
    symbol: 'LUMI',
    url: 'https://luminous.finance/',
    description:
      'Lumi is a token based on the Tron blockchain. Lumi was created as a popular and utility token for the Luminous ecosystem',
    chain: 'Tron',
    logo: 'https://icons.llama.fi/luminous.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'lumi-credits',
    cmcId: '11582',
    category: 'Dexes',
    chains: ['Tron'],
    module: 'luminous/index.js',
    twitter: 'LumiToken',
    listedAt: 1642108395,
    slug: 'luminous-finance',
    tvl: 0,
    chainTvls: {
      pool2: 2077240.0567269535,
      Tron: 0,
      'Tron-pool2': 2077240.0567269535,
      'Tron-staking': 410279.88041938003,
      staking: 410279.88041938003,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 410279.88041938003,
    pool2: 2077240.0567269535,
    mcap: 0,
  },
  {
    id: '1255',
    name: 'Proof of Humanity',
    address: '0xDd1Ad9A21Ce722C151A836373baBe42c868cE9a4',
    symbol: 'UBI',
    url: 'https://www.proofofhumanity.id',
    description:
      "Proof Of Humanity is launching with Democracy Earth's UBI Token. Our verified humans will be the first fully decentralized UBI recipients. We also launched a sustained liquidity provider rewards program. You can provide liquidity in ETH/UBI and DAI/UBI curves on Uniswap and farm a share of UBI rewards minted over the course of a year.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/proof-of-humanity.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'universal-basic-income',
    cmcId: '12919',
    category: 'Staking',
    chains: ['Ethereum'],
    module: 'ubi-poh.js',
    twitter: 'proofofhumanity',
    listedAt: 1642140900,
    slug: 'proof-of-humanity',
    tvl: 0,
    chainTvls: { Ethereum: 0, pool2: 325751.5383599483, 'Ethereum-pool2': 325751.5383599483 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 325751.5383599483,
    mcap: 0,
  },
  {
    id: '1256',
    name: 'Delta Financial',
    address: '0x9ea3b5b4ec044b70375236a281986106457b20ef',
    symbol: 'DELTA',
    url: 'https://www.delta.finance',
    description:
      'Delta is an on-chain options layer which utilizes a combination of liquidity standards to reduce premiums and offer competitive options prices.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/delta-financial.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'delta-financial',
    cmcId: '8994',
    category: 'Options',
    chains: ['Ethereum'],
    module: 'delta/index.js',
    twitter: 'Delta_Token',
    listedAt: 1642141415,
    slug: 'delta-financial',
    tvl: 0,
    chainTvls: {
      pool2: 14333216.241012122,
      'Ethereum-staking': 3156162.7739219917,
      staking: 3156162.7739219917,
      'Ethereum-pool2': 14333216.241012122,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3156162.7739219917,
    pool2: 14333216.241012122,
    fdv: 115063002,
    mcap: 0,
  },
  {
    id: '1263',
    name: 'SmartPad',
    address: '0x5067006F830224960Fb419D7f25a3a53e9919BB0',
    symbol: 'PAD',
    url: 'https://smartpad.network/',
    description:
      'SmartPad is the first launchpad platform of its kind. Our vision is to create a multi-cross-chain launchpad, enabling participation in the IDOs occurring on one blockchain while using the assets from another chain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/smartpad.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'smartpad',
    cmcId: '10610',
    category: 'Launchpad',
    chains: ['Aurora', 'Ethereum', 'Polygon'],
    module: 'smartpad/index.js',
    twitter: 'SmartPad_launch',
    listedAt: 1642193328,
    slug: 'smartpad',
    tvl: 0,
    chainTvls: {
      'Aurora-staking': 132094.20177252992,
      Ethereum: 0,
      Aurora: 0,
      'Ethereum-staking': 980234.3280871223,
      Polygon: 0,
      'Polygon-staking': 165603.59588511006,
      staking: 1277932.1257447621,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1277932.1257447621,
    mcap: 0,
  },
  {
    id: '1265',
    name: 'X',
    address: '0x7f3141c4d6b047fb930991b450f1ed996a51cb26',
    symbol: 'X',
    url: 'https://x.xyz',
    description:
      'X is a multichain decentralised NFT marketplace that is owned and operated by the NFT community. Governance is provided by the X DAO with voting rights given to token holders.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/x.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'x-2',
    cmcId: '16727',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'x-xyz/index.js',
    twitter: 'Xdotxyz',
    listedAt: 1642194932,
    slug: 'x',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 1976381.1769527989, staking: 1976381.1769527989 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1976381.1769527989,
    fdv: 32753937,
    mcap: 5369410,
  },
  {
    id: '1266',
    name: 'fees.wtf',
    address: '0xA68Dd8cB83097765263AdAD881Af6eeD479c4a33',
    symbol: 'WTF',
    url: 'https://fees.wtf/',
    description: 'Gas consumption stats and upcoming Pro Dashboard at fees.wtf (while holding the rekt NFT)',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/fees-wtf.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'wtf-token',
    cmcId: '17279',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'fees-wtf/index.js',
    twitter: 'feeswtf',
    listedAt: 1642195034,
    slug: 'fees.wtf',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 187969.81326686393,
      'Ethereum-staking': 298327.3254625308,
      staking: 298327.3254625308,
      'Ethereum-pool2': 187969.81326686393,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 298327.3254625308,
    pool2: 187969.81326686393,
    fdv: 6567581,
    mcap: 395021,
  },
  {
    id: '1267',
    name: 'The Open DAO SOS',
    address: '0x3b484b82567a09e2588A13D54D032153f0c0aEe0',
    symbol: 'SOS',
    url: 'https://www.theopendao.com/#sos',
    description: '$SOS is grateful to all NFT creators, collectors and markets for nurturing the entire NFT ecosystem.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/the-open-dao-sos.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'opendao',
    cmcId: '16463',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'the-open-dao-sos/index.js',
    twitter: 'The_OpenDAO',
    audit_links: ['https://www.certik.com/projects/opendao'],
    listedAt: 1642194196,
    slug: 'the-open-dao-sos',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 81737577.38088426, staking: 81737577.38088426 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 81737577.38088426,
    fdv: 215950350,
    mcap: 86154341,
  },
  {
    id: '1270',
    name: 'VyFinance',
    address: 'cardano:asset1znlnu4s2uv3ern2f52csz6s6pvr5rf6dj2uevx',
    symbol: 'VYFI',
    url: 'https://vyfi.io',
    description:
      'VyFinance is a DeFi protocol bringing Neural Net AutoHarvesting & KYC-less Hedge Fund Staking to Cardano.',
    chain: 'Cardano',
    logo: 'https://icons.llama.fi/vyfinance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Staking',
    chains: ['Cardano'],
    module: 'vyfi/index.js',
    twitter: 'VyFiOfficial',
    listedAt: 1642235543,
    slug: 'vyfinance',
    tvl: 0,
    chainTvls: { Cardano: 0, 'Cardano-staking': 9266158.90660278, staking: 9266158.90660278 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 9266158.90660278,
  },
  {
    id: '1280',
    name: 'Tornado Finance',
    address: '0x7a3d5d49d64e57dbd6fbb21df7202bd3ee7a2253',
    symbol: 'TCORE',
    url: 'https://tornado.finance',
    description:
      'TCORE is a non-inflationary cryptocurrency based on cVault (CORE) source code, that is designed to execute profit-generating strategies autonomously with a completely decentralized approach.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/tornado-finance.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'tornadocore',
    cmcId: '8082',
    category: 'Yield',
    chains: ['Polygon', 'Binance', 'Ethereum'],
    module: 'tornadocore/index.js',
    twitter: 'tornadofinance',
    audit_links: ['https://tornado.finance/bsc/TCORE-audit.pdf'],
    forkedFrom: ['cVault Finance'],
    listedAt: 1642457577,
    slug: 'tornado-finance',
    tvl: 0,
    chainTvls: {
      pool2: 320675.79237017763,
      'Polygon-pool2': 347.3693245758335,
      'Binance-pool2': 86859.59532249195,
      'Ethereum-pool2': 233468.82772310983,
      'Binance-staking': 0,
      'Ethereum-staking': 0,
      'Polygon-staking': 0,
      staking: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 0,
    pool2: 320675.79237017763,
    fdv: 356805,
    mcap: 305832,
  },
  {
    id: '1290',
    name: 'Grape Finance',
    address: 'avax:0x5541d83efad1f281571b343977648b75d95cdac2',
    symbol: 'GRAPE',
    url: 'https://grapefinance.app/',
    description: 'Grape Finance is an algorithimic stable coin protocol pegged 1:1 to MIM on Avalanche.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/grape-finance.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'grape-finance',
    cmcId: '17420',
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    module: 'grape-finance/index.js',
    twitter: 'grape_finance',
    forkedFrom: ['Tomb Finance'],
    audit_links: [
      'https://github.com/0xGuard-com/audit-reports/blob/master/grape-finance/Grape-Finance_final-audit-report_1642499628626.pdf',
    ],
    listedAt: 1642625568,
    slug: 'grape-finance',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 509749.9523440998,
      pool2: 815963.260575296,
      Avalanche: 0,
      'Avalanche-pool2': 815963.260575296,
      staking: 509749.9523440998,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 509749.9523440998,
    pool2: 815963.260575296,
    fdv: 24863,
    mcap: 0,
  },
  {
    id: '1294',
    name: 'Hades Money',
    address: 'metis:0x88C37E0bc6a237e96bc4A82774A38BBc30efF3Cf',
    symbol: 'HADES',
    url: 'https://hades.money/',
    description: 'First algorithmic token pegged to $METIS on the Andromeda network.',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/hades-money.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'hades-money',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Metis'],
    module: 'hades-money/index.js',
    twitter: 'hades_andromeda',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1642722078,
    slug: 'hades-money',
    tvl: 0,
    chainTvls: {
      'Metis-pool2': 26282.214326544094,
      Metis: 0,
      pool2: 26282.214326544094,
      'Metis-staking': 1673.0748122823989,
      staking: 1673.0748122823989,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1673.0748122823989,
    pool2: 26282.214326544094,
    mcap: 0,
  },
  {
    id: '1297',
    name: 'BloXmove',
    address: '0x38d9eb07a7b8df7d86f440a4a5c4a4c1a27e1a08',
    symbol: 'BLXM',
    url: 'https://bloxmove.com',
    description: 'We are a shared business to business mobility blockchain platform',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/bloxmove.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'bloxmove-erc20',
    cmcId: '12886',
    category: 'Services',
    chains: ['Binance', 'Ethereum'],
    module: 'bloxmove/index.js',
    twitter: 'BloXmove',
    listedAt: 1642724255,
    slug: 'bloxmove',
    tvl: 0,
    chainTvls: {
      pool2: 463881.4710809464,
      'Binance-pool2': 298103.24680888385,
      'Ethereum-pool2': 165778.2242720625,
      'Binance-staking': 52117.875403131045,
      'Ethereum-staking': 44653.83165899516,
      staking: 96771.7070621262,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 96771.7070621262,
    pool2: 463881.4710809464,
    fdv: 47522984,
    mcap: 4152397,
  },
  {
    id: '1316',
    name: 'Specter Finance',
    address: 'fantom:0x2B7c5841997275d2D548cE9b19D54B655725EBFc',
    symbol: 'SPECTER',
    url: 'https://specterfinance.xyz/',
    description: 'An algorithmic token pegged to $FTM on the Fantom Opera network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/specter-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: 'specter-finance/index.js',
    twitter: 'specter_finance',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1643310364,
    slug: 'specter-finance',
    tvl: 0,
    chainTvls: {
      pool2: 9968.564573823714,
      'Fantom-staking': 767.8483962415551,
      'Fantom-pool2': 9968.564573823714,
      staking: 767.8483962415551,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 767.8483962415551,
    pool2: 9968.564573823714,
  },
  {
    id: '1321',
    name: 'Comfy Money',
    address: 'harmony:0x702f78E81Cf3DfaE89648b5a9e2e1aa8db1De546',
    symbol: 'COMFY',
    url: 'https://comfy.money',
    description:
      'Comfy Money is an algorithmic stablecoin on Harmony, intended to be pegged to the price of $ONE via the seigniorage shares model.',
    chain: 'Harmony',
    logo: 'https://icons.llama.fi/comfy-money.svg',
    audits: '0',
    audit_note: null,
    gecko_id: 'comfy',
    cmcId: '17638',
    category: 'Algo-Stables',
    chains: ['Harmony'],
    module: 'comfymoney/index.js',
    twitter: 'VenomDAO',
    oracles: ['TWAP'],
    forkedFrom: ['Tomb Finance'],
    listedAt: 1643326847,
    slug: 'comfy-money',
    tvl: 0,
    chainTvls: {
      'Harmony-pool2': 1620433.8879789228,
      pool2: 1620433.8879789228,
      'Harmony-staking': 222106.8041950105,
      Harmony: 0,
      'Harmony-Treasury': 0.0008581579060466366,
      Treasury: 0.0008581579060466366,
      staking: 222106.8041950105,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 222106.8041950105,
    pool2: 1620433.8879789228,
    mcap: 1407454,
  },
  {
    id: '1323',
    name: 'Poopsicle',
    address: 'fantom:0x070eb1a48725622de867a7e3d1dd4f0108966ed1',
    symbol: 'POOP',
    url: 'https://www.poopsicle.wtf',
    description:
      "One of the purest meme tokens on the market. We aren't backed by gold, silver nor cashmere. We are however backed by our Metamorphosis, our best secret for now.",
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/poopsicle.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'poopsicle',
    cmcId: '16387',
    category: 'Yield',
    chains: ['Fantom'],
    module: 'poopsicle/index.js',
    twitter: 'poopsicle_crypt',
    listedAt: 1643394486,
    slug: 'poopsicle',
    tvl: 0,
    chainTvls: { pool2: 72814.4407304039, 'Fantom-pool2': 72814.4407304039, Fantom: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 72814.4407304039,
    fdv: 1073953,
    mcap: 0,
  },
  {
    id: '1325',
    name: 'Magik Finance',
    address: 'fantom:0x87a5c9b60a3aaf1064006fe64285018e50e0d020',
    symbol: 'MAGIK',
    url: 'https://magik.finance/',
    description: 'Algorithmic token pegged to $FTM on the Fantom Opera network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/magik-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: 'magik-finance/index.js',
    twitter: 'MagikDotFinance',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1643474952,
    slug: 'magik-finance',
    tvl: 0,
    chainTvls: {
      pool2: 74920.17026199104,
      'Fantom-staking': 29261.67500538503,
      'Fantom-pool2': 74920.17026199104,
      staking: 29261.67500538503,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 29261.67500538503,
    pool2: 74920.17026199104,
  },
  {
    id: '1326',
    name: 'Snowtomb',
    address: 'avax:0x9e6832d13b29d0b1c1c3465242681039b31c7a05',
    symbol: 'STOMB',
    url: 'https://snowtomb.com/',
    description:
      'The $STOMB algorithmic token serves as the backbone of a rapidly growing ecosystem aimed towards bringing liquidity and new use cases to the Avalanche network.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/snowtomb.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    module: 'snowtomb/index.js',
    twitter: 'snow_tomb',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1643475195,
    slug: 'snowtomb',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 4608.025228736956,
      pool2: 216859.5542360011,
      Avalanche: 0,
      'Avalanche-pool2': 216859.5542360011,
      staking: 4608.025228736956,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 4608.025228736956,
    pool2: 216859.5542360011,
  },
  {
    id: '1333',
    name: 'Oceanus',
    address: 'metis:0x41607272ce6f2a42732ae382f00f8f9ce68d78f3',
    symbol: 'SEA',
    url: 'https://oceanus.finance',
    description: 'An algorithmic stablecoin on Andromeda, pegged to the price of 1 METIS via seigniorage.',
    chain: 'Metis',
    logo: 'https://icons.llama.fi/oceanus.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'sea-oceanus',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Metis'],
    module: 'oceanus/index.js',
    twitter: '0xOceanus',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1643486590,
    slug: 'oceanus',
    tvl: 0,
    chainTvls: {
      Metis: 0,
      pool2: 71668.47704448979,
      'Metis-Treasury': 6323.349724400631,
      'Metis-pool2': 71668.47704448979,
      'Metis-staking': 361868.01229568344,
      Treasury: 6323.349724400631,
      staking: 361868.01229568344,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 361868.01229568344,
    pool2: 71668.47704448979,
    mcap: 0,
  },
  {
    id: '1335',
    name: 'ShiroSwap',
    address: 'bsc:0x4ddba615a7F6ee612d3a23C6882B698dFBbef7E7',
    symbol: 'SHIR',
    url: 'https://shirotoken.com',
    description: 'Decentralized Meme Shiro Tokens that grew into a vibrant ecosystem',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/shiroswap.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '16720',
    category: 'Dexes',
    chains: ['Binance'],
    module: 'shiroswap/index.js',
    twitter: 'ShiroTokens',
    forkedFrom: ['Uniswap'],
    listedAt: 1643486991,
    slug: 'shiroswap',
    tvl: 0,
    chainTvls: {
      'Binance-masterchef': 0,
      pool2: 0.174126929278975,
      masterchef: 0,
      'Binance-pool2': 0.174126929278975,
      Binance: 0,
      'Binance-staking': 14896.280828622585,
      staking: 14896.280828622585,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 14896.280828622585,
    pool2: 0.174126929278975,
  },
  {
    id: '1336',
    name: 'Unizen',
    address: '0xc52c326331e9ce41f04484d3b5e5648158028804',
    symbol: 'ZCX',
    url: 'https://www.unizen.io',
    description:
      'Unizen provides a simple interface that caters to all trading personas to acquire any asset, for the best fees across a multitude of liquidity pools',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/unizen.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'unizen',
    cmcId: '9263',
    category: 'Services',
    chains: ['Ethereum'],
    module: 'unizen/index.js',
    twitter: 'unizen_io',
    listedAt: 1643487164,
    slug: 'unizen',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      pool2: 555.6621396974439,
      'Ethereum-staking': 14881857.435058499,
      staking: 14881857.435058499,
      'Ethereum-pool2': 555.6621396974439,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 14881857.435058499,
    pool2: 555.6621396974439,
    mcap: 43788364,
  },
  {
    id: '1339',
    name: 'API3',
    address: '0x0b38210ea11411557c13457d4da7dc6ea731b88a',
    symbol: 'API3',
    url: 'https://api3.eth.link/#/',
    description:
      'The Web3 API Economy: Create trustless applications that interact with Web APIs to connect Smart Contracts to Real-World Data. API3 is leading the movement from legacy third-party oracle networks to first-party oracle solutions that deliver more security, efficiency, regulatory compliance, and simplicity',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/api3.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'api3',
    cmcId: '7737',
    category: 'Oracle',
    chains: ['Ethereum'],
    module: 'api3/index.js',
    twitter: 'API3DAO',
    audit_links: ['https://github.com/api3dao/api3-dao/tree/main/reports'],
    listedAt: 1643713220,
    slug: 'api3',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 194130578.34335014, staking: 194130578.34335014 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 194130578.34335014,
    mcap: 294080762,
  },
  {
    id: '1340',
    name: 'IceCream Finance',
    address: 'avax:0xAE21d31a6494829a9E4B2B291F4984AAE8121757',
    symbol: 'CREAM',
    url: 'https://icecreamfinance.app/',
    description: 'A next-generation algo stable coming to AVAX',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/icecream-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'icecream-finance',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    module: 'icecream-finance/index.js',
    twitter: 'IcecreamFinance',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1643774924,
    slug: 'icecream-finance',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 12740.063041863044,
      pool2: 97445.08743439961,
      Avalanche: 0,
      'Avalanche-pool2': 97445.08743439961,
      staking: 12740.063041863044,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 12740.063041863044,
    pool2: 97445.08743439961,
    fdv: 135455,
    mcap: 0,
  },
  {
    id: '1345',
    name: 'Moon Flower Farmers',
    address: 'aurora:0x78B65477bBa78fc11735801D559C386611d07529',
    symbol: 'MFF',
    url: 'https://moonflowerfarmers.com/',
    description: 'Moonflower farmers is a fork of the popular game sunflower farmers.',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/moonflowerfarmers.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Yield',
    chains: ['Aurora'],
    module: 'moonflowerfarmers/index.js',
    twitter: 'MoonFarmers',
    listedAt: 1643808748,
    slug: 'moon-flower-farmers',
    tvl: 0,
    chainTvls: { 'Aurora-staking': 1057.424498747395, Aurora: 0, staking: 1057.424498747395 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1057.424498747395,
  },
  {
    id: '1348',
    name: 'Athena Money',
    address: 'moonriver:0x5C2dA48241d3bE9626dd0c48081c76DBb6D1046E',
    symbol: 'OWL',
    url: 'https://www.athenamoney.org/',
    description:
      'The $OWL algorithmic token serves as the backbone of a rapidly growing ecosystem aimed towards bringing liquidity and new use cases to the Moonriver network.',
    chain: 'Moonriver',
    logo: 'https://icons.llama.fi/athena-money.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'athena-money-owl',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Moonriver'],
    module: 'athena-money/index.js',
    twitter: 'AthenaMoneyOrg',
    listedAt: 1643835204,
    slug: 'athena-money',
    tvl: 0,
    chainTvls: {
      'Moonriver-staking': 6933.7245313127805,
      Moonriver: 0,
      'Moonriver-pool2': 24632.937183910573,
      pool2: 24632.937183910573,
      staking: 6933.7245313127805,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 6933.7245313127805,
    pool2: 24632.937183910573,
    mcap: 0,
  },
  {
    id: '1350',
    name: 'TreasureDAO',
    address: '0xb0c7a3ba49c7a6eaba6cd4a96c55a1391070ac9a',
    symbol: 'MAGIC',
    url: 'https://www.treasure.lol/',
    description: 'Building bridges between metaverses with $MAGIC ✨and treasures',
    chain: 'Arbitrum',
    logo: 'https://icons.llama.fi/treasuredao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'magic',
    cmcId: '14783',
    category: 'Yield',
    chains: ['Arbitrum'],
    module: 'treasuredao/index.js',
    twitter: 'Treasure_DAO',
    listedAt: 1643836673,
    slug: 'treasuredao',
    tvl: 0,
    chainTvls: {
      pool2: 4703.116790656451,
      'Arbitrum-staking': 371521583.1694966,
      'Arbitrum-pool2': 4703.116790656451,
      staking: 371521583.1694966,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 371521583.1694966,
    pool2: 4703.116790656451,
    fdv: 1221618768,
    mcap: 168928322,
  },
  {
    id: '1354',
    name: 'Dogs of Elon',
    address: '0xf8e9f10c22840b613cda05a0c5fdb59a4d6cd7ef',
    symbol: 'DOE',
    url: 'https://dogsofelon.io',
    description:
      'DoE is a passion project that creates an alternative to CryptoPunks, it is inspired by both DOGE and SHIB and has 10,000 unique collectable characters for the meme coin community',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/dogs-of-elon.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dogsofelon',
    cmcId: '12800',
    category: 'Farm',
    chains: ['Ethereum', 'Arbitrum'],
    module: 'dogsofelon/index.js',
    twitter: 'dogsofelon',
    audit_links: ['https://www.certik.com/projects/dogs-of-elon'],
    listedAt: 1643882177,
    slug: 'dogs-of-elon',
    tvl: 0,
    chainTvls: {
      pool2: 23060.984083584215,
      'Ethereum-staking': 3193363.036966004,
      'Arbitrum-pool2': 23060.984083584215,
      staking: 3193363.036966004,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3193363.036966004,
    pool2: 23060.984083584215,
    fdv: 28580775,
    mcap: 4307053,
  },
  {
    id: '1360',
    name: 'Meld',
    address: 'cardano:asset1zvn33mj5kzgxtct7jr5qjyefu9ewk22xp0s0yw',
    symbol: 'MELD',
    url: 'https://www.meld.com',
    description:
      'MELD is the first DeFi, non-custodial, banking protocol. You can securely lend & borrow both crypto and fiat currencies with ease and stake your MELD tokens for APY.',
    chain: 'Cardano',
    logo: 'https://icons.llama.fi/meld.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'meld',
    cmcId: null,
    category: 'Lending',
    chains: ['Cardano'],
    module: 'meld/index.js',
    twitter: 'MELD_labs',
    listedAt: 1644087933,
    slug: 'meld',
    tvl: 0,
    chainTvls: { Cardano: 0, 'Cardano-staking': 80615154.8341535, staking: 80615154.8341535 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 80615154.8341535,
    fdv: 577973593,
    mcap: 0,
  },
  {
    id: '1361',
    name: 'QAO',
    address: '0x3402e15b3ea0f1aec2679c4be4c6d051cef93953',
    symbol: 'QAO',
    url: 'https://www.qao.io',
    description:
      "QAO: QualiFied. Autonomous. Organization. is a decentralized governance protocol for smart assets. QAO is a purpose-built solution for community-management of Rarechain. QAO is used to stake and vote in order to receive fees generated from Rarechain's API including also Rareswap and Rarepool, as well other Reward Sources built by QAO community. It's main function is to vote for the system's parameters and management decisions.",
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/qao.jpg',
    audits: '2',
    audit_note: null,
    gecko_id: 'qao',
    cmcId: '10962',
    category: 'Indexes',
    chains: ['Ethereum'],
    module: 'qao/index.js',
    twitter: 'QAOdotio',
    audit_links: ['https://github.com/TechRate/Smart-Contract-Audits/blob/main/Rarechain.pdf'],
    oracles: ['Chainlink', 'Internal'],
    listedAt: 1644122822,
    slug: 'qao',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 227978.02217343258, staking: 227978.02217343258 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 227978.02217343258,
    mcap: 1811845,
  },
  {
    id: '1369',
    name: 'Velhalla',
    address: 'bsc:0x8d9fb713587174ee97e91866050c383b5cee6209',
    symbol: 'SCAR',
    url: 'https://velhalla.io',
    description: 'Velhalla - The Velas MetaVerse  Viking mythology meets a hyper-futuristic universe.',
    chain: 'Velas',
    logo: 'https://icons.llama.fi/velhalla.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'velhalla',
    cmcId: '13250',
    category: 'Gaming',
    chains: ['Velas'],
    module: 'velhalla.js',
    twitter: 'velhallaproject',
    language: 'Solidity',
    listedAt: 1644230549,
    slug: 'velhalla',
    tvl: 0,
    chainTvls: { 'Velas-staking': 493046.6334308259, Velas: 0, staking: 493046.6334308259 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 493046.6334308259,
    fdv: 84332854,
    mcap: 2129405,
  },
  {
    id: '1371',
    name: 'Ripae',
    address: 'fantom:0x112df7e3b4b7ab424f07319d4e92f41e6608c48b',
    symbol: 'pFTM',
    url: 'https://ripae.finance/',
    description: 'New generation of Algorithimic Stable Coin based on Cross Chain Implementation.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/ripae.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Avalanche', 'Fantom'],
    module: 'ripae/index.js',
    twitter: 'ripaefinance',
    language: 'Solidity',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1644240204,
    slug: 'ripae',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 4368286.668255809,
      pool2: 8942674.182025172,
      Avalanche: 0,
      'Fantom-staking': 2070866.5085324564,
      'Avalanche-pool2': 4533623.355571014,
      'Fantom-pool2': 4409050.826454157,
      Fantom: 0,
      staking: 6439153.176788265,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 6439153.176788265,
    pool2: 8942674.182025172,
  },
  {
    id: '1374',
    name: 'Gensokishi',
    address: 'polygon:0xa3c322ad15218fbfaed26ba7f616249f7705d945',
    symbol: 'MV',
    url: 'https://genso.game',
    description: 'GensoKishi Online, the only GameFi with a 13 year history.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/gensokishi.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'gensokishis-metaverse',
    cmcId: '17704',
    category: 'Gaming',
    chains: ['Polygon'],
    module: 'gensokishi/index.js',
    twitter: 'genso_meta',
    listedAt: 1644435089,
    slug: 'gensokishi',
    tvl: 0,
    chainTvls: { Polygon: 0, 'Polygon-staking': 35332829.664130434, staking: 35332829.664130434 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 35332829.664130434,
    mcap: 0,
  },
  {
    id: '1379',
    name: 'Based Finance',
    address: 'fantom:0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    symbol: 'BASED',
    url: 'https://basedfinance.io',
    description: 'An innovative fork of tomb.finance, pegged to the price of 1 TOMB via seigniorage.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/based-finance.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'based-finance',
    cmcId: '17954',
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    module: 'based-finance/index.js',
    twitter: 'BasedFinance_io',
    language: 'Solidity',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1644443064,
    slug: 'based-finance',
    tvl: 0,
    chainTvls: {
      pool2: 65881125.45639813,
      'Fantom-staking': 31791918.901307337,
      'Fantom-Treasury': 980231.707866542,
      'Fantom-pool2': 65881125.45639813,
      Treasury: 980231.707866542,
      Fantom: 0,
      staking: 31791918.901307337,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 31791918.901307337,
    pool2: 65881125.45639813,
    fdv: 54474,
    mcap: 0,
  },
  {
    id: '1383',
    name: 'Platinum Finance',
    address: 'fantom:0xDf15d33875AcF462a8746BF99396F6cCD50E0fFe',
    symbol: 'PLAT',
    url: 'https://platinumfi.app/',
    description:
      'PLAT is an algorithmic token that serves as the backbone of a rapidly growing ecosystem aimed towards bringing liquidity and new use cases to the Fantom Network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/platinum-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    module: 'platinum-finance/index.js',
    twitter: 'PlatinumDeFi',
    language: 'Solidity',
    forkedFrom: ['Tomb Finance'],
    listedAt: 1644484704,
    slug: 'platinum-finance',
    tvl: 0,
    chainTvls: {
      pool2: 5079.409066310646,
      'Fantom-staking': 121.99270189268243,
      'Fantom-pool2': 5079.409066310646,
      staking: 121.99270189268243,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 121.99270189268243,
    pool2: 5079.409066310646,
  },
  {
    id: '1390',
    name: 'ProjectX',
    address: 'avax:0x9e20af05ab5fed467dfdd5bb5752f7d5410c832e',
    symbol: 'PXT2',
    url: 'https://projectx.financial/#home',
    description:
      'Project X, was formed by the 100 Founders - a mature group of corporate executives who are supported by highly capable and intelligent young energy to create, nurture, and grow a node type project to be massively successful.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/projectx.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'project-x-nodes',
    cmcId: '17080',
    category: 'Yield',
    chains: ['Avalanche'],
    oracles: [],
    module: 'projectx/index.js',
    twitter: 'ProjectXNodes',
    listedAt: 1644528550,
    slug: 'projectx',
    tvl: 0,
    chainTvls: { Avalanche: 0, pool2: 712614.4834329904, 'Avalanche-pool2': 712614.4834329904 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 712614.4834329904,
    fdv: 8569233,
    mcap: 0,
  },
  {
    id: '1391',
    name: 'Thor Financial',
    address: 'avax:0x8f47416cae600bccf9530e9f3aeaa06bdd1caa79',
    symbol: 'THOR',
    url: 'https://www.thor.financial/#',
    description: "Gain passive income by leveraging THOR's Financial multi-chain yield-farming protocol",
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/thor-financial.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'thor',
    cmcId: '15789',
    category: 'Yield',
    chains: ['Avalanche'],
    oracles: [],
    module: 'thorfi/index.js',
    twitter: 'thornodes',
    listedAt: 1644528769,
    slug: 'thor-financial',
    tvl: 0,
    chainTvls: { pool2: 2877337.264152403, 'Avalanche-pool2': 2877337.264152403 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 2877337.264152403,
    fdv: 434518581,
    mcap: 0,
  },
  {
    id: '1392',
    name: 'VaporFi',
    address: 'avax:0x83a283641c6b4df383bcddf807193284c84c5342',
    symbol: 'VPND',
    url: 'https://app.vapornodes.finance',
    description: 'Building a robust #DeFi treasury with your favorite projects.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/vaporfi.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'vapornodes',
    cmcId: '16517',
    category: 'Yield',
    chains: ['Avalanche'],
    oracles: [],
    module: 'vaporfi/index.js',
    twitter: 'VaporNodes',
    listedAt: 1644529180,
    slug: 'vaporfi',
    tvl: 0,
    chainTvls: { 'Avalanche-Treasury': 27364769.782855846, Avalanche: 0, Treasury: 27364769.782855846 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    fdv: 27306783,
    mcap: 0,
  },
  {
    id: '1393',
    name: 'Basis Markets',
    address: 'solana:Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa',
    symbol: 'BASIS',
    url: 'https://basis.markets',
    description:
      'A decentralised liquidity pool owned by you, powered by our Basis Trade Engine. Next-level returns. Delta-neutral yield. Real-time insights.',
    chain: 'Solana',
    logo: 'https://icons.llama.fi/basis-markets.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'basis-markets',
    cmcId: '15779',
    category: 'Dexes',
    chains: ['Solana'],
    oracles: [],
    forkedFrom: [],
    module: 'basis-market/index.js',
    twitter: 'basismarkets',
    language: 'Rust',
    listedAt: 1644538201,
    openSource: false,
    slug: 'basis-markets',
    tvl: 0,
    chainTvls: { 'Solana-staking': 38408870.99746805, Solana: 0, staking: 38408870.99746805 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 38408870.99746805,
    fdv: 60612865,
    mcap: 0,
  },
  {
    id: '1411',
    name: 'Manarium',
    address: 'bsc:0xc80a0a55caf6a7bfb4ee22f9380c4077312c4a35',
    symbol: 'ARI',
    url: 'https://www.manarium.com',
    description: 'Manarium is a unique blockchain gaming platform with play to earn games.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/manarium.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'manarium',
    cmcId: '16474',
    category: 'Gaming',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'manarium/index.js',
    twitter: 'manarium_gg',
    audit_links: [
      'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_Manarium.pdf',
    ],
    listedAt: 1644868847,
    slug: 'manarium',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 8242.037884169253, staking: 8242.037884169253 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 8242.037884169253,
    mcap: 0,
  },
  {
    id: '1414',
    name: 'Dibs Money',
    address: 'bsc:0xfd81ef21ea7cf1dc00e9c6dd261b4f3be0341d5c',
    symbol: 'DIBS',
    url: 'https://www.dibs.money/',
    description: 'Fork of Tomb.Finance on the Binance Smart Chain with a 1000:1 peg to BNB.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/dibs-money.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'dibs-money',
    cmcId: '16756',
    category: 'Algo-Stables',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'dibs-money/index.js',
    twitter: 'DibsMoney',
    audit_links: [
      'https://github.com/interfinetwork/smart-contract-audits/blob/audit-updates/DibsMoney_AuditReport_InterFi.pdf',
    ],
    listedAt: 1644886953,
    slug: 'dibs-money',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 332638.26055262215,
      pool2: 735429.8158478886,
      'Binance-pool2': 735429.8158478886,
      staking: 332638.26055262215,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 332638.26055262215,
    pool2: 735429.8158478886,
    fdv: 74420286,
    mcap: 0,
  },
  {
    id: '1415',
    name: 'Partial Finance',
    address: 'fantom:0x9486fDA4C1192db69a08CA7235E2E6bAf31B467B',
    symbol: 'PARTIAL',
    url: 'https://partial.finance',
    description: 'An algorithmic stablecoin on Fantom Opera, pegged to the price of 1/2 $FTM (0.5) via seigniorage.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/partial-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'partial',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'partialfinance/index.js',
    twitter: 'PartialFinance',
    listedAt: 1644969685,
    slug: 'partial-finance',
    tvl: 0,
    chainTvls: {
      pool2: 18157.65415283196,
      'Fantom-staking': 1641.922134577802,
      'Fantom-Treasury': 0.1611747218673154,
      'Fantom-pool2': 18157.65415283196,
      Treasury: 0.1611747218673154,
      Fantom: 0,
      staking: 1641.922134577802,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1641.922134577802,
    pool2: 18157.65415283196,
    mcap: 0,
  },
  {
    id: '1418',
    name: 'Draco Finance',
    address: 'fantom:0x37863ea4bf6ef836bC8bE909221BAF09A2aF43d7',
    symbol: 'DRACO',
    url: 'https://www.draco.finance/',
    description: 'Algorithmic stable coin currently pegged to the value of 1 $FTM.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/draco-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'draco-finance/index.js',
    twitter: 'DracoFinance',
    language: 'Solidity',
    listedAt: 1644972460,
    slug: 'draco-finance',
    tvl: 0,
    chainTvls: {
      pool2: 2827.4911983663824,
      'Fantom-staking': 134.18024146764088,
      'Fantom-pool2': 2827.4911983663824,
      staking: 134.18024146764088,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 134.18024146764088,
    pool2: 2827.4911983663824,
  },
  {
    id: '1420',
    name: 'Arable Protocol',
    address: 'avax:0x00ee200df31b869a321b10400da10b561f3ee60d',
    symbol: 'ACRE',
    url: 'https://arable.finance',
    description: 'World’s First Synthetic Farming Protocol.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/arable-protocol.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'arable-protocol',
    cmcId: null,
    category: 'Synthetics',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: [],
    audit_links: [],
    module: 'arable-protocol/index.js',
    twitter: 'ArableProtocol',
    language: 'Solidity',
    listedAt: 1644992134,
    slug: 'arable-protocol',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 91846.85070329953,
      pool2: 196630.90878188453,
      Avalanche: 0,
      'Avalanche-pool2': 196630.90878188453,
      staking: 91846.85070329953,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 91846.85070329953,
    pool2: 196630.90878188453,
    fdv: 63407999,
    mcap: 5830301,
  },
  {
    id: '1422',
    name: 'Winkyverse',
    address: 'bsc:0xb160A5F19ebccd8E0549549327e43DDd1D023526',
    symbol: 'WNK',
    url: 'https://getwinkies.com',
    description: 'The First Global Education Games Metaverse',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/winkyverse.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'the-winkyverse',
    cmcId: '16652',
    category: 'Staking',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'winkyverse/index.js',
    twitter: 'thewinkies1',
    audit_links: ['https://docsend.com/view/v9fk2ajkksjd9xcz'],
    listedAt: 1645036853,
    slug: 'winkyverse',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 1070299.5658010799, staking: 1070299.5658010799 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1070299.5658010799,
    fdv: 91734457,
    mcap: 0,
  },
  {
    id: '1425',
    name: 'Croissant Games',
    address: 'cronos:0xa0C3c184493f2Fae7d2f2Bd83F195a1c300FA353',
    symbol: 'CROISSANT',
    url: 'https://croissant.games',
    description:
      "Unlike traditional games that operate in black boxes, Croissant Games runs on smart contracts that are fair, transparent, non-custodian, and immutable. Furthermore, we have a proprietary gasless model utilizing EIP-712, meaning that playing any of our games will not require any gas fees from the player's end!",
    chain: 'Cronos',
    logo: 'https://icons.llama.fi/croissant-games.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'croissant-games',
    cmcId: null,
    category: 'Staking',
    chains: ['Cronos'],
    oracles: [],
    forkedFrom: [],
    module: 'croissant/index.js',
    twitter: 'GamesCroissant',
    listedAt: 1645046168,
    slug: 'croissant-games',
    tvl: 0,
    chainTvls: {
      Cronos: 0,
      pool2: 301818.9503480594,
      'Cronos-pool2': 301818.9503480594,
      'Cronos-staking': 371616.77973711264,
      staking: 371616.77973711264,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 371616.77973711264,
    pool2: 301818.9503480594,
    mcap: 0,
  },
  {
    id: '1428',
    name: 'StarCrazy',
    address: 'iotex:0x17df9fbfc1cdab0f90eddc318c4f6fcada730cf2',
    symbol: 'GFT',
    url: 'https://starcrazy.com',
    description:
      'StarCrazy is the NFT and blockchain Play-To-Earn game. Collect, fuse, mine, and earn. GFT is its main game currency.',
    chain: 'IoTeX',
    logo: 'https://icons.llama.fi/starcrazy.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'game-fantasy-token',
    cmcId: null,
    category: 'Gaming',
    chains: ['IoTeX'],
    oracles: [],
    forkedFrom: [],
    module: 'gft/index.js',
    twitter: 'StarCrazyGame',
    listedAt: 1645133393,
    slug: 'starcrazy',
    tvl: 0,
    chainTvls: { IoTeX: 0, pool2: 1538081.0241793743, 'IoTeX-pool2': 1538081.0241793743 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    pool2: 1538081.0241793743,
    fdv: 125790890,
    mcap: 8543885,
  },
  {
    id: '1430',
    name: 'Kommunitas',
    address: 'polygon:0xC004e2318722EA2b15499D6375905d75Ee5390B8',
    symbol: 'KOM',
    url: 'https://kommunitas.net',
    description:
      'Kommunitas is a decentralized and tier-less Launchpad. Kommunitas is the solution for Multi Chain oriented projects. Kommunitas welcomes project from various blockchain like Polygon, BSC, Ethereum, Avalanche, Solana, etc.',
    chain: 'Polygon',
    logo: 'https://icons.llama.fi/kommunitas.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'kommunitas',
    cmcId: '10403',
    category: 'Launchpad',
    chains: ['Polygon'],
    oracles: [],
    forkedFrom: [],
    module: 'kommunitas/index.js',
    twitter: 'Kommunitas1',
    audit_links: [
      'https://67362912-files.gitbook.io/~/files/v0/b/gitbook-28427.appspot.com/o/assets%2F-Mcc99YHj6X7apRGJDII%2F-MgGmu57PGs3UkrB2GsG%2F-MgGoyNwBZNhWmBNpIi0%2FKommunitas%20Staking%20Contract%20Audit%20Report.pdf?alt=media&token=6325c456-fb79-4298-81d8-14c924b57cb2',
    ],
    listedAt: 1645139153,
    slug: 'kommunitas',
    tvl: 0,
    chainTvls: { Polygon: 0, 'Polygon-staking': 2428170.16851332, staking: 2428170.16851332 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 2428170.16851332,
    fdv: 9266824,
    mcap: 5648106,
  },
  {
    id: '1431',
    name: 'X2Y2',
    address: '0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9',
    symbol: 'X2Y2',
    url: 'https://x2y2.io',
    description: 'The decentralized NFT marketplace. By the people, for the people.',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/x2y2.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'x2y2',
    cmcId: '18106',
    category: 'Staking',
    chains: ['Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'x2y2/index.js',
    twitter: 'the_x2y2',
    listedAt: 1645139307,
    slug: 'x2y2',
    tvl: 0,
    chainTvls: { Ethereum: 0, 'Ethereum-staking': 36933192.304554574, staking: 36933192.304554574 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 36933192.304554574,
    fdv: 268510096,
    mcap: 0,
  },
  {
    id: '1440',
    name: 'Polaris Finance',
    address: 'aurora:0xf0f3b9Eee32b1F490A4b8720cf6F005d4aE9eA86',
    symbol: 'POLAR',
    url: 'https://polarisfinance.io',
    description: 'The first algorithmic stablecoin on Aurora chain, pegged to the price of 1 NEAR via seigniorage.',
    chain: 'Aurora',
    logo: 'https://icons.llama.fi/polaris-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'polar-token',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Aurora'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'polaris-finance/index.js',
    twitter: 'PolarisFinance_',
    listedAt: 1645384779,
    slug: 'polaris-finance',
    tvl: 0,
    chainTvls: {
      'Aurora-staking': 445008.1364328279,
      Aurora: 0,
      pool2: 933675.3441535527,
      'Aurora-pool2': 933675.3441535527,
      staking: 445008.1364328279,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 445008.1364328279,
    pool2: 933675.3441535527,
    mcap: 0,
  },
  {
    id: '1441',
    name: 'Peel Defi',
    address: 'bsc:0xEc734Dbb16acc25542d8714fe29CCB401d8f6E17',
    symbol: 'PEEL',
    url: 'https://peeldefi.com/',
    description: 'An algorithmic stablecoin on BSC, pegged to the price of 1 BANANA via seigniorage.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/peel-defi.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'peeldefi/index.js',
    twitter: 'PeelDefi',
    listedAt: 1645395923,
    slug: 'peel-defi',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 146.25776921167628,
      pool2: 7608.381251327633,
      'Binance-pool2': 7608.381251327633,
      staking: 146.25776921167628,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 146.25776921167628,
    pool2: 7608.381251327633,
  },
  {
    id: '1446',
    name: 'FDoge Finance',
    address: 'fantom:0xEb0a2D1b1a33D95204af5d00f65FD9e349419878',
    symbol: 'FDOGE',
    url: 'https://fdoge.finance/',
    description:
      'FDoge Finance is a combination of algorithmics stablecoin on Fantom Opera network and meme token favored by Shiba Inus worldwide. FDoge’s token, FDOGE, aims to be the backbone, main medium of exchange and main source of liquidity for the Fantom network and be frequent owned by Fantom users and Doge lovers.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/fdoge-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'fdoge/index.js',
    twitter: 'FDogefinance',
    listedAt: 1645523736,
    slug: 'fdoge-finance',
    tvl: 0,
    chainTvls: {
      pool2: 32.623545813147636,
      'Fantom-staking': 5.38071915321059,
      'Fantom-pool2': 32.623545813147636,
      staking: 5.38071915321059,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 5.38071915321059,
    pool2: 32.623545813147636,
  },
  {
    id: '1449',
    name: 'ImpactMarket',
    address: 'celo:0x46c9757c5497c5b1f2eb73ae79b6b67d119b0b58',
    symbol: 'PACT',
    url: 'https://www.impactmarket.com',
    description:
      'ImpactMarket is a Decentralized poverty alleviation protocol that allows the creation and distribution of unconditional basic income between communities and their beneficiaries.',
    chain: 'Celo',
    logo: 'https://icons.llama.fi/impactmarket.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'impactmarket',
    cmcId: '16260',
    category: 'Yield',
    chains: ['Celo'],
    oracles: [],
    forkedFrom: [],
    module: 'impact-market/index.js',
    twitter: 'IPCTmarket',
    listedAt: 1645572803,
    slug: 'impactmarket',
    tvl: 0,
    chainTvls: { 'Celo-Treasury': 0, 'Celo-staking': 3641380, Celo: 0, Treasury: 0, staking: 3641380 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3641380,
    fdv: 18661613,
    mcap: 2267385,
  },
  {
    id: '1463',
    name: 'Comet Finance',
    address: 'fantom:0xbeBB20cD6364998b8cAfc3d6B86f1ca6363fF910',
    symbol: 'COMET',
    url: 'https://cometfinance.io/',
    description:
      'The $COMET algorithmic token serves as the backbone of a rapidly growing ecosystem aimed towards bringing liquidity and new use cases to the fantom network.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/comet-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'comet-finance/index.js',
    twitter: 'CometFinance88',
    listedAt: 1645666003,
    slug: 'comet-finance',
    tvl: 0,
    chainTvls: {
      pool2: 17226.919164120784,
      'Fantom-staking': 638.102800673158,
      'Fantom-pool2': 17226.919164120784,
      staking: 638.102800673158,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 638.102800673158,
    pool2: 17226.919164120784,
  },
  {
    id: '1476',
    name: 'Wrapped ILCOIN',
    address: '0xc98a910ede52e7d5308525845f19e17470dbccf7',
    symbol: 'WILC',
    url: 'https://wilc.finance',
    description:
      'Wrapped ILCOIN (WILC) is an ERC-20 token that is tradable directly with ILCOIN asset at a 1:1 ratio. WILC allow the development of the Decentralized Cloud Blockchain (DCB) smart contracts system that stores large amounts of data in a decentralized way and can be used on DEXs for DeFi yield farming',
    chain: 'Ethereum',
    logo: 'https://icons.llama.fi/wrapped-ilcoin.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: '8447',
    category: 'Yield',
    chains: ['Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'wrapped-ilcoin/index.js',
    twitter: 'W_ILC_',
    listedAt: 1645824588,
    slug: 'wrapped-ilcoin',
    tvl: 0,
    chainTvls: { Ethereum: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
  },
  {
    id: '1481',
    name: '2Doge Finance',
    address: 'fantom:0xDD057bCcB72982753266A903Feda154608e54468',
    symbol: '2DOGE',
    url: 'https://2doge.finance',
    description:
      '2Doge Finance is the second token in the ecosystem of $FDOGE Finance which is a combination of algorithmics stablecoin on Fantom Opera network and meme token favored by Shiba Inus worldwide.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/2doge-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: '2doge/index.js',
    twitter: 'FDogefinance',
    listedAt: 1646044539,
    slug: '2doge-finance',
    tvl: 0,
    chainTvls: {
      pool2: 15.258419459904141,
      'Fantom-staking': 4.387310957909872,
      'Fantom-Treasury': 0,
      'Fantom-pool2': 15.258419459904141,
      Treasury: 0,
      Fantom: 0,
      staking: 4.387310957909872,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 4.387310957909872,
    pool2: 15.258419459904141,
  },
  {
    id: '1482',
    name: 'Quartz DeFi',
    address: 'harmony:0xb9E05B4C168B56F73940980aE6EF366354357009',
    symbol: 'QUARTZ',
    url: 'https://quartz-defi.one/',
    description: 'Bringing you $QUARTZ & $AMES. Algo tokens pegged to $UST on Harmony & BNB Chain.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/quartz-defi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'quartz-defi',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Harmony', 'Binance'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'quartzdefi/index.js',
    twitter: 'quartz_defi',
    audit_links: ['https://github.com/0xGuard-com/audit-reports/blob/master/quartz/Quartz_final-audit-report.pdf'],
    listedAt: 1646094448,
    slug: 'quartz-defi',
    tvl: 0,
    chainTvls: {
      'Harmony-pool2': 22985.542995552816,
      pool2: 79669.17876478544,
      'Harmony-staking': 2835.3316769491553,
      Harmony: 0,
      'Binance-pool2': 56683.63576923263,
      Binance: 0,
      'Binance-staking': 50690.88857218257,
      staking: 53526.22024913173,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 53526.22024913173,
    pool2: 79669.17876478544,
    mcap: 0,
  },
  {
    id: '1498',
    name: 'pDollar',
    address: 'fantom:0xb9d62c829fbf7eaff1eba4e50f3d0480b66c1748',
    symbol: 'PDO',
    url: 'https://pdo.partial.finance',
    description: 'An algorithmic stablecoin on Fantom Opera, pegged to the price of ½ $DAI (0.5) via seigniorage.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/pdollar.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'pdollar',
    cmcId: '18390',
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'pdollar/index.js',
    twitter: 'PartialFinance',
    listedAt: 1646251327,
    slug: 'pdollar',
    tvl: 0,
    chainTvls: {
      pool2: 15227.915877892476,
      'Fantom-staking': 3921.9620282106744,
      'Fantom-Treasury': 317.5446020696916,
      'Fantom-pool2': 15227.915877892476,
      Treasury: 317.5446020696916,
      Fantom: 0,
      staking: 3921.9620282106744,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3921.9620282106744,
    pool2: 15227.915877892476,
    mcap: 0,
  },
  {
    id: '1503',
    name: 'Snowyowl',
    address: 'avax:0x1fE4869f2C5181b9CD780a7E16194FA2c4C4293D',
    symbol: 'SNO',
    url: 'https://snowyowl.finance/',
    description:
      ' Welcome to Snowy Owl, home to the first algorithmic token fork pegged to $JOE on the Avalanche network with innovating features.',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/floor-dao.jpg',
    audits: '0',
    audit_note: null,
    gecko_id: 'snowy-owl',
    cmcId: '18238',
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'snowyowl/index.js',
    twitter: 'SnowyOwlFinance',
    listedAt: 1646391286,
    slug: 'snowyowl',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 108399.22928080766,
      pool2: 29874.80241880342,
      Avalanche: 0,
      'Avalanche-pool2': 29874.80241880342,
      staking: 108399.22928080766,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 108399.22928080766,
    pool2: 29874.80241880342,
    mcap: 0,
  },
  {
    id: '1506',
    name: 'Pulse DAO',
    address: 'fantom:0x6A5E24E62135e391B6dd77A80D38Ee5A40834167',
    symbol: 'PLD',
    url: 'https://pulsedao.finance/',
    description: 'The $PLD algorithmic token serves as the backbone of a rapidly growing crosschain ecosystem.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/pulse-dao.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'pulsedao/index.js',
    twitter: 'ThePULSE_DAO',
    listedAt: 1646425665,
    slug: 'pulse-dao',
    tvl: 0,
    chainTvls: {
      pool2: 29010.26674820368,
      'Fantom-staking': 1214.0890974019349,
      'Fantom-pool2': 29010.26674820368,
      staking: 1214.0890974019349,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 1214.0890974019349,
    pool2: 29010.26674820368,
  },
  {
    id: '1507',
    name: 'Zombi Finance',
    address: 'fantom:0xda5db6c2a2fd53a2d3fc4246bbd8acce5f93bae1',
    symbol: 'ZOMB',
    url: 'https://zombi.finance/',
    description:
      'The $ZOMB algorithmic token serves as the backbone of a rapidly growing ecosystem aimed towards bringing liquidity.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/zombi-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'zombi/index.js',
    twitter: 'ZombiFinance',
    listedAt: 1646426722,
    slug: 'zombi-finance',
    tvl: 0,
    chainTvls: {
      pool2: 428.1436465407498,
      'Fantom-staking': 4.840394343385623,
      'Fantom-pool2': 428.1436465407498,
      staking: 4.840394343385623,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 4.840394343385623,
    pool2: 428.1436465407498,
  },
  {
    id: '1508',
    name: 'Code 7 Finance',
    address: 'fantom:0xF77864FCFfeC4598813E3378681c9330B771cA88',
    symbol: 'CODE7',
    url: 'https://www.code7.finance',
    description: 'A Decentralized Real Estate Investment Firm, built from the ground up via a Tomb fork.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/code-7-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'code7/index.js',
    twitter: 'code7finance',
    listedAt: 1646426615,
    slug: 'code-7-finance',
    tvl: 0,
    chainTvls: {
      pool2: 1151880.8118269113,
      'Fantom-staking': 871901.0207647692,
      'Fantom-pool2': 1151880.8118269113,
      staking: 871901.0207647692,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 871901.0207647692,
    pool2: 1151880.8118269113,
  },
  {
    id: '1510',
    name: 'MiniVerse Finance',
    address: 'fantom:0x57976c467608983513c9355238dc6de1b1abbcca',
    symbol: 'MVDOLLAR',
    url: 'https://app.mvfinance.club',
    description: 'MiniVerse Finance is an algorithmic stable coin protocol pegged 1:1 to USDC on Fantom.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/miniverse-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'miniverse-dollar',
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'miniversefinance/index.js',
    twitter: 'miniverse_fin',
    listedAt: 1646428683,
    slug: 'miniverse-finance',
    tvl: 0,
    chainTvls: {
      pool2: 789478.8920259854,
      'Fantom-staking': 848534.0332031398,
      'Fantom-pool2': 789478.8920259854,
      staking: 848534.0332031398,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 848534.0332031398,
    pool2: 789478.8920259854,
    mcap: 0,
  },
  {
    id: '1511',
    name: 'Seedify',
    address: 'bsc:0x477bc8d23c634c154061869478bce96be6045d12',
    symbol: 'SFUND',
    url: 'https://launchpad.seedify.fund/',
    description: 'Seedify is an incubator and launchpad for blockchain games, and gamers.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/seedify.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'seedify-fund',
    cmcId: '8972',
    category: 'Launchpad',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'seedify/index.js',
    twitter: 'SeedifyFund',
    audit_links: [
      'https://assets.website-files.com/5f99eb79d508ca853be5f2e8/61cadee5f08b9f251e0e719e_Seedify%20audit%20report.pdf',
    ],
    listedAt: 1646455528,
    slug: 'seedify',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      'Binance-staking': 100599916.03919679,
      pool2: 25316062.61249216,
      'Binance-pool2': 25316062.61249216,
      staking: 100599916.03919679,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 100599916.03919679,
    pool2: 25316062.61249216,
    mcap: 136070819,
  },
  {
    id: '1513',
    name: 'Zomb2 Finance',
    address: 'fantom:0xA87bcE04d4a396DF40cC1be3a21CF04592C08290',
    symbol: 'ZOMB2',
    url: 'https://zomb2.finance',
    description:
      'Zombiverse 2nd Token - Zomb2, aims to be the second choice, alternative medium of exchange and cover source of liquidity for the Fantom network and be frequent owned by Fantom users and Zomb lovers.',
    chain: 'Fantom',
    logo: 'https://icons.llama.fi/zomb2-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Fantom'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'zomb2/index.js',
    twitter: 'ZombiFinance',
    listedAt: 1646502927,
    slug: 'zomb2-finance',
    tvl: 0,
    chainTvls: {
      pool2: 374.3338066587396,
      'Fantom-staking': 6.104642456099116,
      'Fantom-pool2': 374.3338066587396,
      staking: 6.104642456099116,
      Fantom: 0,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 6.104642456099116,
    pool2: 374.3338066587396,
  },
  {
    id: '1519',
    name: 'CryptEx Locker',
    address: 'bsc:0x97a30C692eCe9C317235d48287d23d358170FC40',
    symbol: 'CRX',
    url: 'https://cryptexlock.me',
    description:
      'CryptEx Locker is part of CryptEx ecosystem which provide LP locker and Team Token Vesting services. CryptEx also providing staking service. Stake CRX to earn 50% of CryptEx locker platform fees.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/cryptex-locker.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'cryptex',
    cmcId: '8731',
    category: 'Staking',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'cryptex/index.js',
    twitter: 'ExLocker',
    listedAt: 1646855948,
    slug: 'cryptex-locker',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 301077.53903364, staking: 301077.53903364 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 301077.53903364,
    fdv: 659992,
    mcap: 560994,
  },
  {
    id: '1522',
    name: 'CakeTomb',
    address: 'bsc:0xAB4F3fC9831dBC77424269B9255fC1A082AC9840',
    symbol: 'CAKETOMB',
    url: 'https://caketomb.finance',
    description:
      'CakeTomb is a seigniorage algorithmic token on BNB Chain (BSC), bringing a new model that follow the price of the CAKE token.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/caketomb.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Binance'],
    oracles: ['Chainlink'],
    forkedFrom: ['Tomb Finance'],
    module: 'Caketomb/index.js',
    twitter: 'CakeTomb',
    listedAt: 1646783157,
    slug: 'caketomb',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 0, pool2: 0, 'Binance-pool2': 0, staking: 0 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 0,
    pool2: 0,
  },
  {
    id: '1527',
    name: 'Libero Financial',
    address: 'bsc:0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
    symbol: 'LIBERO',
    url: 'https://libero.financial/app?lang=en',
    description:
      'Libero Autostaking Protocol or LAP, is a new financial protocol that makes staking easier, and gives $LIBERO token holders the highest stable returns in crypto.',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/libero-financial.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'libero-financial',
    cmcId: '17776',
    category: 'Staking',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'libero/index.js',
    twitter: 'LiberoFinancial',
    audit_links: ['https://github.com/Rugfreecoins/Smart-Contract-Audits/blob/main/Libero%20Financial%20Token.pdf'],
    listedAt: 1646877651,
    slug: 'libero-financial',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 17133242.415072765, staking: 17133242.415072765 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 17133242.415072765,
    fdv: 128679913,
    mcap: 0,
  },
  {
    id: '1540',
    name: 'Polar Bear Finance',
    address: 'avax:0x83dC7F38F94c5D0e0dC3695330367acb2a764907',
    symbol: 'BEAR',
    url: 'https://polar-bear.finance/',
    description: 'Tomb fork on AVAX pegged to JOE',
    chain: 'Avalanche',
    logo: 'https://icons.llama.fi/polar-bear-finance.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Algo-Stables',
    chains: ['Avalanche'],
    oracles: [],
    forkedFrom: ['Tomb Finance'],
    module: 'polar-bear-finance/index.js',
    twitter: 'polarbearfi',
    listedAt: 1647129110,
    slug: 'polar-bear-finance',
    tvl: 0,
    chainTvls: {
      'Avalanche-staking': 50.45372940266581,
      pool2: 377.9455043352501,
      Avalanche: 0,
      'Avalanche-pool2': 377.9455043352501,
      staking: 50.45372940266581,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 50.45372940266581,
    pool2: 377.9455043352501,
  },
  {
    id: '1543',
    name: 'Drip',
    address: 'bsc:0x20f663cea80face82acdfa3aae6862d246ce0333',
    symbol: 'DRIP',
    url: 'https://drip.community',
    description:
      'The DRIP token captures the entire value of the Drip Network and makes it available to the entire BNB Community',
    chain: 'Binance',
    logo: 'https://icons.llama.fi/drip.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'drip-network',
    cmcId: '11093',
    category: 'Yield',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'drip/index.js',
    twitter: 'DRIPcommunity',
    listedAt: 1647236017,
    slug: 'drip',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 13259410.048336618, staking: 13259410.048336618 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 13259410.048336618,
    fdv: 34168507,
    mcap: 0,
  },
  {
    id: '1554',
    name: 'Kleros',
    address: '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d',
    symbol: 'PNK',
    url: 'https://kleros.io',
    description: 'Kleros is a decentralized arbitration service for the disputes of the new economy.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/kleros.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'kleros',
    cmcId: '3581',
    category: 'Staking',
    chains: ['Ethereum', 'xDai'],
    oracles: [],
    forkedFrom: [],
    module: 'kleros/index.js',
    twitter: 'Kleros_io',
    listedAt: 1647396444,
    slug: 'kleros',
    tvl: 0,
    chainTvls: {
      Ethereum: 0,
      xDai: 0,
      'Ethereum-staking': 15057984.599575883,
      staking: 16270013.059345739,
      'xDai-staking': 1212028.4597698569,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 16270013.059345739,
    mcap: 52605680,
  },
  {
    id: '1559',
    name: 'KDLaunch',
    address: 'kadena:-',
    symbol: 'KDL',
    url: 'https://www.kdlaunch.com/#/staking',
    description: 'The First Decentralized IDO Platform on the Kadena Blockchain',
    chain: 'Kadena',
    logo: 'https://icons.llama.fi/kdlaunch.png',
    audits: '0',
    audit_note: null,
    gecko_id: null,
    cmcId: null,
    category: 'Launchpad',
    chains: ['Kadena'],
    oracles: [],
    forkedFrom: [],
    module: 'kdlaunch/index.js',
    twitter: 'KdLaunch',
    listedAt: 1647474666,
    slug: 'kdlaunch',
    tvl: 0,
    chainTvls: { 'Kadena-staking': 3553766.951380344, Kadena: 0, staking: 3553766.951380344 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 3553766.951380344,
  },
  {
    id: '1565',
    name: 'RingFi',
    address: 'bsc:0x021988d2c89b1A9Ff56641b2F247942358FF05c9',
    symbol: 'RING',
    url: 'https://ringfi.io/',
    description:
      "The world's first Auto-Staking & Auto-Compounding 3.0 Protocol with Real Use Cases.Discover how wRING composability introduces RingFi protocol to Yield Farming, CEX trading, Lending, Borrowing, and more.",
    chain: 'Binance',
    logo: 'https://icons.llama.fi/ringfi.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'ring',
    cmcId: '1839',
    category: 'Staking',
    chains: ['Binance'],
    oracles: [],
    forkedFrom: [],
    module: 'ringfi/index.js',
    twitter: 'ringfiprotocol',
    audit_links: [
      'https://github.com/coinscope-co/audits/blob/main/wring/audit.pdf',
      'https://github.com/coinscope-co/audits/blob/main/ring/audit.pdf',
    ],
    listedAt: 1647811513,
    slug: 'ringfi',
    tvl: 0,
    chainTvls: { Binance: 0, 'Binance-staking': 715587.0409856001, staking: 715587.0409856001 },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 715587.0409856001,
    mcap: 3971639,
  },
  {
    id: '1576',
    name: 'MeowSwapFi',
    address: 'cardano:asset1eqydf3sjj00nw9qqqn9qd75purj9y285ueq69c',
    symbol: 'MEOW',
    url: 'https://meowswap.fi',
    description:
      'MeowSwap combines proprietary L2 scaling technology in conjunction with our DEX to finally offer the only working trading solution fully running on Cardano.',
    chain: 'Cardano',
    logo: 'https://icons.llama.fi/meowswapfi.png',
    audits: '0',
    audit_note: null,
    gecko_id: 'meowswap-token',
    cmcId: '17714',
    category: 'Dexes',
    chains: ['Cardano'],
    oracles: [],
    forkedFrom: [],
    module: 'meowswap.fi/index.js',
    twitter: 'Meow_Swap',
    listedAt: 1648061740,
    slug: 'meowswapfi',
    tvl: 0,
    chainTvls: { Cardano: 0 },
    change_1h: -100,
    change_1d: -100,
    change_7d: null,
    mcap: 0,
  },
  {
    id: '1583',
    name: 'Axl Inu',
    address: '0x25b24B3c47918b7962B3e49C4F468367F73CC0E0',
    symbol: 'AXL',
    url: 'https://www.axltoken.com',
    description:
      'AXL INU is being developed as a Decentralized Eco-System with a LaunchPad, Farming, Staking, NFT Marketplace, and a Metaverse.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/axl-inu.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'axl-inu',
    cmcId: '15853',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'axl-inu/index.js',
    twitter: 'AxlToken',
    audit_links: ['https://www.certik.com/projects/axl-token'],
    listedAt: 1648463714,
    slug: 'axl-inu',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      Ethereum: 0,
      'Binance-staking': 285456.5834998842,
      'Ethereum-staking': 256758.9586205085,
      staking: 542215.5421203927,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 542215.5421203927,
    mcap: 0,
  },
  {
    id: '340811',
    name: 'USDC Coin',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    symbol: 'USDC',
    url: 'https://www.centre.io/',
    description:
      'AXL INU is being developed as a Decentralized Eco-System with a LaunchPad, Farming, Staking, NFT Marketplace, and a Metaverse.',
    chain: 'Multi-Chain',
    logo: 'https://icons.llama.fi/axl-inu.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'usdc-coin',
    cmcId: '3408',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'axl-inu/index.js',
    twitter: 'AxlToken',
    audit_links: ['https://www.certik.com/projects/axl-token'],
    listedAt: 1648463714,
    slug: 'axl-inu',
    tvl: 0,
    chainTvls: {
      Binance: 0,
      Ethereum: 0,
      'Binance-staking': 285456.5834998842,
      'Ethereum-staking': 256758.9586205085,
      staking: 542215.5421203927,
    },
    change_1h: null,
    change_1d: null,
    change_7d: null,
    staking: 542215.5421203927,
    mcap: 0,
  },
  {
    id: '34081001',
    name: 'Tether',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    symbol: 'USDT',
    url: 'https://tether.to/',
    description: '',
    chain: 'Multi-Chain',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    audits: '2',
    audit_note: null,
    gecko_id: 'tether',
    cmcId: '825',
    category: 'Yield',
    chains: ['Binance', 'Ethereum'],
    oracles: [],
    forkedFrom: [],
    module: 'axl-inu/index.js',
    twitter: 'AxlToken',
    audit_links: ['https://www.certik.com/projects/axl-token'],
    listedAt: 1648463714,
  },
]
export default cmcProtocols
