import React from 'react'
import { ReactComponent as ApprovePay } from '@/assets/icons/approve-pay.svg'
import Dots from '../Loader/Dots'

const PayButton = ({ pendingTx, balance, fetchStatus, btnText, handleClickPay }) => {
  return (
    <div className="d-flex align-items-center justify-content-between cp-site-box payment-details-btn-container">
      Continue to payment
      <div className="d-flex align-items-center">
        <div className="home-cart-action-btn-icon-container d-flex align-items-center">
          <ApprovePay />
        </div>
        <button
          className="home-cart-action-btn d-flex align-items-center justify-content-center"
          onClick={handleClickPay}
          disabled={pendingTx || !balance || fetchStatus}
        >
          {pendingTx ? <Dots /> : `${btnText}`}
        </button>
      </div>
    </div>
  )
}

export default PayButton
