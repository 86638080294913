import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchEntityData, RealDataEntity } from '@/state/helpers'

export interface EntityState {
  cohortData: RealDataEntity
  currentPoolName: string
}

const initialState: EntityState = {
  cohortData: null,
  currentPoolName: 'Poseidon Pool',
}

export const fetchCorhotPublicData = createAsyncThunk('cohortData/fetchCorhotPublicData', async () => {
  const publicCorhotData = await fetchEntityData()
  return publicCorhotData.data // It should be check later
})

export const setSelectedPoolName = createAsyncThunk('cohortData/setSelectedPoolName', async (poolName: string) => {
  return poolName
})

export const HomeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {
    setCohortData: (state, action) => {
      state.cohortData = { ...state.cohortData, ...action.payload }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCorhotPublicData.fulfilled, (state, action) => {
      const payload = action.payload ? { ...action.payload[0] } : {}
      state.cohortData = { ...state.cohortData, ...payload }
    })
    builder.addCase(setSelectedPoolName.fulfilled, (state, action) => {
      state.currentPoolName = action.payload
    })
  },
})

export const { setCohortData } = HomeSlice.actions

export default HomeSlice.reducer
