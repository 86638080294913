export const BASE_SCAN_URLS = {
  1: 'https://etherscan.io',
  4: 'https://rinkeby.etherscan.io',
}

export const NetworkContextName = 'NETWORK'

export const BASE_URL = 'https://cover.unore.io/'

export const BASE_SCAN_URL = BASE_SCAN_URLS[1]

export const COINGECKO_BASE_URL = 'https://api.coingecko.com/api/v3/'

export const authLocalStorageKey = 'unorev2_auth_status'

export const EXPIREDAYS = 30

export const ONE_DAY_MILI_SECONDS = 24 * 3600 * 1000

export const API_BASE_URL = 'https://cover.unore.io/api/'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const REACT_APP_INFURA_KEY = 'bcc18855878240d29347cc0a70610110'
export const REACT_APP_CHAIN_ID = process.env.REACT_APP_CHAIN_ID ?? '1'

export const BICONOMY_API_KEY = process.env.REACT_APP_BICONOMY_API_KEY // "3GeN7aLjC.1fe689e8-efc9-4c3b-ab72-365c53a6356c"

export const GRAPH_ENDPOINTS = 'https://api.thegraph.com/subgraphs/name/luckywebdev/cover-portal-subgraph' // 'https://api.thegraph.com/subgraphs/name/luckywebdev/cover-portal-subgraph-rinkeby'
