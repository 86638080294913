import { createSlice } from '@reduxjs/toolkit'
import {
  updateAssetsData,
  updateSelectedData,
  updateUnlockedContracts,
  updateErc20TokenData,
  updateSelectedProtocols,
  updateSelectedProtocolIds,
} from './actions'

const initialState = {
  assetData: null,
  lockedContractData: null,
  unlockedContractData1: null,
  erc20TokenData: null,
  selectedData: null,
  selectedProtocolData: [],
  selectedProtocolIds: [],
  tokenPrices: null,
  // isPaymentDlgOpen: false,
  totalInUsd: null,
  totalTokens: 0,
  // payStatus: false,
}

export const CoverSlice = createSlice({
  name: 'Cover',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAssetsData, (state, action) => {
      state.assetData = action.payload
    })
    builder.addCase(updateSelectedData, (state, action) => {
      state.selectedData = action.payload
    })
    builder.addCase(updateSelectedProtocols, (state, action) => {
      state.selectedProtocolData = action.payload
    })
    builder.addCase(updateSelectedProtocolIds, (state, action) => {
      state.selectedProtocolIds = action.payload
    })
    builder.addCase(updateUnlockedContracts, (state, action) => {
      state.unlockedContractData1 = action?.payload?.data
    })
    builder.addCase(updateErc20TokenData, (state, action) => {
      state.erc20TokenData = action.payload.data.erc20TokenData
      state.tokenPrices = action.payload.data.tokenPrice
      state.totalInUsd = action.payload.totalInUsd
      state.totalTokens = action.payload.totalTokens
    })
    // builder.addCase(updatePaymentDlgOpen, (state, action) => {
    //   state.isPaymentDlgOpen = action.payload
    // });
    // builder.addCase(updatePaymentStatus, (state, action) => {
    //   state.payStatus = action.payload;
    // })
  },
})

export default CoverSlice.reducer
