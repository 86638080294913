import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../index'
import { updateUnlockedContracts } from './actions'

export const useSelectedData = () => {
  return useSelector((state) => state.cover.selectedData)
}

export const useGetSelectedProtocols = () => {
  return useSelector((state) => state.cover)
}

export const useGetUnlockedContracts = () => {
  const unlockedContractData = useSelector((state) => state.cover.unlockedContractData)

  const dispatch = useAppDispatch()
  const setUnlockedContractData = useCallback((data) => dispatch(updateUnlockedContracts({ data })), [dispatch])

  return [unlockedContractData, setUnlockedContractData]
}

export const useGetErc20TokenPriceInUsd = () => {
  return useSelector((state) => state.cover.tokenPrices)
}
