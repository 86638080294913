import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack'
import { Alert, AlertColor, AlertTitle } from '@mui/material'
import React from 'react'

interface ReportCompleteProps extends CustomContentProps {
  withdraw?: boolean
  title?: string
  color: AlertColor
}
const gitbookLink = 'https://unore.gitbook.io/docs/investor-app/guide'

const CustomSnackBar = React.forwardRef<HTMLDivElement, ReportCompleteProps>((props, ref) => {
  const { id, message, withdraw = false, title, color, action, ...other } = props

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Alert
        variant="filled"
        severity={color}
        sx={{
          width: '100%',
        }}
        onClose={() => closeSnackbar(id)}
      >
        <AlertTitle> {title} </AlertTitle>
        {message}
        {withdraw && (
          <a href={gitbookLink} target="_blank" rel="noreferrer" style={{ marginLeft: 5, color: 'white' }}>
            Guide.
          </a>
        )}
      </Alert>
    </SnackbarContent>
  )
})

export default CustomSnackBar

// export const action = (snackbarId) => (
//   <>
//     <button
//       onClick={() => {
//         closeSnackbar(snackbarId)
//       }}
//       style={{
//         color: 'white',
//       }}
//     >
//       Dismiss
//     </button>
//   </>
// )

declare module 'notistack' {
  interface VariantOverrides {
    // removes the `warning` variant
    warning: false
    // adds `myCustomVariant` variant
    myCustomVariant: true
    // adds `reportComplete` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    customVariant: {
      withdraw?: boolean
      title?: string
      color: AlertColor
    }
  }
}
