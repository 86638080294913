import { useQuery } from '@tanstack/react-query'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import { getAddressOnBsc, getMigrationAddress, getSalesPolicyAddress, getTokenAddress } from '@/utils/addressHelpers'
import { getBep20Contract, getBep20ContractOnBsc } from '@/utils/contractHelpers'
import { BIG_ALLOWANCE, ethersToBigNumber } from '@/utils/bigNumber'
import TOKENS from '@/config/constants/tokens'
import { JsonRpcProvider } from '@ethersproject/providers'
import { getRpcUrl } from '@/utils/getRpcUrl'

const useGetAllowanceOfTokenQuery = (tokenSymbol = 'usdc') => {
  const { chainId, account } = useActiveWeb3React()
  return useQuery({
    queryKey: ['token allowance', chainId, account, tokenSymbol],

    queryFn: async () => {
      const tokenAddress = getTokenAddress(tokenSymbol)
      const tokenContract = getBep20Contract(tokenAddress)
      const approveAddr = getSalesPolicyAddress()
      const res = await tokenContract.allowance(account, approveAddr)
      return ethersToBigNumber(res)
    },

    enabled: !!chainId && !!account,
  })
}

export const useGetAllowanceQuery = (smtPool) => {
  const { chainId, account } = useActiveWeb3React()

  return useQuery({
    queryKey: ['token allowance 2', chainId, account, smtPool],

    queryFn: async () => {
      if (smtPool && smtPool.stakingToken) {
        let tokenAddress: string
        const uno = getAddressOnBsc(TOKENS.uno.address)

        if (smtPool.oldPool) {
          tokenAddress = uno
        } else {
          tokenAddress = smtPool.stakingToken.address[smtPool.chainId]
        }

        // const tokenAddress = smtPool.ethPool
        //   ? getAddress(smtPool.stakingToken?.address)
        //   : smtPool.oldPool
        //     ? uno
        //     : getAddressOnBsc(smtPool.stakingToken?.address)

        const tokenContract = getBep20Contract(tokenAddress, new JsonRpcProvider(getRpcUrl(smtPool.chainId)))

        const poolAddr = smtPool.contractAddress[smtPool.chainId]

        if (smtPool?.stakingToken?.symbol !== 'ETH') {
          const response = await tokenContract.allowance(account, poolAddr)
          const currentAllowance = ethersToBigNumber(response)

          return currentAllowance
        } else {
          return BIG_ALLOWANCE
        }
      }
    },

    enabled: !!chainId && !!account && !!smtPool,
  })
}

export const useGetAllowanceOfMigrationQuery = () => {
  const { account, chainId } = useActiveWeb3React()

  return useQuery({
    queryKey: ['token allowance', chainId, account],

    queryFn: async () => {
      const usdc = getAddressOnBsc(TOKENS.usdcbsc.address)
      const tokenContract = getBep20ContractOnBsc(usdc)
      const approveAddr = getMigrationAddress()
      const res = await tokenContract.allowance(account, approveAddr)
      const currentAllowance = ethersToBigNumber(res)
      return currentAllowance.gt(0)
    },

    enabled: !!chainId && !!account,
  })
}

export default useGetAllowanceOfTokenQuery
