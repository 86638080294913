import React from 'react'
import BigNumber from 'bignumber.js'
import Routes from './Routes'
import 'bootstrap/dist/css/bootstrap.min.css'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function App() {
  return (
    // <div
    //   className={`cp-dialog-container cp-dialog-container-show fixed-top h-100 w-100 d-flex align-items-center justify-content-center container-fluid`}
    // >
    //   <div className="cp-dialog cp-site-box payment-details-box">
    //     <div className="container-fluid">
    //       <div className="row">
    //         <div className="col p-0" />
    //         <div className="col-8 text-center cp-site-box-title-2">UNDER MAINTENANCE</div>
    //         <div className="col p-0" />
    //       </div>
    //     </div>
    //     <div className='d-md-flex align-items-center justify-content-between' style={{ marginTop: 40, marginBottom: 30}}>
    //       <button
    //         className="home-cart-action-btn d-flex align-items-center justify-content-center me-md-2"
    //       >
    //         <a href="https://t.me/UnoReinsure" rel="noreferrer" target="_blank">
    //           JOIN THE COMMUNITY
    //         </a>
    //       </button>
    //       <button
    //         className="home-cart-action-btn d-flex align-items-center justify-content-center mt-3 mt-md-0 ms-md-2"
    //       >
    //         <a href="https://watchdog.unore.io/" rel="noreferrer" target="_blank">
    //           UNO WATCHDOG
    //         </a>
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <Routes />
  )
}

export default App
