import React from 'react'
import { formatFixedNumber2, formatFixedNumber3 } from '@/utils/formatBalance'

const DlgBody = ({ selectedData }) => {
  return (
    <div className="payment-details-container">
      <div className="d-flex justify-content-between payment-details-top-list-item">
        Total Cover Amount
        <span className="payment-details-cover-amount">{`$${
          selectedData ? formatFixedNumber2(selectedData.coverTotalInUsd, 6) ?? 0 : 0
        }`}</span>
      </div>

      <div className="d-flex justify-content-between">
        No of protocols covered
        <span className="payment-details-no-of-protocols">{`${selectedData ? selectedData.protocols.length : 0}`}</span>
      </div>

      <div className="payment-details-seperator"></div>

      <div className="d-flex justify-content-between payment-details-top-list-item">
        Total premium (Pay in USDC)
        <span className="payment-details-premium-eth">
          {' '}
          {`${selectedData ? formatFixedNumber3(selectedData.coverPrice, 6) : 0}`} USDC{' '}
        </span>
      </div>
    </div>
  )
}

export default DlgBody
