import { useEffect, useRef, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { simpleRpcProvider } from '@/utils/providers'

const useActiveWeb3React = () => {
  const { provider, chainId, account, ...web3React } = useWeb3React()
  const refEth = useRef(provider)
  const [provider1, setprovider] = useState(provider || simpleRpcProvider)

  useEffect(() => {
    if (provider !== refEth.current) {
      setprovider(provider || simpleRpcProvider)
      refEth.current = provider
    }
  }, [provider])

  useEffect(() => {
    if (!account) return

    const currentAccount = localStorage.getItem('account')

    if (account !== currentAccount) {
      localStorage.setItem('account', account)
    }
  }, [account])

  return {
    account: localStorage.getItem('account') || account,
    library: provider1,
    chainId: chainId ?? parseInt(process.env.REACT_APP_CHAIN_ID, 10),
    ...web3React,
  }
}

export default useActiveWeb3React
