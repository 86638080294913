export enum ChainId {
  MAINNET = 1,
  TESTNET = 5,
  BSC = 56,
  BSCTESTNET = 97,
}

export const NetworkContextName = 'NETWORK'

export { default as poolsConfig } from './pools'
