import React from 'react'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/config/firebase'
import LeftArrow from '@/assets/icons/left-arrow.png'

const Header = ({ pendingTx, setPaymentDialog }) => {
  const navigate = useNavigate()

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-0 d-flex align-items-center">
          <img
            src={LeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              if (pendingTx) {
                return
              }
              setPaymentDialog(false)
              logEvent(analytics, 'Payment popup back click')
              navigate('/carts')
            }}
          />
        </div>
        <div className="col-8 text-center cp-site-box-title-2">Payment Details</div>
        <div className="col p-0"></div>
      </div>
    </div>
  )
}

export default Header
