import { createSlice } from '@reduxjs/toolkit'
import poolsConfig, { protocols } from '@/config/constants/pools'
import { AppThunk, Pool, PoolsState, PremiumPool } from '@/state/types'
import { fetchPoolInfos, fetchPoolLockTime, fetchPoolsMaxSize, fetchPoolsTotalStaking } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUnlockDuration,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
  fetchWithdrawRequest,
} from './fetchPoolsUser'

const initialState: PoolsState = {
  data: [...poolsConfig],
  premiumData: [...protocols],
  userDataLoaded: false,
  duration: 0,
  lockPeriod: 0,
  ssipPoolInfo: {
    accUnoPerShare: {
      type: 'BigNumber',
      hex: '0x00',
    },
    lastRewardBlock: {
      type: 'BigNumber',
      hex: '0x00',
    },
    unoMultiplierPerBlock: {
      type: 'BigNumber',
      hex: '0x00',
    },
  },
}

export const fetchCohortPublicDataAsync = () => async (dispatch) => {
  const { duration, lockPeriod } = await fetchUnlockDuration()

  const iDuration = duration ? parseInt(duration) : 0
  dispatch(setDuration({ duration: iDuration, lockPeriod: Number(lockPeriod) }))
}
// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch) => {
  const totalStakings = await fetchPoolsTotalStaking()
  const maxSizes = await fetchPoolsMaxSize()

  const poolInfos = await fetchPoolInfos()
  const lockTimes = await fetchPoolLockTime()

  const liveData = poolsConfig.map((pool) => {
    const totalStaking = totalStakings.find((entry) => entry.poolId === pool.poolId)
    const maxSize = maxSizes.find((entry) => entry.poolId === pool.poolId)
    const rewardPerBlock = poolInfos.find((entry) => entry.poolId === pool.poolId)
    const lockTime = lockTimes.find((entry) => entry.poolId === pool.poolId)
    return {
      ...totalStaking,
      ...maxSize,
      ...rewardPerBlock,
      ...lockTime,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const updateTotalStakeInPool =
  (poolId: number): AppThunk =>
  async (dispatch) => {
    const totalStakings = await fetchPoolsTotalStaking()
    const totalStaking = totalStakings.find((entry) => entry.poolId === poolId)
    dispatch(updatePoolsPublicData({ poolId, field: 'totalStaked', value: totalStaking }))
  }

export const fetchPoolsUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    try {
      // const allowances = await fetchPoolsAllowance(account)
      // const pendingRewards = await fetchUserPendingRewards(account)
      // const userStakedStatus = await fetchWithdrawRequest(account)
      // const userData = poolsConfig.map((pool) => ({
      //   poolId: pool.poolId,
      //   allowance: allowances[pool.poolId],

      //   pendingReward: pendingRewards[pool.poolId],
      //   requestedInfo: userStakedStatus[pool.poolId],
      // }))

      // dispatch(setPoolsUserData(userData))
      dispatch<any>(fetchUserStakingTokenBalances(account))
      dispatch<any>(fetchUserStakedBalances(account))
      dispatch<any>(fetchUsersPendingRewards(account))
      dispatch<any>(fetchUserWithdrawRequest(account))
      dispatch<any>(fetchUserAllowances(account))
    } catch (error) {
      console.log(error)
    }
  }
export const fetchUserAllowances =
  (account: string): AppThunk =>
  async (dispatch) => {
    try {
      const allowances = await fetchPoolsAllowance(account)

      const userData = poolsConfig.map((pool) => ({
        poolId: pool.poolId,
        allowance: allowances[pool.poolId],
      }))

      dispatch(setPoolsUserData(userData))
    } catch (error) {
      console.log(error)
    }
  }
export const fetchUsersPendingRewards =
  (account: string): AppThunk =>
  async (dispatch) => {
    try {
      const pendingRewards = await fetchUserPendingRewards(account)

      const userData = poolsConfig.map((pool) => ({
        poolId: pool.poolId,
        pendingReward: pendingRewards[pool.poolId],
      }))

      dispatch(setPoolsUserData(userData))
    } catch (error) {
      console.log(error)
    }
  }
export const fetchUserWithdrawRequest =
  (account: string): AppThunk =>
  async (dispatch) => {
    const userStakedStatus = await fetchWithdrawRequest(account)
    const userData = poolsConfig.map((pool) => ({
      poolId: pool.poolId,
      requestedInfo: userStakedStatus[pool.poolId],
    }))

    dispatch(setPoolsUserData(userData))
  }
export const fetchUserStakedBalances =
  (account: string): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account)

    const userData = poolsConfig.map((pool) => ({
      poolId: pool.poolId,
      stakedBalance: Array.isArray(stakedBalances[pool.poolId])
        ? stakedBalances[pool.poolId][0]
        : stakedBalances[pool.poolId],
    }))

    dispatch(setPoolsUserData(userData))
  }
export const fetchUserStakingTokenBalances =
  (account: string): AppThunk =>
  async (dispatch) => {
    const stakingTokenBalances = await fetchUserBalances(account)
    const userData = poolsConfig.map((pool) => ({
      poolId: pool.poolId,
      stakingTokenBalance: stakingTokenBalances[pool.poolId],
    }))

    dispatch(setPoolsUserData(userData))
  }

export const updateUserAllowance =
  (poolId: number, account: string): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account)
    dispatch(updatePoolsUserData({ poolId, field: 'allowance', value: allowances[poolId] }))
  }

export const updateUserBalance =
  (poolId: number, account: string): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account)
    dispatch(updatePoolsUserData({ poolId, field: 'stakingTokenBalance', value: tokenBalances[poolId] }))
  }

export const updateUserStakedBalance =
  (poolId: number, account: string): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account)
    dispatch(updatePoolsUserData({ poolId, field: 'stakedBalance', value: stakedBalances[poolId][0] }))
  }

export const updateUserPendingReward =
  (poolId: number, account: string): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account)
    dispatch(updatePoolsUserData({ poolId, field: 'pendingReward', value: pendingRewards[poolId] }))
  }

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setDuration: (state, action) => {
      return {
        ...state,
        duration: action.payload.duration,
        ssipPoolInfo: action.payload.ssipPoolInfo,
        lockPeriod: action.payload.lockPeriod,
      }
    },
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.poolId === pool.poolId)
        return { ...pool, ...livePoolData }
      })
    },
    updatePoolsPublicData: (state, action) => {
      const { field, value, poolId } = action.payload
      const index = state.data.findIndex((p) => p.poolId === poolId)
      if (index >= 0) {
        state.data[index] = { ...state.data[index], [field]: value[`${field}`] }
        return state
      }
    },
    setPremiumPoolsPublicData: (state, action) => {
      const livePoolsData: PremiumPool[] = action.payload
      state.premiumData = state.premiumData.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.protocolId === pool.protocolId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.poolId === pool.poolId)
        return { ...pool, userData: { ...pool.userData, ...userPoolData } }
      })
      state.userDataLoaded = true
      return state
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, poolId } = action.payload
      const index = state.data.findIndex((p) => p.poolId === poolId)
      console.log(field, value, 'from values field')
      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
})

// Actions
export const {
  setDuration,
  setPoolsPublicData,
  setPoolsUserData,
  updatePoolsPublicData,
  updatePoolsUserData,
  setPremiumPoolsPublicData,
} = PoolsSlice.actions

export default PoolsSlice.reducer
