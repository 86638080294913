import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Web3Provider } from '@ethersproject/providers'
import bep20Abi from '@/config/abi/erc20.json'
import { BASE_BSC_SCAN_URLS } from '@/config'
import { ethers } from 'ethers'

export function isAddress(value) {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(data, type, chainId) {
  switch (type) {
    case 'transaction': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}
export function shortenTxHash(tx, chars = 4) {
  if (!tx) {
    return ''
  }
  const len = tx.length
  return `${tx.substring(0, chars + 2)}...${tx.substring(len - chars)}`
}

function getSafeIntAsString(value: string) {
  if (!value.includes('.')) {
    return value
  }
  const [left] = value.split('.')
  return left
}
export function shortenNumber(value: number, decimals = 2) {
  if (value === undefined) return 0
  const parsed1: string = value.toString()
  // const parsed: string = parsed1.includes('.') ? (Number(parsed1.split('.')[0])).toString() : Number(parsed1).toString();
  const parsed: string = getSafeIntAsString(parsed1)
  const pow = 10 ** decimals
  if (parsed.length < 4) {
    const temp1 = parseInt((value * pow).toString())
    return temp1 / pow
  }
  if (parsed.length < 7) {
    const newValue = (pow * value) / 1000
    const ret = newValue.toString()
    const realRet = Number(getSafeIntAsString(ret)) / pow
    return `${realRet} K`
  }
  if (parsed.length < 10) {
    const newValue = (pow * value) / 1000000
    const ret = newValue.toString()
    const realRet = Number(getSafeIntAsString(ret)) / pow
    return `${realRet} M`
  }
  const newValue = (pow * value) / 1000000000
  const ret = newValue.toString()
  const realRet = Number(getSafeIntAsString(ret)) / pow
  return `${realRet} B`
}

export function formatBalanceWithComma(value: number, decimals = 2) {
  if (value === undefined) return '0'
  const pow = 10 ** decimals
  const temp = Math.floor(value * pow)
  const newValue = (temp / pow).toString()
  if (temp === 0 && value !== 0) {
    const temp1 = Math.floor(value * 1000000 * pow)
    const newValue1 = (temp1 / (1000000 * pow)).toString()
    return newValue1.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}
// account is optional
export function getProviderOrSigner(library, account = null) {
  return account ? getSigner(library, account) : library
}
// account is optional
export function getContract(address, ABI, library, account = AddressZero) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

// account is optional
export function getTokenContract(address: string, library: Web3Provider, account?: string): Contract {
  return getContract(address, bep20Abi, library, account)
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jly', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export function getFormattedDate(date: string) {
  if (date === undefined) return ''
  const dateArr = date.split('-')
  return `${dateArr[0]} ${months[parseInt(dateArr[1]) - 1]} ${dateArr[2]}`
}

export function isInDuration(date: string) {
  const dateArr = date.split(' ')
  const month = months.findIndex((_i) => _i === dateArr[1])
  const fixDate = new Date(parseInt(dateArr[2]), month, parseInt(dateArr[0])).getTime() / 1000
  const curSec = new Date().getTime() / 1000
  return curSec < fixDate
}

export function getCurrentTimeStamp() {
  return new Date().getTime() / 1000
}

export function getFormattedDateFromTimeStamp(seconds: number) {
  const date = new Date(seconds * 1000)
  const yy = date.getFullYear()
  const mm = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${dd}/${mm}/${yy}`
}

export function getFormattedDateFromDate(date: Date) {
  const yy = date.getFullYear()
  const mm = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  return `${dd}/${mm}/${yy} ${hh}:${min}:${ss}`
}

export const getFormattedEndDateFromSecondsToTimeStamp = (seconds: string, startDate: number) => {
  const endDateiseconds = getFormattedEndDateSeconds(seconds, startDate)
  //console.log(endDateiseconds, seconds, startDate)
  return getFormattedDateFromTimeStamp(endDateiseconds)
}

export const getFormattedEndDateFromSecondsToTimeStamp2 = (seconds: string, startDate: number) => {
  const endDateiseconds = getFormattedEndDateSeconds(seconds, startDate)

  return getFormattedDateFromTimeStamp2(endDateiseconds)
}

export const getFormattedEndDateSeconds = (seconds: string, startDate: number) => {
  let parsedSeconds = 0
  if (seconds.length > 17) {
    const bigNumberSeconds = ethers.BigNumber.from(seconds)
    parsedSeconds = parseInt(ethers.utils.formatUnits(bigNumberSeconds, 0))
  } else {
    parsedSeconds = parseInt(seconds)
  }

  return startDate + parsedSeconds
}

export function getFormattedDateFromTimeStamp2(seconds) {
  const date = new Date(seconds * 1000)
  const yy = date.getFullYear()
  const mm = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  return `${dd}/${mm}/${yy} ${hh}:${min}:${ss}`
}

export function getFixedValue(val: number, fixed = 2) {
  const newVal = Math.floor(val * 10 ** fixed)
  // const newIntVal = parseInt(newVal, 10)
  return newVal / 10 ** fixed
}

export function getTimeFromTimeStamp(seconds: number) {
  const today = new Date(seconds * 1000)
  const hh = today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
  const mm = today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
  const ss = today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()

  return `${hh}:${mm}:${ss}`
}

const testnetChainIds = ['97', '57000', '11155111']
const chainId = process.env.REACT_APP_CHAIN_ID ?? '1'
export const isTestnet = testnetChainIds.includes(chainId)

export const getThisDate = () => {
  const date = new Date()
  const yy = date.getFullYear()
  const mm = date.getMonth() + 1
  const dd = date.getDate()

  const mmm = mm < 10 ? `0${mm}` : `${mm}`
  const ddd = dd < 10 ? `0${dd}` : `${dd}`
  return `${yy}-${mmm}-${ddd}`
}
export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
