import poolsConfig from '@/config/constants/pools'
import { getAddressOnBsc, getCohortAddress } from '@/utils/addressHelpers'
import {
  getBep20Contract,
  getCohortContract,
  getPoolContract,
  getRiskPoolsContract,
  getSSRPContract,
} from '@/utils/contractHelpers'
import { simpleRpcProvider } from '@/utils/providers'
import { BIG_ZERO } from '@/utils/bigNumber'
import { JsonRpcProvider } from '@ethersproject/providers'
import { getRpcUrl } from '@/utils/getRpcUrl'

// const zeusAddr = '0xEcE9f1A3e8bb72b94c4eE072D227b9c9ba4cd750'.toLowerCase()
// const athenaAddr = '0x0b5C802ecA88161B5daed08e488C83d819a0cD02'.toLowerCase()
// const artemisAddr = '0x2cd32dF1C436f8dE6e09d1A9851945c56bcEd32a'.toLowerCase()

export const fetchPoolsAllowance = async (account) => {
  const _calls = poolsConfig.map(async (poolConfig) => {
    const tokenAddress = poolConfig.stakingToken.address[poolConfig.chainId]

    if (!tokenAddress) {
      throw new Error(`Token address not found for pool ${poolConfig.poolName}`)
    }

    const tokenContract = getBep20Contract(tokenAddress, new JsonRpcProvider(getRpcUrl(poolConfig.chainId)))

    let poolAddress: string

    if (poolConfig.oldPool) {
      poolAddress = getCohortAddress()
    } else {
      poolAddress = poolConfig.contractAddress[poolConfig.chainId]
    }

    return await tokenContract.allowance(account, poolAddress)
  })

  const allowances = await Promise.all(_calls)
    .then((values) => {
      return values
    })
    .catch((err) => {
      console.log(err)
    })

  return poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      ...(allowances && {
        [pool.poolId]: allowances[index].toJSON(),
      }),
    }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  const calls = poolsConfig.map((poolConfig) => {
    const tokenAddress = getBep20Contract(
      poolConfig.stakingToken.address[poolConfig.chainId],
      new JsonRpcProvider(getRpcUrl(poolConfig.chainId)),
    )

    // const token = poolConfig.ethPool
    //   ? getBep20Contract(getAddress(poolConfig.stakingToken.address))
    //   : getBep20ContractOnBsc(getAddressOnBsc(poolConfig.stakingToken.address))

    return poolConfig.ethNativePool ? simpleRpcProvider.getBalance(account) : tokenAddress.balanceOf(account)
  })

  const tokenBalancesRaw = await Promise.all(calls)
    .then((values) => {
      return values
    })
    .catch((err) => {
      console.log(err)
    })
  const tokenBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      ...(tokenBalancesRaw && {
        [pool.poolId]: tokenBalancesRaw[index].toJSON(),
      }),
    }),
    {},
  )
  return { ...tokenBalances }
}

export const fetchUserStakeBalances = async (account) => {
  const _calls = poolsConfig.map(async (_pool) => {
    const contract =
      _pool.ethPool || (!_pool.ethPool && !_pool.oldPool) ? getPoolContract(_pool) : getRiskPoolsContract(_pool.poolId)

    return _pool.ethPool || (!_pool.ethPool && !_pool.oldPool)
      ? contract.getStakedAmountPerUser(account)
      : contract.balanceOf(account)
  })

  const stakedInfo = await Promise.allSettled(_calls)
    .then((value) => {
      return value
    })
    .catch((err) => {
      console.log(err)
    })
  const stakedBalances =
    stakedInfo &&
    stakedInfo.map((values) => {
      return values.status !== 'rejected' ? values.value : null
    })

  return { ...stakedBalances }
}

export const fetchLpAmount = async (account, pool) => {
  const contract = getPoolContract(pool)
  const res =
    pool.ethPool || (!pool.ethPool && !pool.oldPool)
      ? await contract.getStakedAmountPerUser(account)
      : { unoAmount: BIG_ZERO }
  return res.unoAmount.toJSON()
}

export const fetchUserPendingRewards = async (account) => {
  const _calls = poolsConfig.map(async (_pool) => {
    const contract =
      _pool.ethPool || (!_pool.ethPool && !_pool.oldPool) ? getPoolContract(_pool) : await getCohortContract()

    return _pool.ethPool || (!_pool.ethPool && !_pool.oldPool)
      ? contract.pendingUno(account).catch((e) => console.log(e, contract))
      : contract
          .totalPremiumReward(account, getAddressOnBsc(_pool.contractAddress))
          .catch((e) => console.log(e, contract))
  })

  const stakedInfo = await Promise.all(_calls)
    .then((value) => {
      return value
    })
    .catch((err) => {
      console.log(err)
    })

  const pendingRewards = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      ...(stakedInfo && {
        [pool.poolId]: stakedInfo[index].toJSON(),
      }),
    }),
    {},
  )

  return { ...pendingRewards }
}

export const fetchWithdrawRequest = async (account) => {
  // const lpRatio = await fetchLpAmountInBigNumber()
  const ethPools = poolsConfig.filter(
    (poolConfig) => poolConfig.ethPool || (!poolConfig.ethPool && !poolConfig.oldPool),
  )

  const _calls = ethPools.map(async (_pool) => {
    const contract = getPoolContract(_pool)
    return contract.getWithdrawRequestPerUser(account)
  })

  const values = await Promise.all(_calls)
    .then((value) => {
      return value
    })
    .catch((err) => {
      console.log(err)
    })

  const infos = []
  ethPools.forEach((_, index) => {
    const insertItem = values
      ? {
          pendingAmount: values[index].pendingAmount.toJSON(),
          pendingAmountInUno: values[index].pendingAmountInUno.toJSON(),
          requestTime: values[index]?.requestTime.toJSON(),
        }
      : {}
    infos.push(insertItem)
  })

  const emptyData = {
    accUnoPerShare: BIG_ZERO.toJSON(),
    lastRewardBlock: BIG_ZERO.toJSON(),
    unoMultiplierPerBlock: BIG_ZERO.toJSON(),
  }

  const staked = [...infos, emptyData, emptyData, emptyData]

  const stakedBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.poolId]: staked[index],
    }),
    {},
  )

  return { ...stakedBalances }
}

export const fetchUnlockDuration = async () => {
  const cohortContract = await getCohortContract()
  const res = await cohortContract.cohortActiveFrom().catch((e) => console.error(e))
  const duration = await cohortContract.duration().catch((e) => console.error(e))

  return {
    lockPeriod: duration.toString(),
    duration: res.toString(),
  }
}

// not checked below functions
export const fetchSSIPPoolInfo = async () => {
  const ssipContract = getSSRPContract()
  const res1 = await ssipContract.poolInfo()
  return {
    accUnoPerShare: res1.accUnoPerShare.toJSON(),
    lastRewardBlock: res1.lastRewardBlock.toJSON(),
    unoMultiplierPerBlock: res1.unoMultiplierPerBlock.toJSON(),
  }
}

export const fetchRequestedAmt = async (account) => {
  const ssipContract = getSSRPContract()
  const res1 = await ssipContract.getWithdrawRequestPerUser(account)

  return {
    pendingAmount: res1.pendingAmount.toJSON(),
    pendingAmountInUno: res1.pendingAmountInUno.toJSON(),
    originUnoAmount: res1.originUnoAmount.toJSON(),
    requestTime: res1?.requestTime.toJSON(),
  }
}
