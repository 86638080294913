import axios from 'axios'
import { ADMIN_BASE_API_URL, COINGECKO_BASE_URL } from '@/config'
import { usdPriceTokenList } from '@/config/constants/tokens'

export const axiosGetMain = (endpoint) => {
  const url = `${COINGECKO_BASE_URL}${endpoint}`
  const header = {}

  return axios
    .get(url, {
      headers: header,
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return {
        status: 500,
        data: 'Failed!',
        error,
      }
    })
}

const axiosGet = (url) => {
  const header = {}

  return axios
    .get(url, {
      headers: header,
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return {
        status: 500,
        data: 'Failed!',
        error,
      }
    })
}

export const fetchUsdPrices = async () => {
  const returnRes = { ETH: 0, USDC: 0, UNO: 0, USDT: 0 }
  const _calls = usdPriceTokenList.map(async (token) => {
    returnRes[`${token.symbol}`] = 0
    return axiosGetMain(`simple/price?ids=${token.id}&vs_currencies=usd`)
  })

  const prices = await Promise.all(_calls).then((res) => {
    return res
  })
  for (let i = 0; i < usdPriceTokenList.length; i++) {
    returnRes[`${usdPriceTokenList[i].symbol}`] =
      prices[i].status === 200 ? prices[i].data[`${usdPriceTokenList[i].id}`].usd : 0
  }
  return returnRes
}

export const fetchEthUsdPrice = async () => {
  const res = await axiosGetMain(`simple/price?ids=weth&vs_currencies=usd`)
  return res.data
}

export const getProtocolListsWithPagination = (
  page: number,
  limit: number,
  search?: string,
  type?: number | string,
) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(`${ADMIN_BASE_API_URL}protocol?limit=${limit}&page=${page + 1}&search=${search}&type=${type}&status=1`)
        .then((res) => {
          const { protocols, totalItems } = res.data?.data
          const data = []
          protocols?.forEach((protocol, index) => {
            data.push({
              ...protocol,
              address: protocol.protocol_id,
              logo: protocol.product_logo,
              name: protocol.product_name,
              url: protocol.product_link,
              id: protocol._id,
              isNew: true,
            })
          })
          resolve({
            totalCounts: totalItems,
            data,
          })
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getAllPurchasedPolicyData = async (arr) => {
  const calls = arr.map((_) => {
    return axiosGet(`${ADMIN_BASE_API_URL}policy-detail?txhash=${_.transaction}`)
  })

  const items = await Promise.all(calls).then((res: any) => {
    return res
  })

  return items?.map((_) => {
    return _.data.success ? _.data.data.protocol : null
  })
}
