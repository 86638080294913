import BigNumber from 'bignumber.js'
import { BIG_TEN } from '@/utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS = {
  1: 'https://etherscan.io',
  4: 'https://rinkeby.etherscan.io',
  11155111: 'https://sepolia.etherscan.io',
  56: 'https://bscscan.com',
  97: 'https://testnet.bscscan.com',
}

export const BASE_URL = 'https://app.unore.io/'
export const ADMIN_BASE_API_URL =
  Number(process.env.REACT_APP_CHAIN_ID) === 1
    ? 'https://admin.unore.io/api/v1/'
    : 'https://staging-admin.unore.io/api/v1/'

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[1]
export const MAINNET_SCAN_URL = BASE_BSC_SCAN_URLS[1]

export const COINGECKO_BASE_URL = 'https://api.coingecko.com/api/v3/'
export const connectorLocalStorageKey = 'connectorIdUnore'

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5,
  BSC = 56,
  BSCTESTNET = 97,
}

export const API_BASE_URL = 'https://api.unore.io/api/'
export const API_BASE_URL_DEV = 'https://development-api.unore.io/api/'

export const EntityTypes = {
  jeus: 'ZEUS_RISK_DISTRIBUTION_DATA',
  cohort: '20210920_COHORT_1', // "20210907_COHORT_1"
}

export const EventTypes = {
  all: 'all',
}

export const LocalStorageForWalletConnection = 'LocalStorageForWalletConnection'

export const USDT_PER_BLOCK = new BigNumber(40)
export const BLOCKS_PER_YEAR = new BigNumber(2584766) // 10512000
export const ETH_BLOCKS_PER_YEAR = new BigNumber(2598435) // 2102400
export const USDT_PER_YEAR = USDT_PER_BLOCK.times(BLOCKS_PER_YEAR)
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 300000
export const DEFAULT_GAS_PRICE = 5

export const CONTRACT_CREATION_DATE = 1632122867

export const BSC_DEFAULT_CHAIN_ID = process.env.REACT_APP_CHAIN_ID === '1' ? 56 : 97
export const ETH_DEFAULT_CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID ?? 1)

export const ETH_NATIVE_POOL_INDEX = 4
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const BICONOMY_API_KEY = process.env.REACT_APP_BICONOMY_API_KEY

export const LATEST_PROTOCOL_ID = '0x0000000000000000000000000000000000010003'

export const chainNames: { [chainId: string]: string } = {
  1: 'Ethereum',
  2: 'Binance',
  3: 'Polygon',
  4: 'Avalanche',
  5: 'Fantom',
  6: 'Cosmos',
  7: 'Cardano',
  8: 'Algorand',
  9: 'Arbitrum',
  10: 'Harmony',
  11: 'Moonriver',
  12: 'Optimism',
  13: 'Polkadot',
  14: 'Solana',
  15: 'Telos',
  16: 'Terra',
  17: 'Syscoin',
  18: 'Astar',
  19: 'Aurora',
  20: 'Boba',
  21: 'Callisto',
  22: 'Celo',
  23: 'Cronos',
  24: 'CSC',
  25: 'Elastos',
  26: 'Energi',
  27: 'EOS',
  28: 'Fuse',
  29: 'Fusion',
  30: 'Godwoken',
  31: 'Heco',
  32: 'Icon',
  33: 'IoTeX',
  34: 'Klaytn',
  35: 'Kucoin',
  36: 'Kusama',
  37: 'Meter',
  38: 'Metis',
  39: 'Moonbeam',
  40: 'Nuls',
  41: 'Oasis',
  42: 'OKExChain',
  43: 'Palm',
  44: 'RSK',
  45: 'Secret',
  46: 'Shiden',
  47: 'Sifchain',
  48: 'smartBCH',
  49: 'Songbird',
  50: 'Stellar',
  51: 'Tezos',
  52: 'Theta',
  53: 'Thorchain',
  54: 'Tron',
  55: 'Velas',
  56: 'Wanchain',
  57: 'Waves',
  58: 'xDai',
  59: 'Kava',
  60: 'Polygon zkEVM',
}
export const DEFAULT_REQUEST_REFRESH_INTERVAL = 300000
export const dappId = 1
