import React from 'react'
import './styles.css'
import { setShowWalletModal } from '@/state/auth'
import useAuth from '@/hooks/useAuth'
import { useAppDispatch } from '@/state'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'

const BlockModal = ({ isDialogOpen }) => {
  const { login } = useAuth()
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()
  async function handleMetamaskClick() {
    dispatch(setShowWalletModal({ showModal: true, error: false }))
    //await login(ConnectionType.INJECTED)
  }

  return (
    <div
      className={`
        cp-dialog-container fixed-top h-100 w-100 d-flex align-items-center justify-content-center container-fluid 
        ${isDialogOpen ? 'cp-dialog-container-show' : 'visible-hidden'}

      `}
      style={{
        zIndex: 80,
      }}
    >
      <div className="cp-dialog cp-site-box payment-details-box">
        <div className="container-fluid">
          <div className="row">
            <div className="col p-0" />
            <div className="col-8 text-center cp-site-box-title-2"> Under Maintenance</div>
            <div className="col p-0" />
          </div>
        </div>

        {/* <div
          className="d-flex justify-content-between"
          style={{ color: 'white', marginTop: 20, marginBottom: 20, fontSize: 18 }}
        >
         
        </div> */}

        {/* <div className="social-button social-button-hover" >
          <a href="https://discord.com/invite/Yf2DMMEYKQ" rel="noreferrer" target="_blank">
            Join Discord
          </a>
        </div> */}
        <button
          className="home-cart-action-btn d-flex align-items-center justify-content-center"
          style={{ marginTop: 20, marginBottom: 30, width: 200, marginLeft: 'auto', marginRight: 'auto' }}
        >
          <a href="https://discord.com/invite/Yf2DMMEYKQ" rel="noreferrer" target="_blank">
            Join Discord
          </a>
        </button>
        <div
          className="d-flex justify-content-between"
          style={{ color: 'white', marginTop: 20, marginBottom: 20, fontSize: 18, textAlign: 'center' }}
        >
          Connect your wallet to check if you have early access to the new contracts.
        </div>
        {account && (
          <>
            <div style={{ color: 'white', marginTop: 20, marginBottom: 20, fontSize: 18, textAlign: 'center' }}>
              Address connected: {account} has no early access switch to different wallet on your metamask
            </div>

            {/* <button
              className="home-cart-action-btn d-flex align-items-center justify-content-center"
              style={{ marginTop: 20, marginBottom: 30, width: 200, marginLeft: 'auto', marginRight: 'auto' }}
              onClick={handleSwitchWallet}
            >
              Switch wallet
            </button> */}
          </>
        )}
        {!account && (
          <>
            <button
              className="home-cart-action-btn d-flex align-items-center justify-content-center"
              style={{ marginTop: 20, marginBottom: 30, width: 200, marginLeft: 'auto', marginRight: 'auto' }}
              onClick={handleMetamaskClick}
            >
              Connect wallet
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default BlockModal
