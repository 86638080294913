import { useCallback } from 'react'
import { connectorLocalStorageKey, dappId, LocalStorageForWalletConnection } from '@/config'
import { useAppDispatch } from '@/state'
import { setShowWalletModal } from '@/state/auth'
import { ConnectionType } from '@/connectors/types'
import { getConnection } from '@/connectors'
import { didUserReject } from '@/connectors/utils'
import { useWeb3React } from '@web3-react/core'

import { createSiweMessage } from '@/utils/eip712Sign'
import useActiveWeb3React from './useActiveWeb3React'
import axios from 'axios'
import { EndPoints } from '@/config/endpoints'

const useAuth = () => {
  const { connector, provider } = useWeb3React()
  const { account } = useActiveWeb3React()

  const dispatch = useAppDispatch()
  //const isAccount = useRef(account)

  const login = useCallback(
    async (connectorID: ConnectionType) => {
      const connection = getConnection(connectorID)
      window.localStorage.setItem(connectorLocalStorageKey, connectorID)
      window.localStorage.setItem(LocalStorageForWalletConnection, '1')
      try {
        if (connection) {
          await connection.connector.activate()
        } else {
          console.error('Unable to find connector', 'The connector config is wrong')
          // toastError('Unable to find connector', 'The connector config is wrong')
        }
      } catch (error) {
        if (didUserReject(connection, error)) {
          window.localStorage.removeItem(connectorLocalStorageKey)
          dispatch(setShowWalletModal({ showModal: true, step: 1, error: true }))
          return
        }
      }
    },
    [dispatch],
  )

  const logout = useCallback(async () => {
    if (connector && connector.deactivate) {
      await connector.deactivate()
    }
    connector.resetState()
    window.localStorage.removeItem(connectorLocalStorageKey)
    localStorage.removeItem('account')
  }, [connector])

  const getSignature = useCallback(
    async (setAccountCnt) => {
      if (account) {
        try {
          const res = await axios.get(`${EndPoints.GET_ACCOUNT_SIGNATURE}/${account}/${dappId}`)
          if (res?.data && !res?.data?.success) {
            // let signature = await window.ethereum.request({
            //   method: 'eth_signTypedData_v4',
            //   params: [account, termsAndConditions],
            // })
            const message = `
Review the Ecosystem Terms of Service (https://tos.unore.io/) and the Migration Terms of Service (https://tos.unore.io/dapp) by clicking the links.

Proceeding signifies your explicit acceptance of both sets of terms.

This request will not trigger a blockchain transaction or cost any gas fees.
              `
            const signer = provider.getSigner()
            const signature = await signer.signMessage(createSiweMessage(account, message))

            const payload = {
              account,
              signature,
              dappId: 1,
            }
            const postRes = await axios.post(`${EndPoints.ADD_ACCOUNT_SIGNATURE}`, payload)
            if (postRes.data) {
              console.log('Success >>>>')
            }
          }
        } catch (error) {
          logout()
          setAccountCnt(0)
          console.log(error, 'from signature error >>>')
        }
      }
    },
    [account, logout, provider],
  )

  return { login, logout, getSignature }
}

export default useAuth
