import { toChecksumAddress } from 'ethereum-checksum-address'
import { NOT_SUPPORTED_TOKENS_IN_TRUSTWALLET } from '@/config/constants/tokens'
import CMCMapIds from '@/config/cmcCurrencyMap'

export const isSupported = (address) => {
  const checksumAddr = toChecksumAddress(address)

  const index = NOT_SUPPORTED_TOKENS_IN_TRUSTWALLET.indexOf(checksumAddr)
  return index === -1
}

export const getTokenLogoLink = (address) => {
  const checksumAddr = toChecksumAddress(address)
  return isSupported(address)
    ? `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${checksumAddr}/logo.png`
    : 'images/defaultTokenLogo.png'
}

export const getTokenLogoLinkBySymbol = (symbol) => {
  const item = symbol ? CMCMapIds.filter((_) => _.symbol.toLowerCase() === symbol?.toLowerCase()) : []
  return item.length
    ? item[0].cmcId
      ? `https://s2.coinmarketcap.com/static/img/coins/64x64/${item[0].cmcId}.png`
      : item[0].logo
    : null
}

export const getFilterData = (arr) => {
  if (!arr || arr === undefined) return null
  const newArr = []
  arr.forEach((item, index) => {
    const logo = getTokenLogoLinkBySymbol(item.symbol)
    if (logo) {
      newArr.push({
        ...item,
        logo,
      })
    }
    if (arr.length - 1 === index) {
      return newArr
    }
  })
  return newArr.length === 0 ? null : newArr
}
