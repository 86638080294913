import { useQuery } from '@tanstack/react-query'
import useActiveWeb3React from '@/hooks/useActiveWeb3React'
import { getBep20Contract } from '@/utils/contractHelpers'
import { BIG_ZERO, ethersToBigNumber } from '@/utils/bigNumber'
import { formatFixedNumber2, getBalanceAmount } from '@/utils/formatBalance'
import { fetchEthUsdPrice } from '@/utils/apiHelpers'
import BigNumber from 'bignumber.js'
import { isSupported } from '@/utils/getTokenLogoLink'
import { JsonRpcProvider } from '@ethersproject/providers'
import { getRpcUrl } from '@/utils/getRpcUrl'
import { PoolConfig } from '@/config/constants/types'
import { REACT_APP_CHAIN_ID } from '@/Cover-Portal/constants'
import { AppNetworkId } from '@/config/chains'

const useTokenBalanceQuery = (tokenAddress: string) => {
  const { chainId, account } = useActiveWeb3React()
  return useQuery({
    queryKey: ['tokenBalance', chainId, account, tokenAddress],

    queryFn: async () => {
      const contract = await getBep20Contract(tokenAddress)
      const res = await contract.balanceOf(account)
      return ethersToBigNumber(res)
    },

    enabled: !!chainId && !!account && tokenAddress !== '',
  })
}

export const useGetNativeCoinBalanceQuery = (pool?: PoolConfig) => {
  const { chainId, account } = useActiveWeb3React()
  return useQuery({
    queryKey: ['NativeCoinBalance', chainId, account, pool?.chainId],

    queryFn: async () => {
      const rpcUrl = getRpcUrl(pool?.chainId || (Number(REACT_APP_CHAIN_ID) as unknown as AppNetworkId))

      const amount = await new JsonRpcProvider(rpcUrl).getBalance(account)

      return getBalanceAmount(ethersToBigNumber(amount))
    },

    enabled: !!chainId && !!account,
  })
}

export const useGetRewarderBalanceQuery = (pool) => {
  return useQuery({
    queryKey: ['RewarderBalance', pool],

    queryFn: async () => {
      const tokenAddress = pool.earningToken.address[pool.chainId]
      const rewarderAddress = pool.rewardAddress[pool.chainId]
      const contract = getBep20Contract(tokenAddress, new JsonRpcProvider(getRpcUrl(pool.chainId)))
      const res = await contract.balanceOf(rewarderAddress)
      return ethersToBigNumber(res)
    },

    enabled: !!pool && (pool.ethPool || (!pool.ethPool && !pool.oldPool)),
  })
}

export const useGetTotalBalanceForUsdQuery = (tokens) => {
  const nativeCoinBalanceQuery = useGetNativeCoinBalanceQuery()
  const ethBalance = nativeCoinBalanceQuery && nativeCoinBalanceQuery.isSuccess ? nativeCoinBalanceQuery.data : BIG_ZERO

  return useQuery({
    queryKey: ['TotalBalanceForUsd', tokens, ethBalance],

    queryFn: async () => {
      const ethUsdPrice = await fetchEthUsdPrice()
      const decimalAmt = ethBalance ?? BIG_ZERO
      const ethAmt = new BigNumber(ethUsdPrice?.weth?.usd).times(decimalAmt)

      const amtArr = []
      let count = 0
      tokens.forEach((item) => {
        if (isSupported(item.token_address) && item.tokenPriceInUSD) {
          const decimalAmt2 = getBalanceAmount(new BigNumber(item.balance), item.decimals ?? 18)
          const tokenUsdAmt = new BigNumber(item.tokenPriceInUSD.toString()).times(decimalAmt2).toNumber()
          amtArr.push(formatFixedNumber2(tokenUsdAmt, Number(item.decimals)))
          count++
        }
      })
      const tokenSum = amtArr.reduce((a, b) => a + b, 0)
      const final = ethAmt.toNumber() + tokenSum
      return { totalInUsd: final, totalTokens: count }
    },

    enabled: true,
  })
}

export default useTokenBalanceQuery
