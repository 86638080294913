import React from 'react'
import './PageLoader.css'
import Logo from '../../assets/images/unore-logo-2.png'

const PageLoader: React.FC = () => {
  return (
    <div className="fixed-top h-100 d-flex align-items-center justify-content-center">
      <div className="loaderlogo-container position-relative">
        <img src={Logo} alt="UnoRe" className="loader-logo" />
        <span className="d-inline-block loader-dot loader-dot-1"></span>
        <span className="d-inline-block loader-dot loader-dot-2"></span>
        <span className="d-inline-block loader-dot loader-dot-3"></span>
        <span className="d-inline-block loader-dot loader-dot-4"></span>
      </div>
    </div>
  )
}

export default PageLoader
