import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

export const firebaseConfig = {
  apiKey: 'AIzaSyAujUMZn-1bvi844x9RjJK9og4utzkAvqY',
  authDomain: 'unore-staking-dapp.firebaseapp.com',
  projectId: 'unore-staking-dapp',
  storageBucket: 'unore-staking-dapp.appspot.com',
  messagingSenderId: '202805190963',
  appId: '1:202805190963:web:b57070a6f5f50e02ab9e46',
  measurementId: 'G-NCKM5ESKC6',
}

const app = initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)
