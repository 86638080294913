import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { AppState, useAppDispatch } from '../index'
import { updatePaymentDlgOpen, updateTransferDlgOpen, updateCurrentPolicyId } from './actions'

export const useGetPaymentDialogOpen = () => {
  const isPaymentDlgOpen = useSelector((state: AppState) => state.application.isPaymentDlgOpen)

  const dispatch = useAppDispatch()
  const setIsPaymentDlgOpen = useCallback((isOpen: boolean) => dispatch(updatePaymentDlgOpen({ isOpen })), [dispatch])

  return [isPaymentDlgOpen, setIsPaymentDlgOpen]
}

export const useGetPolicyTransferDlgOpen = () => {
  const { isTransferDlgOpen, currentPolicyId } = useSelector((state: AppState) => state.application)

  const dispatch = useAppDispatch()
  const setIsTransferDlgOpen = useCallback((isOpen: boolean) => dispatch(updateTransferDlgOpen({ isOpen })), [dispatch])

  const setCurrentPolicyId = useCallback((id: string) => dispatch(updateCurrentPolicyId({ id })), [dispatch])

  return { isTransferDlgOpen, currentPolicyId, setIsTransferDlgOpen, setCurrentPolicyId }
}

export const useGetPayStatus = () => {
  return useSelector((state: AppState) => state.application)
}
