import { createAction } from '@reduxjs/toolkit'

export const updateAssetsData = createAction('cover/updateAssetsData')

export const updateSelectedData = createAction<{ data: any }>('cover/updateSelectedData')

export const updateSelectedProtocols = createAction<any[]>('cover/updateSelectedProtocols')
export const updateSelectedProtocolIds = createAction<any[]>('cover/updateSelectedProtocolIds')

export const updateUnlockedContracts = createAction('cover/updateUnlockedContracts')

export const updateErc20TokenData = createAction('cover/updateErc20TokenData')

// export const updatePaymentDlgOpen = createAction('cover/updatePaymentDlgOpen');

export const updatePaymentStatus = createAction('cover/updatePaymentStatus')
