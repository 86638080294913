export default {
  _id: '61487cc50640ffa2aa868254',
  entity: '20210920_COHORT_1',
  metadata: {
    total_available_capital: 0.0,
    total_assets_covered: 0.0,
    total_premiums_collected: 0.0,
    total_policies_sold: 0.0,
    cohorts: [
      {
        cohort_id: 0.0,
        cohort_name: 'Cohort I',
        start_date: '27-10-2021',
        end_date: '27-10-2022',
        cohort_status: 'Active',
        cohort_address: '0xa7c8Dd26eB99534Dd848278e53CB7e260Ea83CE1',
        premium_pool_address: '0xd85D5144718982bdD442E67d9810151a6AdF31c2',
        staking_pools: [
          {
            pool_id: 0,
            pool_erc20_address: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
            pool_name: 'Zeus(v2) Pool',
            pool_banner_nft_desk_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/zeus.9a78fd11+(1).png',
            pool_banner_nft_mob_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/zeus-mobile.f5bbce2b+(1).png',
            pool_banner_nft_image: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/UNORE_Zeus_3Dnobase_fixed+(1).png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 20.0,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Active',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              '$USDC Single-Sided Coverage Pool(SSIP) providing coverage capacity for policies sold on our Cover Portal - stake $USDC and earn $UNO.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 1,
            pool_erc20_address: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
            pool_name: 'AresBSC Pool',
            pool_banner_nft_desk_icon: 'images/ares-2.png',
            pool_banner_nft_mob_icon: 'images/AresVertical-mobile.png',
            pool_banner_nft_image: 'images/ares.png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 20.0,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Active',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              '$UNO Single-Sided Coverage Pool(SSIP) on BSC providing coverage capacity for policies sold on our Cover Portal - stake $UNO and earn $UNO.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 2,
            pool_erc20_address: '0x3442a97F780769Ff028e4b491EfAA7ca277cEbD4',
            pool_name: 'Hercules Pool',
            pool_banner_nft_desk_icon: 'images/HerculesHorizontal.png',
            pool_banner_nft_mob_icon: 'images/HerculesVertical-mobile.png',
            pool_banner_nft_image: 'images/UNORE_Hercules_3D.png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 140,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Active',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              '$USDT Single-Sided Coverage Pool(SSIP) providing Coverage capacity for policies sold on our Cover Portal - stake $USDT and earn $UNO.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 3,
            pool_erc20_address: '0x7ee58dc800AE61DF5bCF8FdcEc0D86A6e33036A2',
            pool_name: 'Aphrodite Pool',
            pool_banner_nft_desk_icon: 'images/AphroditeHorizontal.png',
            pool_banner_nft_mob_icon: 'images/AphroditeVertical-mobile.png',
            pool_banner_nft_image: 'images/UNORE_Aphrodite_3D.png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 140,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Active',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              '$USDC Single-Sided Coverage Pool(SSIP) providing coverage capacity for policies sold on our Cover Portal - stake $USDC and earn $UNO.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 4,
            pool_erc20_address: '0xE565c7477e837c1B9764ff0b6ACB4BE9D6Fd128e',
            pool_name: 'Selene Pool',
            pool_banner_nft_desk_icon: 'images/selene-2.png',
            pool_banner_nft_mob_icon: 'images/SeleneVertical-mobile.png',
            pool_banner_nft_image: 'images/selene.png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 140,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Active',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              '$ETH Single-Sided Coverage Pool(SSIP) providing Coverage capacity for policies sold on our Cover Portal - stake $ETH and earn $UNO.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 5,
            pool_erc20_address: '0xE565c7477e837c1B9764ff0b6ACB4BE9D6Fd128e',
            pool_name: 'Ares Pool',
            pool_banner_nft_desk_icon: 'images/ares-2.png',
            pool_banner_nft_mob_icon: 'images/AresVertical-mobile.png',
            pool_banner_nft_image: 'images/ares.png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 140,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Active',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              '$UNO Single-Sided Coverage Pool(SSIP) providing coverage capacity for policies sold on our Cover Portal - stake $UNO and earn $UNO.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 6,
            pool_erc20_address: '0xE565c7477e837c1B9764ff0b6ACB4BE9D6Fd128e',
            pool_name: 'Poseidon Pool',
            pool_banner_nft_desk_icon: 'images/poseidon-2.png',
            pool_banner_nft_mob_icon: 'images/PoseidonVertical-mobile.png',
            pool_banner_nft_image: 'images/poseidon.png',
            max_apr: 0.0,
            curr_apr: 0.0,
            fixed_apr: 140,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Active',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              'Singe Sided Recoverage Pool(SSRP) responsible for claim payouts in the case of black swan events only. Capacity is reserved purely for providing coverage to the existing policies and earn $UNO.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          // {
          //   pool_id: 7,
          //   pool_erc20_address: '0x3442a97F780769Ff028e4b491EfAA7ca277cEbD4',
          //   pool_name: 'Plutus Pool',
          //   pool_banner_nft_desk_icon: 'plutus_mobile.png',
          //   pool_banner_nft_mob_icon: 'plutus_mobile.png',
          //   pool_banner_nft_image: 'plutus.png',
          //   max_apr: 0,
          //   curr_apr: 0.0,
          //   fixed_apr: 140,
          //   expected_claims: 12.0,
          //   risk_score: 64.0,
          //   pool_status: 'Active',
          //   rewards_unlock_date: '01-10-2022',
          //   total_pool_size: 35000.0,
          //   total_staked_capital: 0.0,
          //   description:
          //     '$USDC Single-Sided Coverage Pool (SSIP) providing coverage capacity for policies sold on our Cover Portal - stake $USDC and earn $UNO.',
          //   portfolio_strength_stats_data: [
          //     {
          //       id: 0.0,
          //       icon: 'lock',
          //       name: 'Security Audit',
          //       value: 49.0,
          //     },
          //     {
          //       id: 1.0,
          //       icon: 'person',
          //       name: 'Claims Exposure',
          //       value: 42.0,
          //     },
          //     {
          //       id: 2.0,
          //       icon: 'lock',
          //       name: 'Cover Spread',
          //       value: 42.0,
          //     },
          //     {
          //       id: 3.0,
          //       icon: 'person',
          //       name: 'Product Maturity',
          //       value: 44.3,
          //     },
          //     {
          //       id: 4.0,
          //       icon: 'lock',
          //       name: 'Project Team',
          //       value: 46.7,
          //     },
          //   ],
          //   expected_apr_data: [
          //     {
          //       month: 'OCT 2021',
          //       bullish_value: 30.27,
          //       average_value: 20.18,
          //       bearish_value: 10.09,
          //     },
          //     {
          //       month: 'NOV 2021',
          //       bullish_value: 29.26,
          //       average_value: 20.18,
          //       bearish_value: 11.09,
          //     },
          //     {
          //       month: 'DEC 2021',
          //       bullish_value: 28.25,
          //       average_value: 20.18,
          //       bearish_value: 12.1,
          //     },
          //     {
          //       month: 'JAN 2022',
          //       bullish_value: 27.24,
          //       average_value: 20.18,
          //       bearish_value: 13.11,
          //     },
          //     {
          //       month: 'FEB 2022',
          //       bullish_value: 26.23,
          //       average_value: 20.18,
          //       bearish_value: 14.12,
          //     },
          //     {
          //       month: 'MAR 2022',
          //       bullish_value: 25.22,
          //       average_value: 20.18,
          //       bearish_value: 15.13,
          //     },
          //     {
          //       month: 'APR 2022',
          //       bullish_value: 24.5,
          //       average_value: 20.18,
          //       bearish_value: 15.85,
          //     },
          //     {
          //       month: 'MAY 2022',
          //       bullish_value: 23.71,
          //       average_value: 20.18,
          //       bearish_value: 16.64,
          //     },
          //     {
          //       month: 'JUN 2022',
          //       bullish_value: 22.87,
          //       average_value: 20.18,
          //       bearish_value: 17.48,
          //     },
          //     {
          //       month: 'JUL 2022',
          //       bullish_value: 21.99,
          //       average_value: 20.18,
          //       bearish_value: 18.36,
          //     },
          //     {
          //       month: 'AUG 2022',
          //       bullish_value: 21.09,
          //       average_value: 20.18,
          //       bearish_value: 19.26,
          //     },
          //     {
          //       month: 'SEPT 2022',
          //       bullish_value: 20.18,
          //       average_value: 20.18,
          //       bearish_value: 20.18,
          //     },
          //   ],
          //   capital_distribution_for_protocols: [
          //     {
          //       protocol_id: 0.0,
          //       protocol_name: 'Umbrella Network - Staking Pool',
          //       protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
          //       value: 33.33,
          //     },
          //     {
          //       protocol_id: 1.0,
          //       protocol_name: 'Tidal Finance',
          //       protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
          //       value: 0.0,
          //     },
          //     {
          //       protocol_id: 2.0,
          //       protocol_name: 'Rocket Vault',
          //       protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
          //       value: 0.0,
          //     },
          //     {
          //       protocol_id: 3.0,
          //       protocol_name: 'Umbrella Network - Bridge',
          //       protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
          //       value: 33.33,
          //     },
          //     {
          //       protocol_id: 4.0,
          //       protocol_name: 'Nord Finance',
          //       protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
          //       value: 33.33,
          //     },
          //   ],
          // },
          {
            pool_id: 8,
            pool_erc20_address: '0xEcE9f1A3e8bb72b94c4eE072D227b9c9ba4cd750',
            pool_name: 'Zeus Pool',
            pool_banner_nft_desk_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/zeus.9a78fd11+(1).png',
            pool_banner_nft_mob_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/zeus-mobile.f5bbce2b+(1).png',
            pool_banner_nft_image: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/UNORE_Zeus_3Dnobase_fixed+(1).png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 20.0,
            expected_claims: 12.0,
            risk_score: 64.0,
            pool_status: 'Closed',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 35000.0,
            total_staked_capital: 0.0,
            description:
              'Most risky stablecoin staking Pool responsible for providing coverage capacity for cohort 1 partners and ensuring first set of claim payouts up to $35,000.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 49.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 42.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 42.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 44.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 46.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 30.27,
                average_value: 20.18,
                bearish_value: 10.09,
              },
              {
                month: 'NOV 2021',
                bullish_value: 29.26,
                average_value: 20.18,
                bearish_value: 11.09,
              },
              {
                month: 'DEC 2021',
                bullish_value: 28.25,
                average_value: 20.18,
                bearish_value: 12.1,
              },
              {
                month: 'JAN 2022',
                bullish_value: 27.24,
                average_value: 20.18,
                bearish_value: 13.11,
              },
              {
                month: 'FEB 2022',
                bullish_value: 26.23,
                average_value: 20.18,
                bearish_value: 14.12,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25.22,
                average_value: 20.18,
                bearish_value: 15.13,
              },
              {
                month: 'APR 2022',
                bullish_value: 24.5,
                average_value: 20.18,
                bearish_value: 15.85,
              },
              {
                month: 'MAY 2022',
                bullish_value: 23.71,
                average_value: 20.18,
                bearish_value: 16.64,
              },
              {
                month: 'JUN 2022',
                bullish_value: 22.87,
                average_value: 20.18,
                bearish_value: 17.48,
              },
              {
                month: 'JUL 2022',
                bullish_value: 21.99,
                average_value: 20.18,
                bearish_value: 18.36,
              },
              {
                month: 'AUG 2022',
                bullish_value: 21.09,
                average_value: 20.18,
                bearish_value: 19.26,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 20.18,
                average_value: 20.18,
                bearish_value: 20.18,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 9,
            pool_erc20_address: '0x0b5C802ecA88161B5daed08e488C83d819a0cD02',
            pool_name: 'Athena Pool',
            pool_banner_nft_desk_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/athena.a5f30562+(1).png',
            pool_banner_nft_mob_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/athena-mobile.abf6cf0e+(1).png',
            pool_banner_nft_image: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/UNORE_Athena_3Dnobase+(1).png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 13.0,
            expected_claims: 2.0,
            risk_score: 27.0,
            pool_status: 'Closed',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 165000.0,
            total_staked_capital: 0.0,
            description:
              'Second most risky stablecoin staking Pool responsible for providing coverage capacity for cohort 1 partners and ensuring second set of claim payouts up to $165,000.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 70.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 60.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 60.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 63.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 66.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 19.5,
                average_value: 13.0,
                bearish_value: 6.5,
              },
              {
                month: 'NOV 2021',
                bullish_value: 18.85,
                average_value: 13.0,
                bearish_value: 7.15,
              },
              {
                month: 'DEC 2021',
                bullish_value: 18.2,
                average_value: 13.0,
                bearish_value: 7.8,
              },
              {
                month: 'JAN 2022',
                bullish_value: 17.55,
                average_value: 13.0,
                bearish_value: 8.4,
              },
              {
                month: 'FEB 2022',
                bullish_value: 16.9,
                average_value: 13.0,
                bearish_value: 9.1,
              },
              {
                month: 'MAR 2022',
                bullish_value: 16.25,
                average_value: 13.0,
                bearish_value: 9.7,
              },
              {
                month: 'APR 2022',
                bullish_value: 15.78,
                average_value: 13.0,
                bearish_value: 10.21,
              },
              {
                month: 'MAY 2022',
                bullish_value: 15.27,
                average_value: 13.0,
                bearish_value: 10.72,
              },
              {
                month: 'JUN 2022',
                bullish_value: 14.73,
                average_value: 13.0,
                bearish_value: 11.26,
              },
              {
                month: 'JUL 2022',
                bullish_value: 14.17,
                average_value: 13.0,
                bearish_value: 11.83,
              },
              {
                month: 'AUG 2022',
                bullish_value: 13.59,
                average_value: 13.0,
                bearish_value: 12.4,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 13.0,
                average_value: 13.0,
                bearish_value: 13.0,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
          {
            pool_id: 10,
            pool_erc20_address: '0x2cd32dF1C436f8dE6e09d1A9851945c56bcEd32a',
            pool_name: 'Artemis Pool',
            pool_banner_nft_desk_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/artemis.2793592e+(1).png',
            pool_banner_nft_mob_icon: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/artemis-mobile.1cfd4992+(1).png',
            pool_banner_nft_image: 'https://unore.s3.eu-west-1.amazonaws.com/Pools/UNORE_Artemis_3Dnobase+(1).png',
            max_apr: 0,
            curr_apr: 0.0,
            fixed_apr: 7.0,
            expected_claims: 0.0,
            risk_score: 9.0,
            pool_status: 'Closed',
            rewards_unlock_date: '01-10-2022',
            total_pool_size: 300000.0,
            total_staked_capital: 0.0,
            description:
              'Least riskiest stablecoin staking Pool responsible for providing coverage capacity for cohort 1 partners and ensuring last set of claim payouts up to $300,000.',
            portfolio_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 91.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 78.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 78.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 82.3,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 86.7,
              },
            ],
            expected_apr_data: [
              {
                month: 'OCT 2021',
                bullish_value: 10.5,
                average_value: 7.0,
                bearish_value: 3.5,
              },
              {
                month: 'NOV 2021',
                bullish_value: 10.15,
                average_value: 7.0,
                bearish_value: 3.85,
              },
              {
                month: 'DEC 2021',
                bullish_value: 9.8,
                average_value: 7.0,
                bearish_value: 4.2,
              },
              {
                month: 'JAN 2022',
                bullish_value: 9.45,
                average_value: 7.0,
                bearish_value: 4.55,
              },
              {
                month: 'FEB 2022',
                bullish_value: 9.1,
                average_value: 7.0,
                bearish_value: 4.9,
              },
              {
                month: 'MAR 2022',
                bullish_value: 8.75,
                average_value: 7.0,
                bearish_value: 5.25,
              },
              {
                month: 'APR 2022',
                bullish_value: 8.5,
                average_value: 7.0,
                bearish_value: 5.5,
              },
              {
                month: 'MAY 2022',
                bullish_value: 8.22,
                average_value: 7.0,
                bearish_value: 5.77,
              },
              {
                month: 'JUN 2022',
                bullish_value: 7.93,
                average_value: 7.0,
                bearish_value: 6.06,
              },
              {
                month: 'JUL 2022',
                bullish_value: 7.63,
                average_value: 7.0,
                bearish_value: 6.37,
              },
              {
                month: 'AUG 2022',
                bullish_value: 7.31,
                average_value: 7.0,
                bearish_value: 6.68,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 7.0,
                average_value: 7.0,
                bearish_value: 7.0,
              },
            ],
            capital_distribution_for_protocols: [
              {
                protocol_id: 0.0,
                protocol_name: 'Umbrella Network - Staking Pool',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 1.0,
                protocol_name: 'Tidal Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
                value: 0.0,
              },
              {
                protocol_id: 2.0,
                protocol_name: 'Rocket Vault',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
                value: 0.0,
              },
              {
                protocol_id: 3.0,
                protocol_name: 'Umbrella Network - Bridge',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
                value: 33.33,
              },
              {
                protocol_id: 4.0,
                protocol_name: 'Nord Finance',
                protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
                value: 33.33,
              },
            ],
          },
        ],
        protocols_under_cover_detailed_info: [
          {
            protocol_id: 0,
            protocol_name: 'Umbrella Network',
            protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
            token_symbol: 'UMB',
            cover_type: 'Staking Pool Cover',
            policy_status: 'Inactive',
            total_assets_under_cover: 1500000.0,
            policy_cover_summary:
              "UnoRe will provide a Staking Pool Cover for Umbrella Network's LP staking pool and the Single Sided Staking Pool. The Total Covered Amount being USDC 1,500,000 with a sublimit of a maximum of USDC 10,000 and a USDC 500 deductible per individual policy. ",
            start_date: '01-10-2021',
            end_date: '01-10-2022',
            security_rating: 'A+',
            expected_claims_ratio: 2.0,
            current_total_claims: 0.0,
            policy_cover_document_link:
              'https://unore.s3.eu-west-1.amazonaws.com/Products/UnoRe+__+PolkaCover+__+Umbrella+Network.pdf',
            protocol_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 98.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 90.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 98.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 90.0,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 98.0,
              },
            ],
            expected_premium_sales_data: [
              {
                month: 'OCT 2021',
                bullish_value: 2500.0,
                average_value: 1666.66,
                bearish_value: 833.33,
              },
              {
                month: 'NOV 2021',
                bullish_value: 2333.33,
                average_value: 1666.66,
                bearish_value: 1000.0,
              },
              {
                month: 'DEC 2021',
                bullish_value: 2166.66,
                average_value: 1666.66,
                bearish_value: 1166.66,
              },
              {
                month: 'JAN 2022',
                bullish_value: 2000.0,
                average_value: 1666.66,
                bearish_value: 1333.33,
              },
              {
                month: 'FEB 2022',
                bullish_value: 1833.33,
                average_value: 1666.66,
                bearish_value: 1500.0,
              },
              {
                month: 'MAR 2022',
                bullish_value: 1666.66,
                average_value: 1666.66,
                bearish_value: 1666.66,
              },
              {
                month: 'APR 2022',
                bullish_value: 1666.66,
                average_value: 1666.66,
                bearish_value: 1666.66,
              },
              {
                month: 'MAY 2022',
                bullish_value: 1500.0,
                average_value: 1666.66,
                bearish_value: 1833.33,
              },
              {
                month: 'JUN 2022',
                bullish_value: 1333.33,
                average_value: 1666.66,
                bearish_value: 2000.0,
              },
              {
                month: 'JUL 2022',
                bullish_value: 1166.66,
                average_value: 1666.66,
                bearish_value: 2166.66,
              },
              {
                month: 'AUG 2022',
                bullish_value: 1000.0,
                average_value: 1666.66,
                bearish_value: 2333.33,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 833.33,
                average_value: 1666.66,
                bearish_value: 2500.0,
              },
            ],
          },
          {
            protocol_id: 1,
            protocol_name: 'Tidal Finance',
            protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/TidalLogo.png',
            token_symbol: 'TDL',
            cover_type: 'XOL Cover',
            policy_status: 'Inactive',
            total_assets_under_cover: 500000.0,
            policy_cover_summary:
              "During the Cover Period, when the total amount of the Payouts in the below listed pools of the insured's crypto assets exceeds 50% of the pool value, UnoRe shall pay the amount over and above the 50% threshold subject to the terms and conditions",
            start_date: '01-10-2021',
            end_date: '01-10-2022',
            security_rating: 'A+',
            expected_claims_ratio: 2.0,
            current_total_claims: 0.0,
            policy_cover_document_link: 'https://unore.s3.eu-west-1.amazonaws.com/Products/Unore-Tidal.pdf',
            protocol_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 98.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 90.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 98.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 90.0,
              },
              {
                id: 0.0,
                icon: 'lock',
                name: 'Project Team',
                value: 98.0,
              },
            ],
            expected_premium_sales_data: [
              {
                month: 'OCT 2021',
                bullish_value: 2500.0,
                average_value: 1666.66,
                bearish_value: 833.33,
              },
              {
                month: 'NOV 2021',
                bullish_value: 2333.33,
                average_value: 1666.66,
                bearish_value: 1000.0,
              },
              {
                month: 'DEC 2021',
                bullish_value: 2166.66,
                average_value: 1666.66,
                bearish_value: 1166.66,
              },
              {
                month: 'JAN 2022',
                bullish_value: 2000.0,
                average_value: 1666.66,
                bearish_value: 1333.33,
              },
              {
                month: 'FEB 2022',
                bullish_value: 1833.33,
                average_value: 1666.66,
                bearish_value: 1500.0,
              },
              {
                month: 'MAR 2022',
                bullish_value: 1666.66,
                average_value: 1666.66,
                bearish_value: 1666.66,
              },
              {
                month: 'APR 2022',
                bullish_value: 1666.66,
                average_value: 1666.66,
                bearish_value: 1666.66,
              },
              {
                month: 'MAY 2022',
                bullish_value: 1500.0,
                average_value: 1666.66,
                bearish_value: 1833.33,
              },
              {
                month: 'JUN 2022',
                bullish_value: 1333.33,
                average_value: 1666.66,
                bearish_value: 2000.0,
              },
              {
                month: 'JUL 2022',
                bullish_value: 1166.66,
                average_value: 1666.66,
                bearish_value: 2166.66,
              },
              {
                month: 'AUG 2022',
                bullish_value: 1000.0,
                average_value: 1666.66,
                bearish_value: 2333.33,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 833.33,
                average_value: 1666.66,
                bearish_value: 2500.0,
              },
            ],
          },
          {
            protocol_id: 2,
            protocol_name: 'Rocket Vault',
            protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/RVFLogo.jpeg',
            token_symbol: 'RVF',
            cover_type: 'CEX Cover',
            policy_status: 'Inactive',
            total_assets_under_cover: 100000.0,
            policy_cover_summary:
              "UnoRe will provide CEX Cover for users under the Rocket Vault Finance's Binance Business Account. The Total Covered Amount being USDC 100,000 with a sublimit of a maximum of USDC 10,000 or the Total Value of Locked assets of the User ",
            start_date: '01-10-2021',
            end_date: '01-10-2022',
            security_rating: 'A+',
            expected_claims_ratio: 0.0,
            current_total_claims: 0.0,
            policy_cover_document_link:
              'https://unore.s3.eu-west-1.amazonaws.com/Products/Unore-+RVF%E2%80%99s+CEX+Cover+(1).pdf',
            protocol_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 98.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 90.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 98.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 90.0,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 98.0,
              },
            ],
            expected_premium_sales_data: [
              {
                month: 'OCT 2021',
                bullish_value: 750.0,
                average_value: 500.0,
                bearish_value: 250.0,
              },
              {
                month: 'NOV 2021',
                bullish_value: 700.0,
                average_value: 500.0,
                bearish_value: 300.0,
              },
              {
                month: 'DEC 2021',
                bullish_value: 650.0,
                average_value: 500.0,
                bearish_value: 350.0,
              },
              {
                month: 'JAN 2022',
                bullish_value: 600.0,
                average_value: 500.0,
                bearish_value: 400.0,
              },
              {
                month: 'FEB 2022',
                bullish_value: 550.0,
                average_value: 500.0,
                bearish_value: 450.0,
              },
              {
                month: 'MAR 2022',
                bullish_value: 500.0,
                average_value: 500.0,
                bearish_value: 500.0,
              },
              {
                month: 'APR 2022',
                bullish_value: 500.0,
                average_value: 500.0,
                bearish_value: 500.0,
              },
              {
                month: 'MAY 2022',
                bullish_value: 450.0,
                average_value: 500.0,
                bearish_value: 550.0,
              },
              {
                month: 'JUN 2022',
                bullish_value: 400.0,
                average_value: 500.0,
                bearish_value: 600.0,
              },
              {
                month: 'JUL 2022',
                bullish_value: 350.0,
                average_value: 500.0,
                bearish_value: 650.0,
              },
              {
                month: 'AUG 2022',
                bullish_value: 300.0,
                average_value: 500.0,
                bearish_value: 700.0,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 250.0,
                average_value: 500.0,
                bearish_value: 750.0,
              },
            ],
          },
          {
            protocol_id: 3,
            protocol_name: 'Umbrella Network - Bridge',
            protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/UmbrellaLogo.png',
            token_symbol: 'UMB',
            cover_type: 'Bridge Cover',
            policy_status: 'active',
            total_assets_under_cover: 500000.0,
            policy_cover_summary:
              'During the Cover Period, when a hack is incurred by the bridge smart contract UnoRe shall pay the cover amount according to the subject to the terms and conditions',
            start_date: '10-11-2021',
            end_date: '10-11-2022',
            security_rating: 'A+',
            expected_claims_ratio: 2.0,
            current_total_claims: 0.0,
            policy_cover_document_link: 'https://unore.s3.eu-west-1.amazonaws.com/Products/POLICY+DOC+UMB+NETWORK.pdf',
            protocol_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 97.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 98.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 95.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 94.0,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 98.0,
              },
            ],
            expected_premium_sales_data: [
              {
                month: 'NOV 2021',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'DEC 2021',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'JAN 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'FEB 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'APR 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'MAY 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'JUN 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'JUL 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'AUG 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
            ],
          },
          {
            protocol_id: 4,
            protocol_name: 'Nord Finance',
            protocol_logo: 'https://unore.s3.eu-west-1.amazonaws.com/Products/nf_logo-removebg-preview.png',
            token_symbol: 'NORD',
            cover_type: 'Protocol Cover',
            policy_status: 'active',
            total_assets_under_cover: 1500000.0,
            policy_cover_summary:
              'UnoRe will provide a Protocol Cover for Nord Finance under which the Staking Pools and Savings and Advisory Products will be covered. The Total Covered Amount being USDC 1,500,000 ',
            start_date: '05-11-2021',
            end_date: '05-11-2021',
            security_rating: 'A+',
            expected_claims_ratio: 2.0,
            current_total_claims: 0.0,
            policy_cover_document_link:
              'https://unore.s3.eu-west-1.amazonaws.com/Products/NORD+Finance+policy+doc+revised+(1).pdf',
            protocol_strength_stats_data: [
              {
                id: 0.0,
                icon: 'lock',
                name: 'Security Audit',
                value: 92.0,
              },
              {
                id: 1.0,
                icon: 'person',
                name: 'Claims Exposure',
                value: 98.0,
              },
              {
                id: 2.0,
                icon: 'lock',
                name: 'Cover Spread',
                value: 98.0,
              },
              {
                id: 3.0,
                icon: 'person',
                name: 'Product Maturity',
                value: 93.0,
              },
              {
                id: 4.0,
                icon: 'lock',
                name: 'Project Team',
                value: 95.0,
              },
            ],
            expected_premium_sales_data: [
              {
                month: 'NOV 2021',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'DEC 2021',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'JAN 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'FEB 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'MAR 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'APR 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'MAY 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'JUN 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'JUL 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'AUG 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
              {
                month: 'SEPT 2022',
                bullish_value: 25000,
                average_value: 25000,
                bearish_value: 25000,
              },
            ],
          },
        ],
      },
    ],
  },
  created_at: '2021-09-20T12:21:25.902+0000',
  updated_at: '2021-09-20T12:21:25.902+0000',
}
