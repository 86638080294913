import { ConnectionType } from './types'
import { Connector } from '@web3-react/types'
import { injectedConnection } from './metaMask'
import { walletConnectV2Connection } from './walletConnectV2'

export function getConnections() {
  return [injectedConnection, walletConnectV2Connection]
}

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = getConnections().find((connection) => connection.connector === c)
    if (!connection) {
      throw Error('unsupported connector')
    }
    return connection
  }

  switch (c) {
    case ConnectionType.INJECTED:
      return injectedConnection
    case ConnectionType.WALLET_CONNECT_V2:
      return walletConnectV2Connection
  }
}
