import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useTokenApprove from '@/hooks/useTokenApprove'
import useBuyPolicy from '@/hooks/useBuyPolicy'
import { getTokenAddress } from '@/utils/addressHelpers'
import { useAppDispatch } from '@/state'
import { useSelectedData } from '@/state/cover/hooks'
import { updateSelectedData } from '@/state/cover/actions'
import { updatePaymentStatus } from '@/state/application/actions'
import { logEvent } from 'firebase/analytics'
import { analytics } from '@/config/firebase'
import { toast } from 'react-toastify'
import Header from './Header'
import DlgBody from './DlgBody'
import PayButton from './PayButton'
import { getDecimalAmount } from '@/utils/formatBalance'
import BigNumber from 'bignumber.js'
import useGetAllowanceOfTokenQuery from '@/hooks/queries/useGetAllowanceOfTokenQuery'
import { BIG_ZERO } from '@/utils/bigNumber'
import useTokenBalanceQuery, { useGetNativeCoinBalanceQuery } from '@/hooks/queries/useTokenBalanceQuery'
import { useQueryClient } from '@tanstack/react-query'

const PaymentDetailsModal = ({ isDialogOpen, setPaymentDialog }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [pendingTx, setPendingTx] = useState(false)

  const usdcAllowanceQuery = useGetAllowanceOfTokenQuery()
  const usdcAllowance = usdcAllowanceQuery && usdcAllowanceQuery.isSuccess ? usdcAllowanceQuery.data : BIG_ZERO

  const { onApprove } = useTokenApprove()
  const { onBuyPolicy } = useBuyPolicy()
  const selectedData = useSelectedData()

  const balanceQuery = useTokenBalanceQuery(getTokenAddress('usdc'))
  const balance = balanceQuery && balanceQuery.isSuccess ? balanceQuery.data : BIG_ZERO

  const nativeCoinBalanceQuery = useGetNativeCoinBalanceQuery()
  const ethBalance = nativeCoinBalanceQuery && nativeCoinBalanceQuery.isSuccess ? nativeCoinBalanceQuery.data : BIG_ZERO

  const policyCounts = useMemo(() => {
    if (selectedData) {
      return selectedData.protocols?.length
    }
    return 0
  }, [selectedData])

  const isAllowed = useMemo(() => {
    if (!selectedData) {
      return false
    }
    const bigAmt = getDecimalAmount(new BigNumber(selectedData.coverPrice), 6)
    return usdcAllowance.gte(bigAmt)
  }, [selectedData, usdcAllowance])

  const handleClickPay = async () => {
    logEvent(analytics, 'Approve payment click')
    const bigAmt = getDecimalAmount(new BigNumber(selectedData.coverPrice), 6)
    if (balanceQuery.isLoading) {
      toast.warning('Not fetched the needed infos from the contract.')
      return
    }
    if (bigAmt.gt(balance)) {
      toast.warning('Insufficient USDC balance.')
      return
    }
    if (ethBalance?.toNumber() < 0.0005 && !isAllowed) {
      toast.warning('Insufficient ETH balance.')
      return
    }
    setPendingTx(true)

    if (isAllowed) {
      // implement the pay flow
      const buyRes = await onBuyPolicy(selectedData)
      if (buyRes) {
        toast.success(`Purchased the ${policyCounts > 1 ? 'policies' : 'policy'} successfully!`)
        dispatch<any>(updateSelectedData(null))
        await queryClient.invalidateQueries({ queryKey: ['PolicyPurChasedData'] })
        await queryClient.invalidateQueries({ queryKey: ['CountOfActiveCovers'] })
        setTimeout(() => {
          setPaymentDialog(false)
          dispatch(updatePaymentStatus({ isOpen: true }))
          navigate('/my-policies')
          window.location.reload()
        }, 2000)
      } else {
        toast.warning(`Failed to puchase ${policyCounts > 1 ? 'policies' : 'policy'}!`)
      }
      setPendingTx(false)
    } else {
      const approveStatus = await onApprove()
      if (approveStatus) {
        usdcAllowanceQuery.refetch()
        setPendingTx(false)
      } else {
        setPendingTx(false)
      }
    }
  }

  const btnText = useMemo(() => {
    return isAllowed ? 'Pay' : 'Approve'
  }, [isAllowed])

  return (
    <div
      className={`
        cp-dialog-container fixed-top h-100 w-100 d-flex align-items-center justify-content-center container-fluid
        ${isDialogOpen ? 'cp-dialog-container-show' : 'visible-hidden'}
      `}
    >
      <div className="cp-dialog cp-site-box payment-details-box">
        <Header pendingTx={pendingTx} setPaymentDialog={setPaymentDialog} />

        <DlgBody selectedData={selectedData} />

        <PayButton
          pendingTx={pendingTx}
          balance={balance}
          fetchStatus={balanceQuery.isLoading}
          btnText={btnText}
          handleClickPay={handleClickPay}
        />
      </div>
    </div>
  )
}

export default PaymentDetailsModal
