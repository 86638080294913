import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { Connection, ConnectionType } from './types'
import { AppNetworkId, CHAINS } from '@/config/chains'
import { getRpcUrl } from '@/utils/getRpcUrl'

const [mainnet, ...optionalChains] = Object.keys(CHAINS).map(Number)

//@ts-ignore
export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
        chains: [mainnet],
        optionalChains,
        showQrModal: true,
        rpcMap: {
          [AppNetworkId.ROLLUX_TESTNET]: getRpcUrl(AppNetworkId.ROLLUX_TESTNET),
        },
      },
    }),
)

export const walletConnectV2Connection: Connection = {
  getName: () => 'WalletConnect',
  connector: walletConnectV2,
  hooks,
  type: ConnectionType.WALLET_CONNECT_V2,
  shouldDisplay: () => true,
}
