import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

type SerializedBigNumber = string

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

export const BIG_ALLOWANCE = new BigNumber('10000000000000000000000000000000000')

export const ethersToSerializedBigNumber = (ethersBn: ethers.BigNumber): SerializedBigNumber =>
  ethersToBigNumber(ethersBn).toJSON()

export const ethersToBigNumber = (ethersBn: ethers.BigNumber): BigNumber => new BigNumber(ethersBn.toString())

export const getSafeTokenAmount = (value, decimal = 6) => {
  const before = new BigNumber(value).multipliedBy(BIG_TEN.pow(decimal)).toString().split('.')[0]
  const after = new BigNumber(before).dividedBy(BIG_TEN.pow(decimal)).toNumber()
  return after
}
