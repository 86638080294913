import { createReducer } from '@reduxjs/toolkit'
import {
  updateBlockNumber,
  updatePaymentDlgOpen,
  updateTransferDlgOpen,
  updatePaymentStatus,
  updateCurrentPolicyId,
} from './actions'

export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number }
  isPaymentDlgOpen?: boolean
  payStatus?: boolean
  isTransferDlgOpen?: boolean
  currentPolicyId?: string
}

const initialState: ApplicationState = {
  blockNumber: {},
  isPaymentDlgOpen: false,
  payStatus: false,
  isTransferDlgOpen: false,
  currentPolicyId: '',
}

export default createReducer(initialState, (builder: any) =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId])
      }
    })
    .addCase(updatePaymentDlgOpen, (state, action) => {
      state.isPaymentDlgOpen = action.payload.isOpen
    })
    .addCase(updateTransferDlgOpen, (state, action) => {
      state.isTransferDlgOpen = action.payload.isOpen
    })
    .addCase(updateCurrentPolicyId, (state, action) => {
      state.currentPolicyId = action.payload.id
    })
    .addCase(updatePaymentStatus, (state, action) => {
      state.payStatus = action.payload.isOpen
    }),
)
