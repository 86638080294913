import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RefreshContextProvider } from '@/contexts/RefreshContext'
import './index.css'
import './index-responsive.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Providers from './Providers'
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { DEFAULT_REQUEST_REFRESH_INTERVAL } from '@/config'
import { SnackbarProvider } from 'notistack'
import CustomSnackBar from './components/Snackbar/CustomSnackbar'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: DEFAULT_REQUEST_REFRESH_INTERVAL,
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      autoHideDuration={5000}
      Components={{
        customVariant: CustomSnackBar,
      }}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <Providers>
            <RefreshContextProvider>
              <ReactQueryDevtools />
              <App />
            </RefreshContextProvider>
          </Providers>
        </QueryClientProvider>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode>,
)

reportWebVitals()
